import React, { Component } from 'react'
import './ConfigVendaCentral.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'
import Alert from './../../components/templates/Alert'
import CircularProgress from '@material-ui/core/CircularProgress';
import MaskedInput from 'react-text-mask';

const initalState = {
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  config_venda: {
    cv_id: '',
    cv_lancar_receita: 'Sim',
    cv_lancar_despesa: 'Sim',
    cv_enviar_not_email: 'Sim',
    cv_enviar_not_whats: 'Sim',
    cv_email: '',
    cv_telefone: '55',
    cv_tabela_preco_id: '',
    formas_pagamento: []
  },
  forma_pagamento: {
    fpv_id: '',
    fpv_formapagto_id: '',
    forma_descricao: '',
    fpv_contfin_id: '',
    contafin_descricao: '',
    habilita: '',
    index: ''
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  tabelaPrecos: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'contafin_descricao', numeric: false, disablePadding: true, label: 'Conta Financeira' },
    { id: 'forma_descricao', numeric: false, disablePadding: true, label: 'Forma de Pagamento' }
  ],
  acoesTabela: [],
  loading: true
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+',/\d/,/\d/,' ','(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export default class Curso extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'config-venda-central')[0]

    if(!permissoes.perm_visualizar){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message: 'Você não tem permissão para acessa essa tela!'
        }
      })

      this.backPage()

      return
    }

    if(permissoes.perm_inserir || permissoes.perm_alterar){
      this.setState({
        acoesTabela: ['removeTabela']
      })
    }

    this.setState({
      permissoes
    })

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tabelaPreco`, this.getToken())

      const tabelaPrecos = data.map(param => {
        return {
          tabprec_id: param.tabprec_id,
          tabprec_descricao: param.tabprec_descricao,
          tabprec_data_inicio: param.tabprec_data_inicio,
          tabprec_data_fim: param.tabprec_data_fim,
          itens: param.itens
        }
      })

      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/1`, this.getToken())

      const { data: configVenda } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoVenda`, this.getToken())

      if(configVenda){
        this.setState({
          config_venda: {
            cv_id: configVenda.cv_id,
            cv_lancar_receita: configVenda.cv_lancar_receita ? 'Sim' : 'Não',
            cv_lancar_despesa: configVenda.cv_lancar_despesa ? 'Sim' : 'Não',
            cv_enviar_not_email: configVenda.cv_enviar_not_email ? 'Sim' : 'Não',
            cv_enviar_not_whats: configVenda.cv_enviar_not_whats ? 'Sim' : 'Não',
            cv_email: configVenda.cv_email || '',
            cv_telefone: configVenda.cv_telefone || '',
            cv_tabela_preco_id: configVenda.cv_tabela_preco_id,
            formas_pagamento: configVenda.formas_pagamento.map((forma, key) => {
              return {
                fpv_id: forma.fpv_id,
                fpv_formapagto_id: forma.fpv_formapagto_id,
                forma_descricao: forma.forma_pagamento.formpag_descricao,
                fpv_contfin_id: forma.fpv_contfin_id,
                contafin_descricao: forma.conta_financeira.contfin_descricao,
                habilita: forma.conta_financeira.contfin_tipo_conta,
                index: key
              }
            })
          }
        })
      }

      this.setState({
        tabelaPrecos: {
          list: tabelaPrecos
        },
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        },
        contasFinanceira: {
          list: contasFinanceira
        },
        loading: false
      })

    } catch ({ response }) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateField(event){
    let {config_venda } = this.state

    config_venda[event.target.name] = event.target.value

    this.setState({
      config_venda
    })
  }

  updateFieldFormaPagamento(event){
    let {forma_pagamento, contasFinanceira, formasPagamento } = this.state

    let name = event.target.name
    let value = event.target.value

    forma_pagamento[name] = value

    if(name === 'fpv_contfin_id' && value !== ''){
      let conta_selecionada = contasFinanceira.list.filter(param => param.contfin_id === parseInt(value))[0]
    
      forma_pagamento.habilita = conta_selecionada.contfin_tipo_conta
      forma_pagamento.contafin_descricao = conta_selecionada.contfin_descricao
    }

    if(name === 'fpv_formapagto_id' && value !== ''){
      let forma_selecionada = formasPagamento.list.filter(param => param.formpag_id === parseInt(value))[0]

      forma_pagamento.forma_descricao = forma_selecionada.formpag_descricao
    }

    this.setState({
      forma_pagamento
    })
  }

  adicionarForma(){
    let {forma_pagamento, config_venda} = this.state

    if(forma_pagamento.fpv_contfin_id === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message: 'Por favor informar a Conta Financeira'
        }
      })
      return
    }

    if(forma_pagamento.fpv_formapagto_id === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message: 'Por favor informar a Forma de Pagamento'
        }
      })
      return
    }

    if(forma_pagamento.index === ''){
      let ja_add = config_venda.formas_pagamento.findIndex(param => param.fpv_contfin_id === forma_pagamento.fpv_contfin_id && param.fpv_formapagto_id === forma_pagamento.fpv_formapagto_id)

      if(ja_add !== -1){
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Essa forma de pagamento já foi adicionada'
          }
        })
        return
      }

      forma_pagamento.index = config_venda.formas_pagamento.length
      config_venda.formas_pagamento.push({
        ...forma_pagamento
      })
    }else{
      config_venda.formas_pagamento[forma_pagamento.index] = {
        ...forma_pagamento
      }
    }

    this.setState({
      config_venda,
      forma_pagamento: {
        fpv_id: '',
        fpv_formapagto_id: '',
        fpv_contfin_id: '',
        habilita: '',
        index: ''
      }
    })
  }

  removerItem(index){
    let {config_venda} = this.state

    config_venda.formas_pagamento.splice(index, 1)

    const aux = []

    for (let i = 0; i < config_venda.formas_pagamento.length; i++) {
      aux.push({
        ...config_venda.formas_pagamento[i],
        index: i
      })
    }

    config_venda.formas_pagamento = aux

    this.setState({
      config_venda
    })
  }

  async salvar(){
    let {config_venda} = this.state

    if(config_venda.cv_enviar_not_email === 'Sim'){
      if(config_venda.cv_email === ""){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: "Por favor informar email da notificação"
          }
        })
        return
      }

      if (!validacaoEmail(config_venda.cv_email)) {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Email da notificação invalido!'
          }
        })
        return
      }
    }

    if(config_venda.cv_enviar_not_whats === 'Sim'){
      if(config_venda.cv_telefone === ""){
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: "Por favor informar número do whats da notificação"
          }
        })
        return
      }
    }

    if(config_venda.cv_tabela_preco_id === ""){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: "Por favor informar a tabela de preço"
        }
      })
      return
    }

    if(config_venda.formas_pagamento.length === 0){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: "Por favor informar pelo menos uma forma de pagamento"
        }
      })
      return
    }

    try {
      let dados = {
        cv_lancar_receita: config_venda.cv_lancar_receita === 'Sim' ? true : false,
        cv_lancar_despesa: config_venda.cv_lancar_despesa === 'Sim' ? true : false,
        cv_enviar_not_email: config_venda.cv_enviar_not_email === 'Sim' ? true : false,
        cv_enviar_not_whats: config_venda.cv_enviar_not_whats === 'Sim' ? true : false,
        cv_email: config_venda.cv_email,
        cv_telefone: config_venda.cv_telefone,
        cv_tabela_preco_id: config_venda.cv_tabela_preco_id,
        formas_pagamento: config_venda.formas_pagamento.map(value => {
          return {
            fpv_id: value.fpv_id,
            fpv_formapagto_id: value.fpv_formapagto_id,
            fpv_contfin_id: value.fpv_contfin_id
          }
        })
      }

      if(config_venda.cv_id !== ''){
        await axios.put(`${process.env.REACT_APP_API_URL}/configuracaoVenda/${config_venda.cv_id}`, {...dados} ,this.getToken())
      }else{
        await axios.post(`${process.env.REACT_APP_API_URL}/configuracaoVenda`, {...dados} ,this.getToken())
      }

      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 3000,
          severity: 'success',
          message: 'Configuração salva com sucesso!'
        }
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }
  
  render() {
    const { permissoes, config_venda, tabelaPrecos, formasPagamento, forma_pagamento, contasFinanceira } = this.state
    
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Configuração...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12} xs={12}>
                  <h1 className="titulo">Configuração de Venda</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Lançar Conta Receita Central*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cv_lancar_receita"
                    value={config_venda.cv_lancar_receita}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={(!permissoes.perm_inserir || !permissoes.perm_alterar)}
                  >
                    <option value={'Sim'}> Sim </option>
                    <option value={'Não'}> Não </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Lançar Conta Despesa Unidade*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cv_lancar_despesa"
                    value={config_venda.cv_lancar_despesa}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={(!permissoes.perm_inserir || !permissoes.perm_alterar)}
                  >
                    <option value={'Sim'}> Sim </option>
                    <option value={'Não'}> Não </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Enviar Notificação Email*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cv_enviar_not_email"
                    value={config_venda.cv_enviar_not_email}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={(!permissoes.perm_inserir || !permissoes.perm_alterar)}
                  >
                    <option value={'Sim'}> Sim </option>
                    <option value={'Não'}> Não </option>
                  </TextField>
                </Grid>
                {config_venda.cv_enviar_not_email === 'Sim' &&
                  <Grid item md={3} xs={12} sm={3}>
                    <TextField 
                      className="input" 
                      label="Email Notificação*" 
                      variant="outlined" 
                      size="small" 
                      name="cv_email" 
                      value={config_venda.cv_email} 
                      onChange={(e) => this.updateField(e)} 
                      InputLabelProps={{ shrink: true }}
                      disabled={(!permissoes.perm_inserir || !permissoes.perm_alterar)}
                    />
                  </Grid>
                }
                <Grid item md={3} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Enviar Notificação Whats*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cv_enviar_not_whats"
                    value={config_venda.cv_enviar_not_whats}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={(!permissoes.perm_inserir || !permissoes.perm_alterar)}
                  >
                    <option value={'Sim'}> Sim </option>
                    <option value={'Não'}> Não </option>
                  </TextField>
                </Grid>
                {config_venda.cv_enviar_not_whats === 'Sim' &&
                  <Grid item md={3} xs={12} sm={3}>
                    <TextField 
                      className="input" 
                      label="Email Notificação*" 
                      variant="outlined" 
                      size="small" 
                      name="cv_telefone" 
                      value={config_venda.cv_telefone} 
                      onChange={(e) => this.updateField(e)} 
                      InputLabelProps={{ shrink: true }}
                      disabled={(!permissoes.perm_inserir || !permissoes.perm_alterar)}
                      InputProps={{ inputComponent: TextMaskCustom }}
                    />
                  </Grid>
                }
                <Grid item md={3} xs={12} sm={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tabela de Preço Padrão*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cv_tabela_preco_id"
                    value={config_venda.cv_tabela_preco_id}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={(!permissoes.perm_inserir || !permissoes.perm_alterar)}
                  >
                    <option value=''>  </option>
                    {tabelaPrecos.list.map(tabela => {
                      return (
                        <option value={tabela.tabprec_id}>{tabela.tabprec_descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <hr className="mg_top_10"/>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <h4>Formas de Pagamento:</h4>
                </Grid>
              </Grid>
              {(permissoes.perm_inserir || permissoes.perm_alterar) &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={3} xs={12} sm={3}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Conta Financeira*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="fpv_contfin_id"
                      value={forma_pagamento.fpv_contfin_id}
                      onChangeCapture={(e) => this.updateFieldFormaPagamento(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=''>  </option>
                      {contasFinanceira.list.map(conta => {
                        return (
                          <option value={conta.contfin_id}>{conta.contfin_descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12} sm={3}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Forma de Pagamento*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="fpv_formapagto_id"
                      value={forma_pagamento.fpv_formapagto_id}
                      onChangeCapture={(e) => this.updateFieldFormaPagamento(e)}
                      InputLabelProps={{ shrink: true }}
                      disabled={forma_pagamento.fpv_contfin_id === ''}
                    >
                      <option value=''></option>
                      {formasPagamento.list.filter(param => param.formpag_habilita === forma_pagamento.habilita || param.formpag_habilita === 'AMBOS').map(forma => {
                        return (
                          <option value={forma.formpag_id}>{forma.formpag_descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => this.adicionarForma()}>Adicionar</Button>
                  </Grid>
                </Grid>
              }
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <Table
                    headCell={this.state.cabecalhoTabela}
                    rows={config_venda.formas_pagamento}
                    acoes={this.state.acoesTabela}
                    remove={e => this.removerItem(e)}
                  />
                </Grid>
              </Grid>
              <hr className="mg_top_10"/>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={10} xs={12} sm={9}></Grid>
                <Grid item md={2} xs={12} sm={3}>
                  {(permissoes.perm_inserir || permissoes.perm_alterar) &&
                    <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => this.salvar()}>Salvar</Button>
                  }
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </div>
    )
  }
}