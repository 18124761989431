import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button } from '@material-ui/core'
import Table from './../Table'
import CircularProgress from '@material-ui/core/CircularProgress';

function getModalStyle() {
  return {
   
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    marginLeft: '10%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={10} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Alunos</h5>
            </Grid>
            {props.loadingDownload &&
              <Grid item md={2} xs={12} sm={3}>
                <CircularProgress />
              </Grid>
            }
            {!props.loadingDownload && 
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => props.imprimir('sub')}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          <br />
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
							<Table
                headCell={props.cabecalhoTabela} 
                rows={props.list}
                acoes={props.acoesTabela}
                urlView={props.urlView} />
						</Grid>
					</Grid>
        </div>
      </Modal>
    </div>
  );
}
