import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import React, { Component } from 'react'
import './Franqueado.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'

import ModalErro from './../../components/modals/Erro'

import moment from 'moment';
import { format } from 'cnpj'
import axios from 'axios'

const cpf = require('node-cpf-cnpj')

const initalState = {
  responsavel: {
    id: "",
    pessoa_id: "",
    descricao: "",
    tipoPessoa: "FISICA",
    unin_pessoa_id: "",
    pessoaFisica: {
      pesf_id: "",
      pessoa_id: "",
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    enderecos: [{
      end_id: "",
      cep_id: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: "",
      tipoEndereco: "RESIDENCIAL",
      index: "1"
    }],
    emails: [{
      ema_id: "",
      email: "",
      principal: "",
      index: "1"
    }],
    contatos: [{
      tel_id: "",
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: "",
      index: "1"
    }]
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  loadingSalvar: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function formatarTelefone(str) {
  if (str.length === 11) {
    return str.replace(/(\d{2})?(\d{4})?(\d{5})/, "($1) $2-$3")
  } else if (str.length === 10) {
    return str.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3")
  }
}

export default class CadastroFranqueado extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    try {
      const { match: { params } } = this.props;

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/franqueados/${params.franqueadoId}`, this.getToken())

      let enderecos = []
      let emails = []
      let contatos = []

      for (let i = 0; i < data.responsavel.pessoa.enderecos.length; i++) {
        enderecos.push({
          end_id: data.responsavel.pessoa.enderecos[i].end_id,
          cep_id: data.responsavel.pessoa.enderecos[i].end_cep_id,
          rua: data.responsavel.pessoa.enderecos[i].end_logradouro,
          numero: data.responsavel.pessoa.enderecos[i].end_numero,
          bairro: data.responsavel.pessoa.enderecos[i].end_bairro,
          cidade: data.responsavel.pessoa.enderecos[i].end_cidade,
          estado: data.responsavel.pessoa.enderecos[i].end_estado,
          cep: formatarCEP(data.responsavel.pessoa.enderecos[i].end_cep),
          principal: data.responsavel.pessoa.enderecos[i].end_principal,
          tipoEndereco: data.responsavel.pessoa.enderecos[i].end_tipo,
          index: i + 1
        })
      }

      for (let i = 0; i < data.responsavel.pessoa.telefones.length; i++) {
        contatos.push({
          tel_id: data.responsavel.pessoa.telefones[i].tel_id,
          numero: data.responsavel.pessoa.telefones[i].tel_numero,
          tipoContato: data.responsavel.pessoa.telefones[i].tel_tipo,
          principal: data.responsavel.pessoa.telefones[i].tel_principal,
          index: i + 1
        })
      }

      for (let i = 0; i < data.responsavel.pessoa.emails.length; i++) {
        emails.push({
          ema_id: data.responsavel.pessoa.emails[i].ema_id,
          email: data.responsavel.pessoa.emails[i].ema_email,
          principal: data.responsavel.pessoa.emails[i].ema_principal,
          index: i + 1
        })
      }

      this.setState({
        responsavel: {
          id: data.frao_id,
          pessoa_id: data.frao_responsavel_id,
          tipoPessoa: data.responsavel.pessoa.pes_tipo,
          unin_pessoa_id: data.unin_id,
          pessoaFisica: {
            pesf_id: data.responsavel.pesf_id,
            pessoa_id: data.responsavel.pessoa.pes_id,
            nome: data.responsavel.pesf_nome,
            cpf: cpf.format(data.responsavel.pesf_cpf),
            rg: data.responsavel.pesf_rg,
            rg_orgao_emissor: data.responsavel.pesf_rg_orgao_emissor,
            rg_uf: data.responsavel.pesf_rg_uf,
            rg_data_emissao:data.responsavel.pesf_rg_data_emissao ? moment(data.responsavel.pesf_rg_data_emissao).format('YYYY-MM-DD') : '',
            dataNascimento: data.responsavel.pesf_datanascimento ? moment(data.responsavel.pesf_datanascimento).format('YYYY-MM-DD') : ''
          },
          enderecos,
          emails,
          contatos
        },
        loading: false
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar o responsavel, tente mais tarde"
      })

      this.backPage()
    }

  }

  updateFieldPessoaFisica(event) {
    const responsavel = { ...this.state.responsavel }
    responsavel.pessoaFisica[event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  updateFieldEndereco(event, index) {
    const responsavel = { ...this.state.responsavel }
    responsavel.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  updateFieldEmail(event, index) {
    const responsavel = { ...this.state.responsavel }
    responsavel.emails[index - 1][event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  updateFieldContato(event, index) {
    const responsavel = { ...this.state.responsavel }
    responsavel.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  formataTelefone(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const responsavel = { ...this.state.responsavel }
    responsavel.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    this.setState({ responsavel })
  }

  updateField(event) {
    const responsavel = { ...this.state.responsavel }
    responsavel[event.target.name] = event.target.value
    this.setState({ responsavel })
  }

  updateFieldEnderecoPrincipal(event, index) {
    const responsavel = { ...this.state.responsavel }
    const indexAux = responsavel.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.enderecos[indexAux].principal = false
    }

    responsavel.enderecos[index - 1].principal = !responsavel.enderecos[index - 1].principal
    this.setState({ responsavel })
  }

  updateFieldContatoPrincipal(event, index) {
    const responsavel = { ...this.state.responsavel }
    const indexAux = responsavel.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.contatos[indexAux].principal = false
    }

    responsavel.contatos[index - 1].principal = !responsavel.contatos[index - 1].principal
    this.setState({ responsavel })
  }

  updateFieldEmailPrincipal(event, index) {
    const responsavel = { ...this.state.responsavel }
    const indexAux = responsavel.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      responsavel.emails[indexAux].principal = false
    }

    responsavel.emails[index - 1].principal = !responsavel.emails[index - 1].principal

    this.setState({ responsavel })
  }

  async validarCpfCnpj(event) {

    let cpf_cnpj = event.target.value
    const responsavel = this.state.responsavel

    if (cpf_cnpj.length <= 11) {
      cpf_cnpj = cpf.format(cpf_cnpj)
      if (!cpf.isValid(cpf_cnpj)) {

        responsavel.pessoaFisica.cpf = ""
        this.setState({ responsavel })

        const erro = {
          titulo: "CPF Invalido!",
          descricao: "Por favor corrija o campo CPF"
        }
        this.setState({
          modalErro: true,
          erro
        })

      } else {
        responsavel.pessoaFisica.cpf = cpf_cnpj
        this.setState({ responsavel })
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        responsavel.pessoaJuridica.cnpj = cpf_cnpj
        this.setState({ responsavel })

      } else {
        responsavel.pessoaJuridica.cnpj = ""
        this.setState({ responsavel })
        const erro = {
          titulo: "CNPJ Invalido!",
          descricao: "Por favor corrija o campo CNPJ"
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }
  }

  async pegaEndereco(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const responsavel = { ...this.state.responsavel }
      const enderecos = responsavel.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      responsavel.enderecos[index - 1] = endereco
      this.setState({ responsavel })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        responsavel.enderecos[index - 1] = endereco
        this.setState({ responsavel })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        responsavel.enderecos[index - 1] = endereco
        this.setState({ responsavel })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  addNovoEndereco() {
    const responsavel = { ...this.state.responsavel }
    const enderecos = responsavel.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    responsavel.enderecos = enderecos
    this.setState({ responsavel })
  }

  addNovoContato() {
    const responsavel = { ...this.state.responsavel }
    const contatos = responsavel.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: false,
      index
    })

    responsavel.contatos = contatos
    this.setState({ responsavel })
  }

  addNovoEmail() {
    const responsavel = { ...this.state.responsavel }
    const emails = responsavel.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    responsavel.emails = emails
    this.setState({ responsavel })
  }

  proximo(atual, prox) {
    const { responsavel } = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      let valido = true

      if (responsavel.pessoaFisica.nome === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.cpf === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.rg === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.rg_orgao_emissor === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.rg_uf === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.rg_data_emissao === "") {
        valido = false
      }
      if (responsavel.pessoaFisica.dataNascimento === "") {
        valido = false
      }

      if (valido) {
        this.setState({
          enableDadosPessoa: false,
          enableDadosEndereco: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      responsavel.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEndereco: false,
          enableDadosContato: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      responsavel.contatos.forEach(contato => {
        if (contato.numero === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosContato: false,
          enableDadosEmail: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }
  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    }
  }

  validateForm() {
    const { responsavel } = this.state

    responsavel.emails.forEach(email => {
      if (email.email === "") {
        return false
      }
    })

    return true
  }

  backPage(timeout = 1000) {
    const self = this
    this.setState({
      tabelaPreco: initalState.tabelaPreco
    })
    setTimeout(() => {
      self.props.history.push("/franqueado");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async salvar() {
    const { responsavel } = this.state

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    let emails = []
    let telefones = []
    let enderecos = []

    responsavel.emails.forEach(email => {
      if (email.ema_id) {
        emails.push({
          ema_id: email.ema_id,
          email: email.email,
          principal: email.principal
        })
      } else {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      }
    })

    responsavel.contatos.forEach(telefone => {
      if (telefone.tel_id) {
        telefones.push({
          tel_id: telefone.tel_id,
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      } else {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      }
    })

    responsavel.enderecos.forEach(endereco => {
      if (endereco.end_id) {
        enderecos.push({
          end_id: endereco.end_id,
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      } else {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      }
    })

    const { match: { params } } = this.props;

    const dados = {
      responsavel: {
        unin_id: params.franqueadoId,
        pessoa_id: responsavel.pessoa_id,
        pesf_id: responsavel.pessoaFisica.pesf_id,
        nome: responsavel.pessoaFisica.nome,
        cpf: cpf.strip(responsavel.pessoaFisica.cpf),
        rg: responsavel.pessoaFisica.rg,
        rg_orgao_emissor: responsavel.pessoaFisica.rg_orgao_emissor,
        rg_uf: responsavel.pessoaFisica.rg_uf,
        rg_data_emissao: responsavel.pessoaFisica.rg_data_emissao,
        datanascimento: responsavel.pessoaFisica.dataNascimento,
        class_tipo: "",
        tipo: responsavel.tipoPessoa,
        emails,
        telefones,
        enderecos,
      }
    }

    try {

      await axios.put(`${process.env.REACT_APP_API_URL}/franqueados/responsavel/${params.franqueadoId}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  removeEnderecoResponsavel(index) {
    const responsavel = { ...this.state.responsavel }
    const enderecos = responsavel.enderecos
    const aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    responsavel.enderecos = aux
    this.setState({ responsavel })
  }

  removeContatoResponsavel(index) {
    const responsavel = { ...this.state.responsavel }
    const contatos = responsavel.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    responsavel.contatos = aux
    this.setState({ responsavel })
  }

  removeEmailResponsavel(index) {
    const responsavel = { ...this.state.responsavel }
    const emails = responsavel.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    responsavel.emails = aux
    this.setState({ responsavel })
  }

  render() {
    const { responsavel } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11} xs={10} sm={10}>
                  <h2 className="titulo">Atualização de Responsavel Franqueado</h2>
                </Grid>
                <Grid item md={1} xs={2} sm={2}>
                  {this.state.loadingSalvar &&
                    <CircularProgress />
                  }
                </Grid>
              </Grid>
              {this.state.enableDadosPessoa &&
                <div className="formFranqueado">
                  <FormPessoaFisica validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaFisica(e)} dados={responsavel.pessoaFisica} />
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={9}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEndereco &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Endereço</h3>
                    </Grid>
                  </Grid>
                  {responsavel.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco removerItem={(index) => this.removeEnderecoResponsavel(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEndereco(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldEnderecoPrincipal(e, endereco.index)} updateField={e => this.updateFieldEndereco(e, endereco.index)} dados={endereco} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Contato</h3>
                    </Grid>
                  </Grid>
                  {responsavel.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato formataTelefone={e => this.formataTelefone(e, contato.index)} removerItem={(index) => this.removeContatoResponsavel(index)} updateField={e => this.updateFieldContato(e, contato.index)} dados={contato} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => this.addNovoContato()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEmail &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de E-mail</h3>
                    </Grid>
                  </Grid>
                  {responsavel.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail removerItem={(index) => this.removeEmailResponsavel(index)} updateField={e => this.updateFieldEmail(e, email.index)} dados={email} updateFieldPrincipal={e => this.updateFieldEmailPrincipal(e, email.index)} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => this.addNovoEmail()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
              <br />
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}