import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'

const FormCargo = ({ departamentos, dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Departamento"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="car_departamento_id"
          value={dados.car_departamento_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={dados.car_departamento_id === "" ? "" : dados.car_departamento_id}> {dados.car_departamento_id === "" ? "Selecionar Departamento" : dados.dep_descricao} </option>
          {departamentos.map(departamento => {
            if (departamento._id !== dados.car_departamento_id) {
              return (
                <option key={departamento._id} value={departamento._id}> {departamento.dep_descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Cargo" variant="outlined" size="small" name="car_descricao" value={dados.car_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Comissionado"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="car_comissionado"
          value={dados.car_comissionado}
          onChangeCapture={(e) => updateField(e)}
        >
          <option key="Sim" value={true}>
            Sim
					</option>
          <option key="Não" value={false}>
            Não
					</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.car_ativo}
            />
          }
          label="Ativo"
          name="car_ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormCargo