import React, { Component } from 'react'
import './AreaCliente.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
import ConfirmarCPFCNPJ from './../../components/modals/ConfirmarCPFCNPJ'
import ModalExibeBoleto from './../../components/modals/ExibeBoleto'
import ModalViewContaCliente from './../../components/modals/ViewContaCliente'
import real from '../../services/real'
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ModalErro from './../../components/modals/Erro'
import decrypt from './../../services/crypto'
import Alert from './../../components/templates/Alert'

function renderClassFinanceiro(param) {
  if (param === 'Atrasado') {
    return  '#FFB6C1'
  } else if (param === 'A Pagar') {
    return  '#FFFFE0'
  } else if (param === 'Pago') {
    return '#98FB98'
  }
}

const initalState = {
  cabecalhoTabela: [
    // { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'data_vencimento', numeric: false, disablePadding: true, label: 'Data Vencimento', orderBy: 'data_vencimentoOrder' },
    // { id: 'tipo_conta', numeric: false, disablePadding: true, label: 'Tipo Conta' },
    // { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma Pagto.' },
    // { id: 'valor', numeric: false, disablePadding: true, label: 'Valor' },
    // { id: 'multajuros', numeric: false, disablePadding: true, label: 'Multa / Juros / Desc.' },
    { id: 'valor_total_form', numeric: true, disablePadding: true, label: 'Valor Total' },
    // { id: 'qtd_itens', numeric: true, disablePadding: true, label: 'Qtd. Itens' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Status' }
  ],
  acoesTabela: ['openConta', 'imprimirBoletoCliente'],
  contas: {
    list: []
  },
  openModalConfirmar: false,
  login: {
    cpf_cnpj: ''
  },
  pessoa: {
    pes_tipo: 'FISICA',
    fisica: {
      pesf_nome: ''
    },
    juridica: {
      pesj_razaosocial: ''
    },
    unidade_pertence: {
      descricao: ''
    }
  },
  mostrarBoletos: true,
  loading: false,
  openModalExibeBoleto: false,
  boletoSelecionado: {
    tipo_transaction: 'BOLETO',
    bank_slip: {
      url_slip_pdf: ''
    }
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  filtro: {
    data_inicial: "",
    data_final: "",
    situacao: "A VENCER E VENCIDO",
    data_por: "VENCIMENTO",
  },
  parcela: {
    baixado: "",
    datavencimento: "",
    valorparcela: '0,00',
    documento: "",
    num: "",
    motivo_cancelamento: "",
    parcela_editada: false,
    multajuros: '0,00',
    formas_pgto: [
      {
        id: '',
        formapagto_id: '',
        valor_pagamento: real(0),
        formapagto_descricao: '',
        formapagto_hab_caixa: false,
        contfin_id: '',
        contfin_descricao: '',
        contfin_tipo_conta: '',
        index: 0
      }
    ]
  },
  openModalViewContaCliente: false,
  dataSelecionada: {
    parcelas: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}
export default class BoletosCliente extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token_cliente')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    let {filtro} = this.state

    let data_inicial = moment().format('YYYY-MM')+'-01'
    filtro.data_final = moment(data_inicial).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')

    this.setState({
      filtro
    })

    const tempoConexao = JSON.parse(localStorage.getItem('tempoConexao'))

    let cliente = JSON.parse(localStorage.getItem('cliente'))

    const { match: { params } } = this.props;

    if (!cliente) {
      this.setState({
        openModalConfirmar: true
      })
    } else if (cliente.pes_id !== parseInt(params.pessoaId)) {
      this.setState({
        openModalConfirmar: true
      })
    } else if (tempoConexao) {
      if (new Date(tempoConexao).getTime() < new Date().getTime()) {

        this.setState({
          openModalConfirmar: true
        })
      } else if (!cliente) {
        this.setState({
          openModalConfirmar: true
        })
      } else {
        let cliente = JSON.parse(localStorage.getItem('cliente'))

        this.setState({
          pessoa: cliente,
          mostrarBoletos: true,
        })

        this.filtrarContas()
      }
    } else {
      const { match: { params } } = this.props;

      if (parseInt(params.pessoaId)) {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/pessoaExterno/${parseInt(params.pessoaId)}`, this.getToken())

          let pessoa = JSON.parse(decrypt(data))

          localStorage.setItem('cliente', JSON.stringify(pessoa))

          this.setState({
            pessoa,
            openModalConfirmar: true
          })
        } catch ({ response }) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }
      }
    }

    let novoTempoConexao = moment(new Date()).add(5, 'minutes')
    localStorage.setItem('tempoConexao', JSON.stringify(novoTempoConexao))
  }

  async confirmar() {
    const { login } = this.state

    try {
      const { match: { params } } = this.props;

      const dados = {
        cpf_cnpj: login.cpf_cnpj,
        pessoa_id: parseInt(params.pessoaId)
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/cliente/login`, dados)

      localStorage.setItem('token_cliente', data.token)

      this.filtrarContas()
      this.buscarDadosPessoa()

      this.setState({
        mostrarBoletos: true,
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: error.response.data.message
      })
      return
    }

  }

  async buscarDadosPessoa() {
    const { match: { params } } = this.props;
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/pessoaExterno/${parseInt(params.pessoaId)}`, this.getToken())

      let pessoa = JSON.parse(decrypt(data))

      localStorage.setItem('cliente', JSON.stringify(pessoa))

      this.setState({
        pessoa
      })
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async filtrarContas() {
    const { match: { params } } = this.props;
    this.setState({ loading: true })

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contasCliente/list/${parseInt(params.pessoaId)}`, this.state.filtro, this.getToken())

      let resp = JSON.parse(decrypt(data))

      this.setState({
        contas: {
          list: resp.map((value, key) => {
            return {
              index: key,
              descricao: value.descricao,
              aluno: value.aluno,
              data_vencimento: moment(value.data_vencimento).format('DD/MM/YYYY'),
              data_vencimento_sem_form: moment(value.data_vencimento).format('YYYY-MM-DD'),
              valor: 'R$ ' + real(value.valor_total),
              multajuros: 'R$ ' + real(value.multajuros),
              valor_total:value.valor_total + value.multajuros,
              valor_total_form: 'R$ ' + real(value.valor_total + value.multajuros),
              qtd_itens: value.parcelas.length,
              responsavel: value.responsavel,
              tipo_conta: value.tipo_conta === 'RECEITA' ? 'A Pagar' : 'A Receber',
              forma_pgto_slug: value.forma_pgto_slug,
              forma_pagto: value.forma_pagto,
              status: value.status,
              boleto_transaction_id: value.boleto_transaction_id,
              boleto_link: value.boleto_link,
              tipo_transaction: value.tipo_transaction,
              boleto_digitable_line: value.boleto_digitable_line,
              parcelas: value.parcelas,
              habilita_pix_boleto: value.habilita_pix_boleto
            }
          })
        },
        openModalConfirmar: false,
        mostrarBoletos: true,
        loading: false
      })
    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      // return
    }
  }

  updateField(event) {
    const { login } = this.state
    login[event.target.name] = event.target.value
    this.setState({ login })
  }

  async imprimirBoleto(row) {
    let {contas} = this.state

    if(row.forma_pgto_slug === 'pix'){
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Carregando PIX'
        }
      })

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pix/atualizaPix`,{parcela_id: row.parcelas[0].parcont_id}, this.getToken())

      contas.list[row.index].boleto_digitable_line = data.pix_code.emv
      contas.list[row.index].boleto_transaction_id = data.transaction_id
      contas.list[row.index].boleto_link = data.pix_code.qrcode_image_url
      contas.list[row.index].tipo_transaction = 'PIX'

      this.setState({
        boletoSelecionado:{
          tipo_transaction: 'PIX',
          bank_slip:{
            url_slip_pdf: data.pix_code.qrcode_image_url,
            boleto_digitable_line: data.pix_code.emv
          }
        },
        contas,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        },
        openModalExibeBoleto: true
      })

    }else{
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Carregando Boleto'
        }
      })

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/boleto/atualiza`,{parcela_id: row.parcelas[0].parcont_id}, this.getToken())

      contas.list[row.index].boleto_transaction_id = data.transaction_id
      contas.list[row.index].boleto_digitable_line = data.bank_slip.digitable_line
      contas.list[row.index].boleto_link = data.bank_slip.url_slip_pdf
      contas.list[row.index].tipo_transaction = 'BOLETO'

      this.setState({
        boletoSelecionado:{
          tipo_transaction: 'BOLETO',
          bank_slip:{
            url_slip_pdf: data.bank_slip.url_slip_pdf,
            boleto_digitable_line: data.bank_slip.digitable_line
          }
        },
        contas,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        },
        openModalExibeBoleto: true
      })
    }
  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.handleOpenDialog({
      titulo: 'Texto copiado',
      descricao: 'Texto copiado'
    })
  
    return
    
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleModalExibeBoleto() {
    this.setState({ openModalExibeBoleto: false })
  }

  handleCloseAlerta() {
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateFiltro(event) {
    const { filtro } = this.state

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })

  }

  async viewRow(row) {
    this.setState({
      dataSelecionada: row,
      openModalViewContaCliente: true
    })
  }

  handleModalViewContaCliente() {
    this.setState({
      openModalViewContaCliente: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { contas, mostrarBoletos, pessoa, filtro } = this.state

    return (
      <div className="container-boletos" id="app">
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Contas...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <React.Fragment>
            {mostrarBoletos &&
              <React.Fragment>
                <Grid container spacing={1} direction="row" className="borderBottom">
                  <Grid item md={6} xs={12} sm={6}>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={3} xs={3} sm={3}>
                        <img src="./../../../images/logo.png" alt="Logo British And American" />
                      </Grid>
                      <Grid item md={9} xs={9} sm={9}>
                        <p style={{ textAlign: 'left', marginBottom: '0px', marginLeft: 10 }}>Cliente: {pessoa.pes_tipo === 'FISICA' ? pessoa.fisica.pesf_nome : pessoa.juridica.pesj_razaosocial}</p>
                        {/* <p style={{ textAlign: 'left', marginBottom: '0px', marginLeft: 10 }}>Unidade: </p> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} sm={6}>
                    <h1 className="titulo" style={{ textAlign: 'right', textTransform: 'uppercase' }}>Minhas Contas</h1>
                  </Grid>
                </Grid>
                <Grid style={{ paddingBottom: '10px' }} container spacing={1} direction="row" className="borderBottom mg_top_20">
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" type="date" label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Situação"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="situacao"
                      value={filtro.situacao}
                      onChangeCapture={(e) => this.updateFiltro(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""></option>
                      <option value="A VENCER">A Pagar</option>
                      <option value="BAIXADO">Pago</option>
                      <option value="VENCIDO">Atrasado</option>
                      <option value="A VENCER E VENCIDO">Atrasado e A Pagar</option>
                    
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrarContas()}>
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  {contas.list.map((value, key) => {
                      return (
                        <Grid key={key} item md={3} xs={12} sm={4}>
                          <div
                            style={{
                              width: '96%',
                              marginLeft: '2%',
                              backgroundColor: renderClassFinanceiro(value.status),
                              height: '220px',
                              borderRadius: '10px',
                              overflow: 'hidden',
                              position: 'relative'
                            }}
                          >
                            <Grid container spacing={1} direction="row">
                              <Grid item md={12} xs={12} sm={12} style={{textTransform: 'uppercase'}}>
                                <b><p style={{fontSize: 12, margin: '5px 10px 5px 10px'}}>{value.descricao}</p></b>
                                <b><p style={{fontSize: 12, margin: '5px 10px 5px 10px'}}>{value.aluno}</p></b>
                                <b><p style={{fontSize: 14, margin: '5px 10px 5px 10px'}}>Vencimento: {value.data_vencimento}</p></b>
                                <b><p style={{fontSize: 14, margin: '5px 10px 5px 10px'}}>Valor: R$ <span>{real(value.valor_total)}</span></p></b>
                                <b><p style={{fontSize: 14, margin: '5px 10px 5px 10px'}}>Situação: {value.status}</p></b>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} direction="row" style={{height: '40px', backgroundColor: 'rgba(192,192,192,0.5)', position: 'absolute', bottom: '4px', paddingTop: '3px'}}>
                              <Grid item md={6} xs={6} sm={6} style={{textAlign: 'center'}}>
                                <Tooltip title='Visualizar Contas' aria-label="add">
                                  <IconButton onClick={e => this.viewRow(value)} color="primary" aria-label="view" style={{padding: '0px'}}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item md={6} xs={6} sm={6} style={{textAlign: 'center'}}>
                                <Tooltip title='Visualizar Boleto/Pix' aria-label="add">
                                  <IconButton onClick={e => this.imprimirBoleto(value)} color="secondary" aria-label="view" style={{padding: '0px'}} disabled={!value.habilita_pix_boleto}>
                                    <PrintIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      )
                  })}
                </Grid>
                <br />
                <hr />
              </React.Fragment>
            }
          </React.Fragment>
        }
        <ConfirmarCPFCNPJ
          open={this.state.openModalConfirmar}
          dados={this.state.login}
          confirmar={e => this.confirmar(e)}
          updateData={(e) => this.updateField(e)}
        />

        <ModalExibeBoleto
          open={this.state.openModalExibeBoleto}
          handleClose={e => this.handleModalExibeBoleto()}
          boletoSelecionado={this.state.boletoSelecionado}
          copyLink={e => this.copyLink(e)}
        />

        <ModalViewContaCliente
          open={this.state.openModalViewContaCliente}
          dados={this.state.dataSelecionada}
          handleClose={e => this.handleModalViewContaCliente()}
        />

        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />

        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />

      </div>
    )
  }
}