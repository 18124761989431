import React, { Component } from 'react'
import './Funcionario.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import CircularProgress from '@material-ui/core/CircularProgress';
import TabViews from './TabViews'
import ModalErro from './../../components/modals/Erro'
import axios from 'axios'

const initalState = {
  funcionario: {},
  loading: true,
  modalErro: false,
  unin_tipo: '',
  pessoa: {},
  contratos: {
    list: []
  },
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesPromover: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class View extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'funcionario')[0]

    if (permissoes) {
      this.setState({
        permissoes
      })
    }

    let permissoesPromover = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'promover_colaborador')[0]

    if (permissoesPromover) {
      this.setState({
        permissoesPromover
      })
    }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo,
      pessoa
    })

    const { match: { params } } = this.props;

    try {

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/funcionarios/${params.funcionarioId}`, this.getToken())

      this.setState({
        funcionario: resp.data
      })

    } catch ({response}) {
      // console.log(response.data.message)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })

      this.backPage()
    }

    try {

      const { data: contratos } = await axios.get(`${process.env.REACT_APP_API_URL}/funcionarios/contratos/${params.funcionarioId}`, this.getToken())

      this.setState({
        contratos: {
          list: contratos
        }
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })

      this.backPage()
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 4000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/funcionario");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { funcionario, permissoesPromover } = this.state
    const { match: { params } } = this.props;
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              {funcionario.fisica &&
                <TabViews permissoes={this.state.permissoes}
                  funcionario={funcionario}
                  unin_tipo={this.state.unin_tipo}
                  pessoa={this.state.pessoa}
                  contratos={this.state.contratos.list}
                  permissoesPromover={permissoesPromover}
                  params={params}
                  history={this.props.history}
                  props={this.props}
                />
              }
            </div>
          }
          <br />
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}