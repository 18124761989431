import React from 'react'

import { Grid, TextField } from '@material-ui/core'
import moment from 'moment';

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

const FormPessoaFisica = ({ form, dados, updateField, validarCpfCnpj, disabledDados, dadosDisabled }) => (
  <React.Fragment>
    <Grid container direction="row">
      <Grid item md={12} className="mg_top_10">
        <h5>Dados Pessoais - Pessoa Física</h5>
      </Grid>
    </Grid>

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={3}>
        <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={dados.nome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
        <span id={`error-pf-${form}-nome`} className="error">Informar o Nome</span>
      </Grid>
      <Grid item md={3} xs={12} sm={3}>
        <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={dados.sobrenome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          fullWidth
          className="input"
          type='date'
          name='dataNascimento'
          label="Data Nascimento"
          value={dados.dataNascimento}
          variant="outlined"
          size="small"
          onChange={(e) => updateField(e)}
          InputProps={{ inputProps: { max: dataHojeFormatado } }}
          InputLabelProps={{
            shrink: true,
          }} 
          disabled={disabledDados ? disabledDados(dadosDisabled) : false}
        />
        <span id={`error-pf-${form}-dataNascimento`} className="error">Informar Campo Data de Nascimento</span>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="CPF*" variant="outlined" size="small" name="cpf" value={dados.cpf} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} onBlur={e => validarCpfCnpj(e)} disabled={disabledDados ? (disabledDados(dadosDisabled) ? true : (dados.dataNascimento === '' ? true : false)) : false} />
        <span id={`error-pf-${form}-cpf`} className="error">Informar Campo CPF</span>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="RG*" variant="outlined" size="small" name="rg" value={dados.rg} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
        <span id={`error-pf-${form}-rg`} className="error">Informar Campo RG</span>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="UF Emissor"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="rg_uf"
          value={dados.rg_uf}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={disabledDados ? disabledDados(dadosDisabled) : false}
        >
          <option value="">Selecionar Estado</option>
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
        </TextField>
        <span id={`error-pf-${form}-rg_uf`} className="error">Selecionar UF Emissor</span>
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Orgão Emissor"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="rg_orgao_emissor"
          value={dados.rg_orgao_emissor}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={disabledDados ? disabledDados(dadosDisabled) : false}
        >
          <option value="">Selecionar Orgão Emissor</option>
          <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
          <option value="PM - Polícia Militar">PM - Polícia Militar</option>
          <option value="PC - Policia Civil">PC - Policia Civil</option>
          <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
          <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
          <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
          <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
          <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
          <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
          <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
          <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
          <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
          <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
          <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
          <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
          <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
          <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
          <option value="POF - Polícia Federal">POF - Polícia Federal</option>
          <option value="POM - Polícia Militar">POM - Polícia Militar</option>
          <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
          <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
          <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
          <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
        </TextField>
        <span id={`error-pf-${form}-rg_orgao_emissor`} className="error">Selecionar Orgão Emissor</span>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          fullWidth
          className="input"
          type='date'
          name='rg_data_emissao'
          label="Data de Emissão"
          value={dados.rg_data_emissao}
          variant="outlined"
          size="small"
          onChange={(e) => updateField(e)}
          InputProps={{ inputProps: { max: dataHojeFormatado } }}
          InputLabelProps={{
            shrink: true,
          }} 
          disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
        <span id={`error-pf-${form}-rg_data_emissao`} className="error">Informar Data de Emissão</span>
      </Grid>
    </Grid>
    <br />
    <hr />
  </React.Fragment>
)

export default FormPessoaFisica