import React, { Component } from 'react'
import './Documento.scss'
import './../../main/ultil.scss'

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button, TextField } from '@material-ui/core'

import FormDocumento from '../../components/forms/FormDocumento'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment'

import Table from '../../components/Table'

import { saveAs } from 'file-saver';

const initalState = {
  documento: {
    tempdoc_id: "",
    tempdoc_titulo: "",
    tempdoc_tipo: "",
    tempdoc_tempvar_id: "",
    tempdoc_assinar: false,
    tempver_id: "",
    tempver_versao: "",
    tempver_html: "",
    tempver_publicado: false,
    tempver_datainicio: "",
    vinculos: [],
    versoes: []
  },
  documentos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cursos: {
    list: []
  },
  cursosSelecionadas: [],
  templates: {
    list: []
  },
  templateSelecionado: {
    tempvar_descricao: '',
    tempvar_id: '',
    tempvar_tipo: '',
    variaveis: []
  },
  variaveisFiltrada: [],
  cabecalhoTabela: [
    { id: 'vardoc_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'vardoc_variavel', numeric: false, disablePadding: true, label: 'Variavel' }
  ],
  acoesTabela: ['selectVar'],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false,
  updateVersao: false,
  filtro: {
    vardoc_descricao: ''
  },
  term: '',
  congelado: false,
  editorState: EditorState.createEmpty()
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class CadastroDocumento extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    this.setState({
      documento: {
        tempdoc_id: "",
        tempdoc_titulo: "",
        tempdoc_tipo: "",
        tempdoc_tempvar_id: "",
        tempdoc_assinar: false,
        tempver_id: "",
        tempver_versao: "",
        tempver_html: "",
        tempver_publicado: false,
        tempver_datainicio: "",
        vinculos: [],
        versoes: []
      },
      templateSelecionado: {
        tempvar_descricao: '',
        tempvar_id: '',
        tempvar_tipo: '',
        variaveis: []
      }
    })

    try {
      const { data: cursos } = await axios.get(`${process.env.REACT_APP_API_URL}/curso`, this.getToken())
      const { data: templates } = await axios.get(`${process.env.REACT_APP_API_URL}/templatesvariaveis`, this.getToken())

      this.setState({
        cursos: {
          list: cursos.filter(param => param.curso_ativo === true).map(curso => {
            return {
              curso_id: curso.curso_id,
              curso_nome: curso.curso_nome,
              remove: false
            }
          })
        },
        templates: {
          list: templates
        },
      })

    } catch (error) {
      console.log(error);
    }

    const { match: { params } } = this.props;
    if (params.documentoId) {
      let { templates, templateSelecionado } = this.state
      try {
        const { data: documento } = await axios.get(`${process.env.REACT_APP_API_URL}/templatesdocumentos/${params.documentoId}`, this.getToken())
        templateSelecionado = templates.list.filter(param => param.tempvar_id === parseInt(documento.tempdoc_tempvar_id))[0]

        let variaveisFiltrada = templateSelecionado.variaveis

        variaveisFiltrada.push({
          vardoc_descricao: "Assinatura da Unidade e do Aluno/Responsável",
          vardoc_id: -1,
          vardoc_mapa: "assinatura",
          vardoc_model: "Aluno",
          vardoc_variavel: "ASSINATURA_UNIDADE_E_CLIENTE",
        })

        variaveisFiltrada.push({
          vardoc_descricao: "Assinatura da Unidade",
          vardoc_id: -2,
          vardoc_mapa: "assinatura",
          vardoc_model: "Aluno",
          vardoc_variavel: "ASSINATURA_UNIDADE_UNICA",
        })

        variaveisFiltrada.push({
          vardoc_descricao: "Assinatura do Aluno/Responsável",
          vardoc_id: -3,
          vardoc_mapa: "assinatura",
          vardoc_model: "Aluno",
          vardoc_variavel: "ASSINATURA_CLIENTE_UNICA",
        })

        this.setState({
          documento: {
            tempdoc_id: documento.tempdoc_id,
            tempdoc_titulo: documento.tempdoc_titulo,
            tempdoc_tipo: documento.tempdoc_tipo,
            tempdoc_tempvar_id: documento.tempdoc_tempvar_id,
            tempdoc_assinar: documento.tempdoc_assinar,
            tempver_versao: "",
            tempver_html: "",
            tempver_publicado: false,
            tempver_datainicio: "",
            tempver_id: "",
            vinculos: documento.cursos.map(curso => {
              return {
                curso_id: curso.curso_id,
                curso_nome: curso.curso_nome,
                remove: false
              }
            }),
            versoes: documento.versoes.map(versao => {
              return {
                _id: versao.tempver_id,
                tempver_id: versao.tempver_id,
                tempver_versao: versao.tempver_versao,
                tempver_html: versao.tempver_html,
                tempver_publicado: versao.tempver_publicado,
                tempver_ativo: versao.tempver_ativo,
                tempver_congelado: versao.tempver_congelado,
                tempver_datainicio: moment(versao.tempver_datainicio).format('DD/MM/YYYY'),
                tempver_datainicio_sem_form: versao.tempver_datainicio,
                tempver_tempdoc_id: versao.tempver_tempdoc_id,
              }
            })
          },
          update: true,
          templateSelecionado,
          variaveisFiltrada,
          cursosSelecionadas: documento.cursos.map(curso => {
            return {
              curso_id: curso.curso_id,
              curso_nome: curso.curso_nome,
              remove: false
            }
          })
        })

        for (const versao of documento.versoes) {
          if (versao.tempver_congelado) {
            this.setState({ congelado: true })
          }
        }

      } catch (error) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o documento, tente mais tarde"
        })

        this.backPage()
      }
    }

    this.setState({ loading: false })
  }

  updateField(event) {
    let { documento, templateSelecionado, templates } = this.state

    if (event.target.name === 'tempdoc_tempvar_id') {
      if (event.target.value !== '') {
        templateSelecionado = templates.list.filter(param => param.tempvar_id === parseInt(event.target.value))[0]
      } else {
        templateSelecionado = {
          tempvar_descricao: '',
          tempvar_id: '',
          tempvar_tipo: '',
          variaveis: []
        }
      }

    }

    documento[event.target.name] = event.target.value

    let variaveisFiltrada = templateSelecionado.variaveis

    variaveisFiltrada.push({
      vardoc_descricao: "Assinatura da Unidade e do Aluno/Responsável",
      vardoc_id: -1,
      vardoc_mapa: "assinatura",
      vardoc_model: "Aluno",
      vardoc_variavel: "ASSINATURA_UNIDADE_E_CLIENTE",
    })

    variaveisFiltrada.push({
      vardoc_descricao: "Assinatura da Unidade",
      vardoc_id: -2,
      vardoc_mapa: "assinatura",
      vardoc_model: "Aluno",
      vardoc_variavel: "ASSINATURA_UNIDADE_UNICA",
    })

    variaveisFiltrada.push({
      vardoc_descricao: "Assinatura do Aluno/Responsável",
      vardoc_id: -3,
      vardoc_mapa: "assinatura",
      vardoc_model: "Aluno",
      vardoc_variavel: "ASSINATURA_CLIENTE_UNICA",
    })

    this.setState({
      documento,
      templateSelecionado,
      variaveisFiltrada
    })
  }

  updateFieldHtml(data) {
    const documento = { ...this.state.documento }
    documento.tempver_html = data
    this.setState({ documento })
  }

  updateFieldCurso(event, value) {
    this.setState({
      cursosSelecionadas: value
    })
  }

  updateFieldPublicado() {
    const documento = { ...this.state.documento }
    documento.tempver_publicado = !documento.tempver_publicado

    if(documento.tempver_publicado){
      documento.tempver_datainicio = moment(new Date()).format('YYYY-MM-DD')
    }else{
      documento.tempver_datainicio = ''
    }

    this.setState({ documento })
  }

  editarVersao(value) {
    if (value.tempver_congelado || !value.tempver_ativo) {
      this.handleOpenDialog({
        titulo: 'Ops!',
        descricao: 'Só é possível editar Versões Ativas e não congeladas'
      })
      return false
    }

    const contentBlock = htmlToDraft(value.tempver_html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({
      documento: {
        tempdoc_id: this.state.documento.tempdoc_id,
        tempdoc_titulo: this.state.documento.tempdoc_titulo,
        tempdoc_tipo: this.state.documento.tempdoc_tipo,
        tempdoc_tempvar_id: this.state.documento.tempdoc_tempvar_id,
        tempdoc_assinar: this.state.documento.tempdoc_assinar,
        tempver_versao: value.tempver_versao,
        tempver_html: value.tempver_html,
        tempver_publicado: value.tempver_publicado,
        tempver_datainicio: moment(value.tempver_datainicio_sem_form).format('YYYY-MM-DD'),
        tempver_id: value.tempver_id,
        vinculos: this.state.documento.vinculos,
        versoes: this.state.documento.versoes,
      },
      update: true,
      updateVersao: true,
      editorState,
    })
  }

  copiarVersao(value) {

    const contentBlock = htmlToDraft(value.tempver_html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({
      documento: {
        tempdoc_id: this.state.documento.tempdoc_id,
        tempdoc_titulo: this.state.documento.tempdoc_titulo,
        tempdoc_tipo: this.state.documento.tempdoc_tipo,
        tempdoc_tempvar_id: this.state.documento.tempdoc_tempvar_id,
        tempdoc_assinar: this.state.documento.tempdoc_assinar,
        tempver_versao: "",
        tempver_html: value.tempver_html,
        tempver_publicado: false,
        tempver_datainicio: "",
        tempver_id: "",
        vinculos: this.state.documento.vinculos,
        versoes: this.state.documento.versoes,
      },
      update: true,
      updateVersao: false,
      editorState
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/documento");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { documento, cursosSelecionadas } = this.state

    if (!documento.tempdoc_titulo) return false
    if (!documento.tempdoc_tipo) return false
    if (!documento.tempdoc_tempvar_id) return false

    if(!this.state.update){
      if (!documento.tempver_versao) return false
      // if (!documento.tempver_html) return false
    }

    if (cursosSelecionadas.length === 0) return false

    // if (documento.vinculos.length === 0) return false

    return true
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const variaveis = this.state.templateSelecionado.variaveis
    const filtro = this.state.filtro

    const list = variaveis.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      variaveisFiltrada: list,
      filtro
    })
  }

  selecionarVar(variavel) {
    let documento = { ...this.state.documento }
    documento.tempver_html = documento.tempver_html.replace('{%}', `%%${variavel.vardoc_variavel}`)

    const contentBlock = htmlToDraft(documento.tempver_html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({ documento, editorState })
  }

  async salvar() {
    const { editorState} = this.state

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const documento = this.state.documento
    const cursosSelecionadas = this.state.cursosSelecionadas

    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    html = html.replaceAll(`<p style="text-align:start;"></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:left;"></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:justify;"></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:justify;">&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:start;">&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:left;">&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p>&nbsp;</p>`, "<br/>")
    html = html.replaceAll(`<p></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:left;"><br></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:justify;"><br></p>`, "<br/>")
    html = html.replaceAll(`<p style="text-align:start;"><br></p>`, "<br/>")

    const dadosSalvar = {
      tempdoc_titulo: documento.tempdoc_titulo,
      tempdoc_tipo: documento.tempdoc_tipo,
      tempdoc_tempvar_id: documento.tempdoc_tempvar_id,
      tempdoc_assinar: documento.tempdoc_assinar,
      tempver_versao: documento.tempver_versao,
      tempver_html: html,
      tempver_publicado: documento.tempver_publicado,
      tempver_datainicio: documento.tempver_datainicio,
      vinculos: cursosSelecionadas
    }

    if(dadosSalvar.tempver_publicado === '' || !dadosSalvar.tempver_publicado){
      delete dadosSalvar.tempver_datainicio
    }
    
    const dadosUpdate = {
      tempdoc_titulo: documento.tempdoc_titulo,
      tempdoc_tempvar_id: documento.tempdoc_tempvar_id,
      tempdoc_tipo: documento.tempdoc_tipo,
      tempdoc_assinar: documento.tempdoc_assinar,
      vinculos: cursosSelecionadas,
      versao: {
        tempver_id: documento.tempver_id,
        tempver_versao: documento.tempver_versao,
        tempver_html: html,
        nova_versao: !this.state.updateVersao,
        tempver_publicado: documento.tempver_publicado,
        tempver_datainicio: documento.tempver_datainicio
      }
    }

    console.log(dadosUpdate.versao.tempver_html)

    if(dadosUpdate.versao.tempver_publicado === '' || !dadosUpdate.versao.tempver_publicado){
      delete dadosUpdate.versao.tempver_datainicio
    }

    if (!this.state.updateVersao) {
      delete dadosUpdate.versao.tempver_id
    }

    try {

      const method = documento.tempdoc_id !== "" ? 'put' : 'post'
      const url = documento.tempdoc_id !== "" ? `/templatesdocumentos/${documento.tempdoc_id}` : `/templatesdocumentos`
      const dados = documento.tempdoc_id !== "" ? dadosUpdate : dadosSalvar

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  async imprimirDoc(value){
    const documento = this.state.documento

    // value.tempver_html = `<body style='zoom : 0.75'> 
    //   <div style="font-size: 16px; font-family: 'Times New Roman', serif"> ${value.tempver_html} </div>
    // </body>`

    value.tempver_html = value.tempver_html.replace(/¬/g, ` &nbsp`)

    value.tempver_html = `<html>
      <head>
        <style>
          p{
            margin: 10px 0;
          }
        </style>
      </head>
      <body>
        ${value.tempver_html}
      </body>
    </html>`

    value.tempdoc_titulo = documento.tempdoc_titulo

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/templatesdocumentos/imprimirView`, value, this.getToken())

      console.log(data)

      const dadosImprimir = {
        path: data
      }
      
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, `${documento.tempdoc_titulo}-${value.tempver_versao}.pdf`);

    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  onEditorStateChange(editorState){
    const {documento } = this.state

    documento.tempver_html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    this.setState({editorState, documento})
  } 

  updateFieldAssinarDocumento(){
    let {documento} = this.state

    documento.tempdoc_assinar = !documento.tempdoc_assinar
    this.setState({documento})
  }

  render() {
    let { documento, filtro, editorState } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Documentos</h2>
                </Grid>
              </Grid>
              <FormDocumento
                updateField={e => this.updateField(e)}
                dados={documento}
                cursos={this.state.cursos.list}
                templates={this.state.templates.list}
                updateFieldCurso={(event, value) => this.updateFieldCurso(event, value)}
                updateFieldHtml={e => this.updateFieldHtml(e)}
                updateFieldPublicado={e => this.updateFieldPublicado(e)}
                update={this.state.update}
                updateVersao={this.state.updateVersao}
                editarVersao={e => this.editarVersao(e)}
                copiarVersao={e => this.copiarVersao(e)}
                congelado={this.state.congelado}
                cursosSelecionadas={this.state.cursosSelecionadas}
                imprimirDoc={e => this.imprimirDoc(e)}
                updateFieldAssinarDocumento={() => this.updateFieldAssinarDocumento()}
              />

              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="vardoc_descricao" value={filtro.vardoc_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>

              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table
                    headCell={this.state.cabecalhoTabela}
                    rows={this.state.variaveisFiltrada}
                    acoes={this.state.acoesTabela}
                    selecionarVar={e => this.selecionarVar(e)} />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <div className="cardEditor">
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={e => this.onEditorStateChange(e)}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9} xs={12} sm={8}></Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
              <br />
              <br />
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}
