import React, { Component } from 'react'
import './Perfil.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'

import { Grid, Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  perfil: {},
  perfis: {
    list: []
  },
  perfisFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'per_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'per_visibilidade', numeric: false, disablePadding: true, label: 'Visibilidade' },
    { id: 'criado', numeric: false, disablePadding: true, label: 'Unidade Responsável' },
    { id: 'per_status', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  loading: true,
  unin_tipo: "",
  filtro: {
    per_descricao: "",
    per_visibilidade: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  idUnidadeTrabalho: ''
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Perfil extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'perfil')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    const idUnidadeTrabalho = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_id

    this.setState({
      idUnidadeTrabalho
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    axios.post(`${process.env.REACT_APP_API_URL}/perfis/list`, dados, this.getToken())
      .then(resp => {

        const perfis = resp.data.map(perfil => {
          let per_visibilidade = ''

          if (perfil.per_visibilidade === 'FRANQUEADOR') {
            per_visibilidade = 'Franqueador'
          } else if (perfil.per_visibilidade === 'FRANQUEADO') {
            per_visibilidade = 'Franqueado'
          } else if (perfil.per_visibilidade === 'UNIDADE') {
            per_visibilidade = 'Unidade'
          }

          let criado = ''

          if (perfil.dono.unin_tipo === 'FRANQUEADOR') {
            criado = 'Franqueador'
          } else if (perfil.dono.unin_tipo === 'FRANQUEADO') {
            criado = 'Franqueado'
          } else if (perfil.dono.unin_tipo === 'UNIDADE') {
            criado = 'Unidade'
          }

          return {
            _id: perfil.per_id,
            per_descricao: perfil.per_descricao,
            per_visibilidade,
            per_status: perfil.per_status,
            dono_unin_id: perfil.dono.unin_id,
            criado
          }
        })

        this.setState({
          perfis: {
            list: perfis
          },
          perfisFiltrado: {
            list: perfis
          },
          loading: false
        })

      }).catch(error => {
        console.log(error)
        this.setState({
          modalErro: true,
          msgErro: error,
        })
      })

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const perfis = this.state.perfis.list
    const filtro = this.state.filtro

    const list = perfis.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      perfisFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, perfisFiltrado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Perfis</h1>
                </Grid>
                {['FRANQUEADO', 'FRANQUEADOR'].includes(this.state.unin_tipo) &&
                  <Grid item md={4} xs={12}>
                    <Link to="/perfil/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Perfil
								      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Perfil" variant="outlined" size="small" name="per_descricao" value={filtro.per_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Visibilidade" variant="outlined" size="small" name="per_visibilidade" value={filtro.per_visibilidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table
                    urlUpdate="/perfil/cadastro/"
                    urlView="/perfil/cadastro/"
                    headCell={this.state.cabecalhoTabela}
                    rows={perfisFiltrado.list}
                    acoes={this.state.acoesTabela}
                    idUnidadeTrabalho={this.state.idUnidadeTrabalho}
                    tela="Perfil"
                  />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}