import React, { Component } from 'react'
import './FlyHigh.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormPontosFlyHigh from '../../components/forms/FormPontosFlyHigh'
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'

import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const initalState = {
  fly_high: {
    pfh_id: '',
    pfh_nome: '',
    pfh_descricao: '',
    pfh_data_inicio: '',
    pfh_data_fim: '',
    cursos: [],
    produtos: [],
  },
  curso: {
    fhc_id: '',
    fhc_curso_id: '',
    curso_nome: '',
    fhc_ponto_matricula: '',
    fhc_ponto_rematricula: '',
    fhc_ponto_prorrogacao: '',
    index: ''
  },
  produto: {
    fhp_id: '',
    fhp_prod_id: '',
    prod_descricao: '',
    fhp_ponto: '',
    index: ''
  },
  cursos: {
    list: []
  },
  cursosRemovidos: {
    list: []
  },
  produtos: {
    list: []
  },
  produtosRemovidos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  update: false,
  updateProduto: false,
  editorState: EditorState.createEmpty(),
  loading: true
}

export default class CadastroFlyHigh extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    this.setState({
      fly_high: {
        pfh_id: '',
        pfh_nome: '',
        pfh_descricao: '',
        pfh_data_inicio: '',
        pfh_data_fim: '',
        cursos: [],
        produtos: [],
      },
      curso: {
        fhc_id: '',
        fhc_curso_id: '',
        curso_nome: '',
        fhc_ponto_matricula: '',
        fhc_ponto_rematricula: '',
        fhc_ponto_prorrogacao: '',
        index: ''
      },
      produto: {
        fhp_id: '',
        fhp_prod_id: '',
        prod_descricao: '',
        fhp_ponto: '',
        index: ''
      },
    })

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'funcao')[0]

    this.setState({
      permissoes
    })

    if (params.funcaoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/curso`, this.getToken())
      const cursos = []

      data.filter(param => param.curso_ativo).forEach(curso => {
        cursos.push({
          curso_id: curso.curso_id,
          curso_nome: curso.curso_nome
        })
      })

      this.setState({
        cursos: {
          list: cursos
        }
      })

    } catch (error) {
      console.log(error);
    }

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/listBooks`, this.getToken())

      const produtos = []

      resp.data.filter(param => param.prod_ativo).forEach(produto => {
        produtos.push({
          prod_id: produto.prod_id,
          prod_descricao: produto.prod_descricao,
          unidadeMedida: produto.prod_unidademedida
        })
      })

      this.setState({
        produtos: {
          list: produtos
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    if (params.flyHighId) {
      try {
        const { data: fly_high } = await axios.get(`${process.env.REACT_APP_API_URL}/flyHigh/${params.flyHighId}`, this.getToken())

        let cursosList = this.state.cursos.list
        let produtosList = this.state.produtos.list
      
        const contentBlock = htmlToDraft(fly_high.pfh_descricao);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        this.setState({
          fly_high: {
            pfh_id: fly_high.pfh_id,
            pfh_nome: fly_high.pfh_nome,
            pfh_descricao: fly_high.pfh_descricao,
            pfh_data_inicio: fly_high.pfh_data_inicio,
            pfh_data_fim: fly_high.pfh_data_fim,
            cursos: fly_high.pontos_curso.map((value, key) =>{
              const indexCurso = cursosList.findIndex(param => param.curso_id === value.fhc_curso_id)

              if (indexCurso !== -1) {
                var cursoRemovido = cursosList[indexCurso]
    
                var cursosRemovidos = this.state.cursosRemovidos.list
    
                cursosRemovidos.push(cursoRemovido)
    
                cursosList.splice(indexCurso, 1)
    
                this.setState({
                  cursos: {
                    list: cursosList
                  },
                  cursosRemovidos: {
                    list: cursosRemovidos
                  }
                })
              }

              return {
                fhc_id: value.fhc_id,
                fhc_curso_id: value.fhc_curso_id,
                curso_nome: value.curso.curso_nome,
                fhc_ponto_matricula: value.fhc_ponto_matricula,
                fhc_ponto_rematricula: value.fhc_ponto_rematricula,
                fhc_ponto_prorrogacao: value.fhc_ponto_prorrogacao,
                index: key
              }
            }),
            produtos: fly_high.pontos_produto.map((value, key) => {
              const indexProduto = produtosList.findIndex(param => param.prod_id === value.fhp_prod_id)

              if (indexProduto !== -1) {
                var produtoRemovido = produtosList[indexProduto]
    
                var produtosRemovidos = this.state.produtosRemovidos.list
    
                produtosRemovidos.push(produtoRemovido)
    
                produtosList.splice(indexProduto, 1)
    
                this.setState({
                  produtos: {
                    list: produtosList
                  },
                  produtosRemovidos: {
                    list: produtosRemovidos
                  }
                })
              }

              return {
                fhp_id: value.fhp_id,
                fhp_prod_id: value.fhp_prod_id,
                prod_descricao: value.produto.prod_descricao,
                fhp_ponto: value.fhp_ponto,
                index: key
              }
            }),
          },
          editorState,
          loading: false
        })


      } catch (error) {
        console.log(error);
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar a função, tente mais tarde"
        })

        this.backPage()
      }

    }
  
  }

  updateField(event) {
    const fly_high = { ...this.state.fly_high }
    fly_high[event.target.name] = event.target.value
    this.setState({ fly_high })
  }

  updateFieldCurso(event){
    const {curso, cursos} = this.state
    if(event.target.name === 'fhc_curso_id'){
      const cursoSelecionado = cursos.list.filter(param => param.curso_id === parseInt(event.target.value))[0]
      curso.fhc_curso_id = cursoSelecionado.curso_id
      curso.curso_nome = cursoSelecionado.curso_nome
    }else{
      curso[event.target.name] = event.target.value
    }

    this.setState({ curso })
  }

  validateCurso() {
    const { curso } = this.state

    if (curso.fhc_curso_id === '') return false
    if (curso.fhc_ponto_matricula === '') return false
    if (curso.fhc_ponto_rematricula === '') return false
    if (curso.fhc_ponto_prorrogacao === '') return false

    return true
  }

  addItemCurso(){
    if (!this.validateCurso()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const fly_high = this.state.fly_high
    const curso = this.state.curso
    const cursos = this.state.cursos.list

    const itens = fly_high.cursos

    if (curso.index !== '') {
      itens[curso.index] = {
        fhc_id: curso.fhc_id,
        fhc_curso_id: curso.fhc_curso_id,
        curso_nome: curso.curso_nome,
        fhc_ponto_matricula: curso.fhc_ponto_matricula,
        fhc_ponto_rematricula: curso.fhc_ponto_rematricula,
        fhc_ponto_prorrogacao: curso.fhc_ponto_prorrogacao,
        index: curso.index
      }
    }else{
      itens.push({
        fhc_id: curso.fhc_id,
        fhc_curso_id: curso.fhc_curso_id,
        curso_nome: curso.curso_nome,
        fhc_ponto_matricula: curso.fhc_ponto_matricula,
        fhc_ponto_rematricula: curso.fhc_ponto_rematricula,
        fhc_ponto_prorrogacao: curso.fhc_ponto_prorrogacao,
        index: itens.length
      })
    }

    fly_high.cursos = itens

    this.setState({
      fly_high,
      curso:  {
        fhc_id: '',
        fhc_curso_id: '',
        curso_nome: '',
        fhc_ponto_matricula: '',
        fhc_ponto_rematricula: '',
        fhc_ponto_prorrogacao: '',
        index: ''
      }
    })

    const indexCurso = cursos.findIndex(param => param.curso_id === curso.fhc_curso_id)
  
    if (indexCurso !== -1) {
      var cursoRemovido = cursos[indexCurso]

      var cursosRemovidos = this.state.cursosRemovidos.list
      cursosRemovidos.push(cursoRemovido)
  
      cursos.splice(indexCurso, 1)
  
      this.setState({
        cursos: {
          list: cursos
        },
        cursosRemovidos: {
          list: cursosRemovidos
        }
      })
    }
    
  }

  removerItemCurso(index) {
    const fly_high = this.state.fly_high
    const cursosRemovidos = this.state.cursosRemovidos.list
    const cursos = this.state.cursos.list

    let itens = fly_high.cursos

    const indexProdutoRemovido = cursosRemovidos.findIndex(param => param.curso_id === itens[index].fhc_curso_id)

    const produtoAdd = cursosRemovidos[indexProdutoRemovido]

    cursos.push(produtoAdd)

    cursosRemovidos.splice(indexProdutoRemovido - 1, 1)

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        fhc_id: itens[i].fhc_id,
        fhc_curso_id: itens[i].fhc_curso_id,
        curso_nome: itens[i].curso_nome,
        fhc_ponto_matricula: itens[i].fhc_ponto_matricula,
        fhc_ponto_rematricula: itens[i].fhc_ponto_rematricula,
        fhc_ponto_prorrogacao: itens[i].fhc_ponto_prorrogacao,
        index: i
      })
    }

    itens = aux

    fly_high.itens = itens

    this.setState({
      fly_high,
      cursosRemovidos: {
        list: cursosRemovidos
      },
      cursos: {
        list: cursos
      }
    })
  }

  editarItemCurso(index) {
    const aux = this.state.fly_high.cursos.filter(param => param.index === index)

    this.setState({
      update: true,
      curso: aux[0]
    })
  }

  updateFieldProduto(event){
    const {produto, produtos} = this.state
    if(event.target.name === 'fhp_prod_id'){
      const produtoSelecionado = produtos.list.filter(param => param.prod_id === parseInt(event.target.value))[0]
      produto.fhp_prod_id = produtoSelecionado.prod_id
      produto.prod_descricao = produtoSelecionado.prod_descricao
    }else{
      produto[event.target.name] = event.target.value
    }

    this.setState({ produto })
  }

  validateProduto() {
    const { produto } = this.state

    if (produto.fhp_prod_id === '') return false
    if (produto.fhp_ponto === '') return false

    return true
  }

  addItemProduto(){
    if (!this.validateProduto()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const fly_high = this.state.fly_high
    const produto = this.state.produto
    const produtos = this.state.produtos.list

    const itens = fly_high.produtos

    if (produto.index !== '') {
      itens[produto.index] = {
        fhp_id: produto.fhp_id,
        fhp_prod_id: produto.fhp_prod_id,
        prod_descricao: produto.prod_descricao,
        fhp_ponto: produto.fhp_ponto,
        index: produto.index
      }
    }else{
      itens.push({
        fhp_id: produto.fhp_id,
        fhp_prod_id: produto.fhp_prod_id,
        prod_descricao: produto.prod_descricao,
        fhp_ponto: produto.fhp_ponto,
        index: itens.length
      })
    }

    fly_high.produtos = itens

    this.setState({
      fly_high,
      produto:  {
        fhp_id: '',
        fhp_prod_id: '',
        prod_descricao: '',
        fhp_ponto: '',
        index: ''
      }
    })

    const indexProduto = produtos.findIndex(param => param.produto_id === produto.fhc_produto_id)
  
    if (indexProduto !== -1) {
      var produtoRemovido = produtos[indexProduto]

      var produtosRemovidos = this.state.produtosRemovidos.list
      produtosRemovidos.push(produtoRemovido)
  
      produtos.splice(indexProduto, 1)
  
      this.setState({
        produtos: {
          list: produtos
        },
        produtosRemovidos: {
          list: produtosRemovidos
        }
      })
    }
    
  }

  removerItemProduto(index) {
    const fly_high = this.state.fly_high
    const produtosRemovidos = this.state.produtosRemovidos.list
    const produtos = this.state.produtos.list

    let itens = fly_high.produtos

    const indexProdutoRemovido = produtosRemovidos.findIndex(param => param.produto_id === itens[index].fhc_produto_id)

    const produtoAdd = produtosRemovidos[indexProdutoRemovido]

    produtos.push(produtoAdd)

    produtosRemovidos.splice(indexProdutoRemovido - 1, 1)

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        fhp_id: itens[i].fhp_id,
        fhp_prod_id: itens[i].fhp_prod_id,
        prod_descricao: itens[i].prod_descricao,
        fhp_ponto: itens[i].fhp_ponto,
        index: i
      })
    }

    itens = aux

    fly_high.itens = itens

    this.setState({
      fly_high,
      produtosRemovidos: {
        list: produtosRemovidos
      },
      produtos: {
        list: produtos
      }
    })
  }

  editarItemProduto(index) {
    const aux = this.state.fly_high.produtos.filter(param => param.index === index)

    this.setState({
      updateProduto: true,
      produto: aux[0]
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/pontos_fly_high");
    }, timeout)
    this.setState({
      fly_high: initalState.fly_high
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { fly_high } = this.state

    if (fly_high.pfh_nome === '') return false
    if (fly_high.pfh_data_inicio === '') return false
    if (fly_high.pfh_data_fim === '') return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const fly_high = this.state.fly_high

    const dados = {
      pfh_nome: fly_high.pfh_nome,
      pfh_descricao: fly_high.pfh_descricao,
      pfh_data_inicio: fly_high.pfh_data_inicio,
      pfh_data_fim: fly_high.pfh_data_fim,
      cursos: fly_high.cursos,
      produtos: fly_high.produtos
    }

    if(dados.pfh_descricao === ''){
      delete dados.pfh_descricao
    }

    if(dados.cursos.length === 0){
      delete dados.cursos
    }

    if(dados.produtos.length === 0){
      delete dados.produtos
    }

    try {

      const method = fly_high.pfh_id !== "" ? 'put' : 'post'
      const url = fly_high.pfh_id !== "" ? `/flyHigh/${fly_high.pfh_id}` : `/flyHigh`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  onEditorStateChange(editorState){
    const {fly_high } = this.state

    fly_high.pfh_descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    this.setState({editorState, fly_high})
  } 

  render() {
    const { fly_high, curso, cursos, update, produto, produtos, updateProduto } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
        {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Cadastro...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Pontos Fly High</h2>
                </Grid>
              </Grid>
              <FormPontosFlyHigh 
                updateField={e => this.updateField(e)} 
                updateFieldCurso={e => this.updateFieldCurso(e)} 
                addItemCurso={e => this.addItemCurso(e)} 
                removerItemCurso={e => this.removerItemCurso(e)} 
                editarItemCurso={e => this.editarItemCurso(e)}
                dados={fly_high}
                curso={curso}
                cursos={cursos.list}
                produto={produto}
                produtos={produtos.list}
                update={update}
                updateFieldProduto={e => this.updateFieldProduto(e)} 
                addItemProduto={e => this.addItemProduto(e)} 
                removerItemProduto={e => this.removerItemProduto(e)} 
                editarItemProduto={e => this.editarItemProduto(e)}
                updateProduto={updateProduto}
                editorState={this.state.editorState}
                onEditorStateChange={e => this.onEditorStateChange(e)}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }

          
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}