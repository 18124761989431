import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core'

const FormCargo = ({ unidades, dados, updateField, unidadesSelecionadas, updateFieldUnidade, unin_tipo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="fer_data" value={dados.fer_data} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Descrição*" variant="outlined" size="small" name="fer_descricao" value={dados.fer_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        {unin_tipo === 'FRANQUEADOR' &&
          <TextField className="input" label="Tipo Feriado*" variant="outlined" size="small" name="fer_tipo" value='NACIONAL' onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled />
        }
        {unin_tipo === 'FRANQUEADO' &&
          <TextField
            id="standard-select-currency"
            select
            label="Tipo Feriado*"
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="fer_tipo"
            value={dados.fer_tipo}
            onChangeCapture={(e) => updateField(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value=''>Selecionar Tipo Feriado</option>
            <option value='ESTADUAL'>
              ESTADUAL
            </option>
            <option value='MUNICIPAL'>
              MUNICIPAL
            </option>
          </TextField>
        }
      </Grid>
    </Grid>
    {unin_tipo === 'FRANQUEADO' &&
      <Grid container direction="row" spacing={1} className="mg_top_20">
        <Grid item md={12} xs={12} sm={12}>
          <Autocomplete
            multiple
            limitTags={10}
            id="multiple-limit-tags"
            options={unidades.filter(unidade => {
              if (unidadesSelecionadas.findIndex(i => i.unin_id === unidade.unin_id) === -1){
                return unidade;
              }
              return false
            })}
            size="small"
            value={unidadesSelecionadas}
            onChange={(event, value) => updateFieldUnidade(event, value)}
            getOptionLabel={(option) => option.unin_descricao}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Unidades" />
            )}
          />
        </Grid>
      </Grid>
    }
  </React.Fragment>
)

export default FormCargo