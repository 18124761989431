import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, Button } from '@material-ui/core'
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';

import Table from './../Table'
import real from './../../services/real'

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

const verificaDisabledCampos = (dados) => {
  if (dados.parcont_situacao === 'BAIXADO' || dados.parcont_situacao === 'CANCELADO' || dados.parcont_situacao === 'ESTORNADO') {
    return true
  }

  return false
}

const verificaDisabledCamposValor = (dados) => {
  if(dados.plancontas_slug === 'mensalidade' || dados.plancontas_slug === 'matricula'){
    return true
  }

  return false
}

const cabecalhoTabela = [
  { id: 'valpag_valor_pagamento', numeric: false, disablePadding: true, label: 'Valor Pagamento' },
  { id: 'formpag_descricao', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
  { id: 'contfin_descricao', numeric: false, disablePadding: true, label: 'Conta Financeira' }
]

const acoesTabela = ['editar', 'remove']

const FormParcelaConta = ({ dados, updateField, planoContas, removeFormaPagto, updateForma,
  contasFinanceira, formasPagamento, logado, perfilLogado, addFormaPagto, updateFieldFormaPgto, verificaData, forma_pgto, editarFormaPgto }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={4}>
        <TextField className="input" label="Cadastrado em" variant="outlined" size="small" name="parcont_criado_em" value={dados.parcont_criado_em} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={6} xs={12} sm={4}>
        <TextField className="input" label="Cadastrado por" variant="outlined" size="small" name="criador" value={dados.criador} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField className="input" label="Situação" variant="outlined" size="small" name="parcont_situacao" value={dados.parcont_situacao} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={4}>
        <TextField className="input" label="Plano de contas*" variant="outlined" size="small" name="parcont_situacao" value={`${planoContas.plancontas_codigo} - ${planoContas.plancontas_descricao}`} InputLabelProps={{ shrink: true }} disabled />
        {/* <Autocomplete
          onChange={(event, value) => updateFieldPlano(event, value)}
          freeSolo
          options={planosConta}
          value={planoContas}
          getOptionLabel={option => `${option.plancontas_codigo} - ${option.plancontas_descricao}`}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Plano de contas*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${planoContas.plancontas_codigo} - ${planoContas.plancontas_descricao}`} disabled={verificaDisabledCampos(dados)} disabled={logado.caixa} />
          )}
        /> */}
      </Grid>
      <Grid item md={7} xs={12} sm={5}>
        <TextField className="input" label="Descricão*" variant="outlined" size="small" name="contpr_descricao" value={dados.contpr_descricao} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados) ? true : logado.caixa} />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField className="input" label="Parcela" variant="outlined" size="small" name="parcont_num" value={`${dados.parcont_num}/${dados.contpr_numparcela}`} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados) ? true : logado.caixa} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={6}>
        {verificaDisabledCampos(dados) &&
          <TextField type="date" className="input" label="Data Vencimento*" variant="outlined" size="small" name="parcont_datavencimento" value={dados.parcont_datavencimento} onBlur={(event) => verificaData(event)} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: dataHojeFormatado } }} disabled />
        }
        {!verificaDisabledCampos(dados) &&
          <TextField type="date" className="input" label="Data Vencimento*" variant="outlined" size="small" name="parcont_datavencimento" value={dados.parcont_datavencimento} onBlur={(event) => verificaData(event)} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: dataHojeFormatado } }} disabled={perfilLogado === 'caixa'} />
        }
      </Grid>
      {dados.parcont_baixado &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" label="Data Pagamento*" variant="outlined" size="small" name="parcont_datapagamento" value={dados.parcont_datapagamento} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled={true} />
          </Grid>
          <Grid item md={5} xs={12} sm={4}>
            <TextField className="input" label="Baixado por" variant="outlined" size="small" name="usuario_baixa" value={dados.usuario_baixa} InputLabelProps={{ shrink: true }} disabled />
          </Grid>
        </React.Fragment>
      }
      {dados.data_cancelamento &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" label="Data Cancelamento/Estorno*" variant="outlined" size="small" name="data_cancelamento" value={dados.data_cancelamento} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled />
          </Grid>
          <Grid item md={5} xs={12} sm={4}>
            <TextField className="input" label="Cancelado/Estornado por" variant="outlined" size="small" name="usuario_cancelamento" value={dados.usuario_cancelamento} InputLabelProps={{ shrink: true }} disabled />
          </Grid>
        </React.Fragment>
      }
      {dados.parcont_boleto_transaction_id &&
        <Grid item md={3} xs={12} sm={4}>
          <TextField className="input" label="Cod. Transação do Boleto" variant="outlined" size="small" name="parcont_boleto_transaction_id" value={dados.parcont_boleto_transaction_id} InputLabelProps={{ shrink: true }} disabled />
        </Grid>
      }
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={6}  id='viewBlue'>
        <TextField className="input" label="Valor*" variant="outlined" size="small" name="parcont_valorparcela" value={dados.parcont_valorparcela} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={verificaDisabledCamposValor(dados)}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Juros/Multa ou Desconto" variant="outlined" size="small" name="parcont_multajuros" value={dados.parcont_multajuros} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={verificaDisabledCampos(dados)} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}  id='viewBlue'>
        <TextField className="input" label="Valor Total" variant="outlined" size="small" name="valor_total" value={real(parseFloat(dados.parcont_valorparcela.replace('.', '').replace(',', '.')) + parseFloat(dados.parcont_multajuros ? dados.parcont_multajuros.replace('.', '').replace(',', '.') : 0))} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={true} />
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Documento" variant="outlined" size="small" name="parcont_documento" value={dados.parcont_documento} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados)} />
      </Grid>
    </Grid>
    <hr className="mg_top_10" />
    {dados.parcont_situacao === 'CANCELADO' &&
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12} sm={12}>
          <TextField className="input" label="Motivo Cancelamento" variant="outlined" size="small" name="parcont_motivo_cancelamento" value={dados.parcont_motivo_cancelamento} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados)} />
        </Grid>
      </Grid>
    }
    {dados.parcont_situacao === 'ESTORNADO' &&
      <Grid container direction="row" spacing={1} className="mg_top_10">
        <Grid item md={12} xs={12} sm={12}>
          <TextField className="input" label="Motivo do Estorno" variant="outlined" size="small" name="parcont_motivo_cancelamento" value={dados.parcont_motivo_cancelamento} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados)} />
        </Grid>
      </Grid>
    }
    <br />
    <hr />
    {!dados.parcont_baixado &&
      <React.Fragment>
        {dados.parcont_situacao !== 'CANCELADO' && 
          <React.Fragment>
            {dados.parcont_situacao !== 'ESTORNADO' && 
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Valor Pago*" variant="outlined" size="small" name="valpag_valor_pagamento" value={forma_pgto.valpag_valor_pagamento} onChange={(e) => updateFieldFormaPgto(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  {perfilLogado === 'contas-pagar-receber' &&
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      className="input"
                      fullWidth
                      label="Conta*"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="contfin_id"
                      value={forma_pgto.contfin_id}
                      onChangeCapture={(e) => updateFieldFormaPgto(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">--Escolha--</option>
                      {contasFinanceira.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                        return (
                          <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                        )
                      })}
                    </TextField>
                  }
                  {perfilLogado === 'caixa' &&
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      className="input"
                      fullWidth
                      label="Conta*"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="contfin_id"
                      value={forma_pgto.contfin_id}
                      InputLabelProps={{ shrink: true }}
                      // onChangeCapture={(e) => updateFieldFormaPgto(e)}
                      disabled={true}
                    >
                      <option value="">--Escolha--</option>
                      {contasFinanceira.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                        return (
                          <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                        )
                      })}
                    </TextField>
                  }
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Forma de Pagamento*"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="valpag_formapagto_id"
                    value={forma_pgto.valpag_formapagto_id}
                    onChangeCapture={(e) => updateFieldFormaPgto(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={verificaDisabledCampos(dados) ? true : (forma_pgto.contfin_id === '' ? true : false)}
                  >
                    <option value={forma_pgto.valpag_formapagto_id === '' ? '' : forma_pgto.valpag_formapagto_id}>{forma_pgto.valpag_formapagto_id === '' ? '--Escolha--' : forma_pgto.formpag_descricao}</option>
                    {formasPagamento.map(forma => {
                      if (forma.formpag_id !== forma_pgto.valpag_formapagto_id) {
                        if (forma_pgto.contfin_tipo_conta === 'BANCO') {
                          if (forma.formpag_slug !== 'dinheiro') {
                            return (
                              <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                            )
                          }
                        } else if (forma_pgto.contfin_tipo_conta === 'COFRE') {
                          if (forma.formpag_slug === 'dinheiro') {
                            return (
                              <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                            )
                          }
                        } else {
                          return (
                            <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                          )
                        }
                      }
                      return false
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                <Button fullWidth color="primary" variant="contained" className="btn_salvar" startIcon={<AddIcon />} onClick={e => addFormaPagto(e)} >{updateForma ? 'Alterar Pagamento' : 'Adicionar Pagamento'}</Button>
              </Grid>
              </Grid>
            }
          </React.Fragment>
        }
      </React.Fragment>
    }
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={12} xs={12}>
        <Table
          headCell={cabecalhoTabela}
          rows={dados.formas_pgto}
          acoes={!dados.parcont_baixado ? acoesTabela : []}
          editar={e => editarFormaPgto(e)}
          remove={e => removeFormaPagto(e)} />
      </Grid>
    </Grid>
    {/* <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Total Informado" variant="outlined" size="small" value={real(valor_total)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={true} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Falta" variant="outlined" size="small" value={real(parseFloat(dados.parcont_valorparcela.replace(".", "").replace(",", ".")) - valor_total)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={true} />
      </Grid>
    </Grid> */}
  </React.Fragment>
)

export default FormParcelaConta