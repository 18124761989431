import React, { Component } from 'react'
import './Perfil.scss'
import './../../main/ultil.scss'
import axios from 'axios'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

import FormPerfil from '../../components/forms/FormPerfil'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'

const initalState = {
  perfilOriginal: {},
  perfil: {
    per_id: '',
    per_descricao: '',
    per_visibilidade: 'UNIDADE',
    per_status: true,
    per_unin_id: '',
    permissoes: []
  },
  telas: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  unin_tipo: 'UNIDADE',
  permissoesAtual: []
}

export default class CadastroPerfil extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo,
      unin_id: pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_id
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoesAtual = perfil.permissoes

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'perfil')[0]

    this.setState({
      permissoes,
      permissoesAtual
    })

    // if (params.perfilId) {
    //   if (!permissoes.perm_alterar) {
    //     this.handleOpenDialog({
    //       titulo: "Opps!",
    //       descricao: "Você não tem permissão para acessa essa tela!"
    //     })

    //     this.backPage()
    //   }
    // } else {
    //   if (!permissoes.perm_inserir) {
    //     this.handleOpenDialog({
    //       titulo: "Opps!",
    //       descricao: "Você não tem permissão para acessa essa tela!"
    //     })

    //     this.backPage()
    //   }
    // }

    if (!params.perfilId) {
      const perfilOriginal = { ...this.state.perfil }
      perfilOriginal.permissoes = []
      this.setState({
        perfil: perfilOriginal,
        update: false
      })

      this.setState({
        telas: await this.buscaTelas('unidade')
      })
    }

    if (params.perfilId) {
      try {
        const { data: perfil } = await axios.get(`${process.env.REACT_APP_API_URL}/perfis/${params.perfilId}`, this.getToken())

        if (!perfil.per_unin_id) {
          this.handleOpenDialog({
            titulo: "Opps!",
            descricao: "Esse perfil não pode ser editado!"
          })

          this.backPage()

          return false
        }

        return this.setState({
          perfil,
          telas: await this.buscaTelas(perfil.per_visibilidade.toLowerCase()),
          update: true
        })
      } catch (error) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o perfil, tente mais tarde"
        })

        this.backPage()
      }
    }
  }

  async buscaTelas(visibilidade) {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/telas/visibilidade/${visibilidade}`, this.getToken())

    if (res.data) return res.data
    return []
  }

  async updateField(event) {
    const perfil = { ...this.state.perfil }
    perfil[event.target.name] = event.target.value
    this.setState({ perfil })

    if (event.target.name === 'per_visibilidade') {
      this.setState({
        telas: await this.buscaTelas(event.target.value.toLowerCase())
      })
    }
  }

  updateFieldAtivo(event) {
    const perfil = { ...this.state.perfil }
    perfil.per_status = !perfil.per_status
    this.setState({ perfil })
  }

  updateFieldSwitch(key, event) {

    const { telas, permissoesAtual, unin_tipo } = this.state

    console.log(unin_tipo)

    if (unin_tipo === 'FRANQUEADOR') {
      this.updateOrNewPermissoes(
        telas[key],
        {
          name: event.target.name,
          value: event.target.value
        }
      )
    } else {
      this.updateOrNewPermissoes(
        permissoesAtual[key].tela,
        {
          name: event.target.name,
          value: event.target.value
        }
      )
    }
  }

  updateOrNewPermissoes(tela, field) {
    const { perfil } = this.state
    const { permissoes } = perfil

    if (permissoes.length === 0) {
      perfil.permissoes.push(this.newPermissao(tela, field))
      this.setState({ perfil })
      return true
    }

    let edit = null
    for (const i in permissoes) {
      const perm = permissoes[i]
      if (perm.perm_tela_id === tela.tela_id) {
        edit = { perm_tela_id: tela.tela_id }

        edit[`perm_${field.name}`] = !perm[`perm_${field.name}`]
        perfil.permissoes[i] = Object.assign(perm, edit);

        this.setState({ perfil })

        // if (['caixa', 'contas-pagar-receber'].includes(tela.modulo.mod_slug)) {
        //   this.verificaCaixaEContas(perfil.permissoes[i], i)
        // }

        return true
      }
    }

    const nova = this.newPermissao(tela, field)
    perfil.permissoes.push(nova)
    this.setState({ perfil })

    // if (['caixa', 'contas-pagar-receber'].includes(tela.modulo.mod_slug)) {
    //   this.verificaCaixaEContas(nova, perfil.permissoes.length - 1)
    // }

    return true
  }

  newPermissao(tela, field) {
    const data = {
      tela: tela,
      perm_tela_id: tela.tela_id,
      perm_visualizar: false,
      perm_inserir: false,
      perm_alterar: false,
      perm_deletar: false
    }

    data[`perm_${field.name}`] = !field.value
    return data
  }

  verificaCaixaEContas(permissao, index) {
    const { perfil } = this.state
    const { permissoes } = perfil

    if (!permissao.perm_alterar &&
      !permissao.perm_deletar &&
      !permissao.perm_inserir &&
      !permissao.perm_visualizar) {
      return true
    }

    const procurado =
      permissao.tela.modulo.mod_slug === 'caixa' ?
        'contas-pagar-receber' :
        'caixa'

    for (const i in permissoes) {
      const perm = permissoes[i]

      if (perm.tela.modulo.mod_slug === procurado) {
        if (
          perm.perm_alterar ||
          perm.perm_deletar ||
          perm.perm_inserir ||
          perm.perm_visualizar
        ) {
          perfil.permissoes[index].perm_alterar = false
          perfil.permissoes[index].perm_deletar = false
          perfil.permissoes[index].perm_inserir = false
          perfil.permissoes[index].perm_visualizar = false
          this.setState({ perfil })
          this.handleOpenDialog({
            titulo: 'Opps!',
            descricao: 'Não é possível ter as permisões "Caixa" e "Contas a Pagar e Receber" no mesmo perfil. Escolha somente um'
          })
          return
        }
      }
    }
    return true
  }

  validateForm() {
    const { perfil } = this.state

    if (!perfil.per_descricao) return false
    if (!perfil.per_visibilidade) return false

    return true
  }

  async salvar() {
    this.setState({ loadingSalvar: true })
    const self = this

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      self.setState({ loadingSalvar: false })
      return
    }

    const { perfil } = this.state
    const dados = this.preparaJson(perfil)

    try {
      const method = perfil.per_id !== "" ? 'put' : 'post'
      const url = perfil.per_id !== "" ? `/perfis/${perfil.per_id}` : `/perfis`

      this.setState({
        perfilOriginal: {},
        perfil: {
          per_id: '',
          per_descricao: '',
          per_visibilidade: 'UNIDADE',
          per_status: true,
          permissoes: []
        },
        telas: [],
      })

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()
    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return this.setState({ loadingSalvar: false })
      }

      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({})
    }
  }

  backPage(timeout = 2000) {
    const self = this
    this.setState({
      perfil: initalState.perfil,
      telas: initalState.telas
    })
    setTimeout(() => {
      self.props.history.push("/perfil");
    }, timeout)
  }

  preparaJson(perfil) {
    const retorno = []

    let permissao = {}
    for (const i in perfil.permissoes) {
      permissao = perfil.permissoes[i]

      if (perfil.per_visibilidade === 'FRANQUEADO' && permissao.tela && !['FRANQUEADO', 'UNIDADE'].includes(permissao.tela.modulo.mod_visibilidade)) {
        continue
      }

      if (perfil.per_visibilidade === 'UNIDADE' && permissao.tela && permissao.tela.modulo.mod_visibilidade !== 'UNIDADE') {
        continue
      }

      delete perfil.permissoes[i].tela

      if (
        !permissao.perm_alterar &&
        !permissao.perm_deletar &&
        !permissao.perm_inserir &&
        !permissao.perm_visualizar
      ) {
        if (permissao.perm_id) {
          perfil.permissoes[i].delete = true
          retorno.push(perfil.permissoes[i])
        }
        continue
      } else {
        if (permissao.perm_id) {
          delete perfil.permissoes[i].delete
          retorno.push(perfil.permissoes[i])
          continue
        }
      }

      retorno.push(perfil.permissoes[i])
    }

    perfil.permissoes = retorno
    return perfil
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { perfil, telas } = this.state

    console.log(perfil)
    console.log(this.state.unin_id)

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <div id="perfil" className="cadastro">
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={10}>
                <h2 className="titulo">{perfil.per_id ? 'Editar Perfil' : 'Cadastro de Perfil'}</h2>
              </Grid>
              <Grid item md={2}>
                {this.state.loadingSalvar}
                {this.state.loadingSalvar &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>

            <FormPerfil
              updateField={e => this.updateField(e)}
              updateFieldAtivo={e => this.updateFieldAtivo(e)}
              updateFieldSwitch={(key, e) => this.updateFieldSwitch(key, e)}
              dados={perfil}
              unin_id={this.state.unin_id}
              telas={telas}
              update={this.state.update}
              tipoUnidade={this.state.unin_tipo}
              permissoesAtual={this.state.permissoesAtual}
            />

            <Grid container direction="row" alignItems="flex-end">
              <Grid item md={9}></Grid>
              <Grid item md={3}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  className="btn_salvar"
                  size="small"
                  startIcon={<SaveIcon />}
                  onClick={e => this.salvar(e)}
                  disabled={perfil.per_unin_id === '' ? false : (this.state.unin_id !== perfil.per_unin_id ? true : this.state.loadingSalvar)}
                >
                  Salvar
								</Button>
              </Grid>
            </Grid>
            <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          </div>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}
