import React, { Component } from 'react'
import axios from 'axios'
import './ead.scss'
import Tooltip from '@material-ui/core/Tooltip'
import FormCpf from './modulos/Cpf'
import FormDadosPessoal from './modulos/DadosPessoal'
import FormCurso from './modulos/Curso'
import Pagamento from './modulos/Pagamento'
import Contrato from './modulos/Contrato'
import PosPagamento from './modulos/PosPagamento'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Alert from './../../components/templates/Alert'
import Rodape from './../../components/RodapeLogin'
import getEndereco from './../../services/api/getEndereco'
import moment from 'moment'
import decrypt from './../../services/crypto'
import real from './../../services/real'
const cpf = require('node-cpf-cnpj')

const initalState = {
  tela_atual: 0,
  telas: ['cpf', 'dados_pessoal', 'curso', 'contrato', 'pagamento', 'pos_pagamento'],
  aluno: {
    tipoCadastro: '',
    cpf: '',
    possui_nome_social: false,
    nome: '',
    nome_social: '',
    sobrenome: '',
    telefone: '55',
    data_nascimento: '',
    email: '',
    curso_id: '',
    curso: '',
    tabval_id:  '',
    formpag_id: '',
    vencimento_mensalidade: '',
    pesf_pessoa_id: '',
    responsavel: {
      cpf: '',
      possui_nome_social: false,
      nome: '',
      nome_social: '',
      sobrenome: '',
      telefone: '',
      data_nascimento: '',
      email: '',
    },
    endereco:{
      cep_id: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: ''
    }
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  curso: {
    curso_id: '',
    curso_nome: ''
  },
  planosPagamentos: [],
  tabela_selecionada: {
    tabval_id: '',
    tabval_nome: '',
    tabval_num_parc: 0,
    tabval_val_cheio_mensal: 0,
    tabval_val_mensal: 0,
    tabval_ativo: false
  },
  token: '',
  html_documento: '',
  travaBotao: false,
  cupom: '',
  cuponsSelecionados: [],
  travaBotaoCupom: false,
  boletoSelecionado:{
    tipo_transaction: 'PIX',
    bank_slip:{
      url_slip_pdf: '',
      boleto_digitable_line: ''
    }
  },
  cursos: {
    list: []
  },
  valorPrimeira: null
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}
export default class CadastroEAD extends Component {
  state = { ...initalState }

  getToken(USER_TOKEN) {
    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      let login = {
        login: 'relacionamento@britishandamerican.com.br',
        senha: 'Zeridax@19863'
      }

      const { match: { params } } = this.props;

      let { aluno } = this.state

      const { data: dados_login } = await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/login`, login)
      
      let token = dados_login.token

      const { data: curso } = await axios.get(`${process.env.REACT_APP_API_URL}/curso/${params.cursoId}`, this.getToken(token))
      
      aluno.curso_id = curso.curso_id
      aluno.curso = curso.curso_nome

      aluno.vencimento_mensalidade = moment().add(3, 'days').format('YYYY-MM-DD')

      this.setState({
        token,
        aluno
      })

      await this.consultarPlano(curso.curso_id)

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
    
  }

  handleChangeRadio(event){
    let { aluno } = this.state
    
    aluno.tipoCadastro = event.target.value

    aluno.cpf = ''
    aluno.responsavel.cpf = ''
    
    this.setState({
      aluno
    })
  }

  async buscaContrato(){
    try {
      let {aluno, token, tabela_selecionada} = this.state

      let dados_contrato = {
        aluno,
        tabela_selecionada
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/buscaContrato`, dados_contrato ,this.getToken(token))
      
      this.setState({
        html_documento: data.html
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async buscaPrimeiraParcela(aluno){
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/ead/buscaPrimeiraParcela/${aluno.aluno_id}`, this.getToken(this.state.token))
      
      return data
    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async proximo(){
    let { aluno, tela_atual, tabela_selecionada, token } = this.state
    
    let libera = true

    if(tela_atual === 0){
      if(aluno.tipoCadastro === ''){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o tipo do cadastro'
          }
        })
      }

      if(aluno.tipoCadastro === 'aluno'){
        if(aluno.cpf === '' && libera){
          libera = false
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o CPF'
            }
          })
        }else{
          if (!cpf.isValid(cpf.format(aluno.cpf)) && libera) {
            libera = false
            this.setState({
              alerta: {
                open: true,
                autoHideDuration: 5000,
                severity: 'warning',
                message: 'CPF Invalido!'
              }
            })
          }
        }
      }

      if(aluno.tipoCadastro === 'responsavel'){
        if(aluno.responsavel.cpf === '' && libera){
          libera = false
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o CPF'
            }
          })
        }else{
          if (!cpf.isValid(cpf.format(aluno.responsavel.cpf)) && libera) {
            libera = false
            this.setState({
              alerta: {
                open: true,
                autoHideDuration: 5000,
                severity: 'warning',
                message: 'CPF Invalido!'
              }
            })
          }
        }
      }

      if(aluno.tipoCadastro === 'aluno'){
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/ead/pessoa/fisica/search/${aluno.cpf}`, { buscaPor: 'cpf', busca: 'ead' }, this.getToken(token))
            
          let resp = JSON.parse(decrypt(data))
          
          console.log(resp)

          if(resp){
            aluno.pesf_pessoa_id = resp.pessoa.pesf_pessoa_id
            aluno.nome = resp.pessoa.pesf_nome
            aluno.nome_social = resp.pessoa.pesf_apelido
            aluno.sobrenome= resp.pessoa.pesf_sobrenome

            if(!resp.libera){

              let primeiraParcela = await this.buscaPrimeiraParcela(resp.aluno)

              if(primeiraParcela){
                this.setState({
                  valorPrimeira: real(primeiraParcela.parcont_valorparcela)
                })

                if(primeiraParcela.parcont_situacao !== 'BAIXADO'){
                  if(primeiraParcela.valores_pago[0].forma_pagto.formpag_slug === 'pix'){
                    this.setState({
                      alerta: {
                        open: true,
                        severity: 'info',
                        message: 'Carregando PIX'
                      }
                    })
            
                    if(primeiraParcela.parcont_boleto_transaction_id){
                      this.setState({
                        boletoSelecionado:{
                          tipo_transaction: 'PIX',
                          bank_slip:{
                            url_slip_pdf: primeiraParcela.parcont_boleto_link,
                            boleto_digitable_line: primeiraParcela.parcont_boleto_digitable_line
                          }
                        },
                        tela_atual: 4
                      })
                    }else{
                      const { data: resp } = await axios.post(`${process.env.REACT_APP_API_URL}/pix`,{parcela_id: primeiraParcela.parcont_id}, this.getToken(token))
                
                      this.setState({
                        boletoSelecionado:{
                          tipo_transaction: 'PIX',
                          bank_slip:{
                            url_slip_pdf: resp.pix_code.qrcode_image_url,
                            boleto_digitable_line: resp.pix_code.emv
                          }
                        },
                        tela_atual: 4
                      })
                    }

                    this.setState({
                      alerta: {
                        open: true,
                        autoHideDuration: 5000,
                        severity: 'warning',
                        message: 'Esse CPF já está cadastrado no nosso portal do EAD.'
                      },
                      aluno
                    })
    
                    return
                  }else{
                    this.setState({
                      alerta: {
                        open: true,
                        severity: 'info',
                        message: 'Carregando Boleto'
                      }
                    })

                    if(primeiraParcela.parcont_boleto_transaction_id){
                      this.setState({
                        boletoSelecionado:{
                          tipo_transaction: 'BOLETO',
                          bank_slip:{
                            url_slip_pdf: primeiraParcela.parcont_boleto_link,
                            boleto_digitable_line: primeiraParcela.parcont_boleto_digitable_line
                          }
                        },
                        tela_atual: 4
                      })
                    }else{
                      const { data: resp } = await axios.post(`${process.env.REACT_APP_API_URL}/boleto`,{parcela_id: primeiraParcela.parcont_id}, this.getToken(token))
              
                      this.setState({
                        boletoSelecionado:{
                          tipo_transaction: 'BOLETO',
                          bank_slip:{
                            url_slip_pdf: resp.bank_slip.url_slip_pdf,
                            boleto_digitable_line: resp.bank_slip.digitable_line
                          }
                        },
                        tela_atual: 4
                      })
                    }          

                    this.setState({
                      alerta: {
                        open: true,
                        autoHideDuration: 5000,
                        severity: 'warning',
                        message: 'Esse CPF já está cadastrado no nosso portal do EAD.'
                      },
                      aluno
                    })
    
                    return
                  }
                }else{
                        
                  libera = false

                  if(resp.aluno.aluno_unin_id === 46){
                    this.setState({
                      alerta: {
                        open: true,
                        autoHideDuration: 5000,
                        severity: 'warning',
                        message: 'Esse CPF já está cadastrado no nosso portal do EAD.'
                      },
                      aluno
                    })
        
                    this.setState({
                      tela_atual: 5
                    })
        
                    return
                  }else{
                    this.setState({
                      alerta: {
                        open: true,
                        autoHideDuration: 5000,
                        severity: 'warning',
                        message: 'Esse CPF já possui um contrato ativo em outra unidade.'
                      },
                      aluno
                    })
                    return
                  }
                  
                }
              }else{

                libera = false
                
                if(resp.aluno.aluno_unin_id === 46){
                  
                  this.setState({
                    alerta: {
                      open: true,
                      autoHideDuration: 5000,
                      severity: 'warning',
                      message: 'Esse CPF já está cadastrado no nosso portal do EAD.'
                    },
                    aluno
                  })
      
                  this.setState({
                    tela_atual: 5
                  })
      
                  return

                }else{
                  this.setState({
                    alerta: {
                      open: true,
                      autoHideDuration: 5000,
                      severity: 'warning',
                      message: 'Esse CPF já possui um contrato ativo em outra unidade.'
                    },
                    aluno
                  })
                  return
                }
                        
              }             
            }
          }
  
        } catch (error) {
          console.log(error)
          // this.setState({
          //   alerta: {
          //     open: true,
          //     autoHideDuration: 5000,
          //     severity: 'error',
          //     message: response.data.message
          //   }
          // })
          // return
        }
      }
      
      if(aluno.tipoCadastro === 'responsavel'){
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/ead/pessoa/fisica/search/${aluno.responsavel.cpf}`, { buscaPor: 'cpf', busca: 'responsavel-ead' }, this.getToken(token))
          
          let resp = JSON.parse(decrypt(data))

          if(resp){
            aluno.responsavel = {
              cpf: aluno.responsavel.cpf,
              possui_nome_social: false,
              nome: resp.pesf_nome,
              nome_social: resp.pesf_apelido,
              sobrenome: resp.pesf_sobrenome,
              telefone: '',
              data_nascimento: resp.pesf_datanascimento,
              email: '',
            }
          }
  
        } catch ({response}) {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'error',
              message: response.data.message
            }
          })
          return
        }
      }
    }

    if(tela_atual === 1){
      if(aluno.cpf === ''){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o CPF do Aluno'
          }
        })
      }

      if(aluno.nome === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o nome do Aluno'
          }
        })
      }

      if(aluno.data_nascimento === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar a data de nascimento do Aluno'
          }
        })
      }

      if(aluno.telefone === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o celular do Aluno'
          }
        })
      }
      
      if(aluno.email === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o email do Aluno'
          }
        })
      }

      if (!validacaoEmail(aluno.email)) {
        libera = false
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Email do aluno invalido!'
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      if(aluno.tipoCadastro === 'responsavel'){
        console.log(aluno.responsavel)

        if(aluno.responsavel.cpf === '' && libera){
          libera = false
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o CPF do Responsável'
            }
          })
        }

        if(aluno.responsavel.nome === '' && libera){
          libera = false
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o nome do Responsável'
            }
          })
        }

        if(aluno.responsavel.data_nascimento === '' && libera){
          libera = false
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar a data de nascimento do Responsável'
            }
          })
        }

        if(aluno.responsavel.telefone === '' && libera){
          libera = false
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o celular do Responsável'
            }
          })
        }

        if(aluno.responsavel.email === '' && libera){
          libera = false
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o email do Responsável'
            }
          })
        }

        if (!validacaoEmail(aluno.responsavel.email) && libera) {
          libera = false
          this.setState({
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Email do responsável invalido!'
            }
          })
          this.setState({ loadingSalvar: false })
          return
        }
      }

      if(aluno.endereco.cep === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o CEP'
          }
        })
      }

      if(aluno.endereco.logradouro === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o endereço'
          }
        })
      }

      if(aluno.endereco.numero === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o número do endereço'
          }
        })
      }

      if(aluno.endereco.bairro === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o bairro'
          }
        })
      }

      if(aluno.endereco.cidade === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o cidade'
          }
        })
      }

      if(aluno.endereco.estado === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o estado'
          }
        })
      }

      if(aluno.tipoCadastro === 'responsavel'){
        try {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/ead/pessoa/fisica/search/${aluno.cpf}`, { buscaPor: 'cpf', busca: 'ead' }, this.getToken(token))
            
          let resp = JSON.parse(decrypt(data))

          if(resp){
            if(!resp.libera){
              let primeiraParcela = await this.buscaPrimeiraParcela(resp.aluno)

              this.setState({
                valorPrimeira: real(primeiraParcela.parcont_valorparcela)
              })

              aluno.nome = resp.pessoa.pesf_nome
              aluno.nome_social = resp.pessoa.pesf_apelido
              aluno.sobrenome= resp.pessoa.pesf_sobrenome
              aluno.pesf_pessoa_id = resp.pessoa.pesf_pessoa_id

              if(primeiraParcela.parcont_situacao !== 'BAIXADO'){
                if(primeiraParcela.valores_pago[0].forma_pagto.formpag_slug === 'pix'){
                  this.setState({
                    alerta: {
                      open: true,
                      severity: 'info',
                      message: 'Carregando PIX'
                    }
                  })
          
                  if(primeiraParcela.parcont_boleto_transaction_id){
                    this.setState({
                      boletoSelecionado:{
                        tipo_transaction: 'PIX',
                        bank_slip:{
                          url_slip_pdf: primeiraParcela.parcont_boleto_link,
                          boleto_digitable_line: primeiraParcela.parcont_boleto_digitable_line
                        }
                      }
                    })
                  }else{
                    const { data: resp } = await axios.post(`${process.env.REACT_APP_API_URL}/pix`,{parcela_id: primeiraParcela.parcont_id}, this.getToken(token))
              
                    this.setState({
                      boletoSelecionado:{
                        tipo_transaction: 'PIX',
                        bank_slip:{
                          url_slip_pdf: resp.pix_code.qrcode_image_url,
                          boleto_digitable_line: resp.pix_code.emv
                        }
                      }
                    })
                  }

                  this.setState({
                    alerta: {
                      open: true,
                      autoHideDuration: 5000,
                      severity: 'warning',
                      message: 'Esse CPF já está cadastrado no nosso portal do EAD.'
                    },
                    aluno,
                    tela_atual: 4
                  })
  
                  return
                }else{
                  this.setState({
                    alerta: {
                      open: true,
                      severity: 'info',
                      message: 'Carregando Boleto'
                    }
                  })

                  if(primeiraParcela.parcont_boleto_transaction_id){
                    this.setState({
                      boletoSelecionado:{
                        tipo_transaction: 'BOLETO',
                        bank_slip:{
                          url_slip_pdf: primeiraParcela.parcont_boleto_link,
                          boleto_digitable_line: primeiraParcela.parcont_boleto_digitable_line
                        }
                      }
                    })
                  }else{
                    const { data: resp } = await axios.post(`${process.env.REACT_APP_API_URL}/boleto`,{parcela_id: primeiraParcela.parcont_id}, this.getToken(token))
            
                    this.setState({
                      boletoSelecionado:{
                        tipo_transaction: 'BOLETO',
                        bank_slip:{
                          url_slip_pdf: resp.bank_slip.url_slip_pdf,
                          boleto_digitable_line: resp.bank_slip.digitable_line
                        }
                      }
                    })
                  }          

                  this.setState({
                    alerta: {
                      open: true,
                      autoHideDuration: 5000,
                      severity: 'warning',
                      message: 'Esse CPF já está cadastrado no nosso portal do EAD.'
                    },
                    aluno,
                    tela_atual: 4
                  })
  
                  return
                }
              }else{
                
              
                libera = false
                
                this.setState({
                  alerta: {
                    open: true,
                    autoHideDuration: 5000,
                    severity: 'warning',
                    message: 'Esse CPF já está cadastrado no nosso portal do EAD.'
                  },
                  aluno
                })
    
                this.setState({
                  tela_atual: 5
                })
    
                return
              }
            }
          }
  
        } catch ({response}) {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'error',
              message: response.data.message
            }
          })
          return
        }
      }
    }

    if(tela_atual === 2){
      if(tabela_selecionada.tabval_id === ''){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor selecionar o plano de pagamento'
          }
        })
      }

      if(aluno.formpag_id === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor selecionar a forma de pagamento da mensalidae.'
          }
        })
      }

      if(aluno.vencimento_mensalidade === '' && libera){
        libera = false
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor selecionar a data do vencimento da 1ª Parcela.'
          }
        })
      }
      
    }

    if(tela_atual + 1 === 3){
      await this.buscaContrato()
    }

    if(libera){
      this.setState({
        tela_atual: tela_atual + 1,
        aluno
      })
    }
  }

  voltar(){
    this.setState({
      tela_atual: this.state.tela_atual - 1
    })
  }

  voltarTelaInicio(){
    this.setState({
      tela_atual: 0
    })
  }
  
  updateFieldNomeSocial(){
    let { aluno } = this.state

    aluno.possui_nome_social = !aluno.possui_nome_social

    this.setState({aluno})
  }

  updateFieldNomeSocialResponsavel(){
    let { aluno } = this.state

    aluno.responsavel.possui_nome_social = !aluno.responsavel.possui_nome_social

    this.setState({aluno})
  }

  async updateField(event){
    let {aluno} = this.state

    let name = event.target.name
    let value = event.target.value

    aluno[name] = value

    this.setState({aluno})

    if(name === 'curso_id'){
      if(value !== ''){
        this.consultarPlano(value)
      }else{
        this.setState({
          planoPagamento: {
            planpag_id: '',
            planpag_descricao: '',
            planpag_ativo: true,
            tabelas_valores: []
          }
        })
      }
    }
  }

  async consultarPlano(curso_id){
    try {
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Buscando planos de pagamento do curso.'
        }
      })

      const { data: planosPagamentos } = await axios.get(`${process.env.REACT_APP_API_URL}/planoPagamentoCurso/listByUnidadeByCurso/46/${curso_id}`, this.getToken(this.state.token))

      this.setState({
        planosPagamentos,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      })
    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }
  
  async buscaEndereco(event){
    let {aluno} = this.state

    if(event.target.value !== ''){
      const resp = await getEndereco(event.target.value)

      aluno.endereco = {
        cep_id: resp.id,
        cep: resp.cep,
        logradouro: resp.logradouro,
        complemento: '',
        numero: '',
        bairro: resp.bairro.nome,
        cidade: resp.bairro.cidade.nome,
        estado: resp.bairro.cidade.uf.descricao
      }

      this.setState({aluno})
    }


  }

  updateFieldResponsavel(event){
    let {aluno} = this.state
    aluno.responsavel[event.target.name] = event.target.value
    this.setState({aluno})
  }

  updateFieldEndereco(event){
    let {aluno} = this.state
    aluno.endereco[event.target.name] = event.target.value
    this.setState({aluno})
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  handleChangeRadioPlano(event){
    let {aluno, planosPagamentos} = this.state

    console.log(event.target.value)

    aluno.tabval_id = event.target.value

    console.log(planosPagamentos)

    let index_plano = planosPagamentos.findIndex(param => {
      let index_tabela = param.tabelas_valores.findIndex(tabela => tabela.tabval_id=== parseInt(aluno.tabval_id))

      if(index_tabela !== -1){
        return true
      }

      return false
    })

    this.setState({
      aluno,
      tabela_selecionada: planosPagamentos[index_plano].tabelas_valores[planosPagamentos[index_plano].tabelas_valores.findIndex(param => param.tabval_id === parseInt(aluno.tabval_id))]
    })
  }

  handleChangeRadioFormaPagto(event){
    let {aluno} = this.state

    aluno.formpag_id = event.target.value

    this.setState({
      aluno
    })
  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.setState({
      alerta: {
        open: true,
        severity: 'success',
        message: 'Link Copiado!'
      },
      travaBotao: true
    })
  
    return
    
  }

  async assinarContrato(sigCanvas){
    try {
      
      let {tabela_selecionada, aluno, html_documento, token, cuponsSelecionados, tela_atual} = this.state

      if(sigCanvas.current.isEmpty()) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor escrever a sua assinatura.'
          },
          loadingSalvar: false
        })
        return
      }

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Cadastrando os dados e assinando o contrato...'
        },
        travaBotao: true
      })

      let base64Assinatura = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")

      let dados = {
        aluno,
        tabela_selecionada,
        html_documento,
        base64Assinatura,
        cuponsSelecionados
      }

      let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/cadastroEad`, dados ,this.getToken(token))

      aluno.pesf_pessoa_id = data.pes_id

      if(data.primeiraParcela.forma_pgto === 'pix'){
        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Carregando PIX'
          }
        })

        const { data: resp } = await axios.post(`${process.env.REACT_APP_API_URL}/pix`,{parcela_id: data.primeiraParcela.parcont_id}, this.getToken(token))
  
        this.setState({
          boletoSelecionado:{
            tipo_transaction: 'PIX',
            bank_slip:{
              url_slip_pdf: resp.pix_code.qrcode_image_url,
              boleto_digitable_line: resp.pix_code.emv
            }
          },
          tela_atual: tela_atual + 1,
          aluno
        })

      }else{
        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Carregando Boleto'
          }
        })

        const { data: resp } = await axios.post(`${process.env.REACT_APP_API_URL}/boleto`,{parcela_id: data.primeiraParcela.parcont_id}, this.getToken(token))

        this.setState({
          boletoSelecionado:{
            tipo_transaction: 'BOLETO',
            bank_slip:{
              url_slip_pdf: resp.bank_slip.url_slip_pdf,
              boleto_digitable_line: resp.bank_slip.digitable_line
            }
          },
          tela_atual: tela_atual + 1,
          aluno
        })
      }

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Contrato Assinado!'
        }
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        travaBotao: false
      })
      return
    }
  }

  updateFieldCupom(event){
    this.setState({
      cupom: event.target.value
    })
  }

  async aplicarCupom(){
    let {cupom, token, cuponsSelecionados} = this.state

    try {

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Buscando Cupom de Desconto...'
        },
        travaBotaoCupom: true
      })

      let dados = {
        cupom
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/cupomDesconto/buscaCupom`, dados ,this.getToken(token))
      
      cuponsSelecionados.push({...data})

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cupom de Desconto Aplicado...'
        },
        travaBotaoCupom: true
      })

      this.setState({
        cupom: '',
        cuponsSelecionados,
        travaBotaoCupom: false
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        travaBotaoCupom: false
      })
      return
    }
  }

  render() {
    const { aluno, tela_atual, telas, cursos, planosPagamentos, tabela_selecionada, html_documento, cupom, 
      travaBotaoCupom, cuponsSelecionados, boletoSelecionado } = this.state

    return (
      <div>
        <div className='topo_cadastro'>
          {tela_atual === 0 &&
            <span>Faça sua Inscrição</span>
          }
          {tela_atual === 1 &&
            <React.Fragment>
              <Tooltip title='Voltar' aria-label="add">
                <ArrowBackIcon className='icon_back' onClick={() => this.voltar()}/>
              </Tooltip>
              <span>Ficha de Inscrição</span>
            </React.Fragment>
          }
          {tela_atual === 2 &&
            <React.Fragment>
              <Tooltip title='Voltar' aria-label="add">
                <ArrowBackIcon className='icon_back' onClick={() => this.voltar()}/>
              </Tooltip>
              <span>Confirmação da Inscrição</span>
            </React.Fragment>
          }
          {tela_atual === 3 &&
            <React.Fragment>
              <Tooltip title='Voltar' aria-label="add">
                <ArrowBackIcon className='icon_back' onClick={() => this.voltar()}/>
              </Tooltip>
              <span>Visualizar Contrato</span>
            </React.Fragment>
          }
          {tela_atual === 4 &&
            <React.Fragment>
              <Tooltip title='Voltar' aria-label="add">
                <ArrowBackIcon className='icon_back' onClick={() => this.voltar()}/>
              </Tooltip>
              <span>Pagamento 1ª Parcela</span>
            </React.Fragment>
          }
          {tela_atual === 5 &&
            <React.Fragment>
              <Tooltip title='Voltar' aria-label="add">
                <ArrowBackIcon className='icon_back' onClick={() => this.voltarTelaInicio()}/>
              </Tooltip>
              <span>Links</span>
            </React.Fragment>
          }
        </div>
        <div className="container-boletos" id="app">
          {telas[tela_atual] === 'cpf' &&
            <FormCpf 
              aluno={aluno}
              handleChangeRadio={(e) => this.handleChangeRadio(e)}
              updateField={(e) => this.updateField(e)}
              updateFieldResponsavel={(e) => this.updateFieldResponsavel(e)}
              proximo={() => this.proximo()}
            />
          }
          {telas[tela_atual] === 'dados_pessoal' && 
            <FormDadosPessoal
              aluno={aluno}
              updateField={(e) => this.updateField(e)}
              proximo={() => this.proximo()}
              updateFieldEndereco={(e) => this.updateFieldEndereco(e)}
              updateFieldResponsavel={(e) => this.updateFieldResponsavel(e)}
              updateFieldNomeSocial={(e) => this.updateFieldNomeSocial(e)}
              updateFieldNomeSocialResponsavel={(e) => this.updateFieldNomeSocialResponsavel(e)}
              buscaEndereco={(e) => this.buscaEndereco(e)}
              voltar={() => this.voltar()}
            />
          }
          {telas[tela_atual] === 'curso' && 
            <FormCurso 
              aluno={aluno}
              cursos={cursos.list}
              planosPagamentos={planosPagamentos}
              updateField={(e) => this.updateField(e)}
              handleChangeRadio={(e) => this.handleChangeRadioPlano(e)}
              handleChangeRadioFormaPagto={(e) => this.handleChangeRadioFormaPagto(e)}
              tabela_selecionada={tabela_selecionada}
              aplicarCupom={(e) => this.aplicarCupom(e)}
              cupom={cupom}
              proximo={() => this.proximo()}
              voltar={() => this.voltar()}
              updateFieldCupom={(e) => this.updateFieldCupom(e)}
              travaBotaoCupom={travaBotaoCupom}
              cuponsSelecionados={cuponsSelecionados}
            />
          }
          {telas[tela_atual] === 'contrato' && 
            <Contrato 
              html_documento={html_documento}
              assinarContrato={(e) => this.assinarContrato(e)}
              voltar={() => this.voltar()}
              travaBotao={this.state.travaBotao}
            />
          }
          {telas[tela_atual] === 'pagamento' && 
            <Pagamento 
              cuponsSelecionados={cuponsSelecionados}
              boletoSelecionado={boletoSelecionado}
              tabela_selecionada={tabela_selecionada}
              aluno={aluno}
              valorPrimeira={this.state.valorPrimeira}
              copyLink={(e) => this.copyLink(e)}
            />
          }
          {telas[tela_atual] === 'pos_pagamento' && 
            <PosPagamento 
              aluno={aluno}
            />
          }
        </div>
        <br />
        <br />
        <Rodape />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </div>
    )
  }
}