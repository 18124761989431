import React, { Component } from 'react'
import './../Produto.scss'
import './../../../main/ultil.scss'

import Main from '../../../components/templates/Main'
import Nav from '../../../components/templates/Nav'
import Footer from '../../../components/templates/Footer'

import FormGrupoProdutos from '../../../components/forms/FormGrupoProdutos'

import { Grid, Button } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../../components/modals/Erro'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  grupoProduto: {
    grupprod_id: "",
    grupprod_descricao: "",
    grupprod_ativo: true
  },
  grupoProdutos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class CadastroCargo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'grupo-produtos')[0]

    this.setState({
      permissoes
    })

    if (params.grupoProdutoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.grupoProdutoId) {
      try {

        const { data: grupoProduto } = await axios.get(`${process.env.REACT_APP_API_URL}/grupoProdutos/${params.grupoProdutoId}`, this.getToken())

        this.setState({
          grupoProduto: {
            grupprod_id: grupoProduto.grupprod_id,
            grupprod_descricao: grupoProduto.grupprod_descricao,
            grupprod_ativo: grupoProduto.grupprod_ativo
          },
          loading: false
        })
      } catch (error) {
        console.log(error);
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o grupo de Produto, tente mais tarde"
        })

        this.backPage()
      }
    }

  }

  updateField(event) {
    const grupoProduto = { ...this.state.grupoProduto }
    grupoProduto[event.target.name] = event.target.value
    this.setState({ grupoProduto })
  }

  updateFieldAtivo(event) {
    const grupoProduto = { ...this.state.grupoProduto }
    grupoProduto.grupprod_ativo = !grupoProduto.grupprod_ativo
    this.setState({ grupoProduto })
  }

  validateForm() {
    const { grupoProduto } = this.state

    if (!grupoProduto.grupprod_descricao) return false

    return true
  }

  async salvar() {
    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { grupoProduto } = this.state

    const dados = {
      grupprod_descricao: grupoProduto.grupprod_descricao,
      grupprod_ativo: grupoProduto.grupprod_ativo
    }

    try {

      const method = grupoProduto.grupprod_id !== "" ? 'put' : 'post'
      const url = grupoProduto.grupprod_id !== "" ? `/grupoProdutos/${grupoProduto.grupprod_id}` : `/grupoProdutos`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/grupo_produtos");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { grupoProduto } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={11}>
              <h2 className="titulo">Cadastro de Grupo de Produtos</h2>
            </Grid>
            <Grid item md={1}>
              {this.state.loadingSalvar &&
                <div>
                  <CircularProgress />
                </div>
              }
            </Grid>
          </Grid>
          <FormGrupoProdutos updateField={e => this.updateField(e)} updateFieldAtivo={e => this.updateFieldAtivo(e)} dados={grupoProduto} />
          <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
            <Grid item md={9}></Grid>
            <Grid item md={3}>
              <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
            </Grid>
          </Grid>
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}