import React from 'react'

import { Grid } from '@material-ui/core'

export default function Rodape() {
  return (
    <React.Fragment>
      <div style={{position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff'}}>
        <hr/>
        <Grid container direction="row" className="mg_top_20">
          <Grid item md={12} xs={12} sm={12}>
            {process.env.REACT_APP_APLICACAO === "homologacao" &&
              <div className="boxHomologacao">
                {/* <i className="fas fa-exclamation-triangle"></i> */}
                <p>Você está em um ambiente de Homologação</p>
              </div>
            }
            <p className="marca" style={{textAlign: 'right', marginRight: 10}}>©2023 Desenvolvido por Click Sistemas</p>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}