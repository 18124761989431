import React, { Component } from 'react'
import './Aula.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormAula from '../../components/forms/FormAula'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'

const initalState = {
  aula: {
    _id: "",
    aula_nome: "",
    aula_book_id: "",
    aula_duracao: "",
    aula_tipo: "",
    aula_cor: "#000000",
    aula_cor_texto: "#ffffff",
    aula_agend_online: false,
    aula_ativo: true,
    aula_soma_min_aval: 0,
    aula_aluno_minimo: '',
    aula_aluno_maximo: '',
    aula_limite_aluno: '',
    aula_aluno_padrao: '',
    aula_conversacao: false,
    aula_nivel_inicial: '',
    aula_nivel_final: '',
    aula_ordem: 0,
    avaliacoes: [],
    unidades: []
  },
  avaliacao: {
    avalaula_descricao: "",
    avalaula_nota: 0
  },
  aulas: {
    list: []
  },
  book: {
    bok_id: "",
    bok_nome: "",
    bc_obrigatorio: true,
    bc_ativo: true,
    produto: {},
  },
  books: {
    list: []
  },
  unidades: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'avalaula_descricao', numeric: false, disablePadding: true, label: 'Descrição Avaliação' },
    { id: 'avalaula_nota', numeric: false, disablePadding: false, label: 'Nota Avaliação' },
  ],
  acoesTabela: ['editar', 'remove'],
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class CadastroAula extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {

    this.setState({
      aula: {
        _id: "",
        aula_nome: "",
        aula_book_id: "",
        aula_duracao: "",
        aula_tipo: "",
        aula_cor: "#000000",
        aula_cor_texto: "#ffffff",
        aula_agend_online: false,
        aula_ativo: true,
        aula_soma_min_aval: 0,
        aula_aluno_minimo: '',
        aula_aluno_maximo: '',
        aula_limite_aluno: '',
        aula_aluno_padrao: '',
        aula_conversacao: false,
        aula_nivel_inicial: '',
        aula_nivel_final: '',
        aula_ordem: 0,
        avaliacoes: []
      }
    })

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'aulas')[0]

    this.setState({
      permissoes
    })

    if (params.aulaId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.aulaId) {
      try {
        const { data: aula } = await axios.get(`${process.env.REACT_APP_API_URL}/aula/${params.aulaId}`, this.getToken())

        const avaliacoes = []

        for (let i = 0; i < aula.avaliacoes.length; i++) {
          avaliacoes.push({
            avalaula_id: aula.avaliacoes[i].avalaula_id,
            avalaula_descricao: aula.avaliacoes[i].avalaula_descricao,
            avalaula_nota: aula.avaliacoes[i].avalaula_nota,
            index: i + 1
          })
        }

        this.setState({
          aula: {
            _id: aula.aula_id,
            aula_nome: aula.aula_nome,
            aula_book_id: aula.book[0] ? aula.book[0].bok_id : "",
            aula_duracao: aula.aula_duracao,
            aula_tipo: aula.aula_tipo,
            aula_agend_online: aula.aula_agend_online,
            aula_ativo: aula.aula_ativo,
            aula_soma_min_aval: aula.aula_soma_min_aval,
            aula_tipo_horario: aula.aula_tipo_horario,
            aula_cor: aula.aula_cor,
            aula_cor_texto: aula.aula_cor_texto,
            aula_aluno_minimo: aula.aula_aluno_minimo,
            aula_aluno_maximo: aula.aula_aluno_maximo,
            aula_limite_aluno: aula.aula_limite_aluno,
            aula_aluno_padrao: aula.aula_aluno_padrao,
            aula_conversacao: aula.aula_conversacao,
            aula_nivel_inicial: aula.aula_nivel_inicial,
            aula_nivel_final: aula.aula_nivel_final,
            aula_ordem: aula.aula_ordem,
            avaliacoes
          },
          book: {
            bok_id: aula.book[0] ? aula.book[0].bok_id : "",
            bok_nome: aula.book[0] ? aula.book[0].bok_nome : "",
            bc_obrigatorio: aula.book[0] ? aula.book[0].bc_obrigatorio : "",
            bc_ativo: aula.book[0] ? aula.book[0].bc_ativo : ""
          }
        })


      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar a aula, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/book`, this.getToken())

      let booksAtivos = []
      let books = []

      booksAtivos = resp.data.filter(u => u.bok_ativo === true)

      booksAtivos.forEach(book => {
        books.push({
          bok_id: book.bok_id,
          bok_nome: book.bok_nome,
          bok_descricao: book.bok_descricao,
          bok_produto_id: book.bok_produto_id,
          bok_ativo: book.bok_ativo,
          produto: book.produto
        })
      })

      this.setState({
        books: {
          list: books
        }
      })

    } catch (error) {
      console.log(error);
    }

    this.setState({ loading: false })
  }

  updateField(event) {
    const aula = { ...this.state.aula }
    aula[event.target.name] = event.target.value
    this.setState({ aula })
  }

  updateFieldAtivo() {
    const aula = { ...this.state.aula }
    aula.aula_ativo = !aula.aula_ativo
    this.setState({ aula })
  }

  updateFieldAgendamento() {
    const aula = { ...this.state.aula }
    aula.aula_agend_online = !aula.aula_agend_online
    this.setState({ aula })
  }

  updateFieldAvaliacao(event) {
    const avaliacao = { ...this.state.avaliacao }
    avaliacao[event.target.name] = event.target.value
    this.setState({ avaliacao })
  }

  updateFieldBook(event, value) {
    if (value) {
      const aula = { ...this.state.aula }
      aula.aula_book_id = value.bok_id
      this.setState({
        aula,
        book: {
          bok_id: value.bok_id,
          bok_nome: value.bok_nome,
          bc_obrigatorio: this.state.book.bc_obrigatorio,
          bc_ativo: this.state.book.bc_ativo,
          produto: value.produto
        }
      })
    }
  }

  updateFieldUnidade(event, value) {
    const aula = { ...this.state.aula }
    aula.unidades = value.map(val => {
      return val
    })
    this.setState({ aula })
  }

  addItem() {
    if (!this.validateAvaliacao()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios da Avaliação (*)."
      })
      return
    }

    const { aula, avaliacao, update } = this.state
    const avaliacoes = aula.avaliacoes

    if (!update) {
      if (avaliacoes.length === 5) {
        this.handleOpenDialog({
          titulo: "Erro ao Adicionar!",
          descricao: "Você só pode adicionar 5 Avaliações."
        })
        this.setState({
          avaliacao: initalState.avaliacao,
        })
        return
      }
    }

    if (avaliacao.index) {
      const index = avaliacoes.findIndex(param => param.index === avaliacao.index)

      avaliacoes[index] = {
        avalaula_id: avaliacao.avalaula_id,
        avalaula_descricao: avaliacao.avalaula_descricao,
        avalaula_nota: avaliacao.avalaula_nota,
        index: avaliacao.index
      }
    } else {
      const index = avaliacoes.length + 1

      avaliacoes.push({
        avalaula_descricao: avaliacao.avalaula_descricao,
        avalaula_nota: avaliacao.avalaula_nota,
        index
      })
    }

    aula.avaliacoes = avaliacoes

    this.setState({
      update: false,
      aula,
      avaliacao: initalState.avaliacao,
    })

  }

  validateAvaliacao() {
    const { avaliacao } = this.state

    if (!avaliacao.avalaula_descricao) return false
    if (!avaliacao.avalaula_nota) return false

    return true
  }

  editarItem(index) {
    const aux = this.state.aula.avaliacoes.filter(param => param.index === index)

    this.setState({
      update: true,
      avaliacao: aux[0]
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    this.setState({
      aula: {
        _id: "",
        aula_nome: "",
        aula_book_id: "",
        aula_duracao: "",
        aula_tipo: "",
        aula_cor: "#000000",
        aula_cor_texto: "#ffffff",
        aula_agend_online: false,
        aula_ativo: true,
        aula_soma_min_aval: 0,
        aula_aluno_minimo: '',
        aula_aluno_maximo: '',
        aula_limite_aluno: '',
        aula_aluno_padrao: '',
        aula_conversacao: false,
        aula_nivel_inicial: '',
        aula_nivel_final: '',
        aula_ordem: 0,
        avaliacoes: []
      }
    })
    const self = this
    setTimeout(() => {
      self.props.history.push("/aula");
    }, timeout)
    this.setState({
      aula: initalState.aula,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { aula } = this.state

    if (!aula.aula_nome) return false
    if (!aula.aula_tipo) return false

    if (aula.aula_tipo === 'UNIDADE') {
      if (!aula.aula_book_id) return false
    }

    if (aula.aula_tipo !== 'EVENTO') {
      if (!aula.avaliacoes.length === 0) return false
      if (!aula.aula_soma_min_aval) return false
    }

    // if (aula.aula_tipo === 'EVENTO') {
    //   if (!aula.unidades.length === 0) return false
    // }

    if (!aula.aula_tipo_horario) return false
    if (!aula.aula_duracao) return false
    if (!aula.aula_cor) return false
    if (!aula.aula_cor_texto) return false
    if (!aula.aula_ordem) return false
    if (!aula.aula_aluno_minimo) return false
    if (!aula.aula_aluno_maximo) return false
    if (!aula.aula_aluno_padrao) return false
    if (!aula.aula_nivel_inicial) return false
    if (!aula.aula_nivel_final) return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { aula } = this.state

    if (aula.aula_tipo === 'EVENTO') {
      delete aula.aula_book_id
      delete aula.avaliacoes
      delete aula.aula_soma_min_aval
    }

    if (aula.aula_tipo !== 'EVENTO') {
      delete aula.unidades
    }

    if (aula.aula_book_id === '') {
      delete aula.aula_book_id
    }

    aula.aula_limite_aluno = aula.aula_aluno_padrao

    try {
      if (aula._id !== "") {
        await axios.put(`${process.env.REACT_APP_API_URL}/aula/${aula._id}`, aula, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/aula`, aula, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })

      this.setState({
        aula: {
          _id: "",
          aula_nome: "",
          aula_book_id: "",
          aula_duracao: "",
          aula_tipo: "",
          aula_cor: "#000000",
          aula_cor_texto: "#ffffff",
          aula_agend_online: false,
          aula_ativo: true,
          aula_soma_min_aval: 0,
          aula_aluno_minimo: '',
          aula_aluno_maximo: '',
          aula_limite_aluno: '',
          aula_aluno_padrao: '',
          aula_conversacao: false,
          aula_nivel_inicial: '',
          aula_nivel_final: '',
          aula_ordem: 0,
          avaliacoes: []
        }
      })

      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  removerItem(index) {
    const aula = this.state.aula

    let avaliacoes = aula.avaliacoes

    avaliacoes.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < avaliacoes.length; i++) {
      aux.push({
        avalaula_id: avaliacoes[i].avalaula_id,
        avalaula_descricao: avaliacoes[i].avalaula_descricao,
        avalaula_nota: avaliacoes[i].avalaula_nota,
        index: i + 1
      })
    }

    avaliacoes = aux

    aula.avaliacoes = avaliacoes

    this.setState({
      aula
    })
  }

  render() {
    const { aula, avaliacao, update } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Aula</h2>
                </Grid>
              </Grid>
              <FormAula
                book={this.state.book}
                books={this.state.books.list}
                dados={aula}
                updateField={e => this.updateField(e)}
                updateFieldAtivo={e => this.updateFieldAtivo(e)}
                updateFieldAgendamento={e => this.updateFieldAgendamento(e)}
                updateFieldAvaliacao={e => this.updateFieldAvaliacao(e)}
                updateFieldBook={(e, value) => this.updateFieldBook(e, value)}
                updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
                addItem={e => this.addItem()}
                unidades={this.state.unidades.list}
                avaliacao={avaliacao}
                update={update} />

              {aula.aula_tipo !== '' &&
                <React.Fragment>
                  {aula.aula_tipo !== 'EVENTO' &&
                    <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                      <hr />
                      <Table
                        editar={e => this.editarItem(e)}
                        remove={e => this.removerItem(e)}
                        headCell={this.state.cabecalhoTabela}
                        rows={this.state.aula.avaliacoes.sort((a, b) => (a.avalaula_id > b.avalaula_id) ? 1 : ((b.avalaula_id > a.avalaula_id) ? -1 : 0)).map(avaliacao => {
                          return {
                            _id: avaliacao.avalaula_id,
                            avalaula_descricao: avaliacao.avalaula_descricao,
                            avalaula_nota: avaliacao.avalaula_nota,
                            index: avaliacao.index,
                          }
                        })}
                        acoes={this.state.acoesTabela}
                        noRemove={false} />
                      <hr />
                    </Grid>

                  }
                </React.Fragment>
              }

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}