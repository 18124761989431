import React from 'react'
import {
  Grid, TextField, FormControlLabel, Switch
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import Table from '../../components/Table'

const cabecalhoTabela = [
  { id: 'tempver_versao', numeric: false, disablePadding: true, label: 'Versão' },
  { id: 'tempver_publicado', numeric: false, disablePadding: true, label: 'Publicado' },
  { id: 'tempver_datainicio', numeric: false, disablePadding: true, label: 'Data de Inicio' },
  { id: 'tempver_ativo', numeric: false, disablePadding: true, label: 'Ativo' },
  { id: 'tempver_congelado', numeric: false, disablePadding: true, label: 'Congelado' }
]

const acoesTabela = [ 'imprimirDocVers', 'updateVersaoDoc', 'copy']

const FormDocumento = ({ dados, updateField, cursos, updateFieldCurso, templates,
  updateFieldPublicado, update, editarVersao, copiarVersao, updateVersao, congelado,
  cursosSelecionadas,imprimirDoc, updateFieldAssinarDocumento }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={4}>
        <TextField 
          className="input"
          label="Documento*"
          variant="outlined"
          name="tempdoc_titulo"
          value={dados.tempdoc_titulo}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={congelado}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={3}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="tempdoc_tipo"
          value={dados.tempdoc_tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={congelado}
        >
          <option value=""> Selecionar Tipo </option>
          <option value="EMBRANCO"> Em Branco </option>
          <option value="PREENCHIDO"> Preenchido </option>
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={5}>
        <Autocomplete
          multiple
          limitTags={4}
          id="multiple-limit-tags"
          options={cursos.filter(curso => {
            if (cursosSelecionadas.findIndex(i => i.curso_id === curso.curso_id) === -1){
              return curso;
            }
            return false
          })}
          size="small"
          value={cursosSelecionadas}
          onChange={(event, value) => updateFieldCurso(event, value)}
          getOptionLabel={(option) => option.curso_nome}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Cursos" />
          )}
        />


        {/* <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={cursos}
          disableCloseOnSelect
          getOptionLabel={(option) => option.curso_nome}
          limitTags={2}
          value={dados.vinculos}
          onChange={((event, value) => updateFieldCurso(event, value))}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.curso_nome}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} className="input" variant="outlined" label="Cursos*" size="small" InputLabelProps={{ shrink: true }} />
          )}
        /> */}
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="tempdoc_assinar"
              color="primary"
              checked={dados.tempdoc_assinar}
            />
          }
          size="small"
          label='Assinar Documento?'
          name="tempdoc_assinar"
          onChange={(e) => updateFieldAssinarDocumento(e)}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <TextField
          id="standard-select-currency"
          select
          label="Template*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="tempdoc_tempvar_id"
          value={dados.tempdoc_tempvar_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={congelado}
        >
          <option value=""> Selecionar Template </option>
          {templates.map(template => {
            return (
              <option key={template.tempvar_id} value={template.tempvar_id}> {template.tempvar_descricao} </option>
            )
          })}
        </TextField>
      </Grid>
    </Grid>
    <br />
    <hr />

    {update &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h4>Versões:</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <Table 
            copiarVersao={e => copiarVersao(e)} 
            editarVersao={e => editarVersao(e)}
            headCell={cabecalhoTabela} 
            rows={dados.versoes} 
            acoes={acoesTabela} 
            imprimir={e => imprimirDoc(e)}/>
          </Grid>
        </Grid>
      </React.Fragment>
    }

    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={2} xs={12} sm={3}>
        <TextField className="input" label="Versão*" variant="outlined" size="small" name="tempver_versao" value={dados.tempver_versao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={updateVersao} />
      </Grid>
      {dados.tempver_publicado &&
        <React.Fragment>
          <Grid item md={3} xs={12} sm={3}>
            <TextField type="date" className="input" label="Data de Início*" variant="outlined" size="small" name="tempver_datainicio" value={dados.tempver_datainicio} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={true} />
          </Grid>
        </React.Fragment>
      }
      <Grid item md={2} xs={12} sm={3}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="tempver_publicado"
              color="primary"
              checked={dados.tempver_publicado}
            />
          }
          size="small"
          label={dados.tempver_publicado ? 'Publicado' : 'Publicado'}
          name="tempver_publicado"
          onChange={(e) => updateFieldPublicado(e)}
        />
      </Grid>
    </Grid>
    
  </React.Fragment>
)

export default FormDocumento