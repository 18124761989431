import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, FormControlLabel, Switch, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment'

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid blue',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Alterar Historico Aluno</h5>
            </Grid>
          </Grid>
					<br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p style={{marginBottom: '0px'}}> <b>Dados da Aula:</b></p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={12} sm={6}>
              <p style={{marginBottom: '0px'}}> Aula: {props.historicoSelecionado.agenda.aula.aula_nome}</p>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <p style={{marginBottom: '0px'}}> Data: {moment(props.historicoSelecionado.agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm')}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p style={{marginBottom: '0px'}}> Professor: {props.historicoSelecionado.agenda.professor.funcionario.fisica.pesf_nome}</p>
            </Grid>
          </Grid>


          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p style={{marginBottom: '0px'}}> <b>Aluno Presente:</b></p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <div className="switchSchedule">
                <FormControlLabel
                  control={
                    <Switch
                      name="presente"
                      color="primary"
                      checked={props.historicoSelecionado.presente}
                    />
                  }
                  label={props.historicoSelecionado.presente ? "Presente" : "Ausente"}
                  name="presente"
                  onChange={(e) => props.updatePresenca()}
                />
              </div>
            </Grid>
          </Grid>
          {props.historicoSelecionado.historicos.length > 0 &&
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <p style={{marginBottom: '0px', marginTop: '10px'}}><b>Avaliações:</b></p>
              </Grid>
            </Grid>
          }
          {props.historicoSelecionado.historicos.map(historico => {
            return (
              <Grid style={{marginBottom: '20px'}} container direction="row" spacing={1} className='cardEndereco'>
                <Grid item md={8} xs={12} sm={12}>
                  <p style={{margin: '15px 0'}}>{historico.avaliacao.avalaula_descricao}:</p>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="number" fullWidth variant="outlined" size="small" name="hisaluno_nota_aval" value={historico.hisaluno_nota_aval} onChange={(e) => props.updateNotaAvaliacao(e, historico.hisaluno_id)} InputLabelProps={{ shrink: true }} disabled={!props.historicoSelecionado.presente} />
                </Grid>
                <Grid item md={1} xs={12} sm={6}>
                  <span className="spanTabelaSchedule">/{historico.avaliacao.avalaula_nota}</span>
                </Grid>
              </Grid>
            )
          })}
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)}>Sim</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div >
  );
}
