
import React, { Component } from 'react'
import './CompraProduto.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';

const initalState = {
  vendas: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'usuario_compra', numeric: false, disablePadding: false, label: 'Usu. Lanç.' },
    { id: 'pedcomp_numero', numeric: true, disablePadding: true, label: 'Núm. Pedido' },
    { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'pedcomp_data_compra', numeric: false, disablePadding: false, label: 'Data da Compra', orderBy: 'pedcomp_data_compraOrder' },
    { id: 'pedcomp_situacao', numeric: false, disablePadding: false, label: 'Situação' },
  ],
  acoesTabela: ['view'],
  filtro: {
    pedcomp_numero: "",
    pedcomp_unidade_id: "",
    pedcomp_situacao: "",
    data_inicial: "",
    data_final: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

export default class VendaProduto extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'compra-prod-unidade-central')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    await this.filtrar()

    this.setState({
      acoesTabela,
      permissoes,
      loading: false
    })
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async filtrar(){
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
      const filtro = this.state.filtro

      const dados = {
        ...filtro,
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      let {data} = await axios.post(`${process.env.REACT_APP_API_URL}/pedidoCompra/listUnidade`,dados,  this.getToken())
      
      this.setState({
        vendas: {
          list: data.map(value => {
            let situacao = ''

            if(value.pedcomp_situacao === 'AGUARDANDO_PAGAMENTO'){
              situacao = 'Aguardando Pagamento'
            }

            if(value.pedcomp_situacao === 'FINALIZADO'){
              situacao = 'Finalizado'
            }

            if(value.pedcomp_situacao === 'CANCELADO'){
              situacao = 'Cancelado'
            }

            return {
              _id: value.pedcomp_id,
              unidade: value.unidade_compra.unin_numero + ' - ' + value.unidade_compra.unin_descricao,
              pedcomp_numero: value.pedcomp_numero,
              pedcomp_data_compra: moment(value.pedcomp_data_compra).format('DD/MM/YYYY'),
              pedcomp_situacao: situacao,
              usuario_compra: value.usuario_compra.pessoa.fisica.pesf_nome
            }
          })
        }
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  updateFiltro(event){
    let {filtro} = this.state
    filtro[event.target.name] = event.target.value
    this.setState({filtro})
  }

  render() {
    const { filtro, vendas, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Compras...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Compras</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={3} xs={12}>
                    <Link to="/compra_prod_unidade_central/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Compra
								      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Num. Venda" variant="outlined" size="small" name="pedcomp_numero" value={filtro.pedcomp_numero} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="date" className="input" label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="date" className="input" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situacao"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="pedcomp_situacao"
                    value={filtro.pedcomp_situacao}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> </option>
                    <option value="AGUARDANDO_PAGAMENTO">Aguardando Pagamento</option>
                    <option value="FINALIZADO">Finalizado</option>
                    <option value="CANCELADO">Cancelado</option>
                  </TextField>
                </Grid>
                <Grid item md={1} xs={12} sm={1}>
                  <Button color='primary' className='btn_search_func' fullWidth variant="contained" endIcon={<SearchIcon />} onClick={e => this.filtrar(e)}></Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    headCell={this.state.cabecalhoTabela} 
                    rows={vendas.list} 
                    acoes={this.state.acoesTabela} 
                    urlUpdate="/compra_prod_unidade_central/cadastro/" 
                  />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <Footer history={this.props.history} />
      </div>
    )
  }
}