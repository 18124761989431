import api from './api'

export default function getEndereco(cep) {
  try {
    let cepSemFormatacao = cep.replace("-", "")
    cepSemFormatacao = cepSemFormatacao.replace(".", "")
    return api.get(`${process.env.REACT_APP_API_URL}/cep/${cepSemFormatacao}`)
      .then(resp => {
        return resp.data
      }).catch(error => {
        console.log(error);
        return error
      })
  } catch (error) {
    console.log(error);
    return error
  }

}
