import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import CurrencyInput from './../mask/CurrencyInput'

function getModalStyle() {

  return {
   
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '96%',
    marginLeft: '2%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

const dataHoje = moment(new Date()).format('YYYY-MM-DD')

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {dados, tabelaValores} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação Prorrogação</h5>
            </Grid>
          </Grid>
          <br />
					<Grid container direction="row" spacing={1}>
						<Grid item md={3} xs={12} sm={3}>
							<TextField className="input" label="Data Fim Vigência Atual" size='small' variant="outlined" value={moment(dados.contm_data_fim_vigencia_orig).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled/>
						</Grid>
						<Grid item md={2} xs={12} sm={3}>
						<TextField className="input" type='number' label="Qtd Dias*" variant="outlined" size='small' value={dados.qtdDias} name='qtdDias' InputLabelProps={{ shrink: true }} onChange={(e) => props.updateField(e)}/>
						</Grid>
						<Grid item md={3} xs={12} sm={3}>
							<TextField className="input" label="Nova Data Fim Vigência" size='small' variant="outlined" value={moment(dados.contm_data_fim_vigencia_nova).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled/>
						</Grid>
						<Grid item md={4} xs={12} sm={3}>
							<TextField className="input" label="Comercial" size='small' variant="outlined" value={dados.nome_func} InputLabelProps={{ shrink: true }} disabled/>
							{/* <TextField
								id="standard-select-currency"
								select
								label="Comercial"
								variant="outlined"
								className="input"
								fullWidth
								size="small"
								SelectProps={{
									native: true,
								}}
								name="fun_id"
								value={dados.fun_id}
								onChangeCapture={(e) => props.updateField(e)}
								InputLabelProps={{ shrink: true }}
								disabled
							>
								<option value={dados.fun_id === "" ? "" : dados.fun_id}> {dados.fun_id === "" ? "Selecionar Comercial" : dados.nome_func} </option>
								{props.funcionarios.list.map(funcionario => {
									if (funcionario.fun_id !== dados.fun_id) {
										return (
											<option key={funcionario.fun_id} value={funcionario.fun_id}> {funcionario.pesf_nome} </option>
										)
									} else {
										return false
									}
								})}
							</TextField> */}
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={1} className="mg_top_10">
						<Grid item md={4} xs={12} sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Lançar Mensalidades?</FormLabel>
								<FormControlLabel className="mg_left_10"
									control={
										<Switch
											name="Ativo"
											color="primary"
											checked={dados.lanc_fin}
										/>
									}
									size="small"
									label={dados.lanc_fin ? 'Sim' : 'Não'}
									name="lanc_fin"
									onChange={(e) => props.updateFieldLancFinanceiro(e)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					{dados.lanc_fin &&
						<React.Fragment >
							<hr />
							<Grid container direction="row" spacing={1} className="mg_top_10">
								<Grid item md={4} xs={12} sm={4}>
									<TextField
										id="standard-select-currency"
										select
										variant="outlined"
										className="input"
										fullWidth
										label="Plano Pagamento*"
										size="small"
										SelectProps={{
											native: true,
										}}
										name="contm_tabval_id"
										value={dados.contm_tabval_id}
										onChangeCapture={(e) => props.updateField(e)}
										InputLabelProps={{ shrink: true }}
									>
										<option value={dados.contm_tabval_id !== '' ? dados.contm_tabval_id : ''}>{dados.contm_tabval_id !== '' ? dados.tabval_nome : '--Escolha--'}</option>
										{tabelaValores.filter(param => param.planpag_ativo === true).map(plano => {
											if (plano.tabelas_valores.filter(param => param.tabval_ativo === true).length > 0) {
												return (
													<optgroup key={plano.planpag_id} label={plano.planpag_descricao}>
														{plano.tabelas_valores.filter(param => param.tabval_ativo === true).map(tabela => {
															return (
																<option key={tabela.tabval_id} value={tabela.tabval_id}>{tabela.tabval_nome}</option>
															)
														})}
													</optgroup>
												)
											} else {
												return false
											}

										})}
									</TextField>
								</Grid>
								<Grid item md={2} xs={12} sm={4}>
									<TextField fullWidth className="input" label="Mensalidade Total" variant="outlined" size="small" name="tabval_val_mensal_total" value={dados.tabela.tabval_val_mensal_total} InputLabelProps={{ shrink: true }} disabled />
								</Grid>
								<Grid item md={2} xs={12} sm={4}>
									<TextField fullWidth className="input" label="N. de Parcelas" variant="outlined" size="small" name="tabval_num_parc" value={dados.tabela.tabval_num_parc} InputLabelProps={{ shrink: true }} disabled />
								</Grid>
								<Grid item md={2} xs={12} sm={4}>
									<TextField fullWidth className="input" label="Parcela com desc." variant="outlined" size="small" name="tabval_val_mensal" value={dados.tabela.tabval_val_mensal} InputLabelProps={{ shrink: true }} disabled />
								</Grid>
								<Grid item md={2} xs={12} sm={4}>
									<TextField fullWidth className="input" label="Parcela sem desc." variant="outlined" size="small" name="tabval_val_cheio_mensal" value={dados.tabela.tabval_val_cheio_mensal} InputLabelProps={{ shrink: true }} disabled />
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1} className="mg_top_10">
								<Grid item md={3} xs={12} sm={6}>
									<TextField
										size="small"
										type="date"
										className="input"
										label="Data Vcto 1ª Mensalidade*"
										variant="outlined"
										name="contm_data_vct_primeira_mensalidade"
										value={dados.contm_data_vct_primeira_mensalidade}
										onChange={(e) => props.updateField(e)}
										InputLabelProps={{ shrink: true }}
										onBlur={(e) => props.verifyFinishedDate(e)}
										InputProps={{ inputProps: { min: dataHoje } }}
									/>
								</Grid>
								<Grid item md={3} xs={12} sm={4}>
									<TextField
										id="standard-select-currency"
										select
										variant="outlined"
										className="input"
										fullWidth
										label="Forma de Pagamento Mensalidades*"
										size="small"
										SelectProps={{
											native: true,
										}}
										name="formapagto_id"
										value={dados.formapagto_id}
										onChangeCapture={(e) => props.updateField(e)}
										InputLabelProps={{ shrink: true }}
									>
										<option value="">--Escolha--</option>
										{props.formasPagamento.map(forma => {
											return (
												<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
											)
										})}
									</TextField>
								</Grid>
								<Grid item md={3} xs={12} sm={4}>
									<TextField
										id="standard-select-currency"
										select
										variant="outlined"
										className="input"
										fullWidth
										label="Conta Financeira"
										size="small"
										SelectProps={{
											native: true,
										}}
										name="contfin_id"
										value={dados.contfin_id}
										onChangeCapture={(e) => props.updateField(e)}
										InputLabelProps={{ shrink: true }}
									>
										<option value="">--Escolha--</option>
										{props.contasFinanceira.filter(param => param.contfin_tipo_conta === (dados.formapagto_id === '1' ? 'COFRE': 'BANCO')).map(conta => {
											return (
												<option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
											)
										})}
									</TextField>
								</Grid>
							</Grid>
						</React.Fragment>
					}

					<hr className="mg_top_10" />
					<Grid container direction="row" spacing={1} className="mg_top_10">
						<Grid item md={4} xs={12} sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Lançar Taxa Administrativa?</FormLabel>
								<FormControlLabel className="mg_left_10"
									control={
										<Switch
											name="Ativo"
											color="primary"
											checked={dados.lanc_fin_taxa}
										/>
									}
									size="small"
									label={dados.lanc_fin_taxa ? 'Sim' : 'Não'}
									name="lanc_fin_taxa"
									onChange={(e) => props.updateFieldLancFinanceiroTaxa(e)}
								/>
							</FormControl>
						</Grid>
					</Grid>

					{dados.lanc_fin_taxa &&
						<React.Fragment>
							<Grid container direction="row" spacing={1}>
								<Grid item md={12} xs={12} sm={12}>
									<h5>Taxa Administrativa:</h5>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1} className="mg_top_20">
								<Grid item md={2} xs={12} sm={6}>
									<TextField className="input" label="Tipo da Conta" variant="outlined" value={props.conta.contpr_tipo} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={4} xs={12} sm={6}>
									<TextField className="input" label="Plano de Contas" variant="outlined" value={`${props.conta.plancontas_codigo} - ${props.conta.plancontas_descricao}`} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={4} xs={12} sm={6}>
									<TextField className="input" label="Descrição*" variant="outlined" name="contpr_descricao" value={props.conta.contpr_descricao} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={2} xs={12} sm={6}>
									<TextField className="input" label="Valor*" variant="outlined" name="contpr_valortotal" value={props.conta.contpr_valortotal} onChange={(e) => props.updateFieldConta(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1} className="mg_top_10">
								<Grid item md={12} xs={12} sm={12}>
									<div className='scroll'>
										<table className="lista sempadding borderTable">
											<thead>
												<tr className="titulos acompanha">
													<th>Parcelas</th>
													<th>Valor*</th>
													<th>Vencimento*</th>
													<th>Forma de Pagamento*</th>
													<th>Conta Financeira</th>
													<th>Documento</th>
												</tr>
											</thead>
											<tbody>
												{props.conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
													return (
														<tr key={parcela.index}>
															<td>
																<TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
																<span className="separacaoParcelas">/</span>
																<TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={props.conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) => props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
															</td>
															<td>
																<TextField className="inputValor" variant="outlined" size="small" name="parcont_valorparcela" value={parcela.parcont_valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={props.travarParcelas}/>
															</td>
															<td>
																<TextField type="date" className="inputVencimento" variant="outlined" size="small" name="parcont_datavencimento" value={parcela.parcont_datavencimento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
															</td>
															<td>
																<TextField
																	id="standard-select-currency"
																	select
																	variant="outlined"
																	className="inputFormPagamento"
																	fullWidth
																	size="small"
																	SelectProps={{
																		native: true,
																	}}
																	name="parcont_formapagto_id"
																	value={parcela.parcont_formapagto_id}
																	onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
																	InputLabelProps={{ shrink: true }}
																>
																	<option value="">--Escolha--</option>
																	{props.formasPagamento.map(forma => {
																		return (
																			<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
																		)
																	})}
																</TextField>
															</td>
															<td>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="inputConta"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="contfin_id"
                                  value={parcela.contfin_id}
                                  onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  <option value="">--Escolha--</option>
                                  {props.contasFinanceira.filter(param => param.contfin_tipo_conta === (parcela.parcont_formapagto_id === 1 ? 'COFRE': 'BANCO')).map(conta => {
                                    return (
                                      <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
                                    )
                                  })}
                                </TextField>
                              </td>
															<td>
																<TextField className="inputDocumento" variant="outlined" size="small" name="parcont_documento" value={parcela.parcont_documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								</Grid>
							</Grid>
							<br />
							<hr />
							<br /> 
						</React.Fragment>
					}

					<hr className="mg_top_10" />

					<Grid container direction="row" spacing={1} className="mg_top_10">
						<Grid item md={12} xs={12} sm={12}>
							<TextField
								id="outlined-multiline-static"
								label="Observação"
								className="input"
								multiline
								rows={5}
								size="small"
								variant="outlined"
								name="observacao"
								value={dados.observacao}
								onChange={(e) => props.updateField(e)}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					</Grid>

					<Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente prorrogar esse contrato?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.disabledButtonProrrogar}>Sim</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.disabledButtonProrrogar}>Não</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
