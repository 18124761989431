import React from 'react'; 
import { Grid } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import real from './../../../services/real'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const calcularValorTotal = (cuponsSelecionados, tabela_selecionada) => {
  let valor_total = tabela_selecionada.tabval_val_mensal * tabela_selecionada.tabval_num_parc
  
  for (const cupom of cuponsSelecionados) {
    if(cupom.cupom_tipo === "PORCENTAGEM"){
      valor_total = valor_total - ((cupom.cupom_valor / 100) *  valor_total)
    }else{
      valor_total = valor_total - cupom.cupom_valor
    }
  }

  return valor_total
}

const calcularValorPrimeiraParcela = (cuponsSelecionados, tabela_selecionada) => {
  let valor_parcela = tabela_selecionada.tabval_val_mensal
  
  for (const cupom of cuponsSelecionados) {
    if(cupom.cupom_tipo === "PORCENTAGEM"){
      valor_parcela = valor_parcela - ((cupom.cupom_valor / 100) *  valor_parcela)
    }else{
      valor_parcela = valor_parcela - (cupom.cupom_valor / tabela_selecionada.tabval_num_parc)
    }
  }

  let valor_total = calcularValorTotal(cuponsSelecionados, tabela_selecionada)
  let diferenca = (parseFloat((valor_parcela).toFixed(2)) * tabela_selecionada.tabval_num_parc) - parseFloat((valor_total).toFixed(2))

  return real(valor_parcela - parseFloat((diferenca).toFixed(2)))
}

export default function Pagamento(props) {
  return (
    <React.Fragment>
      <Grid container direction="row" spacing={2}>
        <Grid item md={1} xs={12} sm={1}></Grid>
        <Grid item md={10} xs={12} sm={10} style={{textAlign: 'center'}}>
          <h5 className='titulo'>Segue a baixo os dados para o pagamento da primeira mensalidade:</h5 >
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={2}>
        <Grid item md={3} xs={12} sm={1}></Grid>
        <Grid item md={4} xs={12} sm={10}>
          <p>Responsavel Financeiro: {props.aluno.tipoCadastro === 'aluno' ? props.aluno.nome : props.aluno.responsavel.nome}</p>
          <p>Valor: <b> R$ {props.valorPrimeira || calcularValorPrimeiraParcela(props.cuponsSelecionados, props.tabela_selecionada)}</b></p>
          <p>Forma de Pagamento: {parseInt(props.aluno.formpag_id) === 3 ? 'Boleto' : 'Pix'}</p>
        </Grid>
        <Grid item md={4} xs={12} sm={10}>
          <p>CPF: {props.aluno.tipoCadastro === 'aluno' ? props.aluno.cpf : props.aluno.responsavel.cpf}</p> 
          <p>Data Vencimento: {moment(props.aluno.vencimento_mensalidade).format('DD/MM/YYYY')}</p>
        </Grid>
      </Grid>

      {props.boletoSelecionado.tipo_transaction === 'BOLETO' &&
        <Grid container direction="row" spacing={2}>
          <Grid item md={1} xs={12} sm={1}></Grid>
          <Grid item md={10} xs={12} sm={10} style={{textAlign: 'center'}}>
            <iframe title="Boleto"  style={{width: '100%', height: '1200px'}} src={props.boletoSelecionado.bank_slip.url_slip_pdf} ></iframe>
          </Grid>
        </Grid>
      }

      {props.boletoSelecionado.tipo_transaction === 'PIX' &&
        <React.Fragment>
          <Grid container direction="row" spacing={2}>
            <Grid item md={5} xs={12} sm={1}></Grid>
            <Grid item md={2} xs={12} sm={10} style={{textAlign: 'center'}}>
              <Tooltip title='Copiar QRCode' aria-label="add">
                <img onClick={e => props.copyLink(props.boletoSelecionado.bank_slip.url_slip_pdf)} style={{width: '100%', cursor: 'pointer'}} src={props.boletoSelecionado.bank_slip.url_slip_pdf} alt="qrcode" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row">
            <Grid item md={12} xs={12} sm={12}>
              <Tooltip title='Copiar Texto' aria-label="add">
                <p onClick={e => props.copyLink(props.boletoSelecionado.bank_slip.boleto_digitable_line)} style={{fontSize: '16px', textAlign: 'center', cursor: 'pointer'}}><b>PIX COPIA E COLA</b></p>
              </Tooltip>
            </Grid>
          </Grid>
        </React.Fragment>
      }

      <br />
      <hr />
      <br />

      <Grid container direction="row" spacing={2}>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}>Após o pagamento utilize os seguintes links:</span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}><a target='blank' href="https://aluno.eduqtecnologia.com.br/dominio?britishandamerican"><ArrowRightIcon style={{marginTop: '-5px'}}/>LINK DA PLATAFORMA*</a></span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center', marginTop: '-20px'}}>
          <span style={{fontSize: '18px'}}>*Para o primeiro acesso ao Portal EAD, a sua senha é o seu CPF</span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}><a target='blank' href={`${process.env.REACT_APP_URL}/portal_aluno/${props.aluno.pesf_pessoa_id}`}><ArrowRightIcon style={{marginTop: '-5px'}}/>LINK DO PORTAL FINANCEIRO*</a></span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center', marginTop: '-20px'}}>
          <span style={{fontSize: '18px'}}>*Para o acesso ao Portal Financeiro, a sua senha é o seu CPF</span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}><a target='blank' href='https://wa.me/5544991593770'><ArrowRightIcon style={{marginTop: '-5px'}}/>WHATSAPP - Para Qualquer Duvida</a></span >
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} className="mg_top_20">
        <Grid item md={5} xs={12} sm={4}></Grid>
        <Grid item md={2} xs={12} sm={4}>
          <img src="https://system-british-no-delete.s3.amazonaws.com/logo_2.png" alt="logo" />
        </Grid>                
      </Grid> 
    </React.Fragment>
  )
}