import React, { Component } from 'react'
import './Feriado.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormFeriado from '../../components/forms/FormFeriado'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment';

const initalState = {
  feriado: {
    fer_id: "",
    fer_data: "",
    fer_descricao: "",
    fer_tipo: "",
    unidades: []
  },
  feriados: {
    list: []
  },
  unidades: {
    list: []
  },
  unin_tipo: "",
  unidadesSelecionadas: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true
}

export default class CadastroCargo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'feriados')[0]

    this.setState({
      permissoes
    })

    if (params.feriadoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    if (params.feriadoId) {
      try {
        const { data: feriado } = await axios.get(`${process.env.REACT_APP_API_URL}/feriados/${params.feriadoId}`, this.getToken())

        if (unin_tipo === 'FRANQUEADO') {
          if (feriado.fer_tipo === "NACIONAL") {
            this.handleOpenDialog({
              titulo: "Opps!",
              descricao: "Você não tem permissão para editar esse feriado!"
            })

            this.backPage()
            return false
          }
        }

        if (unin_tipo === 'FRANQUEADOR') {
          if (feriado.fer_tipo === "ESTADUAL" || feriado.fer_tipo === "MUNICIPAL") {
            this.handleOpenDialog({
              titulo: "Opps!",
              descricao: "Você não tem permissão para editar esse feriado!"
            })

            this.backPage()
            return false
          }
        }

        const dataFeriado = moment(feriado.fer_data, moment.defaultFormat).toDate()
        dataFeriado.setMinutes(dataFeriado.getMinutes() + dataFeriado.getTimezoneOffset() + 60)

        this.setState({
          feriado: {
            fer_id: feriado.fer_id,
            fer_descricao: feriado.fer_descricao,
            fer_tipo: feriado.fer_tipo,
            fer_data: moment(dataFeriado).format('YYYY-MM-DD'),
          },
          unidadesSelecionadas: feriado.unidades.map(unidade => {
            return {
              unin_id: unidade.unin_id,
              unin_descricao: unidade.unin_descricao,
              unin_numero: unidade.unin_numero,
              ferunin_id: unidade.FeriadoUnidade.ferunin_id
            }
          })
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o feriado, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {
      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())

      this.setState({
        unidades: {
          list: unidades.map(unidade => {
            return {
              unin_id: unidade.unin_id,
              unin_descricao: unidade.unin_descricao,
              unin_numero: unidade.unin_numero,
            }
          })
        },
        loading: false
      })

    } catch (error) {
      console.log(error)
    }

  }

  updateField(event) {
    const feriado = { ...this.state.feriado }
    feriado[event.target.name] = event.target.value
    this.setState({ feriado })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 2000) {
    const self = this
    this.setState({
      produto: initalState.produto
    })
    setTimeout(() => {
      self.props.history.push("/feriado");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { feriado, unidadesSelecionadas, unin_tipo } = this.state

    if (!feriado.fer_data) return false
    if (!feriado.fer_descricao) return false

    if (unin_tipo === 'FRANQUEADO') {
      if (!feriado.fer_tipo) return false
      if (unidadesSelecionadas.length === 0) return false
    }

    return true
  }

  updateFieldUnidade(event, value) {
    this.setState({
      unidadesSelecionadas: value
    })
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { feriado, unin_tipo } = this.state

    const dados = {
      fer_data: feriado.fer_data,
      fer_descricao: feriado.fer_descricao,
      fer_tipo: unin_tipo === 'FRANQUEADOR' ? 'NACIONAL' : feriado.fer_tipo
    }

    if (unin_tipo === 'FRANQUEADO') {
      dados.unidades = this.state.unidadesSelecionadas
    }

    try {

      const method = feriado.fer_id !== "" ? 'put' : 'post'
      const url = feriado.fer_id !== "" ? `/feriados/${feriado.fer_id}` : `/feriados`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { feriado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Feriado</h2>
                </Grid>
              </Grid>
              <FormFeriado
                unidadesSelecionadas={this.state.unidadesSelecionadas}
                unidades={this.state.unidades.list}
                updateField={e => this.updateField(e)}
                dados={feriado}
                unin_tipo={this.state.unin_tipo}
                updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)} />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}