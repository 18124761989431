import React from 'react'

import { Grid, TextField } from '@material-ui/core'

const FormCargo = ({ dados, updateField, dadosUnidades }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idUnidade"
          value={dados.idUnidade}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          <option value={dados.unidade === "" ? "Selecionar Unidade" : dados.idUnidade}> {dados.unidade === "" ? "Selecionar Unidade" : dados.unidade} </option>
          {dadosUnidades.map(unidade => {
            return (
              <option key={unidade._id} value={unidade._id}> {`${unidade.numero} - ${unidade.descricao}`} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Descricao" variant="outlined" size="small" name="descricao" value={dados.descricao} onChange={(e) => updateField(e)} />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormCargo