import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField, FormControlLabel, Switch, FormControl, FormLabel} from '@material-ui/core'
import Table from './../Table'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const cabecalhoTabela = [
  { id: 'vardoc_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
  { id: 'vardoc_variavel', numeric: false, disablePadding: true, label: 'Variavel' }
]

const acoesTabela = ['selectVar']

const FormNotificacao = ({ dados, updateField, onEditorStateChange, editorState, selecionarVar, variaveisFiltrada, filtro, 
  updateFiltro, unidades, unidadesSelecionadas, updateFieldUnidade, updateFieldAtivo, formasSelecionadas, formasPagamento, updateFieldForma,
  situacoesContrato, situacoesSelecionadas, updateFieldSituacao }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Nome da Notificação*" 
          variant="outlined" 
          size="small" 
          name="nome_alerta" 
          value={dados.nome_alerta} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Titulo da Notificação*" 
          variant="outlined" 
          size="small" 
          name="titulo_alerta" 
          value={dados.titulo_alerta} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Modulo*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="modulo"
          value={dados.modulo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        >
          <option value=''>Selecionar Modulo</option>
          <option value='COBRANCA'> Cobrança Alunos</option>
          <option value='COBRANCA_UNIDADE'> Cobrança Unidade</option>
          <option value='AGENDA'> Agenda </option>
          <option value='ANIVERSARIO'> Aniversário </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Canal de Comunicação*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="canal"
          value={dados.canal}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        >
          <option value=''>Selecionar Canal</option>
          <option value='EMAIL'> Email </option>
          <option value='WHATSAPP'> WhatsApp </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          type="number"
          label="Qtd de Dias*" 
          variant="outlined" 
          size="small" 
          name="qtd_dias" 
          value={dados.qtd_dias} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      {dados.canal === 'EMAIL' &&
        <Grid item md={4} xs={12} sm={6}>
          <TextField 
            className="input" 
            type="text"
            label="Email de Resposta*" 
            variant="outlined" 
            size="small" 
            name="contato_resposta" 
            value={dados.contato_resposta} 
            onChange={(e) => updateField(e)}
            InputLabelProps={{ shrink: true}} 
          />
        </Grid>
      }
      <Grid item md={4} xs={12} sm={6}>
        <Autocomplete
          multiple
          limitTags={3}
          id="multiple-limit-tags"
          options={unidades.sort((a, b) => (a.numero > b.numero) ? 1 : ((b.numero > a.numero) ? -1 : 0)).filter(unidade => {
            if (unidadesSelecionadas.findIndex(i => i.id === unidade.id) === -1)
              return unidade;
            return false
          })}
          size="small"
          value={unidadesSelecionadas}
          onChange={(event, value) => updateFieldUnidade(event, value)}
          getOptionLabel={(option) => `${option.numero} - ${option.descricao}`}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Unidades*" InputLabelProps={{ shrink: true}} />
          )}
        />
      </Grid>
      {dados.modulo === 'COBRANCA' &&
        <React.Fragment>
          <Grid item md={4} xs={12} sm={2}>
            <Autocomplete
              multiple
              id="multiple-limit-tags"
              options={formasPagamento.filter(forma => {
                if (formasSelecionadas.findIndex(i => i.formpag_id === forma.formpag_id) === -1){
                  return forma;
                }else{
                  return false
                }
              })}
              size="small"
              value={formasSelecionadas}
              onChange={(event, value) => updateFieldForma(event, value)}
              getOptionLabel={(option) => option.formpag_descricao}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Formas de Pagto." InputLabelProps={{ shrink: true }}/>
              )}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={2}>
            <Autocomplete
              multiple
              id="multiple-limit-tags"
              options={situacoesContrato.filter(situacao => {
                if (situacoesSelecionadas.findIndex(i => i === situacao) === -1){
                  return situacao;
                }else{
                  return false
                }
              })}
              size="small"
              value={situacoesSelecionadas}
              onChange={(event, value) => updateFieldSituacao(event, value)}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Situação do Contrato." InputLabelProps={{ shrink: true }}/>
              )}
            />
          </Grid>
        </React.Fragment>
      }
      {dados.modulo === 'COBRANCA_UNIDADE' &&
        <Grid item md={4} xs={12} sm={2}>
          <Autocomplete
            multiple
            id="multiple-limit-tags"
            options={formasPagamento.filter(forma => {
              if (formasSelecionadas.findIndex(i => i.formpag_id === forma.formpag_id) === -1){
                return forma;
              }else{
                return false
              }
            })}
            size="small"
            value={formasSelecionadas}
            onChange={(event, value) => updateFieldForma(event, value)}
            getOptionLabel={(option) => option.formpag_descricao}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Formas de Pagto." InputLabelProps={{ shrink: true }}/>
            )}
          />
        </Grid>
      }
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          type="time"
          label="Hora do Envio*" 
          variant="outlined" 
          size="small" 
          name="hora_envio" 
          value={dados.hora_envio} 
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true}} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
      {dados.modulo === 'COBRANCA' &&
        <Grid item md={4} xs={12} sm={2}>
          <span style={{color: 'red'}}>Para notificações com data retroativa, informar o valor negativo. Ex. Para aviso de contas vencidas a partir de <b>um dia</b> usar o valor <b>-1</b>.</span>
        </Grid>
      }
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <span style={{color: 'red'}}>A Notificação ela será enviada no dia seguinte ao cadastro!</span>
      </Grid>
    </Grid>
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <h5 className='titulo'>Mensagem da Notificação:</h5>
      </Grid>
    </Grid>

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
      </Grid>
    </Grid>

    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid item md={12} xs={12}>
        <Table
          headCell={cabecalhoTabela}
          rows={variaveisFiltrada}
          acoes={acoesTabela}
          selecionarVar={e => selecionarVar(e)} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <div className="cardEditor">
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={e => onEditorStateChange(e)}
          />
        </div>
      </Grid>
    </Grid>
    <br />
    <hr />
  </React.Fragment>
)

export default FormNotificacao