import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p className='desc-ausencia'>Ausência:</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={12} sm={12}>
              <p className='desc-ausencia'>Data Inicial: {dados.austemp_data_inicial_form}</p>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <p className='desc-ausencia'>Data Final: {dados.austemp_data_final_form}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <p className='desc-ausencia'>Motivo: {dados.austemp_motivo}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja remarcar essa Ausência Temporária?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              {dados.remarcar &&
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" checked={true} />}
                  onClick={e => props.updateFieldRemarcar(e)}
                  label="Remarcar Ausência Temporária"
                  labelPlacement="end"
                />
              }
              {!dados.remarcar &&
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary" checked={false} />}
                  onClick={e => props.updateFieldRemarcar(e)}
                  label="Remarcar Ausência temporária"
                  labelPlacement="end"
                />
              }
            </Grid>
          </Grid>
          {dados.remarcar &&
            <Grid container direction="row" spacing={1}>
              <Grid item md={6} xs={12} sm={12}>
                <TextField
                  className="input"
                  label="Data Inicio*"
                  variant="outlined"
                  size="small"
                  type='date'
                  name="austemp_data_inicial"
                  value={dados.austemp_data_inicial}
                  onChange={(e) => props.updateData(e)}
                  InputLabelProps={{ shrink: true }}
                  disabled />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <TextField
                  className="input"
                  label="Nova Data Final*"
                  variant="outlined"
                  size="small"
                  type='date'
                  name="austemp_data_final_nova"
                  value={dados.austemp_data_final_nova}
                  onChange={(e) => props.updateData(e)}
                  InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='avisoConciliacao'><i>Esta operação não poderá ser desfeita.</i></p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.voltar()}>Voltar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar()}>{dados.remarcar ? 'Remarcar' : 'Excluir'}</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
