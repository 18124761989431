import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const dataHoje = moment().format('YYYY-MM-DD')

function getModalStyle() {
  return {
   
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '92%',
    marginLeft: '4%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {dados, cursos, curso, aluno, loadingSalvar} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Aditivo de Contrato</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10" >
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                className="input"
                label="Matricula"
                variant="outlined"
                name="codigo"
                value={aluno.aluno_matricula}
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <TextField
                className="input"
                label="Aluno"
                variant="outlined"
                name="codigo"
                value={`${aluno.pessoaFisica.nome}${aluno.pessoaFisica.sobrenome !== '' ? ` ${aluno.pessoaFisica.sobrenome}`: ''}`}
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={3} xs={12} sm={6}>
              {/* <Autocomplete
                onChange={(event, value) => props.updateFieldCurso(event, value)}
                freeSolo
                options={cursos}
                value={curso}
                getOptionLabel={option => option.curso_nome}
                renderInput={(params) => (
                  <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Curso*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={curso.curso_nome} />
                )}
              /> */}
              <TextField
                className="input"
                label="Código*"
                variant="outlined"
                name="curso_nome"
                value={dados.curso_nome}
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                className="input"
                label="Código*"
                variant="outlined"
                name="codigo"
                value={dados.codigo}
                disabled
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField type="date"
                className="input"
                label="Data Inicio Vigencia*"
                variant="outlined"
                name="data_inicio_vigencia"
                value={dados.data_inicio_vigencia}
                InputProps={{ inputProps: { min: dataHoje } }}
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Duração do Contrato*"
                variant="outlined"
                className="input"
                fullWidth
                SelectProps={{
                  native: true,
                }}
                name="duracao"
                value={dados.duracao}
                disabled
                InputLabelProps={{ shrink: true }}
              >
                <option value=""> Selecionar Duração do Contrato </option>
                {curso.duracoes.map(duracao => {
                  return (
                    <option value={duracao.durcur_tempo}> {duracao.durcur_tempo} Meses </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField type="date"
                className="input"
                label="Data Final Vigencia*"
                variant="outlined"
                name="data_fim_vigencia"
                value={dados.data_fim_vigencia}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
          </Grid>
          {dados.curso_id === 2 &&
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={4} xs={12} sm={4}>
                  <Autocomplete
                    multiple
                    limitTags={8}
                    id="multiple-limit-tags"
                    options={props.modulos}
                    size="small"
                    value={props.modulosSelecionadas}
                    disabled
                    getOptionLabel={(option) => `${option.num} (${option.value})`}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Modulos" InputLabelProps={{ shrink: true }} disabled/>
                    )}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Inicio do Curso"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="inicio_curso"
                    value={dados.inicio_curso}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    <option value="1º Semestre">1º Semestre</option>
                    <option value="2º Semestre">2º Semestre"</option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Mês de Inicio do Curso"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="mes_inicio_curso"
                    value={dados.mes_inicio_curso}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    <option value="Janeiro">Janeiro</option>
                    <option value="Fevereiro">Fevereiro</option>
                    <option value="Março">Março</option>
                    <option value="Abril">Abril</option>
                    <option value="Maio">Maio</option>
                    <option value="Junho">Junho</option>
                    <option value="Julho">Julho</option>
                    <option value="Agosto">Agosto</option>
                    <option value="Setembro">Setembro</option>
                    <option value="Outubro">Outubro</option>
                    <option value="Novembro">Novembro</option>
                    <option value="Dezembro">Dezembro</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField 
                    type='number' 
                    fullWidth 
                    className="input" 
                    label="Duração da Aula (min)" 
                    variant="outlined" 
                    size="small" 
                    name="duracao_aula" 
                    value={dados.duracao_aula} 
                    InputLabelProps={{ shrink: true }} 
                    disabled 
                  />  
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Dia do Curso"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="dia_aula_1"
                    value={dados.dia_aula_1}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    <option value="Segunda">Segunda</option>
                    <option value="Terça">Terça</option>
                    <option value="Quarta">Quarta</option>
                    <option value="Quinta">Quinta</option>
                    <option value="Sexta">Sexta</option>
                    <option value="Sabado">Sabado</option>
                    <option value="Domingo">Domingo</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Período"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="dia_aula_1_periodo"
                    value={dados.dia_aula_1_periodo}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    <option value="Manhã">Manhã</option>
                    <option value="Tarde">Tarde</option>
                    <option value="Noite">Noite</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4} style={{borderRight: '1px solid #C0C0C0'}}>
                  <TextField 
                    type='time' 
                    fullWidth 
                    className="input" 
                    label="Horario" 
                    variant="outlined" 
                    size="small" 
                    name="dia_aula_1_horario" 
                    value={dados.dia_aula_1_horario} 
                    InputLabelProps={{ shrink: true }} 
                    disabled 
                  />  
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Dia do Curso"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="dia_aula_2"
                    value={dados.dia_aula_2}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    <option value="Segunda">Segunda</option>
                    <option value="Terça">Terça</option>
                    <option value="Quarta">Quarta</option>
                    <option value="Quinta">Quinta</option>
                    <option value="Sexta">Sexta</option>
                    <option value="Sabado">Sabado</option>
                    <option value="Domingo">Domingo</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Período"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="dia_aula_2_periodo"
                    value={dados.dia_aula_2_periodo}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    <option value="Manhã">Manhã</option>
                    <option value="Tarde">Tarde</option>
                    <option value="Noite">Noite</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField 
                  type='time' 
                  fullWidth 
                  className="input" 
                  label="Horario" 
                  variant="outlined" 
                  size="small" 
                  name="dia_aula_2_horario" 
                  value={dados.dia_aula_2_horario} 
                  InputLabelProps={{ shrink: true }} 
                  disabled
                  />  
                </Grid>
              </Grid>
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className='titulo'>Descrição Aditivo:</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <div className="cardEditor">
                <Editor
                  editorState={props.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={e => props.onEditorStateChange(e)}
                />
              </div>
              {/* <TextField
                id="outlined-multiline-static"
                label="Aditivo"
                className="input"
                multiline
                rows={10}
                size="small"
                variant="outlined"
                name="descricao_aditivo"
                value={dados.descricao_aditivo}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => props.updateField(e)}
              /> */}
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={8} xs={12} sm={4}></Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(false)} >Voltar</Button>
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true)} disabled={dados.descricao_aditivo === "" ? true : loadingSalvar}>Salvar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
