import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, FormControlLabel, Switch, FormControl, FormLabel } from '@material-ui/core'

const FormCupom = ({ dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={5} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="cupom_descricao" value={dados.cupom_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Hash" variant="outlined" size="small" name="cupom_hash" value={dados.cupom_hash} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo do Desconto"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="cupom_tipo"
          value={dados.cupom_tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">Selecionar Tipo do Desconto</option>
          <option value="PORCENTAGEM"> Porcentagem </option>
          <option value="VALOR"> Valor Monetário</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Valor Desconto" 
          variant="outlined" 
          size="small" 
          name="cupom_valor" 
          value={dados.cupom_valor} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
          InputProps={{ inputComponent: CurrencyInput }}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
    <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input"
          type='date'
          label="Data Inicio" 
          variant="outlined" 
          size="small" 
          name="cupom_data_inicio" 
          value={dados.cupom_data_inicio} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
      <TextField 
          className="input"
          type='date'
          label="Data Fim" 
          variant="outlined" 
          size="small" 
          name="cupom_data_fim" 
          value={dados.cupom_data_fim} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" style={{marginLeft: 20}}>
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="cupom_ativo"
                color="primary"
                checked={dados.cupom_ativo}
              />
            }
            size="small"
            label={dados.cupom_ativo ? 'Sim' : 'Não'}
            name="cupom_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormCupom