import getEndereco from './../../services/api/getEndereco'
import calculaIdade from './../../services/calculaIdade'
import React, { Component } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';

const cpf = require('node-cpf-cnpj');

const initalState = {
  aluno: {
    _id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      contm_data_inicio_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: ""
    }
  },
  responsavel: {
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    }],
  },
  cursos: {
    list: []
  },
  curso: {
    curso_id: "",
    curso_nome: "",
  },
  unidade: {
    unin_id: "",
    unin_descricao: "",
  },
  unidades: {
    list: []
  },
  enableDadosPessoa: true,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false
}

export default class UpdateAluno extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;
    if (params.alunoId) {
      try {
        const { data: aluno } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/${params.alunoId}`, this.getToken())

        let enderecos = []
        let contatos = []
        let emails = []

        const auxEnderecos = aluno.fisica.pessoa.enderecos

        for (let i = 0; i < auxEnderecos.length; i++) {
          enderecos.push({
            end_id: auxEnderecos[i].end_id,
            cep_id: auxEnderecos[i].end_cep_id,
            rua: auxEnderecos[i].cep.logradouro,
            complemento: auxEnderecos[i].end_complemento,
            numero: auxEnderecos[i].end_numero,
            bairro: auxEnderecos[i].cep.bairro.nome,
            cidade: auxEnderecos[i].cep.bairro.cidade.nome,
            estado: auxEnderecos[i].cep.bairro.cidade.uf.descricao,
            cep: auxEnderecos[i].cep.cep,
            principal: auxEnderecos[i].end_principal,
            tipoEndereco: auxEnderecos[i].end_tipo,
            index: i + 1
          })
        }

        const auxContatos = aluno.fisica.pessoa.telefones

        for (let i = 0; i < auxContatos.length; i++) {
          contatos.push({
            tel_id: auxContatos[i].tel_id,
            numero: auxContatos[i].tel_numero,
            tipoContato: auxContatos[i].tel_tipo,
            principal: auxContatos[i].tel_principal,
            index: i + 1
          })
        }

        const auxEmails = aluno.fisica.pessoa.emails

        for (let i = 0; i < auxEmails.length; i++) {
          emails.push({
            ema_id: auxEmails[i].ema_id,
            email: auxEmails[i].ema_email,
            principal: auxEmails[i].ema_principal,
            index: i + 1
          })
        }

        const idade = calculaIdade(moment(aluno.fisica.pesf_datanascimento).format('Y-MM-DD'))

        this.setState({
          aluno: {
            _id: aluno.aluno_id,
            aluno_matricula: aluno.aluno_matricula,
            pesf_id: aluno.fisica.pesf_id,
            pessoaFisica: {
              nome: aluno.fisica.pesf_nome,
              cpf: cpf.format(aluno.fisica.pesf_cpf),
              rg: aluno.fisica.pesf_rg,
              rg_orgao_emissor: aluno.fisica.pesf_rg_orgao_emissor,
              rg_uf: aluno.fisica.pesf_rg_uf,
              rg_data_emissao: moment(aluno.fisica.pesf_rg_data_emissao).format('YYYY-MM-DD'),
              dataNascimento: moment(aluno.fisica.pesf_datanascimento).format('YYYY-MM-DD'),
              idade: idade,
              maiorIdade: idade < 18 ? false : true
            },
            enderecos,
            emails,
            contatos
          }
        })

        this.setState({ loading: false })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o aluno, tente mais tarde"
        })

        this.backPage()
      }
    }
  }

  updateFieldPessoaFisica(event) {
    const aluno = { ...this.state.aluno }
    aluno.pessoaFisica[event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldEndereco(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldContato(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldEmail(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.emails[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldEnderecoPrincipal(event, index) {
    const aluno = { ...this.state.aluno }
    const indexAux = aluno.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      aluno.enderecos[indexAux].principal = false
    }

    aluno.enderecos[index - 1].principal = !aluno.enderecos[index - 1].principal
    this.setState({ aluno })
  }

  updateFieldContatoPrincipal(event, index) {
    const aluno = { ...this.state.aluno }
    const indexAux = aluno.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      aluno.contatos[indexAux].principal = false
    }

    aluno.contatos[index - 1].principal = !aluno.contatos[index - 1].principal
    this.setState({ aluno })
  }

  updateFieldEmailPrincipal(event, index) {
    const aluno = { ...this.state.aluno }
    const indexAux = aluno.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      aluno.emails[indexAux].principal = false
    }

    aluno.emails[index - 1].principal = !aluno.emails[index - 1].principal

    this.setState({ aluno })
  }

  async validarCpfCnpj(event) {

    let cpf_cnpj = event.target.value
    const aluno = this.state.aluno

    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {
      aluno.pessoaFisica.cpf = ""
      this.setState({ aluno })

      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
    } else {
      aluno.pessoaFisica.cpf = cpf_cnpj
      this.setState({ aluno })
    }
  }

  async pegaEndereco(event, index) {
    let aux = event.target.value.trim()

    if (aux === "-") return false

    if (event.target.name === "cep") {
      const aluno = { ...this.state.aluno }
      const enderecos = aluno.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: enderecos[index - 1].cep,
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      aluno.enderecos[index - 1] = endereco
      this.setState({ aluno })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: enderecos[index - 1].cep,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        aluno.enderecos[index - 1] = endereco
        this.setState({ aluno })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        aluno.enderecos[index - 1] = endereco
        this.setState({ aluno })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  addNovoEndereco() {
    const aluno = { ...this.state.aluno }
    const enderecos = aluno.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      comlpemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    aluno.enderecos = enderecos
    this.setState({ aluno })
  }

  addNovoContato() {
    const aluno = { ...this.state.aluno }
    const contatos = aluno.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      complemento: "",
      tipoContato: "COMERCIAL",
      principal: false,
      index
    })

    aluno.contatos = contatos
    this.setState({ aluno })
  }

  addNovoEmail() {
    const aluno = { ...this.state.aluno }
    const emails = aluno.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    aluno.emails = emails
    this.setState({ aluno })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  backPage(timeout = 1000) {
    const self = this
    this.setState({
      aluno: {
        _id: "",
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          dataNascimento: "",
          idade: 0,
          maiorIdade: false
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        contrato: {
          contm_data_inicio_vigencia: "",
          contm_duracao: "",
          contm_observacao: "",
          contm_curso_id: "",
          contm_ativo: true,
          contm_unin_id: ""
        }
      }
    })
    setTimeout(() => {
      self.props.history.push("/aluno");
    }, timeout)
  }

  proximo(atual, prox) {
    const { aluno } = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      const aluno = { ...this.state.aluno }
      const idade = calculaIdade(moment(aluno.pessoaFisica.dataNascimento).format('Y-MM-DD'))

      aluno.pessoaFisica.maiorIdade = idade < 18 ? false : true
      aluno.pessoaFisica.idade = idade
      this.setState({ aluno })

      if (
        aluno.pessoaFisica.nome === "" ||
        aluno.pessoaFisica.cpf === "" ||
        aluno.pessoaFisica.rg === "" ||
        aluno.pessoaFisica.rg_orgao_emissor === "" ||
        aluno.pessoaFisica.rg_uf === "" ||
        aluno.pessoaFisica.rg_data_emissao === "" ||
        aluno.pessoaFisica.dataNascimento === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosPessoa: false,
        enableDadosEndereco: true
      })

    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      aluno.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEndereco: false,
        enableDadosContato: true
      })

    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      aluno.contatos.forEach(contato => {
        if (contato.numero === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosContato: false,
        enableDadosEmail: true
      })
    }

  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    }
  }

  validateFormEmail() {
    const { aluno } = this.state

    aluno.emails.forEach(email => {
      if (!email.email) return false
    })

    return true
  }

  removeEnderecoAluno(index) {
    const aluno = { ...this.state.aluno }
    const enderecos = aluno.enderecos
    const aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    aluno.enderecos = aux
    this.setState({ aluno })
  }

  removeContatoAluno(index) {
    const aluno = { ...this.state.aluno }
    const contatos = aluno.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    aluno.contatos = aux
    this.setState({ aluno })
  }

  removeEmailAluno(index) {
    const aluno = { ...this.state.aluno }
    const emails = aluno.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    aluno.emails = aux
    this.setState({ aluno })
  }

  async salvar() {
    const { aluno } = this.state
    this.setState({ loadingSalvar: true })

    if (!this.validateFormEmail()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const dados = {
      aluno: {
        nome: aluno.pessoaFisica.nome,
        cpf: aluno.pessoaFisica.cpf,
        rg: aluno.pessoaFisica.rg,
        rg_orgao_emissor: aluno.pessoaFisica.rg_orgao_emissor,
        rg_uf: aluno.pessoaFisica.rg_uf,
        rg_data_emissao: aluno.pessoaFisica.rg_data_emissao,
        datanascimento: aluno.pessoaFisica.dataNascimento,
        pesf_id: aluno.pesf_id,
        enderecos: aluno.enderecos.map(end => {
          return {
            cep_id: end.cep_id,
            numero: end.numero,
            complemento: "teste",
            principal: end.principal,
            tipo: end.tipoEndereco
          }
        }),
        emails: aluno.emails,
        telefones: aluno.contatos.map(cont => {
          return {
            ...cont,
            tipo: cont.tipoContato
          }
        })
      }
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/aluno/${aluno._id}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Atualização realizada com sucesso.'
      })
      this.setState({ loadingSalvar: false })
      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  render() {
    const { aluno } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={11}>
              <h2 className="titulo">Atualização de Alunos</h2>
            </Grid>
            {this.state.loadingSalvar &&
              <Grid item md={1}>
                <div>
                  <CircularProgress />
                </div>
              </Grid>
            }
          </Grid>

          {this.state.enableDadosPessoa &&
            <div className="formFranqueado">
              <FormPessoaFisica validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaFisica(e)} dados={aluno.pessoaFisica} />

              <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                </Grid>
              </Grid>
            </div>
          }

          {this.state.enableDadosEndereco &&
            <div className="formFranqueado">
              <Grid container direction="row">
                <Grid item md={12}>
                  <h3>Dados de Endereço</h3>
                </Grid>
              </Grid>
              {aluno.enderecos.map(endereco => {
                return (
                  <div key={endereco.index} className="cardEndereco">
                    <FormEndereco removerItem={(index) => this.removeEnderecoAluno(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEndereco(e, endereco.index)} updateField={e => this.updateFieldEndereco(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, endereco.index)} dados={endereco} />
                  </div>
                )
              })}
              <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                <Grid item md={4}>
                  <Button fullWidth onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                </Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                </Grid>
              </Grid>
            </div>
          }

          {this.state.enableDadosContato &&
            <div className="formFranqueado">
              <Grid container direction="row">
                <Grid item md={12}>
                  <h3>Dados de Contato</h3>
                </Grid>
              </Grid>
              {aluno.contatos.map(contato => {
                return (
                  <div key={contato.index} className="cardEndereco">
                    <FormContato removerItem={(index) => this.removeContatoAluno(index)} updateField={e => this.updateFieldContato(e, contato.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)} dados={contato} />
                  </div>
                )
              })}
              <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                <Grid item md={4}>
                  <Button fullWidth onClick={() => this.addNovoContato()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                </Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                </Grid>
              </Grid>
            </div>
          }

          {this.state.enableDadosEmail &&
            <div className="formFranqueado">
              <Grid container direction="row">
                <Grid item md={12}>
                  <h3>Dados de E-mail</h3>
                </Grid>
              </Grid>
              {aluno.emails.map(email => {
                return (
                  <div key={email.index} className="cardEndereco">
                    <FormEmail removerItem={(index) => this.removeEmailAluno(index)} updateField={e => this.updateFieldEmail(e, email.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, email.index)} dados={email} />
                  </div>
                )
              })}
              <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                <Grid item md={4}>
                  <Button fullWidth onClick={() => this.addNovoEmail()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={3}>
                  <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                </Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }

          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}