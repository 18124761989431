import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link } from 'react-router-dom'
import moment from 'moment';
import ModalErro from './../../../components/modals/Erro'
import ModalDesativarUnidade from './../../../components/modals/DesativarUnidade'
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';
import axios from 'axios'
import Table from '../../../components/Table'
import socketClient from './../../../components/lib/socket'
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

const cpf = require('node-cpf-cnpj')
const cnpj = require('node-cpf-cnpj')

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const ColorYellowButton = withStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: Color('#FFFF00').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FFFF00',
    },
  },
}))(Button);

const ColorGreenButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#008000').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#008000',
    },
  },
}))(Button);

const initalState = {
  unidade: {},
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  modalDesativarUnidade: false,
  dadosDesativacao:{
    hisatiuni_observacao: ''
  },
  historicosAtivacao: [],
  cabecalhoTabela: [
    { id: 'hisatiuni_acao', numeric: false, disablePadding: false, label: 'Ação' },
    { id: 'usuario', numeric: false, disablePadding: false, label: 'Por' },
    { id: 'hisatiuni_criado_em', numeric: false, disablePadding: false, label: 'Em', orderBy: 'hisatiuni_criado_em_order' },
    { id: 'hisatiuni_observacao', numeric: false, disablePadding: false, label: 'Observação',}
  ],
  acoesTabela: [],
  usuariosLogados: []
}

export default class Unidade extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    const unidade = this.props.unidade

    let historicosAtivacao = unidade.historicosAtivacao.map(historico => {
      return {
        hisatiuni_observacao: historico.hisatiuni_observacao,
        hisatiuni_criado_em: moment(historico.hisatiuni_criado_em).format('DD/MM/YYYY HH:mm'),
        hisatiuni_criado_em_order: moment(historico.hisatiuni_criado_em).format('YYYYMMDDHHmm'),
        hisatiuni_acao: historico.hisatiuni_acao,
        usuario: historico.usuario.pessoa.fisica.pesf_nome
      }
    })
    
    this.setState({
      unin_tipo,
      unidade: unidade,
      loading: false,
      historicosAtivacao
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async openDesativarUnidade(){
    try {
      const {unidade} = this.state

      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/usuarios/logadoUnidade/${unidade.unin_id}`, this.getToken())
    
      this.setState({
        modalDesativarUnidade: true,
        usuariosLogados: data
      })
    } catch ({response}) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }


  }

  handleCloseDesativar(){
    this.setState({
      modalDesativarUnidade: false
    })
  }

  updateFieldDesativar(event){
    const {dadosDesativacao} = this.state
    dadosDesativacao[event.target.name] = event.target.value
    this.setState({dadosDesativacao})
  }

  async desativarUnidade(value){

    if(value){

      try {
        const {dadosDesativacao, unidade} = this.state
  
        if(dadosDesativacao.hisatiuni_observacao === ''){
          this.setState({
            modalErro: true,
            erro: {
              titulo: "Campo Vazio!",
              descricao: "Existem campo obrigatorios vazios."
            }
          })
          return
        }
  
        await axios.put(`${process.env.REACT_APP_API_URL}/unidades/desativar/${unidade.unin_id}`, dadosDesativacao, this.getToken())
      
        socketClient({acao: 'desativar-unidade', values: unidade})

        const { data: unidadeAux } = await axios.get(`${process.env.REACT_APP_API_URL}/unidades/${unidade.unin_id}`, this.getToken())

        let historicosAtivacao = unidadeAux.historicosAtivacao.map(historico => {
          return {
            hisatiuni_observacao: historico.hisatiuni_observacao,
            hisatiuni_criado_em: moment(historico.hisatiuni_criado_em).format('DD/MM/YYYY HH:mm'),
            hisatiuni_criado_em_order: moment(historico.hisatiuni_criado_em).format('YYYYMMDDHHmm'),
            hisatiuni_acao: historico.hisatiuni_acao,
            usuario: historico.usuario.pessoa.fisica.pesf_nome
          }
        })

        this.setState({
          modalDesativarUnidade: false,
          unidade: unidadeAux,
          historicosAtivacao,
          dadosDesativacao:{
            hisatiuni_observacao: ''
          },
        })
      } catch ({response}) {
        if (response.status === 400) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }
  
        this.handleOpenDialog({})
      }
    }else{
      this.setState({
        modalDesativarUnidade: false,
        dadosDesativacao:{
          hisatiuni_observacao: ''
        }
      })
    }

  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.handleOpenDialog({
      titulo: 'Link copiado',
      descricao: 'Link copiado'
    })
  
    return
    
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { unidade, unin_tipo } = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Unidade...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&

          <div>
            <br />
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={6} xs={12} sm={12}>
                <h2 className="titulo">Dados Unidade: </h2>
              </Grid>
              {unin_tipo === 'FRANQUEADOR' &&
                <React.Fragment>
                  {unidade.unin_status &&
                    <Grid item md={2} xs={6} sm={4}>
                      <ColorYellowButton className="btnCadastrar" variant="contained" onClick={() => this.openDesativarUnidade()}>
                        Inativar Unidade
                      </ColorYellowButton>
                    </Grid>
                  }
                  {!unidade.unin_status &&
                    <Grid item md={2} xs={6} sm={4}>
                      <ColorGreenButton className="btnCadastrar" variant="contained" onClick={() => this.openDesativarUnidade()}>
                        Ativar Unidade
                      </ColorGreenButton>
                    </Grid>
                  }
                  <Grid item md={2} xs={6} sm={6}>
                    <Link to={`/unidade/update/${unidade.unin_id}`}>
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Editar Unidade
                    </Button>
                    </Link>
                  </Grid>
                </React.Fragment>
              }
              {unin_tipo !== 'FRANQUEADOR' &&
                <Grid item md={4} xs={false} sm={false}>

                </Grid>
              }
              <Grid item md={2} xs={6} sm={6}>
                <Link to={`/unidade`}>
                  <Button className="btnCadastrar" variant="contained" color="secondary">
                    Voltar
                </Button>
                </Link>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={2} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade" value={unidade.unin_numero} disabled />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Descrição" value={unidade.unin_descricao} disabled />
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Status" value={unidade.unin_status ? 'Ativo' : 'Inativo'} disabled />
              </Grid>
            </Grid>
            {unidade.pessoa.pes_tipo === "FISICA" &&
              <div>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Dados Pessoa Física: </h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={unidade.pessoa.fisica.pesf_nome} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(unidade.pessoa.fisica.pesf_cpf)} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={unidade.pessoa.fisica.pesf_rg} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(unidade.pessoa.fisica.pesf_datanascimento).format('YYYY-MM-DD')} disabled InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </div>
            }
            {unidade.pessoa.pes_tipo === "JURIDICA" &&
              <div>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Dados Pessoa Jurídica: </h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={unidade.pessoa.juridica.pesj_razaosocial} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantasia" value={unidade.pessoa.juridica.pesj_nomefantasia} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={cnpj.format(unidade.pessoa.juridica.pesj_cnpj)} disabled />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={unidade.pessoa.juridica.pesj_inscricaoestadual} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} sm={6} xs={12}>
                    <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Fundação" value={moment(unidade.pessoa.juridica.pesj_datafundacao).format('YYYY-MM-DD')} disabled InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </div>
            }
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={4} xs={12} sm={8}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Link Portal" value={`${process.env.REACT_APP_URL}/portal_aluno/${unidade.pessoa.pes_id}`} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
              <Grid item md={2} xs={12} sm={4}>
                <Tooltip title='Copiar Link' aria-label="add">
                  <IconButton style={{marginTop: '-3px', marginLeft: '-10px'}} color="primary" aria-label="view" onClick={e => this.copyLink(`${process.env.REACT_APP_URL}/portal_aluno/${unidade.pessoa.pes_id}`)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">Endereços Unidade: </h4>
              </Grid>
            </Grid>
            {unidade.pessoa.enderecos.map(endereco => {
              return (
                <div className="cardEndereco" key={endereco.end_id}>
                  {endereco.end_principal &&
                    <h4>Endereço Principal:</h4>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.end_cep} disabled />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.end_logradouro} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.end_numero} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.end_complemento} disabled />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.end_bairro} disabled />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.end_cidade} disabled />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.end_Estado} disabled />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.end_tipo} disabled />
                    </Grid>
                  </Grid>
                </div>
              )
            })}
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">Telefones Unidade: </h4>
              </Grid>
            </Grid>
            {unidade.pessoa.telefones.map(telefone => {
              return (
                <div className="cardEndereco" key={telefone.tel_id}>
                  {telefone.tel_principal &&
                    <h4>Telefone Principal:</h4>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.tel_numero} disabled InputProps={{ inputComponent: TextMaskCustom }} />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tel_tipo} disabled />
                    </Grid>
                  </Grid>
                </div>
              )
            })}
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">E-mails Franqueado: </h4>
              </Grid>
            </Grid>
            {unidade.pessoa.emails.map(email => {
              return (
                <div className="cardEndereco" key={email.ema_id}>
                  {email.ema_principal &&
                    <h4>E-mail Principal:</h4>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.ema_email} disabled />
                    </Grid>
                  </Grid>
                </div>
              )
            })}
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">Historico de Ativação/Inativação: </h4>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table 
                headCell={this.state.cabecalhoTabela} 
                rows={this.state.historicosAtivacao} 
                acoes={this.state.acoesTabela}/>
              </Grid>
            </Grid>
          </div>
        }
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        <ModalDesativarUnidade 
        dadosDesativacao={this.state.dadosDesativacao} 
        unidade={unidade} 
        usuariosLogados={this.state.usuariosLogados}
        open={this.state.modalDesativarUnidade}
        handleClose={e => this.handleCloseDesativar(e)}
        updateField={e => this.updateFieldDesativar(e)}
        confirmar={e => this.desativarUnidade(e)}/>
      
      </React.Fragment>
    )
  }
}