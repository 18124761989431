import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: '#FFF',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#2c4e82',
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTableHead(props) {
  const headCells = props.headCell

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map(headCell => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
            >
              <span className='textoTabelaCabHistorico'>{headCell.label}</span>
            </StyledTableCell>
          )
        })}
        {props.acoes.length === 1 &&
          <StyledTableCell style={{ width: 100 }} align={'center'}>
            Ações
          </StyledTableCell>
        }
        {props.acoes.length === 2 &&
          <StyledTableCell style={{ width: 140 }} align={'center'}>
            Ações
          </StyledTableCell>
        }
        {props.acoes.length === 3 &&
          <StyledTableCell style={{ width: 170 }} align={'center'}>
            Ações
          </StyledTableCell>
        }
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const { acoes, headCell, rows } = props

  const classes = useStyles();
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={rows.length}
              headCell={headCell}
              acoes={acoes}
            />
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id ? row._id : row.index}
                      selected={isItemSelected}
                      className={row.total ? 'linhaTotal' : ''}
                    >
                      {headCell.map(param => {
                        return (
                          <TableCell
                            key={param.id}
                            component="th"
                            id={labelId}
                            scope="row"
                            align={param.numeric ? 'right' : 'left'}
                            padding={param.disablePadding ? 'none' : 'default'}
                          >
                            <span className='textoTabelaHistorico'>
                              {row[param.id] ? row[param.id] : '-'}
                            </span>
                          </TableCell>
                        )
                      })}
                      {acoes.length > 0 &&
                        <TableCell align={'center'}>
                          {acoes.map(acao => {
                            if (acao === "editarHistorico") {
                              if (row.aluaula_status !== 'CANCELADO')
                                return (
                                  <Tooltip title='Editar' aria-label="add">
                                    <React.Fragment key={acao}>
                                      <IconButton onClick={e => props.editar(row)} color="secondary" aria-label="editar">
                                        <CreateIcon />
                                      </IconButton>
                                    </React.Fragment>
                                  </Tooltip>
                                )
                            } else {
                              return false
                            }
                            return false
                          })}
                        </TableCell>
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell colSpan={headCell.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 30, rows.length]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
