import React, { Component } from 'react'
import './Curso.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'
import Alert from './../../components/templates/Alert'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  curso: {

  },
  cursos: {
    list: []
  },
  cursosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'nome', numeric: false, disablePadding: true, label: 'Curso' },
    { id: 'gera_certificado', numeric: false, disablePadding: false, label: 'Certificado' },
    { id: 'curso_ead', numeric: false, disablePadding: false, label: 'EAD?' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    nome: ""
  },
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Curso extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'curso')[0]

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/curso/list`, this.getToken())
      const cursos = []

      data.forEach(curso => {
        cursos.push({
          _id: curso.curso_id,
          nome: curso.curso_nome,
          gera_certificado: curso.curso_gera_certificado,
          curso_ead: curso.curso_ead,
          ativo: curso.curso_ativo
        })
      })

      this.setState({
        cursos: {
          list: cursos
        },
        cursosFiltrado: {
          list: cursos
        },
        loading: false
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  } 

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const cursos = this.state.cursos.list
    const filtro = this.state.filtro

    const list = cursos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      cursosFiltrado: {
        list
      },
      filtro
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  copiarLink(row){
    let url = `${process.env.REACT_APP_URL}/ead/${row._id}/cadastro`

    navigator.clipboard.writeText(url);

    this.setState({
      alerta: {
        open: true,
        autoHideDuration: 3000,
        severity: 'success',
        message: 'Link Copiado'
      }
    })
    return
  }

  render() {
    const { filtro, cursosFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Cursos...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Cursos</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/curso/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Curso
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Curso" variant="outlined" size="small" name="nome" value={filtro.nome} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate="/curso/cadastro/" 
                    urlView="/curso/view/" 
                    headCell={this.state.cabecalhoTabela} 
                    rows={cursosFiltrado.list} 
                    acoes={this.state.acoesTabela}
                    copiarLink={(e) => this.copiarLink(e)}
                  />
                </Grid>
              </Grid>
            </div>
          }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}