import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Troca Senha</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" className="mg_top_20" >
            <Grid item md={12} xs={12} sm={12}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Senha Atual</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={props.showPassword ? 'text' : 'password'}
                  value={props.usuario.senhaAtual}
                  onChange={(e) => props.updateField(e)}
                  className="input"
                  size="small"
                  name="senhaAtual"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => props.handleClickShowPassword(e)}
                        edge="end"
                      >
                        {props.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" className="mg_top_20" >
            <Grid item md={12} xs={12} sm={12}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Nova Senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={props.showPasswordNova ? 'text' : 'password'}
                  value={props.usuario.novaSenha}
                  onChange={(e) => props.updateField(e)}
                  className="input"
                  size="small"
                  name="novaSenha"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => props.handleClickShowPasswordNova(e)}
                        edge="end"
                      >
                        {props.showPasswordNova ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" className="mg_top_20" >
            <Grid item md={12} xs={12} sm={12}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Nova Senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={props.showPasswordRepetir ? 'text' : 'password'}
                  value={props.usuario.repetirSenha}
                  onChange={(e) => props.updateField(e)}
                  className="input"
                  size="small"
                  name="repetirSenha"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => props.handleClickShowPasswordRepetir(e)}
                        edge="end"
                      >
                        {props.showPasswordRepetir ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <span>Nivel Senha: <b style={{ color: props.corSenha }}>{props.nivelSenha}</b> </span>
            </Grid>
          </Grid>
          <Grid container direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <Button className="btnCadastrar" variant="contained" color="primary" onClick={e => props.trocarSenha()}>
                Trocar Senha
							</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
