import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import real from './../../services/real'
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '70%',
    marginLeft: '15%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  }
}))(Tooltip);


function renderTooltipDentro() {
  const rows = []

  rows.push(<p><b key='1'>Dentro da Carteira:</b> Apresenta o valor total da carteira de mensalidades pera o período e os valores pagos;</p>)
  rows.push(<p><b key='2'>Meses Anteriores:</b> Mensalidades vencidas dentro do período da carteira e pagas antecipadamente;</p>)
  rows.push(<p><b key='3'>Mês Atual:</b> Mensalidade com vencimento e pagamento dentro do período da carteira;</p>)
  // rows.push(<p><b key='4'>Atrasadas:</b> São mensalidades com vencimento dentro do período da carteira mas pagos após este período;</p>)

  return rows
}

function renderTooltipFora() {
  const rows = []

  rows.push(<p><b key='1'>Atrasadas recebidas neste mês:</b> Apresenta o valor das mensalidades vencidas e recebidas dentro da carteira atual;</p>)
  rows.push(<p><b key='2'>Recebidas de carteiras futuras:</b> São mensalidades com vencimento em carteira futura mas recebidas dentro da carteira atual;</p>)
  rows.push(<p><b key='3'>Recebidas de alunos inativos:</b> Mensalidades recebidas dentro do período que o aluno estava inativo;</p>)
  rows.push(<p><b key='4'>Recebidas do mês atual:</b> Apresenta as mensalidades que foram lançadas e pagas dentro do período da carteira;</p>)

  return rows
}


export default function SimpleModal(props) {
  const classes = useStyles()

  console.log(props.valores.dentro_carteira.recebidos)
  console.log(props.valores.dentro_carteira.total)
      
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Carteira de Mensalidade</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1} direction="row">
            <Grid item md={12} xs={12}>
              <h4>Unidade: {props.unidadeSelecionada.unin_numero} - {props.unidadeSelecionada.unin_descricao}</h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={3} xs={12} sm={5}>
              <TextField className="input" type="text" placeholder='xx/xxxx' label="Mês/Ano" inputProps={{ maxLength: 7 }} variant="outlined" size="small" name="data" value={props.filtro.data} onChange={(e) => props.updateFieldFiltroUnidade(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <Button fullWidth variant="contained" color="primary" onClick={() => props.filtrarUnidade()} disabled={props.filtro.data === '' ? true : false}>
                Filtrar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <Button fullWidth variant="contained" color="secondary" onClick={() => props.imprimirCarteiraUnidade()} disabled={props.filtro.data === '' ? true : false}>
                Imprimir
              </Button>
            </Grid>
            {props.loadingFiltroCarteriaUnidade &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} >
              <div className='scroll'>
                  <table style={{width: '100%', border: '1px solid #000' }} >
                    <thead>
                      <tr>
                        <td style={{width: '30%', textAlign: 'right', backgroundColor: '#D3D3D3', fontSize: '20px'}}>
                          <IconButton onClick={e => props.visualizarCarteira('total')} color="primary" aria-label="editar">
                            <SearchIcon />
                          </IconButton>
                          <b>Dentro da Carteira</b>
                        </td>
                        <td style={{width: '70%', textAlign: 'left', fontSize: '20px'}}>
                          <b>R$ {real(props.valores.dentro_carteira.total)}</b>
                          <DarkTooltip
                            title={renderTooltipDentro()}
                          >
                            <IconButton color="secondary" style={{float: 'right'}}>
                              <HelpIcon />
                            </IconButton>
                          </DarkTooltip>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3'}}>
                            <IconButton onClick={e => props.visualizarCarteira('baixado')} color="primary" aria-label="editar">
                              <SearchIcon />
                            </IconButton>
                            Carteira do Mês
                          </td>
                          <td style={{textAlign: 'left'}}>
                            <div style={{width: '95%', height: '30px', border: '1px solid #000'}}>
                              <div style={{textAlign: 'center', width: `${((props.valores.dentro_carteira.recebidos)/props.valores.dentro_carteira.total) *100}%`, height: '100%', backgroundColor: '#DAA520'}}>
                                <span style={{color: '#000',}}>{(((props.valores.dentro_carteira.recebidos)/props.valores.dentro_carteira.total) *100).toFixed(2)}%</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3'}}>
                            <IconButton onClick={e => props.visualizarCarteira('anterior')} color="primary" aria-label="editar">
                              <SearchIcon />
                            </IconButton>
                            Meses Anteriores
                          </td>
                          <td style={{textAlign: 'left'}}>R$ {real(props.valores.dentro_carteira.anteriores)}</td>
                        </tr>
                        <tr style={{borderBottom: '2px solid #000'}}>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3'}}>
                            <IconButton onClick={e => props.visualizarCarteira('atual')} color="primary" aria-label="editar">
                              <SearchIcon />
                            </IconButton>
                            Mês Atual
                          </td>
                          <td style={{textAlign: 'left'}}>R$ {real(props.valores.dentro_carteira.atual)}</td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3', fontSize: '20px'}}>
                            <b>Fora da Carteira</b>
                          </td>
                          <td style={{textAlign: 'left', fontSize: '20px'}}>
                            <DarkTooltip
                              title={renderTooltipFora()}
                            >
                              <IconButton color="secondary" style={{float: 'right'}}>
                                <HelpIcon />
                              </IconButton>
                            </DarkTooltip>

                          </td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3'}}>
                            <IconButton onClick={e => props.visualizarCarteira('atrasadas')} color="primary" aria-label="editar">
                              <SearchIcon />
                            </IconButton>
                            Atrasadas recebidas neste mês
                          </td>
                          <td style={{textAlign: 'left'}}>R$ {real(props.valores.fora_carteira.atrasadas_recebidas_neste_mes)}</td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3'}}>
                            <IconButton onClick={e => props.visualizarCarteira('futuras')} color="primary" aria-label="editar">
                              <SearchIcon />
                            </IconButton>
                            Recebidas de carteiras futuras
                          </td>
                          <td style={{textAlign: 'left'}}>R$ {real(props.valores.fora_carteira.recebidas_carteiras_futuras)}</td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3'}}>
                            <IconButton onClick={e => props.visualizarCarteira('inativos')} color="primary" aria-label="editar">
                              <SearchIcon />
                            </IconButton>
                            Recebidas de alunos inativos
                          </td>
                          <td style={{textAlign: 'left'}}>R$ {real(props.valores.fora_carteira.alunos_inativo)}</td>
                        </tr>
                        <tr>
                          <td style={{textAlign: 'right', backgroundColor: '#D3D3D3'}}>
                            <IconButton onClick={e => props.visualizarCarteira('periodo')} color="primary" aria-label="editar">
                              <SearchIcon />
                            </IconButton>
                            Recebidas do mês atual <br/> *Lançadas após o inicio da carteira
                          </td>
                          <td style={{textAlign: 'left'}}>R$ {real(props.valores.fora_carteira.inicio_carteira)}</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
