import React, { Component } from 'react'
import './ContaFinanceira.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import real from '../../services/real'

const initalState = {
  contaFinanceira: {

  },
  contaFinanceiras: {
    list: []
  },
  contaFinanceirasFiltrado: {
    list: []
  },
  filtro: {
    contfin_tipo_conta: "",
    contfin_descricao: "",
    contfin_ativo: "Sim"
  },
  cabecalhoTabela: [
    { id: 'contfin_ordem', numeric: false, disablePadding: true, label: 'Ordem' },
    { id: 'contfin_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'contfin_saldo_atual', numeric: false, disablePadding: false, label: 'Saldo Atual' },
    { id: 'contfin_tipo_conta', numeric: false, disablePadding: false, label: 'Tipo Conta' },
    { id: 'contfin_ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
}

export default class contaFinanceiras extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {

      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'conta-financeira')[0]
      let permissoesCorrigeSaldo = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'ajuste_saldo_conta')[0]

      if (!permissoes.perm_visualizar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }

      const acoesTabela = []

      if (permissoes.perm_alterar) {
        acoesTabela.push('update')
      }

      if(permissoesCorrigeSaldo){
        if (permissoesCorrigeSaldo.perm_inserir || permissoesCorrigeSaldo.perm_alterar) {
          acoesTabela.push('corrigeSaldo')
        }
      }

      this.setState({ acoesTabela })

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
      const filtro = this.state.filtro

      const dados = {
        filtro,
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      console.log(data)

      const contaFinanceiras = data.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(param => {
        return {
          _id: param.contfin_id,
          contfin_descricao: param.contfin_descricao,
          contfin_ordem: param.contfin_ordem,
          contfin_saldo_atual: 'R$ ' + real(param.contfin_saldo_atual),
          contfin_tipo_conta: param.contfin_tipo_conta,
          contfin_ativo: param.contfin_ativo ? 'Sim' : 'Não',
          unin_descricao: param.unin_descricao,
        }
      })

      this.setState({
        contaFinanceiras: {
          list: contaFinanceiras
        },
      })

    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      // return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })

  }

  async filtrarContas(){
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    const filtro = this.state.filtro

    const dados = {
      filtro,
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

    const contaFinanceiras = data.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(param => {
      return {
        _id: param.contfin_id,
        contfin_descricao: param.contfin_descricao,
        contfin_ordem: param.contfin_ordem,
        contfin_saldo_atual: 'R$ ' + real(param.contfin_saldo_atual),
        contfin_tipo_conta: param.contfin_tipo_conta,
        contfin_ativo: param.contfin_ativo ? 'Sim' : 'Não',
        unin_descricao: param.unin_descricao,
      }
    })

    this.setState({
      contaFinanceiras: {
        list: contaFinanceiras
      },
    })
  }

  render() {
    const { filtro, contaFinanceiras } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={8} xs={12}>
              <h1 className="titulo">Contas Financeiras</h1>
            </Grid>
            <Grid item md={4} xs={12}>
              <Link to="/conta_financeira/cadastro">
                <Button className="btnCadastrar" variant="contained" color="primary">
                  Cadastrar Conta
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField className="input" label="Descrição" variant="outlined" size="small" name="contfin_descricao" value={filtro.contfin_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Tipo Conta" variant="outlined" size="small" name="contfin_tipo_conta" value={filtro.contfin_tipo_conta} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Contas Ativas*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="contfin_ativo"
                value={filtro.contfin_ativo}
                onChangeCapture={(e) => this.updateFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value='Sim'> Sim </option>
                <option value='Não'> Não </option>
              </TextField>
            </Grid>
            <Grid item md={3} xs={false} sm={false}></Grid>
            <Grid item md={2} xs={12} sm={6}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrarContas()}>
                Filtrar
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
          >
            <Grid item md={12} xs={12}>
              <Table
                urlUpdate="/conta_financeira/cadastro/"
                urlView="/conta_financeira/view/"
                urlCorrigeSaldo="/conta_financeira/corrige_saldo/"
                headCell={this.state.cabecalhoTabela}
                rows={contaFinanceiras.list}
                acoes={this.state.acoesTabela} />
            </Grid>
          </Grid>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}