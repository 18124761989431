import React, { Component } from 'react'
import './Departamento.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormDepartamento from '../../components/forms/FormDepartamento'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  departamento: {
    dep_id: "",
    dep_descricao: "",
    dep_ativo: true
  },
  departamentos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false
}

export default class CadastroDepartamento extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'departamento')[0]

    this.setState({
      permissoes
    })

    if (params.departamentoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.departamentoId) {
      try {

        const { data: departamento } = await axios.get(`${process.env.REACT_APP_API_URL}/departamentos/${params.departamentoId}`, this.getToken())

        this.setState({
          departamento: {
            dep_id: departamento.dep_id,
            dep_descricao: departamento.dep_descricao,
            dep_ativo: departamento.dep_ativo
          },
          loading: false
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o departamento, tente mais tarde"
        })

        this.backPage()
      }
    }
  }

  updateField(event) {
    const departamento = { ...this.state.departamento }
    departamento[event.target.name] = event.target.value
    this.setState({ departamento })
  }

  updateFieldAtivo(event) {
    const departamento = { ...this.state.departamento }
    departamento.dep_ativo = !departamento.dep_ativo
    this.setState({ departamento })
  }

  validateForm() {
    const { departamento } = this.state

    if (!departamento.dep_descricao) return false

    return true
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/departamentos");
    }, timeout)
    this.setState({
      departamento: initalState.departamento
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const departamento = this.state.departamento

    const dados = {
      dep_descricao: departamento.dep_descricao,
      dep_ativo: departamento.dep_ativo
    }

    try {

      const method = departamento.dep_id !== "" ? 'put' : 'post'
      const url = departamento.dep_id !== "" ? `/departamentos/${departamento.dep_id}` : `/departamentos`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  render() {
    const { departamento } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={11}>
              <h2 className="titulo">Cadastro de Departamentos</h2>
            </Grid>
            <Grid item md={1}>
              {this.state.loadingSalvar &&
                <div>
                  <CircularProgress />
                </div>
              }
            </Grid>
          </Grid>
          <FormDepartamento updateField={e => this.updateField(e)} updateFieldAtivo={e => this.updateFieldAtivo(e)} dados={departamento} />
          <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
            <Grid item md={9}></Grid>
            <Grid item md={3}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
            </Grid>
          </Grid>
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}