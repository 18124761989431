import React from 'react'

import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl } from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete';

const FormCurso = ({ dados, updateField, updateFieldAtivo, produto, produtos, updateFieldProduto, updateFieldParametro, planos, updateFieldTemParametros, informarParametros, update }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={5} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Nivel do Plano*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="plancontas_nivel_superior_id"
          value={dados.plancontas_nivel_superior_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={true}
        >
          <option value=''> Primeiro Nivel </option>
          {planos.sort((a, b) => (a.plancontas_codigo > b.plancontas_codigo) ? 1 : ((b.plancontas_codigo > a.plancontas_codigo) ? -1 : 0)).map(plano => {
            return (
              <option value={plano.plancontas_id}> {plano.plancontas_codigo} - {plano.plancontas_descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Codigo*" variant="outlined" size="small" name="plancontas_codigo" value={dados.plancontas_codigo} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
    </Grid>

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={5} xs={12} sm={6}>
        <TextField className="input" label="Nome*" variant="outlined" name="plancontas_descricao" value={dados.plancontas_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldProduto(event, value)}
          freeSolo
          options={produtos}
          value={produto}
          getOptionLabel={option => option.prod_descricao}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Material" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={produto.prod_descricao} />
          )}
        />
      </Grid>
      <Grid item md={1} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="plancontas_ativo"
                color="primary"
                checked={dados.plancontas_ativo}
              />
            }
            size="small"
            label={dados.plancontas_ativo ? 'Ativo' : 'Inativo'}
            name="plancontas_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Informar Parâmetros?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="informarParametros"
                color="primary"
                checked={informarParametros}
              />
            }
            size="small"
            label={informarParametros ? 'Sim' : 'Não'}
            name="plancontas_ativo"
            disabled={update}
            onChange={(e) => updateFieldTemParametros(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <hr />
    {informarParametros &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={12} sm={3}>
            <TextField
              id="standard-select-currency"
              select
              label="Aluno"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_aluno"
              value={dados.parametros_financeiro.paramfin_aluno}
              onChangeCapture={(e) => updateFieldParametro(e)}
            >
              <option value='I'> Inativo </option>
              <option value='C'> Crédito </option>
              <option value='D'> Débito </option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={3}>
            <TextField
              id="standard-select-currency"
              select
              label="Assessor"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_assessor"
              value={dados.parametros_financeiro.paramfin_assessor}
              onChangeCapture={(e) => updateFieldParametro(e)}
            >
              <option value='I'> Inativo </option>
              <option value='C'> Crédito </option>
              <option value='D'> Débito </option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={3}>
            <TextField
              id="standard-select-currency"
              select
              label="Caixa / Banco"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_caixa"
              value={dados.parametros_financeiro.paramfin_caixa}
              onChangeCapture={(e) => updateFieldParametro(e)}
            >
              <option value='I'> Inativo </option>
              <option value='C'> Crédito </option>
              <option value='D'> Débito </option>
              <option value='T'> Transferência </option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={3}>
            <TextField
              id="standard-select-currency"
              select
              label="Colaborador"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_funcionario"
              value={dados.parametros_financeiro.paramfin_funcionario}
              onChangeCapture={(e) => updateFieldParametro(e)}
            >
              <option value='I'> Inativo </option>
              <option value='C'> Crédito </option>
              <option value='D'> Débito </option>
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={3}>
            <TextField
              id="standard-select-currency"
              select
              label="Professor"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_professor"
              value={dados.parametros_financeiro.paramfin_professor}
              onChangeCapture={(e) => updateFieldParametro(e)}
            >
              <option value='I'> Inativo </option>
              <option value='C'> Crédito </option>
              <option value='D'> Débito </option>
            </TextField>
          </Grid>
        </Grid>
        <br />
        <hr />
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Parcelamento*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_parcelamento"
              value={dados.parametros_financeiro.paramfin_parcelamento}
              onChangeCapture={(e) => updateFieldParametro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=''> Selecionar Parcelamento </option>
              <option value='30 Dias'> 30 Dias </option>
              <option value='Mensal'> Mensal </option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Duplo Lançamento*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_duplo"
              value={dados.parametros_financeiro.paramfin_duplo}
              onChangeCapture={(e) => updateFieldParametro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=''> Selecionar Lançamento </option>
              <option value={true}> Sim </option>
              <option value={false}> Não </option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Recorrente*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_recorrente"
              value={dados.parametros_financeiro.paramfin_recorrente}
              onChangeCapture={(e) => updateFieldParametro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=''> Selecionar Recorrencia </option>
              <option value={true}> Sim </option>
              <option value={false}> Não </option>
            </TextField>
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Relatório*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_relatorio"
              value={dados.parametros_financeiro.paramfin_relatorio}
              onChangeCapture={(e) => updateFieldParametro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=''> Gerar Relatório ? </option>
              <option value={true}> Sim </option>
              <option value={false}> Não </option>
            </TextField>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Recepção*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paramfin_recepcao"
              value={dados.parametros_financeiro.paramfin_recepcao}
              onChangeCapture={(e) => updateFieldParametro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=''> Selecionar Recepção </option>
              <option value={true}> Liberado </option>
              <option value={false}> Bloqueado </option>
            </TextField>
          </Grid>
        </Grid>
        <br />
        <hr />
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormCurso