import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import real from './../../services/real'

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { dados, open, handleClose, formas_sem_filtro, updateFieldParcela } = props

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <div style={modalStyle} className={classes.paper}>
            <Grid container direction="row" spacing={1} className="borderBottom">
              <Grid item md={12} xs={12} sm={12}>
                <h5 className="tituloConfirmacao">Trocar Forma de Pagamento</h5>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className='mg_top_20'>
              <Grid item md={12} xs={12} sm={12}>
                <TextField className="input" label="Descrição Parcela" variant="outlined" size="small" value={`${dados.contpr_descricao} - ${dados.parcont_num}/${dados.contpr_numparcela}`} InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className='mg_top_10'>
              <Grid item md={12} xs={12} sm={12}>
                <TextField className="input" label="Valor Recebido" variant="outlined" size="small" value={real(dados.parcont_valorpagamento)} InputLabelProps={{ shrink: true }} disabled={true} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className='mg_top_10'>
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  variant="outlined"
                  className="inputFormPagamento"
                  label="Forma de Pagamento*"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="formpag_id"
                  value={dados.formpag_id}
                  onChangeCapture={(e) => updateFieldParcela(e, dados.index)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value={dados.formpag_id}>{dados.formpag_descricao}</option>
                  {formas_sem_filtro.map(forma => {
                    if (forma.formpag_id !== dados.formpag_id) {
                      return (
                        <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                      )
                    }
                    return false
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className='mg_top_10'>
              <Grid item md={6} xs={6} sm={6}>
                <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar()}>Confirmar</Button>
              </Grid>
              <Grid item md={6} xs={6} sm={6}>
                <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.recusar()} >Cancelar</Button>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      </Modal>
    </div >
  );
}
