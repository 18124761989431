import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'

const FormGrupoProdutos = ({ dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="grupprod_descricao" value={dados.grupprod_descricao} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="ativo"
              color="primary"
              checked={dados.grupprod_ativo}
            />
          }
          label={dados.grupprod_ativo ? "Ativo" : "Inativo"}
          name="grupprod_ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormGrupoProdutos