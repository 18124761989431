import React, { Component } from 'react'
import './DemonstrativoDiario.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button, TextField } from '@material-ui/core'
import ModalErro from '../../components/modals/Erro'
import axios from 'axios'
import real from '../../services/real'
import moment from 'moment'

const initalState = {
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  demonstrativos: {
    list: []
  },
  demonstrativosFiltrados: {
    list: []
  },
  usuarios: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'unin_descricao', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'parcont_datapagamento', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'pesf_nome', numeric: false, disablePadding: true, label: 'Responsável' },
    { id: 'total_credito', numeric: false, disablePadding: false, label: 'Total Crédito' },
    { id: 'total_debito', numeric: false, disablePadding: false, label: 'Total Débito' }
  ],
  acoesTabela: ['viewDemonstrativo'],
  loading: true,
  loadingTable: false,
  filtro: {
    unidadesnegocio: {},
    data_inicial: '',
    data_final: ''
  },
  filtroUsuarios: {
    pesf_id: "",
  },
  term: '',
}

// function searching(term, itemFiltro) {
//   return function (x) {
//     return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
//   }
// }

export default class Cargo extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'demonstrativo-diario')[0]

    this.setState({
      permissoes
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))


    try {
      let dataAtual = new Date()
      dataAtual = moment(dataAtual).format('YYYY-MM-DD')

      let data7dias = new Date()
      data7dias.setDate(data7dias.getDate() - 15)
      data7dias = moment(data7dias).format('YYYY-MM-DD')

      const filtro = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        }),
        data_inicial: data7dias,
        data_final: dataAtual
      }

      this.setState({
        filtro
      })

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/demonstrativoDiario/resumo`, filtro, this.getToken())

      const usuarios = []

      for (const key in data) {
        if(data[key].pesf_id){
          const index = usuarios.findIndex(param => param.pesf_id === data[key].pesf_id)
          if (index === -1) {
            usuarios.push({
              pesf_id: data[key].pesf_id,
              pesf_nome: data[key].pesf_nome.split(' ')[0],
            })
          }
        }
      }

      this.setState({
        demonstrativos: {
          list: data.map(value => {
            if(value.pesf_id){
              return {
                parcont_datapagamento: moment(value.parcont_datapagamento).format('DD/MM/YYYY'),
                parcont_datapagamento_sem_form: value.parcont_datapagamento,
                pesf_nome: value.pesf_nome.split(' ')[0],
                pesf_id: value.pesf_id,
                unin_descricao: value.unin_numero + ' - ' + value.unin_descricao,
                unin_id: value.unin_id,
                total_credito:value.total_credito > 0 ? 'R$ ' + real(value.total_credito) : '-',
                total_debito:value.total_debito > 0 ? 'R$ ' + real(value.total_debito): '-'
              }
            }
            return false
          })
        },
        demonstrativosFiltrado: {
          list: data.map(value => {
            if(value.pesf_id){
              return {
                parcont_datapagamento: moment(value.parcont_datapagamento).format('DD/MM/YYYY'),
                parcont_datapagamento_sem_form: value.parcont_datapagamento,
                pesf_nome: value.pesf_nome.split(' ')[0],
                pesf_id: value.pesf_id,
                unin_descricao: value.unin_numero + ' - ' + value.unin_descricao,
                unin_id: value.unin_id,
                total_credito: value.total_credito > 0 ? 'R$ ' + real(value.total_credito) : '-',
                total_debito:value.total_debito > 0 ? 'R$ ' + real(value.total_debito) : '-'
              }
            }
            return false
          })
        },
        usuarios: {
          list: usuarios
        },
        filtroUsuarios: {
          pesf_id: "",
        }
      })

    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      // return
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })
  }

  updateFiltroUsuario(event) {
    const demonstrativos = this.state.demonstrativos.list
    const filtroUsuarios = this.state.filtroUsuarios

    let list = []
    if (event.target.value !== '') {
      list = demonstrativos.filter(param => param.pesf_id === parseInt(event.target.value))
    } else {
      list = demonstrativos
    }

    filtroUsuarios[event.target.name] = event.target.value

    this.setState({
      demonstrativosFiltrado: {
        list
      },
      filtroUsuarios
    })
  }

  async filtrar() {
    const filtro = this.state.filtro

    if (filtro.data_final === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data final'
      })
      return
    }

    if (filtro.data_inicial === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data inicial'
      })
      return
    }

    this.setState({
      loadingTable: true
    })

    try {

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/demonstrativoDiario/resumo`, filtro, this.getToken())

      this.setState({
        demonstrativos: {
          list: data.map(value => {
            return {
              parcont_datapagamento: moment(value.parcont_datapagamento).format('DD/MM/YYYY'),
              parcont_datapagamento_sem_form: value.parcont_datapagamento,
              pesf_nome: value.pesf_nome.split(' ')[0],
              pesf_id: value.pesf_id,
              unin_descricao: value.unin_numero + ' - ' + value.unin_descricao,
              unin_id: value.unin_id,
              total_credito: 'R$ ' + real(value.total_credito),
              total_debito: 'R$ ' + real(value.total_debito)
            }
          })
        },
        demonstrativosFiltrado: {
          list: data.map(value => {
            return {
              parcont_datapagamento: moment(value.parcont_datapagamento).format('DD/MM/YYYY'),
              parcont_datapagamento_sem_form: value.parcont_datapagamento,
              pesf_nome: value.pesf_nome.split(' ')[0],
              pesf_id: value.pesf_id,
              unin_descricao: value.unin_numero + ' - ' + value.unin_descricao,
              unin_id: value.unin_id,
              total_credito: 'R$ ' + real(value.total_credito),
              total_debito: 'R$ ' + real(value.total_debito)
            }
          })
        },
        loadingTable: false,
        filtroUsuarios: {
          pesf_id: "",
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  render() {
    const { loading, demonstrativosFiltrado, filtro, usuarios, filtroUsuarios } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Movimentações...</p>
              </div>
            </React.Fragment>
          }
          {!loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h1 className="titulo">Demonstrativo Diário</h1>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()}>
                    Filtrar
                  </Button>
                </Grid>
                <Grid item md={2} xs={false} sm={false}></Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Responsável"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="pesf_id"
                    value={filtroUsuarios.pesf_id}
                    onChangeCapture={(e) => this.updateFiltroUsuario(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    {usuarios.list.map(usuario => {
                      return (
                        <option key={usuario.pesf_id} value={usuario.pesf_id}>{usuario.pesf_nome}</option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              {this.state.loadingTable &&
                <div>
                  <CircularProgress />
                </div>
              }
              {!this.state.loadingTable &&
                <Grid container spacing={1} direction="row" className="mg_top_10" >
                  <Grid item md={12} xs={12}>
                    <Table urlView="/demonstrativo_diario/view/" headCell={this.state.cabecalhoTabela} rows={demonstrativosFiltrado.list} acoes={this.state.acoesTabela} />
                  </Grid>
                </Grid>
              }

            </div>
          }

          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}