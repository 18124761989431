import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import { Grid, Button } from '@material-ui/core'
import Table from './../Table'
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '96%',
    marginLeft: '2%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  }
}))(Tooltip);

const cabecalhoTabela = [
  { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição Conta' },
  { id: 'parcont_documento', numeric: false, disablePadding: false, label: 'Doc.' },
  { id: 'parcont_datavencimento', numeric: false, disablePadding: false, label: 'Venc. Original', orderBy: 'datavencimentoOrder' },
  { id: 'parcont_datapagamento', numeric: false, disablePadding: false, label: 'Pag.', orderBy: 'datapagamentoOrder' },
  { id: 'parcont_valor_previsto', numeric: true, disablePadding: true, label: 'Valor Considerado' },
  { id: 'parcont_valorparcela', numeric: true, disablePadding: false, label: 'Vl. Parc.' },
  { id: 'parcont_multajuros', numeric: true, disablePadding: true, label: 'Jur./Mul. ou Desc.' },
  { id: 'valpag_valor_pagamento', numeric: true, disablePadding: false, label: 'Vl. Pag./Rec.' }
]

function renderTooltipDentro() {
  const rows = []

  rows.push(<p><b key='1'>Total:</b> Apresenta o valor total da carteira de mensalidades pera o período e os valores pagos;</p>)
  rows.push(<p><b key='2'>Anterior:</b> Mensalidades vencidas dentro do período da carteira e pagas antecipadamente;</p>)
  rows.push(<p><b key='3'>Atual:</b> Mensalidade com vencimento e pagamento dentro do período da carteira;</p>)
  rows.push(<p><b key='4'>Atrasadas:</b> São mensalidades com vencimento dentro do período da carteira mas pagos após este período;</p>)

  return rows
}

function renderTooltipFora() {
  const rows = []

  rows.push(<p><b key='1'>Atrasadas:</b> Apresenta as mensalidades vencidas e recebidas dentro da carteira atual;</p>)
  rows.push(<p><b key='2'>Futuras:</b> São mensalidades com vencimento em carteira futura mas recebidas dentro da carteira atual;</p>)
  rows.push(<p><b key='3'>Inativos:</b> Mensalidades recebidas dentro do período que o aluno estava inativo;</p>)
  rows.push(<p><b key='4'>Período:</b> Apresenta as mensalidades que foram lançadas e pagas dentro do período da carteira;</p>)

  return rows
}

export default function SimpleModal(props) {
  const classes = useStyles()
      
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao" style={{float: 'left', marginTop: '10px'}}>{props.tituloCarteira}</h5>
              {props.tituloCarteira === 'Dentro da Carteira' &&
                <DarkTooltip
                  title={renderTooltipDentro()}
                >
                  <IconButton color="secondary" style={{float: 'right'}}>
                    <HelpIcon />
                  </IconButton>
                </DarkTooltip>
              }
              {props.tituloCarteira === 'Fora da Carteira' &&
                <DarkTooltip
                  title={renderTooltipFora()}
                >
                  <IconButton color="secondary" style={{float: 'right'}}>
                    <HelpIcon />
                  </IconButton>
                </DarkTooltip>
              }
            </Grid>
          </Grid>
          {props.loadingFiltroParcelas &&
            <React.Fragment>
              <br />
              <br />
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText" style={{top: '80%'}}>
                <p>Carregando Parcelas...</p>
              </div>
              <br />
              <br />
            </React.Fragment>
          }
          {!props.loadingFiltroParcelas && 
            <React.Fragment>
              <br />
              <Grid container spacing={1} direction="row">
                <Grid item md={8} xs={12}>
                  <h4>Unidade: {props.unidadeSelecionada.unin_numero} - {props.unidadeSelecionada.unin_descricao} - Mês: {props.filtroUnidade.data}</h4>
                </Grid>
                  <Grid item md={1} xs={12}>
                    {props.loadingDownloadParcelas &&
                      <CircularProgress />
                    }
                  </Grid>
                <Grid item md={3} xs={12}>
                  <Button style={{float: 'right'}} fullWidth variant="contained" color="secondary" onClick={() => props.imprimirParcelas()} disabled={props.filtro.situacao === '' ? true : (props.loadingDownloadParcelas ? true :false)}>
                    Imprimir
                  </Button>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4>Situação: {props.filtro.titulo}</h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table 
                    headCell={cabecalhoTabela} 
                    rows={props.dados}
                    acoes={['update']} 
                    urlUpdate="/conta_pagar_receber/cadastro/" 
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          }
        </div>
      </Modal>
    </div>
  );
}
