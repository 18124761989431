import React from 'react'
import { Container, Preview} from './styles'

const FileList = ({files, onDelete, category}) => (
	<Container>
		{files.map(uploadedFile => (
			<div key={uploadedFile.id}>
				<Preview src={uploadedFile.preview} />
			</div>
		))}
	</Container>
)

export default FileList