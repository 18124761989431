import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button } from '@material-ui/core'
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid blue',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloCancelamento">Cancelar Participação em Aula</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p className='perguntaTrocaQtdAlunos margin0'>Confirme as informações abaixo para continuar.</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='perguntaTrocaQtdAlunos margin0'>-{moment(props.dados.agenda.ageaula_data_hora).format('dddd').replace('-feira', '')}, {moment(props.dados.agenda.ageaula_data_hora).format('DD/MM/YYYY')} - {moment(props.dados.agenda.ageaula_data_hora).format('HH')}h</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='perguntaTrocaQtdAlunos margin0'>-{props.dados.agenda.aula.aula_nome}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='perguntaTrocaQtdAlunos margin0'>Professor: {props.dados.agenda.professor.funcionario.fisica.pesf_nome}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className='mg_top_20'>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => props.voltar()} disabled={props.disabledSalvar}>Voltar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(props.dados.agenda)} disabled={props.disabledSalvar}>Confirmar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div >
  );
}
