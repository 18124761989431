import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button, FormControl, InputLabel, OutlinedInput, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Observação da Prorrogação</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
						<Grid item md={12} xs={12} sm={12}>
							<TextField
								id="outlined-multiline-static"
								label="Observação"
								className="input"
								multiline
								rows={5}
								size="small"
								variant="outlined"
								name="observacao"
								value={dados}
								disabled={true}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
					</Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}></Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.handleClose()}>Voltar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
