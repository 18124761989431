import React, { Component } from 'react'
import './CodigoAlfaNum.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import ModalErro from './../../components/modals/Erro'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment'
import SearchIcon from '@material-ui/icons/Search';
const initalState = {
  codigo: {

  },
  codigos: {
    list: []
  },
  codigosFiltrado: {
    list: []
  },
  materiais: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'codmatdid_codigo', numeric: false, disablePadding: true, label: 'Código' },
    { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Material' },
    { id: 'codmatdid_criado_em', numeric: false, disablePadding: false, label: 'Gerado em' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Gerado por' },
    { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Gerado para' },
    { id: 'liberado_em', numeric: false, disablePadding: false, label: 'Liberado em' },
    { id: 'liberado_por', numeric: false, disablePadding: false, label: 'Liberado por' },
    { id: 'hiscod_situacao', numeric: false, disablePadding: false, label: 'Situação' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  permissoes:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  filtro: {
    codmatdid_codigo: '',
    codmatdid_prod_id: '',
    data_inicial: '',
    data_final: '',
    hiscod_unin_id: '',
    hiscod_situacao: '',
    data_por: 'GERADO'
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  loadingTable: false
}

function formatarCodigo(str) {
  const parte1 = str.slice(0, 4)
  const parte2 = str.slice(4, 8)
  const parte3 = str.slice(8, 12)

  return `${parte1}-${parte2}-${parte3}`
}

export default class Cargo extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'codigo-alfa')[0]

    if(permissoes){
      if(permissoes.perm_visualizar){
        this.setState({
          acoesTabela: ['view']
        })
      }

      this.setState({
        permissoes
      })
    }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/listBooks`, this.getToken())

      const produtos = []

      resp.data.forEach(produto => {
        produtos.push({
          _id: produto.prod_id,
          url: produto.midia.mid_caminho,
          prod_descricao: produto.prod_descricao,
          unidadeMedida: produto.prod_unidademedida,
          subGrupoProduto: produto.subgrupoproduto.subgrupprod_descricao,
          grupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
          visibilidade: produto.prod_visibilidade,
          ativo: produto.prod_ativo
        })
      })

      this.setState({
        materiais: {
          list: produtos
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())

      const unidades = []

      resp.data.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : (b.unin_numero > a.unin_numero) ? -1 : 0).forEach(unidade => {
        const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.end_principal)
        if (unidade.pessoa.pes_tipo === "FISICA") {
          let franqueado = ""
          if (unidade.superior.pessoa.pes_tipo === "FISICA") {
            franqueado = unidade.superior.pessoa.fisica.pesf_nome
          } else {
            franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
          }
          unidades.push({
            _id: unidade.unin_id,
            unin_numero: unidade.unin_numero,
            unin_descricao: unidade.unin_descricao,
            franqueado,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: unidade.pessoa.pes_tipo,
            ativo: unidade.unin_status
          })
        } else {
          let franqueado = ""
          if (unidade.superior.pessoa.pes_tipo === "FISICA") {
            franqueado = unidade.superior.pessoa.fisica.pesf_nome
          } else {
            franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
          }
          unidades.push({
            _id: unidade.unin_id,
            unin_numero: unidade.unin_numero,
            unin_descricao: unidade.unin_descricao,
            franqueado,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: unidade.pessoa.pes_tipo,
            ativo: unidade.unin_status
          })
        }
      });

      this.setState({
        unidades: {
          list: unidades
        },
        modalTransferir: true
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }


    this.setState({loadingTable: true})

    const filtro_codigos_alfa = JSON.parse(localStorage.getItem('filtro_codigos_alfa'))

    if(filtro_codigos_alfa){
      this.setState({
        filtro: filtro_codigos_alfa
      })
    }else{
  
      let data7Dias = new Date()
      data7Dias.setDate(data7Dias.getDate() + 15)
      data7Dias = moment(data7Dias).format('YYYY-MM-DD')
  
      let data7DiasAtras = new Date()
      data7DiasAtras.setDate(data7DiasAtras.getDate() - 15)
      data7DiasAtras = moment(data7DiasAtras).format('YYYY-MM-DD')

      this.setState({
        filtro:{
          codmatdid_codigo: '',
          codmatdid_prod_id: '',
          data_inicial: data7DiasAtras,
          data_final: data7Dias,
          hiscod_situacao: '',
          hiscod_unin_id: '',
          data_por: 'GERADO'
        }
      })
    }

    try {

      const { filtro } = this.state

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/codigoMaterial/filtro`, filtro, this.getToken())

      let codigos = data.sort((a, b) => new Date(a.codmatdid_criado_em).getTime() - new Date(b.codmatdid_criado_em).getTime()).map(codigo => {
        let hiscod_situacao = ''
        if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'AGUARDANDO_LIBERACAO') {
          hiscod_situacao = 'Aguardando Liberação'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'LIBERADO_USO') {
          hiscod_situacao = 'Liberado P/ Uso'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'ULTILIZADO') {
          hiscod_situacao = 'Ultilizado'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'AGUARDANDO_APROVACAO_TRANSFERENCIA') {
          hiscod_situacao = 'Aguardando Aprovação Transf.'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'CANCELADO') {
          hiscod_situacao = 'Cancelado'
        }

        let liberado_em = ''
        let liberado_por = ''

        const indexLiberado = codigo.historicosSemFiltro.findIndex(param => param.hiscod_situacao === 'LIBERADO_USO')

        if(indexLiberado !== -1){
          liberado_em = moment(codigo.historicosSemFiltro[indexLiberado].hiscod_criado_em).format('DD/MM/YYYY HH:mm')
          liberado_por = codigo.historicosSemFiltro[indexLiberado].usuario.pessoa.fisica ? codigo.historicosSemFiltro[indexLiberado].usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração'
        }

        return {
          _id: codigo.codmatdid_id,
          codmatdid_codigo: formatarCodigo(codigo.codmatdid_codigo),
          codmatdid_criado_em: moment(codigo.codmatdid_criado_em).format('DD/MM/YYYY HH:mm'),
          prod_descricao: codigo.produto.prod_descricao,
          pesf_nome: codigo.historico[0].usuario.pessoa.fisica ? codigo.historico[0].usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração',
          unin_descricao: codigo.historico[0].unidade.unin_numero ? codigo.historico[0].unidade.unin_numero + ' - ' + codigo.historico[0].unidade.unin_descricao : '0 - Central',
          hiscod_situacao,
          liberado_em,
          liberado_por
        }
      })

      this.setState({
        codigos: {
          list: codigos
        },
        codigosFiltrado: {
          list: codigos
        }
      })

      this.setState({loadingTable: false})

    } catch ({ response }) {
      this.setState({loadingTable: false})
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      unin_tipo,
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const { filtro } = this.state
    filtro[event.target.name] = event.target.value
    this.setState({ filtro })
  }

  async filtrar() {
    this.setState({loadingTable: true})
    const { filtro } = this.state

    let filtroDados = {
      codmatdid_codigo: filtro.codmatdid_codigo.replaceAll('-', ''),
      codmatdid_prod_id: filtro.codmatdid_prod_id,
      data_inicial: filtro.data_inicial,
      data_final: filtro.data_final,
      hiscod_situacao: filtro.hiscod_situacao,
      hiscod_unin_id: parseInt(filtro.hiscod_unin_id),
      data_por: filtro.data_por
    }

    localStorage.setItem('filtro_codigos_alfa', JSON.stringify(filtroDados));

    try {

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/codigoMaterial/filtro`, filtroDados, this.getToken())

      let codigos = data.sort((a, b) => new Date(a.codmatdid_criado_em).getTime() - new Date(b.codmatdid_criado_em).getTime()).map(codigo => {
        let hiscod_situacao = ''
        if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'AGUARDANDO_LIBERACAO') {
          hiscod_situacao = 'Aguardando Liberação'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'LIBERADO_USO') {
          hiscod_situacao = 'Liberado P/ Uso'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'ULTILIZADO') {
          hiscod_situacao = 'Ultilizado'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'AGUARDANDO_APROVACAO_TRANSFERENCIA') {
          hiscod_situacao = 'Aguardando Aprovação Transf.'
        } else if (codigo.historicosSemFiltro[codigo.historicosSemFiltro.length - 1].hiscod_situacao === 'CANCELADO') {
          hiscod_situacao = 'Cancelado'
        }

        let liberado_em = ''
        let liberado_por = ''

        const indexLiberado = codigo.historicosSemFiltro.findIndex(param => param.hiscod_situacao === 'LIBERADO_USO')

        if(indexLiberado !== -1){
          liberado_em = moment(codigo.historicosSemFiltro[indexLiberado].hiscod_criado_em).format('DD/MM/YYYY HH:mm')
          liberado_por = codigo.historicosSemFiltro[indexLiberado].usuario.pessoa.fisica ? codigo.historicosSemFiltro[indexLiberado].usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração'
        }

        return {
          _id: codigo.codmatdid_id,
          codmatdid_codigo: formatarCodigo(codigo.codmatdid_codigo),
          codmatdid_criado_em: moment(codigo.codmatdid_criado_em).format('DD/MM/YYYY HH:mm'),
          prod_descricao: codigo.produto.prod_descricao,
          pesf_nome: codigo.historico[0].usuario.pessoa.fisica ? codigo.historico[0].usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração',
          unin_descricao: codigo.historico[0].unidade.unin_numero ? codigo.historico[0].unidade.unin_numero + ' - ' + codigo.historico[0].unidade.unin_descricao : '0 - Central',
          hiscod_situacao,
          liberado_em,
          liberado_por
        }
      })

      this.setState({
        codigos: {
          list: codigos
        },
        codigosFiltrado: {
          list: codigos
        }
      })

      this.setState({loadingTable: false})

    } catch ({ response }) {
      this.setState({loadingTable: false})
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  render() {
    const { filtro, codigosFiltrado, materiais, permissoes,unidades } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Códigos...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Códigos Alfanuméricos</h1>
                </Grid>
                {this.state.unin_tipo === 'FRANQUEADOR' &&
                  <React.Fragment>
                    {permissoes.perm_inserir &&
                     <Grid item md={4} xs={12}>
                        <Link to="/codigo_alfa/cadastro">
                          <Button className="btnCadastrar" variant="contained" color="primary">
                            Cadastrar Código
                          </Button>
                        </Link>
                      </Grid>
                    }
                  </React.Fragment>
                 
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Código" variant="outlined" size="small" name="codmatdid_codigo" value={filtro.codmatdid_codigo} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                {this.state.unin_tipo !== 'UNIDADE' &&
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Unidade"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="hiscod_unin_id"
                      value={filtro.hiscod_unin_id}
                      onChangeCapture={(e) => this.updateFiltro(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""> </option>
                      {unidades.list.map(unidade => {
                        return (
                          <option key={unidade._id} value={unidade._id}>{unidade.unin_numero} - {unidade.unin_descricao} </option>
                        )
                      })}
                    </TextField>
                  </Grid>
                }
                {this.state.unin_tipo === 'UNIDADE' && 
                  <Grid item md={4} xs={false} sm={6}></Grid>
                }
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Material"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="codmatdid_prod_id"
                    value={filtro.codmatdid_prod_id}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> </option>
                    {materiais.list.map(material => {
                      if (material._id !== filtro.codmatdid_prod_id) {
                        return (
                          <option key={material._id} value={material._id}> {material.prod_descricao} </option>
                        )
                      } else {
                        return false
                      }
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situação"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="hiscod_situacao"
                    value={filtro.hiscod_situacao}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> </option>
                    <option value="AGUARDANDO_LIBERACAO">Aguardando Liberação</option>
                    <option value="AGUARDANDO_APROVACAO_TRANSFERENCIA">Aguardando Aprovação Transf.</option>
                    <option value="LIBERADO_USO">Liberado P/ Uso</option>
                    <option value="ULTILIZADO">Ultilizado</option>
                    <option value="CANCELADO">Cancelado</option>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar data por*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="data_por"
                    value={filtro.data_por}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="GERADO">Data de Geração</option>
                    <option value="LIBERADO">Data de Liberação</option>
                    <option value="ATRIBUICAO">Data Utilizado </option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" type='date' label="Data Inicial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={4} xs={false} sm={2}></Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SearchIcon />} onClick={e => this.filtrar(e)}>Filtrar</Button>
                </Grid>
              </Grid>
              {this.state.loadingTable && 
                <React.Fragment>
                  <div className="progressCircular">
                    <CircularProgress />
                  </div>
                  <div className="progressText">
                    <p>Carregando Códigos...</p>
                  </div>
                </React.Fragment>
              }
              {!this.state.loadingTable &&
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12}>
                    <Table urlView='/codigo_alfa/view/' headCell={this.state.cabecalhoTabela} rows={codigosFiltrado.list} acoes={this.state.acoesTabela} />
                  </Grid>
                </Grid>
              }
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}