import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button, FormControlLabel, Switch } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '../../../components/Table'
import AddIcon from '@material-ui/icons/Add'
import axios from 'axios'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import ModalErro from './../../../components/modals/Erro'

const initalState = {
  observacoes: {
    list: []
  },
  planosConta: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'funobs_id', numeric: false, disablePadding: false, label: 'id' },
    { id: 'data', numeric: false, disablePadding: false, label: 'Data' },
    { id: 'funobs_obs', numeric: false, disablePadding: false, label: 'Observação' },
    { id: 'funobs_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
    { id: 'responsavel', numeric: false, disablePadding: false, label: 'Responsável' }
  ],
  acoesTabela: ['update'],
  loading: true,
  observacao: {
    funobs_id: null,
    funobs_fun_id: null,
    funobs_obs: '',
    funobs_ativo: true
  },
  editando: false,
  modal: false,
  dialog: {
    ativo: false,
    titulo: "",
    descricao: ""
  },
  loadList: true
}


export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      const observacao = { ...this.state.observacao }
      observacao.funobs_fun_id = this.props.funcionario.fun_id
      this.setState({ observacao })


      await this.buscarObservacoes()

      this.setState({
        loading: false
      })
    } catch ({ response }) {
      this.openDialog({
        titulo: "Opps!",
        descricao: `${response.data.message}, tente mais tarde`
      })
    }
  }

  async buscarObservacoes() {
    this.setState({ loadList: true })
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/funcionarioobs/funcionario/${this.props.funcionario.fun_id}/list`, {}, this.getToken())

    this.setState({
      observacoes: {
        list: data
      },
      loadList: false
    })
  }

  updateField(event, name) {
    if (name === 'funobs_ativo') {
      const observacao = { ...this.state.observacao }
      observacao.funobs_ativo = !observacao.funobs_ativo
      this.setState({ observacao })
      return
    }

    const split = event.target.name.split('.')
    const data = { ...this.state[split[0]] }
    data[split[1]] = event.target.value
    const intercection = {}
    intercection[split[0]] = data
    this.setState({ ...intercection })
  }

  valida() {
    const observacao = { ...this.state.observacao }

    if (
      observacao.funobs_fun_id === null ||
      observacao.funobs_obs === ''
    ) {
      return false
    }

    return true
  }

  preparaEditar(edit) {
    const logado = JSON.parse(localStorage.getItem('usuario'))

    if (logado.usu_id !== edit.funobs_usu_id) {
      this.openDialog({
        titulo: 'Opps.',
        descricao: 'Somente o responsável pode editar a Observação'
      })
      return
    }

    this.setState({
      observacao: {
        funobs_id: edit.funobs_id,
        funobs_fun_id: edit.funobs_fun_id,
        funobs_obs: edit.funobs_obs,
        funobs_ativo: edit.funobs_ativo
      },
      editando: true
    })
    this.setState({ modal: true })
  }

  async salvar(resposta) {
    this.setState({ salvando: true })

    try {
      const observacao = { ...this.state.observacao }
      const editando = this.state.editando

      if (!this.valida()) {
        this.openDialog({
          titulo: 'Opps.',
          descricao: 'É necessário preencher todos os campos obrigatorios (*)'
        })
        this.setState({ salvando: false })
        return
      }

      if (!editando) {
        delete observacao.funobs_id
        await axios.post(`${process.env.REACT_APP_API_URL}/funcionarioobs`, observacao, this.getToken())
      } else {
        const funobs_id = observacao.funobs_id
        delete observacao.funobs_id
        delete observacao.funobs_fun_id
        await axios.put(`${process.env.REACT_APP_API_URL}/funcionarioobs/${funobs_id}`, observacao, this.getToken())
      }

      this.openDialog({
        titulo: 'Parabens.',
        descricao: editando ? 'Observação editada com sucesso' : 'Observação salva com sucesso'
      })

      this.reload()
      this.limparCampos()
      this.setState({ salvando: false })
    } catch (error) {
      this.setState({ salvando: false })
      this.openDialog({
        titulo: 'Opps.',
        descricao: error.response.data.message
      })
    }
  }

  reload(timeout = 1000) {
    const self = this
    setTimeout(() => {
      this.buscarObservacoes()
      self.setState({
        modal: false,
        salvando: false
      })
      self.closeDialog()
    }, timeout)
  }


  toogleModal() {
    this.setState({
      modal: !this.state.modal
    })
  }

  calcelarCadastro() {
    this.toogleModal()
    this.closeDialog()
    this.limparCampos()
  }

  limparCampos() {
    this.setState({
      observacao: {
        ...initalState.observacao,
        funobs_fun_id: this.props.funcionario.fun_id,
      },
      editando: false
    })
  }

  openDialog(error) {
    if (!error) error = {}

    this.setState({
      dialog: {
        ativo: true,
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  closeDialog() {
    this.setState({
      dialog: {
        ativo: false,
        titulo: '',
        descricao: ''
      }
    })
  }

  render() {
    const observacoes = []

    for (const object of this.state.observacoes.list) {
      observacoes.push({
        ...object,
        data: moment(object.funobs_criado_em).format('DD/MM/YYYY HH:mm') + 'h',
        responsavel: object.usuario.pessoa.fisica ?
          object.usuario.pessoa.fisica.pesf_nome.split(' ')[0] :
          'By Click Sistemas'
      })
    }

    const funcionario = this.props.funcionario
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Observações...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div style={{ 'marginTop': '50px' }}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={6} xs={12} sm={6}>
                <h3> Observações </h3>
              </Grid>
              {funcionario.fun_status && 
                <Grid item md={6} xs={12} sm={6}>
                  <Button
                    style={{ float: 'right', 'marginTop': '5px' }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.toogleModal()}
                    startIcon={<AddIcon />}
                  >
                    Cadastrar
                  </Button>
                </Grid>
              }
            </Grid>
            <Grid container spacing={1} direction="row" style={{ 'marginTop': '15px' }}>
              {this.state.loadList &&
                <React.Fragment>
                  <div className="progressCircular">
                    <CircularProgress />
                  </div>
                </React.Fragment>
              }
              <Grid item md={12} xs={12}>
                {!this.state.loadList &&
                  <Table
                    urlUpdate={e => this.preparaEditar(e)}
                    headCell={this.state.cabecalhoTabela}
                    rows={observacoes}
                    acoes={this.state.acoesTabela}
                    ordem="desc"
                    ordernar="funobs_id"
                  />
                }
              </Grid>
            </Grid>

            <ModalErro open={this.state.dialog.ativo} titulo={this.state.dialog.titulo} descricao={this.state.dialog.descricao} handleClose={e => this.closeDialog(e)} />

            <Dialog
              disableBackdropClick
              fullWidth={true}
              maxWidth="lg"
              open={this.state.modal}
              onClose={e => this.toogleModal(e)}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogContent>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12} sm={12}>
                    {!this.state.editando && <h4>Cadastrar Observação</h4>}
                    {this.state.editando && <h4>Editar Observação</h4>}
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} style={{ 'marginTop': '20px' }}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Observação*"
                      className="input"
                      multiline
                      rows={5}
                      size="small"
                      variant="outlined"
                      name="observacao.funobs_obs"
                      value={this.state.observacao.funobs_obs}
                      onChange={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md={2} xs={6} sm={2}>
                    <FormControlLabel
                      style={{ 'marginTop': '2px' }}
                      label="Ativo"
                      control={<Switch
                        className="switch"
                        name="alterar"
                        color="primary"
                        checked={this.state.observacao.funobs_ativo}
                      />}
                      name="alterar"
                      onChange={(e) => this.updateField(e, 'funobs_ativo')}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={e => this.calcelarCadastro(e)}
                  color="primary"
                  disabled={this.state.salvando}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={e => this.salvar(e)}
                  disabled={this.state.salvando}
                >
                  Salvar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        }

      </React.Fragment>
    )
  }
}