import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import ModalErro from './../../components/modals/Erro'
import ModalSubRelatorio from './../../components/modals/QuantidadeAulas'

const initalState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
		func_id: "",
		fun_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm:{
        perm_visualizar: false
      }
    }]
  },
  loading: false,
  loadingDownload: false,
  urlView: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
	funcionarios: {
		list: []
	},
	funcoes:{
		list: []
	},
  subCabecalhoTabela: [],
  subAcoesTabela: [],
  subRelatorios: {
    list: []
  },
  subUrlView: '',
  modalSubRelatorio: false,
  filtroFuncionario: {
    fun_id: "",
  },
  filtroFuncao: {
    func_id: ""
  }
}

const relatoriosAux = {
  list: []
}

export default class Pedagogico extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    // const { filtro } = this.state

    // filtro.data_inicial = moment(new Date()).format('YYYY-MM-DD')

    // this.setState({
    //   filtro
    // })

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    
    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'rel-ranking')

    this.setState({
      relatorios_options: {
        list: permissoes.map( value => {
          return {
            tela_descricao: value.tela.tela_descricao,
            tela_slug: value.tela.tela_slug,
            perm: {
              perm_visualizar: value.perm_visualizar
            }
          }
        })
      }
    })
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({ filtro })
  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loading: true
    })

    if (filtro.data_inicial === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data inicial'
      })
      this.setState({
        loading: false
      })
      return
    }

    if (filtro.data_final === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data final'
      })
      this.setState({
        loading: false
      })
      return
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())

      if(filtro.relatorio === 'matriculas-por-cargo'){
        this.setState({
          funcionarios:{
            list: data.list.filter(param => param.pesf_nome !== '').map(value => {
              return {
                fun_id: value.fun_id,
                pesf_nome: value.pesf_nome,
                func_descricao: value.func_descricao,
              }
            })
          },
          funcoes:{
            list: data.list.filter(param => param.func_descricao !== 'Total').map(value => {
              return {
                func_id: value.func_id,
                func_descricao: value.func_descricao,
              }
            })
          }
        })
      }

      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list
        },
        relatoriosFiltrado: {
          list: data.list
        },
        urlView: data.urlView
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }
  }

  updateFieldFiltroFuncionario(event){
    const relatorios = this.state.relatorios.list
    const filtroFuncionario = this.state.filtroFuncionario

    let list = []

    if (event.target.value !== '') {
      list = relatorios.filter(param => param.fun_id === parseInt(event.target.value))
    } else {
      list = relatorios
    }

    filtroFuncionario[event.target.name] = event.target.value

    this.setState({
      relatoriosFiltrado: {
        list
      },
      filtroFuncao: {
        func_id: ''
      },
      filtroFuncionario
    })
  }

  updateFieldFiltroFuncao(event){
    const relatorios = this.state.relatorios.list
    const filtroFuncao = this.state.filtroFuncao

    let list = []

    if (event.target.value !== '') {
      list = relatorios.filter(param => param.func_id === parseInt(event.target.value))
    } else {
      list = relatorios
    }

    filtroFuncao[event.target.name] = event.target.value

    this.setState({
      relatoriosFiltrado: {
        list
      },
      filtroFuncao,
      filtroFuncionario: {
        fun_id: ''
      }
    })
  }

  async imprimir(value) {
    const { filtro, relatorios, subFiltro, subRelatorios } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      let filtroImpressao = {}

      console.log(value)

      if(value === 'principal'){
        filtro.unidadesnegocio = dados.unidadesnegocio
        filtro.list = relatorios.list

        filtroImpressao = { ...filtro}
      }else{
        subFiltro.titulo = 'Alunos'
        subFiltro.unidadesnegocio = dados.unidadesnegocio
        subFiltro.list = subRelatorios.list

        filtroImpressao = {...subFiltro}
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtroImpressao, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `Matricula-por-Cargo.pdf`);

      this.setState({
        loadingDownload: false
      })

    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  // async imprimir(value) {

  //   const {filtro} = this.state

  //   this.setState({
  //     loadingDownload: true
  //   })

  //   try {
  //     const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

  //     const dados = {
  //       unidadesnegocio: acesso_atual.map(acesso => {
  //         return acesso.unin_id
  //       }),
       
  //     }

  //     filtro.unidadesnegocio = dados.unidadesnegocio
  //     filtro.list = relatoriosAux.list

  //     const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

  //     const dadosImprimir = {
  //       path: data
  //     }

  //     const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

  //     const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

  //     this.setState({
  //       loadingDownload: false
  //     })

  //     saveAs(pdfBlob, `Matricula-por-Cargo.pdf`);

  //   } catch (error) {
  //     this.setState({
  //       loadingDownload: false
  //     })
  //     console.log(error)
  //   }
  // }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  registrarRows(value){
    
    relatoriosAux.list = value
    
    // this.setState({
    //   relatoriosAux: {
    //     list: value
    //   }
    // })
  }

  async viewRow(row, acao) {
    const filtro = this.state.filtro

    const subFiltro = {...filtro}
    subFiltro.relatorio = acao
    subFiltro.ids = row
    
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, subFiltro, this.getToken())

      this.setState({
        subCabecalhoTabela: data.cabecalhoTabela,
        subAcoesTabela: data.acoesTabela,
        subRelatorios: {
          list: data.list
        },
        subUrlView: data.urlView,
        modalSubRelatorio: true,
        subFiltro
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }

  }

  handleCloseSubRelatorio(){
    this.setState({
      modalSubRelatorio: false
    })
  }

  render() {
    const { filtro, cabecalhoTabela, acoesTabela, relatorios, urlView, loadingDownload, relatorios_options, 
      funcionarios, funcoes, relatoriosFiltrado, filtroFuncionario, filtroFuncao } = this.state
		
		return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Relatório Ranking</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
            <Grid item md={4} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Relatório"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="relatorio"
                value={filtro.relatorio}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha o Relatório--</option>
                {relatorios_options.list.sort((a, b) => (a.tela_descricao > b.tela_descricao) ? 1 : ((b.tela_descricao > a.tela_descricao) ? -1 : 0)).map(value => {
                  if(value.perm){
                   if(value.perm.perm_visualizar){
                      return (
                        <option value={value.tela_slug}>{value.tela_descricao}</option>
                      )
                    }
                  }
                  return false
                })}
              </TextField>
            </Grid>
						<Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.relatorio === '' ? true : false}>
                Filtrar
              </Button>
            </Grid>
            {relatorios.list.length > 0 &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir('principal')} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
					{filtro.relatorio === 'matriculas-por-cargo' &&
            <React.Fragment>
              {relatorios.list.length > 0 &&
                <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Colaborador"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="fun_id"
                      value={filtroFuncionario.fun_id}
                      onChangeCapture={(e) => this.updateFieldFiltroFuncionario(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""></option>
                      {funcionarios.list.sort((a, b) => (a.pesf_nome > b.pesf_nome) ? 1 : ((b.pesf_nome > a.pesf_nome) ? -1 : 0)).map(value => {
                        return (
                          <option value={value.fun_id}>{value.pesf_nome}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Função"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="func_id"
                      value={filtroFuncao.func_id}
                      onChangeCapture={(e) => this.updateFieldFiltroFuncao(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""></option>
                      {funcoes.list.sort((a, b) => (a.func_descricao > b.func_descricao) ? 1 : ((b.func_descricao > a.func_descricao) ? -1 : 0)).map(value => {
                        return (
                          <option value={value.func_id}>{value.func_descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                </Grid>
              }
            </React.Fragment>

					}
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {cabecalhoTabela.length > 0 &&
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table 
                      urlView={urlView} 
                      headCell={cabecalhoTabela} 
                      rows={relatoriosFiltrado.list} 
                      acoes={acoesTabela} 
                      registrarRows={e => this.registrarRows(e)}
                      viewRow={(e, acao) => this.viewRow(e, acao)} />
                  </Grid>
                </Grid>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalSubRelatorio
            open={this.state.modalSubRelatorio}
            urlView={this.state.subUrlView}
            handleClose={e => this.handleCloseSubRelatorio(e)}
            list={this.state.subRelatorios.list}
            cabecalhoTabela={this.state.subCabecalhoTabela}
            acoesTabela={this.state.subAcoesTabela}
            imprimir={e => this.imprimir(e)}
            loadingDownload={loadingDownload}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}