import React, { Component } from 'react'
import './ContaPagarReceber.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'
import { saveAs } from 'file-saver';
import FormParcelaConta from '../../components/forms/FormParcelaConta'

import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import ModalErro from './../../components/modals/Erro'
import ModalBaixaFinanceiro from './../../components/modals/BaixaFinanceiro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import real from '../../services/real'

import ModalConfirmacao from './../../components/modals/ConfirmacaoCancelamento'
import ModalSimple from './../../components/modals/Confirmacao'
import ModalConfirmacaoEstorno from './../../components/modals/ConfirmacaoEstorno'
import Color from 'color';
import { withStyles } from "@material-ui/core/styles";

const initalState = {
  parcela: {
    parcont_baixado: "",
    parcont_datavencimento: "",
    parcont_valorparcela: '0,00',
    parcont_documento: "",
    parcont_num: "",
    parcont_motivo_cancelamento: "",
    parcela_editada: false,
    parcont_multajuros: '0,00',
    formas_pgto: [
      {
        valpag_id: '',
        valpag_formapagto_id: '',
        valpag_valor_pagamento: real(0),
        formpag_descricao: '',
        formpag_hab_caixa: false,
        contfin_id: '',
        contfin_descricao: '',
        contfin_tipo_conta: '',
        index: 0
      }
    ]
  },
  planoContas: {
    plancontas_id: "",
    plancontas_codigo: "",
    plancontas_descricao: "",
    plancontas_ativo: "",
  },
  planosConta: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  formasPagamentoRemovidos: {
    list: []
  },
  modalConfirmacao: {},
  modalConfirmacaoOpen: false,
  modalConfirmacaoEstornoOpen: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false,
  modalCaixaOpen: false,
  modalCaixa: {
    mensagem: '',
    situacao: ''
  },
  permissoesContasPR: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesCaixa: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  perfilLogado: '',
  valor_total: 0,
  modalBaixaFinanceiro: false,
  dadosModalBaixa: {
    modal: ''
  },
  nova_parcela: {
    valor_restante: '',
    vencimento_nova_parcela: '',
    forma_pgto_nova_parcela: '',
    gerar: false
  },
  formas_sem_filtro: {
    list: []
  },
  valor_troco: 0,
  disabledButtons: false,
  forma_pgto: {
    valpag_id: '',
    valpag_formapagto_id: '',
    valpag_valor_pagamento: real(0),
    formpag_descricao: '',
    formpag_hab_caixa: false,
    index: ''
  },
  updateForma: false,
  movConciliada: false
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const ColorGreenButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#008000').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#008000',
    },
  },
}))(Button);

export default class CadastroPlanoContas extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {

    this.setState({
      nova_parcela: {
        valor_restante: '',
        vencimento_nova_parcela: '',
        forma_pgto_nova_parcela: '',
        gerar: false
      },
      forma_pgto: {
        valpag_id: '',
        valpag_formapagto_id: '',
        valpag_valor_pagamento: real(0),
        formpag_descricao: '',
        formpag_hab_caixa: false,
        index: ''
      },
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'contas-pagar-receber')[0]
    let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'caixa')[0]
    let perfilLogado = ''

    if (permissoesCaixa) {
      perfilLogado = 'caixa'
      this.setState({
        permissoesCaixa,
        perfilLogado: 'caixa'
      })
    }

    if (permissoesContasPR) {
      perfilLogado = 'contas-pagar-receber'
      this.setState({
        permissoesContasPR,
        perfilLogado: 'contas-pagar-receber'
      })
    }

    try {
      const caixa = JSON.parse(localStorage.getItem('caixa'))
      this.setState({ logado: { caixa } })

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      const planoContas = data.sort((a, b) => (a.plancontas_codigo > b.plancontas_codigo) ? 1 : ((b.plancontas_codigo > a.plancontas_codigo) ? -1 : 0)).map(param => {
        return {
          plancontas_id: param.plancontas_id,
          plancontas_codigo: param.plancontas_codigo,
          plancontas_descricao: param.plancontas_descricao,
          plancontas_ativo: param.plancontas_ativo,
        }
      })

      this.setState({
        planosConta: {
          list: planoContas
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formas_sem_filtro: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })

      if (perfilLogado === 'caixa') {
        this.setState({
          formasPagamento: {
            list: formasPagamento.filter(param => param.formpag_ativo === true && param.formpag_hab_caixa === true)
          },
          formasPagamentoRemovidos: {
            list: formasPagamento.filter(param => param.formpag_ativo === true && param.formpag_hab_caixa === true)
          }
        })
      } else {
        this.setState({
          formasPagamento: {
            list: formasPagamento.filter(param => param.formpag_ativo === true)
          },
          formasPagamentoRemovidos: {
            list: formasPagamento.filter(param => param.formpag_ativo === true)
          }
        })
      }


    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    const { match: { params } } = this.props;
    if (params.parcelaId) {
      try {
        const { data: parcela } = await axios.get(`${process.env.REACT_APP_API_URL}/parcelaConta/${params.parcelaId}`, this.getToken())
        const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${parcela.conta.pertence.unin_id}`, this.getToken())

        this.setState({
          contasFinanceira: {
            list: contasFinanceira
          }
        })
        
        let usuario_baixa = null

        if(parcela.parcont_baixado){
          usuario_baixa = parcela.usuario_baixa ? parcela.usuario_baixa.pessoa.fisica.pesf_nome : 'Migração'
        }

        const estado = {
          parcela: {
            contpr_id: parcela.conta.contpr_id,
            parcont_criado_em: moment(parcela.parcont_criado_em).format('DD/MM/YYYY HH:mm'),
            parcont_datavencimento: moment(parcela.parcont_datavencimento).format('YYYY-MM-DD'),
            parcont_datavencimento_orig: moment(parcela.parcont_datavencimento).format('YYYY-MM-DD'),
            parcont_datapagamento: moment(parcela.parcont_datapagamento).format('DD/MM/YYYY HH:mm'),
            parcont_valorparcela: real(parcela.parcont_valorparcela),
            parcont_valorparcela_sem_form: parcela.parcont_valorparcela,
            criador: parcela.conta.criador.pessoa.fisica ? parcela.conta.criador.pessoa.fisica.pesf_nome : 'Migração',
            usuario_baixa,
            usuario_cancelamento: parcela.parcont_usu_cancelamento ? parcela.usuario_cancelamento.pessoa.fisica.pesf_nome : null,
            data_cancelamento: parcela.parcont_data_cancelamento ?moment(parcela.parcont_data_cancelamento).format('DD/MM/YYYY HH:mm') : null,
            contpr_descricao: parcela.conta.contpr_descricao,
            contpr_numparcela: parcela.conta.contpr_numparcela,
            parcont_multajuros: real(parcela.parcont_multajuros ? parcela.parcont_multajuros : 0),
            parcont_num: parcela.parcont_num,
            parcont_documento: parcela.parcont_documento ? parcela.parcont_documento : "",
            parcont_baixado: parcela.parcont_baixado,
            parcont_situacao: parcela.parcont_situacao,
            plancontas_id: parcela.conta.planocontas ? parcela.conta.planocontas.plancontas_id : '',
            plancontas_slug: parcela.conta.planocontas ? parcela.conta.planocontas.plancontas_slug : '',
            parcont_motivo_cancelamento: parcela.parcont_motivo_cancelamento ? parcela.parcont_motivo_cancelamento : "",
            formas_pgto: parcela.valores_pago.map((valor_pago, key) => {
              return {
                valpag_id: valor_pago.valpag_id,
                valpag_formapagto_id: valor_pago.forma_pagto.formpag_id,
                valpag_valor_pagamento: valor_pago.valpag_valor_pagamento ? real(valor_pago.valpag_valor_pagamento) : (parcela.valores_pago.length > 1 ? real(0) :real(parcela.parcont_valorparcela)),
                formpag_descricao: valor_pago.forma_pagto.formpag_descricao,
                formpag_hab_caixa: valor_pago.forma_pagto.formpag_hab_caixa,
                contfin_tipo_conta: valor_pago.conta_financeira ? valor_pago.conta_financeira.contfin_tipo_conta : '',
                contfin_descricao: valor_pago.conta_financeira ? valor_pago.conta_financeira.contfin_descricao : '',
                contfin_id: valor_pago.conta_financeira ? valor_pago.conta_financeira.contfin_id : '',
                movcontfin_conciliado: valor_pago.mov ? valor_pago.mov.movcontfin_conciliado : false,
                movcontfin_valor: valor_pago.mov ? valor_pago.mov.movcontfin_valor : false,
                index: key
              }
            }),
            parcont_boleto_digitable_line: parcela.parcont_boleto_digitable_line,
            parcont_boleto_link: parcela.parcont_boleto_link,
            parcont_boleto_transaction_id: parcela.parcont_boleto_transaction_id,
            parcont_tipo_transaction: parcela.parcont_tipo_transaction
          },
          planoContas: {
            plancontas_id: parcela.conta.planocontas ? parcela.conta.planocontas.plancontas_id : '',
            plancontas_codigo: parcela.conta.planocontas ? parcela.conta.planocontas.plancontas_codigo : '',
            plancontas_descricao: parcela.conta.planocontas ? parcela.conta.planocontas.plancontas_descricao : '',
            plancontas_ativo: parcela.conta.planocontas ? parcela.conta.planocontas.plancontas_ativo : '',
          },
          loading: false
        }

        if (this.state.logado.caixa) {
          for (let i = 0; i < estado.parcela.formas_pgto.length; i++) {
            if(estado.parcela.formas_pgto[i].contfin_id === ''){
              estado.parcela.formas_pgto[i].contfin_tipo_conta = contasFinanceira.length ? contasFinanceira[0].contfin_tipo_conta : null
              estado.parcela.formas_pgto[i].contfin_descricao = contasFinanceira.length ? contasFinanceira[0].contfin_descricao : null
              estado.parcela.formas_pgto[i].contfin_id = contasFinanceira.length ? contasFinanceira[0].contfin_id : null
            }
          }
        }

        const {forma_pgto} = this.state

        if (this.state.logado.caixa) {
          forma_pgto.contfin_id = contasFinanceira.length ? contasFinanceira[0].contfin_id : null
          forma_pgto.contfin_descricao = contasFinanceira.length ? contasFinanceira[0].contfin_descricao : null
          forma_pgto.contfin_tipo_conta = contasFinanceira.length ? contasFinanceira[0].contfin_tipo_conta : null
        }


        this.setState({
          forma_pgto
        })

        const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa'))
        estado.ultimoCaixa = ultimoCaixa || {}

        this.setState(estado)

      } catch ({response}) {
        // console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: response.data.message
        })

        this.backPage()
      }
    }
  }

  updateField(event) {
    const parcela = { ...this.state.parcela }

    this.setState({ parcela })
    parcela[event.target.name] = event.target.value
  }

  verificaData(event){
    const parcela = { ...this.state.parcela }

    if(parcela.parcont_datavencimento !== parcela.parcont_datavencimento_orig){
       const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
      const data_vencimento = new Date(parcela.parcont_datavencimento)

      if (data_vencimento.getTime() < data_hoje.getTime()) {
        parcela.parcont_datavencimento = parcela.parcont_datavencimento_orig
      }
    }

    this.setState({
      parcela
    })
  }

  verificaDataNovaParcela(event){
    const nova_parcela = { ...this.state.nova_parcela }
    
     const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
     const data_vencimento = new Date(event.target.value)

    if (data_vencimento.getTime() < data_hoje.getTime()) {
      nova_parcela.vencimento_nova_parcela = moment(new Date()).format('YYYY-MM-DD')
    }

    this.setState({
      nova_parcela
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 4000) {
    const self = this
    setTimeout(() => {
      const ultima_tela_financeiro = JSON.parse(localStorage.getItem('ultima_tela_financeiro'))

      if (ultima_tela_financeiro.tela === 'contasPR') {
        self.props.history.push("/conta_pagar_receber");
      } else if (ultima_tela_financeiro.tela === 'aluno') {
        self.props.history.push(`/aluno/view/${ultima_tela_financeiro.matricula}/financeiro`)
      }
    }, timeout)
    this.setState({
      parcela: initalState.parcela
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleClose() {
    this.setState({
      modalConfirmacaoOpen: false,
      modalConfirmacaoEstornoOpen: false,
      modalCaixaOpen: false
    })
  }

  voltar() {
    const self = this
    const ultima_tela_financeiro = JSON.parse(localStorage.getItem('ultima_tela_financeiro'))

    if (ultima_tela_financeiro.tela === 'contasPR') {
      self.props.history.push("/conta_pagar_receber");
    } else if (ultima_tela_financeiro.tela === 'aluno') {
      self.props.history.push(`/aluno/view/${ultima_tela_financeiro.matricula}/financeiro`)
    } else if (ultima_tela_financeiro.tela === 'carteira-mensalidade') {
      self.props.history.push(`/carteira_mensalidades`)
    }
    
  }

  validateFormSalvar() {
    const { parcela } = this.state

    if (!parcela.contpr_descricao) return false
    if (!parcela.contpr_id) return false
    // if (!parcela.plancontas_id) return false

    if (!parcela.parcont_datavencimento) return false
    if (!parcela.parcont_valorparcela) return false
    if (!parcela.parcont_num) return false

    for (let i = 0; i < parcela.formas_pgto.length; i++) {
      if (!parcela.formas_pgto[i].valpag_formapagto_id) return false
    }

    return true
  }

  validateFormBaixar() {
    const { parcela } = this.state

    if (!parcela.contpr_descricao) return false
    if (!parcela.contpr_id) return false
    // if (!parcela.plancontas_id) return false

    if (!parcela.parcont_datavencimento) return false
    if (!parcela.parcont_valorparcela) return false
    if (!parcela.parcont_num) return false

    for (let i = 0; i < parcela.formas_pgto.length; i++) {
      if (!parcela.formas_pgto[i].valpag_formapagto_id) return false
      if (!parcela.formas_pgto[i].contfin_id) return false
      if (i > 1) {
        if (parseFloat(parcela.formas_pgto[i].valpag_valor_pagamento.replace('.', '').replace(',', '.')) === 0) {
          parcela.formas_pgto.splice(i, 1)
        }
      }
    }

    return true
  }

  verificaCaixa() {
    const { ultimoCaixa, perfilLogado, formasPagamento } = this.state

    if (
      perfilLogado === 'caixa' &&
      (
        !Object.keys(ultimoCaixa).length ||
        (ultimoCaixa && ultimoCaixa['status_atual.scd_status'] !== 'ABERTO')
      )
    ) {
      if (ultimoCaixa['status_atual.scd_status'] === 'FECHADO') {
        this.setState({
          modalCaixa: {
            mensagem: 'É necessário abrir um caixa, deseja abrir um caixa?',
            situacao: 'ABRIR'
          },
          modalCaixaOpen: true,
        })
        return false
      }
      this.handleOpenDialog({
        titulo: 'Opps!',
        descricao: 'O seu caixa anterior está em processo de aprovação, não é possível baixar essa parcela!'
      })
      return false
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.cai_fechado
    ) {
      const dataCaixa = moment(ultimoCaixa.cai_dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        // verificar Data abertura
        this.setState({
          modalCaixa: {
            mensagem: 'Opps, O caixa não é de hoje, é necessario fechar o anterior e abrir um novo, deseja ser redirecionado para o fechamento?',
            situacao: 'FECHAR'
          },
          modalCaixaOpen: true,
        })
        return false
      }
    }

    if (perfilLogado === 'caixa') {
      let dinheiro = false
      for (const forma of formasPagamento.list) {
        if (forma.formpag_slug === 'dinheiro'
          || forma.formpag_slug === 'cartão-de-crédito'
          || forma.formpag_slug === 'cartão-de-débito'
          || forma.formpag_slug === 'cheque') {
          dinheiro = true
        }
      }

      if (!dinheiro) {
        this.handleOpenDialog({
          titulo: 'Opps!',
          descricao: 'Essa forma de pagamento não é permitida para o caixa'
        })
        return false
      }
    }

    return true
  }

  confirmarCaixa(resposta) {
    const { modalCaixa, ultimoCaixa } = this.state

    if (resposta) {
      const encode = Buffer.from(this.props.location.pathname).toString('base64')

      if (modalCaixa.situacao === 'ABRIR') {
        this.props.history.push({ pathname: `/caixa/cadastro/${encode}` })
      }

      if (modalCaixa.situacao === 'FECHAR') {
        this.props.history.push({ pathname: `/caixa/view/${ultimoCaixa.cai_id}` })
      }

    } else {
      this.handleClose()
    }
  }

  async salvar() {
    
    if (!this.validateFormSalvar()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    try {

      const { parcela } = this.state

      const dados = {
        conta: {
          contpr_id: parcela.contpr_id,
          contpr_descricao: parcela.contpr_descricao,
          plancontas_id: parcela.plancontas_id
        },
        parcela: {
          parcont_baixado: parcela.parcont_baixado,
          parcont_datavencimento: parcela.parcont_datavencimento,
          parcont_valorparcela: parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")),
          parcont_num: parcela.parcont_num,
          parcont_multajuros: parseFloat(parcela.parcont_multajuros.replace("R$ ", "").replace(".", "").replace(",", ".")),
          parcont_documento: parcela.parcont_documento,
          parcont_situacao: parcela.parcont_situacao,
          formas_pgto: parcela.formas_pgto.map(forma => {
            if (forma.valpag_id !== "") {
              return {
                valpag_id: forma.valpag_id,
                valpag_formapagto_id: forma.valpag_formapagto_id,
                contfin_id: parseInt(forma.contfin_id),
                valpag_valor_pagamento: forma.valpag_valor_pagamento ? parseFloat(forma.valpag_valor_pagamento.replace("R$ ", "").replace(".", "").replace(",", ".")) : 0,
              }
            } else {
              return {
                valpag_formapagto_id: forma.valpag_formapagto_id,
                contfin_id: parseInt(forma.contfin_id),
                valpag_valor_pagamento: forma.valpag_valor_pagamento ? parseFloat(forma.valpag_valor_pagamento.replace("R$ ", "").replace(".", "").replace(",", ".")) : 0,
              }
            }

          })
        }
      }

      if (dados.parcela.parcont_documento === "" || !dados.parcela.parcont_documento) {
        delete dados.parcela.parcont_documento
      }

      const { match: { params } } = this.props;

      console.log('AQUI!!!!!')

      await axios.put(`${process.env.REACT_APP_API_URL}/parcelaConta/${params.parcelaId}`, dados, this.getToken())

      // if(data.geraBoletoPix){
      //   if(data.formpag_slug === 'boleto'){
      //     await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: params.parcelaId}, this.getToken())
      //   }else{
      //     await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: params.parcelaId}, this.getToken())
      //   }
      // }

      this.setState({
        parcela: initalState.parcela
      })

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Atualização realizada com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  async baixar() {

    this.setState({
      disabledButtons: true
    })

    if (!this.verificaCaixa()) {
      return false
    }

    if (!this.validateFormBaixar()) {
      this.setState({
        disabledButtons: false
      })

      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    try {
      const { parcela, perfilLogado, nova_parcela } = this.state
      const arrayValores = parcela.formas_pgto.map(forma => { return parseFloat(forma.valpag_valor_pagamento.replace('.', '').replace(',', '.')) })
      const valor_total_parcela = parcela.parcont_valorparcela_sem_form + parseFloat(parcela.parcont_multajuros.replace('.', '').replace(',', '.'))
      const valoresParcelas = somarValores(arrayValores)

      if (valoresParcelas === 0 || valoresParcelas < 0) {
        this.setState({
          disabledButtons: false
        })
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Preencha o campo do valor pago!"
        })
        return
      }

      if (parseFloat(valoresParcelas.toFixed(2)) > parseFloat(valor_total_parcela.toFixed(2))) {
        const index = parcela.formas_pgto.findIndex(param => param.formpag_descricao === 'Dinheiro')

        if (index !== -1) {
          const valor_troco = valoresParcelas - valor_total_parcela


          if (valor_troco >= parseFloat(parcela.formas_pgto[index].valpag_valor_pagamento.replace('.', '').replace(',', '.'))) {
            this.setState({
              disabledButtons: false
            })
            this.handleOpenDialog({
              titulo: "Opss!",
              descricao: `Valor de troco superior a quantia recebida em dinheiro`
            })
            return
          } else {
            parcela.formas_pgto[index].valpag_valor_pagamento = real(parseFloat(parcela.formas_pgto[index].valpag_valor_pagamento.replace('.', '').replace(',', '.')) - valor_troco)

            this.setState({
              valor_troco
            })
          }

        }

        if (index === -1) {
          this.setState({
            disabledButtons: false
          })
          this.handleOpenDialog({
            titulo: "Opss!",
            descricao: `Valor informado maior que o valor da parcela, não é possivel retornar troco nessa forma de pagamento`
          })
          return
        }
      }

      if (!nova_parcela.gerar) {
        if (valoresParcelas < valor_total_parcela) {
          this.setState({
            modalBaixaFinanceiro: true,
            disabledButtons: false,
            dadosModalBaixa: {
              modal: 'falta_valor'
            }
          })
          return
        }
      }

      if (perfilLogado === 'caixa') {
        for (let i = 0; i < parcela.formas_pgto.length; i++) {
          if (!parcela.formas_pgto[i].formpag_hab_caixa) {
            this.setState({
              disabledButtons: false
            })
            this.handleOpenDialog({
              titulo: "Erro no Cadastro!",
              descricao: `O caixa não pode receber por ${parcela.formas_pgto[i].formpag_descricao}`
            })
            return
          }
        }
      }

      for (let i = 0; i < parcela.formas_pgto.length; i++) {
        if(parseFloat(parcela.formas_pgto[i].valpag_valor_pagamento.replace("R$ ", "").replace(".", "").replace(",", ".")) === 0 ){
 
          parcela.formas_pgto.splice(i, 1)

        }
      }

      const dados = {
        conta: {
          contpr_id: parcela.contpr_id,
          contpr_descricao: parcela.contpr_descricao,
          plancontas_id: parcela.plancontas_id
        },
        parcela: {
          parcont_baixado: true,
          parcont_datavencimento: parcela.parcont_datavencimento,
          parcont_valorparcela: parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")),
          parcont_multajuros: parseFloat(parcela.parcont_multajuros.replace("R$ ", "").replace(".", "").replace(",", ".")),
          parcont_num: parcela.parcont_num,
          parcont_documento: parcela.parcont_documento,
          parcont_situacao: 'BAIXADO',
          formas_pgto: parcela.formas_pgto.map(forma => {
            if (forma.valpag_id !== "") {
              return {
                valpag_id: forma.valpag_id,
                valpag_formapagto_id: forma.valpag_formapagto_id,
                contfin_id: parseInt(forma.contfin_id),
                valpag_valor_pagamento: parseFloat(forma.valpag_valor_pagamento.replace("R$ ", "").replace(".", "").replace(",", ".")),
              }
            } else {
              return {
                valpag_formapagto_id: forma.valpag_formapagto_id,
                contfin_id: parseInt(forma.contfin_id),
                valpag_valor_pagamento: parseFloat(forma.valpag_valor_pagamento.replace("R$ ", "").replace(".", "").replace(",", ".")),
              }
            }
          })
        }
      }

      if (nova_parcela.gerar) {
        dados.nova_parcela = {
          valor_restante: nova_parcela.valor_restante,
          vencimento_nova_parcela: nova_parcela.vencimento_nova_parcela,
          forma_pgto_nova_parcela: parseInt(nova_parcela.forma_pgto_nova_parcela)
        }
      }

      if (perfilLogado === 'caixa') {
        dados.caixa = this.state.ultimoCaixa.cai_id
      }

      if (dados.parcela.parcont_motivo_cancelamento === "" || !dados.parcela.parcont_motivo_cancelamento) {
        delete dados.parcela.parcont_motivo_cancelamento
      }

      const { match: { params } } = this.props;

      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/parcelaConta/${params.parcelaId}`, dados, this.getToken())

      if (data.caminhoRecibo !== '') {

        const dados = {
          path: data.caminhoRecibo
        }

        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

        saveAs(pdfBlob, `Recibo-${params.parcelaId}.pdf`);
      }

      if (this.state.valor_troco !== 0) {
        this.setState({
          modalBaixaFinanceiro: true,
          dadosModalBaixa: {
            modal: 'informar_troco'
          }
        })
      } else {

        this.setState({
          parcela: initalState.parcela,
          nova_parcela: initalState.nova_parcela
        })

        this.setState({
          disabledButtons: false
        })

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Parcela baixada com sucesso.'
        })
        this.backPage()
      }

    } catch ({response}) {
      // console.log(error)
      this.setState({
        disabledButtons: false
      })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  confirmarEstorno() {
    const { parcela } = this.state

    let movConciliada = false

    for (const i in parcela.formas_pgto) {
      if(parcela.formas_pgto[i].movcontfin_conciliado){
        movConciliada = true
      }
    }

    this.setState({
      modalConfirmacaoEstornoOpen: true,
      movConciliada
    })
  }

  async estornar() {
    try {

      const { parcela } = this.state

      const dados = {
        conta: {
          contpr_id: parcela.contpr_id,
          contpr_descricao: parcela.contpr_descricao,
          plancontas_id: parcela.plancontas_id
        },
        parcela: {
          parcont_baixado: true,
          parcont_datavencimento: parcela.parcont_datavencimento,
          parcont_valorparcela: parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")),
          parcont_multajuros: parseFloat(parcela.parcont_multajuros.replace("R$ ", "").replace(".", "").replace(",", ".")),
          parcont_num: parcela.parcont_num,
          parcont_documento: parcela.parcont_documento,
          parcont_situacao: 'ESTORNADO',
          parcont_motivo_cancelamento: parcela.parcont_motivo_cancelamento,
          formas_pgto: parcela.formas_pgto.map(forma => {
            if (forma.valpag_id !== "") {
              return {
                valpag_id: forma.valpag_id,
                valpag_formapagto_id: parseInt(forma.valpag_formapagto_id),
                contfin_id: parseInt(forma.contfin_id),
                valpag_valor_pagamento: parseFloat(forma.valpag_valor_pagamento.replace("R$ ", "").replace(".", "").replace(",", ".")),
              }
            } else {
              return {
                valpag_formapagto_id: parseInt(forma.valpag_formapagto_id),
                contfin_id: parseInt(forma.contfin_id),
                valpag_valor_pagamento: parseFloat(forma.valpag_valor_pagamento.replace("R$ ", "").replace(".", "").replace(",", ".")),
              }
            }
          })
        }
      }

      if (dados.parcela.parcont_documento === "" || !dados.parcela.parcont_documento) {
        delete dados.parcela.parcont_documento
      }

      const { match: { params } } = this.props;

      await axios.put(`${process.env.REACT_APP_API_URL}/parcelaConta/${params.parcelaId}`, dados, this.getToken())

      this.setState({
        parcela: initalState.parcela
      })

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Atualização realizada com sucesso.'
      })
      this.backPage()

    } catch ({response}) {
    
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  async cancelar() {
    this.setState({
      modalConfirmacaoOpen: true
    })
  }

  async confirmar(param) {
    if (param) {

      this.setState({disabledButtons: true})
      const dados = {
        parcont_motivo_cancelamento: this.state.parcela.parcont_motivo_cancelamento
      }

      try {

        const { match: { params } } = this.props;

        await axios.put(`${process.env.REACT_APP_API_URL}/parcelaConta/cancelar/${params.parcelaId}`, dados, this.getToken())

        this.setState({
          parcela: initalState.parcela
        })

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Atualização realizada com sucesso.'
        })

        this.backPage()

      } catch ({ response }) {
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          this.setState({disabledButtons: false})
          return
        }

        this.handleOpenDialog({})
      }

    } else {
      this.setState({
        modalConfirmacaoOpen: false
      })
    }
  }

  addFormaPagto() {
    this.setState({
      modalBaixaFinanceiro: false
    })
    const { parcela, forma_pgto } = this.state

    const arrayValores = parcela.formas_pgto.map(forma => { return parseFloat(forma.valpag_valor_pagamento) })
    const valoresParcelas = somarValores(arrayValores)
    const itens = parcela.formas_pgto
    const valor_total_parcela = parcela.parcont_valorparcela_sem_form + parseFloat(parcela.parcont_multajuros.replace('.', '').replace(',', '.'))

    if (forma_pgto.index === '') {

      if(itens.length > 0 ){
        if (parseFloat(itens[itens.length - 1].valpag_valor_pagamento.replace('.', '').replace(',', '.')) === 0) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: `Por favor informar o valor do pagamento na Forma de Pagamento ${itens.length}!`
          })
          return false
        }
  
        if (itens[itens.length - 1].valpag_formapagto_id === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: `Por favor informar a Forma de Pagamento do item ${itens.length}!`
          })
          return false
        }
      }

      if (valoresParcelas >= valor_total_parcela) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'O valor total é igual ou maior que o valor da parcela!'
        })
        return
      } else {

        itens.push({
          valpag_id: forma_pgto.valpag_id !== '' ? forma_pgto.valpag_id : '',
          valpag_formapagto_id: forma_pgto.valpag_formapagto_id,
          valpag_valor_pagamento: forma_pgto.valpag_valor_pagamento,
          formpag_descricao: forma_pgto.formpag_descricao,
          formpag_hab_caixa: forma_pgto.formpag_hab_caixa,
          contfin_id: forma_pgto.contfin_id,
          contfin_descricao: forma_pgto.contfin_descricao,
          contfin_tipo_conta: forma_pgto.contfin_tipo_conta,
          index: itens.length
        })

        parcela.formas_pgto = itens

        const forma_pgtoAux = {
          valpag_id: '',
          valpag_formapagto_id: '',
          valpag_valor_pagamento: real(0),
          formpag_descricao: '',
          formpag_hab_caixa: false,
          contfin_id: '',
          contfin_descricao: '',
          contfin_tipo_conta: '',
          index: ''
        }

        if (this.state.logado.caixa) {
          forma_pgtoAux.contfin_id = this.state.contasFinanceira.list.length ? this.state.contasFinanceira.list[0].contfin_id : null
          forma_pgtoAux.contfin_descricao = this.state.contasFinanceira.list.length ? this.state.contasFinanceira.list[0].contfin_descricao : null
          forma_pgtoAux.contfin_tipo_conta = this.state.contasFinanceira.list.length ? this.state.contasFinanceira.list[0].contfin_tipo_conta : null
        }

        this.setState({
          parcela,
          valor_total: valoresParcelas + parseFloat(forma_pgto.valpag_valor_pagamento.replace('.', '').replace(',', '.')),
          forma_pgto: forma_pgtoAux,
          updateForma: false
        })
      }
    } else {
      itens[forma_pgto.index] = {
        valpag_id: forma_pgto.valpag_id !== '' ? forma_pgto.valpag_id : '',
        valpag_formapagto_id: forma_pgto.valpag_formapagto_id,
        valpag_valor_pagamento: forma_pgto.valpag_valor_pagamento,
        formpag_descricao: forma_pgto.formpag_descricao,
        formpag_hab_caixa: forma_pgto.formpag_hab_caixa,
        contfin_id: forma_pgto.contfin_id,
        contfin_descricao: forma_pgto.contfin_descricao,
        contfin_tipo_conta: forma_pgto.contfin_tipo_conta,
        index: forma_pgto.index
      }

      parcela.formas_pgto = itens

      let forma_pgtoAux = {
        valpag_id: '',
        valpag_formapagto_id: '',
        valpag_valor_pagamento: real(0),
        formpag_descricao: '',
        formpag_hab_caixa: false,
        contfin_id: '',
        contfin_descricao: '',
        contfin_tipo_conta: '',
        index: ''
      }

      if (this.state.logado.caixa) {
        forma_pgtoAux.contfin_id = this.state.contasFinanceira.list.length ? this.state.contasFinanceira.list[0].contfin_id : null
        forma_pgtoAux.contfin_descricao = this.state.contasFinanceira.list.length ? this.state.contasFinanceira.list[0].contfin_descricao : null
        forma_pgtoAux.contfin_tipo_conta = this.state.contasFinanceira.list.length ? this.state.contasFinanceira.list[0].contfin_tipo_conta : null
      }

      this.setState({
        parcela,
        valor_total: valoresParcelas + parseFloat(forma_pgto.valpag_valor_pagamento.replace('.', '').replace(',', '.')),
        forma_pgto: forma_pgtoAux,
        updateForma: false
      })
    }
  }

  editarFormaPgto(index) {
    let { parcela, perfilLogado, contasFinanceira, forma_pgto } = this.state

    if(perfilLogado === 'caixa'){
      console.log(contasFinanceira)
      console.log(parcela.formas_pgto[index])
      

      forma_pgto = parcela.formas_pgto[index]
      forma_pgto.valpag_formapagto_id = ''
      forma_pgto.formpag_descricao = ''
      forma_pgto.formpag_hab_caixa = ''

      forma_pgto.contfin_id = contasFinanceira.list[0].contfin_id
      forma_pgto.contfin_descricao = contasFinanceira.list[0].contfin_descricao
      forma_pgto.contfin_tipo_conta = contasFinanceira.list[0].contfin_tipo_conta

      console.log(forma_pgto)

      this.setState({
        forma_pgto,
        updateForma: true
      })

    }else{
      this.setState({
        forma_pgto: parcela.formas_pgto[index],
        updateForma: true
      })
    }
  }

  updateFieldFormaPgto(event, key) {
    let { forma_pgto, formasPagamento, contasFinanceira } = this.state

    if (event.target.name === 'valpag_formapagto_id') {
      const itens = formasPagamento.list
      const index = itens.findIndex(param => param.formpag_id === parseInt(event.target.value))

      forma_pgto[event.target.name] = parseInt(event.target.value)
      forma_pgto.formpag_descricao = itens[index].formpag_descricao
      forma_pgto.formpag_hab_caixa = itens[index].formpag_hab_caixa
    } else if (event.target.name === 'contfin_id') {
      const itens = contasFinanceira.list
      const index = itens.findIndex(param => param.contfin_id === parseInt(event.target.value))

      forma_pgto[event.target.name] = parseInt(event.target.value)
      
      if(itens[index]){
        forma_pgto.contfin_descricao = itens[index].contfin_descricao
        forma_pgto.contfin_tipo_conta = itens[index].contfin_tipo_conta
      }
      
      forma_pgto.valpag_formapagto_id = ''
      forma_pgto.formpag_descricao = ''
      forma_pgto.formpag_hab_caixa = ''

      // if ( perfilLogado === 'contas-pagar-receber'){
      //   forma_pgto.valpag_formapagto_id = ''
      //   forma_pgto.formpag_descricao = ''
      //   forma_pgto.formpag_hab_caixa = ''
      // }
     
    } else {
      forma_pgto[event.target.name] = event.target.value
    }

    this.setState({ forma_pgto })
  }

  openModalNovaParcela() {
    this.setState({
      modalBaixaFinanceiro: false
    })

    const { nova_parcela, parcela } = this.state

    const arrayValores = parcela.formas_pgto.map(forma => { return parseFloat(forma.valpag_valor_pagamento.replace('.', '').replace(',', '.')) })

    const valoresParcelas = somarValores(arrayValores)

    nova_parcela.valor_restante = parseFloat(parcela.parcont_valorparcela.replace('.', '').replace(',', '.')) + parseFloat(parcela.parcont_multajuros ? parcela.parcont_multajuros.replace('.', '').replace(',', '.') : 0) - valoresParcelas

    this.setState({
      modalBaixaFinanceiro: true,
      dadosModalBaixa: {
        modal: 'nova_parcela'
      },
      nova_parcela
    })
    return
  }

  updateFieldNovaParcela(event) {
    const { nova_parcela } = this.state

    nova_parcela[event.target.name] = event.target.value

    this.setState({ nova_parcela })
  }

  recusarNovaParcela() {
    this.setState({
      modalBaixaFinanceiro: false,
      nova_parcela: {
        valor_restante: '',
        vencimento_nova_parcela: '',
        forma_pgto_nova_parcela: ''
      }
    })
  }

  confirmarNovaParcela() {
    const { nova_parcela } = this.state

    nova_parcela.gerar = true

    this.setState(nova_parcela)

    this.baixar()
  }

  confirmarTroco() {
    this.setState({
      disabledButtons: false
    })

    this.setState({
      parcela: initalState.parcela
    })

    this.handleOpenDialog({
      titulo: 'Parabéns',
      descricao: 'Parcela baixada com sucesso.'
    })
    this.backPage()
  }

  removeFormaPagto(event) {
    const { parcela } = this.state

    let itens = parcela.formas_pgto

    if (itens.length >= 1) {
      itens.splice(event, 1)

      for (let i = 0; i < itens.length; i++) {
        itens[i].index = i
      }

      parcela.formas_pgto = itens

      this.setState({
        parcela
      })
    }
  }

  handleCloseBaixa() {
    this.setState({
      modalBaixaFinanceiro: false
    })
  }

  render() {
    const { parcela, logado } = this.state
        
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11}>
                  <h2 className="titulo">Alteração da Parcela</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.disabledButtons &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormParcelaConta
                logado={logado}
                dados={parcela}
                updateField={e => this.updateField(e)}
                planoContas={this.state.planoContas}
                planosConta={this.state.planosConta.list}
                contasFinanceira={this.state.contasFinanceira.list}
                formasPagamento={this.state.formasPagamento.list}
                ultimoCaixa={this.state.ultimoCaixa}
                perfilLogado={this.state.perfilLogado}
                addFormaPagto={e => this.addFormaPagto(e)}
                updateFieldFormaPgto={(e, key) => this.updateFieldFormaPgto(e, key)}
                valor_total={this.state.valor_total}
                removeFormaPagto={(e, key) => this.removeFormaPagto(e, key)}
                forma_pgto={this.state.forma_pgto}
                editarFormaPgto={e => this.editarFormaPgto(e)}
                updateForma={this.state.updateForma}
                verificaData={e => this.verificaData(e)}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10" spacing={1}>
                {parcela.parcont_situacao !== 'TRANSFERIDO' &&
                  <React.Fragment>
                    {parcela.parcont_situacao !== 'BAIXADO' &&
                      <React.Fragment>
                        {parcela.parcont_situacao !== 'CANCELADO' &&
                          <React.Fragment>
                            {parcela.parcont_situacao !== 'ESTORNADO' &&
                              <React.Fragment>
                                <Grid item md={3} xs={12} sm={6}>
                                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)} disabled={this.state.disabledButtons}>Salvar sem Baixar</Button>
                                </Grid>
                                <Grid item md={3} xs={12} sm={6}>
                                  <ColorGreenButton
                                    fullWidth
                                    variant="contained"
                                    className="btn_salvar"
                                    size="small"
                                    startIcon={<GetAppIcon />}
                                    onClick={e => this.baixar(e)}
                                    disabled={this.state.disabledButtons}
                                  >
                                    Baixar
                                  </ColorGreenButton>
                                </Grid>
                                {this.state.perfilLogado === 'contas-pagar-receber' &&
                                  <Grid item md={3} xs={12} sm={6}>
                                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => this.cancelar(e)} disabled={this.state.disabledButtons}>Cancelar</Button>
                                  </Grid>
                                }
                              </React.Fragment>
                            }
                          </React.Fragment>
                        }
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
                {parcela.parcont_situacao === 'BAIXADO' &&
                  <React.Fragment>
                    {!parcela.movcontfin_conciliado &&
                      <Grid item md={3} xs={12} sm={6}>
                        <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => this.confirmarEstorno(e)} disabled={this.state.disabledButtons}>Estornar</Button>
                      </Grid>
                    }
                  </React.Fragment>
                }
                <Grid item md={3} xs={12} sm={6}>
                  <Button fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIosIcon />} onClick={e => this.voltar(e)} disabled={this.state.disabledButtons}>Voltar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <br />
          <ModalConfirmacao 
            open={this.state.modalConfirmacaoOpen} 
            dados={parcela} handleClose={e => this.handleClose(e)} 
            updateData={e => this.updateField(e)} 
            confirmar={e => this.confirmar(e)} 
            disabledButtons={this.state.disabledButtons}
          />

          <ModalConfirmacaoEstorno 
            open={this.state.modalConfirmacaoEstornoOpen} 
            dados={parcela} 
            handleClose={e => this.handleClose(e)} 
            updateData={e => this.updateField(e)} 
            confirmar={e => this.estornar(e)} 
            movConciliada={this.state.movConciliada}/>

          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />

          <ModalSimple open={this.state.modalCaixaOpen} dados={this.state.modalCaixa} handleClose={e => this.handleClose(e)} updateData={e => this.updateField(e)} confirmar={e => this.confirmarCaixa(e)} />

          <ModalBaixaFinanceiro
            open={this.state.modalBaixaFinanceiro}
            dados={this.state.dadosModalBaixa}
            handleClose={e => this.handleCloseBaixa(e)}
            recusar={e => this.openModalNovaParcela(e)}
            verificaDataNovaParcela={e => this.verificaDataNovaParcela(e)}
            updateFieldNovaParcela={e => this.updateFieldNovaParcela(e)}
            nova_parcela={this.state.nova_parcela}
            formas_sem_filtro={this.state.formas_sem_filtro.list}
            confirmarNovaParcela={e => this.confirmarNovaParcela(e)}
            recusarNovaParcela={e => this.recusarNovaParcela(e)}
            valor_troco={this.state.valor_troco}
            confirmarTroco={e => this.confirmarTroco(e)} 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}