import React from 'react'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import Table from './../Table'
import CurrencyInput from './../mask/CurrencyInput'

let tabela_paramentros = [
  {
    parametro: 'FIXO',
    value: 'Fixo'
  },
  {
    parametro: 'ALUNOS',
    value: 'Por Alunos'
  },
  {
    parametro: 'NUM_WHATS',
    value: 'Por Números do Whats'
  }
]

let cabecalhoTabela = [
  { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Serviço' },
  { id: 'configprod_dia_vencimento', numeric: true, disablePadding: true, label: 'Dia do Vencimento' },
  { id: 'parametro', numeric: false, disablePadding: true, label: 'Parâmetro' },
  { id: 'valor_form', numeric: true, disablePadding: true, label: 'Valor' },
  { id: 'configprod_habilita_valor_min', numeric: true, disablePadding: true, label: 'Hab. Mínimo' },
  { id: 'valor_min', numeric: true, disablePadding: true, label: 'Valor Mínimo' },
  { id: 'configprod_habilita_valor_max', numeric: true, disablePadding: true, label: 'Hab. Máximo' },
  { id: 'valor_max', numeric: true, disablePadding: true, label: 'Valor Máximo' },
  { id: 'configprod_ativo', numeric: false, disablePadding: true, label: 'Ativo' },
]

let acoesTabela = ['editar', 'remove']

const FormConfiguracaoCobranca = ({ dados, updateField, updateFieldAtivo, editarItem, removerItem, produtos, servico, 
  updateFieldServico, updateFieldAtivoServico, addItem, update, contasFinanceira, formasPagamento, planosConta,
  updateFieldMinServico, updateFieldMaxServico }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={4}>
        <TextField 
          className="input" 
          label="Descrição*"
          variant="outlined" 
          size="small" 
          name="configcobran_descricao" 
          value={dados.configcobran_descricao} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          label="Forma de Pagamento*"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="configcobran_formpag_id"
          value={dados.configcobran_formpag_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {formasPagamento.map(forma => {
            return (
              <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          label="Conta Financeira*"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="configcobran_contfin_id"
          value={dados.configcobran_contfin_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.configcobran_formpag_id === ''}
        >
          <option value="">--Escolha--</option>
          {contasFinanceira.filter(param => param.contfin_tipo_conta === (parseInt(dados.configcobran_formpag_id) === 1 ? 'COFRE': 'BANCO')).map(conta => {
            return (
              <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          label="Plano de Contas Receita*"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="configcobran_receita_plancontas_id"
          value={dados.configcobran_receita_plancontas_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {planosConta.filter(param => param.parametros.paramfin_caixa === "C").map((plano, key) => {
            return (
              <option key={key} value={plano.plancontas_id}>{plano.plancontas_codigo} - {plano.plancontas_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          label="Plano de Contas Despesa*"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="configcobran_despesa_plancontas_id"
          value={dados.configcobran_despesa_plancontas_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {planosConta.filter(param => param.parametros.paramfin_caixa === "D").map((plano, key) => {
            return (
              <option key={key} value={plano.plancontas_id}>{plano.plancontas_codigo} - {plano.plancontas_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField 
          className="input" 
          label="Qtd. Dias Juros*"
          type="number"
          variant="outlined" 
          size="small"
          name="configcobran_dias_pagamento" 
          value={dados.configcobran_dias_pagamento} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField 
          className="input" 
          label="% Juros*"
          variant="outlined" 
          size="small" 
          name="configcobran_juros" 
          value={dados.configcobran_juros} 
          InputProps={{ inputComponent: CurrencyInput }}
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>
      <Grid item md={2} xs={12} sm={2}>
        <FormControl style={{marginLeft: 20}} component="fieldset">
          <FormLabel component="legend">Ativo</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.configcobran_ativo}
              />
            }
            size="small"
            label={dados.configcobran_ativo ? 'Sim' : 'Não'}
            name="configcobran_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <h4>Adicionar Serviços:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Serviço*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="prod_id"
          value={servico.prod_id}
          onChangeCapture={(e) => updateFieldServico(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={servico.prod_id !== '' ? servico.prod_id : ''}> {servico.prod_id !== '' ? servico.prod_descricao : 'Selecionar Serviço'}  </option>
          {produtos.filter(param => param._id !== parseInt(servico.prod_id)).map((produto, key) => {
            return (
              <option key={key} value={produto._id}> {produto.prod_descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField className="input" type='number' label="Dia de Vencimento*" variant="outlined" size="small" name="configprod_dia_vencimento" value={servico.configprod_dia_vencimento} onChange={(e) => updateFieldServico(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={3}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade de Medida"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="configprod_parametro"
          value={servico.configprod_parametro}
          onChangeCapture={(e) => updateFieldServico(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          {servico.configprod_parametro === "" &&
            <option value="">
              Selecionar Parâmetro de Cobrança
					  </option>
          }
          <option value="FIXO">
            Fixo
					</option>
          <option value="ALUNOS">
            Por Alunos
					</option>
          <option value="NUM_WHATS">
            Por Números do Whats
					</option>
        </TextField>
      </Grid>
      <Grid item md={(servico.configprod_parametro === "" || servico.configprod_parametro === "FIXO") ? 1 : 2} xs={12} sm={2}>
        <TextField className="input" label="Valor*" variant="outlined" size="small" name="configprod_valor" value={servico.configprod_valor} onChange={(e) => updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
      </Grid>
      <Grid item md={(servico.configprod_parametro === "" || servico.configprod_parametro === "FIXO") ? 1 : 2} xs={12} sm={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={servico.configprod_ativo}
              />
            }
            size="small"
            label={servico.configprod_ativo ? 'Sim' : 'Não'}
            name="configprod_ativo"
            onChange={(e) => updateFieldAtivoServico(e)}
          />
        </FormControl>
      </Grid>
      {(servico.configprod_parametro !== "FIXO" && servico.configprod_parametro !== "") &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Informar Valor Mínimo</FormLabel>
              <FormControlLabel className="mg_left_10"
                control={
                  <Switch
                    name="Ativo"
                    color="primary"
                    checked={servico.configprod_habilita_valor_min}
                  />
                }
                size="small"
                label={servico.configprod_habilita_valor_min ? 'Sim' : 'Não'}
                name="configprod_habilita_valor_min"
                onChange={(e) => updateFieldMinServico(e)}
              />
            </FormControl>
          </Grid>
          {servico.configprod_habilita_valor_min &&
            <Grid item md={2} xs={12} sm={2}>
              <TextField className="input" label="Valor Mínimo*" variant="outlined" size="small" name="configprod_valor_min" value={servico.configprod_valor_min} onChange={(e) => updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
            </Grid>
          }
          <Grid item md={2} xs={12} sm={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Informar Valor Máximo</FormLabel>
              <FormControlLabel className="mg_left_10"
                control={
                  <Switch
                    name="Ativo"
                    color="primary"
                    checked={servico.configprod_habilita_valor_max}
                  />
                }
                size="small"
                label={servico.configprod_habilita_valor_max ? 'Sim' : 'Não'}
                name="configprod_habilita_valor_max"
                onChange={(e) => updateFieldMaxServico(e)}
              />
            </FormControl>
          </Grid>
          {servico.configprod_habilita_valor_max &&
            <Grid item md={2} xs={12} sm={2}>
              <TextField className="input" label="Valor Máximo*" variant="outlined" size="small" name="configprod_valor_max" value={servico.configprod_valor_max} onChange={(e) => updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
            </Grid>
          }
        </React.Fragment>
      }
      <Grid item md={2} xs={12} sm={4}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <Table
          editar={e => editarItem(e)}
          remove={e => removerItem(e)}
          headCell={cabecalhoTabela}
          rows={dados.servicos.map(value => {
            return {
              ...value,
              valor_form: value.configprod_valor, 
              parametro: tabela_paramentros.filter(param => param.parametro === value.configprod_parametro)[0].value,
              valor_min: value.configprod_habilita_valor_min ? value.configprod_valor_min : 'N/A',
              valor_max: value.configprod_habilita_valor_max ? value.configprod_valor_max : 'N/A'
            }
          })}
          acoes={acoesTabela}
          noRemove={false} 
        />
      </Grid>
    </Grid>
    <hr style={{marginTop: 10}}/>
  </React.Fragment>
)

export default FormConfiguracaoCobranca