import React, { Component } from 'react'
import './Funcionario.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import SearchIcon from '@material-ui/icons/Search';
import { Grid, Button, TextField } from '@material-ui/core'

import axios from 'axios'

const initalState = {
  funcionario: {

  },
  funcionarios: {
    list: []
  },
  funcionariosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'func_descricao', numeric: false, disablePadding: false, label: 'Função' },
    { id: 'unin_tipo', numeric: false, disablePadding: false, label: 'Trabalha para' },
    { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'fun_status', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: [],
  filtro: {
    contt_ativo: 'SIM',
    pesf_nome: "",
    unin_descricao: "",
    func_descricao: ""
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class Funcionario extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    this.setState({
      filtro: {
        contt_ativo: 'SIM',
        pesf_nome: "",
        unin_descricao: "",
        func_descricao: ""
      }
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'funcionario')[0]

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    let filtro = this.state.filtro

    let aux = {
      contt_ativo: filtro.contt_ativo === 'SIM' ? true : false,
      pesf_nome: filtro.pesf_nome,
      unin_descricao: filtro.unin_descricao,
      func_descricao: filtro.func_descricao
    }

    aux.unidadesnegocio = dados.unidadesnegocio

    try {
      const { data: funcionarios } = await axios.post(`${process.env.REACT_APP_API_URL}/funcionarios/list/filtro`, aux, this.getToken())

      this.setState({
        funcionarios: {
          list: funcionarios
        },
        funcionariosFiltrado: {
          list: funcionarios
        },
        loading: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })
  }

  async filtrar() {

    this.setState({
      loading: true
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    let filtro = this.state.filtro

    let aux = {
      contt_ativo: filtro.contt_ativo === 'SIM' ? true : false,
      pesf_nome: filtro.pesf_nome,
      unin_descricao: filtro.unin_descricao,
      func_descricao: filtro.func_descricao
    }

    aux.unidadesnegocio = dados.unidadesnegocio

    try {
      const { data: funcionarios } = await axios.post(`${process.env.REACT_APP_API_URL}/funcionarios/list/filtro`, aux, this.getToken())

      this.setState({
        funcionarios: {
          list: funcionarios
        },
        funcionariosFiltrado: {
          list: funcionarios
        },
        loading: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { filtro, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Colaboradores...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Colaboradores</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/funcionario/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Colaborador
								    </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Nome" variant="outlined" size="small" name="pesf_nome" value={filtro.nome} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Função" variant="outlined" size="small" name="func_descricao" value={filtro.funcao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Unidade" variant="outlined" size="small" name="unin_descricao" value={filtro.unin_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Ativo"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="contt_ativo"
                    value={filtro.contt_ativo}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                  >
                    <option key="Sim" value='SIM'>
                      Sim
					          </option>
                    <option key="Não" value='NAO'>
                      Não
					          </option>
                  </TextField>
                </Grid>
                <Grid item md={1} xs={12} sm={6}>
                  <Button className='btn_search_func' fullWidth color="primary" variant="contained" endIcon={<SearchIcon />} onClick={e => this.filtrar(e)}></Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/funcionario/cadastro/" urlView="/funcionario/view/" headCell={this.state.cabecalhoTabela} rows={this.state.funcionariosFiltrado.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}