import React, { Component } from 'react'
import './Unidade.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import CircularProgress from '@material-ui/core/CircularProgress';
import TabViews from './TabViews'
import ModalErro from './../../components/modals/Erro'
import axios from 'axios'

const initalState = {
  unidade: {},
  locaisEstoques: [],
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  loadingSalvar: false,
  unin_tipo: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoes_contrato: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoes_documento: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class View extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'unidade')[0]
    let permissoes_contrato = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'contrato-unidade')[0]
    let permissoes_documento = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'upload-doc-unidades')[0]

    this.setState({
      permissoes
    })

    if(permissoes_contrato){
      this.setState({
        permissoes_contrato
      })
    }

    if(permissoes_documento){
      this.setState({
        permissoes_documento
      })
    }

    if (!permissoes.perm_visualizar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {

      const { match: { params } } = this.props;

      const { data: unidade } = await axios.get(`${process.env.REACT_APP_API_URL}/unidades/${params.unidadeId}`, this.getToken())

      this.setState({
        unidade: unidade,
        loading: false
      })

    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      // return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { unidade, locaisEstoques } = this.state
    const { match: { params } } = this.props;
    
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <TabViews 
                unidade={unidade} 
                locaisEstoques={locaisEstoques} 
                unin_tipo={this.state.unin_tipo} 
                history={this.props.history} 
                permissoes_contrato={this.state.permissoes_contrato}
                permissoes_documento={this.state.permissoes_documento}
                params={params}
              />
            </div>
          }

          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}