import React, { Component } from 'react'
import './CupomDesconto.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Alert from './../../components/templates/Alert'
import { Grid, Button } from '@material-ui/core'
import FormCupom from '../../components/forms/FormCupom'
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  cupom: {
    cupom_id: '',
    cupom_hash: '',
    cupom_descricao: '',
    cupom_tipo: '',
    cupom_valor: '',
    cupom_data_inicio: '',
    cupom_data_fim: '',
    cupom_ativo: true
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  }
}

export default class CadastroCupom extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cupom-desconto')[0]

    this.setState({
      permissoes
    })

    if (params.cupomId) {
      if (!permissoes.perm_alterar) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        })

        this.backPage()
      }
    }

    if (params.cupomId) {
      try {
        const { data: cupom } = await axios.get(`${process.env.REACT_APP_API_URL}/cupomDesconto/${params.cupomId}`, this.getToken())

        this.setState({
          cupom: {
            cupom_id: cupom.cupom_id,
            cupom_hash: cupom.cupom_hash,
            cupom_descricao: cupom.cupom_descricao,
            cupom_tipo: cupom.cupom_tipo,
            cupom_valor: cupom.cupom_valor,
            cupom_data_inicio: cupom.cupom_data_inicio,
            cupom_data_fim: cupom.cupom_data_fim,
            cupom_ativo: cupom.cupom_ativo
          }
        })

      } catch ({response}) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          }
        })
        return
      }
    }

    this.setState({
      loading: false
    })

  }

  updateField(event) {
    const cupom = { ...this.state.cupom }
    cupom[event.target.name] = event.target.value
    this.setState({ cupom })
  }

  updateFieldAtivo(event) {
    const cupom = { ...this.state.cupom }
    cupom.cupom_ativo = !cupom.cupom_ativo
    this.setState({ cupom })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/cupom_desconto");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  validateForm() {
    const { cupom } = this.state

    if (!cupom.cupom_hash) return false
    if (!cupom.cupom_descricao) return false
    if (!cupom.cupom_tipo) return false
    if (!cupom.cupom_valor) return false
    if (!cupom.cupom_data_inicio) return false
    if (!cupom.cupom_data_fim) return false    

    return true
  }

  async salvar() {

    this.setState({
      loadingSalvar: true
    })

    if (!this.validateForm()) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        },
        loadingSalvar: false
      })
      return
    }

    this.setState({
      alerta: {
        open: true,
        severity: 'info',
        message: 'Cadastrando Cupom...'
      },
      loadingSalvar: true
    })

    const {cupom} = this.state

    const dados = {
      cupom_hash: cupom.cupom_hash,
      cupom_descricao: cupom.cupom_descricao,
      cupom_tipo: cupom.cupom_tipo,
      cupom_valor: parseFloat(cupom.cupom_valor),
      cupom_data_inicio: cupom.cupom_data_inicio,
      cupom_data_fim: cupom.cupom_data_fim,
      cupom_ativo: cupom.cupom_ativo
    }

    try {

      const method = cupom.cupom_id !== "" ? 'put' : 'post'
      const url = cupom.cupom_id !== "" ? `/cupomDesconto/${cupom.cupom_id}` : `/cupomDesconto`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        },
        loadingSalvar: false
      })

      this.backPage()

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false
      })
      return
    }
  }

  render() {
    const { cupom } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Cupom de Desconto</h2>
                </Grid>
              </Grid>
              <FormCupom 
                updateField={e => this.updateField(e)} 
                updateFieldAtivo={e => this.updateFieldAtivo(e)} 
                dados={cupom} 
              />
              <hr />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button 
                    fullWidth 
                    color="primary" 
                    variant="contained" 
                    className="btn_salvar" 
                    size="small" 
                    startIcon={<SaveIcon />} 
                    onClick={e => this.salvar(e)} 
                    disabled={this.state.loadingSalvar}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </div>
          }
          <Alert 
            open={this.state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={this.state.alerta.severity}
            message={this.state.alerta.message} 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}