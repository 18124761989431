import React, { Component } from 'react'
import './Estoque.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import FormEstoqueProduto from '../../components/forms/FormEstoqueProduto'

import { Grid, Button, TextField } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  estoque: {
    _id: "",
    idProduto: "",
    produto: "",
    idLocalEstoque: "",
    localEstoque: "",
    idUnidade: "",
    unidade: "",
    estoqueMinimo: "",
    estoqueMaximo: "",
    estoqueAtual: "",
    precoEstoqueAtual: "",
    grupoProduto: "",
    idGrupoProduto: "",
    subGrupoProduto: "",
    idSubGrupoProduto: ""
  },
  produto: {
    _id: "",
    descricao: "",
    ativo: true,
    grupoProduto: "",
    idGrupoProduto: "",
    subGrupoProduto: "",
    idSubGrupoProduto: "",
    unidadeMedida: "",
    visibilidade: "",
  },
  estoques: {
    list: []
  },
  grupoProdutos: {
    list: []
  },
  subGrupoProdutos: {
    list: []
  },
  subGrupoProdutosFiltrado: {
    list: []
  },
  produtosFiltrado: {
    list: []
  },
  localEstoques: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  unidades: {
    list: []
  },
  localEstoquesFiltrado: {
    list: []
  },
  loading: true,
  loadingSalvar: false
}

export default class CadastroEstoque extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const { match: { params } } = this.props;
    if (params.estoqueProdutoId) {
      try {
        const { data: estoqueProduto } = await axios.get(`${process.env.REACT_APP_API_URL}/estoqueProduto/${params.estoqueProdutoId}`, this.getToken())

        var estoqueProdutoAux = {
          _id: estoqueProduto.estoqprod_id,
          idProduto: estoqueProduto.estoqprod_produtoid,
          produto: estoqueProduto.produto.prod_descricao,
          idLocalEstoque: estoqueProduto.estoqprod_localestoqid,
          localEstoque: estoqueProduto.localestoque.localestoq_descricao,
          idUnidade: estoqueProduto.localestoque.unidade.unin_id,
          unidade: estoqueProduto.localestoque.unidade.unin_descricao,
          estoqueMinimo: estoqueProduto.estoqprod_estoqueminimo,
          estoqueMaximo: estoqueProduto.estoqprod_estoquemaximo,
          estoqueAtual: estoqueProduto.estoqprod_estoqueatual,
          precoEstoqueAtual: estoqueProduto.estoqprod_custoestoqueatual,
          grupoProduto: estoqueProduto.produto.subgrupoproduto.grupoproduto.grupprod_descricao,
          idGrupoProduto: estoqueProduto.produto.subgrupoproduto.grupoproduto.grupprod_id,
          subGrupoProduto: estoqueProduto.produto.subgrupoproduto.subgrupprod_descricao,
          idSubGrupoProduto: estoqueProduto.produto.subgrupoproduto.subgrupprod_id
        }

        this.setState({
          estoque: estoqueProdutoAux
        })
      } catch (error) {
        console.log(error);
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o produto no estoque, tente mais tarde"
        })

        this.backPage()
      }
    }

    if (params.produtoId) {
      try {
        const { data: produto } = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/${params.produtoId}`, this.getToken())

        this.setState({
          produto: {
            _id: produto.prod_id,
            descricao: produto.prod_descricao,
            ativo: produto.prod_ativo,
            grupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
            idGrupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_id,
            subGrupoProduto: produto.subgrupoproduto.subgrupprod_descricao,
            idSubGrupoProduto: produto.subgrupoproduto.subgrupprod_id,
            unidadeMedida: produto.prod_unidademedida,
            visibilidade: produto.prod_visibilidade,
            idMidia: produto.prod_idmidia
          }
        })
      } catch (error) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o produto, tente mais tarde"
        })

        this.backPage()
      }
    }

    // const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    // const dados = {
    //   unidadesnegocio: acesso_atual.map(acesso => {
    //     return acesso.unin_id
    //   })
    // }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/localEstoque/list`, {}, this.getToken())

      let localEstoques = []

      data.forEach(localEstoque => {
        localEstoques.push({
          _id: localEstoque.localestoq_id,
          descricao: localEstoque.localestoq_descricao,
          unidade: localEstoque.unidade.unin_descricao,
          idUnidade: localEstoque.unidade.unin_id,
          franqueado: localEstoque.unidade.superior.pessoa.pes_tipo === 'FISICA' ? localEstoque.unidade.superior.pessoa.fisica.pesf_nome : localEstoque.unidade.superior.pessoa.juridica.pesj_razaosocial,
        })
      })

      this.setState({
        localEstoques: {
          list: localEstoques
        }
      })
    } catch (error) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os locais de estoques, tente mais tarde"
      })

      this.backPage()
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())

      let unidades = []

      data.forEach(unidade => {
        const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.end_principal)

        unidades.push({
          _id: unidade.unin_id,
          numero: unidade.unin_numero,
          descricao: unidade.unin_descricao,
          franqueado: unidade.superior.pessoa.pes_tipo === "FISICA" ? unidade.superior.pessoa.fisica.pesf_nome : unidade.superior.pessoa.juridica.pesj_razaosocial,
          cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
          tipo: unidade.pessoa.pes_tipo,
          ativo: unidade.unin_status
        })
      });

      this.setState({
        unidades: {
          list: unidades
        }
      })
    } catch (error) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os locais de estoques, tente mais tarde"
      })

      this.backPage()
    }

    this.setState({
      loading: false
    })
  }

  updateField(event) {

    const estoque = { ...this.state.estoque }

    if (event.target.name === "idGrupoProduto") {
      const subGrupoProdutos = this.state.subGrupoProdutos.list.filter(u => u.idGrupoProduto === event.target.value)

      this.setState({
        subGrupoProdutosFiltrado: {
          list: subGrupoProdutos
        }
      })
    }

    if (event.target.name === "idSubGrupoProduto") {
      const produtos = this.state.produtos.list.filter(u => u.idSubGrupoProduto === event.target.value)

      this.setState({
        produtosFiltrado: {
          list: produtos
        }
      })
    }

    if (event.target.name === "idProduto") {
      const produto = this.state.produtos.list.filter(u => u._id === event.target.value)

      estoque.produto = produto[0].descricao
    }

    if (event.target.name === "idUnidade") {
      const localEstoques = this.state.localEstoques.list.filter(u => u.idUnidade === event.target.value)

      if (localEstoques.length > 0) {
        this.setState({
          localEstoquesFiltrado: {
            list: localEstoques
          }
        })

        estoque.unidade = localEstoques[0].unidade
      } else {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: "Essa Unidade ainda não possui Local de Estoque Cadastrado"
        })
        return
      }

    }

    if (event.target.name === "idLocalEstoque") {
      const localEstoque = this.state.localEstoquesFiltrado.list.filter(u => u._id === event.target.value)

      estoque.localEstoque = localEstoque[0].descricao
    }

    estoque[event.target.name] = event.target.value
    this.setState({ estoque })
  }

  validateForm() {
    const { estoque, produto } = this.state

    if (!produto._id) return false
    if (!estoque.idLocalEstoque) return false
    if (!estoque.estoqueMinimo) return false
    if (!estoque.estoqueMaximo) return false

    return true
  }

  async salvar() {
    const { estoque, produto } = this.state

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const data = {
      estoqprod_produtoid: produto._id,
      estoqprod_localestoqid: estoque.idLocalEstoque,
      estoqprod_estoqueminimo: estoque.estoqueMinimo,
      estoqprod_estoquemaximo: estoque.estoqueMaximo
    }

    try {
      if (estoque._id !== "") {
        await axios.put(`${process.env.REACT_APP_API_URL}/estoqueProduto/${estoque._id}`, data, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/estoqueProduto`, data, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()
    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/produtos");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { estoque, produto } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }{!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11}>
                  <h2 className="titulo">Cadastro de Estoque</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Produto" value={produto.descricao} disabled />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Sub Grupo Produto" value={produto.subGrupoProduto} disabled />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Grupo Produto" value={produto.grupoProduto} disabled />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={6} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Unidade"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="idUnidade"
                    value={estoque.idUnidade}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <option value={estoque.idUnidade === "" ? "" : estoque.idUnidade}> {estoque.idUnidade === "" ? "Selecionar Unidade" : estoque.unidade} </option>
                    {this.state.unidades.list.map(unidade => {
                      return (
                        <option key={unidade._id} value={unidade._id}> {unidade.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Local de Estoque"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="idLocalEstoque"
                    value={estoque.idLocalEstoque}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <option value={estoque.idLocalEstoque === "" ? "" : estoque.idLocalEstoque}> {estoque.idLocalEstoque === "" ? "Selecionar Local de Estoque" : estoque.localEstoque} </option>
                    {this.state.localEstoquesFiltrado.list.map(localEstoque => {
                      return (
                        <option key={localEstoque._id} value={localEstoque._id}> {localEstoque.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <FormEstoqueProduto updateField={e => this.updateField(e)} dados={estoque} />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}