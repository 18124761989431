import React from 'react'
import { Grid, TextField, Button, Radio } from '@material-ui/core'
import real from './../../../services/real'
import moment from 'moment'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const calcularValorTotal = (cuponsSelecionados, tabela_selecionada) => {
  let valor_total = tabela_selecionada.tabval_val_mensal * tabela_selecionada.tabval_num_parc
  
  for (const cupom of cuponsSelecionados) {
    if(cupom.cupom_tipo === "PORCENTAGEM"){
      valor_total = valor_total - ((cupom.cupom_valor / 100) *  valor_total)
    }else{
      valor_total = valor_total - cupom.cupom_valor
    }
  }

  return valor_total
}

const calcularValorPrimeiraParcela = (cuponsSelecionados, tabela_selecionada) => {
  let valor_parcela = tabela_selecionada.tabval_val_mensal
  
  for (const cupom of cuponsSelecionados) {
    if(cupom.cupom_tipo === "PORCENTAGEM"){
      valor_parcela = valor_parcela - ((cupom.cupom_valor / 100) *  valor_parcela)
    }else{
      valor_parcela = valor_parcela - (cupom.cupom_valor / tabela_selecionada.tabval_num_parc)
    }
  }

  let valor_total = calcularValorTotal(cuponsSelecionados, tabela_selecionada)
  let diferenca = (parseFloat((valor_parcela).toFixed(2)) * tabela_selecionada.tabval_num_parc) - parseFloat((valor_total).toFixed(2))

  return real(valor_parcela - parseFloat((diferenca).toFixed(2)))
}

const calcularValorParcela = (cuponsSelecionados, tabela_selecionada) => {
  let valor_total = tabela_selecionada.tabval_val_mensal
  
  for (const cupom of cuponsSelecionados) {
    if(cupom.cupom_tipo === "PORCENTAGEM"){
      valor_total = valor_total - ((cupom.cupom_valor / 100) *  valor_total)
    }else{
      valor_total = valor_total - (cupom.cupom_valor / tabela_selecionada.tabval_num_parc)
    }
  }

  return real(valor_total)
}

const calcularDesconto = (cuponsSelecionados, tabela_selecionada) => {
  let valor_total = tabela_selecionada.tabval_val_mensal * tabela_selecionada.tabval_num_parc
  let desconto = 0

  for (const cupom of cuponsSelecionados) {
    if(cupom.cupom_tipo === "PORCENTAGEM"){
      desconto += (cupom.cupom_valor / 100) *  valor_total
    }else{
      desconto += cupom.cupom_valor
    }
  }

  return desconto
}

const FormCurso = ({ aluno, proximo, handleChangeRadioFormaPagto, planosPagamentos, handleChangeRadio, aplicarCupom, 
  tabela_selecionada, voltar, updateField, cupom, updateFieldCupom, travaBotaoCupom, cuponsSelecionados, cursos }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={2}>
      <Grid item md={1} xs={false} sm={1}></Grid>
      <Grid item md={7} xs={12} sm={7}>
        <Grid container direction="row" spacing={2}>
          <Grid item md={12} xs={12} sm={12}>
          <div className='card_dados'>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <h5>Curso</h5>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={8} xs={12} sm={8}>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={4} xs={12} sm={4}>
                    <TextField className="input" label="CPF" variant="outlined" size="small" name="cpf" value={aluno.cpf} InputLabelProps={{ shrink: true }} disabled={true}/>
                  </Grid>
                  <Grid item md={8} xs={12} sm={8}>
                    <TextField className="input" label="Nome Aluno" variant="outlined" size="small" name="nome" value={aluno.nome} InputLabelProps={{ shrink: true }} disabled={true}/>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField className="input" label="Curso" variant="outlined" size="small" name="curso" value={aluno.curso} InputLabelProps={{ shrink: true }} disabled={true}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <img src="https://system-british-no-delete.s3.amazonaws.com/logo_2.png" alt="logo" style={{width: '80%', marginLeft: '10%', marginTop: '35px'}}/>
              </Grid>
            </Grid>
          </div>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <div className='card_dados'>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Escolha um plano de pagamento</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                {(planosPagamentos.length === 0 && aluno.curso_id !== '') &&
                  <Grid item md={12} xs={12} sm={12}>
                    <span>Nenhuma plano de pagamento foi encontrato para o curso selecionado.</span>
                  </Grid>
                }
                {(planosPagamentos.length === 0 && aluno.curso_id === '') &&
                  <Grid item md={12} xs={12} sm={12}>
                    <span>Por favor selecionar o curso.</span>
                  </Grid>
                }
                {planosPagamentos.length !== 0 &&
                  <React.Fragment>
                    <Grid item md={6} xs={12} sm={6}>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          {planosPagamentos.map(planoPagamento => {
                            return(
                              <React.Fragment>
                                {planoPagamento.tabelas_valores.filter(param => param.tabval_ativo).map(value => {
                                  return (
                                    <div>
                                      <Radio
                                        checked={parseInt(aluno.tabval_id) === parseInt(value.tabval_id)}
                                        color='primary'
                                        onChange={(e) => handleChangeRadio(e)}
                                        value={value.tabval_id}
                                        name="radio-button-demo"
                                      />
                                      <span>{value.tabval_nome} - R$ {real(value.tabval_val_mensal)}</span>
                                    </div>
                                  )
                                })}
                              </React.Fragment>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} sm={6} style={{borderLeft: '1px solid #C0C0C0'}}>
                      <Grid container direction="row" spacing={1} className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          {/* <div>
                            <Radio
                              checked={parseInt(aluno.formpag_id) === 2}
                              color='primary'
                              onChange={(e) => handleChangeRadioFormaPagto(e)}
                              value={2}
                              name="radio-button-demo"
                            />
                            <span>Cartão de Crédito</span>
                          </div> */}
                          <div>
                            <Radio
                              checked={parseInt(aluno.formpag_id) === 3}
                              color='primary'
                              onChange={(e) => handleChangeRadioFormaPagto(e)}
                              value={3}
                              name="radio-button-demo"
                            />
                            <span>Boleto</span>
                          </div>
                          <div>
                            <Radio
                              checked={parseInt(aluno.formpag_id) === 10}
                              color='primary'
                              onChange={(e) => handleChangeRadioFormaPagto(e)}
                              value={10}
                              name="radio-button-demo"
                            />
                            <span>Pix</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <div className='card_dados'>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Vencimento 1ª Mensalidade</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField 
                    className="input"
                    label="1ª Mensalidade" 
                    variant="outlined" 
                    size="small" 
                    name="vencimento_mensalidade" 
                    value={moment(aluno.vencimento_mensalidade).format('DD/MM/YYYY')} 
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <div className='card_dados'>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Possui um cupom de desconto?</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={9} xs={12} sm={8}>
                  <TextField className="input" label="Cupom" variant="outlined" size="small" name="cupom" value={cupom} InputLabelProps={{ shrink: true }} onChange={(e) => updateFieldCupom(e)} disabled={cuponsSelecionados.length > 0}/>
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <Button 
                    fullWidth 
                    color="primary" 
                    variant="contained" 
                    className="btn_salvar" 
                    size="small"
                    onClick={e => aplicarCupom()}
                    disabled={cuponsSelecionados.length > 0 ? true : travaBotaoCupom}
                  >
                    Aplicar Cupom
                  </Button>
                </Grid>
              </Grid>
              {cuponsSelecionados.length > 0 &&
                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12} sm={12}>
                    <h5>Cupons aplicados:</h5>
                  </Grid>
                </Grid>
              }
              {cuponsSelecionados.map(value => {
                return (
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <p style={{margin: 0}}><ArrowForwardIcon style={{marginTop: '-5px'}}/>{value.cupom_hash} - {value.cupom_descricao}</p> 
                    </Grid>
                  </Grid>
                )
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12} sm={3}>
        <div className='card_dados'>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h5>Resumo</h5>
            </Grid>
          </Grid>
          {aluno.tabval_id !== '' &&
            <React.Fragment>
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                  <p style={{margin: 0, marginLeft: 5}}>Valor</p>
                </Grid>
                <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                  <p style={{margin: 0, marginRight: 5, textAlign: 'right'}}>
                    R$ {real(tabela_selecionada.tabval_val_mensal * tabela_selecionada.tabval_num_parc)}
                  </p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                  <p style={{margin: 0, marginLeft: 5}}>1ª Parcela</p>
                </Grid>
                <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                  <p style={{margin: 0, marginRight: 5, textAlign: 'right'}}>
                    1 x R$ {calcularValorPrimeiraParcela(cuponsSelecionados, tabela_selecionada)}
                  </p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                  <p style={{margin: 0, marginLeft: 5}}>Venc. 1ª Parcela</p>
                </Grid>
                <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                  <p style={{margin: 0, marginRight: 5, textAlign: 'right'}}>
                    {aluno.vencimento_mensalidade !== '' ? moment(aluno.vencimento_mensalidade).format('DD/MM/YYYY') : ''}
                  </p>
                </Grid>
              </Grid>
              {tabela_selecionada.tabval_num_parc > 1 &&
                <Grid container direction="row" spacing={1}>
                  <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                    <p style={{margin: 0, marginLeft: 5}}>Parcelas</p>
                  </Grid>
                  <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                    <p style={{margin: 0, marginRight: 5, textAlign: 'right'}}>
                      {tabela_selecionada.tabval_num_parc - 1} x R$ {calcularValorParcela(cuponsSelecionados, tabela_selecionada)}
                    </p>
                  </Grid>
                </Grid>
              } 
              {calcularDesconto(cuponsSelecionados, tabela_selecionada) > 0 &&
                <Grid container direction="row" spacing={1}>
                  <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                    <p style={{margin: 0, marginLeft: 5}}>Desconto</p>
                  </Grid>
                  <Grid item md={6} xs={6} sm={6} style={{borderBottom: '1px solid #D0D0D0'}}>
                    <p style={{margin: 0, marginRight: 5, textAlign: 'right'}}>
                      R$ {real(calcularDesconto(cuponsSelecionados, tabela_selecionada))}
                    </p>
                  </Grid>
                </Grid>
              }
              <Grid container direction="row" spacing={1} className="mg_top_30">
                <Grid item md={2} xs={12} sm={2}></Grid>
                <Grid item md={8} xs={12} sm={8}>
                  <div className='valor_total'>
                    <p style={{margin: 0, marginRight: 5, textAlign: 'center'}}>
                      Valor <b>Final:</b>
                    </p>
                    <p style={{margin: 0, marginRight: 5, textAlign: 'center', fontSize: 24}}>
                      <b>R$ {real(calcularValorTotal(cuponsSelecionados, tabela_selecionada))}</b>
                    </p>
                  </div>
                </Grid>
                <Grid item md={2} xs={12} sm={2}></Grid>
              </Grid>
            </React.Fragment>
          }
        </div>
      </Grid>
      <Grid item md={1} xs={false} sm={1}></Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20" style={{marginBottom:20}}>
      <Grid item md={1} xs={false} sm={1}></Grid>
      <Grid item md={3} xs={12} sm={4}>
        <Button 
          fullWidth 
          color="secondary" 
          variant="contained" 
          className="btn_salvar" 
          size="small" 
          onClick={e => voltar()}
        >
          Voltar
        </Button>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <Button 
          fullWidth 
          color="primary" 
          variant="contained" 
          className="btn_salvar" 
          size="small" 
          onClick={e => proximo()}
        >
          Proximo
        </Button>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormCurso