import React, { Component } from 'react'
import './WhatsApp.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@material-ui/core'
import FormWhatsApp from '../../components/forms/FormWhatsApp'
import SaveIcon from '@material-ui/icons/Save';
import Footer from '../../components/templates/Footer'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import ModalConfirmarSenha from './../../components/modals/ConfirmarSenha'

const initalState = {
  whatsapp: {
    id: "",
    numero: "",
    nome: "",
    api_key: "U2FsdGVkX1-1-DofvhWSZIJtUBsGq7NOgr9tL9UiZrOFTOcvZ+HrcjmwUQYlPe47",
    instancia_id: "",
    instancia_token: "",
    conectado: false,
    ativo: true,
    qrCode: ''
  },
  loadingSalvar: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading:true,
  update: false,
  unidades: {
    list: []
  },
  tipo: 'UNIDADE',
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  openModalSenha: false,
  login: {
    login: '',
    senha: ''
  },
  showPassword: false
}

function formataTelefone(numero) {

  if(numero){
    let tel=numero.replace(/\D/g,"")
    tel=tel.replace(/^(\d)/,"+$1")
    tel=tel.replace(/(.{3})(\d)/,"$1($2")
    tel=tel.replace(/(.{6})(\d)/,"$1)$2")
    if(tel.length === 12) {
			tel=tel.replace(/(.{1})$/,"-$1")
    } else if (tel.length === 13) {
			tel=tel.replace(/(.{2})$/,"-$1")
    } else if (tel.length === 14) {
			tel=tel.replace(/(.{3})$/,"-$1")
    } else if (tel.length === 15) {
			tel=tel.replace(/(.{4})$/,"-$1")
    } else if (tel.length > 15) {
			tel=tel.replace(/(.{4})$/,"-$1")
    }
    return tel;
  }

  return ''
}

let watchID = ''

export default class CadastroNotificacao extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    this.setState({
      whatsapp: {
        id: "",
        numero: "",
        api_key: "U2FsdGVkX1-1-DofvhWSZIJtUBsGq7NOgr9tL9UiZrOFTOcvZ+HrcjmwUQYlPe47",
        instancia_id: "",
        instancia_token: "",
        conectado: false,
        ativo: true,
        qrCode: ''
      },
      unidadesSelecionadas: []
    })

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'central-alerta')[0]

    this.setState({
      permissoes
    })

    if (params.centralAlertaId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Erro!",
          descricao: 'Você não tem permissão para acessa essa tela!'
        })
        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Erro!",
          descricao: 'Você não tem permissão para acessa essa tela!'
        })
        this.backPage()
      }
    }  

    try {
      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupoAll`, {}, this.getToken())

      this.setState({
        unidades:{
          list: unidades.filter(param => (param.unin_tipo === 'UNIDADE' || param.unin_tipo === 'FRANQUEADOR')).filter(param => param.unin_status).map(unidade => {
            return {
              id: unidade.unin_id,
              numero: unidade.unin_numero,
              descricao: unidade.unin_descricao
            }
          })
        }
      })
    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: error.response ? error.response.data.message : 'Erro Interno'
      })

      this.backPage()
    }

    if (params.numeroWhatsId) {
      try {

        const { data: numero_whats } = await axios.get(`${process.env.REACT_APP_API_URL}/whatsApp/getInstancia/${params.numeroWhatsId}`, this.getToken())

        this.setState({
          whatsapp:{
            id: numero_whats.numwhats_id,
            nome: numero_whats.numwhats_nome,
            numero: formataTelefone(numero_whats.numwhats_numero),
            api_key: numero_whats.numwhats_api_key,
            instancia_id: numero_whats.numwhats_instancia_id,
            instancia_token: numero_whats.numwhats_instancia_token,
            conectado: numero_whats.numwhats_conectado,
            ativo: numero_whats.numwhats_ativo
          },
          unidadesSelecionadas:numero_whats.unidades.map(value => {
            return {
              id: value.unidade.unin_id,
              descricao: value.unidade.unin_descricao,
              numero: value.unidade.unin_numero
            }
          })
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Erro!",
          descricao: error.response ? error.response.data.message : 'Erro Interno'
        })

        this.backPage()
      }
    }

    await this.consultaQrcode();

    watchID = setInterval(async () => {
      await this.consultaQrcode();
    }, 1000 * 5);

    this.setState({
      loading: false
    })
  }

  componentWillUnmount() {
    clearInterval(watchID);
  }

  async consultaQrcode(){
    try {
      let {whatsapp} = this.state

      if(whatsapp.id !== ''){
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/whatsApp/getQrcode/${whatsapp.api_key}/${whatsapp.instancia_id}/${whatsapp.instancia_token}`, this.getToken())
      
        if(data.connected){
          whatsapp.conectado = true
          whatsapp.qrCode = ''
        }else{
          whatsapp.qrCode = data.value
          whatsapp.conectado = false
        }
    
        this.setState({
          whatsapp
        })
      }

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: error.response ? error.response.data.message : 'Erro Interno'
      })

      this.backPage()
    }
  }

  backPage(timeout = 2000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/api_whatsapp");
    }, timeout)
    this.setState({
      whatsapp: initalState.whatsapp
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  formataTelefoneWhats(event) {
    if(event.target.value !== ''){
      let texto = event.target.value.trim()
  
      texto = texto.replace(/\D/g,""); 
      
      if(texto.length === 11 || texto.length === 10){
        texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else if(texto.length === 12){
        texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else if(texto.length === 8){
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else{
        this.handleOpenDialog({
          titulo: "Erro!",
          descricao: 'Telefone Invalido'
        })

        const whatsapp = { ...this.state.whatsapp }
        whatsapp.numero = ''
        this.setState({ whatsapp })

        return false

      }
    
      const whatsapp = { ...this.state.whatsapp }
      whatsapp.numero = texto
      this.setState({ whatsapp })
    }
  }

  updateFieldUnidade(event, value) {
    this.setState({
      unidadesSelecionadas: value
    })
  }

  validaForm(){
    const {whatsapp} = this.state

    if(whatsapp.nome === '') return false
    if(whatsapp.numero === '') return false
    if(whatsapp.api_key === '') return false

    return true
  }

  handleCloseConfirmarSenha() {
    this.setState({
      openModalSenha: false
    })
  }

  async salvar(){
    if(!this.validaForm()){
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: 'Preencha os campos obrigatorios (*).'
      })
      return
    }

    this.setState({
      openModalSenha: true
    })
    
  }

  async continuarSalvar(value){
    console.log(value)
    if(value){
      let valido = true

      try {
        const login = {
          login: JSON.parse(localStorage.getItem('usu_login')),
          senha: this.state.login.senha
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/login`, login)

        valido = true

      } catch (error) {
        valido = false
        this.setState({
          loadingSalvar: false
        })
        this.handleOpenDialog({
          titulo: "Senha Invalida!",
          descricao: "Por favor informar a senha corretamente!"
        })
        return
      }

      if(valido){
        this.setState({ loadingSalvar: true })
        const {whatsapp, unidadesSelecionadas} = this.state

        if(unidadesSelecionadas.length > 0){
          whatsapp.unidades = unidadesSelecionadas.map(unidade => { return {unidade_id: unidade.id}})
        }

        try {
          if(whatsapp.id !== ''){
            await axios.put(`${process.env.REACT_APP_API_URL}/whatsApp/${whatsapp.id}`, whatsapp, this.getToken())
          }else{
            await axios.post(`${process.env.REACT_APP_API_URL}/whatsApp`, whatsapp, this.getToken())
          }

          this.handleOpenDialog({
            titulo: "Parabéns",
            descricao: 'Cadastro realizado com sucesso.'
          })

          this.backPage()
          this.setState({ loadingSalvar: false })
        } catch (error) {
          console.log(error)
          this.handleOpenDialog({
            titulo: "Erro!",
            descricao: error.response ? error.response.data.message : 'Erro Interno'
          })
          this.setState({ loadingSalvar: false })
        }
      }else{
        this.handleOpenDialog({
          titulo: "Senha Invalida!",
          descricao: "Por favor informar a senha corretamente!"
        })
        return
      }
    }else{
      this.setState({
        loadingSalvar: false
      })
      this.setState({
        openModalSenha: false
      })
    }
  }

  updateFieldAtivo(){
    const {whatsapp} = this.state
    whatsapp.ativo = !whatsapp.ativo
    this.setState({whatsapp})
  }

  updateField(event){
    const {whatsapp} = this.state

    let name = event.target.name
    let value = event.target.value

    whatsapp[name] = value

    this.setState({whatsapp})
  }

  updateData(event) {
    const { login } = this.state

    login[event.target.name] = event.target.value
    this.setState({ login })
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    const { whatsapp, unidades, unidadesSelecionadas, loadingSalvar } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Dados da Tela...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom" >
                <Grid item md={11}>
                  <h1 className="titulo">Cadastro de Número do WhatsApp</h1>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormWhatsApp 
                dados={whatsapp}
                updateField={e => this.updateField(e)}
                updateFieldAtivo={e => this.updateFieldAtivo()}
                unidades={unidades.list}
                unidadesSelecionadas={unidadesSelecionadas}
                updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
                formataTelefone={e => this.formataTelefoneWhats(e)}
              />
              <hr />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={10} xs={12} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button 
                    fullWidth 
                    color="primary" 
                    variant="contained"  
                    size="small" 
                    startIcon={<SaveIcon />} 
                    disabled={this.state.loadingSalvar}
                    onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalConfirmarSenha
            open={this.state.openModalSenha}
            handleClose={e => this.handleCloseConfirmarSenha(e)}
            dados={this.state.login}
            updateData={e => this.updateData(e)}
            confirmar={e => this.continuarSalvar(e)}
            disabled={loadingSalvar}
            showPassword={this.state.showPassword}
            handleClickShowPassword={e => this.handleClickShowPassword(e)}
            mensagem={'Ao confirmar você estará consciente dos custos da assinatura'}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}