import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Tranferir Código</h5>
            </Grid>
          </Grid>
					<Grid container direction="row" spacing={1} className='mg_top_10'>
            <Grid item md={12} xs={12} sm={12}>
              <p>Informar o aluno que o código será transferido</p>
            </Grid>
          </Grid>
					<Grid container direction="row" spacing={1}> 
						<Grid item md={12} xs={12} sm={12}>
							<TextField
								id="standard-select-currency"
								select
								label="Busca por*"
								variant="outlined"
								className="input"
								fullWidth
								size="small"
								SelectProps={{
									native: true,
								}}
								name="buscaPor"
								value={props.buscaPor}
								onChangeCapture={(e) => props.updateField(e)}
								InputLabelProps={{ shrink: true }}
							>
								<option value="cpf">
									CPF
								</option>
								<option value="matricula">
									Subscrição
								</option>
							</TextField>
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={1} style={{ 'marginTop': '10px' }}> 
						<Grid item md={12} xs={12} sm={12}>
							{props.buscaPor === 'cpf' &&
								<TextField className="input" label="CPF*" variant="outlined" size="small" name="search" value={props.search} onChange={(e) => props.updateField(e)} InputLabelProps={{ shrink: true }} />
							}
							{props.buscaPor === 'matricula' &&
								<TextField className="input" label="Subscrição*" variant="outlined" size="small" name="search" value={props.search} onChange={(e) => props.updateField(e)} InputLabelProps={{ shrink: true }} />
							}
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={1} style={{ 'marginTop': '10px' }}> 
						<Grid item md={6} xs={false} sm={false}></Grid>
						<Grid item md={6} xs={12} sm={12}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={e => props.buscaAluno(e)}
							>
								Buscar
							</Button>
						</Grid>
					</Grid>
					{props.alunoTransferenciaCodigo &&
						<React.Fragment>
							<Grid container direction="row" spacing={1} className='mg_top_10'>
								<Grid item md={12} xs={12} sm={12}>
									<p style={{margin: '0px'}}>Dados do Aluno:</p>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1}>
								<Grid item md={12} xs={12} sm={12}>
									<p style={{margin: '0px'}}>Aluno: <b>{props.alunoTransferenciaCodigo.fisica.pesf_nome}</b></p>
								</Grid>
								<Grid item md={12} xs={12} sm={12}>
									<p style={{margin: '0px'}}>Subscrição: <b>{props.alunoTransferenciaCodigo.aluno_matricula}</b></p>
								</Grid>
								<Grid item md={12} xs={12} sm={12}>
									<p style={{margin: '0px'}}>CPF: <b>{props.alunoTransferenciaCodigo.fisica.pesf_cpf}</b></p>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1} className='mg_top_10'>
								<Grid item md={12} xs={12} sm={12}>
									<p>Confirma a transferência ?</p>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1}>
								<Grid item md={6} xs={6} sm={6}>
									<Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.loadingSalvarTransf}>Sim</Button>
								</Grid>
								<Grid item md={6} xs={6} sm={6}>
									<Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
								</Grid>
							</Grid>
						</React.Fragment>
					}
        </div>
      </Modal>
    </div>
  );
}
