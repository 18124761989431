import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import moment from 'moment'
import SaveIcon from '@material-ui/icons/Save';
import FormProfessorDispHorario from './../../../components/forms/FormProfessorDispHorario'

import ModalErro from './../../../components/modals/Erro'

const initalState = {
  professor: {
    prof_formacao: "",
    disponibilidades: [],
    aulas: []
  },
  disponibilidade: {
    disp_id: '',
    disp_dia_semana: "",
    disp_hora_inicial: "",
    disp_hora_final: "",
    disp_data_vig_inicio: "",
    disp_data_vig_fim: "",
    index: ""
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}

function removeItemAll(arr, value, campo) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i][campo] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const funcionario = this.props.funcionario

    try {
      const { data: respProfessor } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/${funcionario.professor.prof_id}`, this.getToken())

      const disponibilidades = []
      for (let i = 0; i < respProfessor.disponibilidades.length; i++) {
        disponibilidades.push({
          disp_id: respProfessor.disponibilidades[i].disp_id,
          disp_dia_semana: respProfessor.disponibilidades[i].disp_dia_semana,
          disp_hora_inicial: respProfessor.disponibilidades[i].disp_hora_inicial,
          disp_hora_final: respProfessor.disponibilidades[i].disp_hora_final,
          disp_data_vig_inicio: respProfessor.disponibilidades[i].disp_data_vig_inicio ? moment(respProfessor.disponibilidades[i].disp_data_vig_inicio).format('YYYY-MM-DD') : "",
          disp_data_vig_fim: respProfessor.disponibilidades[i].disp_data_vig_fim ? moment(respProfessor.disponibilidades[i].disp_data_vig_fim).format('YYYY-MM-DD') : "",
          index: i + 1
        })
      }

      let professor = {
        prof_id: respProfessor.prof_id,
        prof_formacao: respProfessor.prof_formacao,
        disponibilidades,
      }
      
      this.setState({
        professor
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Books, tente mais tarde"
      })

      this.backPage()
    }

  }

  updateFieldDisponibilidade(hora, diaSemana) {
    const { professor } = this.state
    let disponibilidades = professor.disponibilidades

    const index = disponibilidades.findIndex(disp => disp.disp_dia_semana === diaSemana && disp.disp_hora_inicial === hora)

    if (index === -1) {
      const horaQuebrada = hora.split(":")
      const horaFinal = parseInt(horaQuebrada[0]) + 1
      const disp_hora_final = horaFinal < 10 ? `0${horaFinal}:00` : `${horaFinal}:00`

      disponibilidades.push({
        disp_id: "",
        disp_dia_semana: diaSemana,
        disp_hora_inicial: hora,
        disp_hora_final,
        disp_data_vig_inicio: "",
        disp_data_vig_fim: "",
        index: disponibilidades.length + 1
      })
    } else {
      disponibilidades.splice(index, 1)

      let aux = []

      for (let i = 0; i < disponibilidades.length; i++) {
        aux.push({
          disp_id: disponibilidades[i].disp_id,
          disp_dia_semana: disponibilidades[i].disp_dia_semana,
          disp_hora_inicial: disponibilidades[i].disp_hora_inicial,
          disp_hora_final: disponibilidades[i].disp_hora_final,
          disp_data_vig_inicio: disponibilidades[i].disp_data_vig_inicio,
          disp_data_vig_fim: disponibilidades[i].disp_data_vig_fim,
          index: i + 1
        })
      }

      disponibilidades = aux
    }

    professor.disponibilidades = disponibilidades

    this.setState({ professor })
  }

  updateAllDia(diaSemana) {
    const { professor } = this.state

    let disponibilidades = professor.disponibilidades

    const lengthDispo = disponibilidades.filter(disp => disp.disp_dia_semana === diaSemana).length

    const disponibilidadesAux = removeItemAll(disponibilidades, diaSemana, 'disp_dia_semana')

    if (lengthDispo < 7) {
      for (let i = 8; i < 22; i++) {
        const hora = i < 10 ? `0${i}:00` : `${i}:00`
        const horaFinal = i + 1 < 10 ? `0${i + 1}:00` : `${i + 1}:00`
        disponibilidades.push({
          disp_id: '',
          disp_dia_semana: diaSemana,
          disp_hora_inicial: hora,
          disp_hora_final: horaFinal,
          disp_data_vig_inicio: "",
          disp_data_vig_fim: "",
        })
      }
    } else {
      disponibilidades = disponibilidadesAux

      let aux = []

      for (let i = 0; i < disponibilidades.length; i++) {
        aux.push({
          disp_id: disponibilidades[i].disp_id,
          disp_dia_semana: disponibilidades[i].disp_dia_semana,
          disp_hora_inicial: disponibilidades[i].disp_hora_inicial,
          disp_hora_final: disponibilidades[i].disp_hora_final,
          disp_data_vig_inicio: disponibilidades[i].disp_data_vig_inicio,
          disp_data_vig_fim: disponibilidades[i].disp_data_vig_fim,
          index: i + 1
        })
      }

      disponibilidades = aux
    }

    professor.disponibilidades = disponibilidades

    this.setState({ professor })
  }

  async salvar(){
    const {professor} = this.state
    const funcionario = this.props.funcionario

    try {

      for (let i = 0; i < professor.disponibilidades.length; i++) {
        if (professor.disponibilidades[i].disp_data_vig_inicio === "" || !professor.disponibilidades[i].disp_data_vig_inicio) {
          delete professor.disponibilidades[i].disp_data_vig_inicio
        }
        if (professor.disponibilidades[i].disp_data_vig_fim === "" || !professor.disponibilidades[i].disp_data_vig_fim) {
          delete professor.disponibilidades[i].disp_data_vig_fim
        }

        if (professor.disponibilidades[i].disp_id === "" || !professor.disponibilidades[i].disp_id) {
          delete professor.disponibilidades[i].disp_id
        }
      }

      const dados = {
        prof_id: professor.prof_id,
        prof_funcionario_id: funcionario.fun_id,
        disponibilidades: professor.disponibilidades
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/professores/dispHorarios`, dados, this.getToken())

      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        }
      })

      window.location.reload()
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro no Cadastro!",
          descricao: (response.data && response.data.message) ? response.data.message : "Tente mais tarde."
        }
      })
      this.setState({ loadingSalvar: false })
    }
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const {disponibilidade, professor } = this.state
    const funcionario = this.props.funcionario
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Observações...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div style={{ 'marginTop': '10px' }}>
            {!funcionario.fun_status && 
              <h4>Este colaborador não se encontra mais ativo!</h4>
            }
            {funcionario.fun_status && 
              <React.Fragment>
                <FormProfessorDispHorario
                  dados={professor}
                  disponibilidade={disponibilidade}
                  disponibilidades={professor.disponibilidades}
                  updateFieldDisponibilidade={(hora, diaSemana) => this.updateFieldDisponibilidade(hora, diaSemana)}
                  updateAllDia={(diaSemana) => this.updateAllDia(diaSemana)}
                />
                <br />
                <hr />
                <br />
                <Grid container direction="row" spacing={1}>
                  <Grid item md={9} xs={false} sm={8}></Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          </div>
        }

      </React.Fragment>
    )
  }
}