import React, { Component } from 'react'
import './Documento.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment'

const initalState = {
  documento: {

  },
  documentos: {
    list: []
  },
  documentosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'tempdoc_titulo', numeric: false, disablePadding: true, label: 'Titulo Documento' },
    { id: 'tempver_versao', numeric: false, disablePadding: true, label: 'Versão' },
    { id: 'tempdoc_tipo', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'tempver_publicado', numeric: false, disablePadding: true, label: 'Publicado' },
    { id: 'tempver_datainicio', numeric: false, disablePadding: true, label: 'Data de Inicio', orderBy: 'datainicio_order' },
    { id: 'tempdoc_assinar', numeric: false, disablePadding: true, label: 'Assinar?' },
    { id: 'tempver_ativo', numeric: false, disablePadding: true, label: 'Ativo' }
  ],
  acoesTabela: ['update'],
  unin_tipo: "",
  filtro: {
    departamento: "",
    descricao: ""
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Cargo extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/templatesdocumentos`, this.getToken())

      const documentos = data.sort((a, b) => (a.tempver_id > b.tempver_id) ? -1 : ((b.tempver_id > a.tempver_id) ? 1 : 0)).map(value => {
        return {
          _id: value.tempdoc_id,
          tempdoc_titulo: value.tempdoc_titulo,
          tempdoc_tipo: value.tempdoc_tipo,
          tempdoc_assinar: value.tempdoc_assinar,
          tempver_ativo: value.versoes[0].tempver_ativo,
          tempver_publicado: value.versoes[0].tempver_publicado,
          tempver_versao: value.versoes[0].tempver_versao,
          tempver_html: value.versoes[0].tempver_html,
          tempver_datainicio: moment(value.versoes[0].tempver_datainicio).format('DD/MM/YYYY'),
          datainicio_order: moment(value.versoes[0].tempver_datainicio).format('YYYYMMDD')
        }
      })

      this.setState({
        documentos: {
          list: documentos
        },
        documentosFiltrado: {
          list: documentos
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const cargos = this.state.cargos.list
    const filtro = this.state.filtro

    const list = cargos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      documentosFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { documentosFiltrado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={8} xs={12}>
              <h1 className="titulo">Documentos</h1>
            </Grid>
            <Grid item md={4} xs={12}>
              <Link to="/documento/cadastro">
                <Button className="btnCadastrar" variant="contained" color="primary">
                  Cadastrar Documento
                  </Button>
              </Link>
            </Grid>
          </Grid>
          {/* <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField className="input" label="Cargo" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <TextField className="input" label="Departamento" variant="outlined" size="small" name="departamento" value={filtro.departamento} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
            </Grid>
          </Grid> */}
          <Grid
            container
            spacing={1}
            direction="row"
          >
            <Grid item md={12} xs={12}>
              <Table 
              urlUpdate="/documento/cadastro/" 
              headCell={this.state.cabecalhoTabela} 
              rows={documentosFiltrado.list} 
              acoes={this.state.acoesTabela}/>
            </Grid>
          </Grid>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}