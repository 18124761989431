import React, { Component } from 'react'
import './CupomDesconto.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, TextField, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Alert from './../../components/templates/Alert'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'
import moment from 'moment'
import ModalAntesDepois from './../../components/modals/AntesDepois'
import real from './../../services/real'

const initalState = {
  cupom: {
    cupom_id: '',
    cupom_hash: '',
    cupom_descricao: '',
    cupom_tipo: '',
    cupom_valor: '',
    cupom_data_inicio: '',
    cupom_data_fim: '',
    cupom_ativo: true
  },
  alteracao_selecionada: {
    hiscup_id: '',
    hiscup_alterado_em: '',
    usuario: '',
    hiscup_antes: '',
    hiscup_depois: ''
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'hiscup_alterado_em', numeric: false, disablePadding: true, label: 'Data da Alteração' },
    { id: 'usuario', numeric: false, disablePadding: false, label: 'Alterado Por' }
  ],
  acoesTabela: ['viewAntesDepois'],
  openModalAntesDepois: false,
  update: false
}

export default class ViewCupom extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cupom-desconto')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_visualizar) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message: 'Você não tem permissão para acessa essa tela!'
        }
      })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.cupomId) {
      try {
        const { data: cupom } = await axios.get(`${process.env.REACT_APP_API_URL}/cupomDesconto/${params.cupomId}`, this.getToken())

        this.setState({
          cupom: {
            cupom_id: cupom.cupom_id,
            cupom_hash: cupom.cupom_hash,
            cupom_descricao: cupom.cupom_descricao,
            cupom_tipo: cupom.cupom_tipo,
            cupom_valor: cupom.cupom_valor,
            cupom_data_inicio: cupom.cupom_data_inicio,
            cupom_data_fim: cupom.cupom_data_fim,
            cupom_ativo: cupom.cupom_ativo,
            cupom_criado_em: cupom.cupom_criado_em,
            usuario: cupom.usuario_cadastro.pessoa.fisica.pesf_nome,
            historico_alt: cupom.historico_alt.map(value => {
              return {
                hiscup_id: value.hiscup_id,
                hiscup_alterado_em: moment(value.hiscup_alterado_em).format('DD/MM/YYYY HH:mm'),
                usuario: value.usuario_alteracao.pessoa.fisica.pesf_nome,
                hiscup_antes: JSON.parse(value.hiscup_antes),
                hiscup_depois: JSON.parse(value.hiscup_depois)
              }
            })
          },
          loading: false
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o Curso, tente mais tarde"
        })

        this.backPage()
      }
    }

  }

  handleCloseModalAntesDepois(){
    this.setState({
      alteracao_selecionada: {
        hiscup_id: '',
        hiscup_alterado_em: '',
        usuario: '',
        hiscup_antes: '',
        hiscup_depois: ''
      },
      openModalAntesDepois: false
    })
  }

  viewAntesDepois(row){
    this.setState({
      alteracao_selecionada: row,
      openModalAntesDepois: true
    })
  }

  render() {
    const { cupom, alteracao_selecionada } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div id='view'>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} xs={12}>
                  <h2 className="titulo">Cupom: {cupom.cupom_hash} </h2>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Link to={`/cupom_desconto/cadastro/${cupom.cupom_id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Editar Cupom
                  </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={5} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="cupom_descricao" value={cupom.cupom_descricao} InputLabelProps={{ shrink: true }} disabled={true}/>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Hash" variant="outlined" size="small" name="cupom_hash" value={cupom.cupom_hash} InputLabelProps={{ shrink: true }} disabled={true}/>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Tipo do Desconto" variant="outlined" size="small" name="cupom_tipo" value={cupom.cupom_tipo} InputLabelProps={{ shrink: true }} disabled={true}/>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    label="Valor Desconto" 
                    variant="outlined" 
                    size="small" 
                    name="cupom_valor" 
                    value={cupom.cupom_tipo === 'PORCENTAGEM' ? cupom.cupom_valor + '%' : 'R$ ' + real(cupom.cupom_valor)} 
                    disabled={true}
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input"
                    label="Data Inicio" 
                    variant="outlined" 
                    size="small" 
                    name="cupom_data_inicio" 
                    value={moment(cupom.cupom_data_inicio).format('DD/MM/YYYY')} 
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input"
                    label="Data Fim" 
                    variant="outlined" 
                    size="small" 
                    name="cupom_data_fim" 
                    value={moment(cupom.cupom_data_fim).format('DD/MM/YYYY')} 
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Ativo?" variant="outlined" size="small" name="cupom_ativo" value={cupom.cupom_ativo ? 'Sim': 'Não'} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Cadastrado Por" variant="outlined" size="small" name="usuario" value={cupom.usuario} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input"
                    label="Cadastrado Em" 
                    variant="outlined" 
                    size="small" 
                    name="cupom_criado_em" 
                    value={moment(cupom.cupom_criado_em).format('DD/MM/YYYY')} 
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <hr className="mg_top_20"/> 
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className='titulo'>Historico de Alterações</h4>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Table
                  headCell={this.state.cabecalhoTabela}
                  rows={cupom.historico_alt}
                  acoes={this.state.acoesTabela} 
                  viewAntesDepois={(e) => this.viewAntesDepois(e)}
                />
              </Grid>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9} xs={false}></Grid>
                <Grid item md={3} xs={12}>
                  <Link to={`/curso`}>
                    <Button className="btnCadastrar" variant="contained" color="secondary">
                      Voltar
                  </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          }
          <Alert 
            open={this.state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={this.state.alerta.severity}
            message={this.state.alerta.message} 
          />
          <ModalAntesDepois 
            open={this.state.openModalAntesDepois}
            handleClose={e => this.handleCloseModalAntesDepois()}
            cupom={cupom}
            alteracao_selecionada={alteracao_selecionada} 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}