import React, { Component } from 'react'
import './Login.scss'
import './../../main/ultil.scss'
import { Container, Grid, Button, TextField, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Rodape from '../../components/RodapeLogin'
import axios from 'axios'
import ModalErro from '../../components/modals/Erro'
import verificaForcaDaSenha from './../../services/verificaForcaSenha'

const initalState = {
  dados: {
    codigo: '',
    novaSenha: '',
    repetirSenha: ''
  },
  loading: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  nivelSenha: 'Muito Fraca',
  corSenha: 'red',
  showPassword: false,
  showPasswordRepetir: false
}

export default class Login extends Component {

  constructor(props) {
    super(props)

    localStorage.clear();
  }

  state = { ...initalState }

  componentWillMount() {
    this.setState({
      dados: {
        codigo: '',
        novaSenha: '',
        repetirSenha: ''
      },
      nivelSenha: 'Muito Fraca',
      corSenha: 'red',
      showPassword: false,
      showPasswordRepetir: false
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Login!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async updateField(event) {
    const { dados } = this.state
    dados[event.target.name] = event.target.value

    if (event.target.name === 'novaSenha') {
      const resp = await verificaForcaDaSenha(event.target.value)
      
      this.setState({
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      })
    }

    this.setState({ dados })
  }

  validateForm() {
    const { dados } = this.state

    if (!dados.codigo) return false
    if (!dados.novaSenha) return false
    if (!dados.repetirSenha) return false

    return true
  }

  async trocarSenha() {
    const { dados, nivelSenha } = this.state

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Preencher todos os campos!'
      })
      return
    }

    if (dados.novaSenha !== dados.repetirSenha) {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Senhas não conferem!'
      })
      return
    }

    if (nivelSenha === 'Média' || nivelSenha === 'Forte') {
      try {

        const { match: { params } } = this.props;

        await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/trocarSenha/${params.usuarioId}`, this.state.dados)

        this.props.history.push(`/`)
        this.setState({ loading: false })
        return;
      } catch ({ response }) {
        this.handleOpenDialog({
          titulo: 'Opps',
          descricao: response.data.message
        })
        return
      }
    } else {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Senha Fraca!'
      })
      return
    }
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleClickShowPasswordRepetir() {
    this.setState({
      showPasswordRepetir: !this.state.showPasswordRepetir
    })
  }

  render() {
    const { codigo, novaSenha, repetirSenha } = this.state.dados
    const { nivelSenha, corSenha, showPassword, showPasswordRepetir } = this.state
    return (
      <React.Fragment>
        <div className="topo">
          <img src="./images/logo.png" alt="Logo British And American" />
        </div>
        <Container>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item md={4}>
              <div className="formLogin">
                <Container>
                  <Grid container direction="row" >
                    <Grid item md={12}>
                      <h3>TROCAR SENHA</h3>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_20" >
                    <Grid item md={12} xs={12} sm={12}>
                      <TextField className="input" label="Código:" variant="outlined" name="codigo" value={codigo} onChange={(e) => this.updateField(e)} />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_20" >
                    <Grid item md={12} xs={12} sm={12}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Nova Senha</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={novaSenha}
                          onChange={(e) => this.updateField(e)}
                          className="input"
                          size="small"
                          name="novaSenha"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => this.handleClickShowPassword(e)}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_20" >
                    <Grid item md={12} xs={12} sm={12}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Repetir Senha</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPasswordRepetir ? 'text' : 'password'}
                          value={repetirSenha}
                          onChange={(e) => this.updateField(e)}
                          className="input"
                          size="small"
                          name="repetirSenha"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e) => this.handleClickShowPasswordRepetir(e)}
                                edge="end"
                              >
                                {showPasswordRepetir ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <span>Nivel Senha: <b style={{ color: corSenha }}>{nivelSenha}</b> </span>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_20" alignItems="center" justify="center">
                    <Grid item md={8}>
                      <Button disabled={this.state.loading} variant="contained" className="btn_entrar" size="small" onClick={() => this.trocarSenha()}>
                        Enviar
											</Button>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
        </Container>
        <Rodape />
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
      </React.Fragment>
    )
  }
}