import React, { Component } from 'react'
import './Caixa.scss'
import './../../main/ultil.scss'
import ModalErro from './../../components/modals/Erro'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import axios from 'axios'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { Grid, Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress'
import real from '../../services/real'
import moment from 'moment'

import CurrencyInput from './../../components/mask/CurrencyInput'

const initalState = {
  dadosAprovacao: {
    solac_valor_fundo_troco: '',
    solac_abertura_aprovado: false
  },
  solicitacao: {},
  contaCofre: {},
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  }
}

export default class Caixa extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/solicitacao-abertura/${params.solicitacaoId}`, this.getToken())

      this.setState({
        solicitacao: data.solicitacao,
        contaCofre: data.contaCofre,
        dadosAprovacao: {
          solac_valor_fundo_troco: real(data.solicitacao.solac_valor_fundo_troco),
          solac_abertura_aprovado: false
        }
      })


    } catch (error) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: error.response.data.message
      })
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async aprovar(value) {
    try {

      const { dadosAprovacao } = this.state

      dadosAprovacao.solac_abertura_aprovado = value
      dadosAprovacao.solac_valor_fundo_troco = parseFloat(dadosAprovacao.solac_valor_fundo_troco.replace("R$ ", "").replace(".", "").replace(",", "."))

      const { match: { params } } = this.props;
      await axios.post(`${process.env.REACT_APP_API_URL}/caixa/aprovar-solicitacao-abertura/${params.solicitacaoId}`, dadosAprovacao, this.getToken())

      if (value) {
        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Caixa Liberado para Uso.'
        })
      } else {
        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Abertura de caixa reprovado.'
        })
      }


      // this.backPage()

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: error.response.data.message
      })
    }
  }

  updateField(event) {
    const { dadosAprovacao } = this.state

    dadosAprovacao[event.target.name] = event.target.value

    this.setState({ dadosAprovacao })
  }

  render() {
    const { solicitacao, contaCofre, dadosAprovacao } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={8} xs={12}>
                  <h2 className="titulo">Solicitação Abertura de Caixa</h2>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={5} xs={12} sm={4}>
                  <TextField className="input" label="Usuário:" variant="outlined" size="small" value={solicitacao.usuario_solicitacao.pessoa.fisica.pesf_nome} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Data/Hora Solicitação:" variant="outlined" size="small" value={moment(solicitacao.solac_criado_em).format('DD/MM/YYYY HH:mm')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Saldo Atual Cofre:" variant="outlined" size="small" value={real(contaCofre.contfin_saldo_atual)} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Fundo de Troco:*" variant="outlined" size="small" name='solac_valor_fundo_troco' value={dadosAprovacao.solac_valor_fundo_troco} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} onChange={(e) => this.updateField(e)} />
                </Grid>

              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={8} xs={false} sm={4}></Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    className="btn_salvar"
                    startIcon={<CheckIcon />}
                    onClick={e => this.aprovar(true)}
                    disabled={dadosAprovacao.solac_valor_fundo_troco === ''}>
                    Aprovar
                    </Button>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    className="btn_salvar"
                    startIcon={<CloseIcon />}
                    onClick={e => this.aprovar(false)}>
                    Reprovar
                    </Button>
                </Grid>
              </Grid>
              <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
            </div>
          }
        </Main>
        <Nav />
        <Footer />
      </div>
    )
  }
}