import React, { Component } from 'react'
import './Curso.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, TextField, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Alert from './../../components/templates/Alert'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

const initalState = {
  curso: {
    _id: "",
    curso_nome: "",
    curso_ativo: true,
    curso_gera_certificado: true,
    books: []
  },
  cursos: {
    list: []
  },
  book: {
    bok_id: "",
    bok_nome: "",
    bc_obrigatorio: true,
    bc_ativo: true,
    produto: {},
  },
  books: {
    list: []
  },
  booksRemovidos: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'bok_nome', numeric: false, disablePadding: true, label: 'Book' },
    { id: 'bc_obrigatorio', numeric: false, disablePadding: false, label: 'Obrigatório' },
    { id: 'bc_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: [],
  update: false
}

export default class ViewCurso extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'curso')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_visualizar) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message: 'Você não tem permissão para acessa essa tela!'
        }
      })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.cursoId) {
      try {
        const { data: curso } = await axios.get(`${process.env.REACT_APP_API_URL}/curso/${params.cursoId}`, this.getToken())

        const books = []

        for (let i = 0; i < curso.books.length; i++) {
          books.push({
            bok_id: curso.books[i].bok_id,
            bok_nome: curso.books[i].bok_nome,
            bc_obrigatorio: curso.books[i].BookCurso.bc_obrigatorio,
            bc_ativo: curso.books[i].BookCurso.bc_ativo,
            index: i + 1
          })
        }

        this.setState({
          curso: {
            _id: curso.curso_id,
            curso_nome: curso.curso_nome,
            curso_gera_certificado: curso.curso_gera_certificado,
            curso_ativo: curso.curso_ativo,
            curso_ead: curso.curso_ead,
            books,
          },
          loading: false
        })

      } catch ({response}) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          }
        })
        this.backPage()
        return
      }
    }
  }

  backPage(timeout = 4000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/curso");
    }, timeout)
  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.setState({
      alerta: {
        open: true,
        autoHideDuration: 5000,
        severity: 'success',
        message: 'Link copiado'
      }
    })  
    return
    
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { curso } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} xs={12}>
                  <h2 className="titulo">Curso: {curso.curso_nome} </h2>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Link to={`/curso/cadastro/${curso._id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Editar Curso
                  </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20" id="view">
                <Grid item md={6} xs={12} sm={6}>
                  <TextField className="input" label="Nome Curso" variant="outlined" size="small" name="curso_nome" value={curso.curso_nome} disabled={true} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Gera Certificado?" variant="outlined" size="small" name="curso_gera_certificado" value={curso.curso_gera_certificado ? 'Sim' : 'Não'} disabled={true} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="EAD" variant="outlined" size="small" name="curso_ead" value={curso.curso_ead ? 'Sim' : 'Não'} disabled={true} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Ativo" variant="outlined" size="small" name="curso_ativo" value={curso.curso_ativo ? 'Ativo' : 'Inativo'} disabled={true} />
                </Grid>
              </Grid>
              {curso.curso_ead &&
                <Grid container spacing={1} direction="row" className="mg_top_10" id="view">
                  <Grid item md={4} xs={12} sm={8}>
                    <TextField fullWidth size="small" variant="outlined" className="input" label="Link Portal Aluno" value={`${process.env.REACT_APP_URL}/ead/${curso._id}/cadastro`} InputLabelProps={{ shrink: true }} disabled />
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <Tooltip title='Copiar Link' aria-label="add">
                      <IconButton style={{marginTop: '-3px', marginLeft: '-10px'}} color="primary" aria-label="view" onClick={e => this.copyLink(`${process.env.REACT_APP_URL}/ead/${curso._id}/cadastro`)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              }

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Table
                  headCell={this.state.cabecalhoTabela}
                  rows={this.state.curso.books.map(book => {
                    return {
                      _id: book.bok_id,
                      bok_nome: book.bok_nome,
                      bc_obrigatorio: book.bc_obrigatorio,
                      bc_ativo: book.bc_ativo,
                      index: book.index,
                    }
                  })}
                  acoes={this.state.acoesTabela} />
              </Grid>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9} xs={false}></Grid>
                <Grid item md={3} xs={12}>
                  <Link to={`/curso`}>
                    <Button className="btnCadastrar" variant="contained" color="secondary">
                      Voltar
                  </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          }
          <Alert 
            open={this.state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={this.state.alerta.severity}
            message={this.state.alerta.message} 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}