import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl } from '@material-ui/core'

const FormBook = ({ produto, produtos, dados, updateField, updateFieldAtivo, updateFieldProduto, updateFieldStart }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20"> 
      <Grid item md={3} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldProduto(event, value)}
          freeSolo
          options={produtos}
          value={produto}
          getOptionLabel={option => option.prod_descricao}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Produto*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={produto.prod_descricao} />
          )}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Nome Book*" variant="outlined" size="small" name="bok_nome" value={dados.bok_nome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField type='number' className="input" label="Ordem*" variant="outlined" size="small" name="bok_ordem" value={dados.bok_ordem} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      {/* <Grid item md={2} xs={12} sm={6}>
        <TextField type='number' className="input" label="Pontos Fly High*" variant="outlined" size="small" name="bok_vl_ponto" value={dados.bok_vl_ponto} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid> */}
      <Grid item md={2} xs={12} sm={5}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Book Start?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="bok_start"
                color="primary"
                checked={dados.bok_start}
              />
            }
            size="small"
            label={dados.bok_start ? 'Sim' : 'Não'}
            name="bok_start"
            onChange={(e) => updateFieldStart(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={5}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="bok_ativo"
                color="primary"
                checked={dados.bok_ativo}
              />
            }
            size="small"
            label={dados.bok_ativo ? 'Sim' : 'Não'}
            name="bok_ativo"
            onChange={(e) => updateFieldStart(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    {/* <Grid container direction="row" spacing={1} className="mg_top_10">
    </Grid> */}
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <TextField
          id="outlined-multiline-static"
          label="Descrição"
          className="input"
          multiline
          rows={5}
          size="small"
          variant="outlined"
          name="bok_descricao"
          value={dados.bok_descricao}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormBook