import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button, FormControlLabel, Switch } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '../../../components/Table'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import axios from 'axios'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import ModalErro from './../../../components/modals/Erro'

const initalState = {
  observacoes: {
    list: []
  },
  planosConta: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'alobs_id', numeric: false, disablePadding: false, label: 'id' },
    { id: 'data', numeric: false, disablePadding: false, label: 'Data' },
    { id: 'dataalert', numeric: false, disablePadding: false, label: 'Data Alert' },
    { id: 'alobs_obs', numeric: false, disablePadding: false, label: 'Observação' },
    { id: 'alobs_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
    { id: 'alobs_tipo', numeric: false, disablePadding: false, label: 'Tipo' },
    { id: 'responsavel', numeric: false, disablePadding: false, label: 'Responsável' }
  ],
  acoesTabela: ['update'],
  loading: true,
  observacao: {
    alobs_id: null,
    alobs_aluno_id: null,
    alobs_obs: '',
    alobs_ativo: true,
    alobs_tipo: '',
    alobs_dataalert: ''
  },
  filtro: {
    alobs_tipo: 'TODOS'
  },
  editando: false,
  modal: false,
  dialog: {
    ativo: false,
    titulo: "",
    descricao: ""
  },
  loadList: true
}


export default class Observacao extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      const observacao = { ...this.state.observacao }
      observacao.alobs_aluno_id = this.props.aluno._id
      this.setState({ observacao })

      await this.buscarObservacoes()

      this.setState({
        loading: false
      })
    } catch (error) {
      console.log(error)
      // this.openDialog({
      //   titulo: "Opps!",
      //   descricao: `${response.data.message}, tente mais tarde`
      // })
    }
  }

  async filtrar() {
    const filtro = { ...this.state.filtro }
    

    await this.buscarObservacoes(filtro)
  }

  async buscarObservacoes(filtro = {}) {
    this.setState({ loadList: true })

    const {permissoesObsAcademico, permissoesObsGeral} = this.props

    if(permissoesObsAcademico.perm_visualizar && permissoesObsGeral.perm_visualizar){
      if (filtro.alobs_tipo === 'TODOS') delete filtro.alobs_tipo
    }else if(permissoesObsAcademico.perm_visualizar && !permissoesObsGeral.perm_visualizar){
      filtro.alobs_tipo = 'ACADEMICA'
    }else if(!permissoesObsAcademico.perm_visualizar && permissoesObsGeral.perm_visualizar){
      filtro.alobs_tipo = 'GERAL'
    }
    
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/alunoobs/aluno/${this.props.aluno._id}/list`, filtro, this.getToken())

    this.setState({
      observacoes: {
        list: data
      },
      loadList: false
    })
  }

  updateField(event, name) {
    if (name === 'alobs_ativo') {
      const observacao = { ...this.state.observacao }
      observacao.alobs_ativo = !observacao.alobs_ativo
      this.setState({ observacao })
      return
    }

    const split = event.target.name.split('.')
    const data = { ...this.state[split[0]] }
    data[split[1]] = event.target.value
    const intercection = {}
    intercection[split[0]] = data
    this.setState({ ...intercection })
  }

  valida() {
    const observacao = { ...this.state.observacao }

    if (
      observacao.alobs_aluno_id === null ||
      observacao.alobs_obs === '' ||
      observacao.alobs_tipo === ''
    ) {
      return false
    }

    return true
  }

  preparaEditar(edit) {
    const logado = JSON.parse(localStorage.getItem('usuario'))

    if (logado.usu_id !== edit.alobs_usu_id) {
      this.openDialog({
        titulo: 'Opps.',
        descricao: 'Somente o responsável pode editar a Observação'
      })
      return
    }

    this.setState({
      observacao: {
        alobs_id: edit.alobs_id,
        alobs_aluno_id: edit.alobs_aluno_id,
        alobs_obs: edit.alobs_obs,
        alobs_ativo: edit.alobs_ativo,
        alobs_tipo: edit.alobs_tipo,
        alobs_dataalert: edit.alobs_dataalert
      },
      editando: true
    })
    this.setState({ modal: true })
  }

  async salvar(resposta) {
    this.setState({ salvando: true })

    try {
      const observacao = { ...this.state.observacao }
      const editando = this.state.editando

      if (!this.valida()) {
        this.openDialog({
          titulo: 'Opps.',
          descricao: 'É necessário preencher todos os campos obrigatorios (*)'
        })
        this.setState({ salvando: false })
        return
      }

      if(observacao.alobs_dataalert === ''){
        delete observacao.alobs_dataalert
      }

      if (!editando) {
        delete observacao.alobs_id
        await axios.post(`${process.env.REACT_APP_API_URL}/alunoobs`, observacao, this.getToken())
      } else {
        const alobs_id = observacao.alobs_id
        delete observacao.alobs_id
        delete observacao.alobs_aluno_id
        await axios.put(`${process.env.REACT_APP_API_URL}/alunoobs/${alobs_id}`, observacao, this.getToken())
      }

      this.openDialog({
        titulo: 'Parabens.',
        descricao: editando ? 'Observação editada com sucesso' : 'Observação salva com sucesso'
      })

      this.reload()
      this.limparCampos()
      this.setState({ salvando: false })
    } catch (error) {
      this.setState({ salvando: false })
      this.openDialog({
        titulo: 'Opps.',
        descricao: error.response.data.message
      })
    }
  }

  reload(timeout = 1000) {
    const self = this
    setTimeout(() => {
      this.buscarObservacoes()
      self.setState({
        modal: false,
        salvando: false
      })
      self.closeDialog()
    }, timeout)
  }


  toogleModal() {
    this.setState({
      modal: !this.state.modal
    })
  }

  calcelarCadastro() {
    this.toogleModal()
    this.closeDialog()
    this.limparCampos()
  }

  limparCampos() {
    this.setState({
      observacao: {
        ...initalState.observacao,
        alobs_aluno_id: this.props.aluno._id,
      },
      editando: false
    })
  }

  openDialog(error) {
    if (!error) error = {}

    this.setState({
      dialog: {
        ativo: true,
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  closeDialog() {
    this.setState({
      dialog: {
        ativo: false,
        titulo: '',
        descricao: ''
      }
    })
  }

  render() {
    const observacoes = []

    for (const object of this.state.observacoes.list) {
      observacoes.push({
        ...object,
        dataalert: object.alobs_dataalert ? moment(object.alobs_dataalert).format('DD/MM/YYYY') : '',
        data: moment(object.alobs_criado_em).format('DD/MM/YYYY HH:mm') + 'h',
        responsavel: object.usuario.pessoa.fisica ?
          object.usuario.pessoa.fisica.pesf_nome.split(' ')[0] :
          'Transferência'
      })
    }

    const dataHojeFormatado = moment().format('YYYY-MM-DD')

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Observações...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div style={{ 'marginTop': '15px' }}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={6} xs={12} sm={6}>
                <h3> Observações </h3>
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
              <Grid item md={12} xs={12} sm={12}>
                <h5> Filtro </h5>
              </Grid>
              <Grid item md={3} xs={6} sm={4}>
                <TextField
                  id="standard-select-currency"
                  select
                  variant="outlined"
                  className="input"
                  fullWidth
                  label="Tipo*"
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="filtro.alobs_tipo"
                  value={this.state.filtro.alobs_tipo}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  {this.props.permissoesObsGeral.perm_visualizar && 
                    <React.Fragment> 
                      {this.props.permissoesObsAcademico.perm_visualizar && 
                        <option value=""> TODOS </option>
                      }
                    </React.Fragment>
                  }
                  {this.props.permissoesObsGeral.perm_visualizar && 
                    <option value='GERAL'> GERAL </option>
                  }
                  {this.props.permissoesObsAcademico.perm_visualizar && 
                    <option value='ACADEMICA'> ACADÊMICA </option>
                  }
                </TextField>
              </Grid>
              <Grid item md={2} xs={6} sm={4}>
                <Button
                  style={{ 'marginTop': '3px' }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.filtrar()}
                  startIcon={<SearchIcon />}
                >
                  Filtrar
                </Button>
              </Grid>

              <Grid item md={7} xs={12} sm={4}>
                <Button
                  style={{ float: 'right' }}
                  variant="contained"
                  color="secondary"
                  onClick={() => this.toogleModal()}
                  startIcon={<AddIcon />}
                >
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" style={{ 'marginTop': '30px' }}>
              {this.state.loadList &&
                <React.Fragment>
                  <div className="progressCircular">
                    <CircularProgress />
                  </div>
                </React.Fragment>
              }
              <Grid item md={12} xs={12}>
                {!this.state.loadList &&
                  <Table
                    urlUpdate={e => this.preparaEditar(e)}
                    headCell={this.state.cabecalhoTabela}
                    rows={observacoes}
                    acoes={this.state.acoesTabela}
                    ordem="desc"
                    ordernar="alobs_id"
                  />
                }
              </Grid>
            </Grid>

            <ModalErro open={this.state.dialog.ativo} titulo={this.state.dialog.titulo} descricao={this.state.dialog.descricao} handleClose={e => this.closeDialog(e)} />

            <Dialog
              disableBackdropClick
              fullWidth={true}
              maxWidth="lg"
              open={this.state.modal}
              onClose={e => this.toogleModal(e)}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogContent>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12} sm={12}>
                    {!this.state.editando && <h4>Cadastrar Observação</h4>}
                    {this.state.editando && <h4>Editar Observação</h4>}
                  </Grid>
                  <Grid item md={12} xs={12} sm={12} style={{ 'marginTop': '20px' }}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Observação*"
                      className="input"
                      multiline
                      rows={5}
                      size="small"
                      variant="outlined"
                      name="observacao.alobs_obs"
                      value={this.state.observacao.alobs_obs}
                      onChange={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md={3} xs={6} sm={4}>
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      className="input"
                      fullWidth
                      label="Tipo*"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="observacao.alobs_tipo"
                      value={this.state.observacao.alobs_tipo}
                      onChangeCapture={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">--Escolha--</option>
                      {this.props.permissoesObsGeral.perm_visualizar && 
                        <option value='GERAL'> GERAL </option>
                      }
                      {this.props.permissoesObsAcademico.perm_visualizar && 
                        <option value='ACADEMICA'> ACADÊMICA </option>
                      }
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={6} sm={2}>
                    <FormControlLabel
                      style={{ 'marginTop': '2px' }}
                      label="Ativo"
                      control={<Switch
                        className="switch"
                        name="alterar"
                        color="primary"
                        checked={this.state.observacao.alobs_ativo}
                      />}
                      name="alterar"
                      onChange={(e) => this.updateField(e, 'alobs_ativo')}
                    />
                  </Grid>
                  <Grid item md={2} xs={6} sm={2}>
                    <TextField
                      fullWidth
                      className="input"
                      type='date'
                      name='observacao.alobs_dataalert'
                      label="Data do Alerta"
                      value={this.state.observacao.alobs_dataalert}
                      variant="outlined"
                      size="small"
                      onChange={(e) => this.updateField(e)}
                      InputProps={{ inputProps: { min: dataHojeFormatado } }}
                      InputLabelProps={{
                        shrink: true,
                      }} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={e => this.calcelarCadastro(e)}
                  color="primary"
                  disabled={this.state.salvando}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={e => this.salvar(e)}
                  disabled={this.state.salvando}
                >
                  Salvar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        }

      </React.Fragment>
    )
  }
}