import React, { Component } from 'react'
import './CodigoAlfaNum.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, TextField, Button } from '@material-ui/core'
import Table from '../../components/Table'
import ModalErro from './../../components/modals/Erro'
import CancelamentoCodigo from './../../components/modals/CancelamentoCodigo'
import LiberacaoCodigo from './../../components/modals/LiberacaoCodigo'
import BloqueioCodigo from './../../components/modals/BloqueioCodigo'
import TransferirCodigo from './../../components/modals/TransferirCodigo'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

const initalState = {
  codigo: {

  },
  codigos: {
    list: []
  },
  transferencia: {
    unin_id: '',
    unin_descricao: '',
    unin_numero: ''
  },
  modalErro: false,
  modalCancelamento: false,
  modalLiberacao: false,
  modalBloqueio: false,
  modalTransferir: false,
  unidades: {
    list: []
  },
  erro: {
    titulo: "",
    descricao: ""
  },
  cabecalhoTabela: [
    { id: 'codmatdid_criado_em', numeric: false, disablePadding: false, label: 'Gerado em' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Gerado por' },
    { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Gerado para' },
    { id: 'hiscod_situacao', numeric: false, disablePadding: false, label: 'Situação' }
  ],
  acoesTabela: [],
  loading: true,
  update: false
}

function formatarCodigo(str) {
  const parte1 = str.slice(0, 4)
  const parte2 = str.slice(4, 8)
  const parte3 = str.slice(8, 12)

  return `${parte1}-${parte2}-${parte3}`
}

export default class ViewCodigo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;
    if (params.codigoId) {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/codigoMaterial/${params.codigoId}`, this.getToken())
      
        this.setState({
          codigo: data,
          loading: false
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o Código, tente mais tarde"
        })

        this.backPage()
      }
    }

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleCloseCancelamento() {
    this.setState({
      modalCancelamento: !this.state.modalCancelamento
    })
  }

  handleCloseLiberacao() {
    this.setState({
      modalLiberacao: !this.state.modalLiberacao
    })
  }

  handleCloseBloqueio() {
    this.setState({
      modalBloqueio: !this.state.modalBloqueio
    })
  }

  handleCloseTransferir() {
    this.setState({
      modalTransferir: !this.state.modalTransferir
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/codigo_alfa");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  cancelarCodigo() {
    this.setState({
      modalCancelamento: true
    })
  }

  async confirmarCancelamento(value) {
    if (value) {
      const { codigo } = this.state

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/codigoMaterial/cancelamento/${codigo.codmatdid_id}`, {}, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })

        window.location.reload()
      } catch ({ response }) {
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.handleOpenDialog({})
      }

    } else {
      this.setState({
        modalCancelamento: false
      })
    }

  }

  liberarCodigo() {
    this.setState({
      modalLiberacao: true
    })
  }

  async confirmarLiberacao(value) {
    if (value) {
      const { codigo } = this.state

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/codigoMaterial/liberacao/${codigo.codmatdid_id}`, {}, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })

        window.location.reload()
      } catch ({ response }) {
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.handleOpenDialog({})
      }
    } else {
      this.setState({
        modalLiberacao: false
      })
    }
  }

  bloquearCodigo() {
    this.setState({
      modalBloqueio: true
    })
  }

  async confirmarBloqueio(value) {
    if (value) {
      const { codigo } = this.state

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/codigoMaterial/bloqueio/${codigo.codmatdid_id}`, {}, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })

        window.location.reload()
      } catch ({ response }) {
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.handleOpenDialog({})
      }
    } else {
      this.setState({
        modalBloqueio: false
      })
    }
  }

  async transferirCodigo() {
    try {

      const {codigo} = this.state

      const data = {
        unidadesnegocio: [codigo.historico[codigo.historico.length -1].hiscod_unin_id]
      }

      const {data: unidades} = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupo`, data, this.getToken())

      this.setState({
        unidades: {
          list: unidades.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : (b.unin_numero > a.unin_numero) ? -1 : 0)
        },
        modalTransferir: true
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  updateUnidadeTransferencia(event) {
    const { transferencia } = this.state
    const unidades = this.state.unidades.list

    if (event.target.name === 'unin_id') {
      const unidade = unidades.filter(param => param.unin_id === parseInt(event.target.value))[0]

      transferencia.unin_descricao = unidade.unin_descricao
      transferencia.unin_numero = unidade.unin_numero
    }

    transferencia[event.target.name] = event.target.value
    this.setState({ transferencia })
  }

  async confirmarTransferir(value) {
    if (value) {
      const { codigo } = this.state
      const { transferencia } = this.state

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/codigoMaterial/transferencia/${codigo.codmatdid_id}`, transferencia, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })

        window.location.reload()
      } catch ({ response }) {
        if (response.status === 400 || response.status === 500) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.handleOpenDialog({})
      }
    } else {
      this.setState({
        modalTransferir: false
      })
    }
  }

  voltar(){
    this.props.history.push("/codigo_alfa");
    return;
  }

  render() {
    const { codigo } = this.state
    if (!this.state.loading) {
      var historicos = codigo.historico
      var estadoAtual = historicos.filter(param => param.hiscod_ativo === true)[0]
      var hiscod_situacao_view = ''

      if (estadoAtual.hiscod_situacao === 'AGUARDANDO_LIBERACAO') {
        hiscod_situacao_view = 'Aguardando Liberação'
      } else if (estadoAtual.hiscod_situacao === 'LIBERADO_USO') {
        hiscod_situacao_view = 'Liberado P/ Uso'
      } else if (estadoAtual.hiscod_situacao === 'ULTILIZADO') {
        hiscod_situacao_view = 'Ultilizado'
      } else if (estadoAtual.hiscod_situacao === 'AGUARDANDO_APROVACAO_TRANSFERENCIA') {
        hiscod_situacao_view = 'Aguardando Aprovação Transf.'
      } else if (estadoAtual.hiscod_situacao === 'CANCELADO') {
        hiscod_situacao_view = 'Cancelado.'
      } else if (estadoAtual.hiscod_situacao === 'TRANSFERIDO') {
        hiscod_situacao_view = 'Transferido.'
      }
    }

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom" >
                <Grid item md={12} xs={12}>
                  <h2 className="titulo">Código Alfanúmerico Material: </h2>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20" id='view'>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Codigo" variant="outlined" size="small" value={formatarCodigo(codigo.codmatdid_codigo)} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Material" variant="outlined" size="small" value={codigo.produto.prod_descricao} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Código Ultilizado" variant="outlined" size="small" value={codigo.codmatdid_ultilizado ? 'Sim' : 'Não'} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Estado Atual" variant="outlined" size="small" value={hiscod_situacao_view} disabled />
                </Grid>
                {estadoAtual.hiscod_situacao === "ULTILIZADO" && 
                  <Grid item md={3} xs={12} sm={4}>
                    <TextField className="input" label="Aluno" variant="outlined" size="small" value={codigo.codigo_aluno.aluno.fisica.pesf_nome} disabled />
                  </Grid>
                }
              </Grid>
              <br />
              <hr />
              <Grid container spacing={1} direction="row" >
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Histórico do Código:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabela}
                    rows={codigo.historico.sort((a, b) => new Date(a.hiscod_data).getTime() - new Date(b.hiscod_data).getTime()).map(historico => {
                      let hiscod_situacao = ''

                      if (historico.hiscod_situacao === 'AGUARDANDO_LIBERACAO') {
                        hiscod_situacao = 'Aguardando Liberação'
                      } else if (historico.hiscod_situacao === 'LIBERADO_USO') {
                        hiscod_situacao = 'Liberado P/ Uso'
                      } else if (historico.hiscod_situacao === 'ULTILIZADO') {
                        hiscod_situacao = 'Ultilizado'
                      } else if (historico.hiscod_situacao === 'AGUARDANDO_APROVACAO_TRANSFERENCIA') {
                        hiscod_situacao = 'Aguardando Aprovação Transf.'
                      } else if (historico.hiscod_situacao === 'CANCELADO') {
                        hiscod_situacao = 'Cancelado'
                      } else if (historico.hiscod_situacao === 'TRANSFERIDO') {
                        hiscod_situacao = 'Transferido'
                      }

                      return {
                        _id: historico.hiscod_id,
                        codmatdid_criado_em: moment(historico.hiscod_data).format('DD/MM/YYYY HH:mm'),
                        pesf_nome: historico.usuario.pessoa.fisica ? historico.usuario.pessoa.fisica.pesf_nome : 'Migração',
                        unin_descricao: historico.unidade.unin_numero ? historico.unidade.unin_numero + ' - ' + historico.unidade.unin_descricao : '0 - Central',
                        hiscod_situacao
                      }
                    })}
                    acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>

              {estadoAtual.hiscod_situacao !== 'CANCELADO' &&
                <Grid container spacing={1} direction="row">
                  <Grid item md={2} xs={12} sm={4}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => this.voltar(e)}>Voltar</Button>
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => this.cancelarCodigo(e)}>Cancelar</Button>
                  </Grid>
                  {estadoAtual.hiscod_situacao === 'LIBERADO_USO' &&
                    <Grid item md={2} xs={12} sm={4}>
                      <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<LockIcon />} onClick={e => this.bloquearCodigo(e)}>Bloquear</Button>
                    </Grid>
                  }
                  {estadoAtual.hiscod_situacao === 'AGUARDANDO_LIBERACAO' &&
                    <Grid item md={2} xs={12} sm={4}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<LockOpenIcon />} onClick={e => this.liberarCodigo(e)}>Liberar</Button>
                    </Grid>
                  }

                  {estadoAtual.hiscod_situacao === 'AGUARDANDO_APROVACAO_TRANSFERENCIA' &&
                    <Grid item md={2} xs={12} sm={4}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<LockOpenIcon />} onClick={e => this.liberarCodigo(e)}>Liberar</Button>
                    </Grid>
                  }

                  {estadoAtual.hiscod_situacao !== 'ULTILIZADO' && 
                    <Grid item md={2} xs={12} sm={4}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SwapHorizIcon />} onClick={e => this.transferirCodigo(e)}>Transferir</Button>
                    </Grid>
                  }
                </Grid>
              }

            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <CancelamentoCodigo open={this.state.modalCancelamento} handleClose={e => this.handleCloseCancelamento(e)} confirmar={e => this.confirmarCancelamento(e)} />
          <LiberacaoCodigo open={this.state.modalLiberacao} handleClose={e => this.handleCloseLiberacao(e)} confirmar={e => this.confirmarLiberacao(e)} />
          <BloqueioCodigo open={this.state.modalBloqueio} handleClose={e => this.handleCloseBloqueio(e)} confirmar={e => this.confirmarBloqueio(e)} />
          <TransferirCodigo open={this.state.modalTransferir} handleClose={e => this.handleCloseTransferir(e)} confirmar={e => this.confirmarTransferir(e)} unidades={this.state.unidades.list} updateUnidadeTransferencia={e => this.updateUnidadeTransferencia(e)} transferencia={this.state.transferencia} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}