import React, { Component } from 'react'
import './CentralNotificacao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@material-ui/core'
import FormNotificacao from '../../components/forms/FormNotificacao'
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Footer from '../../components/templates/Footer'
import ModalErro from './../../components/modals/Erro'
import Alert from './../../components/templates/Alert'

const initalState = {
  notificacao: {
    id: "",
    nome_alerta: "",
    titulo_alerta: "",
    modulo: "",
    canal: "",
    qtd_dias: "",
    html: "",
    contato_resposta: "",
    configuracao_id: "",
    hora_envio: "",
    ativo: true
  },
  notificacaos: {
    list: []
  },
  loadingSalvar: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading:true,
  update: false,
  unidades: {
    list: []
  },
  tipo: 'UNIDADE',
  filtro: {
    descricao: ''
  },
  variaveisFiltrada:{
    list: []
  },
  templates: {
    list: []
  },
  editorState: EditorState.createEmpty(),
  unidadesSelecionadas: [],
  openModalSelecionarLogo: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  formasPagamento: {
    list: []
  },
  situacoesContrato: {
    list: ['APROVADO', 'REPROVADO', 'INATIVO', 'AGUARDANDO_APROVACAO', 'TRANSFERIDO', 'CANCELADO', 'ENCERRADO', 'AGUARDANDO_TRANFERENCIA', 'TRANSFERENCIA_RECUSADA', 'REMATRICULA']
  },
  formasSelecionadas: [],
  situacoesSelecionadas: [],
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

export default class CadastroNotificacao extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    this.setState({
      notificacao: {
        id: "",
        nome_alerta: "",
        titulo_alerta: "",
        modulo: "",
        canal: "",
        qtd_dias: "",
        html: "",
        contato_resposta: "",
        configuracao_id: "",
        hora_envio: "",
        ativo: true
      },
      unidadesSelecionadas: []
    })

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'central-alerta')[0]

    this.setState({
      permissoes
    })

    if (params.centralAlertaId) {
      if (!permissoes.perm_alterar) {
        this.setState({
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.setState({
          alerta: {
            open: true,
            severity: 'warnig',
            message: 'Você não tem permissão para acessa essa tela!'
          }
        })

        this.backPage()
      }
    }  

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data: templates } = await axios.get(`${process.env.REACT_APP_API_URL}/templatesvariaveis`, this.getToken())
      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      console.log(unidades)

      this.setState({
        templates:{
          list: templates
        },
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        },
        unidades:{
          list: unidades.filter(param => (param.unin_tipo === 'UNIDADE' || param.unin_tipo === 'FRANQUEADOR')).filter(param => param.unin_status).map(unidade => {
            return {
              id: unidade.unin_id,
              numero: unidade.unin_numero,
              descricao: unidade.unin_descricao
            }
          })
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Error",
          descricao: error.response.data.error
        }
      })
    }

    if (params.centralAlertaId) {
      try {
        const { data: central } = await axios.get(`${process.env.REACT_APP_API_URL}/centralAlerta/${params.centralAlertaId}`, this.getToken())

        const contentBlock = htmlToDraft(central.template.tempver_html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        
        let variaveisFiltrada = []

        if(this.state.templates.list.filter(param => param.tempvar_tipo === central.centalert_modulo)[0]){
          variaveisFiltrada = this.state.templates.list.filter(param => param.tempvar_tipo === central.centalert_modulo)[0].variaveis
        }

        if(central.formas_central){
          this.setState({
            formasSelecionadas: central.formas_central.map(value => {
              return {
                fpcl_id: value.fpcl_id,
                ...value.forma_pagamento
              }
            })
          })
        }

        if(central.situacoes){
          this.setState({
            situacoesSelecionadas: central.situacoes.map(value => {
              return value.sccl_situacao
            }),
          })
        }

        this.setState({
          notificacao: {
            id: central.centalert_id,
            nome_alerta: central.centalert_nome_alerta,
            titulo_alerta: central.centalert_titulo_alerta,
            modulo: central.centalert_modulo,
            canal: central.centalert_canal,
            hora_envio: central.centalert_hora_envio || '',
            qtd_dias: central.centalert_dias_antes_evento,
            html: central.template.tempver_html,
            contato_resposta: central.centalert_contato_resposta,
            configuracao_id: central.centalert_configuracao_id,
            ativo: central.centalert_ativo
          },
          variaveisFiltrada:{
            list: variaveisFiltrada
          },
          unidadesSelecionadas:central.unidades.map(value => {
            return {
              id: value.alertunid_unidade_id,
              descricao: value.unidade.unin_descricao,
              numero: value.unidade.unin_numero
            }
          }),
          editorState,
        })

      } catch (error) {
        console.log(error)
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Error",
            descricao: error.response.data.error
          }
        })
      }
    }

    this.setState({
      loading: false
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/central_notificacao");
    }, timeout)
    this.setState({
      departamento: initalState.departamento
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }
  
  updateField(event){
    const {notificacao, templates} = this.state

    let name = event.target.name
    let value = event.target.value

    notificacao[name] = value

    if(name === 'modulo'){
      if(templates.list.filter(param => param.tempvar_tipo === value)[0]){
        this.setState({
          variaveisFiltrada:{
            list: templates.list.filter(param => param.tempvar_tipo === value)[0].variaveis
          }
        })
      }
    }

    this.setState({notificacao})
  }

  onEditorStateChange(editorState){
    const {notificacao } = this.state

    notificacao.html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    this.setState({editorState, notificacao})
  } 

  selecionarVar(variavel) {
    let notificacao = { ...this.state.notificacao }

    notificacao.html = notificacao.html.replace('{%}', `%%${variavel.vardoc_variavel}`)

    const contentBlock = htmlToDraft(notificacao.html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    this.setState({ notificacao, editorState })
  }

  updateFieldUnidade(event, value) {
    let midias_pessoa = []

    for (const unidade of value) {
      midias_pessoa = midias_pessoa.concat(unidade.midias)
    }

    this.setState({
      unidadesSelecionadas: value,
      midias_pessoa: {
        list: midias_pessoa
      }
    })
  }

  validaForm(){
    const {notificacao, unidadesSelecionadas} = this.state

    if(notificacao.nome_alerta === '') return false
    if(notificacao.alerta_alerta === '') return false
    if(notificacao.hora_envio === '') return false
    if(notificacao.modulo === '') return false
    if(notificacao.canal === '') return false
    if(notificacao.html === '') return false
    if(notificacao.qtd_dias === '') return false
    if(notificacao.canal === 'EMAIL'){
      if(notificacao.contato_resposta === '') return false
    }
    if(unidadesSelecionadas.length === 0) return false

    return true
  }

  async salvar(){
    const {notificacao, unidadesSelecionadas, formasSelecionadas, situacoesSelecionadas} = this.state
    this.setState({ loadingSalvar: true })

    if(!this.validaForm()){
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: 'Preencha os campos obrigatorios (*).'
      })
      return
    }

    if(notificacao.modulo === 'COBRANCA' && formasSelecionadas.length === 0){
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: 'Por favor selecionar pelo menos uma forma de pagamento.'
      })
      return
    }

    if(notificacao.modulo === 'COBRANCA' && situacoesSelecionadas.length === 0){
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: 'Por favor selecionar pelo menos uma situação de contrato.'
      })
      return
    }

    if(notificacao.canal === 'EMAIL'){
      if (!validacaoEmail(notificacao.contato_resposta)) {
  
        this.handleOpenDialog({
          titulo: "Erro no Cadastro!",
          descricao: 'Email Invalido!'
        })    
        this.setState({ loadingSalvar: false })
        return
      }
    }

    notificacao.unidades = unidadesSelecionadas.map(unidade => { return {unidade_id: unidade.id}})

    if(notificacao.modulo === 'COBRANCA'){
      notificacao.formas = formasSelecionadas.map(forma => {
        return {
          fpcl_id: forma.fpcl_id,
          formpag_id: forma.formpag_id
        }
      })

      for (let i = 0; i < notificacao.formas.length; i++) {
        if(notificacao.formas[i].fpcl_id === '' || !notificacao.formas[i].fpcl_id){
          delete notificacao.formas[i].fpcl_id
        }
      }

      notificacao.situacoesContrato = situacoesSelecionadas.map(situacao => {
        return {
          situacao
        }
      })

    }

    if(notificacao.modulo === 'COBRANCA_UNIDADE'){
      notificacao.formas = formasSelecionadas.map(forma => {
        return {
          fpcl_id: forma.fpcl_id,
          formpag_id: forma.formpag_id
        }
      })

      for (let i = 0; i < notificacao.formas.length; i++) {
        if(notificacao.formas[i].fpcl_id === '' || !notificacao.formas[i].fpcl_id){
          delete notificacao.formas[i].fpcl_id
        }
      }
    }

    try {
      if(notificacao.id !== ''){
        await axios.put(`${process.env.REACT_APP_API_URL}/centralAlerta/${notificacao.id}`, notificacao, this.getToken())
      }else{
        await axios.post(`${process.env.REACT_APP_API_URL}/centralAlerta`, notificacao, this.getToken())
      }

      this.handleOpenDialog({
        titulo: "Parabéns!",
        descricao: 'Cadastro realizado com sucesso'
      })      

      this.backPage()
    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: error.response.data.error
      })
      this.setState({ loadingSalvar: false })
    }
    
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  openModalSelecionarLogo(){
    this.setState({
      openModalSelecionarLogo: true
    })
  }

  updateFieldAtivo(){
    const {notificacao} = this.state
    notificacao.ativo = !notificacao.ativo
    this.setState({notificacao})
  }

  updateFieldForma(event, value){
    this.setState({
      formasSelecionadas: value
    })
  }

  updateFieldSituacao(event, value){
    this.setState({
      situacoesSelecionadas: value
    })
  }

  async enviarNotificacao(){
    const {notificacao} = this.state

    try {
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Enviando Notificações'
        }
      })

      await axios.post(`${process.env.REACT_APP_API_URL}/centralAlerta/enviarNotificao/${notificacao.id}`, {}, this.getToken())
      
      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Notificações Enviada'
        }
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: error.response.data.error
      })
      this.setState({ loadingSalvar: false })
    }

  }

  render() {
    const { notificacao, filtro, variaveisFiltrada, editorState, unidades, unidadesSelecionadas, logo_unidade, situacoesContrato, situacoesSelecionadas} = this.state
    

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Dados da Tela...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom" >
                <Grid item md={11}>
                  <h1 className="titulo">Cadastro de Notificações</h1>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormNotificacao 
                dados={notificacao}
                updateField={e => this.updateField(e)}
                filtro={filtro}
                variaveisFiltrada={variaveisFiltrada.list}
                editorState={editorState}
                onEditorStateChange={e => this.onEditorStateChange(e)}
                selecionarVar={e => this.selecionarVar(e)}
                unidades={unidades.list}
                unidadesSelecionadas={unidadesSelecionadas}
                updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
                openModalSelecionarLogo={e => this.openModalSelecionarLogo()}
                updateFieldAtivo={e => this.updateFieldAtivo()}
                logo_unidade={logo_unidade}
                formasPagamento={this.state.formasPagamento.list}
                formasSelecionadas={this.state.formasSelecionadas}
                updateFieldForma={(event, value) => this.updateFieldForma(event, value)}
                situacoesContrato={situacoesContrato.list}
                situacoesSelecionadas={situacoesSelecionadas}
                updateFieldSituacao={(event, value) => this.updateFieldSituacao(event, value)}
              />
              <Grid spacing={1} container direction="row" alignItems="flex-end" className="mg_top_10">
                {notificacao.id !== '' &&
                  <React.Fragment>
                    <Grid item md={8} xs={12} sm={4}></Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button 
                        fullWidth 
                        color="secondary" 
                        variant="contained"  
                        size="small" 
                        onClick={e => this.enviarNotificacao(e)}
                        disabled={!notificacao.ativo}
                      >
                        Enviar Notificação
                      </Button>
                    </Grid>
                  </React.Fragment>
                }
                {notificacao.id === '' &&
                  <Grid item md={10} xs={12} sm={4}></Grid>
                }
                <Grid item md={2} xs={12} sm={4}>
                  <Button 
                    fullWidth 
                    color="primary" 
                    variant="contained"  
                    size="small" 
                    startIcon={<SaveIcon />} 
                    onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </div>
    )
  }
}