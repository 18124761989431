import React, { Component } from 'react'
import './Movimentacao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
import real from '../../services/real'
import ModalErro from './../../components/modals/Erro'
import ModalConfirmaConciliacoes from './../../components/modals/ConfirmaConciliacoes'

const initalState = {
  movimentacao: {

  },
  movimentacoes: {
    list: []
  },
  movimentacoesFiltrado: {
    list: []
  },
  contaFinanceiras: {
    list: []
  },
  filtro: {
    unidadesnegocio: {},
    data_inicial: '',
    data_final: '',
    movcontfin_conciliado: '',
    movcontfin_tipo: '',
    movcontfin_descricao: ''
  },
  cabecalhoTabela: [
    { id: 'movcontfin_data_hora', numeric: false, disablePadding: false, label: 'Data/Hora' },
    { id: 'contfin_descricao', numeric: false, disablePadding: true, label: 'Conta' },
    { id: 'movcontfin_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'movcontfin_valor', numeric: false, disablePadding: false, label: 'Valor Mov.' }
  ],
  acoesTabela: ['conciliar_mov'],
  unin_tipo: "",
  movimentacoesAConciliar:[],
  saldo_contas_unidades: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  modalConfirmaConciliacaoOpen: false,
  saldoConta: {
    contfin_id: '',
    contfin_ordem: '',
    contfin_descricao: '',
    saldo_conciliado: '',
    saldo_nao_conciliado: '',
    data: '',
    saldo_total: ''
  },
  saldoContaTotal: {
    contfin_id: '',
    contfin_ordem: '',
    contfin_descricao: '',
    saldo_conciliado: '',
    saldo_nao_conciliado: '',
    data: '',
    saldo_total: ''
  }
}

export default class movimentacao extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        filtro: {
          contfin_tipo_conta: "",
          contfin_descricao: "",
          contfin_ativo: "Sim"
        },
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceiras = data.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(param => {
        return {
          contfin_id: param.contfin_id,
          contfin_descricao: param.contfin_descricao,
          contfin_ordem: param.contfin_ordem,
        }
      })

      this.setState({
        contaFinanceiras: {
          list: contaFinanceiras
        }
      })
    }catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/saldoContas`, dados, this.getToken())

      this.setState({
        saldo_contas_unidades: {
          list: data
        }
      })

    }catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
    
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const filtro_movimentacoes = JSON.parse(localStorage.getItem('filtro_movimentacoes'))

      let filtro = {}

      if (filtro_movimentacoes) {
        filtro = filtro_movimentacoes

        this.setState({
          filtro
        })
      }else{
        let dataAtual = new Date()
        dataAtual = moment(dataAtual).format('YYYY-MM-DD')
  
        let data7dias = new Date()
        data7dias.setDate(data7dias.getDate() + 7)
        data7dias = moment(data7dias).format('YYYY-MM-DD')
  
        filtro = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.unin_id
          }),
          data_inicial: dataAtual,
          data_final: data7dias,
          movcontfin_conciliado: '',
          movcontfin_tipo: '',
          movcontfin_descricao: '',
          contfin_id: ''
        }

        this.setState({
          filtro
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira/list`, filtro, this.getToken())

      const movimentacoes = data.list.map(param => {
        return {
          _id: param.movcontfin_id,
          movcontfin_descricao: param.movcontfin_descricao,
          contfin_descricao: param.conta_financeira.contfin_descricao,
          movcontfin_valor: 'R$ ' + real(param.movcontfin_valor),
          movcontfin_tipo: param.movcontfin_tipo,
          movcontfin_conciliado: param.movcontfin_conciliado,
          movcontfin_conciliavel: param.movcontfin_conciliavel,
          movcontfin_data_hora: moment(param.movcontfin_data_hora_conta).format('DD/MM/YYYY HH:mm'),
          contfin_conciliavel: param.conta_financeira.contfin_conciliavel,
          forma_pagto: param.forma_pagto,
          aluno: param.aluno
        }
      })

      if(data.saldoContas[0]){
        this.setState({
          saldoConta: data.saldoContas[0]
        })
      }

      this.setState({
        movimentacoes: {
          list: movimentacoes
        },
        movimentacoesFiltrado: {
          list: movimentacoes
        },
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })

  }

  async filtrar() {
    let filtro = this.state.filtro

    if (filtro.data_final === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data final'
      })
      return
    }

    if (filtro.data_inicial === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data inicial'
      })
      return
    }

    this.setState({
      loadingTable: true
    })

    localStorage.setItem('filtro_movimentacoes', JSON.stringify(filtro));

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira/list`, filtro, this.getToken())

      const movimentacoes = data.list.map(param => {
        return {
          _id: param.movcontfin_id,
          movcontfin_descricao: `${param.movcontfin_descricao} ${param.aluno ? `- ${param.aluno.aluno_matricula} ${param.aluno.fisica.pesf_nome}` : ``}`,
          contfin_descricao: param.conta_financeira.contfin_descricao,
          movcontfin_valor: 'R$ ' + real(param.movcontfin_valor),
          movcontfin_tipo: param.movcontfin_tipo,
          movcontfin_conciliado: param.movcontfin_conciliado,
          movcontfin_conciliavel: param.movcontfin_conciliavel,
          movcontfin_data_hora: moment(param.movcontfin_data_hora_conta).format('DD/MM/YYYY HH:mm'),
          contfin_conciliavel: param.conta_financeira.contfin_conciliavel,
          forma_pagto: param.forma_pagto,
          aluno: param.aluno
        }
      })

      if(data.saldoContas[0]){
        this.setState({
          saldoConta: data.saldoContas[0]
        })
      }

      this.setState({
        movimentacoes: {
          list: movimentacoes
        },
        movimentacoesFiltrado: {
          list: movimentacoes
        }
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  conciliarMov(event){
    let {movimentacoes, movimentacoesAConciliar} = this.state 

    const index = movimentacoes.list.findIndex(param => param._id === event._id)

    movimentacoes.list[index].movcontfin_conciliado = movimentacoes.list[index].movcontfin_conciliado ? false : true

    const indexConc = movimentacoesAConciliar.findIndex(param => param.movcontfin_id === event._id)

    if(indexConc === -1){
      movimentacoesAConciliar.push({
        movcontfin_id : event._id
      })
    }else{
      movimentacoesAConciliar.splice(indexConc, 1)
    }
   
    this.setState({
      movimentacoes,
      movimentacoesAConciliar
    })
  }

  async salvarConciliacoes(abreCadastro){
    const {movimentacoesAConciliar} = this.state

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira/conciliar`, movimentacoesAConciliar, this.getToken())
      
      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Atualização realizada com sucesso.'
      })

      if(abreCadastro){
        this.props.history.push("/movimentacao_financeira/cadastro");
        return;
      }else{
        window.location.reload()
      }
    
    }catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleClose(){
    this.setState({
      modalConfirmaConciliacaoOpen: false
    })
  }

  openCadastro(){
    const {movimentacoesAConciliar} = this.state

    if(movimentacoesAConciliar.length > 0){
      this.setState({
        modalConfirmaConciliacaoOpen: true
      })
    }else{
      this.props.history.push("/movimentacao_financeira/cadastro");
      return;
    }
  }

  confirmarModal(event){
    if(event){
     this.salvarConciliacoes(true)
    }else{
      this.props.history.push("/movimentacao_financeira/cadastro");
      return;
    }
  }

  render() {
    const { filtro, saldoConta, movimentacoesFiltrado, saldo_contas_unidades, saldoContaTotal } = this.state

    saldoContaTotal.saldo_conciliado = saldoConta.saldo_conciliado
    saldoContaTotal.saldo_nao_conciliado = saldoConta.saldo_nao_conciliado

    for (const i in movimentacoesFiltrado.list) {
      if(movimentacoesFiltrado.list[i].movcontfin_tipo === 'DEPOSITO'){
        saldoContaTotal.saldo_conciliado = saldoContaTotal.saldo_conciliado + (movimentacoesFiltrado.list[i].movcontfin_conciliado ? parseFloat(movimentacoesFiltrado.list[i].movcontfin_valor.replace('R$', '').replace('-','').replace('.', '').replace(',', '.')) : 0)
        saldoContaTotal.saldo_nao_conciliado = saldoContaTotal.saldo_nao_conciliado +  (!movimentacoesFiltrado.list[i].movcontfin_conciliado ? parseFloat(movimentacoesFiltrado.list[i].movcontfin_valor.replace('R$', '').replace('-','').replace('.', '').replace(',', '.')) : 0)
      }else if(movimentacoesFiltrado.list[i].movcontfin_tipo === 'SAQUE'){
        saldoContaTotal.saldo_conciliado = saldoContaTotal.saldo_conciliado - (movimentacoesFiltrado.list[i].movcontfin_conciliado ? parseFloat(movimentacoesFiltrado.list[i].movcontfin_valor.replace('R$', '').replace('-','').replace('.', '').replace(',', '.')) : 0)
        saldoContaTotal.saldo_nao_conciliado = saldoContaTotal.saldo_nao_conciliado - (!movimentacoesFiltrado.list[i].movcontfin_conciliado ? parseFloat(movimentacoesFiltrado.list[i].movcontfin_valor.replace('R$', '').replace('-','').replace('.', '').replace(',', '.')) : 0)
      }
    }

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={8} xs={12}>
              <h1 className="titulo">Movimentações de Contas Financeiras</h1>
            </Grid>
            <Grid item md={4} xs={12}>
              <Button onClick={e => this.openCadastro()} className="btnCadastrar" variant="contained" color="primary">
                Cadastrar Movimentação
              </Button>
            </Grid>
          </Grid>
          {saldo_contas_unidades.list.map(unidade => {
            return (
              <React.Fragment>
                <Grid container direction="row" spacing={1} className='mg_top_10'>
                  <Grid item md={10} xs={12}>
                    <h6>{unidade.unin_numero} - {unidade.unin_descricao}</h6>
                  </Grid>
                </Grid>
                 <Grid container direction="row" spacing={1} className="borderBottom">
                    <Grid item md={1} xs={false}></Grid>
                    <Grid item md={10} xs={12}>
                      <table className="lista sempadding borderTable">
                        <thead>
                          <tr className="titulos acompanha">
                            <th></th>
                            {unidade.saldoContas.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                              return (
                                <th key={conta.contfin_id}>{`${conta.contfin_ordem} - ${conta.contfin_descricao}`}</th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Disponível</td>
                            {unidade.saldoContas.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                              return (
                                <td className={conta.saldo_conciliado > 0 ? 'positivo' : 'negativo'} key={conta.contfin_id}>{real(conta.saldo_conciliado)}</td>
                              )
                            })}
                          </tr>
                          <tr>
                            <td>A Conciliar</td>
                            {unidade.saldoContas.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                              return (
                                <td className={conta.saldo_nao_conciliado > 0 ? 'positivo' : 'negativo'} key={conta.contfin_id}>{real(conta.saldo_nao_conciliado)}</td>
                              )
                            })}
                          </tr>
                          <tr>
                            <td>Total</td>
                            {unidade.saldoContas.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                              return (
                                <td className={conta.saldo_total > 0 ? 'positivo' : 'negativo'} key={conta.contfin_id}>{real(conta.saldo_total)}</td>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                    <Grid item md={1} xs={false}></Grid>
                  </Grid>
              </React.Fragment>
            )
          })}
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={3}>
              <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={4} xs={12} sm={3}>
              <TextField className="input" label="Descrição" variant="outlined" size="small" name="movcontfin_descricao" value={filtro.movcontfin_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={1} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Conciliado"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="movcontfin_conciliado"
                value={filtro.movcontfin_conciliado}
                onChangeCapture={(e) => this.updateFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""></option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Conta"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="contfin_id"
                value={filtro.contfin_id}
                onChangeCapture={(e) => this.updateFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=""></option>
                {this.state.contaFinanceiras.list.map(conta => {
                  return (
                    <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={1} xs={12} sm={6}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()}>
                Filtrar
              </Button>
            </Grid>
          </Grid>
          {saldoConta.data !== '' &&
            <Grid container spacing={1} direction="row" className="mg_top_10" >
              <Grid item md={6} xs={12}>
              <h6 style={{textAlign:"right"}}>Saldo até o dia:  {saldoConta.data}</h6>
              </Grid>
              <Grid item md={3} xs={12}>
                <h6 style={{textAlign:"right", color: '#006400'}}>Conciliado: R$ {real(saldoConta.saldo_conciliado)}</h6>
              </Grid>
              <Grid item md={3} xs={12}>
                <h6 style={{textAlign:"right", color: '#FF0000'}}>Não Conciliado: R$ {real(saldoConta.saldo_nao_conciliado)}</h6>
              </Grid>
            </Grid>
          }
          <Grid container spacing={1} direction="row">
            <Grid item md={12} xs={12}>
              <Table 
                urlUpdate="/movimentacao_financeira/cadastro/" 
                urlView="/movimentacao_financeira/view/" 
                headCell={this.state.cabecalhoTabela} 
                rows={movimentacoesFiltrado.list} 
                acoes={this.state.acoesTabela} 
                conciliarMov={e => this.conciliarMov(e)}/>
            </Grid>
          </Grid>
          {saldoConta.data !== '' &&
            <Grid container spacing={1} direction="row" className="mg_top_10" >
              <Grid item md={6} xs={12}>
              <h6 style={{textAlign:"right"}}>Saldo fechamento dia:  {moment(filtro.data_final).format('DD/MM/YYYY')}</h6>
              </Grid>
              <Grid item md={3} xs={12}>
                <h6 style={{textAlign:"right", color: '#006400'}}>Conciliado: R$ {real(saldoContaTotal.saldo_conciliado)}</h6>
              </Grid>
              <Grid item md={3} xs={12}>
                <h6 style={{textAlign:"right", color: '#FF0000'}}>Não Conciliado: R$ {real(saldoContaTotal.saldo_nao_conciliado)}</h6>
              </Grid>
            </Grid>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={9} xs={false} sm={6}> </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.salvarConciliacoes(false)}>
                Salvar Conciliações
              </Button>
            </Grid>
          </Grid>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        <ModalConfirmaConciliacoes open={this.state.modalConfirmaConciliacaoOpen} handleClose={e => this.handleClose(e)} confirmar={e => this.confirmarModal(e)} />
      </div>
    )
  }
}