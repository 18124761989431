import React, { Component } from 'react'
import './ConfigAditivo.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, TextField, Button } from '@material-ui/core'

import { Link } from 'react-router-dom'

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'

const initalState = {
  config_aditivo:{
    ccad_id: '',
		ccad_descricao: '',
		ccad_duracao: '',
		ccad_gera_fin: true,
		ccad_ativo: true,
		cursos_aditivo: []
	},
	curso_aditivo: {
    cursad_id: '',
		cursad_ativo: true,
		cursad_atual_curso_id: '',
		cursad_novo_curso_id: '',
		cursad_atual_cursad_descricao: '',
		cursad_novo_cursad_descricao: '',
	  index: ''
  },
	modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  cursos: {
    list: []
  },
  update: false,
  cabecalhoTabela: [
    { id: 'cursad_atual_cursad_descricao', numeric: false, disablePadding: true, label: 'Curso Atual' },
    { id: 'cursad_novo_cursad_descricao', numeric: false, disablePadding: false, label: 'Novo Curso' },
    { id: 'cursad_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: [],
	permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class ViewCurso extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'config-aditivo-curso')[0]

    this.setState({
      permissoes
    })

		if (!permissoes.perm_visualizar) {
			this.handleOpenDialog({
				titulo: "Opps!",
				descricao: "Você não tem permissão para acessa essa tela!"
			})

			this.backPage()
		}
    

    if (params.configId) {
      try {
        const { data: config_aditivo } = await axios.get(`${process.env.REACT_APP_API_URL}/configAditivo/${params.configId}`, this.getToken())

        this.setState({
          config_aditivo:{
            ccad_id: config_aditivo.ccad_id,
            ccad_descricao: config_aditivo.ccad_descricao,
            ccad_duracao: config_aditivo.ccad_duracao,
            ccad_gera_fin: config_aditivo.ccad_gera_fin,
            ccad_ativo: config_aditivo.ccad_ativo,
            cursos_aditivo: config_aditivo.cursos_aditivo.map((value, key) => {
              return {
                cursad_id: value.cursad_id,
                cursad_ativo: value.cursad_ativo,
                cursad_atual_curso_id: value.cursad_atual_curso_id,
                cursad_novo_curso_id: value.cursad_novo_curso_id,
                cursad_atual_cursad_descricao: value.curso_atual.curso_nome,
                cursad_novo_cursad_descricao: value.curso_novo.curso_nome,
                index: key
              }
            })
          }
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o curso, tente mais tarde"
        })

        this.backPage()
      }
    }
  }

  render() {
    const { config_aditivo } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} xs={12}>
                  <h2 className="titulo">Configuração de Aditivo: {config_aditivo.ccad_descricao} </h2>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Link to={`/config_aditivos/cadastro/${config_aditivo.ccad_id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Editar Configuração
                 	 	</Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20" id="view">
								<Grid item md={3} xs={12} sm={4}>
									<TextField className="input" label="Descrição" variant="outlined" size="small" name="ccad_descricao" value={config_aditivo.ccad_descricao} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={3} xs={12} sm={3}>
									<TextField className="input" label="Duração (Dias)" type='number' variant="outlined" size="small" name="ccad_duracao" value={config_aditivo.ccad_duracao} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={3} xs={12} sm={3}>
									<TextField className="input" label="Gerar Financeiro?" variant="outlined" size="small" name="ccad_gera_fin" value={config_aditivo.ccad_gera_fin ? 'Sim' : 'Não'} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={3} xs={12} sm={3}>
									<TextField className="input" label="Ativo" variant="outlined" size="small" name="ccad_ativo" value={config_aditivo.ccad_ativo ? 'Ativo' : 'Inativo' } InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
              </Grid>
							<br />
							<hr />
							<Grid container direction="row" spacing={1}>
								<Grid item md={12} xs={12} sm={12}>
									<h4>Cursos:</h4>
								</Grid>
							</Grid>
							<Grid container direction="row" alignItems="flex-end" className="mg_top_10">
								<Grid item md={12} xs={12} sm={12}>
									<Table
										editar={e => this.editarItem(e)}
										remove={e => this.removerItem(e)}
										headCell={this.state.cabecalhoTabela}
										rows={config_aditivo.cursos_aditivo}
										acoes={this.state.acoesTabela}
										noRemove={false} 
									/>
								</Grid>
              </Grid>
							<Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9} xs={false}></Grid>
                <Grid item md={3} xs={12}>
                  <Link to={`/config_aditivos`}>
                    <Button className="btnCadastrar" variant="contained" color="secondary">
                      Voltar
                  </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}