import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CurrencyInput from './../mask/CurrencyInput'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Autocomplete from '@material-ui/lab/Autocomplete';

function getModalStyle() {

  return {
  
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    marginLeft: '10%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Vincular Materia Didatico</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={4} xs={12} sm={6}>
              <Autocomplete
                onChange={(event, value) => props.updateFieldMaterial(event, value)}
                freeSolo
                options={props.materiais}
                value={props.material}
                getOptionLabel={option => option.prod_descricao}
                renderInput={(params) => (
                  <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Material*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={props.material.prod_descricao} />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <TextField className="input" label="Codigo" variant="outlined" name="codmatdid_codigo" value={props.codigo.codmatdid_codigo} InputLabelProps={{ shrink: true }} disabled/>
            </Grid>
          </Grid>
          <br />
          {props.possuiFinanceiroCodigo &&
            <React.Fragment >
							<hr />
							<Grid container direction="row" spacing={1}>
								<Grid item md={12} xs={12} sm={12}>
									<h5>Lançamento Financeiro:</h5>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1} className="mg_top_20">
								<Grid item md={2} xs={12} sm={6}>
									<TextField className="input" label="Tipo da Conta" variant="outlined" value={props.conta.contpr_tipo} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={4} xs={12} sm={6}>
									<TextField className="input" label="Plano de Contas" variant="outlined" value={`${props.conta.plancontas_codigo} - ${props.conta.plancontas_descricao}`} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={4} xs={12} sm={6}>
									<TextField className="input" label="Descrição*" variant="outlined" name="contpr_descricao" value={props.conta.contpr_descricao} InputLabelProps={{ shrink: true }} disabled/>
								</Grid>
								<Grid item md={2} xs={12} sm={6}>
									<TextField className="input" label="Valor*" variant="outlined" name="contpr_valortotal" value={props.conta.contpr_valortotal} onChange={(e) => props.updateFieldConta(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={1} className="mg_top_10">
								<Grid item md={12} xs={12} sm={12}>
									<div className='scroll'>
										<table className="lista sempadding borderTable">
											<thead>
												<tr className="titulos acompanha">
													<th>Parcelas</th>
													<th>Valor*</th>
													<th>Vencimento*</th>
													<th>Forma de Pagamento*</th>
													<th>Documento</th>
												</tr>
											</thead>
											<tbody>
												{props.conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
													return (
														<tr key={parcela.index}>
															<td>
																<TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
																<span className="separacaoParcelas">/</span>
																<TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={props.conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) => props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
															</td>
															<td>
																<TextField className="inputValor" variant="outlined" size="small" name="parcont_valorparcela" value={parcela.parcont_valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
															</td>
															<td>
																<TextField type="date" className="inputVencimento" variant="outlined" size="small" name="parcont_datavencimento" value={parcela.parcont_datavencimento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
															</td>
															<td>
																<TextField
																	id="standard-select-currency"
																	select
																	variant="outlined"
																	className="inputFormPagamento"
																	fullWidth
																	size="small"
																	SelectProps={{
																		native: true,
																	}}
																	name="parcont_formapagto_id"
																	value={parcela.parcont_formapagto_id}
																	onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
																	InputLabelProps={{ shrink: true }}
																>
																	<option value="">--Escolha--</option>
																	{props.formasPagamento.map(forma => {
																		return (
																			<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
																		)
																	})}
																</TextField>
															</td>
															<td>
																<TextField className="inputDocumento" variant="outlined" size="small" name="parcont_documento" value={parcela.parcont_documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								</Grid>
							</Grid>
							<br />
							<hr />
							<br />
						</React.Fragment>
					}
					<Grid container direction="row" spacing={1}>
						<Grid item md={12} xs={12} sm={12}>
							<p>Deseja vincular esse código ao aluno?</p>
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={1}>
						<Grid item md={6} xs={6} sm={6}>
							<Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} >Sim</Button>
						</Grid>
						<Grid item md={6} xs={6} sm={6}>
							<Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
						</Grid>
					</Grid>
        </div>
      </Modal>
    </div>
  );
}
