import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button, TextField } from '@material-ui/core'
import moment from 'moment';
import real from './../../services/real'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    marginLeft: '5%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper} id='view'>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className='titulo'>Cupom: {props.cupom.cupom_hash} </h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
            <Grid item md={4} xs={12} sm={6}>
              <TextField className="input" label="Alterado Por" variant="outlined" size="small" name="usuario" value={props.alteracao_selecionada.usuario} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input"
                label="Alterado Em" 
                variant="outlined" 
                size="small" 
                name="hiscup_alterado_em" 
                value={moment(props.alteracao_selecionada.hiscup_alterado_em).format('DD/MM/YYYY')} 
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className='titulo'>Antes: </h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={5} xs={12} sm={6}>
              <TextField className="input" label="Descrição" variant="outlined" size="small" name="cupom_descricao" value={props.alteracao_selecionada.hiscup_antes.cupom_descricao} InputLabelProps={{ shrink: true }} disabled={true}/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Hash" variant="outlined" size="small" name="cupom_hash" value={props.alteracao_selecionada.hiscup_antes.cupom_hash} InputLabelProps={{ shrink: true }} disabled={true}/>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <TextField className="input" label="Tipo do Desconto" variant="outlined" size="small" name="cupom_tipo" value={props.alteracao_selecionada.hiscup_antes.cupom_tipo} InputLabelProps={{ shrink: true }} disabled={true}/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Valor Desconto" 
                variant="outlined" 
                size="small" 
                name="cupom_valor" 
                value={props.alteracao_selecionada.hiscup_antes.cupom_tipo === 'PORCENTAGEM' ? props.alteracao_selecionada.hiscup_antes.cupom_valor + '%' : 'R$ ' + real(props.alteracao_selecionada.hiscup_antes.cupom_valor)} 
                disabled={true}
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input"
                label="Data Inicio" 
                variant="outlined" 
                size="small" 
                name="cupom_data_inicio" 
                value={moment(props.alteracao_selecionada.hiscup_antes.cupom_data_inicio).format('DD/MM/YYYY')} 
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input"
                label="Data Fim" 
                variant="outlined" 
                size="small" 
                name="cupom_data_fim" 
                value={moment(props.alteracao_selecionada.hiscup_antes.cupom_data_fim).format('DD/MM/YYYY')} 
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Ativo?" variant="outlined" size="small" name="cupom_ativo" value={props.alteracao_selecionada.hiscup_antes.cupom_ativo ? 'Sim': 'Não'} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className='titulo'>Depois: </h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={5} xs={12} sm={6}>
              <TextField className="input" label="Descrição" variant="outlined" size="small" name="cupom_descricao" value={props.alteracao_selecionada.hiscup_depois.cupom_descricao} InputLabelProps={{ shrink: true }} disabled={true}/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Hash" variant="outlined" size="small" name="cupom_hash" value={props.alteracao_selecionada.hiscup_depois.cupom_hash} InputLabelProps={{ shrink: true }} disabled={true}/>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <TextField className="input" label="Tipo do Desconto" variant="outlined" size="small" name="cupom_tipo" value={props.alteracao_selecionada.hiscup_depois.cupom_tipo} InputLabelProps={{ shrink: true }} disabled={true}/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Valor Desconto" 
                variant="outlined" 
                size="small" 
                name="cupom_valor" 
                value={props.alteracao_selecionada.hiscup_depois.cupom_tipo === 'PORCENTAGEM' ? props.alteracao_selecionada.hiscup_depois.cupom_valor + '%' : 'R$ ' + real(props.alteracao_selecionada.hiscup_depois.cupom_valor)} 
                disabled={true}
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input"
                label="Data Inicio" 
                variant="outlined" 
                size="small" 
                name="cupom_data_inicio" 
                value={moment(props.alteracao_selecionada.hiscup_depois.cupom_data_inicio).format('DD/MM/YYYY')} 
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input"
                label="Data Fim" 
                variant="outlined" 
                size="small" 
                name="cupom_data_fim" 
                value={moment(props.alteracao_selecionada.hiscup_depois.cupom_data_fim).format('DD/MM/YYYY')} 
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Ativo?" variant="outlined" size="small" name="cupom_ativo" value={props.alteracao_selecionada.hiscup_depois.cupom_ativo ? 'Sim': 'Não'} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={10} xs={12} sm={9}></Grid>
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => props.handleClose()}>Voltar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
