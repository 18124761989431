import React, {Component} from 'react'
import './Templates.scss'
import Header from './Header'

export default class Main extends Component {
	async UNSAFE_componentWillMount() {
 	
	}

	render(){
		return (<React.Fragment>
			<Header {...this.props} />
			<main className="content container-fluid">
				<div className="p-3 mt-3 h-90">
					{this.props.children}
				</div>
			</main>
		</React.Fragment>)
	}
}