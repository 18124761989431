import React, { Component } from 'react'
import './CobrancaUnidade.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button, TextField } from '@material-ui/core'
import FormConfiguracaoCobranca from '../../components/forms/FormConfiguracaoCobranca'
import SaveIcon from '@material-ui/icons/Save';
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'
import real from './../../services/real'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom'

const initalState = {
  configuracao: {
    configcobran_id: "",
    configcobran_descricao: "",
    configcobran_contfin_id: "",
    configcobran_formpag_id: "",
    configcobran_ativo: true,
    servicos: []
  },
  cabecalhoTabela: [
    { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Serviço' },
    { id: 'configprod_dia_vencimento', numeric: true, disablePadding: true, label: 'Dia do Vencimento' },
    { id: 'parametro', numeric: false, disablePadding: true, label: 'Parâmetro' },
    { id: 'valor_form', numeric: true, disablePadding: true, label: 'Valor' },
    { id: 'configprod_habilita_valor_min', numeric: true, disablePadding: true, label: 'Hab. Mínimo' },
    { id: 'valor_min', numeric: true, disablePadding: true, label: 'Valor Mínimo' },
    { id: 'configprod_habilita_valor_max', numeric: true, disablePadding: true, label: 'Hab. Máximo' },
    { id: 'valor_max', numeric: true, disablePadding: true, label: 'Valor Máximo' },
    { id: 'configprod_ativo', numeric: false, disablePadding: true, label: 'Ativo' },
  ],
  acoesTabela: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  tabela_paramentros: [
    {
      parametro: 'FIXO',
      value: 'Fixo'
    },
    {
      parametro: 'ALUNOS',
      value: 'Por Alunos'
    },
    {
      parametro: 'NUM_WHATS',
      value: 'Por Números do Whats'
    }
  ]
}
export default class CadastroAula extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {
    
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cobranca-unidade')[0]

    this.setState({
      permissoes
    })

    try {
      const { data: config } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoCobranca/${params.configId}`, this.getToken())

      this.setState({
        configuracao: {
          configcobran_id: config.configcobran_id,
          configcobran_descricao: config.configcobran_descricao,
          formpag_descricao: config.forma_pagamento.formpag_descricao,
          configcobran_ativo: config.configcobran_ativo,
          configcobran_contfin_id: config.configcobran_contfin_id,
          contfin_descricao: config.conta_financeira.contfin_descricao,
          plancontas_receita_codigo: config.plano_contas_receita.plancontas_codigo,
          plancontas_receita_descricao: config.plano_contas_receita.plancontas_descricao,
          plancontas_despesa_codigo: config.plano_contas_despesa.plancontas_codigo,
          plancontas_despesa_descricao: config.plano_contas_despesa.plancontas_descricao,
          configcobran_dias_pagamento: config.configcobran_dias_pagamento,
          configcobran_juros: config.configcobran_juros,
          configcobran_formpag_id: config.configcobran_formpag_id,
          servicos: config.servicos.map((servico, key) => {
            return {
              prod_id: servico.produto.prod_id,
              prod_descricao: servico.produto.prod_descricao,
              configprod_id: servico.configprod_id,
              configprod_dia_vencimento: servico.configprod_dia_vencimento,
              configprod_parametro: servico.configprod_parametro,
              configprod_valor: real(servico.configprod_valor),
              configprod_ativo: servico.configprod_ativo,
              configprod_habilita_valor_min: servico.configprod_habilita_valor_min,
              configprod_habilita_valor_max: servico.configprod_habilita_valor_max,
              configprod_valor_min: real(servico.configprod_valor_min),
              configprod_valor_max: real(servico.configprod_valor_max),
              index: key
            }
          })
        },
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      this.backPage()
      return
    }

    this.setState({ loading: false })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    this.setState({
      configuracao: {
        configcobran_id: "",
        configcobran_descricao: "",
        configcobran_contfin_id: "",
        configcobran_formpag_id: "",
        configcobran_ativo: true,
        servicos: []
      },
      servico: {
        prod_id: "",
        prod_descricao: "",
        configprod_id: "",
        configprod_dia_vencimento: "",
        configprod_parametro: "",
        configprod_valor: "",
        configprod_ativo: true,
        index: ""
      }
    })
    const self = this
    setTimeout(() => {
      self.props.history.push("/cobranca_unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { configuracao, tabela_paramentros } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div id='view'>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9}>
                  <h2 className="titulo">Configuração de Cobrança</h2>
                </Grid>
                <Grid item md={3}>
                  <Link to={`/cobranca_unidade/cadastro/${configuracao.configcobran_id}`}>
                    <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CreateIcon />}>Editar Configuração</Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={4}>
                  <TextField 
                    className="input" 
                    label="Descrição"
                    variant="outlined" 
                    size="small" 
                    name="configcobran_descricao" 
                    value={configuracao.configcobran_descricao} 
                    disabled 
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="Forma de Pagamento"
                    variant="outlined" 
                    size="small" 
                    name="formpag_descricao" 
                    value={configuracao.formpag_descricao} 
                    disabled 
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="Conta Financeira"
                    variant="outlined" 
                    size="small" 
                    name="contfin_descricao" 
                    value={configuracao.contfin_descricao} 
                    disabled 
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="Plano de Contas"
                    variant="outlined" 
                    size="small" 
                    name="plancontas_receita_descricao" 
                    value={`${configuracao.plancontas_receita_codigo} - ${configuracao.plancontas_receita_descricao}`} 
                    disabled 
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="Plano de Contas"
                    variant="outlined" 
                    size="small" 
                    name="plancontas_despesa_descricao" 
                    value={`${configuracao.plancontas_despesa_codigo} - ${configuracao.plancontas_despesa_descricao}`} 
                    disabled 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="Qtd. Dias Juros*"
                    type="number"
                    variant="outlined" 
                    size="small"
                    name="configcobran_dias_pagamento" 
                    value={configuracao.configcobran_dias_pagamento}
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <TextField 
                    className="input" 
                    label="% Juros*"
                    variant="outlined" 
                    size="small" 
                    name="configcobran_juros" 
                    value={configuracao.configcobran_juros}
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={2}>
                  <TextField 
                    className="input" 
                    label="Ativo"
                    variant="outlined" 
                    size="small" 
                    name="configcobran_ativo" 
                    value={configuracao.configcobran_ativo ? 'Sim' : 'Não'} 
                    disabled 
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <Table
                    headCell={this.state.cabecalhoTabela}
                    rows={configuracao.servicos.map(value => {
                      return {
                        ...value,
                        valor_form: value.configprod_valor, 
                        parametro: tabela_paramentros.filter(param => param.parametro === value.configprod_parametro)[0].value,
                        valor_min: value.configprod_habilita_valor_min ? value.configprod_valor_min : 'N/A',
                        valor_max: value.configprod_habilita_valor_max ? value.configprod_valor_max : 'N/A'
                      }
                    })}
                    acoes={this.state.acoesTabela}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={3}>
                  <Link to={`/cobranca_unidade`}>
                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                  </Link>
                </Grid>
                <Grid item md={9}></Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}