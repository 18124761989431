import React from 'react'

import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormCurso = ({ books, book, dados, updateField, updateFieldBook, 
  updateFieldAtivo, updateFieldCertificado, updateFieldObrigatorio, addItem, updateFieldEAD,
  updateFieldBookAtivo, update, duracoes, duracoesSelecionadas, updateFieldDuracao, updateFieldValorPontoBook }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={4}>
        <TextField className="input" label="Nome Curso*" variant="outlined" size="small" name="curso_nome" value={dados.curso_nome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={4} xs={12} sm={4}>
        <Autocomplete
          multiple
          limitTags={4}
          id="multiple-limit-tags"
          options={duracoes.filter(duracao => {
            if (duracoesSelecionadas.findIndex(i => i === duracao) === -1){
              return duracao;
            }
            return false
          })}
          value={duracoesSelecionadas}
          onChange={(event, value) => updateFieldDuracao(event, value)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Duracões do Curso*" InputLabelProps={{ shrink: true }}/>
          )}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Gerar Certificado?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.curso_gera_certificado}
              />
            }
            size="small"
            label={dados.curso_gera_certificado ? 'Sim' : 'Não'}
            name="curso_gera_certificado"
            onChange={(e) => updateFieldCertificado(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.curso_ativo}
              />
            }
            size="small"
            label={dados.curso_ativo ? 'Ativo' : 'Inativo'}
            name="curso_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">EAD?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.curso_ead}
              />
            }
            size="small"
            label={dados.curso_ead ? 'Sim' : 'Não'}
            name="curso_ead"
            onChange={(e) => updateFieldEAD(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <br />
    <hr />
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <h4>Selecionar Books:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldBook(event, value)}
          freeSolo
          options={books}
          value={book}
          getOptionLabel={option => option.bok_nome}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Book*" variant="outlined" InputLabelProps={{ shrink: true }} value={book.bok_nome} />
          )}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField type='number' className="input" label="Ordem*" variant="outlined" size="small" name="bc_ordem" value={book.bc_ordem} onChange={(e) => updateFieldValorPontoBook(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      {/* <Grid item md={2} xs={12} sm={6}>
        <TextField type='number' className="input" label="Pontos Book*" variant="outlined" size="small" name="bc_vl_ponto" value={book.bc_vl_ponto} onChange={(e) => updateFieldValorPontoBook(e)} InputLabelProps={{ shrink: true }} />
      </Grid> */}
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Book Obrigatório?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={book.bc_obrigatorio}
              />
            }
            size="small"
            label={book.bc_obrigatorio ? 'Sim' : 'Não'}
            name="bc_obrigatorio"
            onChange={(e) => updateFieldObrigatorio(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={book.bc_ativo}
              />
            }
            size="small"
            label={book.bc_ativo ? 'Ativo' : 'Inativo'}
            name="bc_ativo"
            onChange={(e) => updateFieldBookAtivo(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <hr />
  </React.Fragment>
)

export default FormCurso