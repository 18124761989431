import React, { Component } from 'react'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, TextField, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '../../components/Table'
import axios from 'axios'
import moment from 'moment';
import real from '../../services/real'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import { saveAs } from 'file-saver';
import Alert from './../../components/templates/Alert'

const initalState = {
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  unidades: {
    list: []
  },
  financeiro: {
    list: []
  },
  transferencia: {
    tra_contrato_id: '',
    tra_unin_destino_id: '',
    contm_data_vct_matricula: '',
    contm_dia_vct_mensalidade: null,
    formapagto_id: '',
    contfin_id: '',
    tra_observacao: ''
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  modalTransferenciaOpen: false,
  modalTransferencia: {
    mensagem: '',
  },
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  cabecalhoTabelaFinanceiro: [
    { id: 'parcont_datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
    { id: 'contpr_descricao', numeric: false, disablePadding: false, label: 'Descrição' },
    { id: 'formpag_descricao', numeric: false, disablePadding: false, label: 'Forma de Pagto.' },
    { id: 'parcont_situacao', numeric: false, disablePadding: false, label: 'Situação' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: false, label: 'Valor' },
  ],
  acoesTabelaFinanceiro: [],
  cabecalhoTabelaAusencia: [
    { id: 'austemp_data_inicial', numeric: false, disablePadding: true, label: 'Data Inicial', orderBy: 'data_inicial_order' },
    { id: 'austemp_data_final', numeric: false, disablePadding: false, label: 'Data Final', orderBy: 'data_final_order' },
    { id: 'qtd_dias', numeric: false, disablePadding: false, label: 'Dias' },
    { id: 'austemp_motivo', numeric: false, disablePadding: false, label: 'Motivo' }
  ],
  acoesTabelaAusencia: [],
  cabecalhoTabelaCodigo: [
    { id: 'codmatdid_codigo', numeric: false, disablePadding: true, label: 'Código' },
    { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Material' },
    { id: 'codmatdid_criado_em', numeric: false, disablePadding: false, label: 'Gerado em' },
    { id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Atribuido por' },
    { id: 'hiscod_data', numeric: false, disablePadding: false, label: 'Atribuido em' }
  ],
  acoesTabelaCodigo: [],
  codigos: {
    list: []
  },
  totalFinanceiro: 0,
  ausencias: {
    list: []
  },
  disabledButtons: false,
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

function calcDifDias(data_inicial, data_final) {
  const inicio = moment(data_inicial);
  const fim = moment(data_final);
  const duration = moment.duration(fim.diff(inicio));

  // Mostra a diferença em dias
  return duration.asDays();
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function formatarCodigo(str) {
  const parte1 = str.slice(0, 4)
  const parte2 = str.slice(4, 8)
  const parte3 = str.slice(8, 12)

  return `${parte1}-${parte2}-${parte3}`
}

export default class AprovarTransferenciaAluno extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'transferencia_aluno')[0]

    if (permissoes) {
      this.setState({
        permissoes
      })
    }

    try {
      const { match: { params } } = this.props;
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/transferencia-aluno/solicitacao/${params.transfId}`, this.getToken())

      const financeirosAux = []

      const financeiros = data.contrato.aluno.contaspr
      const ausencias = data.contrato.aluno.ausencias

      financeiros.forEach(financeiro => {
        financeiro.parcelas.forEach(parcela => {
          financeirosAux.push({
            _id: parcela.parcont_id,
            contpr_descricao: financeiro.planocontas.plancontas_descricao + ' - Parc: ' + parcela.parcont_num + '/' + financeiro.contpr_numparcela,
            parcont_datavencimento: moment(parcela.parcont_datavencimento).format('DD/MM/YYYY'),
            datavencimentoOrder: moment(parcela.parcont_datavencimento).format('YYYYMMDD'),
            plancontas_descricao: parcela.plancontas_descricao,
            formpag_descricao: parcela.valores_pago[0].forma_pagto.formpag_descricao,
            parcont_situacao: parcela.parcont_situacao,
            parcont_valorparcela: real(parcela.parcont_valorparcela),
            parcont_valorpagamento: parcela.parcont_valorpagamento ? real(parcela.parcont_valorpagamento) : "-",
          })
        })
      })

      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${data.tra_unin_destino_id}`, this.getToken())

      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      data.formapagto_id = ''

      this.setState({
        transferencia: data,
        loading: false,
        financeiro: {
          list: financeirosAux
        },
        ausencias: {
          list: ausencias.map(ausencia => {
            return {
              _id: ausencia.austemp_id,
              austemp_data_inicial: moment(ausencia.austemp_data_inicial).format('DD/MM/YYYY'),
              austemp_data_final: moment(ausencia.austemp_data_final).format('DD/MM/YYYY'),
              austemp_motivo: ausencia.austemp_motivo,
              ativo: ausencia.austemp_ativo,
              qtd_dias: calcDifDias(ausencia.austemp_data_inicial, ausencia.austemp_data_final) + 1,
              data_inicial_order: moment(ausencia.austemp_data_inicial).format('YYYYMMDD'),
              data_final_order: moment(ausencia.austemp_data_final).format('YYYYMMDD'),
            }
          })
        },
        totalFinanceiro: somarValores(financeirosAux.map(financeiro => { return parseFloat(financeiro.parcont_valorparcela.replace('.', '').replace(',', '.')) })),
        codigos: {
          list: data.contrato.aluno.codigos.map(codigo => {
            let hiscod_situacao = ''
            if (codigo.codigo_material.historico[0].hiscod_situacao === 'AGUARDANDO_LIBERACAO') {
              hiscod_situacao = 'Aguardando Liberação'
            } else if (codigo.codigo_material.historico[0].hiscod_situacao === 'LIBERADO_USO') {
              hiscod_situacao = 'Liberado P/ Uso'
            } else if (codigo.codigo_material.historico[0].hiscod_situacao === 'ULTILIZADO') {
              hiscod_situacao = 'Ultilizado'
            } else if (codigo.codigo_material.historico[0].hiscod_situacao === 'AGUARDANDO_APROVACAO_TRANSFERENCIA') {
              hiscod_situacao = 'Aguardando Aprovação Transf.'
            } else if (codigo.codigo_material.historico[0].hiscod_situacao === 'CANCELADO') {
              hiscod_situacao = 'Cancelado'
            }

            return {
              _id: codigo.codigo_material.codmatdid_id,
              codmatdid_codigo: formatarCodigo(codigo.codigo_material.codmatdid_codigo),
              codmatdid_criado_em: moment(codigo.codigo_material.codmatdid_criado_em).format('DD/MM/YYYY HH:mm'),
              hiscod_data: moment(codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1 ].hiscod_data).format('DD/MM/YYYY HH:mm'),
              prod_descricao: codigo.codigo_material.produto.prod_descricao,
              pesf_nome: codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1 ].usuario.pessoa.fisica ? codigo.codigo_material.historico[codigo.codigo_material.historico.length - 1 ].usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração',
              hiscod_situacao
            }
          })
        },
        contasFinanceira: {
          list: contasFinanceira
        },
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })
    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Erro Inesperado!"
      })

      this.backPage()
    }


  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async aceitarTransferencia(resposta, transf) {

    this.setState({ transferenciaModel: transf })

    this.setState({
      modalTransferencia: {
        mensagem: `Deseja realmente ${resposta ? 'ACEITAR' : 'RECUSAR'} a transferencia do aluno?`,
      },
      modalTransferenciaOpen: true,
      statusTransferencia: resposta
    })
  }

  async modalAceitarTransferencia(resposta) {
    if (resposta) {
      this.setState({ disabledButtons: true })
      try {
        const { transferencia } = { ...this.state }
        const status = this.state.statusTransferencia
        const URL = `${process.env.REACT_APP_API_URL}/transferencia-aluno/resolver/${this.state.transferenciaModel.tra_id}/${status ? 'aceitar' : 'recusar'}`

        let data = {
          formapagto_id: transferencia.formapagto_id !== '' ? transferencia.formapagto_id : transferencia.contrato.contm_forma_pagto_id,
          contfin_id: transferencia.contfin_id,
          contm_data_vct_matricula: transferencia.contrato.contm_data_vct_matricula,
          contm_data_vct_primeira_mensalidade: transferencia.contrato.contm_data_vct_primeira_mensalidade
        }

        if(data.formapagto_id === '' || !data.formapagto_id){
          this.handleOpenDialog({
            titulo: "Opps!",
            descricao: "Informar a Forma de Pagamento!"
          })
          this.handleCloseConfirmacao()
          this.setState({ disabledButtons: false })
          return
        }

        if(data.contfin_id === '' || !data.contfin_id){
          delete data.contfin_id
        }

        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Ajustando Contratos...'
          }
        })

        const { data: resposta } = await axios.post(URL, data, this.getToken())

        // for (const parcela of resposta.parcelas_criadas) {
        //   this.setState({
        //     alerta: {
        //       open: true,
        //       severity: 'info',
        //       message: 'Lançando Financerio...'
        //     }
        //   })
        //   if(parcela.forma_pgto === 'boleto'){
        //     this.setState({msg_loading: 'Gerando Boletos...'})
        //     await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }else if (parcela.forma_pgto === 'pix'){
        //     this.setState({msg_loading: 'Gerando Pix...'})
        //     await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: parcela.parcont_id}, this.getToken())
        //   }
        // }

        try {
          const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/transferencia-aluno/documento/${resposta.contrato.contm_id}/${transferencia.tra_id}/imprimir`, {}, this.getToken())

          const dadosImprimir = { path: respImprimir.data }
          const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
          const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
          saveAs(pdfBlob, `Transferencia-Aluno.pdf`); 

          this.handleOpenDialog({
            titulo: 'Parabens.',
            descricao: `Transferencia ${status ? 'ACEITA' : 'RECUSADA'} com sucesso`
          })

          this.handleCloseConfirmacao()
          const self = this

          if (resposta) {
            setTimeout(() => {
              self.props.history.push(`/aluno/view/${resposta.alunoNovo.aluno_matricula}`)
              window.location.reload()
            }, 1000)
          } else {
            setTimeout(() => {
              self.props.history.push(`/home`)
              window.location.reload()
            }, 1000)
          }
        } catch ({response}) {
          this.handleOpenDialog({
            titulo: 'Aviso!',
            descricao: response.data.message
          })

          const self = this
          setTimeout(() => {
            self.props.history.push(`/home`)
            window.location.reload()
          }, 1000)

          // let i = 0
        }

        


      } catch (error) {
        this.setState({ disabledButtons: true })
        this.handleOpenDialog({
          titulo: 'Opps.',
          descricao: 'Algo de errado aconteceu, tente mais tarde'
        })
        this.handleCloseConfirmacao()
      }

    } else {
      this.handleCloseConfirmacao()
    }
  }

  handleCloseConfirmacao() {
    this.setState({
      modalConfirmacaoOpen: false,
      modalTransferenciaOpen: false
    })
  }

  updateField(event){
    const {transferencia} = this.state
    transferencia[event.target.name] = event.target.value

    if(event.target.name === 'formapagto_id'){
      transferencia.contfin_id = ''
    }

    this.setState({transferencia})
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { transferencia } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Transferência...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div id='view'>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h2 className="titulo">Aprovar Transferência de Aluno</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Dados do Aluno:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Nome Aluno" variant="outlined" size="small" value={transferencia.contrato.aluno.fisica.pesf_nome} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="CPF Aluno" variant="outlined" size="small" value={transferencia.contrato.aluno.fisica.pesf_cpf} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Data Nascimento" variant="outlined" size="small" value={moment(transferencia.contrato.aluno.fisica.pesf_datanascimento).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Unidade Origem" variant="outlined" size="small" value={transferencia.origem.unin_numero + ' - ' + transferencia.origem.unin_descricao} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Unidade Destino" variant="outlined" size="small" value={transferencia.destino.unin_numero + ' - ' + transferencia.destino.unin_descricao} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Dados do Contrato:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Curso" variant="outlined" size="small" value={transferencia.contrato.curso.curso_nome} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Data Inicio" variant="outlined" size="small" value={moment(transferencia.contrato.contm_data_inicio_vigencia).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Data Fim" variant="outlined" size="small" value={moment(transferencia.contrato.contm_data_fim_vigencia).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Duração" variant="outlined" size="small" value={transferencia.contrato.contm_duracao + ' Meses'} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={4}>
                  <TextField className="input" label="Material Adquirido" variant="outlined" size="small" value={transferencia.contrato.material_contrato ? transferencia.contrato.material_contrato.material.prod_descricao : 'Sem Material'} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Observação Transferência"
                    className="input"
                    multiline
                    rows={3}
                    size="small"
                    variant="outlined"
                    value={transferencia.tra_observacao}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Ausências Vigentes do Aluno:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabelaAusencia} rows={this.state.ausencias.list} acoes={this.state.acoesTabelaAusencia} />
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Financeiro do Aluno:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabelaFinanceiro} rows={this.state.financeiro.list} acoes={this.state.acoesTabelaFinanceiro} />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Qtd. Parcelas" variant="outlined" size="small" value={this.state.financeiro.list.length} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Valor Total das Parcelas" variant="outlined" size="small" value={real(this.state.totalFinanceiro)} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Forma de Pagamento Mensalidades*"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="formapagto_id"
                    value={transferencia.formapagto_id}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    {this.state.formasPagamento.list.map(forma => {
                      return (
                        <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Conta Financeira"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="contfin_id"
                    value={transferencia.contfin_id}
                    onChangeCapture={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    {this.state.contasFinanceira.list.filter(param => param.contfin_tipo_conta === (transferencia.formapagto_id === 1 ? 'COFRE': 'BANCO')).map(conta => {
                      return (
                        <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Material didático do aluno: </h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabelaCodigo} rows={this.state.codigos.list} acoes={this.state.acoesTabelaCodigo} />
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={6} xs={false} sm={4}></Grid>
                <Grid item md={3} xs={5} sm={4}>
                  <Button
                    variant="contained"
										color="secondary"
                    onClick={() => this.aceitarTransferencia(false, transferencia)}
                    startIcon={<CloseIcon />}
										fullWidth
                  >
                    Recusar
                  </Button>
                </Grid>
                <Grid item md={3} xs={6} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.aceitarTransferencia(true, transferencia)}
                    startIcon={<CheckIcon />}
										fullWidth
                  >
                    Aprovar
                  </Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalConfirmacao open={this.state.modalTransferenciaOpen} dados={this.state.modalTransferencia} handleClose={e => this.handleCloseConfirmacao(e)} confirmar={e => this.modalAceitarTransferencia(e)} disabledButtons={this.state.disabledButtons} />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <Alert 
            open={this.state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={this.state.alerta.severity}
            message={this.state.alerta.message} 
          />
        </Main>
        <Nav />
        <Footer />
      </div>
    )
  }
}