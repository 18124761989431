import React, { Component } from 'react'
import './Unidade.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import CircularProgress from '@material-ui/core/CircularProgress'
import ModalErro from './../../components/modals/Erro'
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'

const initalState = {
  unidades: {
    list: []
  },
  unidadesFiltrado: {
    list: []
  },
  unidadesFisica: {
    list: []
  },
  unidadesJuridica: {
    list: []
  },
  loading: true,
  filtro: {
    num_desc: '',
    descricao: "",
    franqueado: "",
    cidade: "",
    tipo: '',
  },
  unin_tipo: "",
  cabecalho: [
    { id: 'numero', numeric: true, disablePadding: false, label: 'Unidade', edit: false, link: "", tooltip: "" },
    { id: 'descricao', numeric: false, disablePadding: false, label: 'Descricao', edit: false, link: "" },
    { id: 'franqueado', numeric: false, disablePadding: true, label: 'Franqueado', edit: false, link: "", tooltip: "" },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade', edit: false, link: "" },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo Pessoa', edit: false, link: "" },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo', edit: false, link: "" }
  ],
  acoesTabela: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toString().toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Unidades extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'unidade')[0]

    this.setState({
      permissoes
    })


    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      unin_tipo
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    try {
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, dados, this.getToken())

      const unidades = []

      resp.data.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : (b.unin_numero > a.unin_numero) ? -1 : 0).forEach(unidade => {
        const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.end_principal)
        if (unidade.pessoa.pes_tipo === "FISICA") {
          let franqueado = ""
          if (unidade.superior.pessoa.pes_tipo === "FISICA") {
            franqueado = unidade.superior.pessoa.fisica.pesf_nome
          } else {
            franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
          }
          unidades.push({
            _id: unidade.unin_id,
            numero: unidade.unin_numero,
            num_desc: `${unidade.unin_numero} - ${unidade.unin_descricao}`,
            descricao: unidade.unin_descricao,
            franqueado,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: unidade.pessoa.pes_tipo,
            ativo: unidade.unin_status
          })
        } else {
          let franqueado = ""
          if (unidade.superior.pessoa.pes_tipo === "FISICA") {
            franqueado = unidade.superior.pessoa.fisica.pesf_nome
          } else {
            franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
          }
          unidades.push({
            _id: unidade.unin_id,
            num_desc: `${unidade.unin_numero} - ${unidade.unin_descricao}`,
            numero: unidade.unin_numero,
            descricao: unidade.unin_descricao,
            franqueado,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: unidade.pessoa.pes_tipo,
            ativo: unidade.unin_status
          })
        }
      });

      this.setState({
        unidades: {
          list: unidades
        },
        unidadesFiltrado: {
          list: unidades
        },
        loading: false
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const unidades = this.state.unidades.list
    const filtro = this.state.filtro

    const list = unidades.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      unidadesFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, unidadesFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Unidades...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Unidades</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/unidade/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Unidade
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Unidade" variant="outlined" size="small" name="num_desc" value={filtro.num_desc} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Franqueado" variant="outlined" size="small" name="franqueado" value={filtro.franqueado} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Cidade" variant="outlined" size="small" name="cidade" value={filtro.cidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tipo Pessoa*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tipo"
                    value={filtro.tipo}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">Pesquisar</option>
                    <option value="FISICA">
                      Pessoa Física
											</option>
                    <option value="JURIDICA">
                      Pessoa Júridica
											</option>
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table urlView="/unidade/view/" urlUpdate="/unidade/update/" headCell={this.state.cabecalho} rows={unidadesFiltrado.list} acoes={this.state.acoesTabela} />
                  {/* <SimpleTabs headCellPessoaFisica={this.state.cabecalhoTabelaPessoaFisica} headCellPessoaJuridica={this.state.cabecalhoTabelaPessoaJuridica} rowsFisica={this.state.unidadesFisica.list} rowsJuridica={this.state.unidadesJuridica.list} /> */}
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}