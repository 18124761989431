import calculaIdade from './../../services/calculaIdade'
import React, { Component } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';

const cpf = require('node-cpf-cnpj');

let initalState = {
  aluno: {
    _id: "",
    pesf_id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }]
  },
  materiais: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  cursos: {
    list: []
  },
  curso: {
    curso_id: "",
    curso_nome: "",
  },
  unidade: {
    unin_id: "",
    unin_descricao: "",
  },
  tabelaValores: {
    list: []
  },
  tabelaValoresFiltrado: {
    list: []
  },
  planos: {
    list: []
  },
  unidades: {
    list: []
  },
  enableDadosPessoa: true,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosContrato: false,
  enableDadosPessoaResponsavel: false,
  enableDadosEnderecoResponsavel: false,
  enableDadosEmailResponsavel: false,
  enableDadosContatoResponsavel: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  unin_tipo: 'FRANQUEADOR'
}

export default class Cadastroaluno extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'adm-pessoas')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_alterar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    } else {
      const { match: { params } } = this.props;
      if (params.alunoId) {
        try {
          let auxPessoa = {}
          let auxFisica = {}
          const { data: aluno } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/${params.alunoId}`, this.getToken())
          auxPessoa = aluno.fisica.pessoa
          auxFisica = aluno.fisica

          let indexEndereco = 0
          let indexEmail = 0
          let indexContato = 0

          this.setState({
            aluno: {
              pes_id: auxPessoa.pes_id,
              pesf_id: auxFisica.pesf_id,
              pessoaFisica: {
                nome: "",
                cpf: "",
                rg: "",
                rg_orgao_emissor: "",
                rg_uf: "",
                rg_data_emissao: "",
                dataNascimento: ""
              },
              enderecos: auxPessoa.enderecos.map(endereco => {
                indexEndereco++
                return {
                  cep_id: endereco.cep.id,
                  rua: endereco.cep.logradouro,
                  complemento: endereco.end_complemento,
                  numero: endereco.end_numero,
                  bairro: endereco.cep.bairro.nome,
                  cidade: endereco.cep.bairro.cidade.nome,
                  estado: endereco.cep.bairro.cidade.uf.descricao,
                  cep: endereco.cep.cep,
                  principal: endereco.end_principal,
                  tipoEndereco: endereco.end_tipo,
                  index: indexEndereco
                }
              }),
              emails: auxPessoa.emails.map(email => {
                indexEmail++
                return {
                  email: email.ema_email,
                  principal: email.ema_principal,
                  index: indexEmail
                }
              }),
              contatos: auxPessoa.telefones.map(telefone => {
                indexContato++
                return {
                  numero: telefone.tel_numero,
                  tipoContato: telefone.tel_tipo,
                  principal: telefone.tel_principal,
                  index: indexContato
                }
              })
            }
          })
        } catch (error) {
          console.log(error)
        }

      }

      this.setState({ loading: false })
    }


  }

  proximo(atual, prox) {
    const { aluno } = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      const aluno = { ...this.state.aluno }
      const idade = calculaIdade(moment(aluno.pessoaFisica.dataNascimento).format('Y-MM-DD'))

      aluno.pessoaFisica.maiorIdade = idade < 18 ? false : true
      aluno.pessoaFisica.idade = idade
      this.setState({ aluno })

      if (
        aluno.pessoaFisica.nome === "" ||
        aluno.pessoaFisica.cpf === "" ||
        aluno.pessoaFisica.dataNascimento === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosPessoa: false,
        enableDadosEndereco: true
      })

    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      aluno.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEndereco: false,
        enableDadosContato: true
      })

    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      aluno.contatos.forEach(contato => {
        if (contato.numero === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosContato: false,
        enableDadosEmail: true
      })
    }
  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    }
  }

  removeEnderecoAluno(index) {
    const aluno = { ...this.state.aluno }
    const enderecos = aluno.enderecos
    let aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    aluno.enderecos = aux
    this.setState({ aluno })
  }

  removeContatoAluno(index) {
    const aluno = { ...this.state.aluno }
    const contatos = aluno.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    aluno.contatos = aux
    this.setState({ aluno })
  }

  removeEmailAluno(index) {
    const aluno = { ...this.state.aluno }
    const emails = aluno.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    aluno.emails = aux
    this.setState({ aluno })
  }

  addNovoEndereco() {
    const aluno = { ...this.state.aluno }
    const enderecos = aluno.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      comlpemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    aluno.enderecos = enderecos
    this.setState({ aluno })
  }

  addNovoContato() {
    const aluno = { ...this.state.aluno }
    const contatos = aluno.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      complemento: "",
      tipoContato: "COMERCIAL",
      principal: false,
      index
    })

    aluno.contatos = contatos
    this.setState({ aluno })
  }

  addNovoEmail() {
    const aluno = { ...this.state.aluno }
    const emails = aluno.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    aluno.emails = emails
    this.setState({ aluno })
  }

  updateFieldPessoaFisica(event) {
    const aluno = { ...this.state.aluno }
    aluno.pessoaFisica[event.target.name] = event.target.value
    this.setState({ aluno })
  }

  async validarCpf(event) {

    let cpf_cnpj = event.target.value
    const aluno = this.state.aluno

    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {
      aluno.pessoaFisica.cpf = ""
      this.setState({ aluno })

      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
    } else {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, this.getToken())

        if (data) {
          aluno.pessoaFisica.cpf = ""
          this.setState({ aluno })

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          this.setState({
            modalErro: true,
            erro
          })
        } else {
          aluno.pessoaFisica.cpf = cpf_cnpj
          this.setState({ aluno })
        }

      } catch (error) {
        console.log(error)
      }


    }
  }

  updateFieldEndereco(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldContato(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  updateFieldEmail(event, index) {
    const aluno = { ...this.state.aluno }
    aluno.emails[index - 1][event.target.name] = event.target.value
    this.setState({ aluno })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  backPage(timeout = 1000) {

    this.setState({
      aluno: {
        _id: "",
        pesf_id: "",
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: "",
          idade: 0,
          maiorIdade: false
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }]
      }
    })

    const { match: { params } } = this.props;

    const self = this
    setTimeout(() => {
      self.props.history.push(`/aluno/view/${params.alunoId}`);
    }, timeout)
  }

  validateFormEmail() {
    const { aluno } = this.state

    aluno.emails.forEach(email => {
      if (email.email === "") return false
    })

    return true
  }

  async salvar() {
    const aluno = { ...this.state.aluno }

    if (!this.validateFormEmail()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    try {

      let dados = {
        aluno: {
          pesf_id: aluno.pesf_id,
          nome: aluno.pessoaFisica.nome,
          cpf: aluno.pessoaFisica.cpf,
          rg: aluno.pessoaFisica.rg,
          rg_orgao_emissor: aluno.pessoaFisica.rg_orgao_emissor,
          rg_uf: aluno.pessoaFisica.rg_uf,
          rg_data_emissao: aluno.pessoaFisica.rg_data_emissao,
          datanascimento: aluno.pessoaFisica.dataNascimento,
          enderecos: aluno.enderecos.map(end => {
            return {
              cep_id: end.cep_id,
              numero: end.numero,
              complemento: end.complemento === "" ? 'N/A' : end.complemento,
              principal: end.principal,
              tipo: end.tipoEndereco
            }
          }),
          emails: aluno.emails,
          telefones: aluno.contatos.map(cont => {
            return {
              ...cont,
              tipo: cont.tipoContato
            }
          }),
          aprovarCadastro: aluno.aprovarCadastro
        }
      }

      if (dados.aluno.rg === '' || !dados.aluno.rg) {
        delete dados.aluno.rg
      }
      if (dados.aluno.rg_orgao_emissor === '' || !dados.aluno.rg_orgao_emissor) {
        delete dados.aluno.rg_orgao_emissor
      }
      if (dados.aluno.rg_uf === '' || !dados.aluno.rg_uf) {
        delete dados.aluno.rg_uf
      }
      if (dados.aluno.rg_data_emissao === '' || !dados.aluno.rg_data_emissao) {
        delete dados.aluno.rg_data_emissao
      }

      const { match: { params } } = this.props;
      await axios.put(`${process.env.REACT_APP_API_URL}/aluno/trocaPessoa/${params.alunoId}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })

      this.setState({ loadingSalvar: false })
      this.backPage()
    } catch (error) {
      console.log(error)
      // this.setState({ loadingSalvar: false })
      // if (
      //   error.response &&
      //   (error.response.status === 400 || error.response.status === 500)
      // ) {
      //   this.handleOpenDialog({
      //     titulo: 'Ops...',
      //     descricao: error.response.data.message
      //   })
      //   return
      // }

      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: error.response.data.message
      // })

      // this.handleOpenDialog({})
    }
  }

  render() {
    const { aluno } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          } {!this.state.loading &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h2 className="titulo">Cadastro de Alunos</h2>
                </Grid>
                {this.state.loadingSalvar &&
                  <Grid item md={1}>
                    <div>
                      <CircularProgress />
                    </div>
                  </Grid>
                }
              </Grid>
              {this.state.enableDadosPessoa &&
                <div className="formFranqueado">
                  <FormPessoaFisica validarCpfCnpj={e => this.validarCpf(e)} updateField={e => this.updateFieldPessoaFisica(e)} dados={aluno.pessoaFisica} />

                  <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                    <Grid item md={9}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }
              {this.state.enableDadosEndereco &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Endereço</h3>
                    </Grid>
                  </Grid>
                  {aluno.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco removerItem={(index) => this.removeEnderecoAluno(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEndereco(e, endereco.index)} updateField={e => this.updateFieldEndereco(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldEnderecoPrincipal(e, endereco.index)} dados={endereco} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Contato</h3>
                    </Grid>
                  </Grid>
                  {aluno.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato removerItem={(index) => this.removeContatoAluno(index)} updateField={e => this.updateFieldContato(e, contato.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)} dados={contato} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoContato()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEmail &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de E-mail</h3>
                    </Grid>
                  </Grid>
                  {aluno.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail removerItem={(index) => this.removeEmailAluno(index)} updateField={e => this.updateFieldEmail(e, email.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, email.index)} dados={email} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoEmail()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}