import React, { Component, useRef } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import { saveAs } from 'file-saver';
import SignaturePad from "react-signature-canvas";
import Alert from './../../components/templates/Alert'
import Rodape from './../../components/RodapeLogin'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';
import ModalAssinarAusencia from './../../components/modals/AssinarAusencia'

const ColorYellowButton = withStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: Color('#FFFF00').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FFFF00',
    },
  },
}))(Button);

const initalState = {
  codigo: '',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  ausencia: {
    austemp_alterado_em: "",
    austemp_aluno_id: '',
    austemp_assinado: false,
    austemp_ativo: true,
    austemp_codigo: "",
    austemp_criado_em: "",
    austemp_data_final: "",
    austemp_data_inicial: "",
    austemp_id: "",
    austemp_motivo: "",
    austemp_usu_id: "",
    austemp_vigente: false,
    aluno: {
      contrato:{
        resp_fin: false
      }
    }
  },
  aluno: {
    aluno_matricula: '',
    pesf_nome: ''
  },
  openModalAssinarAusencia: false,
  localizacao: '',
  loading: true,
  html_ausencia: '',
  travaBotao: false
}

let sigCanvas = {}

export default class ViewAluno extends Component {

  state = { ...initalState }
  
  getToken() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
      
            this.setState({
              localizacao: `${latitude}, ${longitude}`
            })
          },
          (error) => {
            this.setState({
              localizacao: 'Localização não compartilhada pelo usuário'
            })
          }
        );
      }else {
        this.setState({
          localizacao: 'Localização não compartilhada pelo usuário'
        })
      }

      const { match: { params } } = this.props;

      const { data: ausencia } = await axios.get(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/show/${params.ausencia_id}`, this.getToken())

      this.setState({
        ausencia: {
          ...ausencia
        }
      })

      const { data: aluno } = await axios.get(`${process.env.REACT_APP_API_URL}/alunoAssinatura/${ausencia.aluno.aluno_id}`, this.getToken())

      this.setState({
        aluno: {
          aluno_matricula: aluno.aluno_matricula,
          ...aluno.fisica
        }
      })

      const inicio = moment(ausencia.austemp_data_inicial); // Data de hoje
      const fim = moment(ausencia.austemp_data_final); // Outra data no passado
      const duration = moment.duration(fim.diff(inicio));

      // Mostra a diferença em dias
      const dias = duration.asDays();

      const enderecoPrincipal = ausencia.aluno.contrato.unidade.pessoa.enderecos.filter(param => param.end_principal === true)[0]

      this.setState({
        html_ausencia: ausencia.documento.docassin_html.replace('%%ASSINATURA_UNIDADE_E_CLIENTE', '')
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false
      })
      return
    }

    this.setState({
      loading: false
    })
  }
  
  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 4000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/aluno");
    }, timeout)
    this.setState({
      aula: initalState.aula,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateField(event){
    this.setState({
      codigo: event.target.value
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async assinarAusencia(value, sigCanvas){
    if(value){
     
      try {
        this.setState({travaBotao: true})
        const {codigo, ausencia, localizacao} = this.state
     
        let base64Assinatura = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        
        if(codigo === '') {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o código'
            },
            loadingSalvar: false
          })
          return
        }

        if(sigCanvas.current.isEmpty()) {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor escrever a sua assinatura'
            },
            loadingSalvar: false
          })
          return
        }

        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 10000,
            severity: 'info',
            message: 'Assinando Ausência...'
          }
        })
  
        const { match: { params } } = this.props;
  
        const res = await axios.get("https://api.ipify.org/?format=json");
        
        let dados = {
          ausencia: parseInt(params.ausencia_id),
          codigo,
          base64Assinatura,
          localizacao: localizacao,
          ip: res.data.ip
        }

        const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/assinarAusencia`, dados, this.getToken())
  
        const dadosImprimir = { path: respImprimir.data }
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Ausência-Assinado.pdf`); 
  
        ausencia.austemp_assinado = true
  
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'success',
            message: 'Contrato Assinado com sucesso'
          },
          codigo: '',
          loadingSalvar: false,
          openModalAssinarAusencia: false,
          travaBotao: false,
          ausencia
        })
        
        return
  
      } catch ({response}) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          },
          loadingSalvar: false,
          travaBotao: false,
        })
        return
      }
    }else{
      this.handleCloseModalAssinarAusencia()
    }
  }

  openModalAssinarAusencia(){
    this.setState({
      openModalAssinarAusencia: true
    })
  }

  handleCloseModalAssinarAusencia(){
    this.setState({
      openModalAssinarAusencia: false
    })
  }

  openModalAssinarAusencia(){
    this.setState({
      openModalAssinarAusencia: true
    })
  }

  async baixarAusencia() {
    try {

      let {codigo, ausencia} = this.state

      if(codigo === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o código'
          },
          loadingSalvar: false
        })
        return
      }

     
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/gerarPDFAssinado/${ausencia.austemp_id}`, {codigo}, this.getToken())

      const dados = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `Ausência-Assinado.pdf`);

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false,
        travaBotao: false,
      })
      return
    }
  }

  render() {
    const { loading, ausencia, aluno, html_ausencia, codigo } = this.state

    return (
      <div className="container-boletos" id="app">
        {loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Ausência...</p>
            </div>
          </React.Fragment>
        }
        {!loading &&
          <React.Fragment>
            {!ausencia.austemp_assinado &&
              <React.Fragment>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                    <p>Olá {ausencia.aluno.contrato.resp_fin ? ausencia.aluno.contrato.resp_fin.pesf_nome : aluno.pesf_nome}, segue a baixo {ausencia.aluno.contrato.resp_fin ? ` a ausência temporária do aluno ${aluno.pesf_nome}` : ` a sua ausência temporária`}, ao final do termo realizar a sua assinatura.</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item md={4} xs={12} sm={3}></Grid>
                  <Grid item md={4} xs={12} sm={6} >
                    <Button fullWidth variant="contained" color="primary" onClick={() => this.openModalAssinarAusencia()}>
                      Assinar Ausência Temporária
                    </Button>
                  </Grid>
                  <Grid item md={4} xs={12} sm={3}></Grid>
                </Grid>
              </React.Fragment>
            }
            {ausencia.austemp_assinado &&
              <React.Fragment>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                    <p>Olá {ausencia.aluno.contrato.resp_fin ? ausencia.aluno.contrato.resp_fin.pesf_nome : aluno.pesf_nome}, essa ausência temporária já foi assinada, segue abaixo para o download do termo assinado.</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item md={4} xs={12} sm={4} ></Grid>
                  <Grid item md={2} xs={12} sm={4} >
                    <TextField
                      className="input" 
                      label="Código Recebido por E-mail*" 
                      variant="outlined" 
                      size="small" 
                      name="codigo"
                      value={codigo} 
                      onChange={(e) => this.updateField(e)} 
                      InputLabelProps={{ shrink: true }} 
                      inputProps={{ maxLength: 6 }}
                    />   
                  </Grid>
                  <Grid item md={2} xs={12} sm={4} >
                    <Button style={{marginTop: 7}} fullWidth variant="contained" color="secondary" onClick={() => this.baixarAusencia()}>
                      Baixar Ausência
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </React.Fragment>
        }
        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12} sm={12}>
            <img className='imagemLogo' src="https://system-british-no-delete.s3.amazonaws.com/logo.png" alt="logo" />
          </Grid>
        </Grid>
        <Rodape />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAssinarAusencia
          open={this.state.openModalAssinarAusencia}
          handleClose={e => this.handleCloseModalAssinarAusencia()} 
          ausencia={ausencia}
          aluno={aluno}
          codigo={codigo}
          html_ausencia={html_ausencia}
          updateField={(e) => this.updateField(e)}
          confirmar={(e, sigCanvas) => this.assinarAusencia(e, sigCanvas)}
          baixarContrato={() => this.baixarContrato()}
          travaBotao={this.state.travaBotao}
          sigCanvas={sigCanvas}
        />
      </div>
    )
  }
}