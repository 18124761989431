import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import moeda from '../../../services/moeda'
import Table from '../../../components/Table'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios'

const dataHojeFormatado = value => moment(value).format('DD/MM/YYYY')

const initalState = {
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  unidades: {
    list: []
  },
  transferencia: null,
  loading: true,
  disabledButtons: false
}

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'transferencia_aluno')[0]

    if (permissoes) {
      this.setState({
        permissoes
      })
    }

    if (permissoes) {
      if (permissoes.perm_inserir) {
        const aluno = this.props.aluno
        // Unidades do grupo
        const data = {
          unidadesnegocio: [aluno.contrato.contm_unin_id]
        }

        const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupo`, data, this.getToken())

        this.setState({
          unidades: {
            list: unidades
          },
        })

        // const { match: { params } } = this.props;
        const vTranf = new URLSearchParams(this.props.props.location.search).get("visualizacao_tranf")

        if (vTranf) {
          try {
            await axios.post(`${process.env.REACT_APP_API_URL}/transferencia-aluno/visualizacao/${vTranf}`, {}, this.getToken())
          } catch (error) { }
        }
      }
    }

    const { data: transferencia } = await axios.get(`${process.env.REACT_APP_API_URL}/transferencia-aluno/contrato/${this.props.dadosContrato.contm_id}`, this.getToken())

    this.setState({
      loading: false,
      transferencia
    })

  }


  infosCurso(props) {
    return (
      <div>
        <p> Informações do contrato: </p>
        <p>
          <b>Curso: </b> {props.dadosContrato.curso.curso_nome}<br />
          <b>Data inicio vigência: </b> {dataHojeFormatado(props.dadosContrato.contm_data_inicio_vigencia)}<br />
          <b>Data fim vigência: </b> {dataHojeFormatado(props.dadosContrato.contm_data_fim_vigencia)}<br />
          <b>Duração do contrato: </b> {props.dadosContrato.contm_duracao} meses<br />
          <b>Ativo: </b> {props.dadosContrato.contm_ativo ? 'Sim' : 'Não'}<br />
          <b>Observação: </b> {props.dadosContrato.contm_observacao}<br />
        </p>
      </div>
    )
  }

  tabelaParcelas(parcelasOrig) {
    const headCell = [
      { id: 'parcont_num', numeric: false, disablePadding: true, label: 'Num. Parcela' },
      { id: 'parcont_datavencimento', numeric: false, disablePadding: true, label: 'Vencimento', orderBy: 'datavencimento_order' },
      { id: 'parcont_valorparcela', numeric: false, disablePadding: false, label: 'Valor' }
    ]

    const parcelas = []
    for (const parcela of parcelasOrig) {
      parcelas.push({
        ...parcela,
        parcont_valorparcela: `R$ ${moeda(parcela.parcont_valorparcela)}`,
        parcont_datavencimento: dataHojeFormatado(parcela.parcont_datavencimento),
        datavencimento_order: moment(parcela.parcont_datavencimento).format('YYYYMMDD')
      })
    }

    return (
      <Table headCell={headCell} rows={parcelas} acoes={[]} />
    )
  }

  render() {
    const { permissoes, transferencia } = this.state
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Transferência Aluno...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            {permissoes.perm_inserir &&
              <div>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4> Solicitação de Transferência </h4>
                  </Grid>
                </Grid>
                {
                  (() => {
                    if (
                      transferencia &&
                      transferencia.tra_status === 'AGUARDANDO'
                    ) {
                      return (
                        <div>
                          <p>
                            Aguardando transferencia de aluno <br />
                              Sendo transferido para: <b>{transferencia.destino.unin_numero} - {transferencia.destino.unin_descricao}</b>
                          </p>
                        </div>
                      )
                    }
                  })()
                }

                {(() => {
                  if (
                    transferencia &&
                    transferencia.tra_status === 'AGUARDANDO' &&
                    (this.props.logado && transferencia.tra_unin_destino_id === this.props.logado.unin_id) &&
                    this.props.dadosContrato.status.stcontmat_situacao === 'APROVADO'
                  ) {
                    return (
                      <div>
                        { this.infosCurso(this.props)}

                        <p> Financeiro a ser transferido: </p>
                        <p>
                          {transferencia.contrato.aluno.contaspr.map(conta => {
                            if (conta.parcelas.length) {
                              return (
                                <div>
                                  <p> <b>Conta:</b> {conta.contpr_descricao} </p>
                                  <Grid container spacing={1} direction="row">
                                    <Grid item md={12} xs={12} sm={12}>
                                      Parcelas:
                              </Grid>
                                    <Grid item md={12} xs={12} sm={12}>
                                      {this.tabelaParcelas(conta.parcelas)}
                                    </Grid>
                                  </Grid>
                                </div>
                              )
                            } else {
                              return false
                            }
                          })}
                        </p>
                      </div>
                    )
                  }
                })()}

                {(() => {
                  if (
                    transferencia &&
                    transferencia.tra_status === 'AGUARDANDO' &&
                    (this.props.logado && transferencia.tra_unin_destino_id === this.props.logado.unin_id) &&
                    transferencia.contrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO'
                  ) {
                    return (
                      <div>
                        { this.infosCurso(this.props)}

                        <p> Financeiro a ser gerado: </p>
                        <div className="cardEndereco" id="view">
                          <Grid container direction="row" spacing={1}>
                            <Grid item md={3} xs={12} sm={6}>
                              <TextField className="input" label="Nome" variant="outlined" size="small" value={this.props.dadosContrato.tab_val_cont.tabvalcont_nome} disabled />
                            </Grid>
                            <Grid item md={2} xs={12} sm={6}>
                              <TextField className="input" label="N. de parcelas" variant="outlined" size="small" value={this.props.dadosContrato.tab_val_cont.tabvalcont_num_parc} disabled />
                            </Grid>
                            <Grid item md={2} xs={12} sm={6}>
                              <TextField className="input" label="Matrícula" variant="outlined" size="small" value={moeda(this.props.dadosContrato.tab_val_cont.tabvalcont_val_matri)} disabled />
                            </Grid>
                            <Grid item md={2} xs={12} sm={6}>
                              <TextField className="input" label="Mensalidade" variant="outlined" size="small" value={moeda(this.props.dadosContrato.tab_val_cont.tabvalcont_val_mensal)} disabled />
                            </Grid>
                            <Grid item md={2} xs={12} sm={6}>
                              <TextField className="input" label="Mensalidade Cheia" variant="outlined" size="small" value={moeda(this.props.dadosContrato.tab_val_cont.tabvalcont_val_cheio_mensal)} disabled />
                            </Grid>
                          </Grid>
                        </div>

                        <Grid container direction="row" spacing={1}>
                          <Grid item md={4} xs={12} sm={4}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              label="Forma de Pagamento*"
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name="formapagto_id"
                              value={this.props.transferencia.formapagto_id}
                              onChangeCapture={(e) => this.props.updateFieldTransferencia(e)}
                              InputLabelProps={{ shrink: true }}
                            >
                              <option value="">--Escolha--</option>
                              {this.props.formasPagamento.list.map(forma => {
                                return (
                                  <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                                )
                              })}
                            </TextField>
                          </Grid>
                          <Grid item md={4} xs={12} sm={4}>
                            <TextField type="date" className="input" label="Vencimento Matricula*" variant="outlined" size="small" name="contm_data_vct_matricula" value={this.props.transferencia.contm_data_vct_matricula} onChange={(e) => this.props.updateFieldTransferencia(e)} InputLabelProps={{ shrink: true }} />
                          </Grid>
                          <Grid item md={4} xs={12} sm={4}>
                            <TextField type="number" maxLength={2} className="input" label="Dia do vencimento das Parcelas*" variant="outlined" size="small" name="contm_dia_vct_mensalidade" value={this.props.transferencia.contm_dia_vct_mensalidade} onChange={(e) => this.props.updateFieldTransferencia(e)} InputLabelProps={{ shrink: true }} inputProps={{ max: 30, min: 1 }} />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  }
                })()}

                {(() => {
                  if (
                    transferencia &&
                    transferencia.tra_status === 'AGUARDANDO' &&
                    (this.props.logado && transferencia.tra_unin_destino_id === this.props.logado.unin_id)
                  ) {
                    return (
                      <div>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={2} xs={6} sm={3}>
                            <Button
                              variant="contained"
                              onClick={() => this.props.aceitarTransferencia(false, transferencia)}
                              startIcon={<CloseIcon />}
                            >
                              Recusar
                            </Button>
                          </Grid>
                          <Grid item md={4} xs={6} sm={5}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.props.aceitarTransferencia(true, transferencia)}
                              startIcon={<CheckIcon />}
                            >
                              {this.props.dadosContrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO' ? 'Aceitar e Aprovar' : 'Aceitar'}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  }
                })()}

                {
                  (() => {
                    const fimVigencia = moment(this.props.dadosContrato.contm_data_fim_vigencia)
                    const hoje = moment()

                    if (!transferencia && fimVigencia.diff(hoje, 'days') > 0) {
                      return (
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={4} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Unidade de destino"
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name="tra_unin_destino_id"
                              value={this.props.transferencia.tra_unin_destino_id}
                              onChangeCapture={(e) => this.props.updateFieldTransferencia(e)}
                              InputLabelProps={{
                                shrink: true
                              }}
                            >
                              <option key={0} value=''> Escolha uma unidade </option>
                              {this.state.unidades.list.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : (b.unin_numero > a.unin_numero) ? -1 : 0).map(unidade => {
                                return (
                                  <option key={unidade.unin_id} value={unidade.unin_id}>
                                    {unidade.unin_numero} - {
                                      unidade.pessoa.pes_tipo === 'FISICA' ?
                                        unidade.pessoa.fisica.pesf_nome :
                                        unidade.pessoa.juridica.pesj_nomefantasia
                                    }
                                  </option>
                                )
                              })}
                            </TextField>
                          </Grid>
                          <Grid item md={12} xs={12} sm={12}>
                            <TextField
                              id="outlined-multiline-static"
                              label="Observação"
                              className="input"
                              multiline
                              rows={5}
                              size="small"
                              variant="outlined"
                              name="tra_observacao"
                              value={this.props.transferencia.tra_observacao}
                              onChange={(e) => this.props.updateFieldTransferencia(e)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid item md={12} xs={12} sm={12}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => this.props.solicitarTransferencia()}
                              disabled={this.props.disabledButtonsTransferencia}
                            >
                              Solicitar transferÊncia
                        </Button>
                          </Grid>
                        </Grid>
                      )
                    }
                  })()
                }
                {
                  (() => {
                    const fimVigencia = moment(this.props.dadosContrato.contm_data_fim_vigencia)
                    const hoje = moment()

                    if (!transferencia && fimVigencia.diff(hoje, 'days') <= 0) {
                      return (
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={12} xs={12} sm={12}>
                            <p> Não é possível solicitar uma transferẽncia, pois ultrapassou o fim da vigência do contrato </p>
                          </Grid>
                        </Grid>
                      )
                    }
                  })()
                }

              </div>
            }
            {!permissoes.perm_inserir &&
              <h2 style={{ 'marginTop': '30px' }}>Você não tem permissão para acessar essa tela!</h2>
            }
          </div>
        }

      </React.Fragment>
    )
  }
}