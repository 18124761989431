import React from 'react'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';

const FormConfigAditivo = ({ dados, updateField, updateFieldFinanceiro, updateFieldAtivo, curso_aditivo,
  updateFieldConfig, cursos, updateFieldAtivoConfig, addItem, update }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={4}>
        <TextField className="input" label="Descrição*" variant="outlined" size="small" name="ccad_descricao" value={dados.ccad_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField className="input" label="Dias à Aditivar*" type='number' variant="outlined" size="small" name="ccad_duracao" value={dados.ccad_duracao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={1} xs={false} sm={1}></Grid>
      <Grid item md={2} xs={12} sm={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Gerar Financeiro?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.ccad_gera_fin}
              />
            }
            size="small"
            label={dados.ccad_gera_fin ? 'Sim' : 'Não'}
            name="ccad_gera_fin"
            onChange={(e) => updateFieldFinanceiro(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={dados.ccad_ativo}
              />
            }
            size="small"
            label={dados.ccad_ativo ? 'Ativo' : 'Inativo'}
            name="ccad_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <hr />
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <h4>Selecionar Cursos:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Curso Atual*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="cursad_atual_curso_id"
          value={curso_aditivo.cursad_atual_curso_id}
          onChangeCapture={(e) => updateFieldConfig(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={curso_aditivo.cursad_atual_curso_id !== '' ? curso_aditivo.cursad_atual_curso_id : ''}> {curso_aditivo.cursad_atual_curso_id !== '' ? curso_aditivo.cursad_atual_cursad_descricao : 'Selecionar Curso Atual'}  </option>
          {cursos.filter(param => param.curso_id !== parseInt(curso_aditivo.cursad_atual_curso_id)).map(curso => {
            return (
              <option key={curso.curso_id} value={curso.curso_id}> {curso.curso_nome} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Novo Curso*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="cursad_novo_curso_id"
          value={curso_aditivo.cursad_novo_curso_id}
          onChangeCapture={(e) => updateFieldConfig(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={curso_aditivo.cursad_novo_curso_id !== '' ? curso_aditivo.cursad_novo_curso_id : ''}> {curso_aditivo.cursad_novo_curso_id !== '' ? curso_aditivo.cursad_novo_cursad_descricao : 'Selecionar Curso Atual'}  </option>
          {cursos.filter(param => param.curso_id !== parseInt(curso_aditivo.cursad_novo_curso_id)).map(curso => {
            return (
              <option key={curso.curso_id} value={curso.curso_id}> {curso.curso_nome} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={1} xs={false} sm={1}></Grid>
      <Grid item md={2} xs={12} sm={3}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={curso_aditivo.cursad_ativo}
              />
            }
            size="small"
            label={curso_aditivo.cursad_ativo ? 'Ativo' : 'Inativo'}
            name="cursad_ativo"
            onChange={(e) => updateFieldAtivoConfig(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <hr />
  </React.Fragment>
)

export default FormConfigAditivo