import React, { Component } from 'react'
import './Login.scss'
import './../../main/ultil.scss'
import { Container, Grid, Button, TextField, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import socketClient from './../../components/lib/socket'
import FormLogin from './../../components/forms/FormLogin'
import Rodape from './../../components/RodapeLogin'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import verificaForcaDaSenha from './../../services/verificaForcaSenha'
import Alert from './../../components/templates/Alert'

const initalState = {
  login: {
    login: '',
    senha: ''
  },
  loading: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  senhaExpirada: false,
  dadoNovaSenha: {
    usuario_id: '',
    usuario: '',
    senha: '',
    confirma_senha: '',
    login: ''
  },
  showPassword: false,
  showPasswordRepetir: false,
  nivelSenha: 'Muito Fraca',
  corSenha: 'red',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  token: ''
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

export default class Login extends Component {

  constructor(props) {
    super(props)

    const usuario = JSON.parse(localStorage.getItem('usuario'))

    localStorage.clear();

    if(usuario){
      socketClient({acao: 'user-logoff', values: usuario})
    }

  }
  
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = this.state.token

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async login() {

    if (!validacaoEmail(this.state.login.login)) {
      this.setState({
        alerta: {
          open: false,
          severity: 'error',
          message: 'Email Invalido'
        }
      })
      return
    }

    this.setState({ loading: true })

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/login`, this.state.login)

      if(moment(data.usu_data_expiracao).isBefore(moment())){
        console.log(data)

        this.setState({
          senhaExpirada: true,
          loading: false,
          dadoNovaSenha: {
            login: data.usu_login,
            usuario_id: data.usu_id,
            usuario: data.pessoa.fisica.pesf_nome,
            senha: '',
            confirma_senha: '',
          },
          token: data.token
        })
      }else{
        localStorage.setItem('perfil', JSON.stringify(data.perfil))
        localStorage.setItem('pessoa', JSON.stringify(data.pessoa))
        localStorage.setItem('usu_login', JSON.stringify(data.usu_login))
        localStorage.setItem('unidades_acesso', JSON.stringify(data.unidades_acesso))
        localStorage.setItem('token', data.token)
        localStorage.setItem('caixa', JSON.stringify(data.contasPR ? false : data.caixa))
        localStorage.setItem('contasPR', JSON.stringify(data.contasPR))
  
        const usuario = { ...data }
        delete usuario.unidades_acesso
        delete usuario.pessoa
        delete usuario.perfil
        delete usuario.caixa
  
        socketClient({acao: 'drop-user', values: usuario})
  
        usuario.tempo = moment(new Date()).add(1 , 'minutes')
        localStorage.setItem('usuario', JSON.stringify(usuario))
  
        this.props.history.push("/home");
  
        setTimeout(() => {
          socketClient('',{})
        }, 1000);
  
        this.setState({ loading: false })
        return;

      }


    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        },
        loading: false
      })
      return
    }
  }

  updateField(event) {
    const login = { ...this.state.login }
    login[event.target.name] = event.target.value
    this.setState({ login })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  recuperarSenha() {
    this.props.history.push("/recuperar_senha");
    this.setState({ loading: false })
    return;
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleClickShowPasswordRepetir() {
    this.setState({
      showPasswordRepetir: !this.state.showPasswordRepetir
    })
  }

  updateFieldNovaSenha(event){
    let {dadoNovaSenha} = this.state

    if (event.target.name === 'senha') {
      const resp = verificaForcaDaSenha(event.target.value)
      this.setState({
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      })
    }

    dadoNovaSenha[event.target.name] = event.target.value

    this.setState({
      dadoNovaSenha
    })

  }

  async trocarNovaSenha(){
    let {dadoNovaSenha, nivelSenha} = this.state

    if(dadoNovaSenha.senha === ''){
      this.setState({ 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a senha.'
        }
      })
      return
    }

    if(dadoNovaSenha.confirma_senha === ''){
      this.setState({ 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a confirmação da senha.'
        }
      })
      return
    }

    if(dadoNovaSenha.senha !== dadoNovaSenha.confirma_senha){
      this.setState({ 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'As senhas não conferem.'
        }
      })
      return
    }

    console.log(nivelSenha)

    if(nivelSenha === 'Muito Fraca' || nivelSenha === 'Fraca' || nivelSenha === 'Média'){
      this.setState({ 
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar uma senha mais forte.'
        }
      })
      return
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/trocarSenhaExpirada/${dadoNovaSenha.usuario_id}`, {novaSenha:dadoNovaSenha.senha, repetirSenha: dadoNovaSenha.confirma_senha}, this.getToken())
  
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/login`, {login: dadoNovaSenha.login, senha: dadoNovaSenha.senha})
  
      localStorage.setItem('perfil', JSON.stringify(data.perfil))
      localStorage.setItem('pessoa', JSON.stringify(data.pessoa))
      localStorage.setItem('usu_login', JSON.stringify(data.usu_login))
      localStorage.setItem('unidades_acesso', JSON.stringify(data.unidades_acesso))
      localStorage.setItem('token', data.token)
      localStorage.setItem('caixa', JSON.stringify(data.contasPR ? false : data.caixa))
      localStorage.setItem('contasPR', JSON.stringify(data.contasPR))
  
      const usuario = { ...data }
      delete usuario.unidades_acesso
      delete usuario.pessoa
      delete usuario.perfil
      delete usuario.caixa
  
      socketClient({acao: 'drop-user', values: usuario})
  
      usuario.tempo = moment(new Date()).add(1 , 'minutes')
      localStorage.setItem('usuario', JSON.stringify(usuario))
  
      this.props.history.push("/home");
  
      setTimeout(() => {
        socketClient('',{})
      }, 1000);
  
      this.setState({ loading: false })
      return;
      
    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      })
     
      this.setState({ loading: false })
      return
    }

  }

  render() {
    const { login, dadoNovaSenha, showPassword, showPasswordRepetir } = this.state
    return (
      <React.Fragment>
        <div className="topo">
          <img src="./images/logo.png" alt="Logo British And American" />
        </div>
        <Container>
          <Grid container spacing={0} direction="row" alignItems="center" justify="center">
            <Grid item md={4}>
              <div className="formLogin">
                <form noValidate autoComplete="off">
                  <Container>
                    {!this.state.senhaExpirada &&
                      <React.Fragment>
                        <Grid container direction="row" >
                          <Grid item md={12}>
                            <h1>LOGIN</h1>
                          </Grid>
                        </Grid>
                        <FormLogin dados={login} updateField={e => this.updateField(e)} handleClickShowPassword={e => this.handleClickShowPassword(e)} showPassword={this.state.showPassword} />
                        <Grid container direction="row" className="mg_top_20" alignItems="center" justify="center">
                          <Grid item md={8}>
                            <Button disabled={this.state.loading} variant="contained" className="btn_entrar" size="small" onClick={() => this.login()}>
                              Entrar
                            </Button>
                            {this.state.loading &&
                              <div className="loadingLogin">
                                <CircularProgress />
                              </div>
                            }
                          </Grid>
                        </Grid>
                        <Grid container direction="row" className="mg_top_20" alignItems="center" justify="center">
                          <Grid item md={8}>
                            <Button color="primary" size="small" className="btn_esqueceu" onClick={() => this.recuperarSenha()}>Esqueceu sua senha?</Button>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                    {this.state.senhaExpirada &&
                      <React.Fragment>
                        <Grid container direction="row" >
                          <Grid item md={12}>
                            <h1>Senha Expirada!</h1>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" >
                          <Grid item md={12}>
                            <p>Olá {dadoNovaSenha.usuario}, a sua senha expirou por favor informar uma nova senha.</p>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" >
                          <Grid item md={12} xs={12} sm={12}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-password">Nova Senha</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={dadoNovaSenha.senha}
                                onChange={(e) => this.updateFieldNovaSenha(e)}
                                className="input"
                                size="small"
                                name="senha"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e) => this.handleClickShowPassword(e)}
                                      edge="end"
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                labelWidth={70}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" className="mg_top_20" >
                          <Grid item md={12} xs={12} sm={12}>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-password">Repetir Senha</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPasswordRepetir ? 'text' : 'password'}
                                value={dadoNovaSenha.confirma_senha}
                                onChange={(e) => this.updateFieldNovaSenha(e)}
                                className="input"
                                size="small"
                                name="confirma_senha"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e) => this.handleClickShowPasswordRepetir(e)}
                                      edge="end"
                                    >
                                      {showPasswordRepetir ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                labelWidth={70}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container direction="row">
                          <Grid item md={12}>
                            <span>Nivel Senha: <b style={{ color: this.state.corSenha }}>{this.state.nivelSenha}</b> </span>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" className="mg_top_20">
                          <Grid item md={12}>
                            <p>A sua nova senha irá expirar em <b>{moment().add(3, 'months').format('DD/MM/YYYY')}</b></p>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" className="mg_top_20" alignItems="center" justify="center">
                          <Grid item md={8}>
                            <Button disabled={this.state.loading} variant="contained" className="btn_entrar" size="small" onClick={() => this.trocarNovaSenha()}>
                              Entrar
                            </Button>
                            {this.state.loading &&
                              <div className="loadingLogin">
                                <CircularProgress />
                              </div>
                            }
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  </Container>
                </form>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Rodape />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}