import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import './Produto.scss'
import { Link } from 'react-router-dom'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'

const cabecalhoTabela = [
  { id: 'url', numeric: false, disablePadding: true, label: 'Imagem' },
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'unidadeMedida', numeric: false, disablePadding: false, label: 'Unidade de Medida' },
  { id: 'quantidade', numeric: false, disablePadding: false, label: 'Quantidade' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const acoesTabela = []

const cabecalhoTabelaEstoque = [
  { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade' },
  { id: 'localEstoque', numeric: false, disablePadding: false, label: 'Local Estoque' },
  { id: 'estoqueMinimo', numeric: true, disablePadding: false, label: 'Estoq. Mínimo' },
  { id: 'estoqueMaximo', numeric: true, disablePadding: false, label: 'Estoq. Máximo' },
  { id: 'estoqueAtual', numeric: true, disablePadding: false, label: 'Estoq. Atual' }
]

const acoesTabelaEstoque = []

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const produto = props.produto
  const estoqueProdutos = props.estoqueProdutos

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} id="view">
      <AppBar position="static">
        <Tabs id="viewAluno" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Dados do Produto" {...a11yProps(0)} />
          <Tab label="Estoque do Produto" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="cardEndereco">
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Produto" value={produto.descricao} disabled />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Sub Grupo Produto" value={produto.subGrupoProduto} disabled />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Grupo Produto" value={produto.grupoProduto} disabled />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade Medida" value={produto.unidadeMedida} disabled />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Visibilidade" value={produto.visibilidade} disabled />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Ativo" value={produto.ativo ? 'Ativo' : 'Inativo'} disabled />
            </Grid>
          </Grid>
          {produto.itensCombo.length > 1 &&
            <div className="mg_top_10">
              <h4>Itens do Combo:</h4>
              <Grid container pacing={2} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table
                    headCell={cabecalhoTabela}
                    rows={produto.itensCombo.map(item => {
                      return {
                        _id: item.produtoId,
                        url: item.produtoItem.midia.mid_caminho,
                        descricao: item.produtoItem.prod_descricao,
                        unidadeMedida: item.produtoItem.prod_unidademedida,
                        quantidade: item.quantidade,
                        ativo: item.ativo
                      }
                    })}
                    acoes={acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="cardEndereco">
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={8} xs={12}>
              <h2 className="titulo">Estoque do Produto</h2>
            </Grid>
            <Grid item md={4} xs={12}>
              <Link to={`/estoque/cadastro/${produto._id}`}>
                <Button className="btnCadastrar" variant="contained" color="primary">
                  Cadastrar Estoque-Produto
								</Button>
              </Link>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Produto" value={produto.descricao} disabled />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Sub Grupo Produto" value={produto.subGrupoProduto} disabled />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField fullWidth size="small" variant="outlined" className="input" label="Grupo Produto" value={produto.grupoProduto} disabled />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
            className="mg_top_30"
          >
            <Grid item md={12} xs={12}>
              <Table headCell={cabecalhoTabelaEstoque} rows={estoqueProdutos} acoes={acoesTabelaEstoque} urlUpdate="/estoque/cadastro/" />
            </Grid>
          </Grid>
        </div>
      </TabPanel>
    </div>
  );
}
