import getEndereco from './../../services/api/getEndereco'
import React, { Component } from 'react'
import './Funcionario.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'
import FormContratoFuncionario from '../../components/forms/FormContratoFuncionario'
import FormUsuario from '../../components/forms/FormUsuario'
import FormQuemTrabalha from './FormQuemTrabalha'
import FormProfessor from '../../components/forms/FormProfessor'
import FormProfessorDispHorario from '../../components/forms/FormProfessorDispHorario'
import FormContaBancaria from '../../components/forms/FormContaBancaria'
import FormProfessorVigenciaHorario from '../../components/forms/FormProfessorVigenciaHorario'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';

import ModalErro from './../../components/modals/Erro'
import axios from 'axios'

import verificaForcaDaSenha from './../../services/verificaForcaSenha'

const cpf = require('node-cpf-cnpj');

const initalState = {
  contrato: {
    paraquem: "FRANQUEADOR",
    selects_unidadetrabalho: {
      franqueador: true,
      franqueado: false,
      unidade: false
    }
  },
  franqueados: [],
  funcionario: {
    id: "",
    pesf_id: "",
    dataAdmissao: "",
    dataDemissao: "",
    unidadetrabalho_id: "",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    usuario: {
      login: "",
      senha: "",
      repetirSenha: "",
      usu_perfil_id: ""
    },
    contas: [{
      cb_agencia: "",
      cb_numero: "",
      cb_gerente: "",
      cb_chave_pix: "",
      cb_banco_id: "",
      index: 1,
      banco: {
        banco_id: "",
        banco_nome: "",
        banco_numero: "",
        banco_padraocosif: ""
      }
    }],
    ativo: "",
  },
  funcionarios: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  books: {
    list: []
  },
  book: {
    bok_id: "",
    bok_nome: "",
    bc_obrigatorio: true,
    bc_ativo: true,
    produto: {},
  },
  cargosFiltrado: {
    list: []
  },
  perfil: {
    per_id: "",
    per_descricao: ""
  },
  perfis: {
    list: []
  },
  professor: {
    prof_formacao: "",
    disponibilidades: [],
    aulas: []
  },
  disponibilidade: {
    disp_dia_semana: "",
    disp_hora_inicial: "",
    disp_hora_final: "",
    disp_data_vig_inicio: "",
    disp_data_vig_fim: "",
    index: ""
  },
  bancos: {
    list: []
  },
  enableUpdate: false,
  disableEndereco: true,
  enableBuscaFunc: true,
  enableDadosPessoa: true,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  enableDadosContrato: false,
  enableDadosUsuario: false,
  enableDadosDisponibilidade: false,
  enableDadosVigencia: false,
  enableDadosContaBancaria: false,
  enableDadosDisponibilidadeHorario: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  loadingSalvar: false,
  cabecalhoTabela: [
    { id: 'disp_dia_semana', numeric: false, disablePadding: true, label: 'Dia da Semana' },
    { id: 'disp_hora_inicial', numeric: false, disablePadding: false, label: 'Hora Inicial' },
    { id: 'disp_hora_final', numeric: false, disablePadding: false, label: 'Hora Final' }
  ],
  acoesTabela: ['editar', 'remove'],
  update: false,
  aulasSeleciondas: [],
  aulasSeleciondasFiltradas: [],
  aulas: {
    list: []
  },
  aulasFiltrada: {
    list: []
  },
  nomeAulas: [],
  showPassword: false,
  showPasswordRepetir: false,
  searchResp:{
    cpf: ''
  }
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function formatarTelefone(str) {
  if (str.length === 11) {
    return str.replace(/(\d{2})?(\d{4})?(\d{5})/, "($1) $2-$3")
  } else if (str.length === 10) {
    return str.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3")
  }
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

function removeItemAll(arr, value, campo) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i][campo] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export default class CadastroFuncionario extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'funcionario')[0]

    this.setState({
      permissoes
    })

    if (params.funcionarioId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    this.setState({
      contrato: {
        paraquem: "FRANQUEADOR",
        selects_unidadetrabalho: {
          franqueador: true,
          franqueado: false,
          unidade: false
        }
      },
      funcionario: {
        id: "",
        pesf_id: "",
        dataAdmissao: "",
        dataDemissao: "",
        unidadetrabalho_id: "",
        contrato: {
          departamento: "",
          idDepartamento: "",
          cargo: "",
          idCargo: "",
          funcao: "",
          idFuncao: "",
          dataInicial: "",
          dataFinal: "",
          observacao: ""
        },
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        usuario: {
          login: "",
          senha: "",
          repetirSenha: "",
          usu_perfil_id: ""
        },
        contas: [{
          cb_agencia: "",
          cb_numero: "",
          cb_gerente: "",
          cb_chave_pix: "",
          cb_banco_id: "",
          index: 1,
          banco: {
            banco_id: "",
            banco_nome: "",
            banco_numero: "",
            banco_padraocosif: ""
          }
        }],
        ativo: "",
      },
    })

    if (params.funcionarioId) {
      try {
        this.setState({ enableUpdate: true })
        const { data: respFuncionario } = await axios.get(`${process.env.REACT_APP_API_URL}/funcionarios/${params.funcionarioId}`, this.getToken())

        const auxEnderecos = respFuncionario.fisica.pessoa.enderecos
        const auxContatos = respFuncionario.fisica.pessoa.telefones
        const auxEmails = respFuncionario.fisica.pessoa.emails
        const auxContas = respFuncionario.fisica.pessoa.contas

        let enderecos = []
        let contatos = []
        let emails = []
        let contas = []

        for (let i = 0; i < auxEnderecos.length; i++) {
          enderecos.push({
            end_id: auxEnderecos[i].end_id,
            cep_id: auxEnderecos[i].end_cep_id,
            complemento: auxEnderecos[i].end_complemento,
            rua: auxEnderecos[i].end_logradouro,
            numero: auxEnderecos[i].end_numero,
            bairro: auxEnderecos[i].end_bairro,
            cidade: auxEnderecos[i].end_cidade,
            estado: auxEnderecos[i].end_estado,
            cep: formatarCEP(auxEnderecos[i].end_cep),
            principal: auxEnderecos[i].end_principal,
            tipoEndereco: auxEnderecos[i].end_tipo,
            index: i + 1
          })
        }

        for (let i = 0; i < auxContatos.length; i++) {
          contatos.push({
            tel_id: auxContatos[i].tel_id,
            numero: auxContatos[i].tel_numero,
            tipoContato: auxContatos[i].tel_tipo,
            principal: auxContatos[i].tel_principal,
            index: i + 1
          })
        }

        for (let i = 0; i < auxEmails.length; i++) {
          emails.push({
            ema_id: auxEmails[i].ema_id,
            email: auxEmails[i].ema_email,
            principal: auxEmails[i].ema_principal,
            index: i + 1
          })
        }

        for (let i = 0; i < auxContas.length; i++) {
          contas.push({
            cb_id: auxContas[i].cb_id,
            cb_agencia: auxContas[i].cb_agencia,
            cb_numero: auxContas[i].cb_numero,
            cb_gerente: auxContas[i].cb_gerente ? auxContas[i].cb_gerente : "",
            cb_chave_pix: auxContas[i].cb_chave_pix ? auxContas[i].cb_chave_pix : "",
            cb_banco_id: auxContas[i].cb_banco_id,
            index: i + 1,
            banco: {
              banco_id: auxContas[i].banco.banco_id,
              banco_nome: auxContas[i].banco.banco_nome,
              banco_numero: auxContas[i].banco.banco_numero,
              banco_padraocosif: auxContas[i].banco.banco_padraocosif
            }
          })
        }

        let professor = {
          prof_formacao: "",
          disponibilidades: [],
          aulas: []
        }

        let aulasSeleciondas = []

        if (respFuncionario.contrato.funcao.cargo.car_slug === "professor") {
          if(respFuncionario.professor){
            const { data: respProfessor } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/${respFuncionario.professor.prof_id}`, this.getToken())
  
            const disponibilidades = []
            for (let i = 0; i < respProfessor.disponibilidades.length; i++) {
              disponibilidades.push({
                disp_dia_semana: respProfessor.disponibilidades[i].disp_dia_semana,
                disp_hora_inicial: respProfessor.disponibilidades[i].disp_hora_inicial,
                disp_hora_final: respProfessor.disponibilidades[i].disp_hora_final,
                disp_data_vig_inicio: respProfessor.disponibilidades[i].disp_data_vig_inicio ? moment(respProfessor.disponibilidades[i].disp_data_vig_inicio).format('YYYY-MM-DD') : "",
                disp_data_vig_fim: respProfessor.disponibilidades[i].disp_data_vig_fim ? moment(respProfessor.disponibilidades[i].disp_data_vig_fim).format('YYYY-MM-DD') : "",
                index: i + 1
              })
            }
  
            professor = {
              prof_id: respProfessor.prof_id,
              prof_formacao: respProfessor.prof_formacao,
              disponibilidades,
              aulas: respProfessor.aulas
            }
  
            aulasSeleciondas = respProfessor.aulas.map(aula => {
              return {
                aula_id: aula.aula_id,
                aula_nome: aula.aula_nome,
                aula_tipo: aula.aula_tipo,
                aula_tipo_horario: aula.aula_tipo_horario,
                aula_duracao: aula.aula_duracao,
                book: aula.book,
                ativo: aula.aula_ativo
              }
            })
          }
        }

        if (respFuncionario.contrato.unidadetrabalho.unin_tipo === 'FRANQUEADOR') {
          this.setState({
            contrato: {
              paraquem: respFuncionario.contrato.unidadetrabalho.unin_tipo,
              selects_unidadetrabalho: {
                franqueador: true,
                franqueado: false,
                unidade: false
              }
            }
          })
        } else if (respFuncionario.contrato.unidadetrabalho.unin_tipo === 'FRANQUEADO') {
          this.setState({
            contrato: {
              paraquem: respFuncionario.contrato.unidadetrabalho.unin_tipo,
              selects_unidadetrabalho: {
                franqueador: false,
                franqueado: true,
                unidade: false
              }
            }
          })
        } else if (respFuncionario.contrato.unidadetrabalho.unin_tipo === 'UNIDADE') {
          this.setState({
            contrato: {
              paraquem: respFuncionario.contrato.unidadetrabalho.unin_tipo,
              selects_unidadetrabalho: {
                franqueador: false,
                franqueado: false,
                unidade: true
              }
            }
          })
        }

        this.setState({
          funcionario: {
            id: respFuncionario.fun_id,
            pesf_id: respFuncionario.fisica.pesf_id,
            dataAdmissao: moment(respFuncionario.fun_data_admissao).format('YYYY-MM-DD'),
            dataDemissao: respFuncionario.fun_data_demissao ? moment(respFuncionario.fun_data_demissao).format('YYYY-MM-DD') : "",
            unidadetrabalho_id: respFuncionario.contrato.unidadetrabalho.unin_id,
            contrato: {
              contt_id: respFuncionario.contrato.contt_id,
              departamento: respFuncionario.contrato.funcao.cargo.departamento.dep_descricao,
              idDepartamento: respFuncionario.contrato.funcao.cargo.dep_id,
              cargo: respFuncionario.contrato.funcao.cargo.car_descricao,
              idCargo: respFuncionario.contrato.funcao.cargo.car_id,
              funcao: respFuncionario.contrato.funcao.func_descricao,
              idFuncao: respFuncionario.contrato.funcao.func_id,
              dataInicial: moment(respFuncionario.contrato.contt_datainicio).format('YYYY-MM-DD'),
              dataFinal: moment(respFuncionario.contrato.contt_datafinal).format('YYYY-MM-DD'),
              observacao: respFuncionario.contrato.contt_observacao
            },
            pessoaFisica: {
              nome: respFuncionario.fisica.pesf_nome,
              cpf: cpf.format(respFuncionario.fisica.pesf_cpf),
              rg: respFuncionario.fisica.pesf_rg,
              rg_orgao_emissor: respFuncionario.fisica.pesf_rg_orgao_emissor,
              rg_uf: respFuncionario.fisica.pesf_rg_uf,
              rg_data_emissao: respFuncionario.fisica.pesf_rg_data_emissao ? moment(respFuncionario.fisica.pesf_rg_data_emissao).format('YYYY-MM-DD') : "",
              dataNascimento: moment(respFuncionario.fisica.pesf_datanascimento).format('YYYY-MM-DD')
            },
            enderecos,
            emails,
            contatos,
            ativo: respFuncionario.fun_status,
            contas: contas.length > 0 ? contas : initalState.funcionario.contas,
            usuario: {
              login: respFuncionario.fisica.pessoa.usuario ? respFuncionario.fisica.pessoa.usuario.usu_login : "",
              senha: "",
              repetirSenha: "",
              usu_perfil_id: respFuncionario.fisica.pessoa.usuario && respFuncionario.fisica.pessoa.usuario.perfil ? respFuncionario.fisica.pessoa.usuario.perfil.per_id : "",
              usu_id: respFuncionario.fisica.pessoa.usuario ? respFuncionario.fisica.pessoa.usuario.usu_id : ""
            },
          },
          professor,
          aulasSeleciondas,
          perfil: {
            per_id: respFuncionario.fisica.pessoa.usuario && respFuncionario.fisica.pessoa.usuario.perfil ? respFuncionario.fisica.pessoa.usuario.perfil.per_id : '',
            per_descricao: respFuncionario.fisica.pessoa.usuario && respFuncionario.fisica.pessoa.usuario.perfil ? respFuncionario.fisica.pessoa.usuario.perfil.per_descricao : ''
          },
          enableBuscaFunc: false
        })

      } catch ({response}) {
        // console.log(response.data.message)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: response.data.message
        })
  
        this.backPage()
      }

    } else {

      this.updateParaquem({
        target: { value: null }
      })
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/departamentos/list`, {}, this.getToken())

      this.setState({
        departamentos: {
          list: data.filter(param => param.dep_ativo === true)
        }
      })
    } catch ({response}) {
      // console.log(response.data.message)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })

      this.backPage()
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aula`, this.getToken())

      const aulas = []

      data.forEach(aula => {
        aulas.push({
          aula_id: aula.aula_id,
          aula_nome: aula.aula_nome,
          aula_tipo: aula.aula_tipo,
          aula_tipo_horario: aula.aula_tipo_horario,
          aula_duracao: aula.aula_duracao,
          book: aula.book,
          ativo: aula.aula_ativo,
        })
      })

      this.setState({
        aulas: {
          list: aulas
        },
        aulasFiltrada: {
          list: aulas
        },
        loading: false
      })

    } catch ({response}) {
      // console.log(response.data.message)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })

      this.backPage()
    }

    try {
      const { data: bancos } = await axios.get(`${process.env.REACT_APP_API_URL}/bancos`, this.getToken())

      this.setState({
        bancos: {
          list: bancos
        }
      })

    } catch ({response}) {
      // console.log(response.data.message)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })

      this.backPage()
    }

    try {
      const { data: books } = await axios.get(`${process.env.REACT_APP_API_URL}/book`, this.getToken())

      this.setState({
        books: {
          list: books
        }
      })

    } catch ({response}) {
      // console.log(response.data.message)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })

      this.backPage()
    }

  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFieldPessoaFisica(event) {
    const funcionario = { ...this.state.funcionario }
    funcionario.pessoaFisica[event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldEndereco(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldEmail(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.emails[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldContato(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  formataTelefone(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const funcionario = { ...this.state.funcionario }
    funcionario.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    this.setState({ funcionario })
  }

  async updateFieldContrato(event) {
    if (!event.target === undefined) return false
    const funcionario = { ...this.state.funcionario }

    if (event.target && event.target.name === "idDepartamento") {
      if (funcionario.contrato.departamento === event.target.value) return false

      try {
        funcionario.contrato.idDepartamento = event.target.value
        funcionario.contrato.departamento = this.state.departamentos.list.filter(u => u.dep_id === parseInt(event.target.value))[0].dep_descricao

        for (const dep of this.state.departamentos.list) {
          if (dep.dep_id === event.target.value)
            funcionario.contrato.departamento = dep[0].dep_descricao
        }

        this.setState({
          funcionario,
          cargosFiltrado: {
            list: (await axios.get(`${process.env.REACT_APP_API_URL}/cargos/departamento/${event.target.value}`, this.getToken())).data
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (event.target && event.target.name === "idCargo") {
      if (funcionario.contrato.cargo === event.target.value) return false

      try {
        funcionario.contrato.idCargo = event.target.value
        funcionario.contrato.cargo = this.state.cargosFiltrado.list.filter(u => u.car_id === parseInt(event.target.value))[0].car_descricao

        this.setState({
          funcionario,
          funcaosFiltrado: {
            list: (await axios.get(`${process.env.REACT_APP_API_URL}/funcoes/cargo/${event.target.value}`, this.getToken())).data
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    if (event.target && event.target.name === "idFuncao") {
      if (funcionario.contrato.funcao === event.target.value) return false

      funcionario.contrato.idFuncao = event.target.value
      funcionario.contrato.funcao = this.state.funcaosFiltrado.list.filter(u => u.func_id === parseInt(event.target.value))[0].func_descricao
      this.setState({ funcionario })
    }

    if (event.target && event.target.name === "dataInicial") {
      funcionario.contrato.dataInicial = event.target.value
      this.setState({ funcionario })
    }

    if (event.target && event.target.name === "dataFinal") {
      funcionario.contrato.dataFinal = event.target.value
      this.setState({ funcionario })
    }

    if (event.target && event.target.name === "observacao") {
      funcionario.contrato.observacao = event.target.value
      this.setState({ funcionario })
    }
  }

  updateField(event) {
    const funcionario = { ...this.state.funcionario }
    funcionario[event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldEnderecoPrincipal(event, index) {
    const funcionario = { ...this.state.funcionario }
    const indexAux = funcionario.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      funcionario.enderecos[indexAux].principal = false
    }

    funcionario.enderecos[index - 1].principal = !funcionario.enderecos[index - 1].principal
    this.setState({ funcionario })
  }

  updateFieldContatoPrincipal(event, index) {
    const funcionario = { ...this.state.funcionario }
    const indexAux = funcionario.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      funcionario.contatos[indexAux].principal = false
    }

    funcionario.contatos[index - 1].principal = !funcionario.contatos[index - 1].principal
    this.setState({ funcionario })
  }

  updateFieldEmailPrincipal(event, index) {
    const funcionario = { ...this.state.funcionario }
    const indexAux = funcionario.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      funcionario.emails[indexAux].principal = false
    }

    funcionario.emails[index - 1].principal = !funcionario.emails[index - 1].principal

    this.setState({ funcionario })
  }

  async updateFieldUsuario(event) {
    const funcionario = { ...this.state.funcionario }

    funcionario.usuario[event.target.name] = event.target.value

    if (event.target.name === 'senha') {
      const resp = await verificaForcaDaSenha(event.target.value)

      this.setState({
        nivelSenha: resp.forcaSenha,
        corSenha: resp.backgroundColor
      })
    }

    this.setState({ funcionario })
  }

  async validarCpfCnpj(event) {

    let cpf_cnpj = event.target.value
    const funcionario = this.state.funcionario

    cpf_cnpj = cpf.format(cpf_cnpj)

    if (!cpf.isValid(cpf_cnpj)) {

      funcionario.pessoaFisica.cpf = ""
      this.setState({ funcionario })

      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
    } else {
      funcionario.pessoaFisica.cpf = cpf_cnpj
      this.setState({ funcionario })
    }
  }

  async pegaEndereco(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const funcionario = { ...this.state.funcionario }
      const enderecos = funcionario.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      funcionario.enderecos[index - 1] = endereco
      this.setState({ funcionario })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        funcionario.enderecos[index - 1] = endereco
        this.setState({ funcionario })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        funcionario.enderecos[index - 1] = endereco
        this.setState({ funcionario })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  addNovoEndereco() {
    const funcionario = { ...this.state.funcionario }
    const enderecos = funcionario.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      comlpemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    funcionario.enderecos = enderecos
    this.setState({ funcionario })
  }

  addNovoContato() {
    const funcionario = { ...this.state.funcionario }
    const contatos = funcionario.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      complemento: "",
      tipoContato: "COMERCIAL",
      principal: false,
      index
    })

    funcionario.contatos = contatos
    this.setState({ funcionario })
  }

  addNovoEmail() {
    const funcionario = { ...this.state.funcionario }
    const emails = funcionario.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    funcionario.emails = emails
    this.setState({ funcionario })
  }

  async buscaPerfis() {
    const funcionario = { ...this.state.funcionario }
    const selects_unidadetrabalho = { ...this.state.contrato.selects_unidadetrabalho }

    let tipoUnidade = null

    if (selects_unidadetrabalho.franqueador) tipoUnidade = 'FRANQUEADOR'
    if (selects_unidadetrabalho.franqueado) tipoUnidade = 'FRANQUEADO'
    if (selects_unidadetrabalho.unidade) tipoUnidade = 'UNIDADE'

    let params = `visibilidade=${tipoUnidade}`

    if (funcionario.unidadetrabalho_id) {
      params += `&unidadetrabalho_id=${parseInt(funcionario.unidadetrabalho_id)}`
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?${params}`, this.getToken())

      this.setState(
        {
          perfis: {
            list: data
          }
        }
      )
    } catch (error) {
      console.log(error)
    }


  }

  proximo(atual, prox) {
    const { funcionario } = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      if (
        funcionario.pessoaFisica.nome === "" ||
        funcionario.pessoaFisica.cpf === "" ||
        funcionario.pessoaFisica.rg === "" ||
        funcionario.pessoaFisica.dataNascimento === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosPessoa: false,
        enableDadosEndereco: true
      })

    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      funcionario.enderecos.forEach(endereco => {
        if (endereco.cep === "") valido = false
        if (endereco.numero === "") valido = false
        if (endereco.rua === "") valido = false
        if (endereco.bairro === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEndereco: false,
        enableDadosContato: true
      })

    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      funcionario.contatos.forEach(contato => {
        if (contato.numero === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosContato: false,
        enableDadosEmail: true
      })
    } else if (atual === "enableDadosEmail" && prox === "enableDadosContrato") {
      let valido = true
      funcionario.emails.forEach(email => {
        if (email.email === "") valido = false
      })

      if (!valido) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosEmail: false,
        enableDadosContrato: true
      })

    } else if (atual === "enableDadosContrato" && prox === "enableDadosContaBancaria") {

      if (
        funcionario.contrato.cargo === "" ||
        funcionario.contrato.dataInicial === ""
      ) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.buscaPerfis()

      this.setState({
        enableDadosContrato: false,
        enableDadosContaBancaria: true,
      })
    } else if (atual === "enableDadosContaBancaria" && prox === "enableDadosDisponibilidade") {
      this.setState({
        enableDadosContaBancaria: false,
        enableDadosDisponibilidade: true
      })
    } else if (atual === "enableDadosDisponibilidade" && prox === "enableDadosDisponibilidadeHorario") {

      const {professor} = this.state

      if(professor.prof_formacao === ''){
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        return
      }

      this.setState({
        enableDadosDisponibilidade: false,
        enableDadosDisponibilidadeHorario: true,
      })
    } else if (atual === "enableDadosDisponibilidadeHorario" && prox === "enableDadosVigencia") {

      this.setState({
        enableDadosDisponibilidadeHorario: false,
        enableDadosVigencia: true,
      })
    } else if (atual === "enableDadosVigencia" && prox === "enableDadosUsuario") {

      this.setState({
        enableDadosVigencia: false,
        enableDadosUsuario: true,
      })
    } else if (atual === "enableDadosContaBancaria" && prox === "enableDadosUsuario") {

      this.setState({
        enableDadosContaBancaria: false,
        enableDadosUsuario: true
      })
    }
  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosContrato") {
      this.setState({
        enableDadosEmail: true,
        enableDadosContrato: false,
      })
    } else if (anterior === "enableDadosContrato" && atual === "enableDadosContaBancaria") {
      this.setState({
        enableDadosContrato: true,
        enableDadosContaBancaria: false,
      })
    } else if (anterior === "enableDadosContaBancaria" && atual === "enableDadosUsuario") {
      this.setState({
        enableDadosContaBancaria: true,
        enableDadosUsuario: false,
      })
    } else if (anterior === "enableDadosContaBancaria" && atual === "enableDadosDisponibilidade") {
      this.setState({
        enableDadosContaBancaria: true,
        enableDadosDisponibilidade: false,
      })
    } else if (anterior === "enableDadosDisponibilidade" && atual === "enableDadosDisponibilidadeHorario") {
      this.setState({
        enableDadosDisponibilidade: true,
        enableDadosDisponibilidadeHorario: false,
      })
    } else if (anterior === "enableDadosDisponibilidadeHorario" && atual === "enableDadosVigencia") {
      this.setState({
        enableDadosDisponibilidadeHorario: true,
        enableDadosVigencia: false,
      })
    } else if (anterior === "enableDadosVigencia" && atual === "enableDadosUsuario") {
      this.setState({
        enableDadosVigencia: true,
        enableDadosUsuario: false,
      })
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 4000) {
    const self = this
    this.setState({
      contrato: initalState.contrato,
      funcionario: {
        id: "",
        dataAdmissao: "",
        dataDemissao: "",
        unidadetrabalho_id: "",
        contrato: {
          departamento: "",
          idDepartamento: "",
          cargo: "",
          idCargo: "",
          funcao: "",
          idFuncao: "",
          dataInicial: "",
          dataFinal: "",
          observacao: ""
        },
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        usuario: {
          login: "",
          senha: "",
          repetirSenha: "",
          usu_perfil_id: ""
        },
        contas: [{
          cb_agencia: "",
          cb_numero: "",
          cb_gerente: "",
          cb_chave_pix: "",
          cb_banco_id: "",
          index: 1,
          banco: {
            banco_id: "",
            banco_nome: "",
            banco_numero: "",
            banco_padraocosif: ""
          }
        }],
        ativo: "",
      }
    })
    setTimeout(() => {
      self.props.history.push("/funcionario");
    }, timeout)
  }

  updateFieldPerfil(event, value) {
    if (!value) return false

    const funcionario = { ...this.state.funcionario }
    funcionario.usuario.usu_perfil_id = value.per_id

    this.setState({
      funcionario,
      perfil: {
        per_id: value.per_id,
        per_descricao: value.per_descricao
      }
    })

  }

  async updateContrato(event) {
    const { funcionario, contrato } = this.state
    funcionario.unidadetrabalho_id = event.target.value

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?visibilidade=${contrato.paraquem}&unidadetrabalho_id=${parseInt(event.target.value)}`, this.getToken())

      this.setState(
        {
          perfis: {
            list: data
          }
        }
      )
    } catch (error) {
      console.log(error)
    }

    this.setState({ funcionario })
  }

  async updateParaquem(event) {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const nivelAcesso = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo.toLowerCase()

    const para = event.target.value || nivelAcesso
    const funcionario = { ...this.state.funcionario }
    const contrato = { ...this.state.contrato }

    switch (para) {
      case 'franqueador':
        funcionario.unidadetrabalho_id = null
        contrato.paraquem = "FRANQUEADOR"
        contrato.selects_unidadetrabalho = {
          franqueador: true,
          franqueado: false,
          unidade: false
        }

        break;

      case 'franqueado':
        contrato.paraquem = "FRANQUEADO"
        contrato.selects_unidadetrabalho = {
          franqueador: false,
          franqueado: true,
          unidade: false
        }
        break;

      case 'unidade':
        contrato.paraquem = "UNIDADE"
        contrato.selects_unidadetrabalho = {
          franqueador: false,
          franqueado: false,
          unidade: true
        }
        break;

      default:
        break;
    }

    this.setState({
      funcionario,
      contrato
    })

  }

  updateFieldDisponibilidade(hora, diaSemana) {
    const { professor } = this.state
    let disponibilidades = professor.disponibilidades

    const index = disponibilidades.findIndex(disp => disp.disp_dia_semana === diaSemana && disp.disp_hora_inicial === hora)

    if (index === -1) {
      const horaQuebrada = hora.split(":")
      const horaFinal = parseInt(horaQuebrada[0]) + 1
      const disp_hora_final = horaFinal < 10 ? `0${horaFinal}:00` : `${horaFinal}:00`

      disponibilidades.push({
        disp_dia_semana: diaSemana,
        disp_hora_inicial: hora,
        disp_hora_final,
        disp_data_vig_inicio: "",
        disp_data_vig_fim: "",
        index: disponibilidades.length + 1
      })
    } else {
      disponibilidades.splice(index, 1)

      let aux = []

      for (let i = 0; i < disponibilidades.length; i++) {
        aux.push({
          disp_dia_semana: disponibilidades[i].disp_dia_semana,
          disp_hora_inicial: disponibilidades[i].disp_hora_inicial,
          disp_hora_final: disponibilidades[i].disp_hora_final,
          disp_data_vig_inicio: disponibilidades[i].disp_data_vig_inicio,
          disp_data_vig_fim: disponibilidades[i].disp_data_vig_fim,
          index: i + 1
        })
      }

      disponibilidades = aux
    }

    professor.disponibilidades = disponibilidades

    this.setState({ professor })
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  handleClickShowPasswordRepetir() {
    this.setState({
      showPasswordRepetir: !this.state.showPasswordRepetir
    })
  }

  updateAllDia(diaSemana) {
    const { professor } = this.state

    let disponibilidades = professor.disponibilidades

    const lengthDispo = disponibilidades.filter(disp => disp.disp_dia_semana === diaSemana).length

    const disponibilidadesAux = removeItemAll(disponibilidades, diaSemana, 'disp_dia_semana')

    if (lengthDispo < 7) {
      for (let i = 8; i < 22; i++) {
        const hora = i < 10 ? `0${i}:00` : `${i}:00`
        const horaFinal = i + 1 < 10 ? `0${i + 1}:00` : `${i + 1}:00`
        disponibilidades.push({
          disp_dia_semana: diaSemana,
          disp_hora_inicial: hora,
          disp_hora_final: horaFinal,
          disp_data_vig_inicio: "",
          disp_data_vig_fim: "",
        })
      }
    } else {
      disponibilidades = disponibilidadesAux

      let aux = []

      for (let i = 0; i < disponibilidades.length; i++) {
        aux.push({
          disp_dia_semana: disponibilidades[i].disp_dia_semana,
          disp_hora_inicial: disponibilidades[i].disp_hora_inicial,
          disp_hora_final: disponibilidades[i].disp_hora_final,
          disp_data_vig_inicio: disponibilidades[i].disp_data_vig_inicio,
          disp_data_vig_fim: disponibilidades[i].disp_data_vig_fim,
          index: i + 1
        })
      }

      disponibilidades = aux
    }

    professor.disponibilidades = disponibilidades

    this.setState({ professor })
  }

  validateDisponibilidade() {
    const { disponibilidade } = this.state

    if (!disponibilidade.disp_dia_semana) return false
    if (!disponibilidade.disp_hora_inicial) return false
    if (!disponibilidade.disp_hora_final) return false

    return true
  }

  addItem() {
    if (!this.validateDisponibilidade()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios da Avaliação (*)."
      })
      return
    }

    const { professor, disponibilidade } = this.state
    const disponibilidades = professor.disponibilidades

    if (disponibilidade.disp_id) {
      const index = disponibilidades.findIndex(param => param.index === disponibilidade.index)

      disponibilidades[index] = {
        disp_id: disponibilidade.disp_id,
        disp_dia_semana: disponibilidade.disp_dia_semana,
        disp_hora_inicial: disponibilidade.disp_hora_inicial,
        disp_hora_final: disponibilidade.disp_hora_final,
        index: disponibilidade.index
      }
    } else {
      const index = disponibilidades.length + 1

      disponibilidades.push({
        disp_dia_semana: disponibilidade.disp_dia_semana,
        disp_hora_inicial: disponibilidade.disp_hora_inicial,
        disp_hora_final: disponibilidade.disp_hora_final,
        index
      })
    }

    professor.disponibilidades = disponibilidades

    this.setState({
      update: false,
      professor,
      disponibilidade: initalState.disponibilidade,
    })

  }

  editarItem(index) {
    const aux = this.state.professor.disponibilidades.filter(param => param.index === index)

    this.setState({
      update: true,
      disponibilidade: aux[0]
    })
  }

  removerItem(index) {
    let { professor } = this.state
    let disponibilidades = professor.disponibilidades

    disponibilidades.splice(index - 1, 1)

    let aux = []

    for (let i = 0; i < disponibilidades.length; i++) {
      aux.push({
        disp_id: disponibilidades[i].disp_id,
        disp_dia_semana: disponibilidades[i].disp_dia_semana,
        disp_hora_inicial: disponibilidades[i].disp_hora_inicial,
        disp_hora_final: disponibilidades[i].disp_hora_final,
        index: i + 1
      })
    }

    disponibilidades = aux

    professor.disponibilidades = disponibilidades

    this.setState({
      professor
    })
  }

  updateFieldAula(event, value) {
    this.setState({
      aulasSeleciondas: value
    })
  }

  addAula(aula) {
    const { aulasSeleciondas } = this.state

    aulasSeleciondas.push(aula)

    this.setState({
      aulasSeleciondas,
    })
  }

  removerAula(aula) {
    const { aulasSeleciondas } = this.state

    const index = aulasSeleciondas.findIndex(param => param.aula_id === aula.aula_id)

    aulasSeleciondas.splice(index, 1)

    this.setState({
      aulasSeleciondas,
    })
  }

  removerTodasAulas() {
    this.setState({
      aulasSeleciondas: [],
    })
  }

  addTodasAulas() {
    const { aulas } = this.state

    this.setState({
      aulasSeleciondas: aulas.list,
    })
  }

  updateFieldProfessor(event) {
    const professor = { ...this.state.professor }
    professor[event.target.name] = event.target.value
    this.setState({ professor })
  }

  addNovaContaBancaria() {
    const funcionario = { ...this.state.funcionario }
    const contas = funcionario.contas

    const index = contas.length + 1

    contas.push({
      cb_agencia: "",
      cb_numero: "",
      cb_gerente: "",
      cb_chave_pix: "",
      cb_banco_id: "",
      banco: {
        banco_nome: "",
        banco_numero: "",
        banco_padraocosif: ""
      },
      index
    })

    funcionario.contas = contas
    this.setState({ funcionario })
  }

  updateFieldContaBancaria(event, index) {
    const funcionario = { ...this.state.funcionario }
    funcionario.contas[index - 1][event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateFieldBanco(event, value, index) {
    if (!value) return false
    const funcionario = { ...this.state.funcionario }
    funcionario.contas[index - 1].banco = value
    funcionario.contas[index - 1].cb_banco_id = value.banco_id

    this.setState({ funcionario })
  }

  updateFieldDataVigencia(event, index) {
    const professor = { ...this.state.professor }

    const indexDisp = professor.disponibilidades.findIndex(param => param.index === index)

    professor.disponibilidades[indexDisp][event.target.name] = event.target.value
    this.setState({ professor })
  }

  removerConta(index) {
    const funcionario = { ...this.state.funcionario }
    const contas = funcionario.contas
    const aux = contas

    aux.splice(index - 1, 1)

    funcionario.contas = aux
    this.setState({ funcionario })
  }

  removeEndereco(index) {
    const funcionario = { ...this.state.funcionario }
    const enderecos = funcionario.enderecos
    const aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    funcionario.enderecos = aux
    this.setState({ funcionario })
  }

  removeContato(index) {
    const funcionario = { ...this.state.funcionario }
    const contatos = funcionario.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    funcionario.contatos = aux
    this.setState({ funcionario })
  }

  removeEmail(index) {
    const funcionario = { ...this.state.funcionario }
    const emails = funcionario.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    funcionario.emails = aux
    this.setState({ funcionario })
  }

  async salvar() {
    const { funcionario, professor, aulasSeleciondas } = this.state
    this.setState({ loadingSalvar: true })

    let dados = {}

    let auxProfessor = {}

    for (let i = 0; i < professor.disponibilidades.length; i++) {
      if (professor.disponibilidades[i].disp_data_vig_inicio === "" || !professor.disponibilidades[i].disp_data_vig_inicio) {
        delete professor.disponibilidades[i].disp_data_vig_inicio
      }
      if (professor.disponibilidades[i].disp_data_vig_fim === "" || !professor.disponibilidades[i].disp_data_vig_fim) {
        delete professor.disponibilidades[i].disp_data_vig_fim
      }
    }

    if (funcionario.contrato.cargo === "Professor") {
      auxProfessor = {
        ...professor,
        aulas: aulasSeleciondas.map(aula => {
          return {
            aula_id: aula.aula_id
          }
        })
      }
    }

    let emails = []
    let telefones = []
    let enderecos = []

    funcionario.emails.forEach(email => {
      if (email.ema_id) {
        emails.push({
          ema_id: email.ema_id,
          email: email.email,
          principal: email.principal
        })
      } else {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      }
    })

    funcionario.contatos.forEach(telefone => {
      if (telefone.tel_id) {
        telefones.push({
          tel_id: telefone.tel_id,
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      } else {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      }
    })

    funcionario.enderecos.forEach(endereco => {
      if (endereco.end_id) {
        enderecos.push({
          end_id: endereco.end_id,
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: endereco.complemento === "" ? "N/A" : endereco.complemento,
          principal: endereco.principal,
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          tipo: endereco.tipoEndereco
        })
      } else {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: endereco.complemento === "" ? "N/A" : endereco.complemento,
          principal: endereco.principal,
          rua: endereco.rua,
          bairro: endereco.bairro,
          cidade: endereco.cidade,
          estado: endereco.estado,
          cep: endereco.cep,
          tipo: endereco.tipoEndereco
        })
      }
    })

    if (funcionario.usuario.login !== "" || funcionario.usuario.usu_perfil_id !== "" || funcionario.usuario.senha !== "" || funcionario.usuario.repetirSenha !== "") {
      if (funcionario.usuario.login === "" || funcionario.usuario.usu_perfil_id === "") {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      if (funcionario.usuario.senha !== "" && funcionario.usuario.repetirSenha === "") {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      if (funcionario.usuario.senha !== funcionario.usuario.repetirSenha) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Senhas não Conferem!",
            descricao: "Por favor verificar as senhas."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      if (!validacaoEmail(funcionario.usuario.login)) {
        this.handleOpenDialog({
          titulo: 'Opps',
          descricao: 'Email Invalido!'
        })
        return
      }

      dados = {
        ativo: true,
        pesf_id: funcionario.pesf_id,
        nome: funcionario.pessoaFisica.nome,
        cpf: funcionario.pessoaFisica.cpf,
        rg: funcionario.pessoaFisica.rg,
        rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
        rg_uf: funcionario.pessoaFisica.rg_uf,
        rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
        datanascimento: funcionario.pessoaFisica.dataNascimento,
        enderecos,
        emails,
        telefones,
        unidadetrabalho_id: funcionario.unidadetrabalho_id,
        contt_funcao_id: funcionario.contrato.idFuncao,
        fun_data_admissao: funcionario.contrato.dataInicial,
        datainicio: funcionario.contrato.dataInicial,
        observacao: funcionario.contrato.observacao,
        professor: auxProfessor,
        usuario: {
          login: funcionario.usuario.login,
          senha: funcionario.usuario.senha,
          usu_perfil_id: funcionario.usuario.usu_perfil_id,
          usu_id: funcionario.usuario.usu_id
        }
      }

      if (dados.usuario.senha === '') {
        delete dados.usuario.senha
      }

      if (dados.usuario.usu_id === "" || !dados.usuario.usu_id) {
        delete dados.usuario.usu_id
      }
      
    } else {
      dados = {
        ativo: true,
        pesf_id: funcionario.pesf_id,
        nome: funcionario.pessoaFisica.nome,
        cpf: funcionario.pessoaFisica.cpf,
        rg: funcionario.pessoaFisica.rg,
        rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
        rg_uf: funcionario.pessoaFisica.rg_uf,
        rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
        datanascimento: funcionario.pessoaFisica.dataNascimento,
        enderecos,
        emails,
        telefones,
        unidadetrabalho_id: funcionario.unidadetrabalho_id,
        contt_funcao_id: funcionario.contrato.idFuncao,
        fun_data_admissao: funcionario.contrato.dataInicial,
        datainicio: funcionario.contrato.dataInicial,
        observacao: funcionario.contrato.observacao,
        professor: auxProfessor
      }
    }

    if (dados.rg_orgao_emissor === "" || !dados.rg_orgao_emissor) {
      delete dados.rg_orgao_emissor
    }

    if (dados.rg_uf === "" || !dados.rg_uf) {
      delete dados.rg_uf
    }

    if (dados.rg_data_emissao === "" || !dados.rg_data_emissao) {
      delete dados.rg_data_emissao
    }

    if(dados.pesf_id === ''){
      delete dados.pesf_id
    }

    const contas = funcionario.contas
    let contasAux = []

    contas.forEach(conta => {
      if (conta.cb_agencia !== "" && conta.cb_numero !== "" && conta.cb_banco_id !== "") {

        const aux = {
          cb_id: conta.cb_id ? conta.cb_id : "",
          numero: conta.cb_numero,
          gerente: conta.cb_gerente,
          agencia: conta.cb_agencia,
          chave_pix: conta.cb_chave_pix,
          banco_id: conta.cb_banco_id
        }

        if (!conta.cb_id) {
          delete aux.cb_id
        }

        if (conta.cb_gerente === "") {
          delete aux.gerente
        }

        if (conta.cb_chave_pix === "") {
          delete aux.chave_pix
        }

        contasAux.push(aux)
      }
    });

    if (contasAux.length !== 0) {
      dados.contas = contasAux
    }

    if (funcionario.contrato.cargo !== "Professor") {
      delete dados.professor
    }

    try {
      if (funcionario.id !== "") {
        await axios.put(`${process.env.REACT_APP_API_URL}/funcionarios/${funcionario.id}`, dados, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/funcionarios`, dados, this.getToken())
      }

      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        }
      })
      this.backPage()
      return
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro no Cadastro!",
          descricao: (response.data && response.data.message) ? response.data.message : "Tente mais tarde."
        }
      })
      this.setState({ loadingSalvar: false })
    }
  }

  updateFieldSearchFunc(event){
    const {searchResp} = this.state

    searchResp[event.target.name] = event.target.value

    this.setState({searchResp})
  }

  async buscarFunc(){
    const { searchResp } = this.state

    const buscaPor = 'cpf'
    const search = searchResp.cpf

    if (!cpf.isValid(cpf.format(searchResp.cpf))) {
      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      this.setState({
        modalErro: true,
        erro
      })
      return
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pessoa/fisica/search/${search}`, { buscaPor, busca: 'funcionario' }, this.getToken())
      
      console.log(data)
      let funcionario = null
      if(data){
        funcionario = {
          id: "",
          dataAdmissao: "",
          dataDemissao: "",
          unidadetrabalho_id: "",
          pesf_id: data.pesf_id,
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: data.pesf_nome,
            cpf: cpf.format(data.pesf_cpf),
            rg: data.pesf_rg ? data.pesf_rg : '',
            rg_orgao_emissor: data.pesf_rg_orgao_emissor ? data.pesf_rg_orgao_emissor : '',
            rg_uf: data.pesf_rg_uf ? data.pesf_rg_uf : '',
            rg_data_emissao: data.pesf_rg_data_emissao ? data.pesf_rg_data_emissao : '',
            dataNascimento: data.pesf_datanascimento
          },
          enderecos: data.pessoa.enderecos.map((end, key) => {
            return {
              end_id: end.end_id,
              cep_id: end.end_cep_id,
              complemento: end.end_complemento,
              rua: end.cep.logradouro,
              numero: end.end_numero,
              bairro: end.cep.bairro.nome,
              cidade: end.cep.bairro.cidade.nome,
              estado: end.cep.bairro.cidade.uf.descricao,
              cep: end.cep.cep,
              principal: end.end_principal,
              tipoEndereco: end.end_tipo,
              index: key + 1
            }
          }),
          emails: data.pessoa.emails.map((ema, key) => {
            return {
              ema_id: ema.ema_id,
              email: ema.ema_email,
              principal: ema.ema_principal,
              index: key + 1
            }
          }),
          contatos:data.pessoa.telefones.map((tel, key) => {
            return {
              tel_id: tel.tel_id,
              numero: tel.tel_numero,
              tipoContato: tel.tel_tipo,
              principal: tel.tel_principal,
              index: key + 1
            }
          }),
          usuario: {
            login: "",
            senha: "",
            repetirSenha: "",
            usu_perfil_id: ""
          },
          contas: [{
            cb_agencia: "",
            cb_numero: "",
            cb_gerente: "",
            cb_chave_pix: "",
            cb_banco_id: "",
            index: 1,
            banco: {
              banco_id: "",
              banco_nome: "",
              banco_numero: "",
              banco_padraocosif: ""
            }
          }],
          ativo: "",
        }
      }else{
        funcionario = {
          id: "",
          pesf_id: "",
          dataAdmissao: "",
          dataDemissao: "",
          unidadetrabalho_id: "",
          contrato: {
            departamento: "",
            idDepartamento: "",
            cargo: "",
            idCargo: "",
            funcao: "",
            idFuncao: "",
            dataInicial: "",
            dataFinal: "",
            observacao: ""
          },
          pessoaFisica: {
            nome: "",
            cpf: cpf.format(searchResp.cpf),
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: ""
          },
          enderecos: [{
            rua: "",
            complemento: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            cep: "",
            principal: true,
            tipoEndereco: "RESIDENCIAL",
            index: 1
          }],
          emails: [{
            email: "",
            principal: true,
            index: 1
          }],
          contatos: [{
            numero: "",
            tipoContato: "RESIDENCIAL",
            principal: true,
            index: 1
          }],
          usuario: {
            login: "",
            senha: "",
            repetirSenha: "",
            usu_perfil_id: ""
          },
          contas: [{
            cb_agencia: "",
            cb_numero: "",
            cb_gerente: "",
            cb_chave_pix: "",
            cb_banco_id: "",
            index: 1,
            banco: {
              banco_id: "",
              banco_nome: "",
              banco_numero: "",
              banco_padraocosif: ""
            }
          }],
          ativo: "",
        }
      }
      this.setState({ funcionario, enableBuscaFunc: false })
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro no Cadastro!",
          descricao: (response.data && response.data.message) ? response.data.message : "Tente mais tarde."
        }
      })
      this.setState({ loadingSalvar: false })
    }
  }

  voltarBuscaFun(){
    this.setState({ enableBuscaFunc: true })
  }

  render() {
    const { funcionario, enableUpdate, professor, disponibilidade } = this.state

    return (
      <div className="app-menu-closed" id="app">

        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11}>
                  <h2 className="titulo">Cadastro de Funcionários</h2>
                </Grid>
                <Grid item md={1} xs={2} sm={2}>
                  {this.state.loadingSalvar &&
                    <CircularProgress />
                  }
                </Grid>
              </Grid>

              {this.state.enableDadosPessoa &&
                <React.Fragment>
                  {this.state.enableBuscaFunc &&
                    <React.Fragment>
                       <Grid container direction="row" className="mg_top_20">
                          <Grid item md={12}>
                            <p>Por favor informar o CPF do colaborador</p>
                          </Grid>
                        </Grid>
                      <Grid container direction="row" spacing={1}>
                        <Grid item md={3} xs={12} sm={6}>
                          <TextField className="input" label="CPF" variant="outlined" size="small" name="cpf" value={this.state.searchResp.cpf} onChange={(e) => this.updateFieldSearchFunc(e)} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item md={1} xs={12} sm={3}>
                          <Button fullWidth onClick={() => this.buscarFunc()} color="primary" variant="contained" size="small">Buscar</Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }

                  {!this.state.enableBuscaFunc &&
                    <div className="formFranqueado">
                      <FormPessoaFisica validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaFisica(e)} dados={funcionario.pessoaFisica} />

                      <Grid className="mg_top_10" spacing={1} container direction="row" alignItems="flex-end">
                        <Grid item md={6}></Grid>
                        <Grid item md={3}>
                          {!enableUpdate &&
                            <Button fullWidth onClick={() => this.voltarBuscaFun()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                          }
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
                </React.Fragment>
              }

              {this.state.enableDadosEndereco &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Endereço</h3>
                    </Grid>
                  </Grid>
                  {funcionario.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco removerItem={(index) => this.removeEndereco(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEndereco(e, endereco.index)} updateField={e => this.updateFieldEndereco(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, endereco.index)} dados={endereco} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Contato</h3>
                    </Grid>
                  </Grid>
                  {funcionario.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato formataTelefone={e => this.formataTelefone(e, contato.index)} removerItem={(index) => this.removeContato(index)} updateField={e => this.updateFieldContato(e, contato.index)} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)} dados={contato} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoContato()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEmail &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de E-mail</h3>
                    </Grid>
                  </Grid>
                  {funcionario.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail removerItem={(index) => this.removeEmail(index)} updateField={e => this.updateFieldEmail(e, email.index)} updateFieldPrincipal={e => this.updateFieldEmailPrincipal(e, email.index)} dados={email} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoEmail()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEmail", "enableDadosContrato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContrato &&
                <div>
                  <div className="form">
                    <FormQuemTrabalha
                      selects_unidadetrabalho={this.state.contrato.selects_unidadetrabalho}
                      updateParaquem={e => this.updateParaquem(e)}
                      updateContrato={e => this.updateContrato(e)}
                      dados={this.state.contrato}
                      unidadetrabalho_id={funcionario.unidadetrabalho_id}
                      enableUpdate={enableUpdate} />
                  </div>
                  <div className="formFranqueado">
                    <Grid container direction="row">
                      <Grid item md={12}>
                        <h3>Dados do Contrato</h3>
                      </Grid>
                    </Grid>
                    <FormContratoFuncionario
                      enableUpdate={enableUpdate}
                      departamentos={this.state.departamentos.list}
                      cargos={this.state.cargosFiltrado.list}
                      funcaos={this.state.funcaosFiltrado.list}
                      updateField={e => this.updateFieldContrato(e)}
                      dados={funcionario.contrato} />
                    <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                      <Grid item md={6}></Grid>
                      <Grid item md={3}>
                        <Button fullWidth onClick={() => this.voltar("enableDadosContrato", "enableDadosEmail")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                      </Grid>
                      <Grid item md={3}>
                        <Button fullWidth onClick={() => this.proximo("enableDadosContrato", "enableDadosContaBancaria")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              }

              {this.state.enableDadosContaBancaria &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados das Contas Bancarias</h3>
                    </Grid>
                  </Grid>
                  {funcionario.contas.map(conta => {
                    return (
                      <div key={conta.index} className="cardEndereco">
                        <FormContaBancaria
                          updateField={e => this.updateFieldContaBancaria(e, conta.index)}
                          updateFieldBanco={(event, value, index) => this.updateFieldBanco(event, value, conta.index)}
                          dados={conta}
                          banco={conta.banco}
                          bancos={this.state.bancos.list}
                          removerConta={(index) => this.removerConta(index)} />
                      </div>
                    )
                  })}
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovaContaBancaria()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Nova Conta </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContaBancaria", "enableDadosContrato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      {funcionario.contrato.cargo === "Professor" &&
                        <React.Fragment>
                          <Button fullWidth onClick={() => this.proximo("enableDadosContaBancaria", "enableDadosDisponibilidade")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </React.Fragment>
                      }
                      {funcionario.contrato.cargo !== "Professor" &&
                        <React.Fragment>
                          <Button fullWidth onClick={() => this.proximo("enableDadosContaBancaria", "enableDadosUsuario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </React.Fragment>
                      }
                    </Grid>
                  </Grid>
                </div>
              }

              {funcionario.contrato.cargo === "Professor" &&
                <React.Fragment>
                  {this.state.enableDadosDisponibilidade &&
                    <div className="formFranqueado">
                      <FormProfessor
                        updateField={e => this.updateFieldProfessor(e)}
                        dados={professor}
                        addItem={e => this.addItem()}
                        addAula={(aula) => this.addAula(aula)}
                        removerAula={(aula) => this.removerAula(aula)}
                        aulas={this.state.aulas.list}
                        aulasSeleciondas={this.state.aulasSeleciondas}
                        update={this.state.update}
                        book={this.state.book}
                        books={this.state.books.list}
                        updateFieldBook={(e, value) => this.updateFieldBook(e, value)}
                        removerTodasAulas={e => this.removerTodasAulas(e)}
                        addTodasAulas={e => this.addTodasAulas(e)}
                      />
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={6}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosDisponibilidade", "enableDadosContaBancaria")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosDisponibilidade", "enableDadosDisponibilidadeHorario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {this.state.enableDadosDisponibilidadeHorario &&
                    <div className="formFranqueado">
                      <FormProfessorDispHorario
                        updateField={e => this.updateFieldProfessor(e)}
                        dados={professor}
                        disponibilidade={disponibilidade}
                        disponibilidades={professor.disponibilidades}
                        updateFieldDisponibilidade={(hora, diaSemana) => this.updateFieldDisponibilidade(hora, diaSemana)}
                        updateAllDia={(diaSemana) => this.updateAllDia(diaSemana)}
                      />
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={6}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosDisponibilidadeHorario", "enableDadosDisponibilidade")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosDisponibilidadeHorario", "enableDadosVigencia")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {this.state.enableDadosVigencia &&
                    <div className="formFranqueado">
                      <FormProfessorVigenciaHorario
                        disponibilidades={professor.disponibilidades}
                        updateField={(e, index) => this.updateFieldDataVigencia(e, index)} />
                      <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                        <Grid item md={6}></Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.voltar("enableDadosVigencia", "enableDadosDisponibilidadeHorario")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                        </Grid>
                        <Grid item md={3}>
                          <Button fullWidth onClick={() => this.proximo("enableDadosVigencia", "enableDadosUsuario")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                        </Grid>
                      </Grid>
                    </div>
                  }
                </React.Fragment>
              }
              {this.state.enableDadosUsuario &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados do Usuário</h3>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_20">
                    <Grid item md={4} xs={12} sm={6}>
                      <Autocomplete
                        onChange={(event, value) => this.updateFieldPerfil(event, value)}
                        freeSolo
                        options={this.state.perfis.list}
                        value={this.state.perfil}
                        getOptionLabel={option => option.per_descricao}
                        renderInput={(params) => (
                          <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={this.state.perfil.per_descricao} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <FormUsuario
                    updateField={e => this.updateFieldUsuario(e)}
                    dados={funcionario.usuario}
                    handleClickShowPassword={e => this.handleClickShowPassword(e)}
                    handleClickShowPasswordRepetir={e => this.handleClickShowPasswordRepetir(e)}
                    showPassword={this.state.showPassword}
                    showPasswordRepetir={this.state.showPasswordRepetir}
                    nivelSenha={this.state.nivelSenha}
                    corSenha={this.state.corSenha}
                  />
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={6}></Grid>
                    <Grid item md={3}>
                      {funcionario.contrato.cargo === "Professor" &&
                        <Button fullWidth onClick={() => this.voltar("enableDadosUsuario", "enableDadosVigencia")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                      }
                      {funcionario.contrato.cargo !== "Professor" &&
                        <Button fullWidth onClick={() => this.voltar("enableDadosUsuario", "enableDadosContaBancaria")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                      }
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
              <br />
              <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}