import React, { Component } from 'react'
import './Caixa.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button, TextField } from '@material-ui/core'

import moment from 'moment';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress'

import ModalConfirmacao from './../../components/modals/Confirmacao'
import CurrencyInput from './../../components/mask/CurrencyInput'

import Table from '../../components/Table'
import real from '../../services/real'

import { saveAs } from 'file-saver';

const initalState = {
  loading: true,
  caixa: {},
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  modalOpen: false,
  modal: {
    mensagem: '',
  },
  movimentacao: {
    movcontfin_valor: '',
    movcontfin_contfin_id: 0,
    movcontfin_data_conciliacao: '',
    movcontfin_descricao: '',
    caixa: ''
  },
  toogleTransferencia: false,
  cofre: {
    list: []
  },
  transferencias: {
    list: []
  },
  recebidos: {
    list: []
  },
  lancamentos: {
    list: []
  },
  headCellTransferencia: [
    { id: 'posicao', numeric: false, disablePadding: true, label: '' },
    { id: 'data_hora', numeric: false, disablePadding: true, label: 'Data e Hora' },
    { id: 'movcontfin_descricao', numeric: false, disablePadding: true, label: 'Descrição da Movimentação' },
    { id: 'movcontfin_tipo', numeric: false, disablePadding: true, label: 'Tipo da Movimentação' },
    { id: 'movcontfin_valor_caixa', numeric: false, disablePadding: true, label: 'Valor' }
  ],
  headCellRecebidos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'parcont_datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'parcont_datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: true, label: 'Valor Parcela' },
    { id: 'parcont_multajuros', numeric: false, disablePadding: true, label: 'Juros' },
    { id: 'parcont_valorpagamento', numeric: false, disablePadding: true, label: 'Valor Pago' },
    { id: 'formpag_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
  ],
  headCellLancamentos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'parcont_datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'parcont_datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: true, label: 'Valor Parcela' },
    { id: 'parcont_multajuros', numeric: false, disablePadding: true, label: 'Juros' },
    { id: 'parcont_valorpagamento', numeric: false, disablePadding: true, label: 'Valor Pago' },
    { id: 'formpag_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
  ],
  headCellValores: [
    { id: 'formpag_descricao', numeric: false, disablePadding: false, label: 'Forma de Pagamento' },
    { id: 'valor_recebido', numeric: false, disablePadding: false, label: 'Saldo' },
  ],
  contaCofre: {},
  valores_recebidos: {
    list: []
  },
  disabledButtonTransferir: false
}


export default class ViewCaixa extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    if (params.caixaId) {
      try {
        const dados = {
          filtro: {
            contfin_tipo_conta: "COFRE",
            contfin_descricao: "",
            contfin_ativo: "Sim"
          }
        }

        const { data: caixa } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${params.caixaId}`, this.getToken())
        const { data: cofres } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())
        const { data: transferencias } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${caixa.cai_id}/movimentacoes`, this.getToken())
        const { data: recebidos } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${caixa.cai_id}/recebidos`, this.getToken())
        const { data: lancamentos } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${caixa.cai_id}/lancamentos`, this.getToken())
        let { data: valores_recebidos } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/valores-recebidos/${caixa.cai_id}`, this.getToken())

        for (let i = 0; i < transferencias.length; i++) {
          const index = valores_recebidos.findIndex(param => param.formpag_descricao === "Dinheiro")

          if (index !== -1) {
            if (transferencias[i].movcontfin_tipo === "DEPOSITO") {
              valores_recebidos[index].valor_recebido = valores_recebidos[index].valor_recebido + transferencias[i].movcontfin_valor
            }

            if (transferencias[i].movcontfin_tipo === "SAQUE") {
              valores_recebidos[index].valor_recebido = valores_recebidos[index].valor_recebido - transferencias[i].movcontfin_valor
            }
          }else{
            let valor_recebido = 0

            if (transferencias[i].movcontfin_tipo === "DEPOSITO") {
              valor_recebido = valor_recebido + transferencias[i].movcontfin_valor
            }

            if (transferencias[i].movcontfin_tipo === "SAQUE") {
              valor_recebido = valor_recebido - transferencias[i].movcontfin_valor
            }

            valores_recebidos.push({
              valor_recebido,
              formpag_id: 1,
              formpag_descricao: "Dinheiro"
            })
          }
        }

        this.setState({
          caixa,
          cofre: {
            list: cofres
          },
          transferencias: {
            list: transferencias
          },
          valores_recebidos: {
            list: valores_recebidos
          },
          recebidos: {
            list: recebidos.map(parcela => {
              return {
                contpr_descricao: parcela.contpr_descricao,
                contpr_numparcela: parcela.contpr_numparcela,
                parcont_num: parcela.parcont_num,
                parcont_datavencimento: parcela.parcont_datavencimento,
                parcont_datapagamento: parcela.parcont_datapagamento ? parcela.parcont_datapagamento : '',
                parcont_valorparcela: parcela.parcont_valorparcela,
                parcont_multajuros: parcela.parcont_multajuros ? parcela.parcont_multajuros : '',
                parcont_valorpagamento: parcela.valpag_valor_pagamento,
                formpag_descricao: parcela.formpag_descricao
              }
            })
          },
          lancamentos: {
            list: lancamentos.map(parcela => {
              return {
                contpr_descricao: parcela.contpr_descricao,
                contpr_numparcela: parcela.contpr_numparcela,
                parcont_num: parcela.parcont_num,
                parcont_datavencimento: parcela.parcont_datavencimento,
                parcont_datapagamento: parcela.parcont_datapagamento ? parcela.parcont_datapagamento : '',
                parcont_valorparcela: parcela.parcont_valorparcela,
                parcont_multajuros: parcela.parcont_multajuros ? parcela.parcont_multajuros : '',
                parcont_valorpagamento: parcela.valpag_valor_pagamento,
                formpag_descricao: parcela.formpag_descricao
              }
            })
          },
          contaCofre: cofres.filter(param => param.contfin_cofre === true)[0]
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: error.response.data.message
        })
        this.backPage()
      }
    }

    this.setState({ loading: false })
  }

  async updateField(event) {
    const split = event.target.name.split('.')
    const data = { ...this.state[split[0]] }
    data[split[1]] = event.target.value
    const intercection = {}
    intercection[split[0]] = data
    this.setState({ ...intercection })
  }

  fechar() {
    let mensagem = 'Deseja realmente fechar o caixa?'

    this.setState({
      modal: {
        mensagem,
        function: e => this.confirmarFechamento(e)
      },
      modalOpen: true,
    })
  }

  async confirmarFechamento(resposta) {
    if (resposta) {
      try {
        const caixa = { ...this.state.caixa }
        await axios.put(`${process.env.REACT_APP_API_URL}/caixa/${caixa.cai_id}/fechar`, {}, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabens.',
          descricao: 'Caixa fechado com sucesso'
        })

        this.handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } catch ({ response }) {
        this.handleOpenDialog({
          titulo: 'Opps.',
          descricao: response.data.message || 'Algo de errado aconteceu, tente mais tarde'
        })
        this.handleClose()
      }

    } else {
      this.handleClose()
    }
  }

  transferir() {
    const { valores_recebidos } = this.state
    const transf = { ...this.state.movimentacao }

    this.setState({
      disabledButtonTransferir: true
    })

    const index = valores_recebidos.list.findIndex(param => param.formpag_descricao === "Dinheiro")

    if (index !== -1) {
      if (parseFloat(transf.movcontfin_valor.replace(".", "").replace(",", ".")) > valores_recebidos.list[index].valor_recebido) {
        this.setState({
          disabledButtonTransferir: false
        })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Opps',
            descricao: 'Valor Informado superior ao saldo do caixa!',
          }
        })
        return false
      }
    }

    this.setState({
      modal: {
        mensagem: "Deseja mesmo realizar a sangria?",
        function: e => this.confirmarTransferencia(e)
      },
      modalOpen: true,
    })
  }

  async confirmarTransferencia(resposta) {
    if (resposta) {
      try {
        this.setState({ loadingSalvar: true })
        const transf = { ...this.state.movimentacao }
        const caixa = { ...this.state.caixa }

        if (
          transf.movcontfin_valor === 0
        ) {
          this.handleOpenDialog({
            titulo: "Opps!",
            descricao: "Preencha os campos obrigatorios (*)."
          })
          this.setState({ loadingSalvar: false })
          return
        }

        if (caixa['conta.contfin_saldo_atual'] < transf.movcontfin_valor) {
          this.handleOpenDialog({
            titulo: "Opps!",
            descricao: "O valor da movimentacao não pode ser maior do que o saldo do caixa."
          })
          this.setState({ loadingSalvar: false })
          return
        }

        let valor = transf.movcontfin_valor
        valor = valor.replace(/\./g, '');
        valor = valor.replace(',', '.');


        const pessoa = JSON.parse(localStorage.getItem('pessoa'))
        const usuario = JSON.parse(localStorage.getItem('usuario'))
        const contaCofre = this.state.contaCofre


        const data = {
          movcontfin_conciliado: true,
          movcontfin_tipo: 'DEPOSITO',
          movcontfin_valor: parseFloat(valor),
          movcontfin_contfin_id: contaCofre.contfin_id,
          movcontfin_data_conciliacao: new Date(),
          movcontfin_descricao: `Recebimento de sangria do caixa nº ${caixa.cai_numero} do operador ${pessoa.fisica.pesf_nome}.`,
          caixa: caixa.cai_id,
          movcontfin_data_hora_conta: new Date(),
          movcontfin_usu_criador_id: usuario.usu_id
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira`, data, this.getToken())

        this.setState({
          disabledButtonTransferir: false
        })

        this.handleOpenDialog({
          titulo: 'Parabens.',
          descricao: 'Transferencia realizada com sucesso'
        })


        this.handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } catch (error) {
        this.setState({
          disabledButtonTransferir: false
        })
        this.handleOpenDialog({
          titulo: 'Opps.',
          descricao: 'Algo de errado aconteceu, tente mais tarde'
        })
        this.handleClose()
        this.setState({ loadingSalvar: false })
      }

    } else {
      this.handleClose()
    }
  }

  handleClose() {
    this.setState({
      modalOpen: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: false,
      modalOpen: false
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/caixa");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async resumo(event) {
    this.setState({ loadingSalvar: true })

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${this.state.caixa.cai_id}/resumo`, this.getToken())

      const dados = {
        path: data.path
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `resumo-caixa-${this.state.caixa.cai_numero}.pdf`);

      this.setState({ loadingSalvar: false })
    } catch (error) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: 'Opps.',
        descricao: 'Algo de errado aconteceu, tente mais tarde'
      })
    }
  }

  listaParcelas(lista) {
    const retorno = lista.map(object => {
      return {
        ...object,
        parcont_datavencimento: moment(object.parcont_datavencimento).format('DD/MM/YYYY'),
        parcont_datapagamento: object.parcont_datapagamento ? moment(object.parcont_datapagamento).format('DD/MM/YYYY HH:mm') + 'h' : '',
        num_parcela: `${object.parcont_num}/${object.contpr_numparcela}`,
        parcont_valorparcela: real(object.parcont_valorparcela || 0),
        parcont_multajuros: real(object.parcont_multajuros || 0),
        parcont_valorpagamento: real(object.parcont_valorpagamento || 0)
      }
    })

    return retorno
  }

  render() {
    const { caixa, movimentacao, contaCofre, valores_recebidos } = this.state

    const transferencias = []
    let i = this.state.transferencias.list.length
    for (const transf of this.state.transferencias.list) {
      transferencias.push({
        ...transf,
        posicao: i--,
        movcontfin_valor_caixa: real(transf.movcontfin_valor),
        data_hora: moment(transf.movcontfin_data_hora_conta).format('DD/MM/YYYY HH:mm') + 'h',
        conta: caixa['conta.contfin_id'] === transf.conta_financeira.contfin_id ? '' : transf.conta_financeira.contfin_descricao,
        movcontfin_descricao: transf.movcontfin_descricao,
        movcontfin_tipo: transf.movcontfin_tipo,
      })
    }

    const recebidos = this.listaParcelas(this.state.recebidos.list)
    const lancamentos = this.listaParcelas(this.state.lancamentos.list)

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div className="view" id="caixa">
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={8}>
                  <h2 className="titulo">
                    Caixa Nº {caixa.cai_numero}
                    {caixa['status_atual.scd_status'] !== 'ABERTO' && <span>({caixa['status_atual.scd_status']})</span>}
                  </h2>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Transferencias:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (transferencias.length) {
                      return (
                        <Table headCell={this.state.headCellTransferencia} rows={transferencias} acoes={[]} ordem='desc' heightRows={8} />
                      )
                    } else {
                      return (<p>Não há Transferências no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Recebidos de Baixa:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (recebidos.length) {
                      return (
                        <Table headCell={this.state.headCellRecebidos} rows={recebidos} acoes={[]} ordem='desc' heightRows={15} />
                      )
                    } else {
                      return (<p>Não há Transferências no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '30px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Lançamentos:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (lancamentos.length) {
                      return (
                        <Table headCell={this.state.headCellLancamentos} rows={lancamentos} acoes={[]} ordem='desc' heightRows={15} />
                      )
                    } else {
                      return (<p>Não há Transferências no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ marginTop: '50px' }}>
                <Grid item lg={4} md={4} xs={12} sm={4}>
                  <h4 className="saldo">Saldo Atual:</h4>
                  <p>Iniciado com R$ {real(caixa.cai_fundotroco || 0)}</p>
                </Grid>
                {!this.state.toogleTransferencia &&
                  <Grid item md={3} xs={12} sm={4}>
                    {caixa['status_atual.scd_status'] === 'ABERTO' &&
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        className="btn_salvar"
                        style={{ 'marginTop': '5px' }}
                        size="small"
                        onClick={() => this.setState({ toogleTransferencia: true })}
                        disabled={this.state.loadingSalvar || caixa.cai_fechado}
                      >
                        Realizar Sangria
                    </Button>
                    }
                  </Grid>
                }
              </Grid>

              {this.state.toogleTransferencia &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item lg={2} md={3} xs={8} sm={5}>
                    <TextField
                      className="input"
                      label="Conta"
                      variant="outlined"
                      size="small"
                      value={contaCofre.contfin_descricao}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item md={2} xs={4} sm={4}>
                    <TextField
                      className="input"
                      label="Valor*"
                      variant="outlined"
                      size="small"
                      name="movimentacao.movcontfin_valor"
                      value={movimentacao.movcontfin_valor}
                      onChange={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputComponent: CurrencyInput }}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={3}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      className="btn_salvar"
                      style={{ 'marginTop': '5px' }}
                      size="small"
                      onClick={e => this.transferir(e)}
                      disabled={this.state.loadingSalvar ? true : this.state.disabledButtonTransferir}
                    >
                      Confirmar Sangria
                    </Button>
                  </Grid>
                </Grid>
              }

              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={12}>
                  <Table headCell={this.state.headCellValores} rows={valores_recebidos.list} acoes={[]} />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1}>
                <Grid item md={3} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    className="btn_salvar mg_top_10"
                    size="small"
                    onClick={e => this.backPage(0)}
                    disabled={this.state.loadingSalvar}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  {caixa['status_atual.scd_status'] === 'ABERTO' &&
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      className="btn_salvar mg_top_10"
                      size="small"
                      onClick={e => this.fechar(e)}
                      disabled={this.state.loadingSalvar || caixa.cai_fechado}
                    >
                      Fechar Caixa
                    </Button>
                  }

                  {caixa['status_atual.scd_status'] === 'FECHADO' &&
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      className="btn_salvar mg_top_10"
                      size="small"
                      onClick={e => this.resumo(e)}
                      disabled={this.state.loadingSalvar}
                    >
                      Imprimir Resumo
                    </Button>
                  }
                  {caixa['status_atual.scd_status'] === "AGUARDANDO_FECHAMENTO" &&
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      className="btn_salvar mg_top_10"
                      size="small"
                      onClick={e => this.resumo(e)}
                      disabled={this.state.loadingSalvar}
                    >
                      Imprimir Resumo
                    </Button>
                  }
                </Grid>
              </Grid>

              <ModalConfirmacao open={this.state.modalOpen} dados={this.state.modal} handleClose={e => this.handleClose(e)} confirmar={e => this.state.modal.function(e)} />
            </div>
          }
          <br />
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}