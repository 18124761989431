import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import './Funcionario.scss'
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import Table from '../../components/Table'

import { Link } from 'react-router-dom'
import { Grid, Button, TextField } from '@material-ui/core'

import Observacoes from './View/Observacoes'
import Afastamento from './View/Afastamento'
import DispAulas from './View/DispAulas'
import DispHorario from './View/DispHorario'
import VigHorario from './View/VigHorario'

const cabecalhoTabela = [
  { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Unidade' },
  { id: 'contt_datainicio', numeric: false, disablePadding: true, label: 'Data Inicio' },
  { id: 'contt_datafinal', numeric: false, disablePadding: false, label: 'Data Final' },
  { id: 'func_descricao', numeric: false, disablePadding: false, label: 'Função' },
  { id: 'contt_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
]

const acoesTabela = []

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const { funcionario, pessoa, contratos, permissoes, permissoesPromover } = props

  let tabs = {}

  if(funcionario.contrato.funcao.cargo.car_slug === 'professor'){
    tabs = {
      afastamento: 0,
      disp_aulas: 1,
      disp_horarios: 2,
      vigencia: 3,
      funcionario: 4,
      contrato: 5,
      observacao: 6
    }
  }else{
    tabs = {
      afastamento: 0,
      funcionario: 1,
      contrato: 2,
      observacao: 3
    }
  }

  const [value, setValue] = React.useState(tabs[props.params.tab] ? tabs[props.params.tab] : 0);
  
  const handleChange = (event, newValue) => {
   
    let tabsUrl = {}
    
    if(funcionario.contrato.funcao.cargo.car_slug === 'professor'){
      tabsUrl = {
        0: 'afastamento',
        1: 'disp_aulas',
        2: 'disp_horarios',
        3: 'vigencia',
        4: 'funcionario',
        5: 'contrato',
        6: 'observacao'
      }
    }else{
      tabsUrl = {
        0: 'afastamento',
        1: 'funcionario',
        2: 'contrato',
        3: 'observacao'
      }
    }

    
    setValue(newValue)
    props.history.push(`/funcionario/view/${funcionario.fun_id}/${tabsUrl[newValue]}`);
    return;
  };

  return (
    <div className={classes.root} id="view">
      <AppBar position="static">
      <Tabs id="viewAluno" variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Afastamento" {...a11yProps(0)} />
          {funcionario.contrato.funcao.cargo.car_slug === 'professor' &&
            <Tab label="Disp. Aulas" {...a11yProps(1)} />
          }
          {funcionario.contrato.funcao.cargo.car_slug === 'professor' &&
            <Tab label="Disp. Horarios" {...a11yProps(2)} />
          }
          {funcionario.contrato.funcao.cargo.car_slug === 'professor' &&
            <Tab label="Vig. Horarios" {...a11yProps(3)} />
          }
          <Tab label="Dados do Funcionario" {...a11yProps(4)} />
          <Tab label="Dados do Contrato" {...a11yProps(5)} />
          <Tab label="Observaçoes" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      {funcionario.contrato.funcao.cargo.car_slug === 'professor' &&
        <React.Fragment>
          <TabPanel value={value} index={4}>
                  <div className="cardEndereco">
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={funcionario.fisica.pesf_nome} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(funcionario.fisica.pesf_datanascimento).format('DD/MM/YYYY')} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={funcionario.fisica.pesf_cpf} disabled />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={funcionario.fisica.pesf_rg} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="UF de Emissão" value={funcionario.fisica.pesf_rg_uf} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Orgão Emissor" value={funcionario.fisica.pesf_rg_orgao_emissor} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Emissão" value={moment(funcionario.fisica.pesf_rg_data_emissao).format('DD/MM/YYYY')} disabled />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Endereço: </h4>
                      </Grid>
                    </Grid>
                    {funcionario.fisica.pessoa.enderecos.map(endereco => {
                      return (
                        <div className="cardEndereco" key={endereco.end_id}>
                          {endereco.end_principal &&
                            <h4>Endereço Principal:</h4>
                          }
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={2} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.end_cep} disabled />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.end_logradouro} disabled />
                            </Grid>
                            <Grid item md={2} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.end_numero} disabled />
                            </Grid>
                            <Grid item md={2} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.end_complemento} disabled />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={6} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.end_bairro} disabled />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.end_cidade} disabled />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.end_estado} disabled />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={3} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.end_tipo} disabled />
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Telefones: </h4>
                      </Grid>
                    </Grid>
                    {funcionario.fisica.pessoa.telefones.map(telefone => {
                      return (
                        <div className="cardEndereco" key={telefone.tel_id}>
                          {telefone.tel_principal &&
                            <h4>Telefone Principal:</h4>
                          }
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={3} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.tel_numero} disabled InputProps={{ inputComponent: TextMaskCustom }} />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tel_tipo} disabled />
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">E-mails: </h4>
                      </Grid>
                    </Grid>
                    {funcionario.fisica.pessoa.emails.map(email => {
                      return (
                        <div className="cardEndereco" key={email.ema_id}>
                          {email.ema_principal &&
                            <h4>E-mail Principal:</h4>
                          }
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={6} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="E-mail" value={email.ema_email} disabled />
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                    {funcionario.fisica.pessoa.usuario &&
                      <div>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={12} xs={12} sm={12}>
                            <h4 className="titulo">Usuário: </h4>
                          </Grid>
                        </Grid>
                        <div className="cardEndereco">
                          <Grid container spacing={1} direction="row">
                            <Grid item md={6} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Login" value={funcionario.fisica.pessoa.usuario.usu_login} disabled />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                              <TextField fullWidth size="small" variant="outlined" className="input" label="Perfil" value={funcionario.fisica.pessoa.usuario.perfil.per_descricao} disabled />
                            </Grid>
                            {/* <Grid item md={6} sm={6} xs={12}>
                              <Link to={`/funcionario/update/usuario/${funcionario.fun_id}`}>
                                <Button className="btnCadastrar" variant="contained" color="primary">
                                  Editar Usuário
                                </Button>
                              </Link>
                            </Grid> */}
                          </Grid>
                        </div>
                      </div>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={6}></Grid>
                      <Grid item md={3} xs={6} sm={6}>
                        {funcionario.fun_status &&
                          <React.Fragment>
                            {permissoes.perm_inserir &&
                              <Link to={`/funcionario/cadastro/${funcionario.fun_id}`}>
                                <Button className="btnCadastrar" variant="contained" color="primary">
                                  Editar Funcionário
                              </Button>
                              </Link>
                            }
                          </React.Fragment>
                        }
                      </Grid>
                      <Grid item md={3} xs={5} sm={5}>
                        <Link to={`/funcionario`}>
                          <Button className="btnCadastrar" variant="contained" color="secondary">
                            Voltar
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </TabPanel>
          <TabPanel value={value} index={5}>
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Contratos: </h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table headCell={cabecalhoTabela} rows={contratos.sort(function (a, b) {
                    return new Date(b.contt_datainicio) - new Date(a.contt_datainicio);
                  }).map(contrato => {
                    return {
                      unin_descricao: contrato.unidadetrabalho.unin_descricao,
                      contt_datainicio: moment(contrato.contt_datainicio).format('DD/MM/YYYY'),
                      contt_datafinal: contrato.contt_datafinal ? moment(contrato.contt_datafinal).format('DD/MM/YYYY') : 'Em Aberto',
                      func_descricao: contrato.funcao.func_descricao,
                      contt_ativo: contrato.contt_ativo,
                    }
                  })} acoes={acoesTabela} />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Contrato Atual: </h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Matricula" value={funcionario.fun_matricula} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Admissão" value={moment(funcionario.fun_data_admissao).format('DD/MM/YYYY')} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Funcão" value={funcionario.contrato.funcao.func_descricao} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade de Trabalho" value={funcionario.contrato.unidadetrabalho.unin_descricao ? funcionario.contrato.unidadetrabalho.unin_descricao : funcionario.contrato.unidadetrabalho.pessoa.pes_tipo === 'FISICA' ? funcionario.contrato.unidadetrabalho.pessoa.fisica.pesf_nome : funcionario.contrato.unidadetrabalho.pessoa.juridica.pesj_razaosocial} disabled />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} sm={12} xs={12}>
                  <TextField multiline rows={5} fullWidth size="small" variant="outlined" className="input" label="Observação" value={funcionario.contrato.contt_observacao} disabled />
                </Grid>
              </Grid>
              {funcionario.fun_status && 
                <Grid container spacing={1} direction="row">
                  {pessoa.fisica.funcionario.fun_id !== funcionario.fun_id &&
                    <Grid item md={4} sm={12} xs={12}>
                      <Link to={`/funcionario/contrato/demitir/${funcionario.fun_id}`}>
                        <Button className="btnCadastrar" variant="contained" color="secondary">
                          Demitir Funcionário
                        </Button>
                      </Link>
                    </Grid>
                  }
                  {permissoesPromover.perm_inserir &&
                    <Grid item md={4} sm={12} xs={12}>
                      <Link to={`/funcionario/contrato/promover/${funcionario.fun_id}`}>
                        <Button className="btnCadastrar" variant="contained" color="primary">
                          Promover Funcionário
                      </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
              }
            </div>
          </TabPanel>
          <TabPanel value={value} index={0}>
            <Afastamento funcionario={funcionario} {...props.props} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Observacoes funcionario={funcionario} {...props.props} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DispAulas funcionario={funcionario} {...props.props}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DispHorario funcionario={funcionario} {...props.props}/>
          </TabPanel>          
          <TabPanel value={value} index={3}>
            <VigHorario funcionario={funcionario} {...props.props}/>
          </TabPanel>
        </React.Fragment>
      }
      {funcionario.contrato.funcao.cargo.car_slug !== 'professor' &&
        <React.Fragment>
          <TabPanel value={value} index={1}>
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={6} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={funcionario.fisica.pesf_nome} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(funcionario.fisica.pesf_datanascimento).format('DD/MM/YYYY')} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={funcionario.fisica.pesf_cpf} disabled />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={funcionario.fisica.pesf_rg} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="UF de Emissão" value={funcionario.fisica.pesf_rg_uf} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Orgão Emissor" value={funcionario.fisica.pesf_rg_orgao_emissor} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Emissão" value={moment(funcionario.fisica.pesf_rg_data_emissao).format('DD/MM/YYYY')} disabled />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Endereço: </h4>
                </Grid>
              </Grid>
              {funcionario.fisica.pessoa.enderecos.map(endereco => {
                return (
                  <div className="cardEndereco" key={endereco.end_id}>
                    {endereco.end_principal &&
                      <h4>Endereço Principal:</h4>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={2} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.end_cep} disabled />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.end_logradouro} disabled />
                      </Grid>
                      <Grid item md={2} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.end_numero} disabled />
                      </Grid>
                      <Grid item md={2} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.end_complemento} disabled />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.end_bairro} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.end_cidade} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.end_estado} disabled />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.end_tipo} disabled />
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Telefones: </h4>
                </Grid>
              </Grid>
              {funcionario.fisica.pessoa.telefones.map(telefone => {
                return (
                  <div className="cardEndereco" key={telefone.tel_id}>
                    {telefone.tel_principal &&
                      <h4>Telefone Principal:</h4>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.tel_numero} disabled InputProps={{ inputComponent: TextMaskCustom }} />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tel_tipo} disabled />
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">E-mails: </h4>
                </Grid>
              </Grid>
              {funcionario.fisica.pessoa.emails.map(email => {
                return (
                  <div className="cardEndereco" key={email.ema_id}>
                    {email.ema_principal &&
                      <h4>E-mail Principal:</h4>
                    }
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="E-mail" value={email.ema_email} disabled />
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
              {funcionario.fisica.pessoa.usuario &&
                <div>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Usuário: </h4>
                    </Grid>
                  </Grid>
                  <div className="cardEndereco">
                    <Grid container spacing={1} direction="row">
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Login" value={funcionario.fisica.pessoa.usuario.usu_login} disabled />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Perfil" value={funcionario.fisica.pessoa.usuario.perfil.per_descricao} disabled />
                      </Grid>
                      {/* <Grid item md={6} sm={6} xs={12}>
                        <Link to={`/funcionario/update/usuario/${funcionario.fun_id}`}>
                          <Button className="btnCadastrar" variant="contained" color="primary">
                            Editar Usuário
                          </Button>
                        </Link>
                      </Grid> */}
                    </Grid>
                  </div>
                </div>
              }
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={6}></Grid>
                <Grid item md={3} xs={6} sm={6}>
                  {funcionario.fun_status &&
                    <React.Fragment>
                      {permissoes.perm_inserir &&
                        <Link to={`/funcionario/cadastro/${funcionario.fun_id}`}>
                          <Button className="btnCadastrar" variant="contained" color="primary">
                            Editar Funcionário
                        </Button>
                        </Link>
                      }
                    </React.Fragment>
                  }
                </Grid>
                <Grid item md={3} xs={5} sm={5}>
                  <Link to={`/funcionario`}>
                    <Button className="btnCadastrar" variant="contained" color="secondary">
                      Voltar
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="cardEndereco">
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Contratos: </h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table headCell={cabecalhoTabela} rows={contratos.sort(function (a, b) {
                    return new Date(b.contt_datainicio) - new Date(a.contt_datainicio);
                  }).map(contrato => {
                    return {
                      unin_descricao: contrato.unidadetrabalho.unin_descricao,
                      contt_datainicio: moment(contrato.contt_datainicio).format('DD/MM/YYYY'),
                      contt_datafinal: contrato.contt_datafinal ? moment(contrato.contt_datafinal).format('DD/MM/YYYY') : 'Em Aberto',
                      func_descricao: contrato.funcao.func_descricao,
                      contt_ativo: contrato.contt_ativo,
                    }
                  })} acoes={acoesTabela} />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12} sm={12}>
                  <h4 className="titulo">Contrato Atual: </h4>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Matricula" value={funcionario.fun_matricula} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Data de Admissão" value={moment(funcionario.fun_data_admissao).format('DD/MM/YYYY')} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Funcão" value={funcionario.contrato.funcao.func_descricao} disabled />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField fullWidth size="small" variant="outlined" className="input" label="Unidade de Trabalho" value={funcionario.contrato.unidadetrabalho.unin_descricao ? funcionario.contrato.unidadetrabalho.unin_descricao : funcionario.contrato.unidadetrabalho.pessoa.pes_tipo === 'FISICA' ? funcionario.contrato.unidadetrabalho.pessoa.fisica.pesf_nome : funcionario.contrato.unidadetrabalho.pessoa.juridica.pesj_razaosocial} disabled />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} sm={12} xs={12}>
                  <TextField multiline rows={5} fullWidth size="small" variant="outlined" className="input" label="Observação" value={funcionario.contrato.contt_observacao} disabled />
                </Grid>
              </Grid>
              {funcionario.fun_status && 
                <Grid container spacing={1} direction="row">
                  {pessoa.fisica.funcionario.fun_id !== funcionario.fun_id &&
                    <Grid item md={4} sm={12} xs={12}>
                      <Link to={`/funcionario/contrato/demitir/${funcionario.fun_id}`}>
                        <Button className="btnCadastrar" variant="contained" color="secondary">
                          Demitir Funcionário
                        </Button>
                      </Link>
                    </Grid>
                  }
                  {permissoesPromover.perm_inserir &&
                    <Grid item md={4} sm={12} xs={12}>
                      <Link to={`/funcionario/contrato/promover/${funcionario.fun_id}`}>
                        <Button className="btnCadastrar" variant="contained" color="primary">
                          Promover Funcionário
                      </Button>
                      </Link>
                    </Grid>
                  }
                </Grid>
              }
            </div>
          </TabPanel>
          <TabPanel value={value} index={0}>
            <Afastamento funcionario={funcionario} {...props.props} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Observacoes funcionario={funcionario} {...props.props} />
          </TabPanel>
        </React.Fragment>
      }
      
    </div>
  );
}
