import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { dados, open } = props

  return (
    <div>
      <Modal
        open={open}
				onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <div style={modalStyle} className={classes.paper}>
						<Grid container direction="row" spacing={1} className="borderBottom">
							<Grid item md={12} xs={12} sm={12}>
								<h5 className="tituloConfirmacao">Baixar Parcela</h5>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} className='mg_top_20'>
							<Grid item md={12} xs={12} sm={12}>
								<TextField
									id="standard-select-currency"
									select
									variant="outlined"
									className="input"
									fullWidth
									label="Conta*"
									size="small"
									SelectProps={{
										native: true,
									}}
									name="contfin_id"
									value={dados.contfin_id}
									onChangeCapture={(e) => props.updateField(e)}
									InputLabelProps={{ shrink: true }}
								>
									<option value="">--Escolha--</option>
									{props.contasFinanceira.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
										return (
											<option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
										)
									})}
								</TextField>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} className='mg_top_20'>
							<Grid item md={12} xs={12} sm={12}>
								<TextField
									id="standard-select-currency"
									select
									variant="outlined"
									className="input"
									fullWidth
									label="Forma de Pagamento*"
									size="small"
									SelectProps={{
										native: true,
									}}
									name="valpag_formapagto_id"
									value={dados.valpag_formapagto_id}
									onChangeCapture={(e) => props.updateField(e)}
									InputLabelProps={{ shrink: true }}
									disabled={dados.contfin_id === '' ? true : false}
								>
									<option value={dados.valpag_formapagto_id === '' ? '' : dados.valpag_formapagto_id}>{dados.valpag_formapagto_id === '' ? '--Escolha--' : dados.formpag_descricao}</option>
									{props.formasPagamento.map(forma => {
										if (forma.formpag_id !== dados.valpag_formapagto_id) {
											if (dados.contfin_tipo_conta === 'BANCO') {
												if (forma.formpag_slug !== 'dinheiro') {
													return (
														<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
													)
												}
											} else if (dados.contfin_tipo_conta === 'COFRE') {
												if (forma.formpag_slug === 'dinheiro') {
													return (
														<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
													)
												}
											} else {
												return (
													<option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
												)
											}
										}
										return false
									})}
								</TextField>
							</Grid>
						</Grid>
						<Grid container direction="row" spacing={1} className='mg_top_10'>
							<Grid item md={6} xs={6} sm={6}>
								<Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.disabledButtonsSalvar}>Confirmar</Button>
							</Grid>
							<Grid item md={6} xs={6} sm={6}>
								<Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.disabledButtonsSalvar}>Cancelar</Button>
							</Grid>
						</Grid>
					</div>
        </React.Fragment>
      </Modal>
    </div >
  );
}
