import React, { Component } from 'react'
import { Grid, TextField } from '@material-ui/core'
import axios from 'axios'

const initalState = {
  franqueados: {
    list: []
  },
  unidades: {
    list: []
  },
  dadosUsuario: {}
}

export default class FormQuemTrabalha extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    this.setState({
      dadosUsuario: pessoa
    })

    axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())
      .then(resp => {

        this.setState({
          unidades: {
            list: resp.data
          }
        })

      }).catch(error => {
        console.log(error)
      })

    axios.get(`${process.env.REACT_APP_API_URL}/franqueados`, this.getToken())
      .then(resp => {

        this.setState({
          franqueados: {
            list: resp.data
          }
        })

      }).catch(error => {
        console.log(error)
      })
  }

  render() {
    const nivelAcesso = this.state.dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
    const dados = this.props.dados

    return (
      <React.Fragment>
        <Grid container direction="row" spacing={1} alignItems="flex-end">
          <Grid item md={12}> <h3>Para quem irá trabalhar</h3> </Grid>
          <Grid item md={4} className="mg_top_20">
            <TextField
              id="standard-select-currency"
              select
              label="Funcionario para"
              variant="outlined"
              className="input"
              value={dados.paraquem.toLowerCase()}
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="paraquem"
              onChangeCapture={(e) => this.props.updateParaquem(e)}
              InputLabelProps={{ shrink: true }}
              disabled={this.props.enableUpdate}
            >
              {nivelAcesso === "FRANQUEADOR" &&
                <React.Fragment>
                  <option key="franqueador" value="franqueador"> Minha Franqueadora </option>
                  <option key="franqueado" value="franqueado"> Franqueado </option>
                  <option key="unidade" value="unidade"> Unidade </option>
                </React.Fragment>
              }
              {nivelAcesso === "FRANQUEADO" &&
                <React.Fragment>
                  <option key="franqueado" value="franqueado"> Minha Franquia </option>
                  <option key="unidade" value="unidade"> Unidade </option>
                </React.Fragment>
              }
              {nivelAcesso === "UNIDADE" &&
                <React.Fragment>
                  <option key="unidade" value="unidade"> Minha Unidade </option>
                </React.Fragment>
              }
            </TextField>
          </Grid>
          <Grid item md={4}>
            {nivelAcesso === 'FRANQUEADOR' && this.props.selects_unidadetrabalho.franqueado &&
              <TextField
                id="standard-select-currency"
                select
                label="Para qual Franquia"
                variant="outlined"
                className="input"
                value={this.props.unidadetrabalho_id}
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="paraquem"
                onChangeCapture={(e) => this.props.updateContrato(e)}
                InputLabelProps={{ shrink: true }}
                disabled={this.props.enableUpdate}
              >
                <option value=""> Selecionar Franqueado </option>
                {this.state.franqueados.list.map(franqueado => {
                  return (
                    <option key={franqueado.unin_id} value={franqueado.unin_id}>{franqueado.pessoa.pes_tipo === 'FISICA' ? franqueado.pessoa.fisica.pesf_nome : franqueado.pessoa.juridica.pesj_razaosocial} </option>
                  )
                })}
              </TextField>
            }
            {(['FRANQUEADOR', 'FRANQUEADO'].includes(nivelAcesso)) && this.props.selects_unidadetrabalho.unidade &&
              <TextField
                id="standard-select-currency"
                select
                label="Para qual Unidade"
                variant="outlined"
                className="input"
                value={this.props.unidadetrabalho_id}
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="paraquem"
                onChangeCapture={(e) => this.props.updateContrato(e)}
                InputLabelProps={{ shrink: true }}
                disabled={this.props.enableUpdate}
              >
                <option value=""> Selecionar Unidade </option>
                {this.state.unidades.list.map(unidade => {
                  return (
                    <option key={unidade.unin_id} value={unidade.unin_id}>{unidade.unin_numero} - {unidade.unin_descricao} </option>
                  )
                })}
              </TextField>
            }
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}