import React, { Component } from 'react'
import './CupomDesconto.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import Alert from './../../components/templates/Alert'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import { Link } from 'react-router-dom'
import real from './../../services/real'

const initalState = {
  cupons: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'cupom_hash', numeric: false, disablePadding: false, label: 'Hash'},
    { id: 'cupom_descricao', numeric: false, disablePadding: false, label: 'Descrição'},
    { id: 'cupom_tipo', numeric: false, disablePadding: false, label: 'Tipo do Desconto' },
    { id: 'cupom_valor', numeric: false, disablePadding: false, label: 'Valor' },
    { id: 'cupom_data_inicio', numeric: false, disablePadding: false, label: 'Data de Inicio' },
    { id: 'cupom_data_fim', numeric: false, disablePadding: false, label: 'Data de Fim' },
    { id: 'cupom_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    cupom_hash: "",
    cupom_tipo: "",
    cupom_ativo: true
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  }
}

export default class Cargo extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cupom-desconto')[0]

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    await this.filtrar()

    this.setState({
      unin_tipo,
      loading: false
    })

  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async filtrar(){
    try {
      let {filtro} = this.state

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/cupomDesconto/list`, filtro ,this.getToken())
      
      this.setState({
        cupons: {
          list: data.map(cupom => {
            return {
              _id: cupom.cupom_id,
              cupom_hash: cupom.cupom_hash,
              cupom_descricao: cupom.cupom_descricao,
              cupom_tipo: cupom.cupom_tipo === 'PORCENTAGEM' ? 'Porcentagem' : 'Valor Monetário',
              cupom_valor: cupom.cupom_tipo === 'PORCENTAGEM' ? cupom.cupom_valor + '%' : 'R$ ' + real(cupom.cupom_valor),
              cupom_data_inicio: moment(cupom.cupom_data_inicio).format('DD/MM/YYYY'),
              cupom_data_fim: moment(cupom.cupom_data_fim).format('DD/MM/YYYY'),
              cupom_ativo: cupom.cupom_ativo
            }
          })
        },
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  updateFiltro(event){
    let {filtro} = this.state
    filtro[event.target.name] = event.target.value
    this.setState({filtro})
  }

  render() {
    const { filtro, cupons, permissoes } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Cupons...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Cupom de Desconto</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={3} xs={12}>
                    <Link to="/cupom_desconto/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Cupom
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Hash" variant="outlined" size="small" name="cupom_hash" value={filtro.cupom_hash} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tipo do Desconto"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cupom_tipo"
                    value={filtro.cupom_tipo}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    <option value="PORCENTAGEM"> Porcentagem </option>
                    <option value="VALOR"> Valor Monetário</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Ativo?"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="cupom_ativo"
                    value={filtro.cupom_ativo}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value={true}> Sim </option>
                    <option value={false}> Não</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={this.state.loadingImprimir}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    headCell={this.state.cabecalhoTabela} 
                    rows={cupons.list} 
                    acoes={this.state.acoesTabela}
                    urlUpdate="/cupom_desconto/cadastro/" 
                    urlView="/cupom_desconto/view/"
                  />
                </Grid>
              </Grid>
            </div>
          }         
          <Alert 
            open={this.state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={this.state.alerta.severity}
            message={this.state.alerta.message} 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}