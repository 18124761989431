import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip';

function getModalStyle() {
  return {
   
  };
}

const useStyles = makeStyles((theme) => ({
  paperBoleto: {
    position: 'absolute',
    width: '94%',
    height: '90%',
    marginLeft: '3%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
  paperPix: {
    position: 'absolute',
    width: '400px',
    height: '450px',
    marginLeft: 'calc(50% - 150px)',
    marginTop: '30px',
    padding: '10px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    overflowY: "auto",
    listStyle: "none",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={props.boletoSelecionado.tipo_transaction === 'BOLETO' ? classes.paperBoleto: classes.paperPix}>
          {props.boletoSelecionado.tipo_transaction === 'BOLETO' &&
            <iframe title="Boleto"  style={{width: '100%', height: '100%'}} src={props.boletoSelecionado.bank_slip.url_slip_pdf} ></iframe>
          }

          {props.boletoSelecionado.tipo_transaction === 'PIX' &&
           <React.Fragment>
            <Tooltip title='Copiar QRCode' aria-label="add">
              <img onClick={e => props.copyLink(props.boletoSelecionado.bank_slip.url_slip_pdf)} style={{width: '100%', cursor: 'pointer'}} src={props.boletoSelecionado.bank_slip.url_slip_pdf} alt="qrcode" />
            </Tooltip>
            <Grid container spacing={1} direction="row">
              <Grid item md={12} xs={12} sm={12}>
                <Tooltip title='Copiar Texto' aria-label="add">
                  <p onClick={e => props.copyLink(props.boletoSelecionado.bank_slip.boleto_digitable_line)} style={{fontSize: '16px', textAlign: 'center', cursor: 'pointer'}}><b>PIX COPIA E COLA</b></p>
                </Tooltip>
              </Grid>
            </Grid>
          </React.Fragment>
          }
          <br />
        </div>
      </Modal>
    </div>
  );
}
