import React, { Component } from 'react'
import './../Relatorios.scss'
import './../../../main/ultil.scss'
import { Grid } from '@material-ui/core'
import Chart from "react-google-charts";
import moment from 'moment'

const initalState = {
  dataChart: [],
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
		func_id: "",
		fun_id: ""
  }
}

export default class FlyHigh extends Component {
  state = { ...initalState }

  async componentWillMount() {
    const dados = JSON.parse(localStorage.getItem('grafico_carteira_mensalidade'))
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    this.setState({
      dataChart: dados.dataChart,
      filtro: dados.filtro,
      pessoa
    })
    
    setTimeout(() => {
      window.print()
      this.props.history.push("/carteira_mensalidades");
      this.setState({ loading: false })
      return;
    }, 500);
 
  }    

  render() {
    const { filtro, pessoa } = this.state
		
		return (
      <div id="app">
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={12} xs={12} sm={12}>
            <p style={{fontSize: '42px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'center'}}><b>Fly High - Unidades</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Relatório: {moment(new Date()).format('DD/MM/YYYY HH:mm')}</b></p>
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Gerado por: {pessoa.fisica.pesf_nome.split(' ')[0]}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Inicial: {moment(filtro.data_inicial).format('DD/MM/YYYY')}</b></p>
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Data Final: {moment(filtro.data_final).format('DD/MM/YYYY')}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12}>
            <Chart 
              chartType="BarChart" 
              width="100%" 
              height={`${(this.state.dataChart.length - 1) * 50}px`}
              data={this.state.dataChart}
              options={{
                chartArea: {height: '90%'},
                baseline: 0,
                hAxis: {
                  title: 'Porcentagem',
                  minValue: 0
                },
                vAxis: {
                  title: 'Unidades'
                },
                legend: "none"
              }} 
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}