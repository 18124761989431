import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmar {props.unidade.unin_status ? 'Inativação' : 'Ativação'} </h5>
            </Grid>
          </Grid>
					<br />
          <Grid container direction="row" spacing={1}>
						<Grid item md={12} xs={12} sm={12}>
							<TextField
								id="outlined-multiline-static"
								label="Observação"
								className="input"
								multiline
								rows={5}
								size="small"
								variant="outlined"
								name="hisatiuni_observacao"
								value={props.dadosDesativacao.hisatiuni_observacao}
								onChange={(e) => props.updateField(e)}
								InputLabelProps={{ shrink: true }}
							/>
						</Grid>
          </Grid>
          <br />
          {props.unidade.unin_status &&
            <React.Fragment>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <h6>Usuarios Logados:</h6>
                </Grid>
              </Grid>
              {props.usuariosLogados.map(usuario => {
                return (
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={12} xs={12} sm={12}>
                      <span> - {usuario['pessoa.fisica.pesf_nome']}</span>
                    </Grid>
                  </Grid>
                )
              })}
              <br />
            </React.Fragment>
          }
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='avisoConciliacao'>Ao desativar a unidade, os usuários terão as suas sessões encerradas!</p>
            </Grid>
          </Grid>
          <br />
					<Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.dadosDesativacao.hisatiuni_observacao === "" ? true : false}>Confirmar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Sair</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
