import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@material-ui/core'
import CurrencyInput from './../mask/CurrencyInput'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import real  from '../../services/real'
import Table from './../Table'
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment'

function getModalStyle() {
  return {
   
  };
}

let cabecalhoTabela = [
  { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Serviço' },
  { id: 'dia_vencimento', numeric: true, disablePadding: true, label: 'Dia do Vencimento' },
  { id: 'parametro', numeric: false, disablePadding: true, label: 'Parâmetro' },
  { id: 'valor_form', numeric: true, disablePadding: true, label: 'Valor' },
  { id: 'habilita_valor_min', numeric: true, disablePadding: true, label: 'Hab. Mínimo' },
  { id: 'valor_min', numeric: true, disablePadding: true, label: 'Valor Mínimo' },
  { id: 'habilita_valor_max', numeric: true, disablePadding: true, label: 'Hab. Máximo' },
  { id: 'valor_max', numeric: true, disablePadding: true, label: 'Valor Máximo' },
  { id: 'inativa_unidade', numeric: false, disablePadding: true, label: 'Inativa Unidade' },
  { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' },
]

let acoesTabela = ['editar', 'remove']

let tabela_paramentros = [
  {
    parametro: 'FIXO',
    value: 'Fixo'
  },
  {
    parametro: 'ALUNOS',
    value: 'Por Alunos'
  },
  {
    parametro: 'NUM_WHATS',
    value: 'Por Números do Whats'
  },
  {
    parametro: 'ALUNOS_EAD',
    value: 'Por Alunos EAD'
  },
]

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '96%',
    marginLeft: '2%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {dados, servico, produtos, update, addItem, contasFinanceira, formasPagamento, editarItem, removerItem, 
    disabledButton, unidades, disabledUnidade} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Novo Contrato</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            {/* <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Codigo" 
                variant="outlined" 
                size="small"
                name="codigo" 
                value={dados.codigo} 
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid> */}
            <Grid item md={4} xs={12} sm={4}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_id"
                value={dados.unidade_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={disabledUnidade}
              >
                <option value={""}> Selecionar Unidade </option>
                {unidades.map((unidade, key) => {
                  return (
                    <option key={key} value={unidade.id}> {`${unidade.numero} - ${unidade.descricao}`} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField type="date"
                className="input"
                label="Data Inicio Vigência*"
                variant="outlined"
                name="data_ini_vig"
                value={dados.data_ini_vig}
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Tempo Contrato*" 
                type="number"
                variant="outlined" 
                size="small" 
                name="tempo_contrato" 
                value={dados.tempo_contrato} 
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade Tempo Duração*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_tempo"
                value={dados.unidade_tempo}
                disabled={dados.data_ini_vig === ''}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value=''> Selecionar Unidade </option>
                {/* <option value='DIA'> Dia </option> */}
                <option value='MÊS'> Mês </option>
                {/* <option value='SEMESTRE'> Semestre </option> */}
                <option value='ANO'> Ano </option>
                
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField type="date"
                className="input"
                label="Data Fim Vigência*"
                variant="outlined"
                name="data_fim_vig"
                value={dados.data_fim_vig}
                disabled
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                label="Forma de Pagamento*"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="formpag_id"
                value={dados.formpag_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha--</option>
                {formasPagamento.map(forma => {
                  return (
                    <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={4}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                label="Conta Financeira*"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="contfin_id"
                value={dados.contfin_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
                disabled={dados.formpag_id === ''}
              >
                <option value="">--Escolha--</option>
                {contasFinanceira.filter(param => param.contfin_tipo_conta === (parseInt(dados.formpag_id) === 1 ? 'COFRE': 'BANCO')).map(conta => {
                  return (
                    <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Qtd. Dias Pagamento*"
                type="number"
                variant="outlined" 
                size="small"
                name="dias_pagamento" 
                value={dados.dias_pagamento} 
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="% Juros*"
                variant="outlined" 
                size="small" 
                name="juros" 
                value={dados.juros} 
                InputProps={{ inputComponent: CurrencyInput }}
                onChange={(e) => props.updateField(e)} 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h4>Adicionar Serviços:</h4>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={3} xs={12} sm={4}>
              <TextField
                id="standard-select-currency"
                select
                label="Serviço*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="prod_id"
                value={servico.prod_id}
                onChangeCapture={(e) => props.updateFieldServico(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value={servico.prod_id !== '' ? servico.prod_id : ''}> {servico.prod_id !== '' ? servico.prod_descricao : 'Selecionar Serviço'}  </option>
                {produtos.filter(param => param._id !== parseInt(servico.prod_id)).map((produto, key) => {
                  return (
                    <option key={key} value={produto._id}> {produto.prod_descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField className="input" type='number' label="Dia de Vencimento*" variant="outlined" size="small" name="dia_vencimento" value={servico.dia_vencimento} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField
                id="standard-select-currency"
                select
                label="Parâmetro*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="parametro"
                value={servico.parametro}
                onChangeCapture={(e) => props.updateFieldServico(e)}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {servico.parametro === "" &&
                  <option value="">
                    Selecionar Parâmetro
                  </option>
                }
                <option value="FIXO">
                  Fixo
                </option>
                <option value="ALUNOS">
                  Por Alunos
                </option>
                <option value="ALUNOS_EAD">
                  Por Alunos EAD
                </option>
                <option value="NUM_WHATS">
                  Por Números do Whats
                </option>
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={servico.valor} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Inativa Unidade</FormLabel>
                <FormControlLabel className="mg_left_10"
                  control={
                    <Switch
                      name="inativa_unidade"
                      color="primary"
                      checked={servico.inativa_unidade}
                    />
                  }
                  size="small"
                  label={servico.inativa_unidade ? 'Sim' : 'Não'}
                  name="inativa_unidade"
                  onChange={(e) => props.updateFieldInativaUnidadeServico(e)}
                />
              </FormControl>
            </Grid>
            <Grid item md={1} xs={12} sm={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Ativo</FormLabel>
                <FormControlLabel className="mg_left_10"
                  control={
                    <Switch
                      name="Ativo"
                      color="primary"
                      checked={servico.ativo}
                    />
                  }
                  size="small"
                  label={servico.ativo ? 'Sim' : 'Não'}
                  name="ativo"
                  onChange={(e) => props.updateFieldAtivoServico(e)}
                />
              </FormControl>
            </Grid>
            {(servico.parametro !== "FIXO" && servico.parametro !== "") &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Informar Valor Mínimo</FormLabel>
                    <FormControlLabel className="mg_left_10"
                      control={
                        <Switch
                          name="Ativo"
                          color="primary"
                          checked={servico.habilita_valor_min}
                        />
                      }
                      size="small"
                      label={servico.habilita_valor_min ? 'Sim' : 'Não'}
                      name="habilita_valor_min"
                      onChange={(e) => props.updateFieldMinServico(e)}
                    />
                  </FormControl>
                </Grid>
                {servico.habilita_valor_min &&
                  <Grid item md={2} xs={12} sm={2}>
                    <TextField className="input" label="Valor Mínimo*" variant="outlined" size="small" name="valor_min" value={servico.valor_min} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
                  </Grid>
                }
                <Grid item md={2} xs={12} sm={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Informar Valor Máximo</FormLabel>
                    <FormControlLabel className="mg_left_10"
                      control={
                        <Switch
                          name="Ativo"
                          color="primary"
                          checked={servico.habilita_valor_max}
                        />
                      }
                      size="small"
                      label={servico.habilita_valor_max ? 'Sim' : 'Não'}
                      name="habilita_valor_max"
                      onChange={(e) => props.updateFieldMaxServico(e)}
                    />
                  </FormControl>
                </Grid>
                {servico.habilita_valor_max &&
                  <Grid item md={2} xs={12} sm={2}>
                    <TextField className="input" label="Valor Máximo*" variant="outlined" size="small" name="valor_max" value={servico.valor_max} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
                  </Grid>
                }
              </React.Fragment>
            }
            <Grid item md={2} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <Table
                editar={e => editarItem(e)}
                remove={e => removerItem(e)}
                headCell={cabecalhoTabela}
                rows={dados.produtos.map(value => {
                  return {
                    ...value,
                    valor_form: value.valor, 
                    parametro: tabela_paramentros.filter(param => param.parametro === value.parametro)[0].value,
                    valor_min: value.habilita_valor_min ? value.valor_min : 'N/A',
                    valor_max: value.habilita_valor_max ? value.valor_max : 'N/A'
                  }
                })}
                acoes={acoesTabela}
                noRemove={false} 
              />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={disabledButton}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={disabledButton}>Confirmar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
