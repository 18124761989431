import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { Grid, TextField, FormControlLabel, Switch, Button } from '@material-ui/core'

const FormEndereco = ({ disableEndereco, dados, updateField,
  pegaEndereco, updateFieldPrincipal, removerItem, disabledDados, dadosDisabled }) => (
  <React.Fragment>
    <Grid container direction="row">
      <Grid item md={12}>
        <h4>Endereço {dados.index}</h4>
      </Grid>
    </Grid>
    <Grid container spacing={2} direction="row">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          onBlur={e => pegaEndereco(e)}
          fullWidth
          className="input"
          label="CEP*"
          variant="outlined"
          size="small"
          name="cep"
          value={dados.cep}
          inputProps={{ maxLength: 8 }}
          onChange={(e) => updateField(e, dados.index)}
          InputLabelProps={{
            shrink: true
          }} 
          disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
        <span id={`error-end-cep${dados.index}`} className="error">Informar CEP</span>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Rua*" variant="outlined" size="small" name="rua" value={dados.rua} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
        <span id={`error-end-rua${dados.index}`} className="error">Informar a Rua</span>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Número*" variant="outlined" size="small" name="numero" value={dados.numero} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
        <span id={`error-end-numero${dados.index}`} className="error">Informar Número</span>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Complemento" variant="outlined" size="small" name="complemento" value={dados.complemento} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
      </Grid>
    </Grid>
    <Grid container spacing={2} direction="row" className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Bairro*" variant="outlined" size="small" name="bairro" value={dados.bairro} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Cidade*" variant="outlined" size="small" name="cidade" value={dados.cidade} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField fullWidth className="input" label="Estado*" variant="outlined" size="small" name="estado" value={dados.estado} onChange={(e) => updateField(e, dados.index)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados(dadosDisabled) : false}/>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo Endereço*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="tipoEndereco"
          value={dados.tipoEndereco}
          onChangeCapture={(e) => updateField(e, dados.index)}
          InputLabelProps={{ shrink: true }}
          disabled={disabledDados ? disabledDados(dadosDisabled) : false}
        >
          <option value="RESIDENCIAL">
            RESIDENCIAL
					</option>
          <option value="COMERCIAL">
            COMERCIAL
					</option>
        </TextField>
      </Grid>
    </Grid>
    <Grid container spacing={2} direction="row">
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="principal"
              color="primary"
              checked={dados.principal}
            />
          }
          label="Principal*"
          name="principal"
          onChange={(e) => updateFieldPrincipal(e)}
          disabled={disabledDados ? disabledDados(dadosDisabled) : false}
        />
      </Grid>
      <Grid item md={7} xs={12} sm={6}></Grid>
      <Grid item md={3} xs={12} sm={6}>
        {(disabledDados ? !disabledDados(dadosDisabled) : false ) &&
          <Button fullWidth onClick={() => removerItem(dados.index)} color="secondary" variant="contained" size="small" startIcon={<DeleteIcon />}>Remover Endereço</Button>
        }
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormEndereco