import React, { Component } from 'react'
import './Templates.scss'
import Tooltip from '@material-ui/core/Tooltip';

const initalState = {
  dadosUsuario: {}
}

export default class Header extends Component {

  state = { ...initalState }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    this.setState({
      dadosUsuario: pessoa
    })
  }

  openDadosUsuario() {
    this.props.history.push("/usuario");
    return;
  }

  render() {
    const { dadosUsuario } = this.state
    return (
      <footer className="footer">
        {process.env.REACT_APP_APLICACAO === "homologacao" &&
          <React.Fragment>
            <div className="boxHomologacaoRodape">
              {/* <i className="fas fa-exclamation-triangle"></i> */}
              <p>Você está em um ambiente de Homologação</p>
            </div>
            <div className="boxHomologacaoFundo">
              {/* <i className="fas fa-exclamation-triangle"></i> */}
              <p>Você está em um ambiente de Homologação</p>
            </div>
          </React.Fragment>
        }
        <Tooltip title='Meus Dados' aria-label="add" placement="top">
          <div className="dadosUsuario" onClick={e => this.openDadosUsuario()}>
            <p>Nome Usuário: {dadosUsuario.fisica.pesf_nome}</p>
            <p>Acesso: {dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.unin_tipo}</p>
          </div>
        </Tooltip>
      </footer>
    )
  }
}
