import React from 'react'; 
import { Grid } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export default function Pagamento(props) {
  return (
    <React.Fragment>
      <Grid container direction="row" spacing={2}>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}>Olá {props.aluno.nome_social ? props.aluno.nome_social : props.aluno.nome}, segue a baixo alguns links úteis:</span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}><a target='blank' href="https://aluno.eduqtecnologia.com.br/dominio?britishandamerican"><ArrowRightIcon style={{marginTop: '-5px'}}/>LINK DA PLATAFORMA*</a></span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center', marginTop: '-20px'}}>
          <span style={{fontSize: '18px'}}>*Para o primeiro acesso ao Portal EAD, a sua senha é o seu CPF</span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}><a target='blank' href={`${process.env.REACT_APP_URL}/portal_aluno/${props.aluno.pesf_pessoa_id}`}><ArrowRightIcon style={{marginTop: '-5px'}}/>LINK DO PORTAL FINANCEIRO*</a></span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center', marginTop: '-20px'}}>
          <span style={{fontSize: '18px'}}>*Para o acesso ao Portal Financeiro, a sua senha é o seu CPF</span >
        </Grid>
        <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}><a target='blank' href='https://wa.me/5544991593770'><ArrowRightIcon style={{marginTop: '-5px'}}/>WHATSAPP - Para Qualquer Duvida</a></span >
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} className="mg_top_20">
        <Grid item md={5} xs={12} sm={4}></Grid>
        <Grid item md={2} xs={12} sm={4}>
          <img src="https://system-british-no-delete.s3.amazonaws.com/logo_2.png" alt="logo" />
        </Grid>                
      </Grid>
    </React.Fragment>
  )
}