import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField } from '@material-ui/core'

const FormMovimentacao = ({ dados, updateFieldSaque, updateFieldDeposito, movcontfin_tipo,
  updateFieldTipo, contasFinanceira, verificaValorSaldo, updateFieldConciliadoSaque, updateFieldConciliadoDeposito }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      {movcontfin_tipo !== 'TRANSFERENCIA' &&
        <Grid item md={2} xs={12} sm={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Tipo da Mov."
            variant="outlined"
            className="input"
            fullWidth
            size="small"
            SelectProps={{
              native: true,
            }}
            name="movcontfin_tipo"
            value={movcontfin_tipo}
            onChangeCapture={(e) => updateFieldTipo(e)}
            InputLabelProps={{ shrink: true }}
          >
            <option value="">Selecionar Tipo</option>
            <option value="TRANSFERENCIA">Transferência</option>
            <option value="SAQUE">Saque</option>
            <option value="DEPOSITO">Deposito</option>
          </TextField>
        </Grid>
      }
      {movcontfin_tipo === 'SAQUE' &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Conta Fin.*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="movcontfin_contfin_id"
              value={dados[0].movcontfin_contfin_id}
              onChangeCapture={(e) => updateFieldSaque(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Selecionar Conta</option>
              {contasFinanceira.map(conta => {
                return (
                  <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                )
              })}
            </TextField>
          </Grid>
          {dados[0].movcontfin_contfin_id !== '' &&
            <React.Fragment>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="movcontfin_data_hora_conta" value={dados[0].movcontfin_data_hora_conta} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={4} xs={12} sm={6}>
                <TextField className="input" label="Descrição*" variant="outlined" size="small" name="movcontfin_descricao" value={dados[0].movcontfin_descricao} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Valor*" variant="outlined" size="small" name="movcontfin_valor" value={dados[0].movcontfin_valor} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
              </Grid>
            </React.Fragment>
          }
        </React.Fragment>
      }
      {movcontfin_tipo === 'DEPOSITO' &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Conta Fin.*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="movcontfin_contfin_id"
              value={dados[1].movcontfin_contfin_id}
              onChangeCapture={(e) => updateFieldDeposito(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Selecionar Conta</option>
              {contasFinanceira.map(conta => {
                return (
                  <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                )
              })}
            </TextField>
          </Grid>
          {dados[1].movcontfin_contfin_id !== '' &&
            <React.Fragment>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="movcontfin_data_hora_conta" value={dados[1].movcontfin_data_hora_conta} onChange={(e) => updateFieldDeposito(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={4} xs={12} sm={6}>
                <TextField className="input" label="Descrição*" variant="outlined" size="small" name="movcontfin_descricao" value={dados[1].movcontfin_descricao} onChange={(e) => updateFieldDeposito(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Valor*" variant="outlined" size="small" name="movcontfin_valor" value={dados[1].movcontfin_valor} onChange={(e) => updateFieldDeposito(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
              </Grid>
            </React.Fragment>
          }
        </React.Fragment>
      }
    </Grid>
    {movcontfin_tipo === 'TRANSFERENCIA' &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              label="Tipo da Mov."
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="movcontfin_tipo"
              value={movcontfin_tipo}
              onChangeCapture={(e) => updateFieldTipo(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Selecionar Tipo</option>
              <option value="TRANSFERENCIA">Transferência</option>
              <option value="SAQUE">Saque</option>
              <option value="DEPOSITO">Deposito</option>
            </TextField>
          </Grid>
          <Grid item md={5} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              label="Conta Debito*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="movcontfin_contfin_id"
              value={dados[0].movcontfin_contfin_id}
              onChangeCapture={(e) => updateFieldSaque(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Selecionar Conta</option>
              {contasFinanceira.map(conta => {
                if (conta.contfin_tipo_conta !== 'CAIXATURNO') {
                  return (
                    <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                  )
                }
                return false
              })}
            </TextField>
          </Grid>
          <Grid item md={5} xs={12} sm={4}>
            <TextField
              id="standard-select-currency"
              select
              label="Conta Credito*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="movcontfin_contfin_id"
              value={dados[1].movcontfin_contfin_id}
              onChangeCapture={(e) => updateFieldDeposito(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Selecionar Conta</option>
              {contasFinanceira.map(conta => {
                return (
                  <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                )
              })}
            </TextField>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          {dados[0].movcontfin_contfin_id !== '' &&
            <React.Fragment>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="movcontfin_data_hora_conta" value={dados[0].movcontfin_data_hora_conta} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={8} xs={12} sm={6}>
                <TextField className="input" label="Descrição" variant="outlined" size="small" name="movcontfin_descricao" value={dados[0].movcontfin_descricao} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Valor*" variant="outlined" size="small" name="movcontfin_valor" value={dados[0].movcontfin_valor} onChange={(e) => updateFieldSaque(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </React.Fragment>
    }
    <br />
    <hr />
  </React.Fragment>
)

export default FormMovimentacao