import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import moment from 'moment'
import SaveIcon from '@material-ui/icons/Save';
import FormProfessorVigenciaHorario from './../../../components/forms/FormProfessorVigenciaHorario'

import ModalErro from './../../../components/modals/Erro'

const initalState = {
  professor: {
    prof_formacao: "",
    disponibilidades: [],
    aulas: []
  },
  disponibilidade: {
    disp_id: '',
    disp_dia_semana: "",
    disp_hora_inicial: "",
    disp_hora_final: "",
    disp_data_vig_inicio: "",
    disp_data_vig_fim: "",
    index: ""
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const funcionario = this.props.funcionario

    try {
      const { data: respProfessor } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/${funcionario.professor.prof_id}`, this.getToken())

      const disponibilidades = []
      for (let i = 0; i < respProfessor.disponibilidades.length; i++) {
        disponibilidades.push({
          disp_id: respProfessor.disponibilidades[i].disp_id,
          disp_dia_semana: respProfessor.disponibilidades[i].disp_dia_semana,
          disp_hora_inicial: respProfessor.disponibilidades[i].disp_hora_inicial,
          disp_hora_final: respProfessor.disponibilidades[i].disp_hora_final,
          disp_data_vig_inicio: respProfessor.disponibilidades[i].disp_data_vig_inicio ? moment(respProfessor.disponibilidades[i].disp_data_vig_inicio).format('YYYY-MM-DD') : "",
          disp_data_vig_fim: respProfessor.disponibilidades[i].disp_data_vig_fim ? moment(respProfessor.disponibilidades[i].disp_data_vig_fim).format('YYYY-MM-DD') : "",
          index: i + 1
        })
      }

      let professor = {
        prof_id: respProfessor.prof_id,
        prof_formacao: respProfessor.prof_formacao,
        disponibilidades,
      }
      
      this.setState({
        professor
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Books, tente mais tarde"
      })

      this.backPage()
    }

  }

	updateFieldDataVigencia(event, index) {
    const professor = { ...this.state.professor }

    const indexDisp = professor.disponibilidades.findIndex(param => param.index === index)

    professor.disponibilidades[indexDisp][event.target.name] = event.target.value
    this.setState({ professor })
  }

  async salvar(){
    const {professor} = this.state
    const funcionario = this.props.funcionario

    try {

      for (let i = 0; i < professor.disponibilidades.length; i++) {
        if (professor.disponibilidades[i].disp_data_vig_inicio === "" || !professor.disponibilidades[i].disp_data_vig_inicio) {
          delete professor.disponibilidades[i].disp_data_vig_inicio
        }
        if (professor.disponibilidades[i].disp_data_vig_fim === "" || !professor.disponibilidades[i].disp_data_vig_fim) {
          delete professor.disponibilidades[i].disp_data_vig_fim
        }
      }

      const dados = {
        prof_id: professor.prof_id,
        prof_funcionario_id: funcionario.fun_id,
				disponibilidades: professor.disponibilidades
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/professores/dispHorarios`, dados, this.getToken())

      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        }
      })

      window.location.reload()
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro no Cadastro!",
          descricao: (response.data && response.data.message) ? response.data.message : "Tente mais tarde."
        }
      })
      this.setState({ loadingSalvar: false })
    }
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const {professor } = this.state
    const funcionario = this.props.funcionario
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Observações...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div style={{ 'marginTop': '10px' }}>
            {!funcionario.fun_status && 
              <h4>Este colaborador não se encontra mais ativo!</h4>
            }
            {funcionario.fun_status && 
              <React.Fragment>
                <FormProfessorVigenciaHorario
                  disponibilidades={professor.disponibilidades}
                  updateField={(e, index) => this.updateFieldDataVigencia(e, index)} />

                <br />
                <hr />
                <br />
                <Grid container direction="row" spacing={1}>
                  <Grid item md={9} xs={false} sm={8}></Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          </div>
        }

      </React.Fragment>
    )
  }
}