import React from 'react'

import { Grid, TextField, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const FormUsuario = ({ dados, updateField, handleClickShowPassword, handleClickShowPasswordRepetir, showPassword, showPasswordRepetir, corSenha, nivelSenha }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Login" variant="outlined" name="login" value={dados.login} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={dados.senha}
            onChange={(e) => updateField(e)}
            className="input"
            size="small"
            name="senha"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => handleClickShowPassword(e)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        <span>Nivel Senha: <b style={{ color: corSenha }}>{nivelSenha}</b> </span>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Repetir Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPasswordRepetir ? 'text' : 'password'}
            value={dados.repetirSenha}
            onChange={(e) => updateField(e)}
            className="input"
            size="small"
            name="repetirSenha"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => handleClickShowPasswordRepetir(e)}
                  edge="end"
                >
                  {showPasswordRepetir ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormUsuario