import React, { Component } from 'react'
import './Aula.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button, TextField } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import FormHorarioAulaFixa from '../../components/forms/FormHorarioAulaFixa'

const initalState = {
  horarios: [],
  aula: {
    aula_id: '',
    aula_nome: '',
    book: [{
      bok_id: '',
      bok_nome: '',
      cursos: [{
        curso_id: '',
        curso_nome: ''
      }]
    }]
  },
  horario: {
    horaulfix_dia_semana: "",
    horaulfix_hora_inicial: "",
    horaulfix_hora_final: "",
    index: ""
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true
}

export default class CadastroAula extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'horario_aula_fixas')[0]

    const { match: { params } } = this.props;

    if (params.aulaId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.aulaId) {
      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.unin_id
          })
        }

        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/horarioAula/listByAula/${params.aulaId}/${dados.unidadesnegocio[0]}`, this.getToken())

        const horarios = []

        for (let i = 0; i < data.list.length; i++) {
          horarios.push({
            horaulfix_id: data.list[i].horaulfix_id,
            horaulfix_dia_semana: data.list[i].horaulfix_dia_semana,
            horaulfix_hora_inicial: data.list[i].horaulfix_hora_inicial,
            horaulfix_hora_final: data.list[i].horaulfix_hora_final,
            horaulfix_unin_id: dados.unidadesnegocio[0],
            index: i + 1
          })
        }

        this.setState({
          horarios,
          aula: data.aula,
          loading: false
        })

      } catch (error) {
        this.setState({
          modalErro: true,
          msgErro: error.response.data.error,
        })
      }
    }
  }

  getCursos(cursos) {
    let cursosString = ''

    cursos.forEach(curso => {
      cursosString = cursosString + ` ${curso.curso_nome},`
    });

    return cursosString
  }


  updateFieldHorario(hora, diaSemana) {
    let { horarios } = this.state

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    const index = horarios.findIndex(horario => horario.horaulfix_dia_semana === diaSemana && horario.horaulfix_hora_inicial === hora)

    if (index === -1) {
      const horaQuebrada = hora.split(":")
      const horaFinal = parseInt(horaQuebrada[0]) + 1
      const horaulfix_hora_final = horaFinal < 10 ? `0${horaFinal}:00` : `${horaFinal}:00`

      horarios.push({
        horaulfix_dia_semana: diaSemana,
        horaulfix_hora_inicial: hora,
        horaulfix_hora_final,
        horaulfix_unin_id: dados.unidadesnegocio[0],
        index: horarios.length + 1
      })
    } else {
      horarios.splice(index, 1)

      let aux = []

      for (let i = 0; i < horarios.length; i++) {
        aux.push({
          horaulfix_dia_semana: horarios[i].horaulfix_dia_semana,
          horaulfix_hora_inicial: horarios[i].horaulfix_hora_inicial,
          horaulfix_hora_final: horarios[i].horaulfix_hora_final,
          horaulfix_unin_id: dados.unidadesnegocio[0],
          index: i + 1
        })
      }

      horarios = aux
    }

    this.setState({ horarios })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  backPage(timeout = 1000) {
    this.setState({
      aula: {
        aula_id: '',
        aula_nome: '',
        book: [{
          bok_id: '',
          bok_nome: '',
          cursos: [{
            curso_id: '',
            curso_nome: ''
          }]
        }]
      },
      disponibilidade: {
        disp_dia_semana: "",
        disp_hora_inicial: "",
        disp_hora_final: "",
        disp_data_vig_inicio: "",
        disp_data_vig_fim: "",
        index: ""
      }
    })
    const self = this
    setTimeout(() => {
      self.props.history.push("/aula");
    }, timeout)
    this.setState({
      aula: initalState.aula,
      book: initalState.book
    })
  }

  async salvar() {
    const { horarios } = this.state

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const unidades = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    try {
      const dados = {
        unin_id: unidades.unidadesnegocio[0],
        horarios,
      }

      const { match: { params } } = this.props;

      await axios.post(`${process.env.REACT_APP_API_URL}/horarioAula/${params.aulaId}`, dados, this.getToken())

      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        }
      })

      this.backPage()
      return
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro!",
          descricao: response.data.message
        },
      })
    }

  }

  render() {
    const { aula, horarios, horario } = this.state
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    const qtdUnidade = dados.unidadesnegocio.length
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {qtdUnidade > 1 &&
            <div>
              <h3>Por favor selecionar apenas uma unidade!</h3>
            </div>
          }
          {qtdUnidade === 1 &&
            <React.Fragment>
              {this.state.loading &&
                <React.Fragment>
                  <div className="progressCircular">
                    <CircularProgress />
                  </div>
                  <div className="progressText">
                    <p>Carregando Horários...</p>
                  </div>
                </React.Fragment>
              }
              {!this.state.loading &&
                <div>
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={12}>
                      <h2 className="titulo">Horário Aulas Fixas</h2>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="cardEndereco mg_top_20" id="view">
                    <Grid item md={3}>
                      <TextField className="input" label="Aula" variant="outlined" size="small" name="aula_nome" value={aula.aula_nome} disabled />
                    </Grid>
                    {aula.book[0] &&
                      <React.Fragment>
                        <Grid item md={3}>
                          <TextField className="input" label="Book" variant="outlined" size="small" name="bok_nome" value={aula.book[0].bok_nome} disabled />
                        </Grid>
                        <Grid item md={3}>
                          <TextField className="input" label="Cursos" variant="outlined" size="small" name="curso_nome" value={this.getCursos(aula.book[0].cursos)} disabled />
                        </Grid>
                        {/*<Grid item md={3}>
                <TextField className="input" label="Nome" variant="outlined" size="small" name="aula_nome" value={aula.aula_nome} disabled />
                </Grid> */}
                      </React.Fragment>
                    }
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_20">
                    <FormHorarioAulaFixa
                      updateField={e => this.updateFieldHorario(e)}
                      horario={horario}
                      horarios={horarios}
                      updateFieldHorario={(hora, diaSemana) => this.updateFieldHorario(hora, diaSemana)}
                      updateAllDia={(diaSemana) => this.updateAllDia(diaSemana)}
                    />
                  </Grid>
                  <Grid spacing={1} container direction="row" className="mg_top_20">
                    <Grid item md={6}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => this.backPage(e)}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}