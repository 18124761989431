import React from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Table from './../Table'
import Autocomplete from '@material-ui/lab/Autocomplete'

const cabecalhoBanco = [
  { id: 'url_banco', numeric: false, disablePadding: true, label: 'Logo' },
  { id: 'nome', numeric: false, disablePadding: true, label: 'Banco' }
]

const acoesBanco = ['remove']

const FormBancosIsentos = ({dados, banco, bancos, updateFieldBanco, addBanco, removerItemBanco, updateFieldAtivoBanco }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={6} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldBanco(event, value)}
          freeSolo
          options={bancos.filter(param => {
						if(dados.bancos_isentos.findIndex(value => parseInt(value.id) === parseInt(param.id)) === -1) return true
						return false
					})}
          value={banco}
          getOptionLabel={option => `${option.numero} - ${option.nome}`}
          renderTags={options => 
            {
              return (
                options.map(option =>
                  <React.Fragment>
                    <img src={option.logo} style={{width: '40px', height: '40px', marginRight: '5px'}} alt='logo'/> {/*Mock image, attribute in option*/}
                    {`${option.numero} - ${option.nome}`}
                  </React.Fragment>))
        
            }}
            renderOption={option => {
              return (
                <React.Fragment>
                  <img src={option.logo} style={{width: '40px', height: '40px', marginRight: '5px'}} alt='logo'/> {/*Mock image, attribute in option*/}
                  {`${option.numero} - ${option.nome}`}
                </React.Fragment>
              );
            }}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Banco*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${banco.numero} - ${banco.nome}`} />
          )}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addBanco(e)}>Adicionar</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <h4 className='titulo'>Bancos Isentos:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={6} xs={12} sm={12}>
        <Table
          remove={e => removerItemBanco(e)}
          headCell={cabecalhoBanco}
          rows={dados.bancos_isentos.map(item => {
            return {
              _id: item.id,
              url_banco: item.logo,
              nome: `${item.numero} - ${item.nome}`
            }
          })}
          acoes={acoesBanco} />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormBancosIsentos