import React, { Component } from 'react'
import './../Funcionario.scss'
import './../../../main/ultil.scss'
import { Grid, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import SaveIcon from '@material-ui/icons/Save';
import FormProfessor from './../../../components/forms/FormProfessor'
import ModalErro from './../../../components/modals/Erro'

const initalState = {
  professor: {
    prof_formacao: "",
    disponibilidades: [],
    aulas: []
  },
  aulasSeleciondas: [],
  aulasSeleciondasFiltradas: [],
  aulas: {
    list: []
  },
  aulasFiltrada: {
    list: []
  },
  books: {
    list: []
  },
  book: {
    bok_id: "",
    bok_nome: "",
    bc_obrigatorio: true,
    bc_ativo: true,
    produto: {},
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}


export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const funcionario = this.props.funcionario

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aula`, this.getToken())

      const aulas = []

      data.forEach(aula => {
        aulas.push({
          aula_id: aula.aula_id,
          aula_nome: aula.aula_nome,
          aula_tipo: aula.aula_tipo,
          aula_tipo_horario: aula.aula_tipo_horario,
          aula_duracao: aula.aula_duracao,
          book: aula.book,
          ativo: aula.aula_ativo,
        })
      })

      this.setState({
        aulas: {
          list: aulas
        },
        aulasFiltrada: {
          list: aulas
        },
        loading: false
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar as Aulas, tente mais tarde"
      })

      this.backPage()
    }

    try {
      const { data: books } = await axios.get(`${process.env.REACT_APP_API_URL}/book`, this.getToken())

      this.setState({
        books: {
          list: books
        }
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Books, tente mais tarde"
      })

      this.backPage()
    }

    try {
      const { data: respProfessor } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/${funcionario.professor.prof_id}`, this.getToken())

      let professor = {
        prof_id: respProfessor.prof_id,
        prof_formacao: respProfessor.prof_formacao,
        disponibilidades: [],
        aulas: respProfessor.aulas
      }

      let aulasSeleciondas = respProfessor.aulas.map(aula => {
        return {
          aula_id: aula.aula_id,
          aula_nome: aula.aula_nome,
          aula_tipo: aula.aula_tipo,
          aula_tipo_horario: aula.aula_tipo_horario,
          aula_duracao: aula.aula_duracao,
          book: aula.book,
          ativo: aula.aula_ativo
        }
      })

      this.setState({
        professor,
        aulasSeleciondas
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Books, tente mais tarde"
      })

      this.backPage()
    }
  }

  updateFieldProfessor(event) {
    const professor = { ...this.state.professor }
    professor[event.target.name] = event.target.value
    this.setState({ professor })
  }

  addItem() {
    if (!this.validateDisponibilidade()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios da Avaliação (*)."
      })
      return
    }

    const { professor, disponibilidade } = this.state
    const disponibilidades = professor.disponibilidades

    if (disponibilidade.disp_id) {
      const index = disponibilidades.findIndex(param => param.index === disponibilidade.index)

      disponibilidades[index] = {
        disp_id: disponibilidade.disp_id,
        disp_dia_semana: disponibilidade.disp_dia_semana,
        disp_hora_inicial: disponibilidade.disp_hora_inicial,
        disp_hora_final: disponibilidade.disp_hora_final,
        index: disponibilidade.index
      }
    } else {
      const index = disponibilidades.length + 1

      disponibilidades.push({
        disp_dia_semana: disponibilidade.disp_dia_semana,
        disp_hora_inicial: disponibilidade.disp_hora_inicial,
        disp_hora_final: disponibilidade.disp_hora_final,
        index
      })
    }

    professor.disponibilidades = disponibilidades

    this.setState({
      update: false,
      professor,
      disponibilidade: initalState.disponibilidade,
    })

  }

  addAula(aula) {
    const { aulasSeleciondas } = this.state

    aulasSeleciondas.push(aula)

    this.setState({
      aulasSeleciondas,
    })
  }

  removerAula(aula) {
    const { aulasSeleciondas } = this.state

    const index = aulasSeleciondas.findIndex(param => param.aula_id === aula.aula_id)

    aulasSeleciondas.splice(index, 1)

    this.setState({
      aulasSeleciondas,
    })
  }

  // updateFieldBook(event, value) {
  //   const { aulas, aulasSeleciondas } = this.state

  //   if (value) {
  //     const listAulasFiltrada = aulas.list.filter(searching(value.bok_nome))
  //     const listAulasSeleciondasFiltrada = aulasSeleciondas.filter(searching(value.bok_nome))
  //     this.setState({
  //       aulasFiltrada: {
  //         list: listAulasFiltrada
  //       },
  //       aulasSeleciondasFiltradas: listAulasSeleciondasFiltrada,
  //       book: {
  //         bok_id: value.bok_id,
  //         bok_nome: value.bok_nome,
  //         bc_obrigatorio: this.state.book.bc_obrigatorio,
  //         bc_ativo: this.state.book.bc_ativo,
  //         produto: value.produto
  //       }
  //     })
  //   } else {
  //     this.setState({
  //       aulasFiltrada: {
  //         list: aulas.list
  //       },
  //       aulasSeleciondasFiltradas: aulasSeleciondas,
  //       book: {
  //         bok_id: '',
  //         bok_nome: '',
  //         bc_obrigatorio: '',
  //         bc_ativo: '',
  //         produto: {}
  //       }
  //     })
  //   }
  // }

  removerTodasAulas() {
    this.setState({
      aulasSeleciondas: [],
    })
  }

  addTodasAulas() {
    const { aulas } = this.state

    this.setState({
      aulasSeleciondas: aulas.list,
    })
  }

  async salvar(){
    const {professor, aulasSeleciondas} = this.state
    const funcionario = this.props.funcionario

    try {
      const dados = {
        prof_id: professor.prof_id,
        prof_funcionario_id: funcionario.fun_id,
        aulas: aulasSeleciondas.map(aula => {
          return {
            aula_id: aula.aula_id
          }
        })
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/professores/dispAulas`, dados, this.getToken())

      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        }
      })

      window.location.reload()
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro no Cadastro!",
          descricao: (response.data && response.data.message) ? response.data.message : "Tente mais tarde."
        }
      })
      this.setState({ loadingSalvar: false })
    }
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const {aulas, aulasSeleciondas, book, books, professor } = this.state
    const funcionario = this.props.funcionario
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Observações...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div style={{ 'marginTop': '10px' }}>
            {!funcionario.fun_status && 
              <h4>Este colaborador não se encontra mais ativo!</h4>
            }
            {funcionario.fun_status && 
              <React.Fragment>
                <FormProfessor
                  updateField={e => this.updateFieldProfessor(e)}
                  dados={professor}
                  addItem={e => this.addItem()}
                  addAula={(aula) => this.addAula(aula)}
                  removerAula={(aula) => this.removerAula(aula)}
                  aulas={aulas.list}
                  aulasSeleciondas={aulasSeleciondas}
                  book={book}
                  books={books.list}
                  updateFieldBook={(e, value) => this.updateFieldBook(e, value)}
                  removerTodasAulas={e => this.removerTodasAulas(e)}
                  addTodasAulas={e => this.addTodasAulas(e)}
                />

                <br />
                <hr />
                <br />
                <Grid container direction="row" spacing={1}>
                  <Grid item md={9} xs={false} sm={8}></Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }

            <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          </div>
        }

      </React.Fragment>
    )
  }
}