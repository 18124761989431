import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import React, { Component } from 'react'
import './Franqueado.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormPessoaJuridica from '../../components/forms/FormPessoaJuridica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'

import ModalErro from './../../components/modals/Erro'

import moment from 'moment';
import { format } from 'cnpj'
import axios from 'axios'

const cpf = require('node-cpf-cnpj')
const cnpj = require('node-cpf-cnpj')

const initalState = {
  franqueado: {
    id: "",
    pessoa_id: "",
    descricao: "",
    ativo: "",
    tipoPessoa: "Pessoa Física",
    pessoaFisica: {
      pesf_id: "",
      pessoa_id: "",
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    pessoaJuridica: {
      pesj_id: "",
      pessoa_id: "",
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: ""
    },
    enderecos: [{
      end_id: "",
      cep_id: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: "",
      tipoEndereco: "RESIDENCIAL",
      index: "1"
    }],
    emails: [{
      ema_id: "",
      email: "",
      principal: "",
      index: "1"
    }],
    contatos: [{
      tel_id: "",
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: "",
      index: "1"
    }]
  },
  franqueados: {
    list: [
      {

      }
    ]
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  loadingSalvar: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}

function formatarCEP(str) {
  if(str){
    var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *
  
    return str.replace(re, "$1.$2-$3");
  }

  return ''
}

function formatarTelefone(str) {
  if (str.length === 11) {
    return str.replace(/(\d{2})?(\d{4})?(\d{5})/, "($1) $2-$3")
  } else if (str.length === 10) {
    return str.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3")
  }
}

export default class CadastroFranqueado extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    try {
      const { match: { params } } = this.props;

      const { data: franqueado } = await axios.get(`${process.env.REACT_APP_API_URL}/franqueados/${params.franqueadoId}`, this.getToken())

      let fisica = {}
      let juridica = {}

      let enderecos = []
      let emails = []
      let contatos = []

      if (franqueado.pessoa.pes_tipo === "FISICA") {
        fisica = {
          pesf_id: franqueado.pessoa.fisica.pesf_id,
          pessoa_id: franqueado.frao_pessoa_id,
          nome: franqueado.pessoa.fisica.pesf_nome,
          cpf: cpf.format(franqueado.pessoa.fisica.pesf_cpf),
          rg: franqueado.pessoa.fisica.pesf_rg,
          rg_orgao_emissor: franqueado.pessoa.fisica.pesf_rg_orgao_emissor,
          rg_uf: franqueado.pessoa.fisica.pesf_rg_uf,
          rg_data_emissao: moment(franqueado.pessoa.fisica.pesf_rg_data_emissao).format('YYYY-MM-DD'),
          dataNascimento: moment(franqueado.pessoa.fisica.pesf_datanascimento).format('YYYY-MM-DD')
        }
        this.setState({
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false
        })
      } else {
        juridica = {
          pesj_id: franqueado.pessoa.juridica.pesj_id,
          pessoa_id: franqueado.frao_pessoa_id,
          razaoSocial: franqueado.pessoa.juridica.pesj_razaosocial,
          nomeFantasia: franqueado.pessoa.juridica.pesj_nomefantasia,
          cnpj: format(franqueado.pessoa.juridica.pesj_cnpj),
          inscricaoEstadual: franqueado.pessoa.juridica.pesj_inscricaoestadual,
          dataFundacao: moment(franqueado.pessoa.juridica.pesj_datafundacao).format('YYYY-MM-DD')
        }
        this.setState({
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true
        })
      }

      for (let i = 0; i < franqueado.pessoa.enderecos.length; i++) {
        enderecos.push({
          end_id: franqueado.pessoa.enderecos[i].end_id,
          cep_id: franqueado.pessoa.enderecos[i].end_cep_id,
          rua: franqueado.pessoa.enderecos[i].end_logradouro,
          numero: franqueado.pessoa.enderecos[i].end_numero,
          bairro: franqueado.pessoa.enderecos[i].end_bairro,
          cidade: franqueado.pessoa.enderecos[i].end_cidade,
          estado: franqueado.pessoa.enderecos[i].end_estado,
          cep: formatarCEP(franqueado.pessoa.enderecos[i].end_cep),
          principal: franqueado.pessoa.enderecos[i].end_principal,
          tipoEndereco: franqueado.pessoa.enderecos[i].end_tipo,
          index: i + 1
        })
      }

      for (let i = 0; i < franqueado.pessoa.telefones.length; i++) {
        contatos.push({
          tel_id: franqueado.pessoa.telefones[i].tel_id,
          numero: franqueado.pessoa.telefones[i].tel_numero,
          tipoContato: franqueado.pessoa.telefones[i].tel_tipo,
          principal: franqueado.pessoa.telefones[i].tel_principal,
          index: i + 1
        })
      }

      for (let i = 0; i < franqueado.pessoa.emails.length; i++) {
        emails.push({
          ema_id: franqueado.pessoa.emails[i].ema_id,
          email: franqueado.pessoa.emails[i].ema_email,
          principal: franqueado.pessoa.emails[i].ema_principal,
          index: i + 1
        })
      }

      this.setState({
        franqueado: {
          id: franqueado.unin_id,
          pessoa_id: franqueado.unin_pessoa_id,
          descricao: "",
          ativo: franqueado.unin_status,
          tipoPessoa: franqueado.pessoa.pes_tipo,
          pessoaFisica: fisica,
          pessoaJuridica: juridica,
          enderecos,
          emails,
          contatos
        },
        loading: false
      })


    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar o franqueado, tente mais tarde"
      })

      this.backPage()
    }

  }

  updateFieldPessoaFisica(event) {
    const franqueado = { ...this.state.franqueado }
    franqueado.pessoaFisica[event.target.name] = event.target.value
    this.setState({ franqueado })
  }

  updateFieldPessoaJuridica(event) {
    const franqueado = { ...this.state.franqueado }
    franqueado.pessoaJuridica[event.target.name] = event.target.value
    this.setState({ franqueado })
  }

  updateFieldEndereco(event, index) {
    const franqueado = { ...this.state.franqueado }
    franqueado.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ franqueado })
  }

  updateFieldEmail(event, index) {
    const franqueado = { ...this.state.franqueado }
    franqueado.emails[index - 1][event.target.name] = event.target.value
    this.setState({ franqueado })
  }

  updateFieldContato(event, index) {
    const franqueado = { ...this.state.franqueado }
    franqueado.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ franqueado })
  }

  formataTelefone(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const franqueado = { ...this.state.franqueado }
    franqueado.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    this.setState({ franqueado })
  }

  updateField(event) {

    if (event.target.name === "tipoPessoa") {
      if (event.target.value === "FISICA") {
        this.setState({
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false
        })
      } else {
        this.setState({
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true
        })
      }
    }


    const franqueado = { ...this.state.franqueado }
    franqueado[event.target.name] = event.target.value
    this.setState({ franqueado })
  }

  updateFieldEnderecoPrincipal(event, index) {
    const franqueado = { ...this.state.franqueado }
    const indexAux = franqueado.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      franqueado.enderecos[indexAux].principal = false
    }

    franqueado.enderecos[index - 1].principal = !franqueado.enderecos[index - 1].principal
    this.setState({ franqueado })
  }

  updateFieldContatoPrincipal(event, index) {
    const franqueado = { ...this.state.franqueado }
    const indexAux = franqueado.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      franqueado.contatos[indexAux].principal = false
    }

    franqueado.contatos[index - 1].principal = !franqueado.contatos[index - 1].principal
    this.setState({ franqueado })
  }

  updateFieldEmailPrincipal(event, index) {
    const franqueado = { ...this.state.franqueado }
    const indexAux = franqueado.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      franqueado.emails[indexAux].principal = false
    }

    franqueado.emails[index - 1].principal = !franqueado.emails[index - 1].principal

    this.setState({ franqueado })
  }

  async validarCpfCnpj(event) {

    let cpf_cnpj = event.target.value
    const franqueado = this.state.franqueado

    if (cpf_cnpj.length <= 11) {
      cpf_cnpj = cpf.format(cpf_cnpj)
      if (!cpf.isValid(cpf_cnpj)) {

        franqueado.pessoaFisica.cpf = ""
        this.setState({ franqueado })

        const erro = {
          titulo: "CPF Invalido!",
          descricao: "Por favor corrija o campo CPF"
        }
        this.setState({
          modalErro: true,
          erro
        })

      } else {
        franqueado.pessoaFisica.cpf = cpf_cnpj
        this.setState({ franqueado })
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        franqueado.pessoaJuridica.cnpj = cpf_cnpj
        this.setState({ franqueado })

      } else {
        franqueado.pessoaJuridica.cnpj = ""
        this.setState({ franqueado })
        const erro = {
          titulo: "CNPJ Invalido!",
          descricao: "Por favor corrija o campo CNPJ"
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }
  }

  async pegaEndereco(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const franqueado = { ...this.state.franqueado }
      const enderecos = franqueado.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      franqueado.enderecos[index - 1] = endereco
      this.setState({ franqueado })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        franqueado.enderecos[index - 1] = endereco
        this.setState({ franqueado })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        franqueado.enderecos[index - 1] = endereco
        this.setState({ franqueado })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  addNovoEndereco() {
    const franqueado = { ...this.state.franqueado }
    const enderecos = franqueado.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    franqueado.enderecos = enderecos
    this.setState({ franqueado })
  }

  addNovoContato() {
    const franqueado = { ...this.state.franqueado }
    const contatos = franqueado.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: false,
      index
    })

    franqueado.contatos = contatos
    this.setState({ franqueado })
  }

  addNovoEmail() {
    const franqueado = { ...this.state.franqueado }
    const emails = franqueado.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    franqueado.emails = emails
    this.setState({ franqueado })
  }

  proximo(atual, prox) {
    const { franqueado } = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      let valido = true
      if (franqueado.tipoPessoa === "FISICA") {
        if (franqueado.pessoaFisica.nome === "") {
          valido = false
        }
        if (franqueado.pessoaFisica.cpf === "") {
          valido = false
        }
        if (franqueado.pessoaFisica.rg === "") {
          valido = false
        }
        if (franqueado.pessoaFisica.rg_orgao_emissor === "") {
          valido = false
        }
        if (franqueado.pessoaFisica.rg_uf === "") {
          valido = false
        }
        if (franqueado.pessoaFisica.rg_data_emissao === "") {
          valido = false
        }
        if (franqueado.pessoaFisica.dataNascimento === "") {
          valido = false
        }

      } else {
        if (franqueado.pessoaJuridica.razaoSocial === "") {
          valido = false
        }
        if (franqueado.pessoaJuridica.nomeFantasia === "") {
          valido = false
        }
        if (franqueado.pessoaJuridica.cnpj === "") {
          valido = false
        }
        if (franqueado.pessoaJuridica.inscricaoEstadual === "") {
          valido = false
        }
        if (franqueado.pessoaJuridica.dataFundacao === "") {
          valido = false
        }
      }

      if (valido) {
        this.setState({
          enableDadosPessoa: false,
          enableDadosEndereco: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      franqueado.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEndereco: false,
          enableDadosContato: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      franqueado.contatos.forEach(contato => {
        if (contato.numero === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosContato: false,
          enableDadosEmail: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }
  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async salvar() {
    this.setState({ loadingSalvar: true })
    const { franqueado } = this.state

    let valido = true

    franqueado.emails.forEach(email => {
      if (email.email === "") {
        valido = false
      }
    })

    if (valido) {

      let fisica = {}
      let juridica = {}

      if (franqueado.tipoPessoa === "FISICA") {
        fisica = {
          pessoa_id: franqueado.pessoaFisica.pessoa_id,
          pesf_id: franqueado.pessoaFisica.pesf_id,
          nome: franqueado.pessoaFisica.nome,
          cpf: cpf.strip(franqueado.pessoaFisica.cpf),
          rg: franqueado.pessoaFisica.rg,
          rg_orgao_emissor: franqueado.pessoaFisica.rg_orgao_emissor,
          rg_uf: franqueado.pessoaFisica.rg_uf,
          rg_data_emissao: moment(franqueado.pessoaFisica.rg_data_emissao).format('YYYY-MM-DD'),
          datanascimento: moment(franqueado.pessoaFisica.dataNascimento).format('YYYY-MM-DD'),
          class_tipo: ""
        }
      } else {
        juridica = {
          pessoa_id: franqueado.pessoaJuridica.pessoa_id,
          pesj_id: franqueado.pessoaJuridica.pesj_id,
          razaosocial: franqueado.pessoaJuridica.razaoSocial,
          nomefantasia: franqueado.pessoaJuridica.nomeFantasia,
          inscricaoestadual: franqueado.pessoaJuridica.inscricaoEstadual,
          datafundacao: moment(franqueado.pessoaJuridica.dataFundacao).format('YYYY-MM-DD'),
          cnpj: cnpj.strip(franqueado.pessoaJuridica.cnpj),
          class_tipo: ""
        }
      }

      let emails = []
      let telefones = []
      let enderecos = []

      franqueado.emails.forEach(email => {
        if (email.ema_id) {
          emails.push({
            ema_id: email.ema_id,
            email: email.email,
            principal: email.principal
          })
        } else {
          emails.push({
            email: email.email,
            principal: email.principal
          })
        }
      })

      franqueado.contatos.forEach(telefone => {
        if (telefone.tel_id) {
          telefones.push({
            tel_id: telefone.tel_id,
            numero: telefone.numero,
            principal: telefone.principal,
            tipo: telefone.tipoContato
          })
        } else {
          telefones.push({
            numero: telefone.numero,
            principal: telefone.principal,
            tipo: telefone.tipoContato
          })
        }
      })

      franqueado.enderecos.forEach(endereco => {
        if (endereco.end_id) {
          enderecos.push({
            end_id: endereco.end_id,
            cep_id: endereco.cep_id,
            numero: endereco.numero,
            complemento: "N/A",
            rua: endereco.rua,
            bairro: endereco.bairro,
            cidade: endereco.cidade,
            estado: endereco.estado,
            cep: endereco.cep,
            principal: endereco.principal,
            tipo: endereco.tipoEndereco
          })
        } else {
          enderecos.push({
            cep_id: endereco.cep_id,
            numero: endereco.numero,
            complemento: "N/A",
            rua: endereco.rua,
            bairro: endereco.bairro,
            cidade: endereco.cidade,
            estado: endereco.estado,
            cep: endereco.cep,
            principal: endereco.principal,
            tipo: endereco.tipoEndereco
          })
        }
      })

      const dados = {
        franqueado: {
          tipo: franqueado.tipoPessoa,
          pessoa_id: franqueado.pessoa_id,
          franqueado_id: franqueado.id,
          juridica,
          fisica,
          emails,
          telefones,
          enderecos,
        }
      }

      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/franqueados/${franqueado.id}`, dados, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })
        this.backPage()

      } catch ({ response }) {
        this.setState({ loadingSalvar: false })
        if (response.status === 400) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.handleOpenDialog({})
      }

    } else {
      const erro = {
        titulo: "Campo Vazio!",
        descricao: "Existem campo obrigatorios vazios."
      }
      this.setState({
        modalErro: true,
        erro
      })
    }
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/franqueado");
    }, timeout)
    this.setState({
      unidade: initalState.unidade
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  removeEnderecoFranqueado(index) {
    const franqueado = { ...this.state.franqueado }
    const enderecos = franqueado.enderecos
    const aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    franqueado.enderecos = aux
    this.setState({ franqueado })
  }

  removeContatoFranqueado(index) {
    const franqueado = { ...this.state.franqueado }
    const contatos = franqueado.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    franqueado.contatos = aux
    this.setState({ franqueado })
  }

  removeEmailFranqueado(index) {
    const franqueado = { ...this.state.franqueado }
    const emails = franqueado.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    franqueado.emails = aux
    this.setState({ franqueado })
  }

  render() {
    const { franqueado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11} xs={10} sm={10}>
                  <h2 className="titulo">Atualização de Franqueados</h2>
                </Grid>
                <Grid item md={1} xs={2} sm={2}>
                  {this.state.loadingSalvar &&
                    <CircularProgress />
                  }
                </Grid>
              </Grid>
              {this.state.enableDadosPessoa &&
                <div className="formFranqueado">
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    className="mg_top_20"
                  >
                    <Grid item md={3}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Tipo Pessoa"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="tipoPessoa"
                        value={franqueado.tipoPessoa}
                        onChangeCapture={(e) => this.updateField(e)}
                      >
                        <option key="FISICA" value="FISICA">
                          Pessoa Física
                          </option>
                        <option key="JURIDICA" value="JURIDICA">
                          Pessoa Júridica
                          </option>
                      </TextField>
                    </Grid>
                  </Grid>
                  {this.state.enableDadosPessoaFisica &&
                    <FormPessoaFisica validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaFisica(e)} dados={franqueado.pessoaFisica} />
                  }

                  {this.state.enableDadosPessoaJuridica &&
                    <FormPessoaJuridica validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaJuridica(e)} dados={franqueado.pessoaJuridica} />
                  }
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={9}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEndereco &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Endereço</h3>
                    </Grid>
                  </Grid>
                  {franqueado.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco removerItem={(index) => this.removeEnderecoFranqueado(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEndereco(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldEnderecoPrincipal(e, endereco.index)} updateField={e => this.updateFieldEndereco(e, endereco.index)} dados={endereco} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Contato</h3>
                    </Grid>
                  </Grid>
                  {franqueado.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato formataTelefone={e => this.formataTelefone(e, contato.index)} removerItem={(index) => this.removeContatoFranqueado(index)} updateField={e => this.updateFieldContato(e, contato.index)} dados={contato} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => this.addNovoContato()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEmail &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de E-mail</h3>
                    </Grid>
                  </Grid>
                  {franqueado.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail removerItem={(index) => this.removeEmailFranqueado(index)} updateField={e => this.updateFieldEmail(e, email.index)} dados={email} updateFieldPrincipal={e => this.updateFieldEmailPrincipal(e, email.index)} />
                      </div>
                    )
                  })}
                  <Grid container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button onClick={() => this.addNovoEmail()} color="secondary" variant="contained" className="btn_add" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
              <br />
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}