import React, { Component } from 'react'
import './Schedule.scss'
import './../../main/ultil.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import ModalErro from './../../components/modals/Erro'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import ModalMudarProfessor from './../../components/modals/MudarProfessor'
import { saveAs } from 'file-saver';

const initalState = {
  schedules: {
    list: []
  },
  schedulesFiltrado: {
    list: []
  },
  loading: true,
  loadingDownload: false,
  cabecalhoTabela: [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor_schedule', numeric: false, disablePadding: false, label: 'Professor' },
    { id: 'qtd_alunos', numeric: false, disablePadding: false, label: 'Qtd. Alunos' }
  ],
  erro: {
    titulo: "",
    descricao: ""
  },
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    data: "",
    hora_inicio: "",
    hora_fim: "",
    prof_id: "",
    aula_id: "",
    unin_id: "",
    ageaula_status: 'ABERTO/FECHADO'
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesAlteraProfessor:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  professores: [],
  professor: {
    prof_id: "",
    pesf_nome: ""
  },
  aulas: [],
  aula: {
    aula_id: '',
    aula_nome: ''
  },
  retornoSchedule: [],
  modalMudarProfessor: false,
  agendaSelecionada:{
    _id: '',
    aula_nome: '',
    professor_schedule: '',
    professor_id: '',
    ageaula_data_hora: '',
    qtd_alunos: '',
    alunos: '',
    ageaula_unin_id: ''
  },
  rowProfessores: [],
  professoresUnidade:{
    list: []
  },
  professorSelecionado:{
    prof_id: ''
  },
  verificaAulas: true,
  afastamentos: {
    list: []
  },
  agendasUni:{
    list: []
  }
}

export default class Schedules extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'schedule')[0]
    let permissoesAlteraProfessor = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'altera-professor-schedule')[0]

    this.setState({
      permissoes
    })

    if(permissoesAlteraProfessor){
      this.setState({permissoesAlteraProfessor})
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/byUnidade/${dados.unidadesnegocio[0]}`, this.getToken())

      this.setState({
        professores: data
      })

    } catch (error) {
      console.log(error);
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aula`, this.getToken())

      const aulas = []

      data.filter(param => param.aula_ativo === true).sort((a, b) => (a.aula_nome > b.aula_nome) ? 1 : ((b.aula_nome > a.aula_nome) ? -1 : 0)).map(aula => {
        aulas.push({
          aula_id: aula.aula_id,
          aula_nome: aula.aula_nome
        })
        return false
      })

      this.setState({
        aulas: aulas
      })

    } catch (error) {
      console.log(error);
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }

    this.setState({
      loading: false,
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/schedule");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro
    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })
  }

  validateForm() {
    const { filtro } = this.state

    if (!filtro.data) return false
    return true
  }

  async filtrar() {
    this.setState({
      loading: true
    })


    if (!this.validateForm()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Filtro!",
        descricao: "Preencha o campo da data"
      })
      return
    }

    try {

      let filtro = this.state.filtro

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/schedule/list`, filtro, this.getToken())

      console.log(data)

      const schedules = []

      data.forEach(agenda => {
        schedules.push({
          _id: agenda.ageaula_id,
          aula_nome: agenda.aula.aula_nome,
          aula_id: agenda.aula.aula_id,
          aula_duracao: agenda.aula.aula_duracao,
          professor_schedule: agenda.professor.funcionario.fisica.pesf_nome,
          professor_id: agenda.professor.prof_id,
          ageaula_data_hora: moment(agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm'),
          ageaula_data_hora_sem_form: agenda.ageaula_data_hora,
          qtd_alunos: agenda.alunos.length,
          ageaula_unin_id: agenda.ageaula_unin_id,
          alunos: agenda.alunos
        })
      })

      this.setState({
        schedules: {
          list: schedules
        },
        schedulesFiltrado: {
          list: schedules
        },
        retornoSchedule: data,
        loading: false,
      })

    } catch ({ response }) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async imprimir() {

    this.setState({
      loadingDownload: true
    })

    if (!this.validateForm()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro ao imprimir!",
        descricao: "Realizar o Filtro antes da Impressão"
      })
      return
    }

    let filtro = this.state.filtro

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    filtro.unidadesnegocio = dados.unidadesnegocio
    filtro.retornoSchedule = this.state.retornoSchedule

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/gerarSchedule`, filtro, this.getToken())

      const dados = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, `Schedule.pdf`);

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  updateFieldProfessorFiltro(event, value) {
    const filtro = this.state.filtro

    if (!value) {
      filtro.prof_id = ''

      this.setState({
        professor: {
          prof_id: '',
          pesf_nome: ''
        },
        filtro
      })
      return false
    }


    filtro.prof_id = value.prof_id

    this.setState({
      professor: value,
      filtro
    })
  }

  updateFieldAulaFiltro(event, value) {
    const filtro = this.state.filtro

    if (!value) {
      filtro.aula_id = ''
      this.setState({
        aula: {
          aula_id: '',
          aula_nome: ''
        },
        filtro
      })
      return false
    }


    filtro.aula_id = value.aula_id

    this.setState({
      aula: value,
      filtro
    })
  }

  async trocarProfessor(row){
    const { filtro } = this.state

    try {

      let amanha = new Date(filtro.data)
      amanha = amanha.setDate(amanha.getDate() + 1);
      amanha = moment(amanha).format('YYYY-MM-DD')

      const dados = {
        data_inicial: filtro.data + ' 00:01',
        data_final: amanha + ' 23:59'
      }

      const periodo = {
        data_inicial: moment(filtro.data).format('YYYY-MM-DD'),
        data_final: moment(amanha).format('YYYY-MM-DD'),
      }
      
      const { data: professores } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/byUnidade/${row.ageaula_unin_id}`, this.getToken())
      const { data: afastamentos } = await axios.post(`${process.env.REACT_APP_API_URL}/afastamentoFuncionario/periodo/${row.ageaula_unin_id}`, dados, this.getToken())
      const { data: agendas } = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/byUnidade/periodo/${row.ageaula_unin_id}`, periodo, this.getToken())
      
      console.log(professores)

      this.setState({
        professoresUnidade:{
          list: professores
        },
        afastamentos: {
          list: afastamentos
        },
        agendasUni:{
          list: agendas
        },
        modalMudarProfessor: true,
        agendaSelecionada: {...row}
      })

      this.getProfDisp(row.aula_id, row.ageaula_data_hora_sem_form, row.ageaula_unin_id, row)
    } catch ({ response }) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  handleCloseModalMudarProfessor(){
    this.setState({
      modalMudarProfessor: false,
      agendaSelecionada: {
        _id: '',
        aula_nome: '',
        professor_schedule: '',
        professor_id: '',
        ageaula_data_hora: '',
        qtd_alunos: '',
        ageaula_unin_id: '',
        alunos: ''
      }
    })
  }

  async getProfDisp(aulaSelc, dataAula, unidade, agenda){
    const {afastamentos, agendasUni} = this.state

    try {
      const { data: professores } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/byUnidadeByAula/${unidade}/${aulaSelc}`, this.getToken())

      let index = null
      const rows = []
      const diaSemana = moment(dataAula).format("dddd").replace("-feira", "").toUpperCase()
      const agendasUnidade = agendasUni.list
      const aula = agenda

      for (let i = 0; i < professores.length; i++) {
        const hora = moment(dataAula).format('HH:mm')
        let horaForm = hora.replace(":", "")
        horaForm = parseInt(horaForm)
        let horaMais = hora.split(":")
        horaMais = parseInt(horaMais[0]) + 1
        horaMais = horaMais < 10 ? `0${horaMais}` : horaMais
        horaMais = horaMais + ':00'
        let horaMenos = hora.split(":")
        horaMenos = parseInt(horaMenos[0]) - 1
        horaMenos = horaMenos < 10 ? `0${horaMenos}` : horaMenos
        horaMenos = horaMenos + ':00'

        let indexAgenda = agendasUnidade.findIndex(agendaUni => {
          const dataHoje = new Date()
          if (dataHoje.getTimezoneOffset() === 240) {
            let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
            dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
            if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
              return true
            } else {
              return false
            }
          } else {
            if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
              return true
            } else {
              return false
            }
          }
        })

        if (indexAgenda === -1) {
          indexAgenda = agendasUnidade.findIndex(agendaUni => {
            const dataHoje = new Date()
            

            if (dataHoje.getTimezoneOffset() === 240) {
              let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
              dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)

              if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                return true
              } else {
                return false
              }
            } else {
              if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id && agendaUni.aula.aula_duracao > 1) {
                return true
              } else {
                return false
              }
            }
          })
        }

        if (aula.aula_duracao === 2) {
          if (indexAgenda === -1) {
            const indexAgendaAnterior = agendasUnidade.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)
  
            if (indexAgendaAnterior !== -1) {
              if (agendasUnidade[indexAgendaAnterior].ageaula_duracao_aula === 2) {
                indexAgenda = agendasUnidade.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)
              }
            }
          }
        }       

        if (indexAgenda === -1) {
          if (aula.aula_duracao === 2) {
            indexAgenda = agendasUnidade.findIndex(agendaUni => {
              const dataHoje = new Date()

              if (dataHoje.getTimezoneOffset() === 240) {
                let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
                dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
                if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD HH:mm")} ${horaMais}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                  return true
                } else {
                  return false
                }
              } else {
                if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD HH:mm")} ${horaMais}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                  return true
                } else {
                  return false
                }
              }
            })

            if (indexAgenda === -1) {
              indexAgenda = agendasUnidade.findIndex(agendaUni => {
                const dataHoje = new Date()
                if (dataHoje.getTimezoneOffset() === 240) {
                  let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
                  dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)

                  if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                    return true
                  } else {
                    return false
                  }
                } else {     

                  if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                    return true
                  } else {
                    return false
                  }
                }
              })
            }
          }
        }

        if (indexAgenda === -1) {
          index = professores[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === horaForm)
  
          if (aula.aula_duracao === 2) {
            index = professores[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === (horaForm + 100))
          }
  
          if (index !== -1) {

            const indexAusencia = afastamentos.list.findIndex(param => {
              if (new Date(`${moment(dataAula).format("YYYY-MM-DD")} ${hora}`).valueOf() <= new Date(param.afafun_data_final).valueOf() && new Date(`${moment(dataAula).format("YYYY-MM-DD")} ${hora}`).valueOf() >= new Date(param.afafun_data_inicial).valueOf() && param.afafun_fun_id === professores[i].funcionario.fun_id) {
                return true
              }else{
                return false
              }
            })

            if (indexAusencia === -1) {
              if (agenda.ageaula_professor_id !== professores[i].prof_id) {
                if (!professores[i].disponibilidades[index].disp_data_vig_fim && !professores[i].disponibilidades[index].disp_data_vig_inicio) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (Number(new Date(professores[i].disponibilidades[index].disp_data_vig_fim)) > Number(new Date(dataAula)) && Number(new Date(professores[i].disponibilidades[index].disp_data_vig_inicio)) < Number(new Date(dataAula))) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (Number(new Date(professores[i].disponibilidades[index].disp_data_vig_fim)) > Number(new Date(dataAula)) && !professores[i].disponibilidades[index].disp_data_vig_inicio) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (!professores[i].disponibilidades[index].disp_data_vig_fim && Number(new Date(professores[i].disponibilidades[index].disp_data_vig_inicio)) < Number(new Date(dataAula))) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                }
              }
            }
  
          }
        }
      }

      this.setState({
        rowProfessores: rows
      })

    } catch (error) {
      console.log(error)
    }
  }

  updateFieldProfessor(event){
    const {agendaSelecionada, professoresUnidade} = this.state

    const indexProf = professoresUnidade.list.findIndex(param => param.prof_id === parseInt(event.target.value))

    agendaSelecionada.professor_id = parseInt(event.target.value)
    agendaSelecionada.professor_schedule = professoresUnidade.list[indexProf].pesf_nome

    this.setState({
      agendaSelecionada
    })

  }

  async salvarTrocaProfessores(){
    const {agendaSelecionada} = this.state

    const dados = [{
      ageaula_id: agendaSelecionada._id,
      ageaula_professor_id: agendaSelecionada.professor_id
    }]

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/trocarProfessor`, dados, this.getToken())
    
      this.setState({
        modalMudarProfessor: false
      })

      this.filtrar()
    } catch ({ response }) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
    
  }

  render() {
    const { filtro, schedulesFiltrado, permissoes, professores, professor, aula, aulas } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Schedules...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Schedules</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="date" className="input" label="Data*" variant="outlined" name="data" value={filtro.data} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="time" className="input" label="Hora Inicio" variant="outlined" name="hora_inicio" value={filtro.hora_inicio} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="time" className="input" label="Hora Fim" variant="outlined" name="hora_fim" value={filtro.hora_fim} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => this.updateFieldProfessorFiltro(event, value)}
                    freeSolo
                    options={professores}
                    value={professor}
                    getOptionLabel={option => option.pesf_nome}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Professor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={professor.pesf_nome} />
                    )}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => this.updateFieldAulaFiltro(event, value)}
                    freeSolo
                    options={aulas}
                    value={aula}
                    getOptionLabel={option => option.aula_nome}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Aula*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={aula.aula_nome} />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={7} xs={12} sm={4}></Grid>
                <Grid item md={1} xs={12} sm={4}>
                  {this.state.loadingDownload &&
                    <CircularProgress />
                  }
                </Grid>
                {permissoes.perm_visualizar &&
                  <React.Fragment>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={this.state.loadingDownload}>
                        Filtrar
                  </Button>
                    </Grid>
                    <Grid item md={2} xs={12} sm={4}>
                      <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={this.state.loadingDownload}>
                        Imprimir
                  </Button>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table 
                    headCell={this.state.cabecalhoTabela} 
                    rows={schedulesFiltrado.list} 
                    acoes={this.state.acoesTabela} 
                    trocarProfessor={e => this.trocarProfessor(e)}
                    permissoesAlteraProfessor={this.state.permissoesAlteraProfessor}/>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalMudarProfessor 
            open={this.state.modalMudarProfessor}
            handleClose={e => this.handleCloseModalMudarProfessor(e)} 
            updateFieldProfessor={e => this.updateFieldProfessor(e)}
            agenda={this.state.agendaSelecionada}
            rowProfessores = {this.state.rowProfessores}
            salvarTrocaProfessores= {e => this.salvarTrocaProfessores()}/>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}