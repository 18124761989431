import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';

import real from './../../services/real'

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { dados, nova_parcela, formas_sem_filtro, open, recusar, updateFieldNovaParcela, valor_troco, handleClose, verificaDataNovaParcela } = props

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          {dados.modal === 'falta_valor' &&
            <div style={modalStyle} className={classes.paper}>
              <Grid container direction="row" spacing={1} className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="tituloConfirmacao">Confirmar Valores</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <p>Os valores informados são inferiores ao valor da parcela, deseja complementar o pagamento?</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => handleClose()}>Sim</Button>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => recusar()} >Não</Button>
                </Grid>
              </Grid>
            </div>
          }
          {dados.modal === 'nova_parcela' &&
            <div style={modalStyle} className={classes.paper}>
              <Grid container direction="row" spacing={1} className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="tituloConfirmacao">Nova Parcela</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <p> Deseja criar uma nova parcela com o valor restante?</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField className="input" label="Valor Restante*" variant="outlined" size="small" value={real(nova_parcela.valor_restante)} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField type='date' className="input" label="Data Vencimento*" variant="outlined" size="small" name='vencimento_nova_parcela' value={nova_parcela.vencimento_nova_parcela} onBlur={e => verificaDataNovaParcela(e)} onChange={e => updateFieldNovaParcela(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: dataHojeFormatado } }} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="inputFormPagamento"
                    label="Forma de Pagamento*"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="forma_pgto_nova_parcela"
                    value={nova_parcela.forma_pgto_nova_parcela}
                    onChangeCapture={(e) => updateFieldNovaParcela(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    {formas_sem_filtro.map(forma => {
                      return (
                        <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmarNovaParcela()}>Confirmar</Button>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.recusarNovaParcela()} >Cancelar</Button>
                </Grid>
              </Grid>
            </div>
          }
          {dados.modal === 'informar_troco' &&
            <div style={modalStyle} className={classes.paper}>
              <Grid container direction="row" spacing={1} className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="tituloConfirmacao">Troco</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <p> Por favor retornar ao clinte o troco no valor de:</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField className="input" label="Valor Troco" variant="outlined" size="small" value={real(valor_troco)} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmarTroco()}>Confirmar</Button>
                </Grid>
              </Grid>
            </div>
          }
        </React.Fragment>
      </Modal>
    </div >
  );
}
