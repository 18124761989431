import React from 'react'

import { Grid, TextField } from '@material-ui/core'

const FormCodigoAlfa = ({ materiais, dados, updateField, unidades }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" type='number' label="Quantidade*" variant="outlined" size="small" name="qtd" value={dados.qtd} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={5} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Material*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="codmatdid_prod_id"
          value={dados.codmatdid_prod_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={dados.codmatdid_prod_id === "" ? "" : dados.codmatdid_prod_id}> {dados.codmatdid_prod_id === "" ? "Selecionar Material" : dados.prod_descricao} </option>
          {materiais.map(material => {
            if (material._id !== dados.codmatdid_prod_id) {
              return (
                <option key={material._id} value={material._id}> {material.prod_descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
      <Grid item md={5} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade Destino*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="hiscod_unin_id"
          value={dados.hiscod_unin_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={dados.hiscod_unin_id === "" ? "" : dados.hiscod_unin_id}> {dados.hiscod_unin_id === "" ? "Selecionar Unidade Destino" : `${dados.unin_numero} - ${dados.unin_descricao}`} </option>
          {unidades.map(unidade => {
            if (unidade._id !== dados.hiscod_unin_id) {
              return (
                <option key={unidade._id} value={unidade._id}>{unidade.unin_numero} - {unidade.unin_descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormCodigoAlfa