
import React, { Component } from 'react'
import './Lembrete.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from './../../components/templates/Alert'
import axios from 'axios'
import moment from 'moment'

const initalState = {
  lembretes: {
    list: []
  },
  loading: false,
  cabecalhoTabela: [
    { id: 'usuario', numeric: false, disablePadding: false, label: 'Usu. Lanç.' },
    { id: 'lemb_data_lembrete', numeric: false, disablePadding: true, label: 'Data Lembrete', orderBy: 'lemb_data_lembreteOrder'},
    { id: 'lemb_titulo', numeric: false, disablePadding: false, label: 'Titulo' },
    { id: 'unidades', numeric: false, disablePadding: false, label: 'Unidades' },
    { id: 'lemb_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: ['update'],
  filtro: {
    pedcomp_numero: "",
    data_inicial: "",
    data_final: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

export default class VendaProduto extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'lembretes')[0]

    if(permissoes){
      this.setState({
        permissoes
      })
    }else{
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: 'Você não tem permissão para acessa essa tela'
        }
      })

      this.backPage()
      return
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      let {data: lembretes} = await axios.post(`${process.env.REACT_APP_API_URL}/lembrete/list`, dados, this.getToken())
      
      this.setState({
        lembretes: {
          list: lembretes.map(value => {
            let unidades = ''

            for (let i = 0; i < value.lembrete_unidades.length; i++) {
              if((i+1) === value.lembrete_unidades.length){
                unidades += `${value.lembrete_unidades[i].unidade.unin_numero} - ${value.lembrete_unidades[i].unidade.unin_descricao}.`
              }else{
                unidades += `${value.lembrete_unidades[i].unidade.unin_numero} - ${value.lembrete_unidades[i].unidade.unin_descricao}, `
              }
            }

            return {
              _id: value.lemb_id,
              lemb_data_lembrete: moment(value.lemb_data_lembrete).format('DD/MM/YYYY HH:mm'),
              lemb_data_lembreteOrder: moment(value.lemb_data_lembrete).format('YYYYMMDDHHmm'),
              lemb_titulo: value.lemb_titulo,
              lemb_descricao: value.lemb_descricao,
              lemb_ativo: value.lemb_ativo,
              usuario: value.usuario_criacao.pessoa.fisica.pesf_nome,
              unidades
            }
          })
        }
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }

    this.setState({
      loading: false
    })
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { filtro, lembretes, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Lembretes...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Lembrete</h1>
                </Grid>
                {permissoes &&
                  <React.Fragment>
                    {permissoes.perm_inserir &&
                      <Grid item md={3} xs={12}>
                        <Link to="/lembretes/cadastro">
                          <Button className="btnCadastrar" variant="contained" color="primary">
                            Cadastrar Lembrete
                          </Button>
                        </Link>
                      </Grid>
                    }
                  </React.Fragment>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate="/lembretes/cadastro/"
                    headCell={this.state.cabecalhoTabela}
                    rows={lembretes.list}
                    acoes={this.state.acoesTabela}
                  />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <Footer history={this.props.history} />
      </div>
    )
  }
}