import React, { Component } from 'react'
import './Home.scss'
import socketClient from './../../components/lib/socket'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

export default class Home extends Component {
  async UNSAFE_componentWillMount() {
    socketClient('',{})  
  }

  render() {
    return (
      <React.Fragment>
        <div className="app-menu-closed" id="app">
          <Main history={this.props.history}>
            <div className="container">
             
            </div>
          </Main>
          <Nav />
          <Footer history={this.props.history} />
        </div>
      </React.Fragment>
    )
  }
}