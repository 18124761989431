import React, { Component } from 'react'
import './../Estoque.scss'
import './../../../main/ultil.scss'

import Main from '../../../components/templates/Main'
import Nav from '../../../components/templates/Nav'
import Footer from '../../../components/templates/Footer'

import FormLocalEstoque from '../../../components/forms/FormLocalEstoque'

import { Grid, Button } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../../components/modals/Erro'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  localEstoque: {
    _id: "",
    descricao: "",
    unidade: "",
    idUnidade: "",
    ativo: true
  },
  localEstoques: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  unidades: {
    list: []
  },
  loading: true,
  loadingSalvar: false
}

export default class UpdateLocalEstoque extends Component {

  state = { ...initalState }

  async UNSAFE_componentWillMount() {

    const { match: { params } } = this.props;
    if (params.localEstoqueId) {
      try {
        const { data: localEstoque } = await axios.get(`${process.env.REACT_APP_API_URL}/localEstoque/${params.localEstoqueId}`, this.getToken())
        this.setState({
          localEstoque: {
            _id: localEstoque.localestoq_id,
            descricao: localEstoque.localestoq_descricao,
            unidade: localEstoque.unidade.unin_descricao,
            idUnidade: localEstoque.unidade.unin_id
          }
        })
      } catch (error) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o perfil, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/unidades`, this.getToken())

      const unidades = []

      data.forEach(unidade => {
        const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.end_principal)

        unidades.push({
          _id: unidade.unin_id,
          numero: unidade.unin_numero,
          descricao: unidade.unin_descricao,
          franqueado: unidade.superior.pessoa.pes_tipo === "FISICA" ? unidade.superior.pessoa.fisica.pesf_nome : unidade.superior.pessoa.juridica.pesj_razaosocial,
          cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
          tipo: unidade.pessoa.pes_tipo,
          ativo: unidade.uni_status
        })
      });

      this.setState({
        unidades: {
          list: unidades
        },
        loading: false
      })

    } catch (error) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar as Unidades, tente mais tarde"
      })

      this.backPage()
    }
  }

  updateField(event) {
    const localEstoque = { ...this.state.localEstoque }
    localEstoque[event.target.name] = event.target.value
    this.setState({ localEstoque })
  }

  validateForm() {
    const { localEstoque } = this.state

    if (!localEstoque.idUnidade) return false
    if (!localEstoque.descricao) return false

    return true
  }

  async salvar() {
    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { localEstoque } = this.state
    const data = {
      localestoq_unin_id: localEstoque.idUnidade,
      localestoq_descricao: localEstoque.descricao,
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/localEstoque/${localEstoque._id}`, data, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()
    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { localEstoque } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }{!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11}>
                  <h2 className="titulo">Atualizar Local de Estoque</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormLocalEstoque updateField={e => this.updateField(e)} dados={localEstoque} dadosUnidades={this.state.unidades.list} />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}