import React, { Component } from 'react'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Cadastrar from './components/Cadastro'

export default class CadastroPlanoContas extends Component {
  render() {
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Cadastrar {...this.props} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}