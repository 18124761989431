import React, { Component } from 'react'
import './PlanoPagamento.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button, TextField } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'
import moment from 'moment';

const initalState = {
  planoPagamento: {
    planpag_id: "",
    planpag_descricao: "",
    planpag_data: "",
    planpag_ativo: true,
    tabelas_valores: [
      {
        tabval_id: "",
        tabval_nome: "",
        tabval_num_parc: "",
        tabval_val_matri: "",
        tabval_val_mensal: "",
        tabval_val_cheio_mensal: "",
        tabval_ativo: true,
        comissoes: [
          {
            comissfunc_id: "",
            comissfunc_func_id: "",
            func_descricao: "",
            comissfunc_valor_comissao: 0,
          }
        ]
      }
    ]
  },
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function converteFloatMoeda(valor) {
  var inteiro = null, decimal = null, c = null, j = null;
  var aux = [];
  valor = "" + valor;
  c = valor.indexOf(".", 0);
  //encontrou o ponto na string
  if (c > 0) {
    //separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
  } else {
    inteiro = valor;
  }

  //pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  //percorre a string acrescentando os pontos
  inteiro = "";
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += aux[c] + '.';
  }
  //retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = "00";
  } else {
    decimal = "" + decimal;
    if (decimal.length === 1) {
      decimal = decimal + "0";
    }
  }


  valor = inteiro + "," + decimal;


  return valor;

}

export default class PlanoPagamentoView extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'plano-pagamento-curso')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_visualizar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.planoPagamentoId) {
      try {
        const { data: planoPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/planoPagamentoCurso/${params.planoPagamentoId}`, this.getToken())

        const dataPlano = moment(planoPagamento.planpag_data, moment.defaultFormat).toDate()
        dataPlano.setMinutes(dataPlano.getMinutes() + dataPlano.getTimezoneOffset() + 60)

        const tabelas_valores = planoPagamento.tabelas_valores
        const auxTabelas = []

        for (let i = 0; i < tabelas_valores.length; i++) {
          const comissoes = tabelas_valores[i].comissoes_funcao
          const auxComissoes = []

          for (let j = 0; j < comissoes.length; j++) {
            auxComissoes.push({
              comissfunc_id: comissoes[j].comissfunc_id,
              comissfunc_func_id: comissoes[j].comissfunc_func_id,
              func_descricao: comissoes[j].funcao.func_descricao,
              comissfunc_valor_comissao: comissoes[j].comissfunc_valor_comissao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
              index: j + 1
            })
          }

          auxTabelas.push({
            tabval_id: tabelas_valores[i].tabval_id,
            tabval_nome: tabelas_valores[i].tabval_nome,
            tabval_num_parc: tabelas_valores[i].tabval_num_parc,
            tabval_val_matri: tabelas_valores[i].tabval_val_matri.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            tabval_val_mensal: tabelas_valores[i].tabval_val_mensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            tabval_val_cheio_mensal: tabelas_valores[i].tabval_val_cheio_mensal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            tabval_ativo: tabelas_valores[i].tabval_ativo,
            tabval_val_comissoes: converteFloatMoeda(somarValores(tabelas_valores[i].comissoes_funcao.map(comissao => { return comissao.comissfunc_valor_comissao }))),
            index: i + 1,
            comissoes: auxComissoes
          })
        }

        this.setState({
          planoPagamento: {
            planpag_id: planoPagamento.planpag_id,
            planpag_descricao: planoPagamento.planpag_descricao,
            planpag_data: moment(dataPlano).format('DD/MM/YYYY'),
            planpag_ativo: planoPagamento.planpag_ativo,
            tabelas_valores: auxTabelas,
          },
          loading: false,
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o plano de pagamento, tente mais tarde"
        })

        this.backPage()
      }
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/plano_pagamento");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { planoPagamento } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div id="view">
              <Grid container spacing={1} direction="row" className="borderBottom" >
                <Grid item md={6} xs={12} sm={6}>
                  <h1 className="titulo">Plano de Pagamento</h1>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Link to={`/plano_pagamento`}>
                    <Button className="btnCadastrar" variant="contained" color="secondary">
                      Voltar
                    </Button>
                  </Link>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Link to={`/plano_pagamento/cadastro/${planoPagamento.planpag_id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Editar Plano
                  </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" value={planoPagamento.planpag_descricao} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Data" variant="outlined" size="small" value={planoPagamento.planpag_data} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Ativo" variant="outlined" size="small" value={planoPagamento.planpag_ativo ? 'Ativo' : 'Inativo'} disabled />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h4>Tabelas:</h4>
                </Grid>
              </Grid>
              {planoPagamento.tabelas_valores.map(tabela => {
                return (
                  <div key={tabela.tabval_id} className='cardEndereco'>
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={4} xs={12} sm={6}>
                        <TextField className="input" label="Nome" variant="outlined" size="small" value={tabela.tabval_nome} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField className="input" label="N. de parcelas" variant="outlined" size="small" value={tabela.tabval_num_parc} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField className="input" label="Matrícula" variant="outlined" size="small" value={tabela.tabval_val_matri} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField className="input" label="Mensalidade" variant="outlined" size="small" value={tabela.tabval_val_mensal} disabled />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField className="input" label="Mensalidade Cheia" variant="outlined" size="small" value={tabela.tabval_val_cheio_mensal} disabled />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField className="input" label="Ativo" variant="outlined" size="small" value={tabela.tabval_ativo ? 'Ativo' : 'Inativo'} disabled />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <h4>Honorários: </h4>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                      {tabela.comissoes.map(comissao => {
                        return (
                          <Grid item md={2} xs={12} sm={6}>
                            <TextField className="input" label={comissao.func_descricao} variant="outlined" size="small" value={comissao.comissfunc_valor_comissao} disabled />
                          </Grid>
                        )
                      })}
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField className="input" label='Total Honorários' variant="outlined" size="small" value={tabela.tabval_val_comissoes} disabled />
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
            </div>
          }

          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}