import React, { Component } from 'react'
import './Funcionario.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button } from '@material-ui/core'
import FormNovoContrato from '../../components/forms/FormNovoContrato'
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import ModalErro from './../../components/modals/Erro'
import axios from 'axios'

const cpf = require('node-cpf-cnpj');

const initalState = {
  contrato: {
    paraquem: "FRANQUEADOR",
    selects_unidadetrabalho: {
      franqueador: true,
      franqueado: false,
      unidade: false
    }
  },
  franqueados: [],
  funcionario: {
    _id: "",
    dataAdmissao: "",
    dataDemissao: "",
    unidadetrabalho_id: "",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    ativo: "",
  },
  novoContrato: {
    unidadetrabalho_id: "",
    mudar_unidade: false,
    departamento: "",
    idDepartamento: "",
    cargo: "",
    idCargo: "",
    funcao: "",
    idFuncao: "",
    dataInicial: "",
    dataFinal: "",
    observacao: "",
    paraOnde: ""
  },
  funcionarios: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  unidades: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  loadingSalvar: false,
  status: '',
  unin_tipo: 'UNIDADE',
  edit: false
}

export default class CadastroFuncionario extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
    const novoContrato = this.state.novoContrato

    if (unin_tipo === 'UNIDADE') {
      novoContrato.paraOnde = 'UNIDADE'
      this.setState({
        novoContrato
      })
    }

    this.setState({
      unin_tipo
    })

    this.setState({
      contrato: {
        paraquem: "FRANQUEADOR",
        selects_unidadetrabalho: {
          franqueador: true,
          franqueado: false,
          unidade: false
        }
      },
      funcionario: {
        id: "",
        dataAdmissao: "",
        dataDemissao: "",
        unidadetrabalho_id: "",
        contrato: {
          departamento: "",
          idDepartamento: "",
          cargo: "",
          idCargo: "",
          funcao: "",
          idFuncao: "",
          dataInicial: "",
          dataFinal: "",
          observacao: ""
        },
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        usuario: {
          login: "",
          senha: "",
          repetirSenha: "",
          usu_perfil_id: ""
        },
        contas: [{
          cb_agencia: "",
          cb_numero: "",
          cb_gerente: "",
          cb_chave_pix: "",
          cb_banco_id: "",
          index: 1,
          banco: {
            banco_id: "",
            banco_nome: "",
            banco_numero: "",
            banco_padraocosif: ""
          }
        }],
        ativo: "",
      },
    })

    const dataHoje = new Date()
    const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

    const { match: { params } } = this.props;

    if (params.funcionarioId) {
      try {
        this.setState({ enableUpdate: true })
        const { data: funcionario } = await axios.get(`${process.env.REACT_APP_API_URL}/funcionarios/${params.funcionarioId}`, this.getToken())
  
        this.setState({
          funcionario: {
            _id: funcionario.fun_id,
            dataAdmissao: moment(funcionario.fun_data_admissao).format('YYYY-MM-DD'),
            dataDemissao: moment(funcionario.fun_data_demissao).format('YYYY-MM-DD'),
            unidadetrabalho_id: funcionario.contrato.unidadetrabalho.unin_id,
            matricula: funcionario.fun_matricula,
            contrato: {
              contt_id: funcionario.contrato.contt_id,
              departamento: funcionario.contrato.funcao.cargo.departamento.dep_descricao,
              idDepartamento: funcionario.contrato.funcao.cargo.dep_id,
              cargo: funcionario.contrato.funcao.cargo.car_descricao,
              idCargo: funcionario.contrato.funcao.cargo.car_id,
              funcao: funcionario.contrato.funcao.func_descricao,
              idFuncao: funcionario.contrato.funcao.func_id,
              dataInicial: moment(funcionario.contrato.contt_datainicio).format('YYYY-MM-DD'),
              dataFinal: dataHojeFormatado,
              observacao: funcionario.contrato.contt_observacao,
              unidadetrabalho: funcionario.contrato.unidadetrabalho,
              ativo: funcionario.contrato.contt_ativo
            },
            pessoaFisica: {
              nome: funcionario.fisica.pesf_nome,
              cpf: cpf.format(funcionario.fisica.pesf_cpf),
              rg: funcionario.fisica.pesf_rg,
              rg_orgao_emissor: funcionario.fisica.pesf_rg_orgao_emissor,
              rg_uf: funcionario.fisica.pesf_rg_uf,
              rg_data_emissao: moment(funcionario.fisica.pesf_rg_data_emissao).format('YYYY-MM-DD'),
              dataNascimento: moment(funcionario.fisica.pesf_datanascimento).format('YYYY-MM-DD')
            },
            ativo: funcionario.fun_status
          },
          novoContrato: {
            departamento: '',
            idDepartamento: '',
            cargo: '',
            idCargo: '',
            funcao: '',
            idFuncao: '',
            dataInicial: dataHojeFormatado,
            dataFinal: '',
            observacao: '',
            unidadetrabalho: '',
            unidadetrabalho_id: '',
            ativo: true,
            paraOnde: novoContrato.paraOnde
          },
          loading: false,
          status: params.status
        })
  
      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o funcionario, tente mais tarde"
        })
  
        this.backPage()
      }
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/departamentos/list`, {}, this.getToken())

      this.setState({
        departamentos: {
          list: data
        }
      })
    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Departamentos, tente mais tarde"
      })

      this.backPage()
    }

    try {

      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupoAll`, {}, this.getToken())

      this.setState({
        unidades: {
          list: unidades.sort((a, b) => (a.unin_id > b.unin_id) ? 1 : (b.unin_id > a.unin_id) ? -1 : 0).map(unidade => {
            let unin_descricao = ''
            if (unidade.unin_tipo === 'FRANQUEADOR') {
              unin_descricao = `${unidade.pessoa.pes_tipo === "JURIDICA" ? unidade.pessoa.juridica.pesj_razaosocial : unidade.pessoa.fisica.pesf_nome}`
            } else if (unidade.unin_tipo === 'FRANQUEADO') {
              unin_descricao = `${unidade.pessoa.pes_tipo === "JURIDICA" ? unidade.pessoa.juridica.pesj_razaosocial : unidade.pessoa.fisica.pesf_nome}`
            } else if (unidade.unin_tipo === 'UNIDADE') {
              unin_descricao = `${unidade.unin_numero} - ${unidade.unin_descricao}`
            }

            return {
              unin_id: unidade.unin_id,
              unin_descricao,
              unin_tipo: unidade.unin_tipo
            }
          })
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    this.setState({
      contrato: initalState.contrato,
      funcionario: {
        _id: "",
        dataAdmissao: "",
        dataDemissao: "",
        unidadetrabalho_id: "",
        contrato: {
          departamento: "",
          idDepartamento: "",
          cargo: "",
          idCargo: "",
          funcao: "",
          idFuncao: "",
          dataInicial: "",
          dataFinal: "",
          observacao: ""
        },
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        ativo: "",
      },
      novoContrato: {
        unidadetrabalho_id: "",
        mudar_unidade: false,
        departamento: "",
        idDepartamento: "",
        cargo: "",
        idCargo: "",
        funcao: "",
        idFuncao: "",
        dataInicial: "",
        dataFinal: "",
        observacao: "",
        paraOnde: ""
      }
    })
    setTimeout(() => {
      self.props.history.push("/funcionario");
    }, timeout)
  }

  async updateField(event) {
    const { status, funcionario, novoContrato } = this.state

    if (status === 'demitir') {
      funcionario.contrato[event.target.name] = event.target.value
      this.setState({ funcionario })
    }
    if (status === 'promover') {
      if (event.target && event.target.name === "idDepartamento") {
        if (novoContrato.departamento === parseInt(event.target.value)) return false

        try {
          novoContrato.idDepartamento = parseInt(event.target.value)
          novoContrato.departamento = this.state.departamentos.list.filter(u => u.dep_id === parseInt(event.target.value))[0].dep_descricao

          this.setState({
            novoContrato,
            cargosFiltrado: {
              list: (await axios.get(`${process.env.REACT_APP_API_URL}/cargos/departamento/${parseInt(event.target.value)}`, this.getToken())).data
            }
          })
        } catch (error) {
          console.log(error)
        }
      }

      if (event.target && event.target.name === "idCargo") {
        if (novoContrato.cargo === parseInt(event.target.value)) return false

        try {
          novoContrato.idCargo = parseInt(event.target.value)
          novoContrato.cargo = this.state.cargosFiltrado.list.filter(u => u.car_id === parseInt(event.target.value))[0].car_descricao

          this.setState({
            novoContrato,
            funcaosFiltrado: {
              list: (await axios.get(`${process.env.REACT_APP_API_URL}/funcoes/cargo/${parseInt(event.target.value)}`, this.getToken())).data
            }
          })
        } catch (error) {
          console.log(error)
        }
      }

      if (event.target && event.target.name === "idFuncao") {
        if (novoContrato.funcao === parseInt(event.target.value)) return false

        novoContrato.idFuncao = parseInt(event.target.value)
        novoContrato.funcao = this.state.funcaosFiltrado.list.filter(u => u.func_id === parseInt(event.target.value))[0].func_descricao
        this.setState({ novoContrato })
      }

      if (event.target && event.target.name === 'dataFinal') {
        funcionario.contrato.dataFinal = event.target.value
        novoContrato.dataInicial = event.target.value
        this.setState({ funcionario, novoContrato })
      } else if (event.target && event.target.name === 'unidadetrabalho_id') {
        novoContrato.unidadetrabalho_id = event.target.value
        this.setState({ novoContrato })
      }

    }
    if (status === 'transferir') {
      if (event.target && event.target.name === 'dataFinal') {
        funcionario.contrato.dataFinal = event.target.value
        novoContrato.dataInicial = event.target.value
        this.setState({ funcionario, novoContrato })
      }
    }
  }

  async updateFieldNovoContrato(event) {
    const { novoContrato, unidades } = this.state

    if (event.target.name === 'unidadetrabalho_id') {
      const unidade = unidades.list.filter(param => param.unin_id === parseInt(event.target.value))

      if (unidade[0].unin_tipo === 'UNIDADE') {
        novoContrato.unidade = `${unidade[0].unin_numero} - ${unidade[0].unin_descricao}`
      } else {
        novoContrato.unidade = unidade[0].unin_descricao
      }
    }

    novoContrato[event.target.name] = event.target.value
    this.setState({ novoContrato })
  }

  updateFieldMudarUnidade() {
    const novoContrato = this.state.novoContrato
    novoContrato.mudar_unidade = !novoContrato.mudar_unidade
    this.setState({ novoContrato })
  }

  validateForm() {
    const { novoContrato } = this.state

    if (novoContrato.mudar_unidade) {
      if (novoContrato.unidadetrabalho_id === "") return false
    }

    if (novoContrato.idFuncao === "") return false

    return true
  }

  async salvar() {
    const { status, funcionario, novoContrato } = this.state

    let dados = {}

    if (status === 'demitir') {
      const contratoAntigo = {
        contt_id: funcionario.contrato.contt_id,
        contt_unidadetrabalho_id: funcionario.unidadetrabalho_id,
        contt_funcao_id: funcionario.contrato.idFuncao,
        contt_funcionario_id: funcionario._id,
        contt_observacao: funcionario.contrato.observacao,
        contt_ativo: false,
        contt_datainicio: funcionario.contrato.dataInicial,
        contt_datafinal: funcionario.contrato.dataFinal
      }

      dados = {
        status_mudanca: status,
        contratoAntigo
      }
    }

    if (status === 'promover') {

      if (!this.validateForm()) {
        this.setState({ loadingSalvar: false })
        this.handleOpenDialog({
          titulo: "Erro no Cadastro!",
          descricao: "Preencha os campos obrigatorios (*)."
        })
        return
      }

      const contratoAntigo = {
        contt_id: funcionario.contrato.contt_id,
        contt_unidadetrabalho_id: funcionario.unidadetrabalho_id,
        contt_funcao_id: funcionario.contrato.idFuncao,
        contt_funcionario_id: funcionario._id,
        contt_observacao: funcionario.contrato.observacao,
        contt_ativo: false,
        contt_datainicio: funcionario.contrato.dataInicial,
        contt_datafinal: funcionario.contrato.dataFinal
      }

      let contratoNovo = {}

      if (novoContrato.mudar_unidade) {
        contratoNovo = {
          contt_unidadetrabalho_id: novoContrato.unidadetrabalho_id,
          contt_funcao_id: novoContrato.idFuncao,
          contt_funcionario_id: funcionario._id,
          contt_observacao: novoContrato.observacao,
          contt_ativo: true,
          contt_datainicio: novoContrato.dataInicial
        }
      } else {
        if (funcionario.contrato.idFuncao === novoContrato.idFuncao) {
          this.setState({ loadingSalvar: false })
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: "Você não pode promover o funcionário para a mesma função."
          })
          return
        }

        contratoNovo = {
          contt_unidadetrabalho_id: funcionario.unidadetrabalho_id,
          contt_funcao_id: novoContrato.idFuncao,
          contt_funcionario_id: funcionario._id,
          contt_observacao: novoContrato.observacao,
          contt_ativo: true,
          contt_datainicio: novoContrato.dataInicial
        }
      }

      dados = {
        status_mudanca: status,
        contratoAntigo,
        contratoNovo
      }
    }

    try {

      await axios.put(`${process.env.REACT_APP_API_URL}/funcionarios/contrato/${funcionario._id}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { funcionario, status, novoContrato, unin_tipo } = this.state

    return (
      <div className="app-menu-closed" id="app">

        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  {status === 'demitir' &&
                    <h2 className="titulo">Demissão de Funcionário</h2>
                  }
                  {status === 'promover' &&
                    <h2 className="titulo">Promoção de Funcionário</h2>
                  }
                  {status === 'transferir' &&
                    <h2 className="titulo">Transferência de Funcionário</h2>
                  }
                </Grid>
                <Grid item md={1} xs={2} sm={2}>
                  {this.state.loadingSalvar &&
                    <CircularProgress />
                  }
                </Grid>
              </Grid>
              <FormNovoContrato
                funcionario={funcionario}
                status={status}
                departamentos={this.state.departamentos.list}
                cargos={this.state.cargosFiltrado.list}
                funcaos={this.state.funcaosFiltrado.list}
                novoContrato={novoContrato}
                unidades={this.state.unidades.list}
                unin_tipo={unin_tipo}
                updateFieldMudarUnidade={e => this.updateFieldMudarUnidade(e)}
                updateField={e => this.updateField(e)}
                updateFieldNovoContrato={e => this.updateFieldNovoContrato(e)}
              />
              <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
              <br />
              <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}