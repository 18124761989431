import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormTabelaPreco = ({ dados, updateField, produto, produtos, updateFieldProduto, addItem,
  updateFieldProdutoAtivo, updateFieldProdutoPreco, update, unidades, updateFieldUnidade,
  unidadesSelecionadas, unin_tipo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Descrição *" variant="outlined" size="small" name="tabprec_descricao" value={dados.tabprec_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" type="date" label="Data de Inicio *" variant="outlined" size="small" name="tabprec_data_inicio" value={dados.tabprec_data_inicio} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" type="date" label="Data Final *" variant="outlined" size="small" name="tabprec_data_fim" value={dados.tabprec_data_fim} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    {unin_tipo === 'FRANQUEADO' &&
      <Grid container direction="row" spacing={1} className="mg_top_20">
        <Grid item md={12} xs={12} sm={12}>
          <Autocomplete
            multiple
            limitTags={10}
            id="multiple-limit-tags"
            options={unidades.filter(unidade => {
              if (unidadesSelecionadas.findIndex(i => i.unin_id === unidade.unin_id) === -1){
                return unidade;
              }
              return false
            })}
            size="small"
            value={unidadesSelecionadas}
            onChange={(event, value) => updateFieldUnidade(event, value)}
            getOptionLabel={(option) => option.unin_descricao}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Unidades" />
            )}
          />
        </Grid>
      </Grid>
    }

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <h4>Selecionar Produto:</h4>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={6} xs={12} sm={12}>
        <Autocomplete
          onChange={(event, value) => updateFieldProduto(event, value)}
          freeSolo
          options={produtos}
          value={produto}
          getOptionLabel={option => option.prod_descricao}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Produto" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={produto.prod_descricao} />
          )}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Preço Mínimo *" variant="outlined" name="precprod_valor_minimo" value={produto.precprod_valor_minimo} onChange={(e) => updateFieldProdutoPreco(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Preço Máximo *" variant="outlined" name="precprod_valor_maximo" value={produto.precprod_valor_maximo} onChange={(e) => updateFieldProdutoPreco(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="Ativo"
                color="primary"
                checked={produto.precprod_ativo}
              />
            }
            size="small"
            label={produto.precprod_ativo ? 'Ativo' : 'Inativo'}
            name="precprod_ativo"
            onChange={(e) => updateFieldProdutoAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={9} xs={false} sm={6}></Grid>
      <Grid item md={3} xs={12} sm={6}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={update ? <CreateIcon /> : <AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormTabelaPreco