import calculaIdade from './../../services/calculaIdade'
import React, { Component } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import moment from 'moment';
import axios from 'axios'
import TabViews from './TabViews'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress'

import ModalConfirmacao from './../../components/modals/Confirmacao'

const cpf = require('node-cpf-cnpj');

const initalState = {
  aluno: {
    _id: "",
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      contm_data_inicio_vigencia: "",
      contm_data_fim_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: ""
    }
  },
  responsavel: {
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    }],
  },
  cursos: {
    list: []
  },
  curso: {
    curso_id: "",
    curso_nome: "",
  },
  unidade: {
    unin_id: "",
    unin_descricao: "",
  },
  unidades: {
    list: []
  },
  financeiros: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  filtro: {
    matricula: ""
  },
  loading: true,
  transferencia: {
    tra_contrato_id: '',
    tra_unin_destino_id: '',
    formapagto_id: '',
    contm_data_vct_matricula: '',
    contm_dia_vct_mensalidade: null,
    tra_observacao: ''
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  modalTransferenciaOpen: false,
  modalTransferencia: {
    mensagem: '',
  },
  formasPagamento: {
    list: []
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesTransferencia: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesAgenda:{
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesObsGeral: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesObsAcademico: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesObsTela: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  disabledButtonsTransferencia: false
}
export default class ViewAluno extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alunos')[0]
    if (!permissoes) {
      permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'nova-matricula')[0]
    }

    let permissoesTransferencia = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'transferencia_aluno')[0]
   
    let permissoesAgenda = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'agenda-aulas')[0]

    if(permissoesAgenda){
      this.setState({
        permissoesAgenda
      })
    }

    let permissoesObs = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'aluno-obs')
    let permissoesObsTela = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'aluno-obs')[0]

    if(permissoesObsTela){
      this.setState({
        permissoesObsTela
      })
    }

    if(permissoesObs){
      let permissoesObsGeral = permissoesObs.filter(param => param.tela.tela_slug === 'geral')[0]

      if(permissoesObsGeral){
        this.setState({
          permissoesObsGeral
        })
      }

      let permissoesObsAcademico = permissoesObs.filter(param => param.tela.tela_slug === "academico")[0]

      if(permissoesObsAcademico){
        this.setState({
          permissoesObsAcademico
        })
      }
    }


    this.setState({
      permissoes,
      permissoesTransferencia
    })

    if (!permissoes.perm_visualizar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    const { match: { params } } = this.props;

    if (params.alunoId) {
      try {
        const { data: aluno } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/${params.alunoId}`, this.getToken())

        let enderecos = []
        let contatos = []
        let emails = []

        const auxEnderecos = aluno.fisica.pessoa.enderecos

        for (let i = 0; i < auxEnderecos.length; i++) {
          enderecos.push({
            end_id: auxEnderecos[i].end_id,
            cep_id: auxEnderecos[i].end_cep_id,
            rua: auxEnderecos[i].end_logradouro,
            complemento: auxEnderecos[i].end_complemento,
            numero: auxEnderecos[i].end_numero,
            bairro: auxEnderecos[i].end_bairro,
            cidade: auxEnderecos[i].end_cidade,
            estado: auxEnderecos[i].end_estado,
            cep: auxEnderecos[i].end_cep,
            principal: auxEnderecos[i].end_principal,
            tipoEndereco: auxEnderecos[i].end_tipo,
            index: i + 1
          })
        }

        const auxContatos = aluno.fisica.pessoa.telefones

        for (let i = 0; i < auxContatos.length; i++) {
          contatos.push({
            tel_id: auxContatos[i].tel_id,
            numero: auxContatos[i].tel_numero,
            tipoContato: auxContatos[i].tel_tipo,
            principal: auxContatos[i].tel_principal,
            index: i + 1
          })
        }

        const auxEmails = aluno.fisica.pessoa.emails

        for (let i = 0; i < auxEmails.length; i++) {
          emails.push({
            ema_id: auxEmails[i].ema_id,
            email: auxEmails[i].ema_email,
            principal: auxEmails[i].ema_principal,
            index: i + 1
          })
        }

        const idade = calculaIdade(moment(aluno.fisica.pesf_datanascimento).format('Y-MM-DD'))

        console.log(aluno.contrato)

        this.setState({
          aluno: {
            _id: aluno.aluno_id,
            aluno_matricula: aluno.aluno_matricula,
            aluno_unin_id: aluno.aluno_unin_id,
            pessoaFisica: {
              pesf_id: aluno.fisica.pesf_id,
              pesf_pessoa_id: aluno.fisica.pesf_pessoa_id,
              nome: aluno.fisica.pesf_nome,
              sobrenome: aluno.fisica.pesf_sobrenome,
              apelido: aluno.fisica.pesf_apelido,
              cpf: cpf.format(aluno.fisica.pesf_cpf),
              rg: aluno.fisica.pesf_rg,
              dataNascimento: moment(aluno.fisica.pesf_datanascimento).format('Y-MM-DD'),
              idade: idade,
              maiorIdade: idade < 18 ? false : true
            },
            enderecos,
            emails,
            contatos,
            contrato: aluno.contrato
          }
        })

        if (aluno.contrato.resp_fin) {

          let enderecos = []
          let contatos = []
          let emails = []

          const auxEnderecos = aluno.contrato.resp_fin.pessoa.enderecos

          for (let i = 0; i < auxEnderecos.length; i++) {
            enderecos.push({
              end_id: auxEnderecos[i].end_id,
              cep_id: auxEnderecos[i].end_cep_id,
              rua: auxEnderecos[i].end_logradouro,
              complemento: auxEnderecos[i].end_complemento,
              numero: auxEnderecos[i].end_numero,
              bairro: auxEnderecos[i].end_bairro,
              cidade: auxEnderecos[i].end_cidade,
              estado: auxEnderecos[i].end_estado,
              cep: auxEnderecos[i].end_cep,
              principal: auxEnderecos[i].end_principal,
              tipoEndereco: auxEnderecos[i].end_tipo,
              index: i + 1
            })
          }

          const auxContatos = aluno.contrato.resp_fin.pessoa.telefones

          for (let i = 0; i < auxContatos.length; i++) {
            contatos.push({
              tel_id: auxContatos[i].tel_id,
              numero: auxContatos[i].tel_numero,
              tipoContato: auxContatos[i].tel_tipo,
              principal: auxContatos[i].tel_principal,
              index: i + 1
            })
          }

          const auxEmails = aluno.contrato.resp_fin.pessoa.emails

          for (let i = 0; i < auxEmails.length; i++) {
            emails.push({
              ema_id: auxEmails[i].ema_id,
              email: auxEmails[i].ema_email,
              principal: auxEmails[i].ema_principal,
              index: i + 1
            })
          }

          this.setState({
            responsavel: {
              pessoaFisica: {
                pesf_pessoa_id: aluno.contrato.resp_fin.pesf_pessoa_id,
                nome: aluno.contrato.resp_fin.pesf_nome,
                sobrenome: aluno.contrato.resp_fin.pesf_sobrenome,
                apelido: aluno.contrato.resp_fin.pesf_apelido,
                cpf: cpf.format(aluno.contrato.resp_fin.pesf_cpf),
                rg: aluno.contrato.resp_fin.pesf_rg,
                dataNascimento: moment(aluno.contrato.resp_fin.pesf_datanascimento).format('Y-MM-DD'),
              },
              enderecos,
              emails,
              contatos,
            }
          })
        }

        this.setState({
          transferencia: {
            tra_contrato_id: aluno.contrato.contm_id,
            tra_unin_destino_id: '',
            formapagto_id: '',
            contm_data_vct_matricula: '',
            contm_dia_vct_mensalidade: null,
            tra_observacao: ''
          }
        })

        this.setState({
          loading: false
        })
      } catch ({response}) {
        // console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: response.data.message
        })

        this.backPage()
      }
    }

    const session = JSON.parse(localStorage.getItem('unidades_acesso'))

    this.setState({
      logado: session.unidade
    })

    const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())
    this.setState({
      formasPagamento: {
        list: formasPagamento.filter(param => param.formpag_ativo === true)
      }
    })
  }

  updateFieldFiltro(event) {
    const filtro = { ...this.state.filtro }

    filtro.matricula = event.target.value
    this.setState({ filtro })
  }

  updateFieldTransferencia(event) {
    const transferencia = { ...this.state.transferencia }
    transferencia[event.target.name] = event.target.value

    this.setState({ transferencia })
  }

  async solicitarTransferencia() {
    this.setState({ disabledButtonsTransferencia: true })
    const transferencia = { ...this.state.transferencia }

    if (transferencia.tra_unin_destino_id === '') {
      this.handleOpenDialog({
        titulo: 'Erro ao solicitar Transferência',
        descricao: 'É necessário escolher uma Unidade de Destino'
      })
      return
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/agendaAula/verificaAgendaAberta/byAluno/${this.state.aluno._id}`, this.getToken())
      // const { data: ausencias } = await axios.get(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/verificaAusenciasAbertas/${this.state.aluno._id}`, this.getToken())

      if (data.aulas_passadas.length > 0) {
        let mensagem = 'Esse aluno possui aulas aberta para os dias: '

        data.aulas_passadas.forEach(agenda => {
          mensagem = mensagem + `${moment(agenda.ageaula_data_hora).format('DD/MM/YYYY')} as ${moment(agenda.ageaula_data_hora).format('HH:mm')}, `
        })

        mensagem = mensagem + 'por favor baixar essas aulas antes da transferência!'

        this.setState({ disabledButtonsTransferencia: false })

        this.handleOpenDialog({
          titulo: 'Aulas Abertas',
          descricao: mensagem
        })
        return
      }

      if (data.aulas_futuras.length > 0) {
        let mensagem = 'Esse aluno possui aulas agendadas para os dias: '

        data.aulas_futuras.forEach(agenda => {
          mensagem = mensagem + `${moment(agenda.ageaula_data_hora).format('DD/MM/YYYY')} as ${moment(agenda.ageaula_data_hora).format('HH:mm')}, `
        })

        mensagem = mensagem + 'caso confirme a transferência, elas serão desmarcadas '

        this.setState({
          modalConfirmacao: {
            mensagem,
          },
          modalConfirmacaoOpen: true
        })

      } else {
        this.setState({
          modalConfirmacao: {
            mensagem: "Deseja realmente solicitar uma transferência?",
          },
          modalConfirmacaoOpen: true
        })
      }

    } catch (error) {
      this.setState({ disabledButtonsTransferencia: false })
      this.handleOpenDialog({
        titulo: 'Opps.',
        descricao: 'Algo de errado aconteceu, tente mais tarde'
      })
      this.handleCloseConfirmacao()
    }
  }

  async confirmarTransferencia(resposta) {
    if (resposta) {
      try {
        const transferencia = { ...this.state.transferencia }
        await axios.post(`${process.env.REACT_APP_API_URL}/transferencia-aluno/solicitacao`, transferencia, this.getToken())

        this.setState({ disabledButtonsTransferencia: false })

        this.handleOpenDialog({
          titulo: 'Parabens.',
          descricao: 'Solicitação realizada com sucesso'
        })

        this.handleCloseConfirmacao()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } catch (error) {
        this.setState({ disabledButtonsTransferencia: false })
        this.handleOpenDialog({
          titulo: 'Opps.',
          descricao: 'Algo de errado aconteceu, tente mais tarde'
        })
        this.handleCloseConfirmacao()
      }

    } else {
      this.handleCloseConfirmacao()
    }
  }

  async aceitarTransferencia(resposta, transf) {
    this.setState({ transferenciaModel: transf })

    const aluno = { ...this.state.aluno }
    const transferencia = { ...this.state.transferencia }

    if (aluno.contrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO') {
      if (
        !transferencia.formapagto_id ||
        !transferencia.contm_data_vct_matricula ||
        !transferencia.contm_dia_vct_mensalidade
      ) {
        this.handleOpenDialog({
          titulo: 'Opps.',
          descricao: 'É necessário preencher os campos obrigatórios'
        })
        return
      }
    }

    this.setState({
      modalTransferencia: {
        mensagem: `Deseja realmente ${resposta ? 'ACEITAR' : 'RECUSAR'} a transferencia do aluno?`,
      },
      modalTransferenciaOpen: true,
      statusTransferencia: resposta
    })
  }

  async modalAceitarTransferencia(resposta) {
    if (resposta) {
      try {
        const { transferencia } = { ...this.state }
        const status = this.state.statusTransferencia
        const URL = `${process.env.REACT_APP_API_URL}/transferencia-aluno/resolver/${this.state.transferenciaModel.tra_id}/${status ? 'aceitar' : 'recusar'}`

        let data = {}
        if (this.state.transferenciaModel.contrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO') {
          data = {
            formapagto_id: transferencia.formapagto_id,
            contm_data_vct_matricula: transferencia.contm_data_vct_matricula,
            contm_dia_vct_mensalidade: transferencia.contm_dia_vct_mensalidade
          }
        }

        const { data: alunoNovo } = await axios.post(URL, data, this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabens.',
          descricao: `Transferencia ${status ? 'ACEITA' : 'RECUSADA'} com sucesso`
        })

        this.handleCloseConfirmacao()
        const self = this
        setTimeout(() => {
          self.props.history.push(`/aluno/view/${alunoNovo.aluno_matricula}`)
          window.location.reload()
        }, 1000)
      } catch (error) {
        this.handleOpenDialog({
          titulo: 'Opps.',
          descricao: 'Algo de errado aconteceu, tente mais tarde'
        })
        this.handleCloseConfirmacao()
      }

    } else {
      this.handleCloseConfirmacao()
    }
  }

  handleCloseConfirmacao() {
    this.setState({
      modalConfirmacaoOpen: false,
      modalTransferenciaOpen: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 4000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/aluno");
    }, timeout)
    this.setState({
      aula: initalState.aula,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  recarregarPagina() {
    const { match: { params } } = this.props;

    if(params.tab){
      this.props.history.push(`/aluno/view/${this.state.filtro.matricula}/${params.tab}`);
      window.location.reload()
    }else{
      this.props.history.push(`/aluno/view/${this.state.filtro.matricula}`);
      window.location.reload()
    }

  }

  render() {
    const { match: { params } } = this.props;
    
    const { permissoesAgenda, permissoesObsTela, permissoesObsAcademico, permissoesTransferencia, permissoesObsGeral } = this.state

    return (
      <div className="app-menu-closed" id="app">

        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <TabViews
                dadosAluno={this.state.aluno}
                dadosContrato={this.state.aluno.contrato}
                dadosResponsavel={this.state.responsavel}
                filtro={this.state.filtro}
                recarregarPagina={e => this.recarregarPagina(e)}
                updateFieldFiltro={e => this.updateFieldFiltro(e)}
                financeiros={this.state.financeiros.list}
                updateFieldTransferencia={e => this.updateFieldTransferencia(e)}
                solicitarTransferencia={e => this.solicitarTransferencia(e)}
                disabledButtonsTransferencia={this.state.disabledButtonsTransferencia}
                aceitarTransferencia={(e, transf) => this.aceitarTransferencia(e, transf)}
                params={params}
                logado={this.state.logado}
                formasPagamento={this.state.formasPagamento}
                transferencia={this.state.transferencia}
                unidades={this.state.unidades}
                history={this.props.history}
                props={this.props}
                permissoesAgenda={permissoesAgenda}
                permissoesTransferencia={permissoesTransferencia}
                permissoesObsTela={permissoesObsTela}
                permissoesObsAcademico={permissoesObsAcademico}
                permissoesObsGeral={permissoesObsGeral}
              />
              <ModalConfirmacao open={this.state.modalConfirmacaoOpen} dados={this.state.modalConfirmacao} handleClose={e => this.handleCloseConfirmacao(e)} confirmar={e => this.confirmarTransferencia(e)} />
              <ModalConfirmacao open={this.state.modalTransferenciaOpen} dados={this.state.modalTransferencia} handleClose={e => this.handleCloseConfirmacao(e)} confirmar={e => this.modalAceitarTransferencia(e)} />
            </div>
          }
          <br />
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}