import React, { Component } from 'react'
import './../Produto.scss'
import './../../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../../components/templates/Main'
import Nav from '../../../components/templates/Nav'
import Footer from '../../../components/templates/Footer'
import Table from '../../../components/Table'

import { Grid, Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../../components/modals/Erro'

import axios from 'axios'

const initalState = {
  grupoProduto: {

  },
  grupoProdutos: {
    list: []
  },
  grupoProdutosFiltrado: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Cargo extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'grupo-produtos')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/grupoProdutos`, this.getToken())

      const grupoProdutos = []

      resp.data.forEach(grupoProduto => {
        grupoProdutos.push({
          _id: grupoProduto.grupprod_id,
          descricao: grupoProduto.grupprod_descricao,
          ativo: grupoProduto.grupprod_ativo
        })
      })

      this.setState({
        grupoProdutos: {
          list: grupoProdutos
        },
        grupoProdutosFiltrado: {
          list: grupoProdutos
        },
        loading: false,
      })
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }


  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const grupoProdutos = this.state.grupoProdutos.list
    const filtro = this.state.filtro

    const list = grupoProdutos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      grupoProdutosFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, grupoProdutosFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Grupo de Produtos...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Grupo Produtos</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/grupo_produtos/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Grupo Produtos
								      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Grupo" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabela} rows={grupoProdutosFiltrado.list} acoes={this.state.acoesTabela} urlUpdate="/grupo_produtos/cadastro/" />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}