import React, { Component } from 'react'
import './PlanoPagamento.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormPlanoPagamento from '../../components/forms/FormPlanoPagamento'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import Table from '../../components/Table'

const initalState = {
  planoPagamento: {
    planpag_id: "",
    planpag_descricao: "",
    planpag_data: "",
    planpag_ativo: true,
    tabelas_valores: []
  },
  tabela_valor: {
    tabval_id: "",
    tabval_nome: "",
    tabval_num_parc: "",
    tabval_val_matri: "",
    tabval_val_mensal: "",
    tabval_val_cheio_mensal: "",
    tabval_val_comissoes: "R$ 0,00",
    tabval_ativo: true,
    comissoes: []
  },
  comissao: {
    comissfunc_id: "",
    comissfunc_func_id: "",
    func_descricao: "",
    comissfunc_valor_comissao: "R$ 0,00",
    index: 1
  },
  planoPagamentos: {
    list: []
  },
  funcoes: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'tabval_nome', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'tabval_num_parc', numeric: false, disablePadding: false, label: 'N. Parcelas' },
    { id: 'tabval_val_matri', numeric: false, disablePadding: false, label: 'Matrícula' },
    { id: 'tabval_val_comissoes', numeric: false, disablePadding: false, label: 'Total Honorários' },
    { id: 'tabval_val_mensal', numeric: false, disablePadding: false, label: 'Mensalidade' },
    { id: 'tabval_val_cheio_mensal', numeric: false, disablePadding: false, label: 'Val. Mens. Cheio' },
    { id: 'tabval_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: ['editar'],
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  unidades: {
    list: []
  },
  unidadesSelecionadas: [],
  cursos: {
    list: []
  },
  cursosSelecionados: []
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function converteFloatMoeda(valor) {
  var inteiro = null, decimal = null, c = null, j = null;
  var aux = [];
  valor = "" + valor;
  c = valor.indexOf(".", 0);
  //encontrou o ponto na string
  if (c > 0) {
    //separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
  } else {
    inteiro = valor;
  }

  //pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  //percorre a string acrescentando os pontos
  inteiro = "";
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += aux[c] + '.';
  }
  //retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = "00";
  } else {
    decimal = "" + decimal;
    if (decimal.length === 1) {
      decimal = decimal + "0";
    }
  }


  valor = inteiro + "," + decimal;


  return valor;

}

export default class CadastroPlanoPagamento extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'plano-pagamento-curso')[0]

    this.setState({
      permissoes
    })

    if (params.planoPagamentoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    this.setState({
      planoPagamento: {
        planpag_id: "",
        planpag_descricao: "",
        planpag_data: "",
        planpag_ativo: true,
        tabelas_valores: []
      },
      tabela_valor: {
        tabval_id: "",
        tabval_nome: "",
        tabval_num_parc: "",
        tabval_val_matri: "",
        tabval_val_mensal: "",
        tabval_val_cheio_mensal: "",
        tabval_val_comissoes: "R$ 0,00",
        tabval_ativo: true,
        comissoes: []
      },
      comissao: {
        comissfunc_id: "",
        comissfunc_func_id: "",
        func_descricao: "",
        comissfunc_valor_comissao: "R$ 0,00",
        index: 1
      }
    })

    try {
      const { data: funcoes } = await axios.get(`${process.env.REACT_APP_API_URL}/funcoes/listByComissionado`, this.getToken())

      const { tabela_valor } = this.state

      const comissoes = []

      for (let i = 0; i < funcoes.length; i++) {
        comissoes.push({
          comissfunc_func_id: funcoes[i].func_id,
          func_descricao: funcoes[i].func_descricao,
          comissfunc_valor_comissao: "R$ 0,00",
          index: i + 1
        })
      }

      tabela_valor.comissoes = comissoes

      this.setState({
        funcoes: {
          list: funcoes
        },
        tabela_valor
      })
    } catch (error) {
      console.log(error)
    }

    if (params.planoPagamentoId) {
      try {
        const { data: planoPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/planoPagamentoCurso/${params.planoPagamentoId}`, this.getToken())

        console.log(planoPagamento)

        const dataPlano = moment(planoPagamento.planpag_data, moment.defaultFormat).toDate()
        dataPlano.setMinutes(dataPlano.getMinutes() + dataPlano.getTimezoneOffset() + 60)

        const tabelas_valores = planoPagamento.tabelas_valores
        const auxTabelas = []

        for (let i = 0; i < tabelas_valores.length; i++) {
          const comissoes = tabelas_valores[i].comissoes_funcao
          const auxComissoes = []

          for (let j = 0; j < comissoes.length; j++) {
            auxComissoes.push({
              comissfunc_id: comissoes[j].comissfunc_id,
              comissfunc_func_id: comissoes[j].comissfunc_func_id,
              func_descricao: comissoes[j].funcao.func_descricao,
              comissfunc_valor_comissao: converteFloatMoeda(comissoes[j].comissfunc_valor_comissao),
              index: j + 1
            })
          }

          auxTabelas.push({
            tabval_id: tabelas_valores[i].tabval_id,
            tabval_nome: tabelas_valores[i].tabval_nome,
            tabval_num_parc: tabelas_valores[i].tabval_num_parc,
            tabval_val_matri: converteFloatMoeda(tabelas_valores[i].tabval_val_matri),
            tabval_val_mensal: converteFloatMoeda(tabelas_valores[i].tabval_val_mensal),
            tabval_val_cheio_mensal: converteFloatMoeda(tabelas_valores[i].tabval_val_cheio_mensal),
            tabval_val_comissoes: converteFloatMoeda(somarValores(tabelas_valores[i].comissoes_funcao.map(comissao => { return comissao.comissfunc_valor_comissao }))),
            tabval_ativo: tabelas_valores[i].tabval_ativo,
            index: i + 1,
            comissoes: auxComissoes
          })
        }

        this.setState({
          planoPagamento: {
            planpag_id: planoPagamento.planpag_id,
            planpag_descricao: planoPagamento.planpag_descricao,
            planpag_data: moment(dataPlano).format('YYYY-MM-DD'),
            planpag_ativo: planoPagamento.planpag_ativo,
            tabelas_valores: auxTabelas
          },
          unidadesSelecionadas: planoPagamento.unidades.map(unidade => {
            return {
              unin_id: unidade.unin_id,
              unin_descricao: unidade.unin_descricao,
              unin_numero: unidade.unin_numero,
            }
          }),
          cursosSelecionados: planoPagamento.cursos.map(unidade => {
            return {
              curso_id: unidade.curso_id,
              curso_nome: unidade.curso_nome
            }
          })
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o plano de pagamento, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {
      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())
      const { data: cursos } = await axios.get(`${process.env.REACT_APP_API_URL}/curso/list`, this.getToken())

      this.setState({
        unidades: {
          list: unidades.map(unidade => {
            return {
              unin_id: unidade.unin_id,
              unin_descricao: unidade.unin_descricao,
              unin_numero: unidade.unin_numero,
            }
          })
        },
        cursos: {
          list: cursos.filter(param => param.curso_ativo === true)
        }
      })



    } catch (error) {
      console.log(error)
    }

    this.setState({
      loading: false
    })

  }

  updateField(event) {
    const planoPagamento = { ...this.state.planoPagamento }

    planoPagamento[event.target.name] = event.target.value
    this.setState({ planoPagamento })
  }

  updateFieldAtivo() {
    const planoPagamento = { ...this.state.planoPagamento }
    planoPagamento.planpag_ativo = !planoPagamento.planpag_ativo
    this.setState({ planoPagamento })
  }

  updateFieldTabelaValor(event) {
    const tabela_valor = { ...this.state.tabela_valor }

    tabela_valor[event.target.name] = event.target.value
    this.setState({ tabela_valor })
  }

  updateFieldAtivoTabelaValor() {
    const tabela_valor = { ...this.state.tabela_valor }
    tabela_valor.tabval_ativo = !tabela_valor.tabval_ativo
    this.setState({ tabela_valor })
  }

  updateFieldComissao(event, index) {
    const tabela_valor = { ...this.state.tabela_valor }

    tabela_valor.comissoes[index - 1].comissfunc_valor_comissao = event.target.value

    tabela_valor.tabval_val_comissoes = converteFloatMoeda(somarValores(tabela_valor.comissoes.map(comissao => { return parseFloat(comissao.comissfunc_valor_comissao.replace("R$ ", "").replace(".", "").replace(",", ".")) })))
    this.setState({ tabela_valor })
  }

  validateFormTabela() {
    const { tabela_valor } = this.state

    if (!tabela_valor.tabval_nome) return false
    if (!tabela_valor.tabval_num_parc) return false
    if (!tabela_valor.tabval_val_matri) return false
    if (!tabela_valor.tabval_val_mensal) return false
    if (!tabela_valor.tabval_val_cheio_mensal) return false

    return true
  }

  addItem() {

    if (!this.validateFormTabela()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios da Tabela de Valor (*)."
      })
      return
    }

    const planoPagamento = this.state.planoPagamento
    let tabela_valor = this.state.tabela_valor

    const itens = planoPagamento.tabelas_valores

    if (tabela_valor.index) {
      const index = planoPagamento.tabelas_valores.findIndex(param => param.index === tabela_valor.index)

      itens[index] = {
        tabval_id: tabela_valor.tabval_id,
        tabval_nome: tabela_valor.tabval_nome,
        tabval_num_parc: tabela_valor.tabval_num_parc,
        tabval_val_matri: tabela_valor.tabval_val_matri,
        tabval_val_mensal: tabela_valor.tabval_val_mensal,
        tabval_val_cheio_mensal: tabela_valor.tabval_val_cheio_mensal,
        tabval_ativo: tabela_valor.tabval_ativo,
        tabval_val_comissoes: converteFloatMoeda(somarValores(tabela_valor.comissoes.map(comissao => { return parseFloat(comissao.comissfunc_valor_comissao.replace("R$ ", "").replace(".", "").replace(",", ".")) }))),
        comissoes: tabela_valor.comissoes,
        index: tabela_valor.index
      }
    } else {
      const index = itens.length + 1

      itens.push({
        tabval_id: tabela_valor.tabval_id,
        tabval_nome: tabela_valor.tabval_nome,
        tabval_num_parc: tabela_valor.tabval_num_parc,
        tabval_val_matri: tabela_valor.tabval_val_matri,
        tabval_val_mensal: tabela_valor.tabval_val_mensal,
        tabval_val_cheio_mensal: tabela_valor.tabval_val_cheio_mensal,
        tabval_ativo: tabela_valor.tabval_ativo,
        tabval_val_comissoes: converteFloatMoeda(somarValores(tabela_valor.comissoes.map(comissao => { return parseFloat(comissao.comissfunc_valor_comissao.replace("R$ ", "").replace(".", "").replace(",", ".")) }))),
        comissoes: tabela_valor.comissoes,
        index
      })
    }

    planoPagamento.tabelas_valores = itens

    const funcoes = this.state.funcoes.list
    const comissoes = []

    for (let i = 0; i < funcoes.length; i++) {
      comissoes.push({
        comissfunc_func_id: funcoes[i].func_id,
        func_descricao: funcoes[i].func_descricao,
        comissfunc_valor_comissao: "R$ 0,00",
        index: i + 1
      })
    }

    tabela_valor = initalState.tabela_valor

    tabela_valor.comissoes = comissoes

    this.setState({
      planoPagamento,
      tabela_valor,
      update: false
    })

  }

  editarItem(index) {
    const aux = this.state.planoPagamento.tabelas_valores.filter(param => param.index === index)

    this.setState({
      tabela_valor: aux[0],
      update: true
    })
  }

  updateFieldUnidade(event, value) {
    this.setState({
      unidadesSelecionadas: value
    })
  }

  updateFieldCurso(event, value) {
    this.setState({
      cursosSelecionados: value
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/plano_pagamento");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { planoPagamento, unidadesSelecionadas } = this.state

    if (!planoPagamento.planpag_descricao) return false
    if (!planoPagamento.planpag_data) return false
    if (planoPagamento.tabelas_valores.length === 0) return false
    if (unidadesSelecionadas.length === 0) return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const {planoPagamento , cursosSelecionados, unidadesSelecionadas} = this.state

    try {

      const method = planoPagamento.planpag_id !== "" ? 'put' : 'post'
      const url = planoPagamento.planpag_id !== "" ? `/planoPagamentoCurso/${planoPagamento.planpag_id}` : `/planoPagamentoCurso`

      if (planoPagamento.planpag_id === "") {
        delete planoPagamento.planpag_id
      }

      const { match: { params } } = this.props;
      if (params.planoPagamentoId) {
        const dataPlano = moment(planoPagamento.planpag_data, moment.defaultFormat).toDate()
        dataPlano.setMinutes(dataPlano.getMinutes() + dataPlano.getTimezoneOffset() - 60)
        planoPagamento.planpag_data = dataPlano
      }

      const tabelas_valores = planoPagamento.tabelas_valores

      for (let i = 0; i < tabelas_valores.length; i++) {
        tabelas_valores[i].tabval_num_parc = parseInt(tabelas_valores[i].tabval_num_parc)
        tabelas_valores[i].tabval_val_matri = parseFloat(tabelas_valores[i].tabval_val_matri.replace("R$ ", "").replace(".", "").replace(",", "."))
        tabelas_valores[i].tabval_val_mensal = parseFloat(tabelas_valores[i].tabval_val_mensal.replace("R$ ", "").replace(".", "").replace(",", "."))
        tabelas_valores[i].tabval_val_cheio_mensal = parseFloat(tabelas_valores[i].tabval_val_cheio_mensal.replace("R$ ", "").replace(".", "").replace(",", "."))

        if (tabelas_valores[i].tabval_id === "") {
          delete tabelas_valores[i].tabval_id
        }

        delete tabelas_valores[i].index
        const comissoes = tabelas_valores[i].comissoes

        for (let j = 0; j < comissoes.length; j++) {
          comissoes[j].comissfunc_valor_comissao = parseFloat(comissoes[j].comissfunc_valor_comissao.replace("R$ ", "").replace(".", "").replace(",", "."))
          if (comissoes[j].comissfunc_id === "") {
            delete comissoes[j].comissfunc_id
          }
          delete comissoes[j].index
          delete comissoes[j].func_descricao
        }

        tabelas_valores[i].comissoes = comissoes

      }

      planoPagamento.tabelas_valores = tabelas_valores
      planoPagamento.unidades = unidadesSelecionadas
      planoPagamento.cursos = cursosSelecionados

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, planoPagamento, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({response}) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { planoPagamento, tabela_valor, comissao } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Plano de Pagamento</h2>
                </Grid>
              </Grid>
              <FormPlanoPagamento
                comissao={comissao}
                tabela_valor={tabela_valor}
                updateField={e => this.updateField(e)}
                updateFieldAtivo={e => this.updateFieldAtivo(e)}
                updateFieldAtivoTabelaValor={e => this.updateFieldAtivoTabelaValor(e)}
                dados={planoPagamento}
                updateFieldTabelaValor={e => this.updateFieldTabelaValor(e)}
                updateFieldComissao={(e, index) => this.updateFieldComissao(e, index)}
                addItem={e => this.addItem()}
                update={this.state.update}
                updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
                unidadesSelecionadas={this.state.unidadesSelecionadas}
                unidades={this.state.unidades.list}
                cursos={this.state.cursos.list}
                cursosSelecionados={this.state.cursosSelecionados}
                updateFieldCurso={(event, value) => this.updateFieldCurso(event, value)}
              />

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Table
                  updateAtivo={e => this.updateAtivo(e)}
                  editar={e => this.editarItem(e)}
                  remove={e => this.removerItem(e)}
                  headCell={this.state.cabecalhoTabela}
                  rows={this.state.planoPagamento.tabelas_valores}
                  acoes={this.state.acoesTabela}
                  noRemove={true}
                />
              </Grid>

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}