import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'

const FormFuncao = ({ departamentos, cargos, dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Departamento"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="dep_id"
          value={dados.dep_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={dados.dep_id === "" ? "Selecionar Departamento" : dados.dep_id}> {dados.dep_id === "" ? "Selecionar Departamento" : dados.dep_descricao} </option>
          {departamentos.map(departamento => {
            if (departamento.dep_id !== dados.dep_id) {
              return (
                <option key={departamento.dep_id} value={departamento.dep_id}> {departamento.dep_descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Cargo"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="func_idcargo"
          value={dados.func_idcargo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          <option value={dados.func_idcargo === "" ? "Selecionar cargo" : dados.func_idcargo}> {dados.func_idcargo === "" ? "Selecionar Cargo" : dados.car_descricao} </option>
          {cargos.map(cargo => {
            if (cargo.car_id !== dados.func_idcargo) {
              return (
                <option key={cargo.car_id} value={cargo.car_id}> {cargo.car_descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Função" variant="outlined" size="small" name="func_descricao" value={dados.func_descricao} onChange={(e) => updateField(e)} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Nivel da Função" variant="outlined" size="small" name="func_nivel" value={dados.func_nivel} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="ativo"
              color="primary"
              checked={dados.func_ativo}
            />
          }
          label="Ativo"
          name="func_ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormFuncao