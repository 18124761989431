import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import Table from './../Table'
import moment from 'moment'

const cabecalhoServico = [
  { id: 'servico_descricao', numeric: false, disablePadding: true, label: 'Serviço' },
  { id: 'valor', numeric: false, disablePadding: true, label: 'Valor' },
  { id: 'data_inicio', numeric: false, disablePadding: true, label: 'Data Inicial' },
  { id: 'data_final', numeric: false, disablePadding: true, label: 'Data Final' },
  { id: 'cobrar_isentos', numeric: false, disablePadding: true, label: 'Cobrar Isentos' }
]

const acoesServico = ['editar', 'remove']

const FormServicosParceiro = ({ dados, updateField, updateFieldAtivo, updateFieldParceiro, fornecedores,
  servico, servicos, updateFieldServico, addServico, alteraServico, editarItemServico,removerItemServico }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Serviço*"
          variant="outlined"
          className="input"
          size="small"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="servico_bancario_id"
          value={servico.servico_bancario_id}
          onChangeCapture={(e) => updateFieldServico(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={servico._id !== '' ? servico._id : ''}> {servico._id !== '' ? servico.servico_descricao : 'Selecionar Serviço'} </option>
          {servicos.filter(param => {
						if(dados.servicos.findIndex(value => parseInt(value.servico_bancario_id) === parseInt(param._id) && !value.data_final) === -1) return true
						return false
					}).map((servico, key) => {
            return (
              <option key={key} value={servico._id}>{servico.descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={1} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Valor*" 
          variant="outlined" 
          name="valor" 
          size='small'
          value={servico.valor} 
          onChange={(e) => updateFieldServico(e)} 
          InputLabelProps={{ shrink: true }} 
          InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Data Inicial*" 
          type='date'
          variant="outlined" 
          name="data_inicio" 
          size='small'
          value={servico.data_inicio} 
          onChange={(e) => updateFieldServico(e)} 
          InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField 
          className="input" 
          label="Data Final" 
          type='date'
          variant="outlined" 
          name="data_final" 
          size='small'
          value={servico.data_final} 
          onChange={(e) => updateFieldServico(e)} 
          InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
      <TextField
          id="standard-select-currency"
          select
          label="Cobrar Isentos*"
          variant="outlined"
          className="input"
          size="small"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="cobrar_isentos"
          value={servico.cobrar_isentos}
          onChangeCapture={(e) => updateFieldServico(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value='Sim'> Sim</option>
          <option value='Não'> Não</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={alteraServico ? <CreateIcon /> : <AddIcon />} onClick={e => addServico(e)}>{alteraServico ? 'Alterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <Table
          editar={e => editarItemServico(e)}
          remove={e => removerItemServico(e)}
          headCell={cabecalhoServico}
          rows={dados.servicos.sort(function(a,b){
            return new Date(b.data_inicio) - new Date(a.data_inicio);
          }).map(item => {
            return {
              _id: item.id,
              servico_descricao: item.servico_descricao,
              valor: 'R$ ' + item.valor,
              data_inicio: moment(item.data_inicio).format('DD/MM/YYYY'),
              data_final: item.data_final ? moment(item.data_final).format('DD/MM/YYYY') : '',
              cobrar_isentos: item.cobrar_isentos,
              index: item.index
            }
          })}
          acoes={acoesServico} />
      </Grid>
    </Grid>
    <br />
    <hr />
  </React.Fragment>
)

export default FormServicosParceiro