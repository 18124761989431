import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import CurrencyInput from './../../../components/mask/CurrencyInput'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import ModalErro from './../../../components/modals/Erro'
import filesize from 'filesize'
import UploadCertificado from './../../../services/uploads/UploadCertificado'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const initalState = {
  unidade: {},
  loading: true,
  configuracao: {
    confunin_multa: '',
    confunin_juros: '',
    confunin_recibo_baixa: '',
    confunin_dia_pagto_prof: '',
    confunin_primeiro_dia_pagto_honorario: '',
    confunin_segundo_dia_pagto_honorario: '',
    confunin_exige_taxa_falta: '',
    confunin_envia_email_pagamento: '',
    confunin_taxa_falta_aluno: '',
    confunin_exige_fechar_cx_diario: '',
    confunin_exige_auto_abertura_caixa: 'Não',
    confunin_unin_id: '',
    confunin_fuso: '',
    confunin_nome_fuso: '',
    confunin_forma_pag_taxa_falta: '',
    habilita_forma_pag_taxa_falta: '',
    confunin_conta_fin_taxa_falta: '',
    confunin_dias_taxa_falta: '',
    confunin_hab_assinatura_doc: 'Não',
    confunin_perm_agenda_sem_cod: 'Não',
    confunin_bloq_dia_antes_depois: 'Não',
    confunin_horas_bloq_agenda: 0,
    confunin_intervalo_aula_fixa: 1,
    confunin_receber_notif_assinatura: 'Não'
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  uploadedFiles: [],
  dados_certificado: {
    senha_certificado: '',
    certificado_informado: false
  },
  showPassword: false,
  unin_tipo: 'UNIDADE'
}

export default class Unidade extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    const unidade = this.props.unidade

    try {

      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })

      const { data: configuracao } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${unidade.unin_id}`, this.getToken())

      if (configuracao) {
        this.setState({
          configuracao: {
            confunin_id: configuracao.confunin_id,
            confunin_unin_id: configuracao.confunin_unin_id,
            confunin_multa: configuracao.confunin_multa,
            confunin_juros: configuracao.confunin_juros,
            confunin_recibo_baixa: configuracao.confunin_recibo_baixa ? 'Sim' : 'Não',
            confunin_dia_pagto_prof: configuracao.confunin_dia_pagto_prof,
            confunin_primeiro_dia_pagto_honorario: configuracao.confunin_primeiro_dia_pagto_honorario,
            confunin_segundo_dia_pagto_honorario: configuracao.confunin_segundo_dia_pagto_honorario,
            confunin_exige_taxa_falta: configuracao.confunin_exige_taxa_falta ? 'Sim' : 'Não',
            confunin_envia_email_pagamento: configuracao.confunin_envia_email_pagamento ? 'Sim' : 'Não',
            confunin_taxa_falta_aluno: configuracao.confunin_taxa_falta_aluno,
            confunin_exige_fechar_cx_diario: configuracao.confunin_exige_fechar_cx_diario ? 'Sim' : 'Não',
            confunin_exige_auto_abertura_caixa: configuracao.confunin_exige_auto_abertura_caixa ? 'Sim' : 'Não',
            confunin_fuso: configuracao.confunin_fuso,
            confunin_nome_fuso: configuracao.confunin_nome_fuso,
            confunin_forma_pag_taxa_falta: configuracao.confunin_forma_pag_taxa_falta || '',
            confunin_conta_fin_taxa_falta: configuracao.confunin_conta_fin_taxa_falta || '',
            confunin_dias_taxa_falta: configuracao.confunin_dias_taxa_falta || '',
            habilita_forma_pag_taxa_falta: formasPagamento.filter(param => param.formpag_id === configuracao.confunin_forma_pag_taxa_falta)[0] ? formasPagamento.filter(param => param.formpag_id === configuracao.confunin_forma_pag_taxa_falta)[0].formpag_habilita : '',
            confunin_certificado_id: configuracao.confunin_certificado_id,
            confunin_hab_assinatura_doc: configuracao.confunin_hab_assinatura_doc ? 'Sim' : 'Não',
            confunin_perm_agenda_sem_cod: configuracao.confunin_perm_agenda_sem_cod ? 'Sim' : 'Não',
            confunin_bloq_dia_antes_depois: configuracao.confunin_bloq_dia_antes_depois ? 'Sim' : 'Não',
            confunin_receber_notif_assinatura: configuracao.confunin_receber_notif_assinatura ? 'Sim' : 'Não',
            confunin_horas_bloq_agenda: configuracao.confunin_horas_bloq_agenda,
            confunin_intervalo_aula_fixa: configuracao.confunin_intervalo_aula_fixa
          },
        })
      }else{
        this.setState({
          configuracao: {
            confunin_multa: '',
            confunin_juros: '',
            confunin_recibo_baixa: '',
            confunin_dia_pagto_prof: '',
            confunin_primeiro_dia_pagto_honorario: '',
            confunin_segundo_dia_pagto_honorario: '',
            confunin_exige_taxa_falta: '',
            confunin_envia_email_pagamento: '',
            confunin_taxa_falta_aluno: '',
            confunin_exige_fechar_cx_diario: '',
            confunin_exige_auto_abertura_caixa: 'Não',
            confunin_unin_id: '',
            confunin_fuso: '',
            confunin_nome_fuso: '',
            confunin_forma_pag_taxa_falta: '',
            habilita_forma_pag_taxa_falta: '',
            confunin_conta_fin_taxa_falta: '',
            confunin_dias_taxa_falta: '',
            confunin_hab_assinatura_doc: 'Não',
            confunin_perm_agenda_sem_cod: 'Não',
            confunin_bloq_dia_antes_depois: 'Não',
            confunin_horas_bloq_agenda: 0,
            confunin_intervalo_aula_fixa: 1,
            confunin_receber_notif_assinatura: 'Não'
          }
        })
      }

      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${unidade.unin_id}`, this.getToken())
      
      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      unin_tipo,
      unidade: unidade,
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  backPage(timeout = 1000) {
    window.location.reload()
    // const self = this
    // setTimeout(() => {
    //   self.props.history.push("/unidade");
    // }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateField(event) {
    const { configuracao, formasPagamento } = this.state

    let name = event.target.name
    let value = event.target.value

    if(name === 'confunin_forma_pag_taxa_falta'){
      let index = formasPagamento.list.findIndex(param => param.formpag_id === parseInt(value))

      configuracao.habilita_forma_pag_taxa_falta = formasPagamento.list[index].formpag_habilita
    }

    configuracao[name] = value

    console.log(configuracao[name])

    this.setState({ configuracao })
  }

  voltar() {
    this.props.history.push("/unidade");
    return;
  }

  validateForm() {
    const { configuracao } = this.state

    if (configuracao.confunin_multa === '') return false
    if (configuracao.confunin_juros === '') return false
    if (!configuracao.confunin_recibo_baixa) return false
    if (!configuracao.confunin_dia_pagto_prof) return false
    if (!configuracao.confunin_primeiro_dia_pagto_honorario) return false
    if (!configuracao.confunin_segundo_dia_pagto_honorario) return false
    if (!configuracao.confunin_exige_taxa_falta) return false
    if (!configuracao.confunin_envia_email_pagamento) return false
    if (!configuracao.confunin_exige_fechar_cx_diario) return false
    if (!configuracao.confunin_exige_auto_abertura_caixa) return false
    if (!configuracao.confunin_fuso) return false
    if (!configuracao.confunin_nome_fuso) return false
    if (!configuracao.confunin_intervalo_aula_fixa) return false

    if (configuracao.confunin_exige_taxa_falta === 'Sim') {
      if (!configuracao.confunin_taxa_falta_aluno) return false
    }

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    try {
      const { configuracao, uploadedFiles, dados_certificado } = this.state

      if (configuracao.confunin_exige_taxa_falta === 'Sim') {
        if (!configuracao.confunin_dias_taxa_falta){
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: "Por favor informar a quantidade de dias para o vencimento da taxa de falta."
          })
          return
        }
      }

      // if(configuracao.confunin_hab_assinatura_doc === 'Sim' && uploadedFiles.length === 0){
      //   this.handleOpenDialog({
      //     titulo: "Erro no Cadastro!",
      //     descricao: "Por favor informar o certificado e senha para a assinatura dos documentos."
      //   })
      //   return
      // }

      const dados = {
        confunin_multa: parseFloat(configuracao.confunin_multa),
        confunin_juros: parseFloat(configuracao.confunin_juros),
        confunin_recibo_baixa: configuracao.confunin_recibo_baixa === 'Sim' ? true : false,
        confunin_exige_fechar_cx_diario: configuracao.confunin_exige_fechar_cx_diario === 'Sim' ? true : false,
        confunin_envia_email_pagamento: configuracao.confunin_envia_email_pagamento === 'Sim' ? true : false,
        confunin_dia_pagto_prof: parseInt(configuracao.confunin_dia_pagto_prof),
        confunin_primeiro_dia_pagto_honorario: parseInt(configuracao.confunin_primeiro_dia_pagto_honorario),
        confunin_segundo_dia_pagto_honorario: parseInt(configuracao.confunin_segundo_dia_pagto_honorario),
        confunin_exige_taxa_falta: configuracao.confunin_exige_taxa_falta === 'Sim' ? true : false,
        confunin_unin_id: configuracao.confunin_unin_id,
        confunin_fuso: configuracao.confunin_fuso,
        confunin_nome_fuso: configuracao.confunin_nome_fuso,
        confunin_exige_auto_abertura_caixa: configuracao.confunin_exige_auto_abertura_caixa === 'Sim' ? true : false,
        confunin_taxa_falta_aluno: '',
        confunin_forma_pag_taxa_falta: configuracao.confunin_forma_pag_taxa_falta,
        confunin_conta_fin_taxa_falta: configuracao.confunin_conta_fin_taxa_falta,
        confunin_dias_taxa_falta: configuracao.confunin_dias_taxa_falta,
        confunin_hab_assinatura_doc: configuracao.confunin_hab_assinatura_doc === 'Sim' ? true : false,
        confunin_perm_agenda_sem_cod: configuracao.confunin_perm_agenda_sem_cod === 'Sim' ? true : false,
        confunin_bloq_dia_antes_depois: configuracao.confunin_bloq_dia_antes_depois === 'Sim' ? true : false,
        confunin_receber_notif_assinatura: configuracao.confunin_receber_notif_assinatura === 'Sim' ? true : false,
        confunin_horas_bloq_agenda: configuracao.confunin_horas_bloq_agenda,
        confunin_intervalo_aula_fixa: configuracao.confunin_intervalo_aula_fixa
      }

      if (dados.confunin_exige_taxa_falta) {
        dados.confunin_taxa_falta_aluno = parseFloat(configuracao.confunin_taxa_falta_aluno)
      } else {
        dados.confunin_taxa_falta_aluno = 0.0
      }

      if(dados.confunin_dias_taxa_falta === ''){
        delete dados.confunin_dias_taxa_falta
      }
    
      if (configuracao.confunin_id) {
        await axios.put(`${process.env.REACT_APP_API_URL}/configuracaoUnidade/${configuracao.confunin_id}`, dados, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/configuracaoUnidade`, dados, this.getToken())
      }

      if(uploadedFiles.length > 0){
        let data = new FormData();

        if(dados_certificado.senha_certificado === ''){
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: "Por favor informar a senha do certificado"
          })
          return
        }

        data.append("file", uploadedFiles[0].file, uploadedFiles[0].name)
        data.append("senha_certificado", dados_certificado.senha_certificado);

        await axios.post(`${process.env.REACT_APP_API_URL}/configuracaoUnidade/uploadCertificado/${configuracao.confunin_unin_id}`, data, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })

      this.backPage()

    } catch ({response}) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  handleUploadCertificado = files => {
    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    this.setState({
      uploadedFiles
    })
  }

  updateFieldEmissao(event){
    let {dados_certificado} = this.state

    dados_certificado[event.target.name] = event.target.value
    this.setState({dados_certificado})    
  }

  handleClickShowPassword(){
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    const { configuracao, formasPagamento, contasFinanceira, uploadedFiles, dados_certificado, showPassword, unin_tipo } = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados da Configuração...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
              <Grid item md={6} xs={12} sm={12}>
                <h2 className="titulo">Configurações da Unidade: </h2>
              </Grid>
            </Grid>
            <br />
            <Grid container direction="row" spacing={1}>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input"
                  label="Multa (%)"
                  variant="outlined"
                  size="small"
                  name="confunin_multa"
                  value={configuracao.confunin_multa}
                  onChange={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputComponent: CurrencyInput }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input"
                  label="Juros (%)"
                  variant="outlined"
                  size="small"
                  name="confunin_juros"
                  value={configuracao.confunin_juros}
                  onChange={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputComponent: CurrencyInput }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Recibo ao baixar"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_recibo_baixa"
                  value={configuracao.confunin_recibo_baixa}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  <option value='Sim'> Exibir </option>
                  <option value='Não'> Não Exibir </option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Exigir Fecha. diário de caixa"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_exige_fechar_cx_diario"
                  value={configuracao.confunin_exige_fechar_cx_diario}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  <option value='Sim'> Sim </option>
                  <option value='Não'> Não </option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Email notificação de pagamento"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_envia_email_pagamento"
                  value={configuracao.confunin_envia_email_pagamento}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  <option value='Sim'> Enviar </option>
                  <option value='Não'> Não Enviar </option>
                </TextField>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Dia Base Pgto Prof." type='number' variant="outlined" size="small" name="confunin_dia_pagto_prof" value={configuracao.confunin_dia_pagto_prof} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="1º Dia Base Honorários" type='number' variant="outlined" size="small" name="confunin_primeiro_dia_pagto_honorario" value={configuracao.confunin_primeiro_dia_pagto_honorario} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="2º Dia Base Honorários" type='number' variant="outlined" size="small" name="confunin_segundo_dia_pagto_honorario" value={configuracao.confunin_segundo_dia_pagto_honorario} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Eviar sol. abert. Caixa"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_exige_auto_abertura_caixa"
                  value={configuracao.confunin_exige_auto_abertura_caixa}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  <option value='Sim'> Sim </option>
                  <option value='Não'> Não </option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Fuso" variant="outlined" size="small" name="confunin_fuso" value={configuracao.confunin_fuso} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Nome Fuso" variant="outlined" size="small" name="confunin_nome_fuso" value={configuracao.confunin_nome_fuso} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Perm. Agenda sem Código"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_perm_agenda_sem_cod"
                  value={configuracao.confunin_perm_agenda_sem_cod}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  <option value='Sim'> Sim </option>
                  <option value='Não'> Não </option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Bloq. Dia Antes e Depois da Aula"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_bloq_dia_antes_depois"
                  value={configuracao.confunin_bloq_dia_antes_depois}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  <option value='Sim'> Sim </option>
                  <option value='Não'> Não </option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField className="input" label="Bloq. Horas Antes e Depois da Aula." type='number' variant="outlined" size="small" name="confunin_horas_bloq_agenda" value={configuracao.confunin_horas_bloq_agenda} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField className="input" label="Intervalo Aulas Fixas. (Mês)" type='number' variant="outlined" size="small" name="confunin_intervalo_aula_fixa" value={configuracao.confunin_intervalo_aula_fixa} onChange={(e) => this.updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
            <br />
            <hr />
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Emitir multa falta aluno"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_exige_taxa_falta"
                  value={configuracao.confunin_exige_taxa_falta}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">--Escolha--</option>
                  <option value='Sim'> Sim </option>
                  <option value='Não'> Não </option>
                </TextField>
              </Grid>
              {configuracao.confunin_exige_taxa_falta === 'Sim' &&
                <React.Fragment>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField 
                      className="input"
                      label="Taxa para falta do aluno"
                      variant="outlined"
                      size="small" 
                      name="confunin_taxa_falta_aluno"
                      value={configuracao.confunin_taxa_falta_aluno}
                      onChange={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ inputComponent: CurrencyInput }} 
                    />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField 
                      className="input"
                      label="Qtd. de dias para o vencimento da taxa"
                      variant="outlined"
                      size="small" 
                      type='number'
                      name="confunin_dias_taxa_falta"
                      value={configuracao.confunin_dias_taxa_falta}
                      onChange={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Forma de Pag. da Taxa"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="confunin_forma_pag_taxa_falta"
                      value={configuracao.confunin_forma_pag_taxa_falta}
                      onChangeCapture={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">--Escolha--</option>
                      {formasPagamento.list.map((value, key) => {
                        return (
                          <option key={key} value={value.formpag_id}>{value.formpag_descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Conta Fin. da Taxa"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="confunin_conta_fin_taxa_falta"
                      value={configuracao.confunin_conta_fin_taxa_falta}
                      onChangeCapture={(e) => this.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                      disabled={configuracao.confunin_forma_pag_taxa_falta === ''}
                    >
                      <option value="">--Escolha--</option>
                      {contasFinanceira.list.filter(param => param.contfin_tipo_conta === configuracao.habilita_forma_pag_taxa_falta).map((value, key) => {
                        return (
                          <option key={key} value={value.contfin_id}>{value.contfin_ordem} - {value.contfin_descricao}</option>
                        )
                      })}
                    </TextField>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
            <br />
            <hr />
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Habilitar Assin. de Documentos"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_hab_assinatura_doc"
                  value={configuracao.confunin_hab_assinatura_doc}
                  disabled={unin_tipo !== 'FRANQUEADOR'}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value='Não'>Não</option>
                  <option value='Sim'>Sim</option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Receber Notif. da Assinatura"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="confunin_receber_notif_assinatura"
                  value={configuracao.confunin_receber_notif_assinatura}
                  onChangeCapture={(e) => this.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value='Não'>Não</option>
                  <option value='Sim'>Sim</option>
                </TextField>
              </Grid>
            </Grid>
            {configuracao.confunin_hab_assinatura_doc === 'Sim' &&
              <React.Fragment>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h4 className="titulo">Certificado Digital: </h4>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_20">
                  <Grid item md={4} xs={12} sm={4}>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12} sm={6}>
                        <UploadCertificado onUpload={e => this.handleUploadCertificado(e)} />
                      </Grid>
                    </Grid>
                    {uploadedFiles[0] &&
                      <Grid container spacing={1} direction="row">
                        <Grid item md={12} xs={12} sm={12}>
                          <p>{uploadedFiles[0] ? uploadedFiles[0].name : ''}</p>
                        </Grid>
                      </Grid>
                    }
                    {configuracao.confunin_certificado_id &&
                      <Grid container spacing={1} direction="row">
                        <Grid item md={12} xs={12} sm={12}>
                          <p style={{margin:0}}>Certificado e senha já informado!</p>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={4} xs={12} sm={4}>
                    <TextField 
                      className="input" 
                      label="Senha Certificado"
                      type={showPassword ? "text" : "password"}
                      variant="outlined" 
                      size="small" 
                      name="senha_certificado" 
                      value={dados_certificado.senha_certificado} 
                      onChange={(e) => this.updateFieldEmissao(e)} 
                      InputLabelProps={{ shrink: true }}
                    />
                    {!showPassword &&
                      <VisibilityIcon className='visibilityPasswordCertificado' onClick={e => this.handleClickShowPassword()}/>
                    }
                    {showPassword &&
                      <VisibilityOffIcon className='visibilityPasswordCertificado' onClick={e => this.handleClickShowPassword()}/>
                    }
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            <hr className="mg_top_10" />
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={6} xs={false} sm={4}></Grid>
              <Grid item md={3} xs={false} sm={4}>
                <Button fullWidth onClick={() => this.voltar()} color="secondary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
              </Grid>
              <Grid item md={3} xs={false} sm={4}>
                <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
      </React.Fragment>
    )
  }
}