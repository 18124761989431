import React, { Component } from 'react'
import './Franqueado.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Link } from 'react-router-dom'
import { Grid, TextField, Button } from '@material-ui/core'
import MaskedInput from 'react-text-mask';

import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const cpf = require('node-cpf-cnpj')
const cnpj = require('node-cpf-cnpj')

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const initalState = {
  franqueado: {},
  franqueados: {
    list: [
      {

      }
    ]
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  loading: true,
  loadingSalvar: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}

export default class View extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      const { match: { params } } = this.props;

      const { data: franqueado } = await axios.get(`${process.env.REACT_APP_API_URL}/franqueados/${params.franqueadoId}`, this.getToken())

      this.setState({
        franqueado: franqueado,
        loading: false
      })

    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/franqueado");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { franqueado, loading } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!loading &&
            <div>
              {franqueado.pessoa &&
                <div id="view" className="cardEndereco">
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={6} xs={12} sm={12}>
                      <h2 className="titulo">Dados Franqueado: </h2>
                    </Grid>
                    <Grid item md={3} xs={6} sm={6}>
                      <Link to={`/franqueado`}>
                        <Button className="btnCadastrar" variant="contained" color="secondary">
                          Voltar
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item md={3} xs={6} sm={6}>
                      <Link to={`/franqueado/update/${franqueado.unin_id}`}>
                        <Button className="btnCadastrar" variant="contained" color="primary">
                          Editar Franqueado
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  {franqueado.pessoa.pes_tipo === "FISICA" &&
                    <div>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Dados Pessoa Física: </h4>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={franqueado.pessoa.fisica.pesf_nome} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(franqueado.pessoa.fisica.pesf_cpf)} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={franqueado.pessoa.fisica.pesf_rg} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(franqueado.pessoa.fisica.pesf_datanascimento).format('YYYY-MM-DD')} disabled />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  {franqueado.pessoa.pes_tipo === "JURIDICA" &&
                    <div>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={12} xs={12} sm={12}>
                          <h4 className="titulo">Dados Pessoa Jurídica: </h4>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Razão Social" value={franqueado.pessoa.juridica.pesj_razaosocial} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Fantasia" value={franqueado.pessoa.juridica.pesj_nomefantasia} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="CNPJ" value={cnpj.format(franqueado.pessoa.juridica.pesj_cnpj)} disabled />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField fullWidth size="small" variant="outlined" className="input" label="Inscrição Estadual" value={franqueado.pessoa.juridica.pesj_inscricaoestadual} disabled />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} direction="row" className="mg_top_10">
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Fundação" value={moment(franqueado.pessoa.juridica.pesj_datafundacao).format('YYYY-MM-DD')} disabled />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Endereços Franqueado: </h4>
                    </Grid>
                  </Grid>
                  {franqueado.pessoa.enderecos.map(endereco => {
                    return (
                      <div className="cardEndereco" key={endereco.end_id}>
                        {endereco.end_principal &&
                          <h4>Endereço Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.end_cep} disabled />
                          </Grid>
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.end_logradouro} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.end_numero} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.end_complemento} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.end_bairro} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.end_cidade} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.end_estado} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.end_tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Telefones Franqueado: </h4>
                    </Grid>
                  </Grid>
                  {franqueado.pessoa.telefones.map(telefone => {
                    return (
                      <div className="cardEndereco" key={telefone.tel_id}>
                        {telefone.tel_principal &&
                          <h4>Telefone Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.tel_numero} disabled InputProps={{ inputComponent: TextMaskCustom }} />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tel_tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">E-mails Franqueado: </h4>
                    </Grid>
                  </Grid>
                  {franqueado.pessoa.emails.map(email => {
                    return (
                      <div className="cardEndereco" key={email.ema_id}>
                        {email.ema_principal &&
                          <h4>E-mail Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.ema_email} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              }
              {franqueado.responsavel &&
                <div id="view" className="cardEndereco">
                  <Grid container spacing={1} direction="row" className="borderBottom">
                    <Grid item md={9} xs={12} sm={12}>
                      <h2 className="titulo">Dados Responsavel: </h2>
                    </Grid>
                    <Grid item md={3} xs={6} sm={6}>
                      <Link to={`/franqueado/updateResponsavel/${franqueado.unin_id}`}>
                        <Button className="btnCadastrar" variant="contained" color="primary">
                          Editar Responsável
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <div>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className="titulo">Dados Pessoa Física: </h4>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={franqueado.responsavel.pesf_nome} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={cpf.format(franqueado.responsavel.pesf_cpf)} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={franqueado.responsavel.pesf_rg} disabled />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={moment(franqueado.responsavel.pesf_datanascimento).format('YYYY-MM-DD')} disabled />
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Endereços Responsável: </h4>
                    </Grid>
                  </Grid>
                  {franqueado.responsavel.pessoa.enderecos.map(endereco => {
                    return (
                      <div className="cardEndereco" key={endereco.end_id}>
                        {endereco.end_principal &&
                          <h4>Endereço Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.end_cep} disabled />
                          </Grid>
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.end_logradouro} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.end_numero} disabled />
                          </Grid>
                          <Grid item md={2} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.end_complemento} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.end_bairro} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.end_cidade} disabled />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.end_estado} disabled />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.end_tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">Telefones Responsável: </h4>
                    </Grid>
                  </Grid>
                  {franqueado.responsavel.pessoa.telefones.map(telefone => {
                    return (
                      <div className="cardEndereco" key={telefone.tel_id}>
                        {telefone.tel_principal &&
                          <h4>Telefone Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.tel_numero} disabled InputProps={{ inputComponent: TextMaskCustom }} />
                          </Grid>
                          <Grid item md={3} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tel_tipo} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h4 className="titulo">E-mails Responsável: </h4>
                    </Grid>
                  </Grid>
                  {franqueado.responsavel.pessoa.emails.map(email => {
                    return (
                      <div className="cardEndereco" key={email.ema_id}>
                        {email.ema_principal &&
                          <h4>E-mail Principal:</h4>
                        }
                        <Grid container spacing={1} direction="row" className="mg_top_10">
                          <Grid item md={6} sm={6} xs={12}>
                            <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.ema_email} disabled />
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                </div>
              }
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div >
    )
  }
}