import React, { Component } from 'react'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, TextField, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import moment from 'moment'
import ModalConfirmacao from './../../components/modals/Confirmacao'

const initalState = {
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  unidades: {
    list: []
  },
  financeiro: {
    list: []
  },
  transferencia: {
    tra_contrato_id: '',
    tra_unin_destino_id: '',
    contm_data_vct_matricula: '',
    contm_dia_vct_mensalidade: null,
    tra_observacao: '',
		contrato: {
			aluno: {
				fisica: {
					pesf_nome: '',
					pesf_cpf: '',
					pesf_datanascimento: ''
				}
			}
		}
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  modalTransferenciaOpen: false,
  modalTransferencia: {
    mensagem: '',
  },
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  cabecalhoTabelaFinanceiro: [
    { id: 'parcont_datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
    { id: 'contpr_descricao', numeric: false, disablePadding: false, label: 'Descrição' },
    { id: 'parcont_situacao', numeric: false, disablePadding: false, label: 'Situação' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: false, label: 'Valor' },
  ],
  acoesTabelaFinanceiro: [],
  cabecalhoTabelaAusencia: [
    { id: 'austemp_data_inicial', numeric: false, disablePadding: true, label: 'Data Inicial', orderBy: 'data_inicial_order' },
    { id: 'austemp_data_final', numeric: false, disablePadding: false, label: 'Data Final', orderBy: 'data_final_order' },
    { id: 'qtd_dias', numeric: false, disablePadding: false, label: 'Dias' },
    { id: 'austemp_motivo', numeric: false, disablePadding: false, label: 'Motivo' }
  ],
  acoesTabelaAusencia: [],
  totalFinanceiro: 0,
  ausencias: {
    list: []
  },
  disabledButtons: false
}

export default class AprovarTransferenciaAluno extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'transferencia_aluno')[0]

    if (permissoes) {
      this.setState({
        permissoes
      })
    }

    try {
      const { match: { params } } = this.props;
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/rematricula-aluno/solicitacao/${params.transfId}`, this.getToken())

      this.setState({
        transferencia: data,
        loading: false,
      })
    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Erro Inesperado!"
      })

      this.backPage()
    }

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async aceitarRematricula(resposta, transf) {
    this.setState({ transferenciaModel: transf })

    this.setState({
      modalTransferencia: {
        mensagem: `Deseja realmente ${resposta ? 'APROVAR' : 'RECUSAR'} a rematricula do aluno?`,
      },
      modalTransferenciaOpen: true,
      statusTransferencia: resposta
    })
  }

  async modalAceitarRematricula(resposta) {
    if (resposta) {
      this.setState({ disabledButtons: true })
      try {
				const status = this.state.statusTransferencia
				const URL = `${process.env.REACT_APP_API_URL}/rematricula-aluno/resolver/${this.state.transferenciaModel.tra_id}/${status ? 'aceitar' : 'recusar'}`

				this.handleOpenDialog({
          titulo: 'Parabens.',
          descricao: `Rematricula ${status ? 'ACEITA' : 'RECUSADA'} com sucesso`
        })

				this.handleCloseConfirmacao()
        const self = this

				await axios.post(URL, {}, this.getToken())

				setTimeout(() => {
					self.props.history.push(`/home`)
					window.location.reload()
				}, 1000)

      } catch (error) {
        this.setState({ disabledButtons: true })
        this.handleOpenDialog({
          titulo: 'Opps.',
          descricao: 'Algo de errado aconteceu, tente mais tarde'
        })
        this.handleCloseConfirmacao()
      }

    } else {
      this.handleCloseConfirmacao()
    }
  }

  handleCloseConfirmacao() {
    this.setState({
      modalConfirmacaoOpen: false,
      modalTransferenciaOpen: false
    })
  }

  render() {
    const { transferencia } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Transferência...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div id='view'>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h2 className="titulo">Aprovar Rematricula de Aluno</h2>
                </Grid>
								<Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
							<Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Dados do Aluno:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Nome Aluno" variant="outlined" size="small" value={transferencia.contrato.aluno.fisica.pesf_nome} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="CPF Aluno" variant="outlined" size="small" value={transferencia.contrato.aluno.fisica.pesf_cpf} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Data Nascimento" variant="outlined" size="small" value={moment(transferencia.contrato.aluno.fisica.pesf_datanascimento).format('DD/MM/YYYY')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>
							<Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Unidade Solicitante:</h5>
                </Grid>
              </Grid>
							<Grid container spacing={1} direction="row" className="mg_top_10">
								<Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Unidade" variant="outlined" size="small" value={transferencia.destino.unin_numero + ' - ' + transferencia.destino.unin_descricao} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
								<Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Usuário Solicitante" variant="outlined" size="small" value={transferencia.usuario_origem.pessoa.fisica.pesf_nome} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>
							<br />
							<hr />
							<Grid container spacing={1} direction="row" className="mg_top_10">
								<Grid item md={6} xs={false} sm={4}></Grid>
                <Grid item md={3} xs={5} sm={4}>
                  <Button
                    variant="contained"
										color="secondary"
                    onClick={() => this.aceitarRematricula(false, transferencia)}
                    startIcon={<CloseIcon />}
										fullWidth
                  >
                    Recusar
                  </Button>
                </Grid>
                <Grid item md={3} xs={6} sm={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.aceitarRematricula(true, transferencia)}
                    startIcon={<CheckIcon />}
										fullWidth
                  >
                    Aprovar
                  </Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalConfirmacao open={this.state.modalTransferenciaOpen} dados={this.state.modalTransferencia} handleClose={e => this.handleCloseConfirmacao(e)} confirmar={e => this.modalAceitarRematricula(e)} disabledButtons={this.state.disabledButtons} />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer />
      </div>
    )
  }
}