import React, { Component } from 'react'
import './ContaPagarReceber.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'
import Alert from './../../components/templates/Alert'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Button, TextField } from '@material-ui/core'
import { saveAs } from 'file-saver';
import axios from 'axios'
import moment from 'moment';
import ModalErro from './../../components/modals/Erro'
import ModalExibeBoleto from './../../components/modals/ExibeBoleto'
import real from '../../services/real'

const initalState = {
  conta: {

  },
  contas: {
    list: []
  },
  contasFiltrado: {
    list: []
  },
  planosConta: {
    list: []
  },
  filtro: {
    contpr_tipo: "",
    plancontas_id: "",
    contpr_descricao: "",
    data_inicial: "",
    data_final: "",
    parcont_situacao: "",
    data_por: "VENCIMENTO",
    formpag_id: "",
    stcontmat_situacao: "",
    formpag_descricao: "",
    plancontas_descricao: "",
    contfin_id: ""
  },
  cabecalhoTabela: [
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Uni.' },
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição Conta' },
    { id: 'parcont_documento', numeric: false, disablePadding: false, label: 'Doc.' },
    { id: 'plancontas_descricao', numeric: false, disablePadding: true, label: 'Plano' },
    { id: 'parcont_datavencimento', numeric: false, disablePadding: false, label: 'Venc.', orderBy: 'datavencimentoOrder' },
    { id: 'parcont_datapagamento', numeric: false, disablePadding: false, label: 'Pag./Canc.', orderBy: 'datapagamentoOrder' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: false, label: 'Vl. Parc.', orderBy: 'parcont_valorparcela_ordem'},
    { id: 'parcont_multajuros', numeric: false, disablePadding: true, label: 'Jur./Mul. ou Desc.', orderBy: 'parcont_multajuros_ordem' },
    { id: 'valpag_valor_pagamento', numeric: false, disablePadding: false, label: 'Vl. Pag./Rec.' },
    { id: 'formpag_descricao', numeric: false, disablePadding: false, label: 'Forma Pagto.' },
    { id: 'parcont_situacao', numeric: false, disablePadding: true, label: 'Situação' }
  ],
  acoesTabela: ['update', 'imprimirBoleto'],
  unin_tipo: "",
  loading: true,
  loadingTable: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoesContasPR: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesCaixa: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  perfilLogado: '',
  formasPagamento:{
    list: []
  },
  totais:{
    totalParcelaPagar: 0,
    totalParcelaReceber: 0,
    totalPagoPagar: 0,
    totalPagoReceber: 0
  },
  loadingImprimir: false,
  openModalExibeBoleto: false,
  boletoSelecionado:{
    tipo_transaction: 'BOLETO',
    bank_slip:{
      url_slip_pdf: ''
    }
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  contasFinanceira: {
    list:[]
  }
}

export default class ContaPagarReceber extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    this.setState({
      totais:{
        totalParcelaPagar: 0,
        totalParcelaReceber: 0,
        totalPagoPagar: 0,
        totalPagoReceber: 0
      }
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'contas-pagar-receber')[0]
    let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'caixa')[0]
    let perfilLogado = ''

    if (permissoesCaixa) {
      perfilLogado = 'caixa'
      this.setState({
        permissoesCaixa,
        perfilLogado: 'caixa'
      })
    }

    if (permissoesContasPR) {
      perfilLogado = 'contas-pagar-receber'
      this.setState({
        permissoesContasPR,
        perfilLogado: 'contas-pagar-receber'
      })
    }

    const ultima_tela_financeiro = {
      tela: 'contasPR'
    }

    localStorage.setItem('ultima_tela_financeiro', JSON.stringify(ultima_tela_financeiro))

    const caixa = JSON.parse(localStorage.getItem('caixa'))
    const contasPR = JSON.parse(localStorage.getItem('contasPR'))
    this.setState({ logado: { caixa, contasPR } })

    let dataAtual = new Date()
    dataAtual = moment(dataAtual).format('YYYY-MM-DD')

    let data7dias = new Date()
    data7dias.setDate(data7dias.getDate() + 7)
    data7dias = moment(data7dias).format('YYYY-MM-DD')

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      let planoContas = []
      if (perfilLogado === 'caixa') {
        planoContas = data
          .filter(param => param.plancontas_ativo === true)
          .filter(param => {
            if (
              !caixa ||
              (caixa && param.parametros && param.parametros.paramfin_aluno !== 'I')
            ) {
              return {
                plancontas_id: param.plancontas_id,
                plancontas_codigo: param.plancontas_codigo,
                plancontas_descricao: param.plancontas_descricao,
                plancontas_ativo: param.plancontas_ativo,
                plancontas_slug: param.plancontas_slug
              }
            }
            return false
          })
      } else {
        planoContas = data
          .filter(param => param.plancontas_ativo === true)
          .map(param => {
            return {
              plancontas_id: param.plancontas_id,
              plancontas_codigo: param.plancontas_codigo,
              plancontas_descricao: param.plancontas_descricao,
              plancontas_ativo: param.plancontas_ativo,
              plancontas_slug: param.plancontas_slug
            }
          })
      }

      this.setState({
        planosConta: {
          list: planoContas
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }


    const filtro_contas_rel_consolidado = JSON.parse(localStorage.getItem('filtro_contas_rel_consolidado'))

    let filtro = {
      contpr_tipo: "",
      plancontas_id: "",
      contpr_descricao: "",
      data_inicial: dataAtual,
      data_final: data7dias,
      parcont_situacao: "",
      data_por: "VENCIMENTO",
      stcontmat_situacao: "",
      formpag_id: "",
      formpag_descricao: "",
      plancontas_descricao: "",
      contfin_id: ""
    }

    if(filtro_contas_rel_consolidado){
      let {planosConta} = this.state

      let plano = planosConta.list.filter(param => `${param.plancontas_codigo} - ${param.plancontas_descricao}` === filtro_contas_rel_consolidado.plano)[0]
      
      filtro.data_inicial = filtro_contas_rel_consolidado.data_inicial
      filtro.data_final = filtro_contas_rel_consolidado.data_final

      if(filtro_contas_rel_consolidado.filtrar_por === 'VENCIMENTO'){
        filtro.data_por = "VENCIMENTO"
        filtro.parcont_situacao = 'AVENCER_BAIXADO'
      }else{
        filtro.data_por = "PAGAMENTO"
        filtro.parcont_situacao = 'BAIXADO'
      }
    
      filtro.plancontas_id = plano.plancontas_id
      filtro.dados = dados

    }else{
      const filtro_contaspr = JSON.parse(localStorage.getItem('filtro_contaspr'))

      if (filtro_contaspr) {
        filtro = filtro_contaspr
      }
  
      filtro.dados = dados
    }

    this.setState({
      filtro
    })   

    try {
      let {totais} = this.state

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/parcelaConta/filtro`, filtro, this.getToken())

      const contas = []

      data.forEach(conta => {
        if(conta['conta.contpr_descricao']){
          if(conta['conta.contpr_tipo'] === 'PAGAR'){
            totais.totalParcelaPagar += conta.parcont_valorparcela
  
            if(conta.parcont_situacao === 'BAIXADO'){
              totais.totalPagoPagar += conta['valores_pago.valpag_valor_pagamento']
            }
          }else{
            totais.totalParcelaReceber += conta.parcont_valorparcela
  
            if(conta.parcont_situacao === 'BAIXADO'){
              totais.totalPagoReceber += conta['valores_pago.valpag_valor_pagamento']
            }
          }

          console.log(conta)

          contas.push({
            _id: conta.parcont_id,
            unidade: `${conta['conta.pertence.unin_numero']}`,
            contpr_descricao: conta['conta.contpr_descricao'] + ' - Parc: ' + conta.parcont_num + '/' + conta['conta.contpr_numparcela'],
            parcont_num: conta.parcont_num,
            parcont_documento: conta.parcont_documento,
            contpr_tipo: conta['conta.contpr_tipo'] === 'PAGAR' ? 'PAGAR' : 'RECEBER',
            plancontas_descricao: conta['conta.planocontas.plancontas_codigo'] ? (conta['conta.planocontas.plancontas_codigo'] + ' - ' + conta['conta.planocontas.plancontas_descricao']) : '',
            plancontas_id: conta['conta.planocontas.plancontas_id'],
            parcont_datavencimento: moment(conta.parcont_datavencimento).format('DD/MM/YYYY'),
            datavencimentoOrder: moment(conta.parcont_datavencimento).format('YYYYMMDD'),
            parcont_datapagamento: conta.parcont_situacao === 'CANCELADO' ? (conta.parcont_data_cancelamento ? moment(conta.parcont_data_cancelamento).format('DD/MM/YYYY') : '') : (conta.parcont_datapagamento ? moment(conta.parcont_datapagamento).format('DD/MM/YYYY') : ''),
            datapagamentoOrder: conta.parcont_situacao === 'CANCELADO' ? (conta.parcont_data_cancelamento ? moment(conta.parcont_data_cancelamento).format('YYYYMMDD') : '') : (conta.parcont_datapagamento ? moment(conta.parcont_datapagamento).format('YYYYMMDD') : ''),
            parcont_datavencimento_sem_format: conta.parcont_datavencimento,
            parcont_situacao: conta.parcont_situacao,
            parcont_valorparcela: real(conta.parcont_valorparcela),
            parcont_valorparcela_ordem: conta.parcont_valorparcela,
            parcont_multajuros_ordem: conta.parcont_multajuros ? conta.parcont_multajuros : 0,
            valpag_valor_pagamento: conta.parcont_situacao === 'BAIXADO' ? real(conta['valores_pago.valpag_valor_pagamento']) : '-',
            aluno_matricula: conta['conta.aluno.aluno_matricula'],
            aluno_nome: `${conta['conta.aluno.fisica.pesf_nome']}${conta['conta.aluno.fisica.pesf_sobrenome'] ? ` ${conta['conta.aluno.fisica.pesf_sobrenome']}` : ''}`,
            formpag_descricao: conta['valores_pago.forma_pagto.formpag_descricao'],
            formpag_slug: conta['valores_pago.forma_pagto.formpag_slug'],
            parcont_multajuros: conta.parcont_multajuros ? real(conta.parcont_multajuros) : real(0),
            parcont_boleto_digitable_line: conta.parcont_boleto_digitable_line,
            parcont_boleto_transaction_id: conta.parcont_boleto_transaction_id,
            parcont_boleto_link: conta.parcont_boleto_link,
            parcont_tipo_transaction: conta.parcont_tipo_transaction,
            habilita_pix_boleto: conta['valores_pago.conta_financeira.conta_corrente.parceiro.pbu_id'] ? true: false
          })
        }
      })

      this.setState({
        contas: {
          list: contas
        },
        loading: false,
        totais
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())
      
      const dados = {
        filtro: {
          contfin_tipo_conta: "",
          contfin_descricao: "",
          contfin_ativo: "Sim"
        },
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }      
      
      const { data: contasFinanceira } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo)
        },
        contasFinanceira: {
          list: contasFinanceira.filter(param => param.contfin_ativo).sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(value => {
            return {
              contfin_id: value.contfin_id,
              contfin_descricao: value.contfin_descricao,
              contfin_ordem: value.contfin_ordem
            }
          })
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  backPage(timeout = 1000) {
    // const self = this

    // setTimeout(() => {
    //   self.props.history.push("/home");
    // }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    const {filtro, planosConta, formasPagamento} = this.state

    filtro[event.target.name] = event.target.value

    if(event.target.name === 'plancontas_id'){
      if(planosConta.list.filter(param => param.plancontas_id === parseInt(event.target.value))[0]){
        filtro.plancontas_descricao = planosConta.list.filter(param => param.plancontas_id === parseInt(event.target.value))[0].plancontas_descricao
      }else{
        filtro.plancontas_descricao = ''
      }
    }

    if(event.target.name === 'formpag_id'){
      if(formasPagamento.list.filter(param => param.formpag_id === parseInt(event.target.value))[0]){
        filtro.formpag_descricao = formasPagamento.list.filter(param => param.formpag_id === parseInt(event.target.value))[0].formpag_descricao
      }else{
        filtro.formpag_descricao = ''
      }
    }

    this.setState({
      filtro
    })

  }

  async filtrar() {
    const filtro = this.state.filtro

    if (filtro.data_final === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data final'
      })
      return
    }

    if (filtro.data_inicial === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data inicial'
      })
      return
    }

    this.setState({
      loadingTable: true
    })

    localStorage.setItem('filtro_contaspr', JSON.stringify(filtro));

    try {

      this.setState({
        totais:{
          totalParcelaPagar: 0,
          totalParcelaReceber: 0,
          totalPagoPagar: 0,
          totalPagoReceber: 0
        }
      })

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.dados = dados

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/parcelaConta/filtro`, filtro, this.getToken())

      let {totais} = this.state

      const contas = []

      data.forEach(conta => {
        if(conta['conta.contpr_descricao']){
          if(conta['conta.contpr_tipo'] === 'PAGAR'){
            totais.totalParcelaPagar += conta.parcont_valorparcela
  
            if(conta.parcont_situacao === 'BAIXADO'){
              totais.totalPagoPagar += conta['valores_pago.valpag_valor_pagamento']
            }
          }else{
            totais.totalParcelaReceber += conta.parcont_valorparcela
  
            if(conta.parcont_situacao === 'BAIXADO'){
              totais.totalPagoReceber += conta['valores_pago.valpag_valor_pagamento']
            }
          }
  
          contas.push({
            _id: conta.parcont_id,
            unidade: `${conta['conta.pertence.unin_numero']}`,
            contpr_descricao: conta['conta.contpr_descricao'] + ' - Parc: ' + conta.parcont_num + '/' + conta['conta.contpr_numparcela'],
            contpr_tipo: conta['conta.contpr_tipo'] === 'PAGAR' ? 'PAGAR' : 'RECEBER',
            parcont_num: conta.parcont_num,
            parcont_documento: conta.parcont_documento,
            plancontas_descricao: conta['conta.planocontas.plancontas_codigo'] ? (conta['conta.planocontas.plancontas_codigo'] + ' - ' + conta['conta.planocontas.plancontas_descricao']) : '',
            plancontas_id: conta['conta.planocontas.plancontas_id'],
            parcont_datavencimento: moment(conta.parcont_datavencimento).format('DD/MM/YYYY'),
            datavencimentoOrder: moment(conta.parcont_datavencimento).format('YYYYMMDD'),
            parcont_datapagamento: conta.parcont_situacao === 'CANCELADO' ? (conta.parcont_data_cancelamento ? moment(conta.parcont_data_cancelamento).format('DD/MM/YYYY') : '') : (conta.parcont_datapagamento ? moment(conta.parcont_datapagamento).format('DD/MM/YYYY') : ''),
            datapagamentoOrder: conta.parcont_situacao === 'CANCELADO' ? (conta.parcont_data_cancelamento ? moment(conta.parcont_data_cancelamento).format('YYYYMMDD') : '') : (conta.parcont_datapagamento ? moment(conta.parcont_datapagamento).format('YYYYMMDD') : ''),
            parcont_datavencimento_sem_format: conta.parcont_datavencimento,
            parcont_situacao: conta.parcont_situacao,
            parcont_valorparcela: real(conta.parcont_valorparcela),
            parcont_valorparcela_ordem: conta.parcont_valorparcela,
            parcont_multajuros_ordem: conta.parcont_multajuros ? conta.parcont_multajuros : 0,
            valpag_valor_pagamento: conta.parcont_situacao === 'BAIXADO' ? real(conta['valores_pago.valpag_valor_pagamento']) : '-',
            aluno_matricula: conta['conta.aluno.aluno_matricula'],
            aluno_nome: `${conta['conta.aluno.fisica.pesf_nome']}${conta['conta.aluno.fisica.pesf_sobrenome'] ? ` ${conta['conta.aluno.fisica.pesf_sobrenome']}` : ''}`,
            formpag_descricao: conta['valores_pago.forma_pagto.formpag_descricao'],
            formpag_slug: conta['valores_pago.forma_pagto.formpag_slug'],
            parcont_multajuros: conta.parcont_multajuros ? real(conta.parcont_multajuros) : real(0),
            parcont_boleto_digitable_line: conta.parcont_boleto_digitable_line,
            parcont_boleto_transaction_id: conta.parcont_boleto_transaction_id,
            parcont_boleto_link: conta.parcont_boleto_link,
            parcont_tipo_transaction: conta.parcont_tipo_transaction,
            habilita_pix_boleto: conta['valores_pago.conta_financeira.conta_corrente.parceiro.pbu_id'] ? true: false
          })
        }
      });

      this.setState({
        contas: {
          list: contas
        },
        loadingTable: false,
        totais
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  async imprimir() {
    
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    if(acesso_atual.length !== 1){
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor selecionar apenas uma unidade para impressão'
      })
    }else{
      const {contas } = this.state
  
      this.setState({
        loadingImprimir: true
      })
  
      try {
        let { cabecalhoTabela } = this.state

        const ordenacao = JSON.parse(localStorage.getItem('ordenacao'))
        
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.unin_id
          })
        }
  
        let filtro = {
          relatorio: 'contas-pagar-receber',
          data_inicial: this.state.filtro.data_inicial,
          data_final: this.state.filtro.data_final,
          filtroTela: this.state.filtro,
          unin_id: "",
          unidadesnegocio: dados.unidadesnegocio,
          list: [],
          totais: this.state.totais
        }

        let indexCabecalho = -1
        if(ordenacao){
          indexCabecalho = cabecalhoTabela.findIndex(param => param.id === ordenacao.property)
        }

        if(indexCabecalho !== -1){
          if(ordenacao.isAsc){
            filtro.list = contas.list.sort((a, b) => (a[ordenacao.property] < b[ordenacao.property]) ? 1 : (b[ordenacao.property] < a[ordenacao.property]) ? -1 : 0)
          }else{
            filtro.list = contas.list.sort((a, b) => (a[ordenacao.property] > b[ordenacao.property]) ? 1 : (b[ordenacao.property] > a[ordenacao.property]) ? -1 : 0)
          }
        }else{
          filtro.list = contas.list
        }     
  
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())
  
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        this.setState({
          loadingImprimir: false
        })
  
        saveAs(pdfBlob, 'Contas-Pagar-Receber.pdf')
  
  
      } catch (error) {
        this.setState({
          loadingImprimir: false
        })
        console.log(error)
      }
    }
    
  }

  async imprimirBoleto(row){
    this.setState({
      alerta: {
        open: true,
        severity: 'info',
        message: 'Carregando Pix/Boleto...'
      }
    })

    let {contas} = this.state

    let index = contas.list.findIndex(param => param._id === row._id)

    if(row.formpag_slug === 'pix'){
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pix/atualizaPix`, {parcela_id: row._id}, this.getToken())

      contas.list[index].parcont_boleto_digitable_line = data.pix_code.emv
      contas.list[index].parcont_boleto_transaction_id = data.transaction_id
      contas.list[index].parcont_boleto_link = data.pix_code.qrcode_image_url
      contas.list[index].parcont_tipo_transaction = 'PIX'

      this.setState({
        boletoSelecionado: {
          tipo_transaction: 'PIX',
          bank_slip:{
            url_slip_pdf: data.pix_code.qrcode_image_url,
            boleto_digitable_line: data.pix_code.emv
          }
        },
        contas
      })

      this.setState({
        openModalExibeBoleto: true
      })
    }else{
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/boleto/atualizaBoleto`, {parcela_id: row._id}, this.getToken())

      contas.list[index].parcont_boleto_transaction_id = data.transaction_id
      contas.list[index].parcont_boleto_digitable_line = data.bank_slip.digitable_line
      contas.list[index].parcont_boleto_link = data.bank_slip.url_slip_pdf
      contas.list[index].parcont_tipo_transaction = 'BOLETO'

      this.setState({
        boletoSelecionado: {
          tipo_transaction: 'PIX',
          bank_slip:{
            url_slip_pdf: data.pix_code.qrcode_image_url,
            boleto_digitable_line: data.pix_code.emv
          }
        },
        contas
      })

      this.setState({
        openModalExibeBoleto: true
      })
    }

    this.handleCloseAlerta()
  }

  handleModalExibeBoleto(){
    this.setState({openModalExibeBoleto: false})
  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.handleOpenDialog({
      titulo: 'Texto copiado',
      descricao: 'Texto copiado'
    })
  
    return
    
  }

  render() {
    const { filtro, contas, logado, totais, contasFinanceira } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Contas a Pagar/Receber...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Contas a Pagar/Receber</h1>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Link to="/conta_pagar_receber/cadastro">
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Conta
                </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tipo"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="contpr_tipo"
                    value={filtro.contpr_tipo}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    {logado.contasPR &&
                      <React.Fragment>
                        <option value=""></option>
                        <option value="PAGAR">A PAGAR</option>
                        <option value="RECEBER">A RECEBER</option>
                      </React.Fragment>
                    }
                    {!logado.contasPR &&
                      <option value="RECEBER">A RECEBER</option>
                    }
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Plano de Contas"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="plancontas_id"
                    value={filtro.plancontas_id}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    {this.state.planosConta.list.map(plano => {
                      return (
                        <option key={plano.plancontas_id} value={plano.plancontas_id}>{plano.plancontas_descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="contpr_descricao" value={filtro.contpr_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situação"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="parcont_situacao"
                    value={filtro.parcont_situacao}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    <option value="A VENCER">A VENCER</option>
                    <option value="BAIXADO">BAIXADO</option>
                    <option value="VENCIDO">VENCIDO</option>
                    <option value="ESTORNADO">ESTORNADO</option>
                    <option value="CANCELADO">CANCELADO</option>
                    <option value="AVENCER_VENCIDO">A VENCER E VENCIDO</option>
                    <option value="AVENCER_BAIXADO">A VENCER E BAIXADO</option>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar data por*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="data_por"
                    value={filtro.data_por}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="VENCIMENTO">Data de Vencimento</option>
                    <option value="PAGAMENTO">Data de Pagamento</option>
                    <option value="CANCELAMENTO">Data de Cancelamento</option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Forma de Pagto"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="formpag_id"
                    value={filtro.formpag_id}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    {this.state.formasPagamento.list.map(forma => {
                      return (
                        <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Conta Financeira"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="contfin_id"
                    value={filtro.contfin_id}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    {contasFinanceira.list.map(conta => {
                      return (
                        <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situação do Aluno"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="stcontmat_situacao"
                    value={filtro.stcontmat_situacao}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""></option>
                    <option value="APROVADO">Aprovado</option>
                    <option value="INATIVO">Inativo</option>
                    <option value="TRANSFERIDO">Transferido</option>
                    <option value="CANCELADO">Cancelado</option>
                    <option value="ENCERRADO">Encerrado</option>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={7} xs={false} sm={6}>
                  {filtro.contpr_tipo === '' &&
                    <p style={{fontSize: '20px'}}>Tipo das Contas: A PAGAR / A RECEBER</p>
                  }
                  {filtro.contpr_tipo !== '' &&
                    <p style={{fontSize: '20px'}}>Tipo das Contas: {filtro.contpr_tipo === 'PAGAR' ? 'A PAGAR' : 'A RECEBER'}</p>
                  }
                </Grid>
                <Grid item md={1} xs={12} sm={3}>
                    {this.state.loadingImprimir &&
                      <CircularProgress />
                    }
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={this.state.loadingImprimir}>
                    Imprimir
                  </Button>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={this.state.loadingImprimir}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              {this.state.loadingTable &&
                <div>
                  <CircularProgress />
                </div>
              }
              {!this.state.loadingTable &&
                <div>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12}>
                      <Table
                        urlUpdate="/conta_pagar_receber/cadastro/" 
                        headCell={this.state.cabecalhoTabela} 
                        rows={contas.list} 
                        acoes={this.state.acoesTabela} 
                        imprimirBoleto={e => this.imprimirBoleto(e)}
                        tamanhoFonte={'12px'}/>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={0} className="mg_top_10">
                    <Grid item md={3} xs={false} sm={false}></Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <p style={{textAlign: 'right', fontSize: '22px'}}>A Receber/Pagar: R$ <span style={{color: totais.totalParcelaReceber - totais.totalParcelaPagar < 0 ? '#FF0000' : '#006400' }}> {real(totais.totalParcelaReceber - totais.totalParcelaPagar)}</span></p>
                    </Grid>
                    <Grid item md={3} xs={12} sm={5}>
                      <p style={{textAlign: 'right', fontSize: '22px'}}>Recebidos/Pagos: R$ <span style={{color: totais.totalPagoReceber - totais.totalPagoPagar < 0 ? '#FF0000' : '#006400' }}>{real(totais.totalPagoReceber - totais.totalPagoPagar)} </span></p>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                    <p style={{textAlign: 'right', fontSize: '22px'}}>Resultado: R$ <span style={{color:  (totais.totalPagoReceber - totais.totalPagoPagar) - (totais.totalParcelaReceber - totais.totalParcelaPagar) < 0 ? '#FF0000' : '#006400' }}>{real((totais.totalPagoReceber - totais.totalPagoPagar) - (totais.totalParcelaReceber - totais.totalParcelaPagar))}</span></p>
                    </Grid>
                  </Grid>
                </div>
              }
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalExibeBoleto 
            open={this.state.openModalExibeBoleto}
            dados={this.state.login}
            handleClose={e => this.handleModalExibeBoleto()} 
            boletoSelecionado={this.state.boletoSelecionado}
            copyLink={e => this.copyLink(e)} 
          />
          <Alert 
            open={this.state.alerta.open}
            handleClose={e => this.handleCloseAlerta()} 
            severity={this.state.alerta.severity}
            message={this.state.alerta.message} 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}