import React, { Component } from 'react'
import './../Relatorios.scss'
import './../../../main/ultil.scss'
import { Grid } from '@material-ui/core'
import Chart from "react-google-charts";
import moment from 'moment'

const initalState = {
  data_charts_atrasadas: [],
  data_charts_futuras: [],
  data_charts_inativos: [],
  data_charts_periodo: [],
  total_alunos_inativos: 0,
  total_atrasadas: 0,
  total_futuras: 0,
  total_mes: 0,
  data_charts_total: [],
  data_charts_anterior: [],
  data_charts_atual: [],
  data_charts_dentro_atrasadas : [],
  unidadeSelecionada: {
    unin_id: '',
		unin_numero: '',
		unin_descricao: '',
		pontos_matricula : 0,
		pontos_rematricula : 0,
		pontos_prorrogacao : 0,
		md : 0,
		total: 0
  },
  recebidos_mes: 0,
  filtro: {
    relatorio: "",
    data: '',
    unin_id: ''
  }
}

export default class FlyHigh extends Component {
  state = { ...initalState }

  async componentWillMount() {
    const dados = JSON.parse(localStorage.getItem('grafico_carteira_unidade'))
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    const {
      data_charts_atrasadas,
      data_charts_futuras,
      data_charts_inativos,
      data_charts_periodo,
      data_charts_total,
      data_charts_anterior,
      data_charts_atual,
      data_charts_dentro_atrasadas,
      total_alunos_inativos,
      total_atrasadas,
      total_futuras,
      total_mes
    } = dados

    this.setState({
      data_charts_atrasadas,
      data_charts_futuras,
      data_charts_inativos,
      data_charts_periodo,
      data_charts_total,
      data_charts_anterior,
      data_charts_atual,
      data_charts_dentro_atrasadas,
      total_alunos_inativos,
      total_atrasadas,
      total_futuras,
      total_mes,
      recebidos_mes: dados.recebidos_mes,
      total: dados.total,
      filtro: dados.filtro,
      unidadeSelecionada: dados.unidadeSelecionada,
      pessoa
    })
    
    setTimeout(() => {
      window.print()
      this.props.history.push("/carteira_mensalidades");
      this.setState({ loading: false })
      return;
    }, 500);
 
  }    

  render() {
    const { filtro, pessoa } = this.state
		
		return (
      <div id="app">
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={12} xs={12} sm={12}>
            <p style={{fontSize: '42px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'center'}}><b>Fly High - Unidades</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Relatório: {moment(new Date()).format('DD/MM/YYYY HH:mm')}</b></p>
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Gerado por: {pessoa.fisica.pesf_nome.split(' ')[0]}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Filtro: {filtro.data}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12}>
            <h4>Unidade: {this.state.unidadeSelecionada.unin_numero} - {this.state.unidadeSelecionada.unin_descricao}</h4>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} className='cardCarteira'>
              <div className='border'>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12}>
                    <h5 style={{float: 'left', marginTop: '10px', marginLeft: '10px'}}>Dentro da Carteira:</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" style={{marginTop: '-30px'}}>
                  <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_total}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: 0,
                        redTo: this.state.total * 0.10,
                        yellowFrom: this.state.total * 0.10,
                        yellowTo: this.state.total * 0.25,
                        greenTo: this.state.total,
                        greenFrom: this.state.total * 0.85,
                        minorTicks: 5,
                        max: this.state.total
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_anterior}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: 0,
                        redTo: this.state.total * 0.10,
                        yellowFrom: this.state.total * 0.10,
                        yellowTo: this.state.total * 0.25,
                        greenTo: this.state.total,
                        greenFrom: this.state.total * 0.85,
                        minorTicks: 5,
                        max: this.state.total
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_atual}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: 0,
                        redTo: this.state.total * 0.10,
                        yellowFrom: this.state.total * 0.10,
                        yellowTo: this.state.total * 0.25,
                        greenTo: this.state.total,
                        greenFrom: this.state.total * 0.85,
                        minorTicks: 5,
                        max: this.state.total
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_dentro_atrasadas}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: 0,
                        redTo: this.state.total * 0.10,
                        yellowFrom: this.state.total * 0.10,
                        yellowTo: this.state.total * 0.25,
                        greenTo: this.state.total,
                        greenFrom: this.state.total * 0.85,
                        minorTicks: 5,
                        max: this.state.total
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} className='cardCarteira'>
              <div className='border'>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12}>
                    <h5 style={{float: 'left', marginTop: '10px', marginLeft: '10px'}}>Fora da Carteira:</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" style={{marginTop: '-30px'}}>
                <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_atrasadas}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: this.state.total_atrasadas * 0.90,
                        redTo: this.state.total_atrasadas,
                        yellowFrom: this.state.total_atrasadas * 0.75,
                        yellowTo: this.state.total_atrasadas * 0.90,
                        greenTo: this.state.total_atrasadas * 0.15,
                        greenFrom: 0,
                        minorTicks: 5,
                        max: this.state.total_atrasadas
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_futuras}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: this.state.total_futuras * 0.90,
                        redTo: this.state.total_futuras,
                        yellowFrom: this.state.total_futuras * 0.75,
                        yellowTo: this.state.total_futuras * 0.90,
                        greenTo: this.state.total_futuras * 0.15,
                        greenFrom: 0,
                        minorTicks: 5,
                        max: this.state.total_futuras
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_inativos}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: this.state.total_alunos_inativos * 0.90,
                        redTo: this.state.total_alunos_inativos,
                        yellowFrom: this.state.total_alunos_inativos * 0.75,
                        yellowTo: this.state.total_alunos_inativos * 0.90,
                        greenTo: this.state.total_alunos_inativos * 0.15,
                        greenFrom: 0,
                        minorTicks: 5,
                        max: this.state.total_alunos_inativos
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <Chart
                      chartType="Gauge"
                      width="100%"
                      height="200px"
                      data={this.state.data_charts_periodo}
                      formatters={[
                        {
                          type: 'NumberFormat',
                          column: 1,
                          options: {
                            groupingSymbol: '.',
                            decimalSymbol: ','
                          },
                        },
                      ]}
                      options={{
                        width: '100%',
                        height: 200,
                        redFrom: this.state.total_mes * 0.90,
                        redTo: this.state.total_mes,
                        yellowFrom: this.state.total_mes * 0.75,
                        yellowTo: this.state.total_mes * 0.90,
                        greenTo: this.state.total_mes * 0.15,
                        greenFrom: 0,
                        minorTicks: 5,
                        max: this.state.total_mes
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
      </div>
    )
  }
}