import React from 'react'
import moment from 'moment';
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl } from '@material-ui/core'

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

const FormNovoContrato = ({ novoContrato, departamentos, cargos, funcaos, funcionario, updateField, status, unidades, updateFieldMudarUnidade, unin_tipo, updateFieldNovoContrato }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Nome" variant="outlined" size="small" name="nome" value={funcionario.pessoaFisica.nome} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="CPF" variant="outlined" size="small" name="cpf" value={funcionario.pessoaFisica.cpf} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="RG" variant="outlined" size="small" name="rg" value={funcionario.pessoaFisica.rg} disabled />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Matricula" variant="outlined" size="small" name="matricula" value={funcionario.matricula} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Departamento" variant="outlined" size="small" name="departamento" value={funcionario.contrato.departamento} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Cargo" variant="outlined" size="small" name="cargo" value={funcionario.contrato.cargo} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Função" variant="outlined" size="small" name="funcao" value={funcionario.contrato.funcao} disabled />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Data de Admissão" variant="outlined" size="small" name="dataAdmissao" value={moment(funcionario.dataAdmissao).format('DD/MM/YYYY')} disabled />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Data Inicio Contrato" variant="outlined" size="small" name="dataInicial" value={moment(funcionario.contrato.dataInicial).format('DD/MM/YYYY')} disabled />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        {status === 'demitir' &&
          <TextField type="date" className="input" label="Data da Demissão*" variant="outlined" size="small" name="dataFinal" value={funcionario.contrato.dataFinal} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { max: dataHojeFormatado } }} />
        }
        {status === 'promover' &&
          <TextField type="date" className="input" label="Data da Promoção*" variant="outlined" size="small" name="dataFinal" value={funcionario.contrato.dataFinal} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { max: dataHojeFormatado } }} />
        }
        {status === 'transferir' &&
          <TextField type="date" className="input" label="Data da Transferência*" variant="outlined" size="small" name="dataFinal" value={funcionario.contrato.dataFinal} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { max: dataHojeFormatado } }} />
        }
      </Grid>
    </Grid>
    {status === 'promover' &&
      <div>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={4} xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Deseja mudar de Unidade?</FormLabel>
              <FormControlLabel className="mg_left_10"
                control={
                  <Switch
                    name="mudar_unidade"
                    color="primary"
                    checked={novoContrato.mudar_unidade}
                  />
                }
                size="small"
                label={novoContrato.mudar_unidade ? 'Sim' : 'Não'}
                name="mudar_unidade"
                onChange={(e) => updateFieldMudarUnidade(e)}
              />
            </FormControl>
          </Grid>
        </Grid>
        {novoContrato.mudar_unidade &&
          <div>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12}>
                <h4>Nova Unidade</h4>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item md={4} xs={12} sm={6}>
                <TextField className="input" label="Unidade Atual" variant="outlined" size="small" name="dataInicial" value={funcionario.contrato.unidadetrabalho.unin_descricao ? funcionario.contrato.unidadetrabalho.unin_descricao : (funcionario.contrato.unidadetrabalho.pessoa.pes_tipo === "JURIDICA" ? funcionario.contrato.unidadetrabalho.pessoa.juridica.pesj_razaosocial : funcionario.contrato.unidadetrabalho.pessoa.fisica.pesf_nome)} disabled InputLabelProps={{ shrink: true }} />
              </Grid>
              {unin_tipo !== 'UNIDADE' &&
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Para Onde*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="paraOnde"
                    value={novoContrato.paraOnde}
                    onChangeCapture={(e) => updateFieldNovoContrato(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <option value="">Selecionar Para Onde:</option>
                    {unin_tipo === 'FRANQUEADOR' &&
                      <React.Fragment>
                        <option value="FRANQUEADOR">Franqueador</option>
                        <option value="FRANQUEADO">Franqueado</option>
                        <option value="UNIDADE">Unidade</option>
                      </React.Fragment>
                    }
                    {unin_tipo === 'FRANQUEADO' &&
                      <React.Fragment>
                        <option value="FRANQUEADO">Franqueado</option>
                        <option value="UNIDADE">Unidade</option>
                      </React.Fragment>
                    }
                  </TextField>
                </Grid>
              }
              {novoContrato.paraOnde !== '' &&
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Nova Unidade*"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="unidadetrabalho_id"
                    value={novoContrato.unidadetrabalho_id}
                    onChangeCapture={(e) => updateFieldNovoContrato(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <option value={novoContrato.unidadetrabalho_id === "" ? "" : novoContrato.unidadetrabalho_id}> {novoContrato.unidadetrabalho_id === "" ? "Selecionar Unidade" : novoContrato.unidade} </option>
                    {
                      unidades.filter(param => param.unin_tipo === novoContrato.paraOnde).map(unidade => {
                        if (unidade.unin_id !== novoContrato.unidadetrabalho_id) {
                          if (unidade.unin_id !== funcionario.contrato.unidadetrabalho.unin_id) {
                            return (
                              <option key={unidade.unin_id} value={unidade.unin_id}> {unidade.unin_descricao} </option>
                            )
                          } else {
                            return false
                          }
                        } else {
                          return false
                        }
                      })
                    }
                  </TextField>
                </Grid>
              }
            </Grid>
          </div>
        }
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12}>
            <h4>Nova Função</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item md={4} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Departamento*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="idDepartamento"
              value={novoContrato.departamento}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{
                shrink: true
              }}
            >
              <option value={novoContrato.departamento === "" ? "" : novoContrato.idDepartamento}> {novoContrato.departamento === "" ? "Selecionar Departamento" : novoContrato.departamento} </option>
              {
                departamentos.map(departamento => {
                  if (departamento.dep_id !== novoContrato.idDepartamento) {
                    return (
                      <option key={departamento.dep_id} value={departamento.dep_id}> {departamento.dep_descricao} </option>
                    )
                  } else {
                    return false
                  }
                })
              }
            </TextField>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Cargo*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="idCargo"
              value={novoContrato.cargo}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{
                shrink: true
              }}
            >
              <option value={novoContrato.cargo === "" ? "" : novoContrato.idCargo}> {novoContrato.cargo === "" ? "Selecionar Cargo" : novoContrato.cargo} </option>
              {
                cargos.map(cargo => {
                  if (cargo.car_id !== novoContrato.idCargo) {
                    return (
                      <option key={cargo.car_id} value={cargo.car_id}> {cargo.car_descricao} </option>
                    )
                  } else {
                    return false
                  }
                })
              }
            </TextField>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Funcão*"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="idFuncao"
              value={novoContrato.funcao}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{
                shrink: true
              }}
            >
              <option value={novoContrato.funcao === "" ? "" : novoContrato.idFuncao}> {novoContrato.funcao === "" ? "Selecionar Função" : novoContrato.funcao} </option>
              {
                funcaos.map(funcao => {
                  if (funcao.func_id !== novoContrato.idFuncao) {
                    return (
                      <option key={funcao.func_id} value={funcao.func_id}> {funcao.func_descricao} </option>
                    )
                  } else {
                    return false
                  }
                })
              }
            </TextField>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Observação Novo Contrato"
              className="input"
              multiline
              rows={6}
              size="small"
              variant="outlined"
              name="observacao"
              value={novoContrato.observacao}
              onChange={(e) => updateFieldNovoContrato(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>
    }
    {status === 'demitir' &&
      <div>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Observação"
              className="input"
              multiline
              rows={6}
              size="small"
              variant="outlined"
              name="observacao"
              value={funcionario.contrato.observacao}
              onChange={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </div>
    }
  </React.Fragment>
)


export default FormNovoContrato