import React, { Component } from 'react'
import './Feriado.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalErro from './../../components/modals/Erro'

const initalState = {
  feriado: {

  },
  feriados: {
    list: []
  },
  feriadosFiltrado: {
    list: []
  },
  filtro: {
    fer_descricao: "",
    fer_tipo: "",
    unidade: ""
  },
  cabecalhoTabela: [
    { id: 'fer_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'fer_data', numeric: false, disablePadding: true, label: 'Data', orderBy: 'data_order' },
    { id: 'fer_tipo', numeric: false, disablePadding: false, label: 'Tipo Feriado' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao: {
    mensagem: '',
  },
  feriadoSelecionado: {
    _id: ''
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}

function searching(term, itemFiltro) {
  if (itemFiltro === "fer_descricao") {
    return function (x) {
      return x.aula.fer_descricao.toLowerCase().includes(term.toLowerCase()) || !term;
    }
  } else if (itemFiltro === "fer_tipo") {
    return function (x) {
      return x.fer_tipo.toLowerCase().includes(term.toLowerCase()) || !term;
    }
  } else if (itemFiltro === "unidade") {
    return function (x) {
      for (let i = 0; i < x.unidades.length; i++) {
        const unidadeFiltro = x.unidades[i].unin_numero_descricao.toLowerCase().includes(term.toLowerCase()) || !term
        if (unidadeFiltro) {
          return x
        }
      }
    }
  }
}

export default class Feriado extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'feriados')[0]

    let acoesTabela = []

    if (permissoes.perm_deletar) {
      acoesTabela.push('removerFeriado')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    try {

      const { data: feriados } = await axios.post(`${process.env.REACT_APP_API_URL}/feriados/list`, dados, this.getToken())

      this.setState({
        feriados: {
          list: feriados.map(feriado => {
            const dataFeriado = moment(feriado.fer_data, moment.defaultFormat).toDate()
            dataFeriado.setMinutes(dataFeriado.getMinutes() + dataFeriado.getTimezoneOffset() + 60)
            return {
              _id: feriado.fer_id,
              fer_descricao: feriado.fer_descricao,
              fer_tipo: feriado.fer_tipo,
              fer_data: moment(dataFeriado).format('DD/MM/YYYY'),
              data_order: moment(dataFeriado).format('YYYYMMDD'),
              unidades: feriado.unidades.map(unidade => {
                return {
                  unin_numero: unidade.unin_numero,
                  unin_descricao: unidade.unin_descricao,
                  unin_numero_descricao: `${unidade.unin_numero} - ${unidade.unin_descricao}`
                }
              })
            }
          })
        },
        feriadosFiltrado: {
          list: feriados.map(feriado => {
            const dataFeriado = moment(feriado.fer_data, moment.defaultFormat).toDate()
            dataFeriado.setMinutes(dataFeriado.getMinutes() + dataFeriado.getTimezoneOffset() + 60)
            return {
              _id: feriado.fer_id,
              fer_descricao: feriado.fer_descricao,
              fer_tipo: feriado.fer_tipo,
              fer_data: moment(dataFeriado).format('DD/MM/YYYY'),
              data_order: moment(dataFeriado).format('YYYYMMDD'),
              unidades: feriado.unidades.map(unidade => {
                return {
                  unin_numero: unidade.unin_numero,
                  unin_descricao: unidade.unin_descricao,
                  unin_numero_descricao: `${unidade.unin_numero} - ${unidade.unin_descricao}`
                }
              })
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const feriados = this.state.feriados.list
    const filtro = this.state.filtro

    const list = feriados.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      feriadosFiltrado: {
        list
      },
      filtro
    })

  }

  deletar(row){
    this.setState({
      modalConfirmacaoOpen: true,
      feriadoSelecionado: row,
      modalConfirmacao: {
        mensagem: `Deseja excluir o feriado ${row.fer_descricao} no dia ${row.fer_data}?`,
      }
    })
  }

  handleCloseConfirmacao() {
    this.setState({
      modalConfirmacaoOpen: false
    })
  }

  async confirmarDeletar(){
    this.setState({
      modalConfirmacaoOpen: false
    })

    const {feriadoSelecionado} = this.state

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/feriados/${feriadoSelecionado._id}`, this.getToken())
    
      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Registro removido com sucesso.'
      })

      window.location.reload()
    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { filtro, permissoes, feriadosFiltrado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={8} xs={12}>
              <h1 className="titulo">Feriados</h1>
            </Grid>
            {permissoes.perm_inserir &&
              <Grid item md={4} xs={12}>
                <Link to="/feriado/cadastro">
                  <Button className="btnCadastrar" variant="contained" color="primary">
                    Cadastrar Feriado
                  </Button>
                </Link>
              </Grid>
            }
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
            <Grid item md={4} xs={12} sm={6}>
              <TextField className="input" label="Descrição" variant="outlined" size="small" name="fer_descricao" value={filtro.fer_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <TextField className="input" label="Tipo Feriado" variant="outlined" size="small" name="fer_tipo" value={filtro.fer_tipo} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <TextField className="input" label="Unidade" variant="outlined" size="small" name="unidade" value={filtro.unidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Ex.: 1 - Unidade' />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
          >
            <Grid item md={12} xs={12}>
              <Table 
              urlUpdate="/feriado/cadastro/" 
              headCell={this.state.cabecalhoTabela} 
              rows={feriadosFiltrado.list} 
              acoes={this.state.acoesTabela}
              remover={e => this.deletar(e)} 
              unin_tipo={this.state.unin_tipo}/>
            </Grid>
          </Grid>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        <ModalConfirmacao 
          open={this.state.modalConfirmacaoOpen} 
          dados={this.state.modalConfirmacao} 
          handleClose={e => this.handleCloseConfirmacao(e)}  
          confirmar={e => this.confirmarDeletar(e)} />
      </div>
    )
  }
}