import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import Table from './../Table'

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const {aulas_abertas} = props

  const cabecalhoTabela = [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' }
  ]
  const acoesTabela = ['viewBaixaAula']

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Aulas Abertas</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p>Esse aluno possui essas aulas que não foram baixadas:</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={aulas_abertas.map(agenda => {
                  return {
                    _id: agenda.ageaula_id,
                    ageaula_data_hora: moment(agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm'),
                    data_aula_sem_form: moment(agenda.ageaula_data_hora).format('YYYY-MM-DD'),
                    aula_nome: agenda.aula.aula_nome,
                    professor: agenda.professor.funcionario.fisica.pesf_nome
                  }
                })}
                acoes={acoesTabela}
                urlView={'/baixa_aula/'}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja continuar?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.voltar()}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar()}>Continuar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
