import React, { Component } from 'react'
import './Login.scss'
import './../../main/ultil.scss'
import { Container, Grid, Button, TextField } from '@material-ui/core'
import Rodape from './../../components/RodapeLogin'
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'

const initalState = {
  dados: {
    email: ""
  },
  loading: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  nivelSenha: 'Muito Fraca',
  corSenha: 'red',
}

function validacaoEmail(email) {
  const usuario = email.substring(0, email.indexOf("@"));
  const dominio = email.substring(email.indexOf("@") + 1, email.length);

  if ((usuario.length >= 1) &&
    (dominio.length >= 3) &&
    (usuario.search("@") === -1) &&
    (dominio.search("@") === -1) &&
    (usuario.search(" ") === -1) &&
    (dominio.search(" ") === -1) &&
    (dominio.search(".") !== -1) &&
    (dominio.indexOf(".") >= 1) &&
    (dominio.lastIndexOf(".") < dominio.length - 1)) {
    return true
  }
  else {
    return false
  }
}

export default class Login extends Component {

  constructor(props) {
    super(props)

    localStorage.clear();
  }

  state = { ...initalState }

  componentWillMount() {
    this.setState({
      dados: {
        email: ""
      }
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Login!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  updateField(event) {
    const { dados } = this.state
    dados[event.target.name] = event.target.value
    this.setState({ dados })
  }

  async enviarTrocaSenha() {
    const { dados } = this.state

    if (!validacaoEmail(dados.email)) {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: 'Email Invalido!'
      })
      return
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/recuperarSenha`, this.state.dados)

      this.props.history.push(`/trocar_senha/${data.usu_id}`)
      this.setState({ loading: false })
      return;

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Opps',
        descricao: response.data.message
      })
      return
    }

  }

  render() {
    const { email } = this.state.dados
    return (
      <React.Fragment>
        <div className="topo">
          <img src="./images/logo.png" alt="Logo British And American" />
        </div>
        <Container>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item md={4}>
              <div className="formLogin">
                <Container>
                  <Grid container direction="row" >
                    <Grid item md={12}>
                      <h3>RECUPERAR SENHA</h3>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_20" >
                    <Grid item md={12} xs={12} sm={12}>
                      <TextField className="input" label="Email" variant="outlined" name="email" value={email} onChange={(e) => this.updateField(e)} />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className="mg_top_20" alignItems="center" justify="center">
                    <Grid item md={8}>
                      <Button disabled={this.state.loading} variant="contained" className="btn_entrar" size="small" onClick={() => this.enviarTrocaSenha()}>
                        Enviar
											</Button>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Rodape />
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
      </React.Fragment>
    )
  }
}