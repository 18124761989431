import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { Grid, TextField, FormControlLabel, Switch, Button } from '@material-ui/core'

const FormPessoaJuridica = ({ dados, updateField, updateFieldPrincipal, removerItem, disabledDados }) => (
  <React.Fragment>
    <Grid container direction="row">
      <Grid item md={12}>
        <h4>E-mail {dados.index}</h4>
      </Grid>
    </Grid>

    <Grid container spacing={1} direction="row">
      <Grid item md={7} xs={12} sm={6}>
        <TextField fullWidth className="input" label="E-mail" variant="outlined" size="small" name="email" value={dados.email} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} disabled={disabledDados ? disabledDados('academico') : false}/>
        <span id={`error-ema-email${dados.index}`} className="error">Informar E-mail</span>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="principal"
              color="primary"
              checked={dados.principal}
            />
          }
          label="Principal"
          name="principal"
          onChange={(e) => updateFieldPrincipal(e)}
          disabled={disabledDados ? disabledDados('academico') : false}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        {!(disabledDados ? !disabledDados('academico') : false ) &&
          <Button fullWidth onClick={() => removerItem(dados.index)} color="secondary" variant="contained" size="small" startIcon={<DeleteIcon />}>Remover E-mail</Button>
        }
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormPessoaJuridica