import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './App.scss'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import React, {Component} from 'react'
import socketClient from './../components/lib/socket'

export default class App extends Component {

  async UNSAFE_componentWillMount() {
    socketClient('',{})  
  }

  render(){
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    )
  }
}