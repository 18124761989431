import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core'
import Table from './../Table'
import Chart from "react-google-charts";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    marginLeft: '10%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

const cabecalhoTabelaGeral = [
	{ id: 'item', numeric: false, disablePadding: false, label: 'Item' },
	{ id: 'pontos', numeric: false, disablePadding: false, label: 'Pontos' }
]

const cabecalhoTabelaAssessorPorItem = [
	{ id: 'pesf_nome', numeric: false, disablePadding: false, label: 'Assessor' },
	{ id: 'pontos_matricula', numeric: false, disablePadding: false, label: 'Matricula' },
	{ id: 'pontos_rematricula', numeric: false, disablePadding: false, label: 'Rematricula' },
	{ id: 'pontos_prorrogacao', numeric: false, disablePadding: false, label: 'Prorrogação' },
	{ id: 'md', numeric: false, disablePadding: false, label: 'MD' }
]

export default function SimpleModal(props) {
  const classes = useStyles()

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Ranking Fly High</h5>
            </Grid>
          </Grid>
          <br />
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
							<h4>Unidade: {props.unidadeSelecionada.unin_numero} - {props.unidadeSelecionada.unin_descricao}</h4>
						</Grid>
					</Grid>
					<br />
					<hr />
					<br />
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
								<h5>Pontos da Unidade:</h5>
						</Grid>
					</Grid>
					<Grid container spacing={1} direction="row">
						<Grid item md={4} xs={12}>
							<Table 
								headCell={cabecalhoTabelaGeral} 
								rows={[
									{item: 'Matricula', pontos: props.unidadeSelecionada.pontos_matricula},
									{item: 'Rematricula', pontos: props.unidadeSelecionada.pontos_rematricula},
									{item: 'Prorrogação', pontos: props.unidadeSelecionada.pontos_prorrogacao},
									{item: 'MD', pontos: props.unidadeSelecionada.md}
								]} 
								acoes={[]}
							/>
						</Grid>
						<Grid item md={8} xs={12}>
							<Chart
								width={'100%'}
								height={'500px'}
								chartType="PieChart"
								data={[
									['Item', 'Pontos'],
									['Matricula', props.unidadeSelecionada.pontos_matricula],
									['Rematricula', props.unidadeSelecionada.pontos_rematricula],
									['Prorrogação', props.unidadeSelecionada.pontos_prorrogacao],
									['MD', props.unidadeSelecionada.md],
								]}
								options={{
									chartArea: {height: '90%'},
									is3D: true,
								}}
							/>
						</Grid>
					</Grid>
					<hr />
					<br />
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
								<h5>Ranking por Assessor:</h5>
						</Grid>
					</Grid>
					<Grid container spacing={1} direction="row">
						<Grid item md={4} xs={12}>
							<Table 
								headCell={props.subCabecalhoTabela} 
								rows={props.subRelatorios.list} 
								acoes={[]}
								qtdLinhas = {props.subRelatorios.list.length}
							/>
						</Grid>
						<Grid item md={8} xs={12}>
							<Chart
								width={'100%'}
								height={'400px'}
								chartType="BarChart"
								data={props.subDataChartGeral}
								options={{
									chartArea: {height: '90%'},
									legend: "none",
									annotations: {
										textStyle: {
											fontSize: 18
										}
									}
								}} 
							/>
						</Grid>
					</Grid>
					<hr />
					<br />
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
							<h5>Ranking de Assessor:</h5>
						</Grid>
					</Grid>
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
							<Table 
								headCell={cabecalhoTabelaAssessorPorItem} 
								rows={props.subRelatorios.list} 
								qtdLinhas = {props.subRelatorios.list.length}
								acoes={[]} 
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1} direction="row">
						<Grid item md={12} xs={12}>
							<Chart
								width={'100%'}
								height={'auto'}
								chartType="BarChart"
								data={props.subDataChartItem}
								options={{
									chartArea: { width: '70%', height: 'auto'},
									timeline: { groupByRowLabel: false },
									isStacked: true,
								}}
								// For tests
								rootProps={{ 'data-testid': '3' }}
							/>
						</Grid>
					</Grid>
        </div>
      </Modal>
    </div>
  );
}
