import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import moment from 'moment'
import ModalErro from './../../components/modals/Erro'
import ModalRelatorio from './../../components/modals/Relatorios'
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

const initalState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
    data: '',
    cabecalhoTabela: []
  },
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm: {
        perm_visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  ModalRelatorio: false,
  cabecalhoTabelaQtdAulas: [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
    { id: 'qtd_alunos2', numeric: false, disablePadding: false, label: 'Qtd. Alunos' }
  ],
  acoesTabelaQtdAulas: [],
  linhaSelecionada: '',
  total: null
}

const maskData = value => {
  return value.replace(/\D/g, "").replace(/^(\d{2})(\d{4})+?$/, "$1/$2");
};

export default class Comercial extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { filtro } = this.state

    filtro.data_inicial = moment(new Date()).format('YYYY-MM-DD')

    this.setState({
      filtro
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'rel-comercial')

    this.setState({
      relatorios_options: {
        list: permissoes.map(value => {
          return {
            tela_descricao: value.tela.tela_descricao,
            tela_slug: value.tela.tela_slug,
            perm: {
              perm_visualizar: value.perm_visualizar
            }
          }
        })
      }
    })
  }

  updateFieldFiltro(event) {
    const filtro = this.state.filtro

    let name = event.target.name
    let value = event.target.value

    if(name === 'relatorio'){
      this.setState({
        cabecalhoTabela: [],
        acoesTabela: [],
        relatorios: {
          list: []
        },
      })
    }

    if(name === 'data'){
      filtro[name] = maskData(value)
    }else{
      filtro[name] = value
    }

    this.setState({ filtro })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loading: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      if (filtro.relatorio === 'contratos-periodo') {
        if (filtro.data_inicial === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data inicial'
          })
          this.setState({
            loading: false
          })
          return
        }

        if (filtro.data_final === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data final'
          })
          this.setState({
            loading: false
          })
          return
        }
      }

      if (filtro.relatorio === 'evolucao-alunos-periodo') {
        if (filtro.data === '') {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data'
          })
          this.setState({
            loading: false
          })
          return
        }
    
        const aux = filtro.data.split('/')
    
        if( aux.length !== 2){
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data corretamente'
          })
          this.setState({
            loading: false
          })
          return
        }
    
        const mes = parseInt(aux[0])
        const ano = parseInt(aux[1])
    
        if( !mes || mes < 1 || mes > 12){
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data corretamente'
          })
          this.setState({
            loading: false
          })
          return
        }
    
        if( !ano || ano < 1990){
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: 'Por favor informar a data corretamente'
          })
          this.setState({
            loading: false
          })
          return
        }
      }

      localStorage.setItem('filtro_relatorio', JSON.stringify(filtro))

      let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())

      console.log(filtro.relatorio)

      let total = null

      if(filtro.relatorio === 'contratos-periodo-unidades'){
        total = data.list.pop()
      }
      
      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list
        },
        urlView: data.urlView,
        total
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }
  }

  async imprimir() {
    const { filtro, relatorios } = this.state

    this.setState({
      loadingDownload: true
    })

    if(filtro.relatorio === 'evolucao-alunos-periodo'){
      const dados = {
        filtro,
        relatorio: relatorios.list,
        cabecalhoTabela: this.state.cabecalhoTabela
      }
  
      localStorage.setItem('evolucao_alunos_periodo', JSON.stringify(dados));

      this.props.history.push("/rel_comercial/impressao/evolucao_alunos_periodo");
      this.setState({ loadingDownload: false })
      return;
    }else{
      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.unin_id
          })
        }
  
        filtro.unidadesnegocio = dados.unidadesnegocio
  
        if (filtro.relatorio !== 'contratos-alunos') {
          filtro.list = relatorios.list
        }
  
        filtro.cabecalhoTabela = this.state.cabecalhoTabela
  
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())
  
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        this.setState({
          loadingDownload: false
        })
  
        if (filtro.relatorio === 'contratos-periodo')
          saveAs(pdfBlob, 'Contratos-Periodo.pdf')
        if (filtro.relatorio === 'contratos-periodo-unidades')
          saveAs(pdfBlob, 'Contratos-Periodo-Unidades.pdf')
  
      } catch (error) {
        this.setState({
          loadingDownload: false
        })
        console.log(error)
      }
    }

  }

  async imprimirSub(){
    const { filtro, filtroSub, linhaSelecionada } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtroSub.unidadesnegocio = dados.unidadesnegocio
      filtroSub.titulo = filtro.relatorio.replace('-', ' ').replace('-', ' ').toUpperCase() + ' - ' + linhaSelecionada
      filtroSub.data_inicial = filtro.data_inicial
      filtroSub.data_final = filtro.data_final

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtroSub, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      if (filtro.relatorio === 'contratos-periodo')
        saveAs(pdfBlob, 'Contratos-Periodo.pdf')

      if(filtro.relatorio === 'contratos-alunos')
        saveAs(pdfBlob, 'Alunos.pdf')

      if(filtro.relatorio === 'evolucao-alunos-periodo')
        saveAs(pdfBlob, 'Alunos.pdf')
    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  async viewRow(ids, acao, status) {
    try {

      let filtroAux = {
        relatorio: acao,
        ids: ids,
        status
      }
            
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtroAux, this.getToken())

      const filtroSub = this.state.filtroSub

      filtroSub.relatorio = acao
      filtroSub.list = data.list

      this.setState({
        cabecalhoTabelaModal: data.cabecalhoTabela,
        acoesTabelaModal: data.acoesTabela,
        relatoriosModal: {
          list: data.list
        },
        filtroSub,
        urlViewModal: data.urlView,
        modalRelatorio: true,
        linhaSelecionada: status
      })

    } catch (error) {
      console.log(error)
      // this.handleOpenDialog({
      //   titulo: 'Ops...',
      //   descricao: response.data.message
      // })
      return
    }
  }

  handleCloseModalRelatorio() {
    this.setState({
     modalRelatorio: false,
     filtro: JSON.parse(localStorage.getItem('filtro_relatorio'))
    })
  }

  render() {
    const { filtro, cabecalhoTabela, acoesTabela, relatorios, urlView, loadingDownload, relatorios_options, total } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Relatório Comercial</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_20">
            <Grid item md={4} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Relatório"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="relatorio"
                value={filtro.relatorio}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha o Relatório--</option>
                {relatorios_options.list.sort((a, b) => (a.tela_descricao > b.tela_descricao) ? 1 : ((b.tela_descricao > a.tela_descricao) ? -1 : 0)).map(value => {
                  if (value.perm) {
                    if (value.perm.perm_visualizar) {
                      return (
                        <option value={value.tela_slug}>{value.tela_descricao}</option>
                      )
                    }
                  }
                  return false
                })}
              </TextField>
            </Grid>

            {(filtro.relatorio === "contratos-periodo" || filtro.relatorio === "contratos-periodo-unidades") &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            {filtro.relatorio === "evolucao-alunos-periodo" &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  type="text" 
                  placeholder='xx/xxxx' 
                  label="Mês/Ano" 
                  variant="outlined" 
                  size="small" 
                  name="data" 
                  value={filtro.data} 
                  inputProps={{ maxLength: 7 }}
                  onChange={(e) => this.updateFieldFiltro(e)}
                  InputLabelProps={{ shrink: true }} />
                </Grid>
              </React.Fragment>
            }
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.relatorio === '' ? true : false}>
                Filtrar
              </Button>
            </Grid>
            {relatorios.list.length > 0 &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {cabecalhoTabela.length > 0 &&
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    {filtro.relatorio === "evolucao-alunos-periodo" &&
                      <div className='scroll relatorio'>
                        <table className="lista sempadding borderTable">
                          <thead>
                            <tr className="titulos acompanha">
                              {cabecalhoTabela.map(value => {
                                return (
                                  <th key={value.id}>{value.label}</th>
                                )
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {relatorios.list.map((row, key) => {
                              return (
                                <tr key={key}>
                                  <td>{row.descricao}</td>
                                  <td>
                                    {row.data_0.qtd}
                                    {row.data_0.qtd !== '' &&
                                      <IconButton color="primary" aria-label="view" disabled={row.data_0.contratos.length === 0} onClick={e => this.viewRow(row.data_0.contratos, 'contratos-alunos', `${row.descricao} - ${cabecalhoTabela[1].label}`)}>
                                        <SearchIcon />
                                      </IconButton>
                                    }
                                  </td>
                                  <td>
                                    {row.data_1.qtd}
                                    {row.data_1.qtd !== '' &&
                                      <IconButton color="primary" aria-label="view" disabled={row.data_1.contratos.length === 0} onClick={e => this.viewRow(row.data_1.contratos, 'contratos-alunos', `${row.descricao} - ${cabecalhoTabela[2].label}`)}>
                                        <SearchIcon />
                                      </IconButton>
                                    }
                                  </td>
                                  <td>
                                    {row.data_2.qtd}
                                    {row.data_2.qtd !== '' &&
                                      <IconButton color="primary" aria-label="view" disabled={row.data_2.contratos.length === 0} onClick={e => this.viewRow(row.data_2.contratos, 'contratos-alunos', `${row.descricao} - ${cabecalhoTabela[3].label}`)}>
                                        <SearchIcon />
                                      </IconButton>
                                    }
                                  </td>
                                  <td>
                                    {row.data_3.qtd}
                                    {row.data_3.qtd !== '' &&
                                      <IconButton color="primary" aria-label="view" disabled={row.data_3.contratos.length === 0} onClick={e => this.viewRow(row.data_3.contratos, 'contratos-alunos', `${row.descricao} - ${cabecalhoTabela[4].label}`)}>
                                        <SearchIcon />
                                      </IconButton>
                                    }
                                  </td>
                                  <td>
                                    {row.porcentagem === 'msg' &&
                                      <span>Novos Alunos + Rematrículas + Prorrogações + Ativação + <br /> Transferências de Entrada - Encerramento - Inativações - <br /> Cancelamentos - Transferências de Saída</span>
                                    }
                                    {(row.porcentagem !== '' &&  row.porcentagem !== 'msg') &&
                                      <div className='porcentagem'>
                                        <div style={{width: `${row.porcentagem}%`}}>
                                          {row.porcentagem}%
                                        </div>
                                      </div>
                                    }
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    }
                    {filtro.relatorio === "contratos-periodo" &&
                      <Table
                        urlView={urlView}
                        headCell={cabecalhoTabela}
                        rows={relatorios.list}
                        acoes={acoesTabela}
                        viewRow={(e, acao, status) => this.viewRow(e, acao, status)} 
                        qtdLinhas={filtro.relatorio === "evolucao-alunos-periodo" ? relatorios.list.length : 10}/>
                    }
                    {filtro.relatorio === "contratos-periodo-unidades" &&
                      <React.Fragment>
                        <Table
                          urlView={urlView}
                          headCell={cabecalhoTabela}
                          rows={relatorios.list}
                          acoes={acoesTabela}
                          viewRow={(e, acao, status) => this.viewRow(e, acao, status)} 
                          qtdLinhas={relatorios.list.length}
                        />
                        {total &&
                          <table style={{width: '100%'}}>
                            <thead>
                              <tr style={{backgroundColor: '#2c4e82', color: '#FFF'}}>
                                {cabecalhoTabela.map(value => {
                                  return (
                                    <th style={{
                                      fontWeight: '400', 
                                      fontSize: '0.875rem', 
                                      padding: '5px 10px', 
                                      textAlign: value.numeric ? 'right' : 'left',
                                      border: '1px solid #DCDCDC'
                                    }}>{value.label === 'Unidade' ? '' : value.label}</th>
                                  )
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', border: '1px solid #DCDCDC'}}><span>{total.unidade}</span></th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_matriculas}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_rematriculas}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_prorrogacao}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_ativados}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_cancelados}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_inativados}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_encerrados}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_transf_entrada}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_transf_saida}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.resultado}</th>
                                  <th style={{fontWeight: '400', fontSize: '0.875rem', padding: '5px 10px', textAlign: 'right', border: '1px solid #DCDCDC'}}>{total.qtd_ativos_periodo}</th>
                                </tr>
                            </tbody>
                          </table>
                        }
                      </React.Fragment>
                    }
                  </Grid>
                </Grid>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalRelatorio
            open={this.state.modalRelatorio}
            handleClose={e => this.handleCloseModalRelatorio(e)}
            list={this.state.relatoriosModal.list}
            cabecalhoTabela={this.state.cabecalhoTabelaModal}
            acoesTabela={this.state.acoesTabelaModal}
            imprimir={e => this.imprimirSub(e)}
            loadingDownload={loadingDownload}
            urlView={this.state.urlViewModal}
            linhaSelecionada={this.state.linhaSelecionada}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}