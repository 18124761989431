import React, { Component } from 'react'
import './DemonstrativoDiario.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button } from '@material-ui/core'
import ModalErro from '../../components/modals/Erro'
import axios from 'axios'
import real from '../../services/real'
import moment from 'moment'
import { saveAs } from 'file-saver';

const initalState = {
  movimentacoes: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'ordem', numeric: false, disablePadding: true, label: 'Ordem' },
    { id: 'parcont_datapagamento', numeric: false, disablePadding: true, label: 'Hora' },
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'formpag_descricao', numeric: false, disablePadding: false, label: 'Forma Pgto.' },
    { id: 'valor_credito', numeric: false, disablePadding: false, label: 'Crédito' },
    { id: 'valor_debito', numeric: false, disablePadding: false, label: 'Débito' },
    { id: 'contfin_descricao', numeric: false, disablePadding: false, label: 'Conta' }
  ],
  acoesTabela: [],
  cabecalhoTabelaConciliacao: [
    { id: 'ordem', numeric: false, disablePadding: true, label: 'Ordem' },
    { id: 'movcontfin_data_conciliacao', numeric: false, disablePadding: true, label: 'Hora' },
    { id: 'movcontfin_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'valor_credito', numeric: false, disablePadding: false, label: 'Crédito' },
    { id: 'valor_debito', numeric: false, disablePadding: false, label: 'Débito' },
    { id: 'contfin_descricao', numeric: false, disablePadding: false, label: 'Conta' }
  ],
  acoesTabelaConciliacao: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  dadosGeral: {
    data: '',
    responsavel: ''
  },
  loading: true,
  loadingImprimir: false
}

export default class Cargo extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'demonstrativo-diario')[0]

    this.setState({
      permissoes
    })

    const { match: { params } } = this.props;
    if (params.data && params.pesf_id && params.unin_id) {
      try {
        const filtro = {
          data: params.data,
          pesf_id: params.pesf_id,
          unin_id: params.unin_id 
        }

        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/demonstrativoDiario/detalhado`, filtro, this.getToken())

        let movimentacoes = data.movimentacoes
        let conciliacoes = data.conciliacoes
        let saldoContas = data.saldoContas

        const dadosGeral= {
          data: moment(params.data).format('DD/MM/YYY'),
          responsavel: movimentacoes[0].pesf_nome,
          somaMovimentacoes:{
            soma_credito: 0,
            soma_debito: 0
          },
          somaConciliacoes:{
            soma_credito: 0,
            soma_debito: 0
          }
        }

        for (const i in movimentacoes) {
          dadosGeral.somaMovimentacoes.soma_credito = movimentacoes[i].contpr_tipo === 'RECEBER' ? dadosGeral.somaMovimentacoes.soma_credito + movimentacoes[i].valor_credito : dadosGeral.somaMovimentacoes.soma_credito
          dadosGeral.somaMovimentacoes.soma_debito = movimentacoes[i].contpr_tipo === 'PAGAR' ? dadosGeral.somaMovimentacoes.soma_debito + movimentacoes[i].valor_debito : dadosGeral.somaMovimentacoes.soma_debito

          movimentacoes[i]._id = movimentacoes[i].ordem
          movimentacoes[i].valor_credito = movimentacoes[i].valor_credito > 0 ? 'R$ ' + real(movimentacoes[i].valor_credito) : '-'
          movimentacoes[i].valor_debito = movimentacoes[i].valor_debito > 0 ? 'R$ ' + real(movimentacoes[i].valor_debito) : '-'
        }

        for (const i in conciliacoes) {
          dadosGeral.somaConciliacoes.soma_credito = conciliacoes[i].movcontfin_tipo === 'DEPOSITO' ? dadosGeral.somaConciliacoes.soma_credito + conciliacoes[i].valor_credito : dadosGeral.somaConciliacoes.soma_credito
          dadosGeral.somaConciliacoes.soma_debito = conciliacoes[i].movcontfin_tipo === 'SAQUE' ? dadosGeral.somaConciliacoes.soma_debito + conciliacoes[i].valor_debito : dadosGeral.somaConciliacoes.soma_debito

          conciliacoes[i]._id = conciliacoes[i].ordem
          conciliacoes[i].valor_credito = conciliacoes[i].valor_credito > 0 ? 'R$ ' + real(conciliacoes[i].valor_credito) : '-'
          conciliacoes[i].valor_debito = conciliacoes[i].valor_debito > 0 ? 'R$ ' + real(conciliacoes[i].valor_debito) : '-'
        }

        for (const i in saldoContas) {
          saldoContas[i].saldo_total = saldoContas[i].saldo_conciliado + saldoContas[i].saldo_nao_conciliado
        }

        this.setState({
          movimentacoes: {
            list: movimentacoes
          },
          conciliacoes: {
            list: conciliacoes
          },
          saldoContas:{
            list: saldoContas
          },
          dadosGeral
        })
      
      } catch ({ response }) {
        // console.log(error)
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async imprimir(){
    const { movimentacoes, dadosGeral, conciliacoes } = this.state 

    this.setState({
      loadingImprimir: true
    })


    try {
  
      let filtro = {
        relatorio: 'desmonstrativo-diario-view',
        movimentacoes,
        conciliacoes,
        dadosGeral
      }
      
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingImprimir: false
      })

      saveAs(pdfBlob, 'Demonstrativo-Diario.pdf')


    } catch (error) {
      this.setState({
        loadingImprimir: false
      })
      console.log(error)
    }
    

  }

  render() {
    const { loading, movimentacoes, dadosGeral, conciliacoes, saldoContas } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Movimentações...</p>
              </div>
            </React.Fragment>
          }
          {!loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={8} xs={10} sm={7}>
                  <h1 className="titulo">Demostrativo de Movimentações Financeiras</h1>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                  {this.state.loadingTable &&
                    <div>
                        <CircularProgress />
                    </div>
                  }
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={this.state.loadingImprimir}>
                    Imprimir
                  </Button>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="borderBottom">
                <Grid item md={1} xs={false}></Grid>
                <Grid item md={10} xs={12}>
                  <table className="lista sempadding borderTable">
                    <thead>
                      <tr className="titulos acompanha">
                        <th></th>
                        {saldoContas.list.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                          return (
                            <th key={conta.contfin_id}>{`${conta.contfin_ordem} - ${conta.contfin_descricao}`}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Disponível</td>
                        {saldoContas.list.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                          return (
                            <td className={conta.saldo_conciliado > 0 ? 'positivo' : 'negativo'} key={conta.contfin_id}>{real(conta.saldo_conciliado)}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        <td>A Conciliar</td>
                        {saldoContas.list.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                          return (
                            <td className={conta.saldo_nao_conciliado > 0 ? 'positivo' : 'negativo'} key={conta.contfin_id}>{real(conta.saldo_nao_conciliado)}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        <td>Total</td>
                        {saldoContas.list.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                          return (
                            <td className={conta.saldo_total > 0 ? 'positivo' : 'negativo'} key={conta.contfin_id}>{real(conta.saldo_total)}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid item md={1} xs={false}></Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={6} xs={6}>
                  <h5>Data do Movimento: {dadosGeral.data}</h5>
                </Grid>
                <Grid item md={6} xs={6}>
                  <h5>Responsável: {dadosGeral.responsavel}</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={12} xs={12}>
                  <h5>Movimentações Financeira:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabela} rows={movimentacoes.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={6} xs={false}></Grid>
                <Grid item md={3} xs={6}>
                  <h5>Total Crédito: R$ {real(dadosGeral.somaMovimentacoes.soma_credito)}</h5>
                </Grid>
                <Grid item md={3} xs={6}>
                  <h5>Total Débito: R$ {real(dadosGeral.somaMovimentacoes.soma_debito)}</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={12} xs={12}>
                  <h5> Movimentações/Conciliações Bancárias:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabelaConciliacao} rows={conciliacoes.list} acoes={this.state.acoesTabelaConciliacao} />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={6} xs={false}></Grid>
                <Grid item md={3} xs={6}>
                  <h5>Total Crédito: R$ {real(dadosGeral.somaConciliacoes.soma_credito)}</h5>
                </Grid>
                <Grid item md={3} xs={6}>
                  <h5>Total Débito: R$ {real(dadosGeral.somaConciliacoes.soma_debito)}</h5>
                </Grid>
              </Grid>
            </div>
          }

          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}