import React, { Component } from 'react'
import './../Relatorios.scss'
import './../../../main/ultil.scss'
import { Grid } from '@material-ui/core'
import Chart from "react-google-charts";
import moment from 'moment'

const initalState = {
  porCargo: [],
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
		func_id: "",
		fun_id: ""
  }
}

export default class FlyHigh extends Component {
  state = { ...initalState }

  async componentWillMount() {
    const dados = JSON.parse(localStorage.getItem('grafico_unidade'))
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    this.setState({
      porCargo: dados.porCargo,
      filtro: dados.filtro,
      pessoa
    })
    
    setTimeout(() => {
      window.print()
      this.props.history.push("/fly_high");
      this.setState({ loading: false })
      return;
    }, 500);
 
  }    

  render() {
    const { filtro, pessoa } = this.state
		
		return (
      <div id="app">
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={12} xs={12} sm={12}>
            <p style={{fontSize: '42px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'center'}}><b>Fly High - Funções</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Relatório: {moment(new Date()).format('DD/MM/YYYY HH:mm')}</b></p>
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Gerado por: {pessoa.fisica.pesf_nome.split(' ')[0]}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Inicial: {moment(filtro.data_inicial).format('DD/MM/YYYY')}</b></p>
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Data Final: {moment(filtro.data_final).format('DD/MM/YYYY')}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row">
          <br />
          {this.state.porCargo.map((cargo, key) => {
            return (
              <React.Fragment>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12}>
                    <Chart
                      width={'100%'}
                      height={`${(cargo.colaboradores.length ) * 50}px`}
                      chartType="BarChart"
                      data={cargo.colaboradores}
                      options={{
                        chartArea: { width: '60%', height: '70%'},
                        timeline: { groupByRowLabel: false },
                        title: cargo.func_descricao,
                        isStacked: true,
                      }}
                      // For tests
                      rootProps={{ 'data-testid': '3' }}
                    />
                  </Grid>
                </Grid>
                <hr />
              </React.Fragment>
            )
          })}
        </Grid>
      </div>
    )
  }
}