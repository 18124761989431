import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField, FormControl, FormLabel, FormControlLabel, Switch, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import Table from './../Table'

const formataIntervalo = (value, qtd) => {
  if(value === 'DIA'){
    if(parseInt(qtd) === 1){
      return 'Dia'
    }else{
      return 'Dias'
    }
  }else if(value === 'SEMANA'){
    if(parseInt(qtd) === 1){
      return 'Semana'
    }else{
      return 'Semanas'
    }
  }else if(value === 'MÊS'){
    if(parseInt(qtd) === 1){
      return 'Mês'
    }else{
      return 'Meses'
    }
  }else if(value === 'SEMESTRE'){
    if(parseInt(qtd) === 1){
      return 'Semestre'
    }else{
      return 'Semestres'
    }
  }else if(value === 'ANO'){
    if(parseInt(qtd) === 1){
      return 'Ano'
    }else{
      return 'Anos'
    }
  }
} 

const FormLembrete = ({ dados, updateField, unidades, updateFieldAtivo, unidadesSelecionadas, updateFieldUnidade, intervalo,
  updateFieldIntervalo, update, addIntervalo, cabecalhoTabela, acoesTabela, editarItem, removerItem }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          className="input"
          label="Titulo Lembrete*" 
          variant="outlined" 
          size="small" 
          name="lemb_titulo" 
          value={dados.lemb_titulo}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => updateField(e)}
        />
      </Grid>
      <Grid item md={9} xs={12} sm={6}>
        <Autocomplete
          multiple
          limitTags={5}
          id="multiple-limit-tags"
          options={unidades.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : ((b.unin_numero > a.unin_numero) ? -1 : 0)).filter(unidade => {
            if (unidadesSelecionadas.findIndex(i => i.unin_id === unidade.unin_id) === -1)
              return unidade;
            return false
          })}
          size="small"
          value={unidadesSelecionadas}
          onChange={(event, value) => updateFieldUnidade(event, value)}
          getOptionLabel={(option) => `${option.unin_numero} - ${option.unin_descricao}`}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Unidades*" InputLabelProps={{ shrink: true}} />
          )}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          className="input"
          type="datetime-local"
          label="Data Lembrete*" 
          variant="outlined" 
          size="small" 
          name="lemb_data_lembrete" 
          value={dados.lemb_data_lembrete}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => updateField(e)}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" style={{marginLeft: 10}}>
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="lemb_ativo"
                color="primary"
                checked={dados.lemb_ativo}
              />
            }
            size="small"
            label={dados.lemb_ativo ? 'Sim' : 'Não'}
            name="lemb_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} style={{marginTop: '-15px'}}>
      <Grid item md={12} xs={12} sm={12}>
        <TextField
          id="outlined-multiline-static"
          label="Descrição*"
          className="input"
          multiline
          rows={5}
          size="small"
          variant="outlined"
          name="lemb_descricao"
          value={dados.lemb_descricao}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <span style={{color: 'red', fontSize: 14}}>Obs. Os lembretes serão enviados para o e-mail e telefone (Whats) cadastrados na unidade! E os alertas no "sininho" 20 dias antes do vencimento do lembrete!</span>
      </Grid>
    </Grid>
    <hr style={{marginTop: 10}}/>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <h4>Intervalos do Lembrete:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          className="input"
          label="Tempo*" 
          variant="outlined" 
          size="small" 
          name="intlemb_quantidade" 
          value={intervalo.intlemb_quantidade}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => updateFieldIntervalo(e)}
        />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade de Tempo*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="intlemb_intervalo"
          value={intervalo.intlemb_intervalo}
          onChangeCapture={(e) => updateFieldIntervalo(e)}
          InputLabelProps={{shrink: true}}
        >
          <option value=""> </option>
          <option value="DIA">Dia</option>
          <option value="SEMANA">Semana</option>
          <option value="MÊS">Mês</option>
          <option value="SEMESTRE">Semestre</option>
          <option value="ANO">Ano</option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={update ? <CreateIcon /> : <AddIcon />} onClick={e => addIntervalo(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
      <Grid item md={6} xs={12} sm={12}>
        <Table
          editar={e => editarItem(e)}
          remove={e => removerItem(e)}
          headCell={cabecalhoTabela}
          rows={dados.intervalos.map(value => {
            return {
              intervalo: value.intlemb_quantidade === 0 ? 'No dia.' : `${value.intlemb_quantidade} ${formataIntervalo(value.intlemb_intervalo, value.intlemb_quantidade)} Antes.`,
              index: value.index
            }
          })}
          acoes={acoesTabela}
          noRemove={false}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormLembrete