import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

import ModalErro from './../../../components/modals/Erro'

const initalState = {
  funcoes: {
    list: []
  },
  loading: true,
  comissoes: [],
  modal: false,
  dialog: {
    ativo: false,
    titulo: "",
    descricao: ""
  },
  logado: {
    unidadetrabalho: {
      unin_tipo: ''
    }
  },
  loadList: true,
  funcionarios:{
    list: []
  }
}


export default class Comissao extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      await this.buscarComissoes()
      
      const comissoes = this.state.funcoes.list.map(funcao => {
        return {
          funcionario: this.verificaComissionado(funcao.func_id),
          funcao_id: funcao.func_id,
          func_nivel: funcao.func_nivel,
          cargo_id: funcao.cargo.car_id
        }
      })

      let habilita = true

      for (const i in comissoes) {
        if(comissoes[i].funcionario.contcomis_fun_id !== ''){
          habilita = false
        }
      }
      
      const logado = JSON.parse(localStorage.getItem('usuario'))

      this.setState({
        logado,
        habilita,
        comissoes,
        loading: false
      })
    } catch (error) {
      console.log(error)
      this.openDialog({
        titulo: "Opps!",
        descricao: `${error.response.data.message}, tente mais tarde`
      })
    }
  }
 
  async buscarComissoes () {
    this.setState({ loadList: true })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/comissaoFuncao/funcoes`, dados, this.getToken())
    const { data: comissionados } = await axios.get(`${process.env.REACT_APP_API_URL}/comissao/${this.props.aluno.contrato.contm_id}`, this.getToken())
    
    let funcionarios = []
          
    for (const i in data) {
      for (const j in data[i].contratos) {
        funcionarios.push({
          car_id: data[i].cargo.car_id,
          func_id: data[i].func_id,
          func_nivel: data[i].func_id,
          funcionario: data[i].contratos[j].funcionario
        })
      }
    }

    this.setState({
      comissionados,
      funcoes: {
        list: data
      },
      funcionarios:{
        list: funcionarios
      },
      loadList: false
    })
  }

  verificaComissionado (func_id) {
    for (const comissionado of this.state.comissionados) {
      if (comissionado.comissaofunc.funcao.func_id === func_id) {
        return {
          contcomis_fun_id: comissionado.contcomis_fun_id,
          pesf_nome: comissionado.func.fisica.pesf_nome
        }
      }
    }

    return {
      contcomis_fun_id: '',
      pesf_nome: ''
    }
  }

  updateField(event, func_id) {
    const comissoes = [ ...this.state.comissoes ]

    const index = comissoes.findIndex(param => param.funcao_id === func_id)

    for (let i = 0; i < comissoes.length; i++) {
      if(comissoes[i].func_nivel < comissoes[index].func_nivel){
        if(comissoes[i].funcionario.contcomis_fun_id === ''){
          comissoes[i].funcionario.contcomis_fun_id = event.target.value
        }
      }
    }

    comissoes[index].funcionario.contcomis_fun_id = event.target.value
    this.setState({ comissoes })
  }

  preparaDados(comissoes) {
    const retorno = []
    for (const item of comissoes) {
      if (item.funcionario.contcomis_fun_id) {
        retorno.push({
          cargo_id: item.cargo_id,
          func_nivel: item.func_nivel,
          funcao_id: item.funcao_id,
          contcomis_fun_id: parseInt(item.funcionario.contcomis_fun_id)
        })
      }
    }
    return retorno
  }
  
  async salvar(resposta) {
    this.setState({ salvando: true })
    const comissoes = this.preparaDados(this.state.comissoes)

    const index = comissoes.findIndex(param => param.funcao_id === 5)

    if(comissoes[index].contcomis_fun_id === ''){
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Campo Vazio!",
          descricao: "Por favor informar o colaborador da função Trainee Júnior ."
        }
      })
      return
    }

    try {

      await axios.post(`${process.env.REACT_APP_API_URL}/comissao/${this.props.aluno.contrato.contm_id}`, {comissoes}, this.getToken())
      
      this.openDialog({
        titulo: 'Parabens.',
        descricao: 'Honorários salva com sucesso'
      })

      // this.reload()
      this.setState({ salvando: false })
      window.location.reload()
    } catch (error) {
      this.setState({ salvando: false })
      this.openDialog({
        titulo: 'Opps.',
        descricao: error.response.data.message
      })
    }
  }

  reload(timeout = 1000) {
    const self = this
    setTimeout(() => {
      this.buscarComissoes()
      self.setState({
        modal: false,
        salvando: false
      })
      self.closeDialog()
    }, timeout)
  }

  toogleModal() {
    this.setState({
      modal: !this.state.modal
    })
  }

  calcelarCadastro () {
    this.toogleModal()
    this.closeDialog()
  }
  
  openDialog(error) {
    if (!error) error = {}

    this.setState({
      dialog: {
        ativo: true,
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  closeDialog() {
    this.setState({
      dialog: {
        ativo: false,
        titulo: '',
        descricao: ''
      }
    })
  }

  render() {
    const { funcoes, funcionarios, comissoes } = this.state
        
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Honorários...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading  &&
          <div style={{'marginTop': '15px'}}>
            <Grid container direction="row" spacing={1}>
              <Grid item md={6} xs={12} sm={6}>
                <h3> Honorários </h3>
              </Grid>
            </Grid>

            <div style={{'marginTop': '30px'}}>
              <Grid container direction="row" spacing={1} style={{'marginBottom': '30px'}}>
                <Grid item md={3} xs={4} sm={4}>
                  <h6><b>Função</b></h6>
                </Grid>
                <Grid item md={9} xs={8} sm={8}>
                  <h6><b>Colaborador</b></h6>
                </Grid>
              </Grid>

              {funcoes.list.sort((a, b) => (a.func_nivel > b.func_nivel) ? 1 : ((b.func_nivel > a.func_nivel) ? -1 : 0)).map((funcao, key) => {
                const index = comissoes.findIndex(param => param.funcao_id === funcao.func_id)
                
                return (
                  <Grid container direction="row" spacing={1} style={{'marginTop': '15px'}} key={key}>
                    <Grid item md={3} xs={4} sm={4}>
                      { funcao.func_descricao }
                    </Grid>
                    <Grid item md={9} xs={8} sm={8}>
                      {this.state.habilita &&
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          label="Colaborador*"
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={`contcomis_fun_id`}
                          value={comissoes[index].funcionario.contcomis_fun_id !== '' ? comissoes[index].funcionario.contcomis_fun_id : ''}
                          onChangeCapture={(e) => this.updateField(e, funcao.func_id)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value="">Não Informado</option>
                          {funcionarios.list.filter(param => param.car_id === comissoes[index].cargo_id && param.func_nivel >= funcao.func_nivel).map((contrato, keyC) => {
                            return (
                              <option key={keyC} value={contrato.funcionario.fun_id}>{contrato.funcionario.fisica.pesf_nome}</option>
                            )
                          })}
                        </TextField>
                      }
                      {!this.state.habilita &&
                        <TextField className="input" label="Colaborador" variant="outlined" size="small" name="func_descricao" value={comissoes[index].funcionario.contcomis_fun_id !== '' ? comissoes[index].funcionario.pesf_nome : '-'} disabled/>
                      }
                    </Grid>
                  </Grid>
                )
              })}
            </div>
            {this.state.habilita &&
              <Grid container direction="row" spacing={1} style={{'marginTop': '50px'}}>
                <Grid item md={12} xs={12} sm={12}>
                  <Button
                    style={{float: 'right'}}
                    variant="contained"
                    color="primary"
                    onClick={e => this.salvar(e)}
                    disabled={this.state.salvando}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            }

            <ModalErro open={this.state.dialog.ativo} titulo={this.state.dialog.titulo} descricao={this.state.dialog.descricao} handleClose={e => this.closeDialog(e)} />
          </div>
        }

      </React.Fragment>
    )
  }
}