import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import SyncAltIcon from '@material-ui/icons/SyncAlt';

const FormProfessor = ({ dados, updateField, aulas, aulasSeleciondas, addAula, removerAula }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Formação*" variant="outlined" size="small" name="prof_formacao" value={dados.prof_formacao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <br />
    <hr />
    <Grid container direction="row">
      <Grid item md={12}>
        <h3>Aulas Habilitadas:</h3>
      </Grid>
    </Grid>
    {/* <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={5}>
        <Autocomplete
          onChange={(event, value) => updateFieldBook(event, value)}
          freeSolo
          options={books}
          value={book}
          getOptionLabel={option => option.bok_nome}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Book*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={book.bok_nome} />
          )}
        />
      </Grid>
    </Grid> */}
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={5}>
        <div className="cardSelectAulas">
          <h4>Não Selecionados</h4>
          <div className="cardSelectAulasOverFlow">
            {aulas.filter(param => {
              if (aulasSeleciondas.findIndex(i => i.aula_id === param.aula_id) === -1) {
                return true
              } else {
                return false
              }
            }).sort((a, b) => (a.aula_nome > b.aula_nome) ? 1 : ((b.aula_nome > a.aula_nome) ? -1 : 0)).map(aula => {
              return (
                <p key={aula.aula_id} onClick={() => addAula(aula)}>{aula.aula_nome}</p>
              )
            })}
          </div>
          {/* <h4 className='cursorPointer' onClick={e => addTodasAulas()}>Selecionar Todos</h4> */}
        </div>
      </Grid>
      <Grid item md={1} xs={12} sm={2} className="aling-center">
        <SyncAltIcon className="iconeTroca" />
      </Grid>
      <Grid item md={4} xs={12} sm={5}>
        <div className="cardSelectAulas">
          <h4>Selecionados</h4>
          <div className="cardSelectAulasOverFlow">
            {aulasSeleciondas.sort((a, b) => (a.aula_nome > b.aula_nome) ? 1 : ((b.aula_nome > a.aula_nome) ? -1 : 0)).map(aula => {
              return (
                <p key={aula.aula_id} onClick={() => removerAula(aula)}>{aula.aula_nome}</p>
              )
            })}
          </div>
          {/* <h4 className='cursorPointer' onClick={e => removerTodasAulas()}>Remover Todos</h4> */}
        </div>
      </Grid>
    </Grid>
  </React.Fragment >
)

export default FormProfessor