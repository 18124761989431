import React from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import Table from './../Table'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const cabecalhoTabela = [
  { id: 'curso_nome', numeric: false, disablePadding: true, label: 'Curso' },
  { id: 'fhc_ponto_matricula', numeric: false, disablePadding: true, label: 'Fly High Matricula' },
  { id: 'fhc_ponto_rematricula', numeric: false, disablePadding: false, label: 'Fly High Rematricula' },
  { id: 'fhc_ponto_prorrogacao', numeric: false, disablePadding: false, label: 'Fly High Prorrogação' }
]

const cabecalhoTabelaProduto = [
  { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'fhp_ponto', numeric: false, disablePadding: true, label: 'Fly High' },
]

const acoesTabela = ['editar', 'remove']

const FormProduto = ({ dados, updateField, curso, cursos, updateFieldCurso, update, addItemCurso, editarItemCurso,
  removerItemCurso, produto, produtos, updateFieldProduto, updateProduto, addItemProduto, editarItemProduto, 
  removerItemProduto, onEditorStateChange, editorState }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={4}>
        <TextField className="input" label="Nome*" variant="outlined" size="small" name="pfh_nome" value={dados.pfh_nome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField className="input" type='date' label="Data Inicial*" variant="outlined" size="small" name="pfh_data_inicio" value={dados.pfh_data_inicio} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField className="input" type='date' label="Data Final*" variant="outlined" size="small" name="pfh_data_fim" value={dados.pfh_data_fim} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>

        <div className="cardEditor">
                      
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={e => onEditorStateChange(e)}
          />
        </div>


        {/* <TextField
          id="outlined-multiline-static"
          label="Descrição"
          className="input"
          multiline
          rows={3}
          size="small"
          variant="outlined"
          name="pfh_descricao"
          value={dados.pfh_descricao}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        /> */}
      </Grid>
    </Grid>
    <br />
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <h4>Pontuações dos Cursos:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Curso*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="fhc_curso_id"
          value={curso.fhc_curso_id}
          onChangeCapture={(e) => updateFieldCurso(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={curso.fhc_curso_id !== '' ? curso.fhc_curso_id : ''}> { curso.fhc_curso_id !== '' ? curso.curso_nome : 'Selecionar Curso'} </option>
          {cursos.sort((a, b) => (a.curso_nome > b.curso_nome) ? 1 : ((b.curso_nome > a.curso_nome) ? -1 : 0)).map(curso => {
            return (
              <option key={curso.curso_id} value={curso.curso_id}> {curso.curso_nome} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField type='number' className="input" label="Pontos Fly High Matricula*" variant="outlined" size="small" name="fhc_ponto_matricula" value={curso.fhc_ponto_matricula} onChange={(e) => updateFieldCurso(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField type='number' className="input" label="Pontos Fly High Rematricula*" variant="outlined" size="small" name="fhc_ponto_rematricula" value={curso.fhc_ponto_rematricula} onChange={(e) => updateFieldCurso(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField type='number' className="input" label="Pontos Fly High Prorrogação*" variant="outlined" size="small" name="fhc_ponto_prorrogacao" value={curso.fhc_ponto_prorrogacao} onChange={(e) => updateFieldCurso(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
       <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addItemCurso(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
      <Table
        editar={e => editarItemCurso(e)}
        remove={e => removerItemCurso(e)}
        headCell={cabecalhoTabela}
        rows={dados.cursos.map(curso => {
          return {
            _id: curso.fhc_id,
            curso_nome: curso.curso_nome,
            fhc_ponto_matricula: curso.fhc_ponto_matricula,
            fhc_ponto_rematricula: curso.fhc_ponto_rematricula,
            fhc_ponto_prorrogacao: curso.fhc_ponto_prorrogacao,
            index: curso.index
          }
        })}
        acoes={acoesTabela}
        noRemove={false} />
    </Grid>
    <br />
    <hr />
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <h4>Pontuações dos Books:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Material*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="fhp_prod_id"
          value={produto.fhp_prod_id}
          onChangeCapture={(e) => updateFieldProduto(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value={produto.fhp_prod_id !== '' ? produto.fhp_prod_id : ''}> { produto.fhp_prod_id !== '' ? produto.prod_descricao : 'Selecionar Produto'} </option>
          {produtos.sort((a, b) => (a.prod_descricao > b.prod_descricao) ? 1 : ((b.prod_descricao > a.prod_descricao) ? -1 : 0)).map(value => {
            return (
              <option key={value.prod_id} value={value.prod_id}> {value.prod_descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <TextField type='number' className="input" label="Pontos Fly High*" variant="outlined" size="small" name="fhp_ponto" value={produto.fhp_ponto} onChange={(e) => updateFieldProduto(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={3}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => addItemProduto(e)}>{updateProduto ? 'Aterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
      <Table
        editar={e => editarItemProduto(e)}
        remove={e => removerItemProduto(e)}
        headCell={cabecalhoTabelaProduto}
        rows={dados.produtos.map(produto => {
          return {
            _id: produto.fhc_id,
            fhp_prod_id: produto.fhp_prod_id,
            prod_descricao: produto.prod_descricao,
            fhp_ponto: produto.fhp_ponto,
            index: produto.index
          }
        })}
        acoes={acoesTabela}
        noRemove={false} />
    </Grid>
  </React.Fragment >
)

export default FormProduto