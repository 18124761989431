import React, { Component } from 'react'
import './FlyHigh.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import moment from 'moment'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'

const initalState = {
  fly_highs: {
    list: []
  },
  fly_highsFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'pfh_nome', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'pfh_data_inicio', numeric: false, disablePadding: false, label: 'Data Inicial' },
    { id: 'pfh_data_fim', numeric: false, disablePadding: false, label: 'Data Final' },
    { id: 'pfh_ativo', numeric: true, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  filtro: {
    pfh_nome: "",
    pfh_data_inicio: "",
    pfh_data_fim: ""
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}


export default class FlyHigh extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'pontuacao-fly-high')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/flyHigh/list`, this.state.filtro , this.getToken())

      const fly_highs = []

      data.forEach(fly_high => {
        fly_highs.push({
          _id: fly_high.pfh_id,
          pfh_nome: fly_high.pfh_nome,
          pfh_data_inicio: moment(fly_high.pfh_data_inicio).format('DD/MM/YYYY'),
          pfh_data_fim: moment(fly_high.pfh_data_fim).format('DD/MM/YYYY'),
          pfh_ativo: fly_high.pfh_ativo,
        })
      })

      this.setState({
        fly_highs: {
          list: fly_highs
        },
        fly_highsFiltrado: {
          list: fly_highs
        },
        loading: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  updateFiltro(event) {
    const filtro = this.state.filtro
    filtro[event.target.name] = event.target.value
    this.setState({filtro})
  }

  async filtrar(){
    try {
      
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/flyHigh/list`, this.state.filtro , this.getToken())

      const fly_highs = []

      data.forEach(fly_high => {
        fly_highs.push({
          _id: fly_high.pfh_id,
          pfh_nome: fly_high.pfh_nome,
          pfh_data_inicio: moment(fly_high.pfh_data_inicio).format('DD/MM/YYYY'),
          pfh_data_fim: moment(fly_high.pfh_data_fim).format('DD/MM/YYYY'),
          pfh_ativo: fly_high.pfh_ativo,
        })
      })

      this.setState({
        fly_highs: {
          list: fly_highs
        },
        fly_highsFiltrado: {
          list: fly_highs
        },
        loading: false
      })
    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  render() {
    const { filtro, fly_highsFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Fly High...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} xs={12} sm={6}>
                  <h1 className="titulo">Tabelas Fly High</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={3} xs={12} sm={6}>
                    <Link to="/pontos_fly_high/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Pontuação
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Nome" variant="outlined" size="small" name="pfh_nome" value={filtro.pfh_nome} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField type='date' className="input" label="Data Inicio" variant="outlined" size="small" name="pfh_data_inicio" value={filtro.pfh_data_inicio} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField type='date' className="input" label="Data Fim" variant="outlined" size="small" name="pfh_data_fim" value={filtro.pfh_data_fim} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/pontos_fly_high/cadastro/" headCell={this.state.cabecalhoTabela} rows={fly_highsFiltrado.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}