import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className='mg_top_10'>
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente Trasferir esse código?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade Destino*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unin_id"
                value={props.transferencia.unin_id}
                onChangeCapture={(e) => props.updateUnidadeTransferencia(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value={props.transferencia.unin_id === "" ? "" : props.transferencia.unin_id}> {props.transferencia.unin_id === "" ? "Selecionar Unidade Destino" : `${props.transferencia.unin_numero} - ${props.transferencia.unin_descricao}`} </option>
                {props.unidades.map(unidade => {
                  if (unidade.unin_id !== props.transferencia.unin_id) {
                    return (
                      <option key={unidade.unin_id} value={unidade.unin_id}>{unidade.unin_numero} - {unidade.unin_descricao} </option>
                    )
                  } else {
                    return false
                  }
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className='mg_top_10'>
            <Grid item md={12} xs={12} sm={12}>
              <p className='avisoConciliacao'><i>Esta operação não poderá ser desfeita.</i></p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" disabled={props.transferencia.unin_id === '' ? true : false} startIcon={<CheckIcon />} onClick={e => props.confirmar(true)}>Sim</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} >Não</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
