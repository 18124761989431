import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertMenssager = ({open, autoHideDuration, handleClose, severity, message}) => (
  <div>
		<Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'  }} open={open} onClose={handleClose}>
			<Alert onClose={handleClose} severity={severity}>
				{message}
			</Alert>
		</Snackbar>
	</div>
)

export default AlertMenssager
