import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import CurrencyInput from '../../../components/mask/CurrencyInput'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import ModalErro from '../../../components/modals/Erro'
import Table from './../../../components/Table'
import ModalNovoContrato from './../../../components/modals/NovoContrato'
import ModalContrato from './../../../components/modals/ContratoUnidade'
import ModalCancelarContratoUnidade from './../../../components/modals/CancelarContratoUnidade'
import ModalAdicionarServContratoUnidade from './../../../components/modals/AdicionarServContratoUnidade'
import moment from 'moment'
import real from './../../../services/real'
import Alert from './../../../components/templates/Alert'
import moeda from '../../../services/moeda'

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const initalState = {
  unidade: {},
  loading: true,
  contratos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'data_ini_vig_form', numeric: false, disablePadding: false, label: 'Data Inicio'},
    { id: 'data_fim_vig_form', numeric: false, disablePadding: false, label: 'Data Fim' },
    { id: 'produtos', numeric: false, disablePadding: false, label: 'Serviços' },
    { id: 'situacao_atual', numeric: false, disablePadding: false, label: 'Situação' },
  ],
  acoesTabela: ['viewContrato', 'updateContrato'],
  openModalNovoContrato: false,
  dadosContrato: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    produtos: [],
    formpag_id: '',
    contfin_id: '',
    dias_pagamento: '',
    juros: '',
    observacao: ''
  },
  servico: {
    prod_id: "",
    prod_descricao: "",
    id: "",
    dia_vencimento: "",
    parametro: "",
    valor: "",
    ativo: true,
    habilita_valor_min: false,
    habilita_valor_max: false,
    inativa_unidade: true,
    valor_min: real(0),
    valor_max: real(0),
    index: ""
  },
  update: false,
  produtos: {
    list: []
  },
  disabledButton: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalContrato: false,
  dadosContratoView: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    produtos: [],
    formpag_id: '',
    contfin_id: '',
    dias_pagamento: '',
    juros: '',
    observacao: ''
  },
  financeiros_pendentes: {
    list: []
  },
  openModalCancelarContratoUnidade: false,
  dadosCancelamento: {
    lanc_fin: false,
    motivoCancelamento: ''
  },
  conta: {
    contpr_descricao: "",
    contpr_tipo: "",
    contpr_valortotal: "",
    contpr_numparcela: 1,
    contpr_planocontas_id: "",
    qtd_parcelas: 1,
    parcelas: [
      {
        parcont_baixado: "",
        parcont_datavencimento: "",
        parcont_datavencimento_orig: "",
        parcont_valorparcela: "",
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: "",
        parcela_editada: false,
        contfin_id: '',
        index: 1
      }
    ]
  },
  planosConta: {
    list: []
  },
  openModalAdicionarServContratoUnidade: false,
  contasFinanceira:{
    list: []
  },
  formasPagamento:{
    list: []
  },
  unidades: {
    list: []
  }
}

export default class Contratos extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    const unidade = this.props.unidade

    try {

      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })

      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/1`, this.getToken())
      
      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

      const { data: contratos } = await axios.get(`${process.env.REACT_APP_API_URL}/unidades/listContratosUnidade/${unidade.unin_id}`, this.getToken())

      this.setState({
        contratos: {
          list: contratos.map(contrato => {

            let produtos = ''

            for (let i = 0; i < contrato.servicos.length; i++) {
              if((i + 1) === contrato.servicos.length){
                produtos += `${contrato.servicos[i].produto.prod_descricao}.`
              }else{
                produtos += `${contrato.servicos[i].produto.prod_descricao}, `
              }
              
            }

            return {
              ...contrato,
              data_ini_vig_form: moment(contrato.contunin_data_ini_vig).format('DD/MM/YYYY'),
              data_fim_vig_form: moment(contrato.contunin_data_fim_vig).format('DD/MM/YYYY'),
              situacao_atual: contrato.situacao_atual.hiscontunid_situacao,
              produtos
            }
          })
        }
      })

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return [acesso.unin_id]
        })
      }

      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())

      this.setState({
        unidades:{
          list: unidades.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : ((b.unin_numero > a.unin_numero) ? -1 : 0)).filter(param => (param.unin_tipo === 'UNIDADE')).map(unidade => {
            return {
              id: unidade.unin_id,
              numero: unidade.unin_numero,
              descricao: unidade.unin_descricao,
              status: unidade.unin_status
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: error.response.data.message
      })
      return
    }

    this.setState({
      unin_tipo,
      unidade: unidade,
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  backPage(timeout = 1000) {
    window.location.reload()
    // const self = this
    // setTimeout(() => {
    //   self.props.history.push("/unidade");
    // }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  voltar() {
    this.props.history.push("/unidade");
    return;
  }

  async novoContrato(){
    let dadosContrato ={
      codigo: '',
      data_ini_vig: '',
      data_fim_vig: '',
      unidade_tempo: '',
      tempo_contrato: '',
      unidade_id: '',
      produtos: [],
      formpag_id: '',
      contfin_id: '',
      dias_pagamento: '',
      juros: '',
      observacao: ''
    }

    dadosContrato.unidade_id = this.props.unidade.unin_id

    await this.consultaProdutos()

    this.setState({
      dadosContrato,
      openModalNovoContrato: true,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        dia_vencimento: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        inativa_unidade: true,
        valor_min: real(0),
        valor_max: real(0),
        index: ""
      }
    })
  }

  handleCloseModalNovoContrato(){
    this.setState({
      openModalNovoContrato: false,
      disabledButton: false
    })
  }

  verificaUnidadeTempo(value){
    if(value === 'DIA'){
      return 'day'
    }else if (value === 'MÊS'){
      return 'month'
    }else if (value === 'ANO'){
      return 'year'
    }
  }

  updateFieldContrato(event){
    const { dadosContrato } = this.state

    const name = event.target.name
    const value = event.target.value

    dadosContrato[name] = value

    if((name === 'data_ini_vig' || name === 'unidade_tempo' || name === 'tempo_contrato') && dadosContrato.data_ini_vig !== '' && dadosContrato.unidade_tempo !== '' && dadosContrato.tempo_contrato !== ''){
      dadosContrato.data_fim_vig = moment(dadosContrato.data_ini_vig).add(dadosContrato.tempo_contrato, this.verificaUnidadeTempo(dadosContrato.unidade_tempo)).subtract(1, 'day').format('YYYY-MM-DD')   
    }

    this.setState({ dadosContrato })
  }

  async consultaProdutos(){
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/produtos`, this.getToken())

      const produtos = []

      resp.data.forEach(produto => {
        if(produto.prod_ativo && produto.prod_tipo === 'SERVICO'){
          produtos.push({
            _id: produto.prod_id,
            prod_descricao: produto.prod_descricao,
            prod_ativo: produto.prod_ativo,
            index: ""
          })
        }
      })

      this.setState({
        produtos: {
          list: produtos
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  updateFieldServico(event){
    const {servico, produtos} = this.state

    let value = event.target.value
    let name = event.target.name

    if(name === "prod_id"){
      servico.prod_descricao = produtos.list.filter(param => param._id === parseInt(value))[0].prod_descricao
    }

    servico[name] = value
    this.setState({ servico })
  }

  updateFieldAtivo(){
    const configuracao = { ...this.state.configuracao }
    configuracao.configcobran_ativo = !configuracao.configcobran_ativo
    this.setState({ configuracao })
  }

  updateFieldAtivoServico(){
    const servico = { ...this.state.servico }
    servico.ativo = !servico.ativo
    this.setState({ servico })
  }

  updateFieldMinServico(){
    const servico = { ...this.state.servico }
    servico.habilita_valor_min = !servico.habilita_valor_min
    this.setState({ servico })
  }

  updateFieldMaxServico(){
    const servico = { ...this.state.servico }
    servico.habilita_valor_max = !servico.habilita_valor_max
    this.setState({ servico })
  }

  updateFieldInativaUnidadeServico(){
    const servico = { ...this.state.servico }
    servico.inativa_unidade = !servico.inativa_unidade
    this.setState({ servico })
  }

  validateServico(){
    const {servico} = this.state

    if(servico.prod_id === '') return false
    if(servico.dia_vencimento === '') return false
    if(servico.parametro === '') return false
    if(servico.valor === '') return false

    if(servico.habilita_valor_min){
      if(servico.valor_min === '') return false
    }

    if(servico.habilita_valor_max){
      if(servico.valor_max === '') return false
    }

    return true
  }

  addItem(){
    const {servico, dadosContrato} = this.state

    if (!this.validateServico()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    if(parseFloat(servico.valor_min.replace(".", "").replace(",", ".")) > parseFloat(servico.valor_max.replace(".", "").replace(",", "."))){
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "O valor mínimo não pode ser superior ao valor máximo!"
      })
      return
    }

    if(servico.index === ""){
      dadosContrato.produtos.push({
        ...servico,
        index: dadosContrato.produtos.length
      })
    }else{
      dadosContrato.produtos[servico.index] = servico
    }

    this.setState({
      dadosContrato,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        dia_vencimento: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        valor_min: real(0),
        valor_max: real(0),
        inativa_unidade: true,
        index: ""
      },
      update: false
    })
  }

  editarItem(index) {
    const {dadosContrato} = this.state

    this.setState({
      update: true,
      servico: dadosContrato.produtos[index]
    })
  }

  removerItem(index) {
    const {dadosContrato} = this.state
    
    let itens = dadosContrato.produtos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      console.log(itens[i])

      aux.push({
        prod_id: itens[i].prod_id,
        prod_descricao: itens[i].prod_descricao,
        id: itens[i].id,
        dia_vencimento: itens[i].dia_vencimento,
        parametro: itens[i].parametro,
        valor: itens[i].valor,
        ativo: itens[i].ativo,
        habilita_valor_min: itens[i].habilita_valor_min,
        habilita_valor_max: itens[i].habilita_valor_max,
        inativa_unidade: itens[i].inativa_unidade,
        valor_min: itens[i].valor_min,
        valor_max: itens[i].valor_max,
        index: i
      })
    }

    itens = aux

    dadosContrato.produtos = itens

    this.setState({
      dadosContrato
    })
  }
  
  validateForm(){
    let {dadosContrato} = this.state

    if(dadosContrato.contfin_id === "") return 
    if(dadosContrato.data_fim_vig === "") return false
    if(dadosContrato.data_ini_vig === "") return false
    if(dadosContrato.formpag_id === "") return false
    if(dadosContrato.tempo_contrato === "") return false
    if(dadosContrato.unidade_tempo === "") return false
    if(dadosContrato.unidade_id === "") return false
    if(dadosContrato.dias_pagamento === "") return false
    if(dadosContrato.juros === "") return false
    
    return true
  }

  async confirmarNovoContrato(value){

    if(value){
      let {dadosContrato} = this.state
  
      if (!this.validateForm()) {
        this.handleOpenDialog({
          titulo: "Erro ao Adicionar!",
          descricao: "Preencha os campos obrigatorios (*)."
        })
        return
      }
  
      if(dadosContrato.produtos.length === 0){
        this.handleOpenDialog({
          titulo: "Erro ao Adicionar!",
          descricao: "Por favor informar ao menos um serviço."
        })
        return
      }
  
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Cadastrando Contrato...'
        }
      })

      try {
        let dados = {
          codigo: dadosContrato.codigo,
          contfin_id: parseInt(dadosContrato.contfin_id),
          data_fim_vig: dadosContrato.data_fim_vig,
          data_ini_vig: dadosContrato.data_ini_vig,
          formpag_id: parseInt(dadosContrato.formpag_id),
          tempo_contrato: parseInt(dadosContrato.tempo_contrato),
          unidade_id:  parseInt(dadosContrato.unidade_id),
          unidade_tempo: dadosContrato.unidade_tempo,
          dias_pagamento: parseInt(dadosContrato.dias_pagamento),
          juros: parseInt(dadosContrato.juros),
          observacao: dadosContrato.observacao,
          servicos: dadosContrato.produtos.map(servico => {
            return {
              id: servico.id,
              prod_id: parseInt(servico.prod_id),
              dia_vencimento: parseInt(servico.dia_vencimento),
              parametro: servico.parametro,
              ativo: servico.ativo,
              valor: parseFloat(servico.valor.replace(".", "").replace(",", ".")),
              habilita_valor_min: servico.habilita_valor_min,
              habilita_valor_max: servico.habilita_valor_max,
              inativa_unidade: servico.inativa_unidade,
              valor_min: parseFloat(servico.valor_min.replace(".", "").replace(",", ".")),
              valor_max: parseFloat(servico.valor_max.replace(".", "").replace(",", ".")),
            }
          })
        }
  
        this.setState({
          disabledButton: true
        })
  
        await axios.post(`${process.env.REACT_APP_API_URL}/unidades/novoContrato`, dados, this.getToken())
        
        this.setState({
          alerta: {
            open: true,
            severity: 'success',
            message: 'Contrato cadastrado com sucesso'
          }
        })
  
        this.UNSAFE_componentWillMount()
        this.handleCloseModalNovoContrato()
        this.handleCloseModalContrato()
    
        return 
  
      } catch ({response}) {
        // console.log(error)
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        this.setState({
          disabledButton: false
        })
        return
      }
    }else{
      this.handleCloseModalNovoContrato()
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async viewContrato(value){
    this.setState({
      dadosContratoView: {
        id: value.contunin_id,
        codigo: value.contunin_codigo,
        data_ini_vig_unform: value.contunin_data_ini_vig,
        data_fim_vig_unform: value.contunin_data_fim_vig,
        data_ini_vig: moment(value.contunin_data_ini_vig).format('DD/MM/YYYY'),
        data_fim_vig: moment(value.contunin_data_fim_vig).format('DD/MM/YYYY'),
        unidade_tempo: value.contunin_unidade_tempo,
        tempo_contrato: value.contunin_tempo_contrato,
        unidade_id: value.contunin_unin_id,
        dias_pagamento: value.contunin_dias_pagamento,
        juros: value.contunin_juros,
        observacao: '',
        situacao: value.situacao_atual,
        forma_pagto: value.forma_pagamento.formpag_descricao,
        conta_fin: value.conta_financeira.contfin_descricao,
        formpag_id: value.forma_pagamento.formpag_id,
        contfin_id: value.conta_financeira.contfin_id,
        servicos: value.servicos,
        produtos: value.servicos.map(servico => {
          return {
            prod_descricao: servico.produto.prod_descricao,
            id: servico.cup_id,
            dia_vencimento: servico.cup_dia_vencimento,
            parametro: servico.cup_parametro,
            valor: real(servico.cup_valor),
            ativo: servico.cup_ativo,
            habilita_valor_min: servico.cup_habilita_valor_min,
            habilita_valor_max: servico.cup_habilita_valor_max,
            inativa_unidade: servico.cup_inativa_unidade,
            valor_min: real(servico.cup_valor_min),
            valor_max: real(servico.cup_valor_max),
            pendencias: servico.pendencias
          }
        }),
      },
      openModalContrato: true
    })
  }

  handleCloseModalContrato(){
    this.setState({
      openModalContrato: false
    })
  }

  async consultaPlanoContas(){
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      this.setState({
        planosConta: {
          list: data.filter(param => param.plancontas_ativo === true)
          .sort((a, b) => (parseInt(a.plancontas_codigo) > parseInt(b.plancontas_codigo)) ? 1 : ((parseInt(b.plancontas_codigo) > parseInt(a.plancontas_codigo)) ? -1 : 0))
          .map(value => {
            return {
              plancontas_id: value.plancontas_id,
              plancontas_codigo: value.plancontas_codigo,
              plancontas_descricao: value.plancontas_descricao,
              plancontas_ativo: value.plancontas_ativo,
              plancontas_slug: value.plancontas_slug,
              parametros: value.parametros
            }
          })
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async cancelarContrato(){
    let {dadosContratoView} = this.state

    let financeiros_pendentes = []

    for (const produto of dadosContratoView.produtos) {
      for (const pendencia of produto.pendencias) {
        if(pendencia.parcela_receita.parcont_situacao === "A VENCER"){
          financeiros_pendentes.push({
            ...pendencia.parcela_receita
          })
        }
      }
    }

    await this.consultaPlanoContas()

    this.setState({
      financeiros_pendentes: {
        list: financeiros_pendentes
      },
      conta: {
        contpr_descricao: "Cancelamento de Contrato",
        contpr_tipo: "",
        contpr_valortotal: "",
        contpr_numparcela: 1,
        contpr_planocontas_id: "",
        plancontas_id: "",
        qtd_parcelas: 1,
        parcelas: [
          {
            parcont_baixado: "",
            parcont_datavencimento: "",
            parcont_datavencimento_orig: "",
            parcont_valorparcela: "",
            parcont_formapagto_id: "",
            parcont_documento: "",
            parcont_num: "",
            parcela_editada: false,
            contfin_id: '',
            index: 1
          }
        ]
      },
      openModalCancelarContratoUnidade: true
    })
  }

  handleCloseModalCancelarContratoUnidade(){
    this.setState({
      openModalCancelarContratoUnidade: false
    })
  }

  updateFieldLancFinanceiroCancelamento(){
    let {dadosCancelamento} = this.state
    dadosCancelamento.lanc_fin = !dadosCancelamento.lanc_fin
    this.setState({dadosCancelamento})
  }

  updateFieldConta(event) {
    const conta = { ...this.state.conta }

    if (event.target.name === 'contpr_valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replace(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].parcont_valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = this.ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value

    this.setState({ conta })
  }

  updateFieldParcela(event, index) {
    const conta = { ...this.state.conta }

    let value = event.target.value
    let name = event.target.name

    if(name === 'parcont_datavencimento'){
      conta.parcelas[index - 1][name] = value
    }else{
      conta.parcelas[index - 1][name] = value
    }

    if(name === 'contfin_id'){
      const conta_selecionada = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(value))[0]

      if(conta_selecionada.conta_corrente){
        if(conta_selecionada.conta_corrente.parceiro){
          this.setState({
            disabledSalvarBaixar: true
          })
        }else{
          this.setState({
            disabledSalvarBaixar: false
          })
        }
      }else{
        this.setState({
          disabledSalvarBaixar: false
        })
      }
    }
    
    this.setState({ conta })
  }

  mudarParcelas(event) {
    const conta = { ...this.state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date();
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i));

      array.push({
        parcont_baixado: "",
        parcont_datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        parcont_valorparcela: real(valorDividido),
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = this.ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.contpr_numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array
    this.setState({ conta })
  }

  ajustarArray(qtdParcela, array, valorDividido, sobra) {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].parcont_valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  recalcularParcela(event, index) {
    const conta = { ...this.state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.parcont_valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))

      let qtdParcelasEditado = this.state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        this.setState({
          qtdParcelasEditado
        })
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)


      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].parcont_valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = this.ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      this.setState({ conta })
    }
  }

  updateFieldCancelamento(event){
    let {dadosCancelamento} = this.state
    dadosCancelamento[event.target.name] = event.target.value
    this.setState({dadosCancelamento})
  }

  async confirmarCancelamento(value){
    if(value){
      let {dadosCancelamento, conta, dadosContratoView, financeiros_pendentes} = this.state

      if(dadosCancelamento.lanc_fin){
        if(conta.contpr_planocontas_id === ''){
          this.handleOpenDialog({
            titulo: "Campo Obrigatorio!",
            descricao: "Por favor informar o Plano de Contas."
          })
          return
        }
  
        if(conta.contpr_valortotal === ''){
          this.handleOpenDialog({
            titulo: "Campo Obrigatorio!",
            descricao: "Por favor informar o Valor Total."
          })
          return
        }
  
        if(conta.contpr_descricao === ''){
          this.handleOpenDialog({
            titulo: "Campo Obrigatorio!",
            descricao: "Por favor informar a descrição da conta."
          })
          return
        }
      }

      if(dadosCancelamento.motivoCancelamento === ''){
        this.handleOpenDialog({
          titulo: "Campo Obrigatorio!",
          descricao: "Por favor informar o motivo do cancelamento."
        })
        return
      }

      try {
        const dados = {
          motivoCancelamento: dadosCancelamento.motivoCancelamento,
          contrato_id: dadosContratoView.id,
          conta,
          financeiros_pendentes: financeiros_pendentes.list.map(value => {
            return {
              id: value.parcont_id
            }
          })
        }

        if(dados.financeiros_pendentes.length === 0){
          delete dados.financeiros_pendentes
        }

        if(!dadosCancelamento.lanc_fin){
          delete dados.conta
        }else{
          dados.conta.contpr_tipo = 'RECEBER'
          dados.conta.contpr_unin_id = 1
          dados.conta.contpr_valortotal = parseFloat(dados.conta.contpr_valortotal.replace(".", "").replace(",", "."))
    
          for (let i = 0; i < dados.conta.parcelas.length; i++) {
            dados.conta.parcelas[i].parcont_valorparcela = parseFloat(dados.conta.parcelas[i].parcont_valorparcela.replace(".", "").replace(",", "."))
            dados.conta.parcelas[i].parcont_baixado = false
            dados.conta.parcelas[i].parcont_num = dados.conta.parcelas[i].index
            dados.conta.parcelas[i].formas_pgto = [
              {
                valpag_formapagto_id: parseInt(dados.conta.parcelas[i].parcont_formapagto_id)
              }
            ]
      
            if(
              moment(dados.conta.parcelas[i].parcont_datavencimento).isBefore(new Date()) 
              && (dados.conta.parcelas[i].formas_pgto.valpag_formapagto_id === 4 || dados.conta.parcelas[i].formas_pgto.valpag_formapagto_id === 5)){
               
                this.handleOpenDialog({
                  titulo: "Alerta Cadastro!",
                  descricao: "A data do ${dados.conta.parcelas[i].formas_pgto.valpag_formapagto_id === 4 ? 'Pix' : 'Boleto'} não pode ser inferior ao dia de amanhã"
                })
                return
            }
    
            delete dados.conta.parcelas[i].valpag_formapagto_id
          }
        }

        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: `Cancelando Contrato...`
          }
        })
          
        await axios.post(`${process.env.REACT_APP_API_URL}/unidades/cancelarContrato/${dadosContratoView.id}`, dados, this.getToken())   
  
        this.setState({
          alerta: {
            open: true,
            severity: 'success',
            message: `Contrato Cancelado.`
          }
        })

        this.UNSAFE_componentWillMount()
        this.handleCloseModalCancelarContratoUnidade()
        this.handleCloseModalContrato()

        return
        
      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: error.response.data.message
        })
        return
      }

    }else{
      this.handleCloseModalCancelarContratoUnidade()
    }
  }

  async addServico(){
    await this.consultaProdutos()

    this.setState({
      openModalAdicionarServContratoUnidade: true
    })
  }

  handleCloseModalAdicionarServContratoUnidade(){
    this.setState({
      openModalAdicionarServContratoUnidade: false
    })
  }

  async confirmarNovoServico(value){
    if(value){
      if (!this.validateServico()) {
        this.handleOpenDialog({
          titulo: "Erro ao Adicionar!",
          descricao: "Preencha os campos obrigatorios (*)."
        })
        return
      }

      try {
        let {servico, dadosContratoView} = this.state

        let dados = {
          prod_id: parseInt(servico.prod_id),
          dia_vencimento: parseInt(servico.dia_vencimento),
          parametro: servico.parametro,
          ativo: servico.ativo,
          valor: parseFloat(servico.valor.replace(".", "").replace(",", ".")),
          habilita_valor_min: servico.habilita_valor_min,
          habilita_valor_max: servico.habilita_valor_max,
          inativa_unidade: servico.inativa_unidade,
          valor_min: parseFloat(servico.valor_min.replace(".", "").replace(",", ".")),
          valor_max: parseFloat(servico.valor_max.replace(".", "").replace(",", ".")),
        }

        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: `Adicionando Serviço...`
          }
        })
        
        await axios.post(`${process.env.REACT_APP_API_URL}/unidades/adicionarServico/${dadosContratoView.id}`, dados, this.getToken())   

        this.setState({
          alerta: {
            open: true,
            severity: 'success',
            message: `Serviço Adicionado.`
          }
        })

        this.UNSAFE_componentWillMount()
        this.handleCloseModalAdicionarServContratoUnidade()
        this.handleCloseModalContrato()
        
        return

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: error.response.data.message
        })
        return
      }

    }else{
      this.handleCloseModalAdicionarServContratoUnidade()
    }
  }

  async renovarContrato(){
    let {dadosContratoView} = this.state

    await this.consultaProdutos()

    this.setState({
      dadosContrato: {
        codigo: "",
        data_ini_vig: "",
        data_fim_vig: "",
        unidade_tempo: dadosContratoView.unidade_tempo,
        tempo_contrato: dadosContratoView.tempo_contrato,
        unidade_id: dadosContratoView.unidade_id,
        formpag_id: dadosContratoView.formpag_id,
        contfin_id: dadosContratoView.contfin_id,
        dias_pagamento: dadosContratoView.dias_pagamento,
        juros: dadosContratoView.juros,
        observacao: dadosContratoView.observacao,
        produtos: dadosContratoView.servicos.map((servico, key) => {
          return {
            prod_id: servico.produto.prod_id,
            prod_descricao: servico.produto.prod_descricao,
            dia_vencimento: servico.cup_dia_vencimento,
            parametro: servico.cup_parametro,
            valor: real(servico.cup_valor),
            ativo: true,
            habilita_valor_min: servico.cup_habilita_valor_min,
            habilita_valor_max: servico.cup_habilita_valor_max,
            inativa_unidade: servico.cup_inativa_unidade,
            valor_min: real(servico.cup_valor_min),
            valor_max: real(servico.cup_valor_max),
            index: key
          }
        }),
      },
      openModalNovoContrato: true,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        dia_vencimento: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        inativa_unidade: true,
        valor_min: real(0),
        valor_max: real(0),
        index: ""
      }
    })
  }

  render() {
    const { servico, update, produtos, contasFinanceira, formasPagamento, dadosContratoView, financeiros_pendentes, 
      dadosCancelamento, conta, planosConta, disabledButton, unidades} = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Contratos...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
              <Grid item md={6} xs={12} sm={12}>
                <h2 className="titulo">Contratos da Unidade: </h2>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table 
                  headCell={this.state.cabecalhoTabela} 
                  rows={this.state.contratos.list} 
                  acoes={this.state.acoesTabela} 
                  viewContrato={e => this.viewContrato(e)}
                />
              </Grid>
            </Grid>
            {this.props.permissoes_contrato.perm_inserir &&
              <Grid container spacing={1} direction="row">
                <Grid item md={9} sm={12} xs={9}></Grid>
                <Grid item md={3} sm={12} xs={3}>
                  <Button
                    size='small'
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => this.novoContrato()}
                  >
                    Adicionar Contrato
                  </Button>
                </Grid>
              </Grid>
            }
          </div>
        }
        <ModalNovoContrato 
          open={this.state.openModalNovoContrato}
          handleClose={e => this.handleCloseModalNovoContrato()}
          dados={this.state.dadosContrato}
          updateField={e => this.updateFieldContrato(e)}
          servico={servico}
          update={update}
          produtos={produtos.list}
          updateFieldServico={(e) => this.updateFieldServico(e)}
          updateFieldAtivoServico={() => this.updateFieldAtivoServico()}
          updateFieldMinServico={() => this.updateFieldMinServico()}
          updateFieldMaxServico={() => this.updateFieldMaxServico()}
          updateFieldInativaUnidadeServico={() => this.updateFieldInativaUnidadeServico()}
          contasFinanceira={contasFinanceira.list}
          formasPagamento={formasPagamento.list}
          addItem={() => this.addItem()}
          removerItem={(e) => this.removerItem(e)}
          disabledButton={this.state.disabledButton}
          confirmar={e => this.confirmarNovoContrato(e)}
          disabledUnidade={true}
          unidades={unidades.list}
        />

        <ModalContrato 
          open={this.state.openModalContrato}
          handleClose={e => this.handleCloseModalContrato()}
          dados={dadosContratoView}
          cancelarContrato={() => this.cancelarContrato()}
          addServico={() => this.addServico()}
          renovarContrato={() => this.renovarContrato()}
        />

        <ModalCancelarContratoUnidade 
          open={this.state.openModalCancelarContratoUnidade}
          handleClose={e => this.handleCloseModalCancelarContratoUnidade()}
          financeiros_pendentes={financeiros_pendentes.list}
          dados={dadosCancelamento}
          updateFieldLancFinanceiroCancelamento={() => this.updateFieldLancFinanceiroCancelamento()}
          conta={conta}
          contasFinanceira={contasFinanceira.list}
          formasPagamento={formasPagamento.list}
          updateFieldConta={e => this.updateFieldConta(e)}
          updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
          mudarParcelas={e => this.mudarParcelas(e)}
          recalcularParcela={(e, value) => this.recalcularParcela(e, value)}
          planosConta={planosConta.list}
          updateField={(e) => this.updateFieldCancelamento(e)}
          confirmar={(e) => this.confirmarCancelamento(e)}
        />

        <ModalAdicionarServContratoUnidade 
          open={this.state.openModalAdicionarServContratoUnidade }
          handleClose={e => this.handleCloseModalAdicionarServContratoUnidade()}
          dados={servico}
          produtos={produtos.list}
          disabledButton={disabledButton}
          confirmar={(e) => this.confirmarNovoServico(e)}
          updateFieldServico={(e) => this.updateFieldServico(e)}
          updateFieldAtivoServico={() => this.updateFieldAtivoServico()}
          updateFieldMinServico={() => this.updateFieldMinServico()}
          updateFieldMaxServico={() => this.updateFieldMaxServico()}
          updateFieldInativaUnidadeServico={() => this.updateFieldInativaUnidadeServico()}
        />

        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}