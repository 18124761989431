
import React from 'react'
import { Grid } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const renderCheckbox = (disponibilidades, hora, diaSemana, updateFieldDisponibilidade) => {

  if (disponibilidades.findIndex(disp => disp.disp_hora_inicial === hora && disp.disp_dia_semana === diaSemana) !== -1) {
    return (
      <Checkbox
        checked={true}
        onChange={() => updateFieldDisponibilidade(hora, diaSemana)}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    )
  } else {
    return (
      <Checkbox
        checked={false}
        onChange={() => updateFieldDisponibilidade(hora, diaSemana)}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    )
  }

}

const renderLinhas = (updateFieldDisponibilidade, disponibilidades) => {
  const rows = []
  for (let i = 8; i < 22; i++) {
    const hora = i < 10 ? `0${i}:00` : `${i}:00`

    rows.push(<tr>
      <td>
        <p>{hora}</p>
      </td>
      <td>
        {renderCheckbox(disponibilidades, hora, 'DOMINGO', (hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana))}
      </td>
      <td>
        {renderCheckbox(disponibilidades, hora, 'SEGUNDA', (hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana))}
      </td>
      <td>
        {renderCheckbox(disponibilidades, hora, 'TERÇA', (hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana))}
      </td>
      <td>
        {renderCheckbox(disponibilidades, hora, 'QUARTA', (hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana))}
      </td>
      <td>
        {renderCheckbox(disponibilidades, hora, 'QUINTA', (hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana))}
      </td>
      <td>
        {renderCheckbox(disponibilidades, hora, 'SEXTA', (hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana))}
      </td>
      <td>
        {renderCheckbox(disponibilidades, hora, 'SÁBADO', (hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana))}
      </td>
    </tr>)
  }

  return rows
}

const FormProfessorDispHorario = ({ dados, updateField, disponibilidades, updateFieldDisponibilidade, updateAllDia }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12}>
        <h3>Disponibilidade de Horário</h3>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <table className="lista sempadding" id="professorDisp">
        <thead>
          <tr className="titulos acompanha">
            <th>
              <p>Horas</p>
            </th>
            <th>
              <Tooltip title='Todos' aria-label="add">
                <p onClick={() => updateAllDia('DOMINGO')} className="cursorpointer">Domingo</p>
              </Tooltip>
            </th>
            <th>
              <Tooltip title='Todos' aria-label="add">
                <p onClick={() => updateAllDia('SEGUNDA')} className="cursorpointer">Segunda</p>
              </Tooltip>
            </th>
            <th>
              <Tooltip title='Todos' aria-label="add">
                <p onClick={() => updateAllDia('TERÇA')} className="cursorpointer">Terça</p>
              </Tooltip>
            </th>
            <th>
              <Tooltip title='Todos' aria-label="add">
                <p onClick={() => updateAllDia('QUARTA')} className="cursorpointer">Quarta</p>
              </Tooltip>
            </th>
            <th>
              <Tooltip title='Todos' aria-label="add">
                <p onClick={() => updateAllDia('QUINTA')} className="cursorpointer">Quinta</p>
              </Tooltip>
            </th>
            <th>
              <Tooltip title='Todos' aria-label="add">
                <p onClick={() => updateAllDia('SEXTA')} className="cursorpointer">Sexta</p>
              </Tooltip>
            </th>
            <th>
              <Tooltip title='Todos' aria-label="add">
                <p onClick={() => updateAllDia('SÁBADO')} className="cursorpointer">Sábado</p>
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderLinhas((hora, diaSemana) => updateFieldDisponibilidade(hora, diaSemana), disponibilidades)}
        </tbody>
      </table>
    </Grid>
  </React.Fragment >
)

export default FormProfessorDispHorario