import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';

const FormBook = ({ dados, updateField, banco, bancos, updateFieldBanco, removerConta }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldBanco(event, value)}
          freeSolo
          options={bancos}
          value={banco}
          getOptionLabel={option => `${option.banco_numero} - ${option.banco_nome}`}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Banco*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${banco.banco_numero} - ${banco.banco_nome}`} />
          )}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Núm. Agência*" variant="outlined" size="small" name="cb_agencia" value={dados.cb_agencia} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Núm. Conta*" variant="outlined" size="small" name="cb_numero" value={dados.cb_numero} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Gerente" variant="outlined" size="small" name="cb_gerente" value={dados.cb_gerente} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Chave Pix" variant="outlined" size="small" name="cb_chave_pix" value={dados.cb_chave_pix} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <Button fullWidth onClick={() => removerConta(dados.index)} color="secondary" variant="contained" size="small" startIcon={<DeleteIcon />}>Remover Conta</Button>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormBook