import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from './../../../components/templates/Alert'
import filesize from 'filesize'
import UploadMidias from './../../../services/uploads/UploadMidias'
import axios from 'axios'
import Table from './../../../components/Table'

const initalState = {
  documento: {
    descricao: ''
  },
  documentos: {
    list: []
  },
  loading: true,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  uploadedFiles: [],
  cabecalhoTabela: [
    { id: 'doc_descricao', numeric: false, disablePadding: false, label: 'Descrição'},
    { id: 'mid_nome', numeric: false, disablePadding: false, label: 'Arquivo'},
    { id: 'mid_formato', numeric: false, disablePadding: false, label: 'Formato'}
  ],
  acoesTabela: [],
}

function getFileExtension(url) {
  // Usa expressão regular para capturar a extensão do arquivo
  const extension = url.split('.').pop().split(/\#|\?/)[0];
  return extension;
}

export default class Contratos extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
 
    const unidade = this.props.unidade

    let acoesTabela = []

    if(this.props.permissoes_documento.perm_visualizar){
      acoesTabela.push('downloadFile')
    }

    if(this.props.permissoes_documento.perm_deletar){
      acoesTabela.push('removeFile')
    }

    this.setState({
      documentos: {
        list: unidade.pessoa.documentos.sort((a, b) => (a.doc_descricao > b.doc_descricao) ? 1 : ((b.doc_descricao > a.doc_descricao) ? -1 : 0)).map(value => {
          return {
            _id: value.doc_id,
            doc_descricao: value.doc_descricao,
            mid_caminho: value.midia.mid_caminho,
            mid_formato: getFileExtension(value.midia.mid_caminho),
            mid_nome: value.midia.mid_nome
          }
        })
      },
      acoesTabela,
      loading: false
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateField(event){
    let{documento} = this.state

    documento[event.target.name] = event.target.value

    this.setState({
      documento
    })
  }

  handleUploadMidias = files => {
    console.log(files)

    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
    }))

    this.setState({
      uploadedFiles
    })
  }

  async uploadCertificado(){
    const {documento, uploadedFiles } = this.state

    this.setState({
      disabledButton: true
    })

    if(documento.descricao === ''){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a descrição do arquivo'
        },
        disabledButton: false
      })
      return
    }

    if(uploadedFiles.length === 0){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar o arquivo'
        },
        disabledButton: false
      })
      return
    }

    try {
      const unidade = this.props.unidade

      let data = new FormData();

      data.append("file", uploadedFiles[0].file, uploadedFiles[0].name)
      data.append("descricao", documento.descricao);

      await axios.post(`${process.env.REACT_APP_API_URL}/unidades/uploadDocumento/${unidade.unin_id}`, data, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Arquivo salvo com sucesso!'
        },
        disabledButton: false
      })
      window.location.reload()
    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        },
        disabledButton: false
      })
      return
    }
  }

  downloadFile(documento){
    // Cria um elemento <a> temporário
    const link = document.createElement('a');
    
    // Define a URL do arquivo para o href do link
    link.href = documento.mid_caminho;
    
    // Define o nome do arquivo que será baixado
    link.download = documento.doc_descricao;

    // Adiciona o link temporariamente à página
    document.body.appendChild(link);
    
    // Simula o clique para iniciar o download
    link.click();
    
    // Remove o link após o download
    document.body.removeChild(link);
  }

  async removeFile(documento){
    try {
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Deletando Arquivo!'
        }
      })

      await axios.delete(`${process.env.REACT_APP_API_URL}/unidades/deleteDocumento/${documento._id}`, this.getToken())
      
      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Arquivo Deletado!'
        }
      })

      window.location.reload()

    } catch ({response}) {
      // console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  render() {
    const {documento, uploadedFiles,disabledButton, documentos, cabecalhoTabela, acoesTabela } = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Contratos...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="borderBottom mg_top_10">
              <Grid item md={6} xs={12} sm={12}>
                <h2 className="titulo">Documentos da Unidade: </h2>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              {this.props.permissoes_documento.perm_inserir &&
                <React.Fragment>
                  <Grid item md={4} xs={12} sm={4}>
                    <TextField 
                      className="input" 
                      label="Descrição do Documento*" 
                      variant="outlined" 
                      size="small" 
                      name="descricao" 
                      value={documento.descricao} 
                      onChange={(e) => this.updateField(e)} 
                      InputLabelProps={{ shrink: true }} 
                    />
                  </Grid>
                  <Grid item md={3} xs={12} sm={4}>
                    <UploadMidias onUpload={e => this.handleUploadMidias(e)} />
                    {uploadedFiles[0] &&
                      <span style={{fontSize: '12px'}}>{uploadedFiles[0] ? uploadedFiles[0].name : ''}</span>
                    }
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <Button
                      size='small'
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.uploadCertificado()}
                      disabled={disabledButton}
                    >
                      Upload
                    </Button>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table 
                  headCell={cabecalhoTabela} 
                  rows={documentos.list} 
                  acoes={acoesTabela}
                  downloadFile={(e) => this.downloadFile(e)}
                  removeFile={(e) => this.removeFile(e)}
                />
              </Grid>
            </Grid>
          </div>
        }
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}