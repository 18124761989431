import React, { Component } from 'react'
import './Templates.scss'

import { Link } from 'react-router-dom'
import axios from 'axios'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Button } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import Alert from './Alert'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const initalState = {
  hiddenMenu: true,
  dadosUsuario: {},
  unidades_acesso: {},
  classMenuUnidadeClosed: "block",
  classMenuUnidadeOpen: "hidden",
  acesso_atual: [],
  notificacoes: [],
  openNotification: false,
  menuUnidade: 'close',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

export default class Header extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }


  async UNSAFE_componentWillMount() {

    // const usuario = JSON.parse(localStorage.getItem('usuario'))

    // if(usuario){
    //   const tempoConexao = usuario.tempo
        
    //   if(new Date(tempoConexao).getTime() < new Date().getTime()){
    //     this.props.history.push("/login");
    //     return;
    //   }
    // }

    this.setState({
      menuUnidade: 'close'
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    if(pessoa){
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
      
      const unidades_acesso = JSON.parse(localStorage.getItem('unidades_acesso'))
      
      let acesso_atual = []
      
      if (!localStorage.getItem('acesso_atual')) {
        if (unin_tipo === 'FRANQUEADOR') {
          acesso_atual = [
            {
              unin_id: unidades_acesso.franqueador.unin_id,
              unin_descricao: unidades_acesso.franqueador.unin_descricao,
              unin_numero: unidades_acesso.franqueador.unin_numero,
              unin_tipo: unidades_acesso.franqueador.unin_tipo
            }
          ]
        } else if (unin_tipo === 'FRANQUEADO') {
          acesso_atual = [
            {
              unin_id: unidades_acesso.franquia.unin_id,
              unin_descricao: unidades_acesso.franquia.unin_descricao,
              unin_numero: unidades_acesso.franquia.unin_numero,
              unin_tipo: unidades_acesso.franquia.unin_tipo
            }
          ]
        } else if (unin_tipo === 'UNIDADE') {
          acesso_atual = [
            {
              unin_id: unidades_acesso.unidade.unin_id,
              unin_descricao: unidades_acesso.unidade.unin_descricao,
              unin_numero: unidades_acesso.unidade.unin_numero,
              unin_tipo: unidades_acesso.unidade.unin_tipo
            }
          ]
        }
      } else {
        acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
      }
      
      localStorage.setItem('acesso_atual', JSON.stringify(acesso_atual));
      
      this.setState({
        acesso_atual,
        dadosUsuario: pessoa,
        unidades_acesso
      })
      
      try {
      
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.unin_id
          })
        }
      
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/notificacao`, dados, this.getToken())
      
        this.setState({
          notificacoes: data
        })
      } catch (error) {
        console.log(error)
      }
      
      try {
        const { data: ultimoCaixa } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/ultimo`, this.getToken())
        localStorage.setItem('ultimoCaixa', JSON.stringify(ultimoCaixa));
      
        this.setState({
          ultimoCaixa
        })
      } catch (error) {
        console.log(error)
      }

      const numero_verificado = JSON.parse(localStorage.getItem('numero_verificado'))
      if(!numero_verificado){
        try {
          const { data: numero_verificado_resp } = await axios.get(`${process.env.REACT_APP_API_URL}/whatsApp/verifica_desconectado`, this.getToken())
          localStorage.setItem('numero_verificado', true);

          console.log(numero_verificado_resp)

          if(numero_verificado_resp.numero_desconectado){
            this.setState({
              alerta: {
                open: true,
                autoHideDuration: 5000,
                severity: 'error',
                message: numero_verificado_resp.texto
              }
            })
          }

        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  hiddenMenu() {
    const hiddenMenu = this.state.hiddenMenu

    if (hiddenMenu) {
      document.getElementById('nav').style.display = "block"
      document.getElementById('app').className = "app-menu-open"

      this.setState({
        hiddenMenu: false
      })

    } else if (!hiddenMenu) {
      document.getElementById('nav').style.display = "none"
      document.getElementById('app').className = "app-menu-closed"

      this.setState({
        hiddenMenu: true
      })
    }

  }

  openSelect(param) {
    if (param === "close") {
      this.setState({
        classMenuUnidadeClosed: "hidden",
        classMenuUnidadeOpen: "block",
        menuUnidade: 'open'
      })
    } else if (param === "open") {
      this.setState({
        classMenuUnidadeClosed: "block",
        classMenuUnidadeOpen: "hidden",
        menuUnidade: 'close'
      })
    }
  }

  renderTituloAcesso() {
    const { acesso_atual } = this.state

    if (acesso_atual.length === 1) {
      return (
        <p className="acesso_atual">{acesso_atual[0].unin_numero ? `${acesso_atual[0].unin_numero} - ${acesso_atual[0].unin_descricao}` : acesso_atual[0].unin_descricao}</p>
      )
    } else if (acesso_atual.length > 1) {
      return (
        <p className="acesso_atual">Mais de um acesso simultâneo</p>
      )
    }

  }

  renderAcessos() {
    return (
      <ClickAwayListener onClickAway={e => this.handleClickAway(e)}>
        <div>
          <div id="boxClosed" className={`boxUnidadesMenuClosed ${this.state.classMenuUnidadeClosed}`} >
            <Button className='angle-down' color="primary" variant="contained" size="small" startIcon={<ArrowDropDownIcon />} onClick={e => this.openSelect('close')}></Button>
            {this.renderTituloAcesso()}
          </div>
          <div id="boxOpen" className={`boxUnidadesMenuOpen ${this.state.classMenuUnidadeOpen}`}>
            <Button className='angle-up' color="primary" variant="contained" size="small" startIcon={<ArrowDropUpIcon />} onClick={e => this.openSelect('open')}></Button>
            {this.renderUnidades()}
          </div>
        </div>
      </ClickAwayListener>
    )
  }

  handleClickAway() {
    if (this.state.menuUnidade === 'open') {
      this.setState({
        classMenuUnidadeClosed: "block",
        classMenuUnidadeOpen: "hidden",
        menuUnidade: 'close'
      })
    }
  }

  renderCheckbox(value) {
    const { acesso_atual } = this.state

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    
    if(pessoa){
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
  
      if(value.unin_status){
        const index = acesso_atual.findIndex(acesso => acesso.unin_id === value.unin_id)
    
        if (index !== -1) {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  name="checkedB"
                  color="primary"
                  size="small"
                  onChange={e => this.updateFieldCheckbox(value)}
                />
              }
              label={(value.unin_numero ? `${value.unin_numero} - ${value.unin_descricao}` : value.unin_descricao) + (value.unin_status ? '' : ' - Inativo') }
            />
          )
        } else {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={false}
                  name="checkedB"
                  color="primary"
                  size="small"
                  onChange={e => this.updateFieldCheckbox(value)}
                />
              }
              label={(value.unin_numero ? `${value.unin_numero} - ${value.unin_descricao}` : value.unin_descricao) + (value.unin_status ? '' : ' - Inativo')}
            />
          )
        }
      }else{
        if (unin_tipo === 'FRANQUEADOR') {
          const index = acesso_atual.findIndex(acesso => acesso.unin_id === value.unin_id)
    
          if (index !== -1) {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    name="checkedB"
                    color="primary"
                    size="small"
                    onChange={e => this.updateFieldCheckbox(value)}
                  />
                }
                label={(value.unin_numero ? `${value.unin_numero} - ${value.unin_descricao}` : value.unin_descricao) + (value.unin_status ? '' : ' - Inativo') }
              />
            )
          } else {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={false}
                    name="checkedB"
                    color="primary"
                    size="small"
                    onChange={e => this.updateFieldCheckbox(value)}
                  />
                }
                label={(value.unin_numero ? `${value.unin_numero} - ${value.unin_descricao}` : value.unin_descricao) + (value.unin_status ? '' : ' - Inativo')}
              />
            )
          }
        }else{
          return (
            <span className='unidadeInativa'>{(value.unin_numero ? `${value.unin_numero} - ${value.unin_descricao}` : value.unin_descricao) + (value.unin_status ? '' : ' - Inativo') }</span>
          )
        }
  
      }
    }
  }

  updateFieldCheckbox(value) {
    const { acesso_atual } = this.state

    const index = acesso_atual.findIndex(acesso => acesso.unin_id === value.unin_id)

    if (index !== -1) {
      if (acesso_atual.length > 1) {
        acesso_atual.splice(index, 1);
      }
    } else {
      acesso_atual.push(value)
    }

    this.setState({
      acesso_atual
    })


  }

  renderUnidades() {
    const unin_tipo = this.state.dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
    const unidades_acesso = this.state.unidades_acesso

    if (unin_tipo === 'FRANQUEADOR') {
      return (
        <div className="mg-top--10">
          <div>
            {this.renderCheckbox(unidades_acesso.franqueador)}
          </div>
          <div>
            {unidades_acesso.franqueador.franquias.map(franquia => {
              return (
                <React.Fragment key={franquia.unin_id}>
                  <p className="subTitulo">Franqueado:</p>
                  <div className="franquias">
                    {this.renderCheckbox(franquia)}
                    <div className="mg-top--10">
                      <span className="subSubTitulo">Unidades:</span>
                      {franquia.unidades.map(unidade => {
                        return (
                          <div key={unidade.unin_id} className="unidades">
                            {this.renderCheckbox(unidade)}
                          </div>
                        )
                      })}
                      <hr />
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

        </div>
      )
    } else if (unin_tipo === 'FRANQUEADO') {
      return (
        <div className="mg-top--10">
          <div>
            {this.renderCheckbox(unidades_acesso.franquia)}
            <div className="mg-top--10">
              <span className="subSubTitulo">Unidades:</span>
              {unidades_acesso.franquia.unidades.map(unidade => {
                if (unidade.unin_status) {
                  return (
                    <div key={unidade.unin_id} className="unidades">
                      {this.renderCheckbox(unidade)}
                    </div>
                  )
                }
                return false
              })}
              <hr />
            </div>
          </div>
        </div>
      )
    } else if (unin_tipo === 'UNIDADE') {
      return (
        <div className="mg-top--10">
          {this.renderCheckbox(unidades_acesso.unidade)}
        </div>
      )
    }
  }

  trocarAcesso() {
    const { acesso_atual } = this.state
    localStorage.setItem('acesso_atual', JSON.stringify(acesso_atual));
    this.props.history.push("/home");
    window.location.reload()
    return;
  }

  openNotification() {
    this.setState({
      openNotification: !this.state.openNotification
    })
  }

  handleClickAwayNotification() {
    if (this.state.openNotification) {
      this.setState({
        openNotification: !this.state.openNotification
      })
    }
  }

  render() {
    const { notificacoes, ultimoCaixa } = this.state
    return (
      <header className="header">
        <i onClick={e => this.hiddenMenu(e)} id="bars" >
          <MenuIcon className="menu" />
        </i>
        {ultimoCaixa && !ultimoCaixa.cai_fechado &&
          <div className="caixa">
            <a href={`/caixa/view/${ultimoCaixa.cai_id}`}>
              <p>Caixa</p>
              <p>Nº {ultimoCaixa.cai_numero}</p>
            </a>
          </div>
        }
        <div className="dadosUnidades">
          {this.renderAcessos()}
        </div>

        <Button className='btn-trocar' color="secondary" variant="contained" size="small" startIcon={<SyncAltIcon />} onClick={e => this.trocarAcesso()}></Button>
        
        <div className='notification'>
          {notificacoes.length < 10 &&
            <div className='qtdNotif9'>
              <span>{notificacoes.length}</span>
            </div>
          }
          {notificacoes.length >= 10 &&
            <div className='qtdNotif10'>
              <span>{notificacoes.length}</span>
            </div>
          }
          <NotificationsIcon className="menu" onClick={e => this.openNotification()} />
        </div>
        {this.state.openNotification &&
          <ClickAwayListener onClickAway={e => this.handleClickAwayNotification(e)}>
            <div className='openNotification'>
              {notificacoes.map(notificacao => {
                return (
                  <Link to={notificacao.url}>
                    <div className='itemNotificacao' style={{ backgroundColor: notificacao.cor }}>
                      <p style={{ color: notificacao.corFonte }}>{notificacao.descricao}</p>
                    </div>
                  </Link>
                )
              })}
              <br />
            </div>
          </ClickAwayListener>
        }
        <div className="logout">
          <Link to="/">
            <ExitToAppIcon className="menu" />
          </Link>
        </div>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </header>
    )
  }
}
