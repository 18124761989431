import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'

const FormDepartamento = ({ dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="dep_descricao" value={dados.dep_descricao} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.dep_ativo}
            />
          }
          label="Ativo"
          name="dep_ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormDepartamento