import React, { Component, useRef } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import { saveAs } from 'file-saver';
import SignaturePad from "react-signature-canvas";
import Alert from './../../components/templates/Alert'
import Rodape from './../../components/RodapeLogin'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';
import ModalAssinarAditivo from './../../components/modals/AssinarAditivo'
import decrypt from './../../services/crypto'

const ColorYellowButton = withStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: Color('#FFFF00').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FFFF00',
    },
  },
}))(Button);

const initalState = {
  codigo: '',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  aditivo: {
    aditcontm_assinado: false,
    contrato:{
      resp_fin: null,
      aluno:{
        fisica: {
          pesf_nome: '',
          pesf_sobrenome: '',
          pesf_cpf: '',
          pesf_rg: ''
        }
      }
    },
  },
  aluno: {
    aluno_matricula: '',
    pesf_nome: ''
  },
  openModalAssinarAditivo: false,
  localizacao: '',
  loading: true,
  html_aditivo: '',
  travaBotao: false
}

let sigCanvas = {}

export default class AssinarAditivo extends Component {

  state = { ...initalState }
  
  getToken() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
      
            this.setState({
              localizacao: `${latitude}, ${longitude}`
            })
          },
          (error) => {
            this.setState({
              localizacao: 'Localização não compartilhada pelo usuário'
            })
          }
        );
      }else {
        this.setState({
          localizacao: 'Localização não compartilhada pelo usuário'
        })
      }

      const { match: { params } } = this.props;

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/contratoMatricula/aditivo/${params.aditivo_id}`, this.getToken())

      let aditivo = JSON.parse(decrypt(data))

      this.setState({
        aditivo: {
          ...aditivo
        }
      })

      const { data: resp } = await axios.get(`${process.env.REACT_APP_API_URL}/contratoMatricula/aditivo/preencherHtml/${params.aditivo_id}`, this.getToken())

      let html = JSON.parse(decrypt(resp))

      this.setState({
        html_aditivo: html.replaceAll('%%ASSINATURA_UNIDADE_E_CLIENTE', '').replaceAll('%%ASSINATURA_CLIENTE_UNICA', '').replaceAll('%%ASSINATURA_UNIDADE_UNICA', '')
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false
      })
      return
    }

    this.setState({
      loading: false
    })
  }
  
  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 4000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/aluno");
    }, timeout)
    this.setState({
      aula: initalState.aula,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateField(event){
    this.setState({
      codigo: event.target.value
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async assinarAditivo(value, sigCanvas){
    if(value){
     
      try {
        this.setState({travaBotao: true})
        const {codigo, aditivo, localizacao} = this.state
     
        let base64Assinatura = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        
        if(codigo === '') {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor informar o código'
            },
            travaBotao: false
          })
          return
        }

        if(sigCanvas.current.isEmpty()) {
          this.setState({
            alerta: {
              open: true,
              autoHideDuration: 5000,
              severity: 'warning',
              message: 'Por favor escrever a sua assinatura'
            },
            travaBotao: false
          })
          return
        }

        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 10000,
            severity: 'info',
            message: 'Assinando Aditivo...'
          }
        })
  
        const { match: { params } } = this.props;
  
        const res = await axios.get("https://api.ipify.org/?format=json");
        
        let dados = {
          aditivo: parseInt(params.aditivo_id),
          codigo,
          base64Assinatura,
          localizacao: localizacao,
          ip: res.data.ip
        }

        const respImprimir = await axios.post(`${process.env.REACT_APP_API_URL}/contratoMatricula/aditivo/assinarAditivo`, dados, this.getToken())
  
        const dadosImprimir = { path: JSON.parse(decrypt(respImprimir.data)) }

        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `Aditivo-Assinado.pdf`); 
  
        aditivo.aditcontm_assinado = true
  
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'success',
            message: 'Aditivo Assinado com sucesso'
          },
          codigo: '',
          loadingSalvar: false,
          openModalAssinarAditivo: false,
          travaBotao: false,
          aditivo
        })
        
        return
  
      } catch ({response}) {
        console.log(response.data)
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          },
          loadingSalvar: false,
          travaBotao: false,
        })
        return
      }
    }else{
      this.handleCloseModalAssinarAditivo()
    }
  }

  handleCloseModalAssinarAditivo(){
    this.setState({
      openModalAssinarAditivo: false
    })
  }

  openModalAssinarAditivo(){
    this.setState({
      openModalAssinarAditivo: true
    })
  }
  
  async baixarAditivo() {
    try {

      let {codigo, aditivo} = this.state

      if(codigo === '') {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Por favor informar o código'
          }
        })
        return
      }

      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Baixando Arquivo...'
        },
        loadingSalvar: true
      })
     
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contratoMatricula/aditivo/gerarPDFAssinado/${aditivo.aditcontm_id}`, {codigo}, this.getToken())

      let path = JSON.parse(decrypt(data))

      const dados = {
        path: path
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `Aditivo-Assinado.pdf`);

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Arquivo Baixando...'
        },
        loadingSalvar: false
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        },
        loadingSalvar: false,
        travaBotao: false,
      })
      return
    }
  }

  render() {
    const { loading, aditivo, aluno, html_aditivo, codigo } = this.state

    return (
      <div className="container-boletos" id="app">
        {loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados do Aditivo...</p>
            </div>
          </React.Fragment>
        }
        {!loading &&
          <React.Fragment>
            {!aditivo.aditcontm_assinado &&
              <React.Fragment>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                    <p>Olá {aditivo.contrato.resp_fin ? aditivo.contrato.resp_fin.pesf_nome : aditivo.contrato.aluno.fisica.pesf_nome}, segue a baixo {aditivo.contrato.resp_fin ? ` o aditivo do contrato do aluno ${aditivo.contrato.aluno.fisica.pesf_nome}` : ` o aditivo do seu contrato`}, ao final do termo realizar a sua assinatura.</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item md={4} xs={12} sm={3}></Grid>
                  <Grid item md={4} xs={12} sm={6} >
                    <Button fullWidth variant="contained" color="primary" onClick={() => this.openModalAssinarAditivo()}>
                      Assinar Aditivo de Contrato
                    </Button>
                  </Grid>
                  <Grid item md={4} xs={12} sm={3}></Grid>
                </Grid>
              </React.Fragment>
            }
            {aditivo.aditcontm_assinado &&
              <React.Fragment>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
                    <p>Olá {aditivo.contrato.resp_fin ? aditivo.contrato.resp_fin.pesf_nome : aditivo.contrato.aluno.fisica.pesf_nome}, esse aditivo de contrato já foi assinada, segue abaixo para o download do termo assinado.</p>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item md={4} xs={12} sm={4} ></Grid>
                  <Grid item md={2} xs={12} sm={4} >
                    <TextField
                      className="input" 
                      label="Código Recebido por E-mail*" 
                      variant="outlined" 
                      size="small" 
                      name="codigo"
                      value={codigo} 
                      onChange={(e) => this.updateField(e)} 
                      InputLabelProps={{ shrink: true }} 
                      inputProps={{ maxLength: 6 }}
                    />   
                  </Grid>
                  <Grid item md={2} xs={12} sm={4} >
                    <Button style={{marginTop: 7}} fullWidth variant="contained" color="secondary" onClick={() => this.baixarAditivo()} disabled={this.state.loadingSalvar}>
                      Baixar Aditivo
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </React.Fragment>
        }
        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12} sm={12}>
            <img className='imagemLogo' src="https://system-british-no-delete.s3.amazonaws.com/logo.png" alt="logo" />
          </Grid>
        </Grid>
        <Rodape />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAssinarAditivo
          open={this.state.openModalAssinarAditivo}
          handleClose={e => this.handleCloseModalAssinarAditivo()} 
          aditivo={aditivo}
          codigo={codigo}
          html_aditivo={html_aditivo}
          updateField={(e) => this.updateField(e)}
          confirmar={(e, sigCanvas) => this.assinarAditivo(e, sigCanvas)}
          baixarContrato={() => this.baixarContrato()}
          travaBotao={this.state.travaBotao}
          sigCanvas={sigCanvas}
        />
      </div>
    )
  }
}