import React from 'react'
import Porcentagem from './../mask/Porcentagem'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl } from '@material-ui/core'

const FormContaFinanceira = ({ dados, parceirosBancarios, updateFieldContaCorrente, updateFieldInformarParceiro, 
  updateFieldParceiro, contasFinanceira, updateFieldContaPH, contasPH, updateFieldCobrarJuros }) => (
  <React.Fragment>
    {dados.contfin_tipo_conta === 'BANCO' && 
      <React.Fragment>
        {dados.pes_id !== "" &&
          <React.Fragment>
            {dados.informar_parceiro &&
              <React.Fragment>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={2} xs={12} sm={4}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item md={12} xs={12} sm={12}>
                        <FormControl style={{marginLeft: '20px'}} component="fieldset">
                          <FormLabel component="legend">Informar Parceiro?</FormLabel>
                          <FormControlLabel className="mg_left_10"
                            control={
                              <Switch
                                name="informar_parceiro"
                                color="primary"
                                checked={dados.informar_parceiro}
                              />
                            }
                            size="small"
                            label={dados.informar_parceiro ? 'Sim' : 'Não'}
                            name="informar_parceiro"
                            onChange={(e) => updateFieldInformarParceiro(e)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={10} xs={12} sm={8}>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={4} xs={12} sm={4}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Parceiro*"
                          variant="outlined"
                          className="input"
                          size="small"
                          fullWidth
                          SelectProps={{
                            native: true,
                          }}
                          name="parceiro_id"
                          value={dados.parceiro.parceiro_id}
                          onChangeCapture={(e) => updateFieldParceiro(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value={dados.parceiro.parceiro_id !== '' ? dados.parceiro.parceiro_id : ''}> {dados.parceiro.parceiro_id !== '' ? dados.parceiro.parceiro_descricao : 'Selecionar Parceiro'} </option>
                          {parceirosBancarios.map((parc, key) => {
                            return (
                              <option key={key} value={parc._id}>{parc.descricao}</option>
                            )
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={4} xs={12} sm={4}>
                        <TextField className="input" label="Api Key*" variant="outlined" size="small" name="api_key" value={dados.parceiro.api_key} onChange={(e) => updateFieldParceiro(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                      <Grid item md={4} xs={12} sm={4}>
                        <TextField className="input" label="Token*" variant="outlined" size="small" name="token" value={dados.parceiro.token} onChange={(e) => updateFieldParceiro(e)} InputLabelProps={{ shrink: true }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <hr />
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField className="input" type="number" label="Qtd. de dias para pag.*" variant="outlined" size="small" name="bol_qtd_dias_pag" value={dados.parceiro.bol_qtd_dias_pag} onChange={(e) => updateFieldParceiro(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={4}>
                    <TextField className="input" label="Multa* (0%, 1% ou 2%)" variant="outlined" size="small" name="bol_multa" value={dados.parceiro.bol_multa} onChange={(e) => updateFieldParceiro(e)} InputLabelProps={{ shrink: true }} placeholder="0,00%" InputProps={{ inputComponent: Porcentagem }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={3}>
                    <FormControl component="fieldset" style={{marginLeft: '10%'}}>
                      <FormLabel component="legend" style={{color: '#000'}}>Cobrar Juros Diários? (1% ao mês / 0,033% ao dia)</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="Ativo"
                            color="primary"
                            checked={dados.parceiro.bol_juros}
                          />
                        }
                        size="small"
                        label={dados.parceiro.bol_juros ? 'Sim' : 'Não'}
                        name="ccad_gera_fin"
                        onChange={(e) => updateFieldCobrarJuros(e)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Texto Boleto"
                      className="input"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      name="bol_texto"
                      value={dados.parceiro.bol_texto}
                      inputProps={{ maxLength: 85 }}
                      onChange={(e) => updateFieldParceiro(e)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  <Grid item md={12} xs={12} sm={12}>
                   <span style={{fontSize: 16}}>Limite: {dados.parceiro.bol_texto ? dados.parceiro.bol_texto.length : 0} de 85 Caracteres</span>
                  </Grid>
                </Grid>
                {contasFinanceira.length > 0 &&
                  <React.Fragment>
                    <br />
                    <hr />
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <h4 className='titulo'>Relação entre contas:</h4>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <div className='scroll'>
                          <table style={{width: '50%'}} className="lista sempadding borderTable">
                            <thead>
                              <tr className="titulos acompanha">
                                <th style={{width: '50%'}}>Conta +F</th>
                                <th style={{width: '50%'}}>Conta PagHiper</th>
                              </tr>
                            </thead>
                            <tbody>
                              {contasFinanceira.map((conta, key) => {
                                return (
                                  <tr key={key}>
                                    <td style={{padding: '5px'}}>
                                      <img style={{width: '30px', float: 'left', marginRight: '10px'}} src={conta.logo} alt="logo"/>
                                      <span style={{float: 'left', marginTop: '3px', fontSize: '16px'}}>{conta.descricao}</span>
                                    </td>
                                    <td style={{padding: '5px'}}>
                                      <TextField
                                        id="standard-select-currency"
                                        select
                                        variant="outlined"
                                        className="input"
                                        fullWidth
                                        size="small"
                                        SelectProps={{
                                          native: true,
                                        }}
                                        name="bank_account_id"
                                        value={conta.bank_account_id}
                                        onChangeCapture={(e) => updateFieldContaPH(e, conta.index)}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        <option value="">--Escolha--</option>
                                        {contasPH.map(contaPH => {
                                          return (
                                            <option key={contaPH.bank_account_id} value={contaPH.bank_account_id}>{contaPH.bank_code} - {contaPH.bank_name}</option>
                                          )
                                        })}
                                      </TextField>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            <Grid container direction="row" spacing={1} className="mg_top_10">
              {!dados.informar_parceiro && 
                <Grid item md={2} xs={12} sm={4}>
                  <FormControl style={{marginLeft: '20px'}} component="fieldset">
                    <FormLabel component="legend">Informar Parceiro?</FormLabel>
                    <FormControlLabel className="mg_left_10"
                      control={
                        <Switch
                          name="informar_parceiro"
                          color="primary"
                          checked={dados.informar_parceiro}
                        />
                      }
                      size="small"
                      label={dados.informar_parceiro ? 'Sim' : 'Não'}
                      name="informar_parceiro"
                      onChange={(e) => updateFieldInformarParceiro(e)}
                    />
                  </FormControl>
                </Grid>
              }
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    }

    {dados.contfin_tipo_conta !== 'BANCO' &&
      <React.Fragment> 
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h5>Apenas para contas do tipo BANCO</h5>
          </Grid>
        </Grid>
      </React.Fragment>
    }   
  </React.Fragment>
)

export default FormContaFinanceira