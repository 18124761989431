import React, { Component } from 'react'
import './Lembrete.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button } from '@material-ui/core'
import FormLembrete from '../../components/forms/FormLembrete'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import moment from 'moment'

const initalState = {
  lembrete: {
    lemb_id: '',
    lemb_data_lembrete: '',
    lemb_titulo: '',
    lemb_descricao: '',
    lemb_ativo: true,
    intervalos: []
  },
  intervalo: {
    intlemb_id: '',
    intlemb_quantidade: '',
    intlemb_intervalo: '',
    index: ''
  },
  unidadesSelecionadas: [],
  unidades: {
    list: []
  },
  loadingSalvar: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'intervalo', numeric: false, disablePadding: true, label: 'Unidade de Tempo' },
  ],
  acoesTabela: ['editar', 'removeTabela'],
}

export default class CadastroDepartamento extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'lembretes')[0]

    this.setState({
      permissoes
    })

    if (params.lembreteId) {
      if (!permissoes.perm_alterar) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: "Você não tem permissão para acessa essa tela!"
          }
        })

        this.backPage()
        return
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: "Você não tem permissão para acessa essa tela!"
          }
        })

        this.backPage()
        return
      }
    }

    try {
      const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupoAll`, {}, this.getToken())

      this.setState({
        unidades:{
          list: unidades.filter(param => (param.unin_tipo === 'UNIDADE' || param.unin_tipo === 'FRANQUEADOR')).filter(param => param.unin_status).map(unidade => {
            return {
              unin_id: unidade.unin_id,
              unin_numero: unidade.unin_numero,
              unin_descricao: unidade.unin_descricao
            }
          })
        }
      })

      if (params.lembreteId) {
        const { data: lembrete } = await axios.get(`${process.env.REACT_APP_API_URL}/lembrete/${params.lembreteId}`, this.getToken())

        this.setState({
          lembrete: {
            lemb_id: lembrete.lemb_id,
            lemb_data_lembrete: moment(lembrete.lemb_data_lembrete).format('YYYY-MM-DD HH:mm'),
            lemb_titulo: lembrete.lemb_titulo,
            lemb_descricao: lembrete.lemb_descricao,
            lemb_ativo: lembrete.lemb_ativo,
            intervalos: lembrete.lembrete_intervalos.map((intervalo, key) => {
              return {
                intlemb_id: intervalo.intlemb_id,
                intlemb_quantidade: intervalo.intlemb_quantidade,
                intlemb_intervalo: intervalo.intlemb_intervalo,
                index: key
              }
            })
          },
          unidadesSelecionadas: lembrete.lembrete_unidades.map(value => {
            return {
              unin_id: value.unidade.unin_id,
              unin_descricao: value.unidade.unin_descricao,
              unin_numero: value.unidade.unin_numero
            }
          })
        })
      }

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }

    this.setState({
      loading: false
    })

  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  updateField(event) {
    const {lembrete} = this.state
    lembrete[event.target.name] = event.target.value
    this.setState({ lembrete })
  }
  
  updateFieldIntervalo(event){
    let {intervalo} = this.state

    intervalo[event.target.name] = event.target.value

    this.setState({intervalo})
  }

  updateFieldAtivo(){
    let {lembrete} = this.state

    lembrete.lemb_ativo = !lembrete.lemb_ativo

    this.setState({lembrete})
  }

  backPage(timeout = 1000) {
    const self = this
   
    setTimeout(() => {
      self.props.history.push("/lembretes");
    }, timeout)
  }

  updateFieldUnidade(event, value) {
    this.setState({
      unidadesSelecionadas: value
    })
  }

  addIntervalo(){
    let {intervalo, lembrete} = this.state

    if(intervalo.intlemb_quantidade === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar o tempo!"
        }
      })
      return
    }

    if(intervalo.intlemb_intervalo === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar a unidade de tempo!"
        }
      })
      return
    }

    if(intervalo.index === ''){
      intervalo.index = lembrete.intervalos.length
      lembrete.intervalos.push({...intervalo})
    }else{
      lembrete.intervalos[intervalo.index] = {...intervalo}
    }
    
    this.setState({
      lembrete,
      intervalo: {
        intlemb_id: '',
        intlemb_quantidade: '',
        intlemb_intervalo: '',
        index: ''
      }
    })
  }

  editarItem(index){
    let {lembrete} = this.state

    this.setState({
      intervalo: {...lembrete.intervalos[index]}
    })
  }

  removerItem(index){
    let {lembrete} = this.state

    lembrete.intervalos.splice(index, 1)

    for (let i = 0; i < lembrete.intervalos.length; i++) {
      lembrete.intervalos[i].index = i;
    }

    this.setState({
      lembrete
    })
  }
 
  async salvar() {
    let {lembrete, unidadesSelecionadas} = this.state

    if(lembrete.lemb_titulo === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar o titulo do lembrete!"
        }
      })
      return
    }

    if(lembrete.lemb_data_lembrete === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar a data do lembrete!"
        }
      })
      return
    }

    if(lembrete.lemb_descricao === ''){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar a descrição do lembrete!"
        }
      })
      return
    }

    if(unidadesSelecionadas.length === 0){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar pelo menos uma unidade!"
        }
      })
      return
    }

    if(lembrete.intervalos.length === 0){
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'warning',
          message:  "Por favor informar ao menos um intervalo!"
        }
      })
      return
    }

    try {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'info',
          message:  `Salvando lembrete...`
        }
      })

      let dados = {
        lemb_data_lembrete: lembrete.lemb_data_lembrete,
        lemb_titulo: lembrete.lemb_titulo,
        lemb_descricao: lembrete.lemb_descricao,
        lemb_ativo: lembrete.lemb_ativo,
        intervalos: lembrete.intervalos.map(value => {
          return {
            intlemb_id: value.intlemb_id !== '' ? value.intlemb_id : null,
            intlemb_quantidade: parseInt(value.intlemb_quantidade),
            intlemb_intervalo: value.intlemb_intervalo
          }
        }),
        unidades: unidadesSelecionadas.map(unidade => {
          return {
            unin_id: unidade.unin_id
          }
        })
      }

      if(lembrete.lemb_id !== ''){
        await axios.put(`${process.env.REACT_APP_API_URL}/lembrete/${lembrete.lemb_id}`, {...dados}, this.getToken())
      }else{
        await axios.post(`${process.env.REACT_APP_API_URL}/lembrete`, {...dados}, this.getToken())
      }

      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'success',
          message:  `Lembrete Criado!`
        }
      })
      this.backPage()

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  render() {
    const { lembrete, unidades, unidadesSelecionadas, intervalo } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} id='view'>
        {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Lembrete...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h2 className="titulo">Cadastro de Lembretes</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormLembrete 
                dados={lembrete}
                updateField={(e) => this.updateField(e)}
                unidades={unidades.list}
                updateFieldAtivo={() => this.updateFieldAtivo()}
                unidadesSelecionadas={unidadesSelecionadas}
                updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
                updateFieldIntervalo={(e) => this.updateFieldIntervalo(e)}
                intervalo={intervalo}
                acoesTabela={this.state.acoesTabela}
                cabecalhoTabela={this.state.cabecalhoTabela}
                addIntervalo={() => this.addIntervalo()}
                editarItem={(e) => this.editarItem(e)}
                removerItem={(e) => this.removerItem(e)} 
              />
              <hr className="mg_top_10"/>
              <Grid container direction="row" spacing={1} alignItems="flex-end" className="mg_top_10">
                <Grid item md={10}></Grid>
                <Grid item md={2}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => this.salvar(false)}>Salvar</Button>
                </Grid>
              </Grid>
            </React.Fragment>
          }
        </Main>
        <Nav />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <Footer history={this.props.history} />
      </div>
    )
  }
}