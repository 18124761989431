import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField } from '@material-ui/core'
import Table from '../../components/Table'
import real from './../../services/real'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    marginLeft: '5%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

const cabecalhoTabela = [
  { id: 'cliente', numeric: false, disablePadding: true, label: 'Cliente' },
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
  { id: 'valor', numeric: true, disablePadding: true, label: 'Valor Parcela' },
  { id: 'multajuros', numeric: true, disablePadding: true, label: 'Multa / Juros / Desc.' },
  { id: 'valor_total', numeric: true, disablePadding: true, label: 'Valor Total' },
  { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma de Pagto.' },
  { id: 'status', numeric: true, disablePadding: true, label: 'Status' }
]

const acoesTabela = []

export default function SimpleModal(props) {
  const classes = useStyles()

  const {dados} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Contas do Dia: {dados.data_vencimento}</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={5}>
              <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={dados.descricao} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            {/* <Grid item md={4} xs={12} sm={5}>
              <TextField className="input" label="Responsável Financeiro" variant="outlined" size="small" name="responsavel" value={dados.responsavel} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid> */}
            <Grid item md={2} xs={12} sm={5}>
              <TextField className="input" label="Valor Total" variant="outlined" size="small" name="valor" value={dados.valor} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={2} xs={12} sm={5}>
              <TextField className="input" label="Tipo da Conta" variant="outlined" size="small" name="tipo_conta" value={dados.tipo_conta} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
          </Grid>
          <br />
          <hr />
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className='titulo'>Contas: </h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={dados.parcelas.map(parcela => {
                  return {
                    _id: parcela.id,
                    descricao: `${parcela.conta.contpr_descricao} - Parc ${parcela.parcont_num}/${parcela.conta.contpr_numparcela}`,
                    valor:'R$ ' + real(parcela.valor_parcela),
                    multajuros: 'R$ ' + real(parcela.parcont_multajuros),
                    valor_total:'R$ ' + real(parcela.valor_parcela + parcela.parcont_multajuros),
                    status: parcela.status,
                    boleto_transaction_id: parcela.boleto_transaction_id,
                    cliente: parcela.cliente,
                    forma_pagto: parcela.forma_pagto_descricao
                  }
                })}
                qtdLinhas={dados.parcelas.length}
                acoes={acoesTabela} />
            </Grid>
          </Grid>

          {/* <Grid container direction="row" spacing={1}>
            <Grid item md={4} xs={12} sm={5}>
              <TextField className="input" label="Descricão*" variant="outlined" size="small" name="contpr_descricao" value={dados.contpr_descricao} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={1} xs={12} sm={2}>
              <TextField className="input" label="Parcela" variant="outlined" size="small" name="num" value={`${dados.num}/${dados.contpr_numparcela}`} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <TextField className="input" label="Situação" variant="outlined" size="small" name="situacao" value={allStatus.filter(param => param.de === dados.situacao)[0] ? allStatus.filter(param => param.de === dados.situacao)[0].para : ''} InputLabelProps={{ shrink: true }} disabled />
            </Grid>
            <Grid item md={4} xs={12} sm={5}>
              <TextField className="input" label="Cliente/Fornecedor*" variant="outlined" size="small" name="cliente_fornecedor" value={dados.cliente_fornecedor} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={3} xs={12} sm={6}>
              <TextField type="date" className="input" label="Data Vencimento*" variant="outlined" size="small" name="datavencimento" value={dados.datavencimento} InputLabelProps={{ shrink: true }} disabled={true} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Valor*" variant="outlined" size="small" name="valorparcela" value={dados.valorparcela} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={true}/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Juros/Multa ou Desc." variant="outlined" size="small" name="multajuros" value={dados.multajuros} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={true} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" label="Valor Total" variant="outlined" size="small" name="valor_total" value={real(parseFloat(dados.valorparcela.replace('.', '').replace(',', '.')) + parseFloat(dados.multajuros ? dados.multajuros.replace('.', '').replace(',', '.') : 0))} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={true} />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className='titulo'>Formas de Pagamento:</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={dados.formas_pgto}
                qtdLinhas={dados.formas_pgto.length}
                acoes={dados.situacao === 'VENCIDO' || dados.situacao === 'A VENCER' ? acoesTabela : []} />
            </Grid>
          </Grid> */}
        </div>
      </Modal>
    </div>
  );
}
