import React, { Component } from 'react'
import './FechamentoCaixa.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'

import { Grid, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import real from '../../services/real'
import moment from 'moment'

const initalState = {
  caixa: {},
  caixas: {
    list: []
  },
  caixasFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'cai_numero', numeric: false, disablePadding: true, label: 'Nº Caixa' },
    { id: 'operador', numeric: false, disablePadding: true, label: 'Operador' },
    { id: 'cai_dataabertura', numeric: false, disablePadding: true, label: 'Data de abertura' },
    { id: 'cai_saldo', numeric: false, disablePadding: true, label: 'Saldo atual' },
    { id: 'situacao', numeric: false, disablePadding: true, label: 'Situação' },
  ],
  acoesTabela: ['view'],
  loading: true,
  filtro: {
    cai_numero: "",
    operador: "",
    scd_status: ""
  },
  unin_tipo: 'UNIDADE'
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Caixa extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo : 'UNIDADE'

    this.setState({
      unin_tipo
    })

    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/caixa/aguardando-fechamento`, dados, this.getToken())

      const list = data.map(caixa => {
        return {
          _id: caixa.cai_id,
          cai_numero: caixa.cai_numero,
          operador: caixa.operador,
          cai_dataabertura: moment(caixa.cai_dataabertura).format('DD/MM/YYYY HH:mm'),
          cai_saldo: `R$ ${real(caixa.contfin_saldo_atual)}`,
          situacao: caixa.scd_status
        }
      })

      this.setState({
        caixas: { list },
        caixasFiltrado: { list },
        loading: false
      })

    } catch (error) {
      console.log(error)
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const caixas = this.state.caixas.list
    const filtro = this.state.filtro

    const list = caixas.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      caixasFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, caixasFiltrado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Caixas Aguardando Fechamento</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Caixa" variant="outlined" size="small" name="cai_numero" value={filtro.cai_numero} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Operador" variant="outlined" size="small" name="operador" value={filtro.operador} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Situação" variant="outlined" size="small" name="scd_status" value={filtro.scd_status} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table
                    urlView="/caixa_fechamento/view/"
                    headCell={this.state.cabecalhoTabela}
                    rows={caixasFiltrado.list}
                    acoes={this.state.acoesTabela}
                    ordem='desc'
                  />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Footer />
      </div>
    )
  }
}