import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button } from '@material-ui/core'
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask"

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Grid container direction="row" className="mg_top_20">
            <Grid item md={4} xs={12} sm={4}></Grid>
            <Grid item md={4} xs={12} sm={4}>
              <Grid container direction="row" spacing={1} style={{marginTop: '100px'}}>
                <Grid item md={12} xs={12} sm={12}>
                  <h6 style={{fontSize: 20}} className="tituloConfirmacao">Área do Aluno</h6>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item md={12}>
                  <p style={{margin:'10px 0px'}}>Por favor informar o seu CPF/CNPJ:</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <CpfCnpj
                    label="CPF/CNPJ"
                    className="inputCpfCnpj"
                    name="cpf_cnpj"
                    value={dados.cpf_cnpj}
                    onChange={(e) => props.updateData(e)}
                    style={{marginTop: '-10px'}}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true)} disabled={dados.cpf_cnpj === "" ? true : false}>Entrar</Button>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <img style={{width: '60%', marginLeft: '20%', marginTop: '30px'}} src="./../../images/logo.png" alt="Logo British And American" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} sm={4}></Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
