import React, { Component } from 'react'
import './RankingBFF.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import moment from 'moment'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import Checkbox from '@material-ui/core/Checkbox';
import Color from 'color';
import { withStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SaveIcon from '@material-ui/icons/Save';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import real from './../../services/real'
import CurrencyInput from './../../components/mask/CurrencyInput'

const initalState = {
  ranking_bff:{
    list: []
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  filtro: {
    data: '',
    relatorio: 'ranking-bff'
  },
  loadingDownload: false,
  habilita_edicao: false,
  tab: 0,
  regra: {
    html: ''
  },
  editorState: EditorState.createEmpty(),
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const maskData = value => {
  return value.replace(/\D/g, "").replace(/^(\d{2})(\d{4})+?$/, "$1/$2");
};

const ColorDarkGreenButton = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: Color('#006400').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#006400',
    },
  },
}))(Button);

export default class FlyHigh extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'ranking-bff')[0]

    this.setState({
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    if(unin_tipo === "FRANQUEADOR" && permissoes.perm_inserir){
      this.setState({
        habilita_edicao: true
      })
    }

    let {filtro} = this.state

    filtro.data = `${moment().format('MM')}/${moment().format('YYYY')}`

    this.setState({
      filtro
    })

    await this.filtrar()

    this.setState({
      unin_tipo,
      loading: false
    })
  }

  somarValores(list){    
    for (let i = 0; i < list.length; i++) {
      const element = list[i];

      console.log(element.rkg_certificado)

      let rkg_administrador = element.rkg_cobranca + 
      element.rkg_aluno_sem_material + 
      element.rkg_sem_aula_15_dias + 
      element.rkg_canc_inat_ause + 
      element.rkg_reuniao_geral + 
      element.rkg_fale_com_gente + 
      element.rkg_reclame_aqui + 
      element.rkg_decoracao
      
      let rkg_pedagogico = element.rkg_retencao + 
      element.rkg_teens + 
      element.rkg_hyper + 
      (element.rkg_certificado === "" ? 0 : parseFloat(element.rkg_certificado.replaceAll('.', '').replaceAll(',', '.'))) + 
      element.rkg_sem_teens + 
      element.rkg_sem_hyper + 
      element.rkg_national_event + 
      element.rkg_super_class + 
      element.rkg_special_class

      let rkg_total = (element.rkg_matriculas + rkg_administrador + rkg_pedagogico) + (element.rkg_penalidade === "" ? 0 : parseFloat(element.rkg_penalidade.replaceAll('.', '').replaceAll(',', '.')))

      element.rkg_administrador = rkg_administrador
      element.rkg_pedagogico = rkg_pedagogico
      element.rkg_total = rkg_total
      
      list[i] = element
    }

    return list
  }

  handleChangeCheck(value, name, valor){
    let {ranking_bff} = this.state

    let index  = ranking_bff.list.findIndex(param => param.rkg_id === value.rkg_id)

    if(ranking_bff.list[index][name] !== valor){
      ranking_bff.list[index][name] = valor
    }else{
      ranking_bff.list[index][name] = 0
    }

    ranking_bff.list = this.somarValores(ranking_bff.list)

    this.setState({
      ranking_bff
    })
  }

  updateRanking(e, value) {
    let {ranking_bff} = this.state

    let index  = ranking_bff.list.findIndex(param => param.rkg_id === value.rkg_id)

    ranking_bff.list[index][e.target.name] = e.target.value

    ranking_bff.list = this.somarValores(ranking_bff.list)

    this.setState({
      ranking_bff
    })
  }

  updateFieldFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = maskData(event.target.value)

    this.setState({ filtro })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async filtrar(){
    try {
      let {filtro} = this.state

      // filtro.data = `${moment().format('MM')}/${moment().format('YYYY')}`

			const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())
      
      if(data.regra){
        const contentBlock = htmlToDraft(data.regra.regra_html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
      
        this.setState({
          regra: {
            html: data.regra.regra_html
          },
          editorState
        })
      }

      let array = []

      for (const ranking of data.ranking) {
        array.push({
          ...ranking,
          rkg_certificado: real(ranking.rkg_certificado),
          rkg_penalidade: real(ranking.rkg_penalidade)
        })
      }

      console.log(array)

      this.setState({
        ranking_bff:{
          list: array
        },
        filtro
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async salvar(){
    let {ranking_bff} = this.state

    try {
      let array = []

      for (const ranking of ranking_bff.list) {
        array.push({
          ...ranking,
          rkg_certificado: parseFloat(ranking.rkg_certificado.replaceAll('.', '').replaceAll(',', '.')),
          rkg_penalidade: parseFloat(ranking.rkg_penalidade.replaceAll('.', '').replaceAll(',', '.'))
        })
      }

		  await axios.put(`${process.env.REACT_APP_API_URL}/rankingBFF`, array, this.getToken())
      
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'success',
          message: 'Ranking Atualizado!'
        },
        ranking_bff: {
          list: ranking_bff.list.sort((a, b) => (a.rkg_total > b.rkg_total) ? -1 : (b.rkg_total > a.rkg_total) ? 1 : 0)
        }
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  async imprimir() {
    const { filtro, ranking_bff } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      let array = []

      for (const ranking of ranking_bff.list) {
        array.push({
          ...ranking,
          rkg_certificado: parseFloat(ranking.rkg_certificado.replaceAll('.', '').replaceAll(',', '.')),
          rkg_penalidade: parseFloat(ranking.rkg_penalidade.replaceAll('.', '').replaceAll(',', '.'))
        })
      }


      filtro.list = array

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, 'RankingBFF.pdf')

    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }

  }

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  onEditorStateChange(editorState){
    const {regra } = this.state

    regra.html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    this.setState({editorState, regra})
  }

  async salvarRegras(){
    const {regra } = this.state

    try {
		  await axios.post(`${process.env.REACT_APP_API_URL}/rankingBFF/regras`, regra, this.getToken())
      
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'success',
          message: 'Regras Atualizada!'
        },
      })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  render() {
    const { filtro, ranking_bff, loadingDownload, habilita_edicao, tab } = this.state

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Ranking...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div id='ranking_bff'>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h1 className="titulo">Ranking BFF</h1>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={2} xs={12} sm={5}>
                  <TextField 
                    className="input" 
                    type="text" 
                    placeholder='xx/xxxx' 
                    label="Mês/Ano" 
                    variant="outlined" 
                    size="small" 
                    name="data" 
                    value={filtro.data} 
                    inputProps={{ maxLength: 7 }}
                    onChange={(e) => this.updateFieldFiltro(e)}
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={2}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.data === '' ? true : false}>
                    Filtrar
                  </Button>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                    Imprimir
                  </Button>
                </Grid>
                {habilita_edicao &&
                  <Grid item md={2} xs={12} sm={3}>
                    <ColorDarkGreenButton fullWidth variant="contained" onClick={() => this.salvar()} disabled={loadingDownload}>
                      Salvar
                    </ColorDarkGreenButton>
                  </Grid>
                }
              </Grid>
              <br />
              <AppBar position="static">
                <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                  <Tab label="Ranking" {...a11yProps(0)} />
                  <Tab label="Regras" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <div class="table-container">
                      <table className="tabela_bff">
                        <thead>
                          <tr>
                            <th rowSpan="2" style={{width: 200}}>Unidade</th>
                            <th rowSpan="2" style={{width: 70}}>Total</th>
                            <th rowSpan="2" style={{width: 70}}>Com.</th>
                            <th rowSpan="2" style={{width: 120}}>Penal.</th>
                            <th style={{width: 70}}>Adm.</th>
                            <th style={{width: 120}}>Cobrança</th>
                            <th>Alunos s/ Mater.</th>
                            <th style={{width: 115}}>Reun. Geral</th>
                            <th style={{width: 115}}>Fale c/ a Gente</th>
                            <th style={{width: 115}}>Reclame Aqui</th>
                            <th style={{width: 110}}>Decor.</th>
                            <th colSpan="2">%Sem aula últ. 15 dias</th>
                            <th>Canc. + Inat. + Ausên.</th>
                          </tr>
                          <tr>
                            <th>Ped.</th>
                            <th>Certif.</th>
                            <th>Retenção</th>
                            <th>Sem Tenns</th>
                            <th>Teens</th>
                            <th>Sem Hyper</th>
                            <th>Hyper</th>
                            <th style={{width: 110}}>National Event</th>
                            <th style={{width: 110}}>Super Class</th>
                            <th style={{width: 110}}>Special Class</th>
                          </tr>
                        </thead>
                        <tbody className='rolagem'>
                          {ranking_bff.list.map((value, key) => {
                            return (
                              <React.Fragment>
                                <tr key={`${key}-1`}>
                                  <td rowSpan={2}>{key+1}º - {value.unidade.unin_descricao}</td>
                                  <td rowSpan={2}>{value.rkg_total ? real(value.rkg_total.toFixed(2)) : real(0)} </td>
                                  <td rowSpan={2}>{value.rkg_matriculas ? real(value.rkg_matriculas) : real(0)} </td>
                                  <td rowSpan={2}>
                                    {habilita_edicao &&
                                      <TextField
                                        variant="outlined" 
                                        size="small" 
                                        value={value.rkg_penalidade}
                                        name='rkg_penalidade'
                                        onChange={(e) => this.updateRanking(e, value) }
                                        InputProps={{ inputComponent: CurrencyInput }}
                                      />
                                    }
                                    {!habilita_edicao &&
                                      <span>{value.rkg_penalidade}</span>
                                    }
                                  </td>
                                  <td>{value.rkg_administrador ? real(value.rkg_administrador.toFixed(2)) : real(0)}</td>
                                  <td>{value.rkg_cobranca ? real(value.rkg_cobranca.toFixed(2)) : real(0)}</td>
                                  <td>{value.rkg_aluno_sem_material ? real(value.rkg_aluno_sem_material.toFixed(2)) : real(0)}</td>
                                  <td>
                                    {habilita_edicao &&
                                      <Checkbox
                                        checked={value.rkg_reuniao_geral === 5}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_reuniao_geral', 5)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_reuniao_geral.toFixed(2))}
                                  </td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_fale_com_gente === -50}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_fale_com_gente', -50)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_fale_com_gente.toFixed(2))}
                                  </td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_reclame_aqui === -50}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_reclame_aqui', -50)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_reclame_aqui.toFixed(2))}
                                  </td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_decoracao === 2}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_decoracao', 2)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_decoracao.toFixed(2))}
                                  </td>
                                  <td colSpan={2}>{real(value.rkg_sem_aula_15_dias.toFixed(2))}</td>
                                  <td>{real(value.rkg_canc_inat_ause.toFixed(2))}</td>
                                </tr>
                                <tr key={`${key}-2`}>
                                  <td>{real(value.rkg_pedagogico.toFixed(2))}</td>
                                  <td>
                                    {habilita_edicao && 
                                      <TextField
                                        variant="outlined" 
                                        size="small" 
                                        value={value.rkg_certificado}
                                        name='rkg_certificado'
                                        onChange={(e) => this.updateRanking(e, value) }
                                        InputProps={{ inputComponent: CurrencyInput }}
                                      />
                                    }
                                    {!habilita_edicao && 
                                      <span>{value.rkg_certificado}</span>
                                    }
                                  </td>
                                  <td>{real(value.rkg_retencao.toFixed(2))}</td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_sem_teens === -30}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_sem_teens', -30)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_sem_teens.toFixed(2))}
                                  </td>
                                  <td>{real(value.rkg_teens.toFixed(2))}</td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_sem_hyper === -30}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_sem_hyper', -30)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_sem_hyper.toFixed(2))}
                                  </td>
                                  <td>{real(value.rkg_hyper.toFixed(2))}</td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_national_event === 10}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_national_event', 10)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_national_event.toFixed(2))}
                                  </td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_super_class === 5}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_super_class', 5)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_super_class.toFixed(2))}
                                  </td>
                                  <td>
                                    {habilita_edicao && 
                                      <Checkbox
                                        checked={value.rkg_special_class === 3}
                                        color="primary"
                                        onChange={() => this.handleChangeCheck(value, 'rkg_special_class', 3)}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                      />
                                    }
                                    {real(value.rkg_special_class.toFixed(2))}
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {unin_tipo === 'FRANQUEADOR' &&
                  <React.Fragment>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={12} xs={12} sm={12}>
                        <div className="cardEditor">
                          <Editor
                            editorState={this.state.editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={e => this.onEditorStateChange(e)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" className="mg_top_10">
                      <Grid item md={10} xs={12} sm={9}></Grid>
                      <Grid item md={2} xs={12} sm={3}>
                        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvarRegras(e)}>Salvar Regras</Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
                {unin_tipo !== 'FRANQUEADOR' &&
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <div style={{width: "100%"}} dangerouslySetInnerHTML={{__html: this.state.regra.html}}></div>
                    </Grid>
                  </Grid>
                }
              </TabPanel>
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </div>
    )
  }
}