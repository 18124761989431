import React, { Component } from 'react'
import './WhatsApp.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button } from '@material-ui/core'
import axios from 'axios'
import Footer from '../../components/templates/Footer'
import ModalErro from './../../components/modals/Erro'

const initalState = {
  numeros: {
    list: []
  },
  numerosFiltrado: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'numero', numeric: false, disablePadding: true, label: 'Número' },
    { id: 'conectado', numeric: false, disablePadding: true, label: 'Conectado' },
    { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  filtro: {
    descricao: "",
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function formataTelefone(numero) {

  if(numero){
    let tel=numero.replace(/\D/g,"")
    tel=tel.replace(/^(\d)/,"+$1")
    tel=tel.replace(/(.{3})(\d)/,"$1($2")
    tel=tel.replace(/(.{6})(\d)/,"$1)$2")
    if(tel.length === 12) {
			tel=tel.replace(/(.{1})$/,"-$1")
    } else if (tel.length === 13) {
			tel=tel.replace(/(.{2})$/,"-$1")
    } else if (tel.length === 14) {
			tel=tel.replace(/(.{3})$/,"-$1")
    } else if (tel.length === 15) {
			tel=tel.replace(/(.{4})$/,"-$1")
    } else if (tel.length > 15) {
			tel=tel.replace(/(.{4})$/,"-$1")
    }
    return tel;
  }

  return ''
}

export default class WhatsApp extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'api-whatsapp')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    try {
      const { data: numeros } = await axios.get(`${process.env.REACT_APP_API_URL}/whatsApp/listInstancia`, this.getToken())

      this.setState({
        numeros: {
          list: numeros.map((value, key) => {
            return {
              _id: value.numwhats_id,
              nome: value.numwhats_nome,
              numero: formataTelefone(value.numwhats_numero),
              conectado: value.numwhats_conectado,
              ativo: value.numwhats_ativo,
              index: key
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: error.response.data.error
      })
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { permissoes, numeros } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Números...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12} sm={6}>
                  <h1 className="titulo">Números WhatsApp</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={3} xs={12} sm={6}>
                    <Link to="/api_whatsapp/cadastro">
                      <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Número
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate="/api_whatsapp/cadastro/" 
                    urlView="/api_whatsapp/view/" 
                    headCell={this.state.cabecalhoTabela} 
                    rows={numeros.list} 
                    acoes={this.state.acoesTabela} 
                  />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}