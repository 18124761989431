import React, { useRef } from 'react'; 
import SignaturePad from "react-signature-canvas";
import { Grid, TextField, Button, Radio } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import Color from 'color';

const ColorYellowButton = withStyles((theme) => ({
  root: {
    color: '#000',
    backgroundColor: Color('#FFFF00').alpha(0.9).string(),
    '&:hover': {
      backgroundColor: '#FFFF00',
    },
  },
}))(Button);

export default function Contrato(props) {
  const sigCanvas = useRef({})
  const clear = () => sigCanvas.current.clear();

  return (
    <React.Fragment>
      <Grid container direction="row" spacing={2}>
        <Grid item md={1} xs={12} sm={1}></Grid>
        <Grid item md={10} xs={12} sm={10} style={{textAlign: 'center'}}>
          <span>Leia com atenção o contrato e assine no final da página</span>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item md={1} xs={12} sm={1}></Grid>
        <Grid item md={10} xs={12} sm={10}>
          <div style={{border: '1px solid #C0C0C0', padding: '40px'}} dangerouslySetInnerHTML={{__html: props.html_documento}} />  
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item md={1} xs={12} sm={1}></Grid>
        <Grid item md={10} xs={12} sm={10} style={{textAlign: 'center'}}>
          <span>Ao finalizar, declaro que li atentamente e concordo integralmente com todos os termos e condições estabelecidos no contrato de serviço mencionado acima.</span>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item md={1} xs={12} sm={1}></Grid>
        <Grid item md={10} xs={12} sm={10} style={{textAlign: 'center'}}>
          <span style={{fontSize: '24px'}}><b>Sua assinatura:</b></span>
        </Grid>
      </Grid>
      <Grid container spacing={1} direction="row">
        <Grid item md={2} xs={12} sm={1}></Grid>
        <Grid item md={8} xs={12} sm={10}>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "signatureCanvas"
            }}
          />
        </Grid>
        <Grid item md={2} xs={12} sm={1}></Grid>
      </Grid>
      <Grid container direction="row" spacing={1} className="mg_top_20" style={{marginBottom:20}}>
        <Grid item md={2} xs={false} sm={1}></Grid>
        <Grid item md={2} xs={12} sm={4}>
          <Button 
            fullWidth 
            color="secondary" 
            variant="contained" 
            className="btn_salvar" 
            size="small" 
            onClick={e => props.voltar()}
          >
            Voltar
          </Button>
        </Grid>
        <Grid item md={1} xs={false} sm={1}></Grid>
        <Grid item md={2} xs={12} sm={4}>
          <ColorYellowButton fullWidth variant="contained" className="btn_salvar" size="small" onClick={clear} disabled={props.travaBotao}>Limpar</ColorYellowButton>
        </Grid>
        <Grid item md={1} xs={false} sm={1}></Grid>
        <Grid item md={2} xs={12} sm={4}>
          <Button 
            fullWidth 
            color="primary" 
            variant="contained" 
            className="btn_salvar" 
            size="small" 
            onClick={e => props.assinarContrato(sigCanvas)}
            disabled={props.travaBotao}
          >
            Assinar Contrato
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}