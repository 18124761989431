import React, { Component } from 'react'
import './Funcionario.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormUsuario from '../../components/forms/FormUsuario'
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import ModalErro from './../../components/modals/Erro'
import axios from 'axios'

const cpf = require('node-cpf-cnpj');

const initalState = {
  contrato: {
    paraquem: "FRANQUEADOR",
    selects_unidadetrabalho: {
      franqueador: true,
      franqueado: false,
      unidade: false
    }
  },
  franqueados: [],
  funcionario: {
    id: "",
    dataAdmissao: "",
    dataDemissao: "",
    unidadetrabalho_id: "",
    contrato: {
      departamento: "",
      idDepartamento: "",
      cargo: "",
      idCargo: "",
      funcao: "",
      idFuncao: "",
      dataInicial: "",
      dataFinal: "",
      observacao: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    usuario: {
      login: "",
      senha: "",
      repetirSenha: "",
      usu_perfil_id: ""
    },
    contas: [{
      cb_agencia: "",
      cb_numero: "",
      cb_gerente: "",
      cb_chave_pix: "",
      cb_banco_id: "",
      index: 1,
      banco: {
        banco_id: "",
        banco_nome: "",
        banco_numero: "",
        banco_padraocosif: ""
      }
    }],
    ativo: "",
  },
  funcionarios: {
    list: []
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  perfil: {
    per_id: "",
    per_descricao: ""
  },
  perfils: {
    list: []
  },
  professor: {
    prof_formacao: "",
    disponibilidades: [],
    aulas: []
  },
  disponibilidade: {
    disp_dia_semana: "",
    disp_hora_inicial: "",
    disp_hora_final: ""
  },
  bancos: {
    list: []
  },
  enableUpdate: false,
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  enableDadosContrato: false,
  enableDadosUsuario: false,
  enableDadosDisponibilidade: false,
  enableDadosContaBancaria: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  loadingSalvar: false,
  cabecalhoTabela: [
    { id: 'disp_dia_semana', numeric: false, disablePadding: true, label: 'Dia da Semana' },
    { id: 'disp_hora_inicial', numeric: false, disablePadding: false, label: 'Hora Inicial' },
    { id: 'disp_hora_final', numeric: false, disablePadding: false, label: 'Hora Final' }
  ],
  acoesTabela: ['editar', 'remove'],
  update: false,
  aulasSeleciondas: [],
  aulas: {
    list: []
  },
  nomeAulas: []
}

export default class FuncionarioUpdateUsuario extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    this.setState({
      contrato: {
        paraquem: "FRANQUEADOR",
        selects_unidadetrabalho: {
          franqueador: true,
          franqueado: false,
          unidade: false
        }
      },
      funcionario: {
        id: "",
        dataAdmissao: "",
        dataDemissao: "",
        unidadetrabalho_id: "",
        contrato: {
          departamento: "",
          idDepartamento: "",
          cargo: "",
          idCargo: "",
          funcao: "",
          idFuncao: "",
          dataInicial: "",
          dataFinal: "",
          observacao: ""
        },
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        usuario: {
          login: "",
          senha: "",
          repetirSenha: "",
          usu_perfil_id: ""
        },
        contas: [{
          cb_agencia: "",
          cb_numero: "",
          cb_gerente: "",
          cb_chave_pix: "",
          cb_banco_id: "",
          index: 1,
          banco: {
            banco_id: "",
            banco_nome: "",
            banco_numero: "",
            banco_padraocosif: ""
          }
        }],
        ativo: "",
      },
    })

    const { match: { params } } = this.props;

    try {
      this.setState({ enableUpdate: true })
      const { data: respFuncionario } = await axios.get(`${process.env.REACT_APP_API_URL}/funcionarios/${params.funcionarioId}`, this.getToken())

      const auxEnderecos = respFuncionario.fisica.pessoa.enderecos
      const auxContatos = respFuncionario.fisica.pessoa.telefones
      const auxEmails = respFuncionario.fisica.pessoa.emails
      const auxContas = respFuncionario.fisica.pessoa.contas

      let enderecos = []
      let contatos = []
      let emails = []
      let contas = []

      for (let i = 0; i < auxEnderecos.length; i++) {
        enderecos.push({
          end_id: auxEnderecos[i].end_id,
          cep_id: auxEnderecos[i].end_cep_id,
          rua: auxEnderecos[i].cep.logradouro,
          complemento: auxEnderecos[i].end_complemento,
          numero: auxEnderecos[i].end_numero,
          bairro: auxEnderecos[i].cep.bairro.nome,
          cidade: auxEnderecos[i].cep.bairro.cidade.nome,
          estado: auxEnderecos[i].cep.bairro.cidade.uf.descricao,
          cep: auxEnderecos[i].cep.cep,
          principal: auxEnderecos[i].end_principal,
          tipoEndereco: auxEnderecos[i].end_tipo,
          index: i + 1
        })
      }

      for (let i = 0; i < auxContatos.length; i++) {
        contatos.push({
          tel_id: auxContatos[i].tel_id,
          numero: auxContatos[i].tel_numero,
          tipoContato: auxContatos[i].tel_tipo,
          principal: auxContatos[i].tel_principal,
          index: i + 1
        })
      }

      for (let i = 0; i < auxEmails.length; i++) {
        emails.push({
          ema_id: auxEmails[i].ema_id,
          email: auxEmails[i].ema_email,
          principal: auxEmails[i].ema_principal,
          index: i + 1
        })
      }

      for (let i = 0; i < auxContas.length; i++) {
        contas.push({
          cb_id: auxContas[i].cb_id,
          cb_agencia: auxContas[i].cb_agencia,
          cb_numero: auxContas[i].cb_numero,
          cb_gerente: auxContas[i].cb_gerente ? auxContas[i].cb_gerente : "",
          cb_chave_pix: auxContas[i].cb_chave_pix ? auxContas[i].cb_chave_pix : "",
          cb_banco_id: auxContas[i].cb_banco_id,
          index: i + 1,
          banco: {
            banco_id: auxContas[i].banco.banco_id,
            banco_nome: auxContas[i].banco.banco_nome,
            banco_numero: auxContas[i].banco.banco_numero,
            banco_padraocosif: auxContas[i].banco.banco_padraocosif
          }
        })
      }

      let professor = {
        prof_formacao: "",
        disponibilidades: [],
        aulas: []
      }

      let aulasSeleciondas = []

      if (respFuncionario.professor) {
        professor = {
          prof_id: respFuncionario.professor.prof_id,
          prof_formacao: respFuncionario.professor.prof_formacao,
          disponibilidades: respFuncionario.professor.disponibilidades,
          aulas: respFuncionario.professor.aulas
        }

        aulasSeleciondas = respFuncionario.professor.aulas.map(aula => {
          return {
            aula_id: aula.aula_id,
            aula_nome: aula.aula_nome,
            aula_tipo: aula.aula_tipo,
            aula_tipo_horario: aula.aula_tipo_horario,
            aula_duracao: aula.aula_duracao,
            ativo: aula.aula_ativo
          }
        })
      }

      if (respFuncionario.contrato.unidadetrabalho.unin_tipo === 'FRANQUEADOR') {
        this.setState({
          contrato: {
            paraquem: respFuncionario.contrato.unidadetrabalho.unin_tipo,
            selects_unidadetrabalho: {
              franqueador: true,
              franqueado: false,
              unidade: false
            }
          }
        })
      } else if (respFuncionario.contrato.unidadetrabalho.unin_tipo === 'FRANQUEADO') {
        this.setState({
          contrato: {
            paraquem: respFuncionario.contrato.unidadetrabalho.unin_tipo,
            selects_unidadetrabalho: {
              franqueador: false,
              franqueado: true,
              unidade: false
            }
          }
        })
      } else if (respFuncionario.contrato.unidadetrabalho.unin_tipo === 'UNIDADE') {
        this.setState({
          contrato: {
            paraquem: respFuncionario.contrato.unidadetrabalho.unin_tipo,
            selects_unidadetrabalho: {
              franqueador: false,
              franqueado: false,
              unidade: true
            }
          }
        })
      }

      let paramsPerfil = `visibilidade=${respFuncionario.contrato.unidadetrabalho.unin_tipo}`
      paramsPerfil += `&unidadetrabalho_id=${respFuncionario.contrato.unidadetrabalho.unin_id}`

      const { data: perfils } = await axios.get(`${process.env.REACT_APP_API_URL}/perfis/cadastro/unidadenegocio/?${paramsPerfil}`, this.getToken())

      this.setState({
        funcionario: {
          id: respFuncionario.fun_id,
          dataAdmissao: moment(respFuncionario.fun_data_admissao).format('YYYY-MM-DD'),
          dataDemissao: moment(respFuncionario.fun_data_demissao).format('YYYY-MM-DD'),
          unidadetrabalho_id: respFuncionario.contrato.unidadetrabalho.unin_id,
          contrato: {
            contt_id: respFuncionario.contrato.contt_id,
            departamento: respFuncionario.contrato.funcao.cargo.departamento.dep_descricao,
            idDepartamento: respFuncionario.contrato.funcao.cargo.dep_id,
            cargo: respFuncionario.contrato.funcao.cargo.car_descricao,
            idCargo: respFuncionario.contrato.funcao.cargo.car_id,
            funcao: respFuncionario.contrato.funcao.func_descricao,
            idFuncao: respFuncionario.contrato.funcao.func_id,
            dataInicial: moment(respFuncionario.contrato.contt_datainicio).format('YYYY-MM-DD'),
            dataFinal: moment(respFuncionario.contrato.contt_datafinal).format('YYYY-MM-DD'),
            observacao: respFuncionario.contrato.contt_observacao
          },
          pessoaFisica: {
            nome: respFuncionario.fisica.pesf_nome,
            cpf: cpf.format(respFuncionario.fisica.pesf_cpf),
            rg: respFuncionario.fisica.pesf_rg,
            rg_orgao_emissor: respFuncionario.fisica.pesf_rg_orgao_emissor,
            rg_uf: respFuncionario.fisica.pesf_rg_uf,
            rg_data_emissao: moment(respFuncionario.fisica.pesf_rg_data_emissao).format('YYYY-MM-DD'),
            dataNascimento: moment(respFuncionario.fisica.pesf_datanascimento).format('YYYY-MM-DD')
          },
          enderecos,
          emails,
          contatos,
          ativo: respFuncionario.fun_status,
          contas: contas.length > 0 ? contas : initalState.funcionario.contas,
          usuario: {
            login: respFuncionario.fisica.pessoa.usuario.usu_login,
            senha: "",
            repetirSenha: "",
            usu_perfil_id: respFuncionario.fisica.pessoa.usuario.perfil.per_id,
            usu_id: respFuncionario.fisica.pessoa.usuario.usu_id
          },
        },
        perfil: {
          per_id: respFuncionario.fisica.pessoa.usuario.perfil.per_id,
          per_descricao: respFuncionario.fisica.pessoa.usuario.perfil.per_descricao
        },
        professor,
        perfils: {
          list: perfils
        },
        aulasSeleciondas,
        loading: false
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar o funcionario, tente mais tarde"
      })

      this.backPage()
    }

  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    this.setState({
      contrato: initalState.contrato,
      funcionario: {
        id: "",
        dataAdmissao: "",
        dataDemissao: "",
        unidadetrabalho_id: "",
        contrato: {
          departamento: "",
          idDepartamento: "",
          cargo: "",
          idCargo: "",
          funcao: "",
          idFuncao: "",
          dataInicial: "",
          dataFinal: "",
          observacao: ""
        },
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          rg_orgao_emissor: "",
          rg_uf: "",
          rg_data_emissao: "",
          dataNascimento: ""
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        usuario: {
          login: "",
          senha: "",
          repetirSenha: "",
          usu_perfil_id: ""
        },
        contas: [{
          cb_agencia: "",
          cb_numero: "",
          cb_gerente: "",
          cb_chave_pix: "",
          cb_banco_id: "",
          index: 1,
          banco: {
            banco_id: "",
            banco_nome: "",
            banco_numero: "",
            banco_padraocosif: ""
          }
        }],
        ativo: "",
      }
    })
    setTimeout(() => {
      self.props.history.push("/funcionario");
    }, timeout)
  }

  updateFieldPerfil(event, value) {
    if (!value) return false

    const funcionario = { ...this.state.funcionario }
    funcionario.usuario.usu_perfil_id = value.per_id

    this.setState({
      funcionario,
      perfil: {
        per_id: value.per_id,
        per_descricao: value.per_descricao
      }
    })

  }

  updateFieldUsuario(event) {
    const funcionario = { ...this.state.funcionario }
    funcionario.usuario[event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  async salvar() {
    const { funcionario, professor, aulasSeleciondas } = this.state
    this.setState({ loadingSalvar: true })

    let dados = {}

    let auxProfessor = {}

    if (funcionario.contrato.cargo === "Professor") {
      auxProfessor = {
        ...professor,
        aulas: aulasSeleciondas.map(aula => {
          return {
            aula_id: aula.aula_id
          }
        })
      }
    }

    let emails = []
    let telefones = []
    let enderecos = []

    funcionario.emails.forEach(email => {
      if (email.ema_id) {
        emails.push({
          ema_id: email.ema_id,
          email: email.email,
          principal: email.principal
        })
      } else {
        emails.push({
          email: email.email,
          principal: email.principal
        })
      }
    })

    funcionario.contatos.forEach(telefone => {
      if (telefone.tel_id) {
        telefones.push({
          tel_id: telefone.tel_id,
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      } else {
        telefones.push({
          numero: telefone.numero,
          principal: telefone.principal,
          tipo: telefone.tipoContato
        })
      }
    })

    funcionario.enderecos.forEach(endereco => {
      if (endereco.end_id) {
        enderecos.push({
          end_id: endereco.end_id,
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      } else {
        enderecos.push({
          cep_id: endereco.cep_id,
          numero: endereco.numero,
          complemento: "N/A",
          principal: endereco.principal,
          tipo: endereco.tipoEndereco
        })
      }
    })

    let usuarioAux = {}

    if (funcionario.usuario.senha === "") {
      if (funcionario.usuario.login === "" || funcionario.usuario.usu_perfil_id === "") {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      usuarioAux = {
        login: funcionario.usuario.login,
        usu_perfil_id: funcionario.usuario.usu_perfil_id,
        usu_id: funcionario.usuario.usu_id
      }

    } else {
      if (funcionario.usuario.login === "" || funcionario.usuario.usu_perfil_id === "" || funcionario.usuario.senha === "" || funcionario.usuario.repetirSenha === "") {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio!",
            descricao: "Existem campo obrigatorios vazios."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      if (funcionario.usuario.senha !== funcionario.usuario.repetirSenha) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Senhas não Conferem!",
            descricao: "Por favor verificar as senhas."
          }
        })
        this.setState({ loadingSalvar: false })
        return
      }

      usuarioAux = {
        login: funcionario.usuario.login,
        senha: funcionario.usuario.senha,
        usu_perfil_id: funcionario.usuario.usu_perfil_id,
        usu_id: funcionario.usuario.usu_id
      }
    }

    dados = {
      ativo: true,
      nome: funcionario.pessoaFisica.nome,
      cpf: funcionario.pessoaFisica.cpf,
      rg: funcionario.pessoaFisica.rg,
      rg_orgao_emissor: funcionario.pessoaFisica.rg_orgao_emissor,
      rg_uf: funcionario.pessoaFisica.rg_uf,
      rg_data_emissao: funcionario.pessoaFisica.rg_data_emissao,
      datanascimento: funcionario.pessoaFisica.dataNascimento,
      enderecos,
      emails,
      telefones,
      contt_id: funcionario.contrato.contt_id,
      unidadetrabalho_id: funcionario.unidadetrabalho_id,
      contt_funcao_id: funcionario.contrato.idFuncao,
      fun_data_admissao: funcionario.dataAdmissao,
      datainicio: funcionario.contrato.dataInicial,
      observacao: funcionario.contrato.observacao,
      professor: auxProfessor,
      usuario: usuarioAux
    }

    const contas = funcionario.contas
    let contasAux = []

    contas.forEach(conta => {
      if (conta.cb_agencia !== "" && conta.cb_numero !== "" && conta.cb_banco_id !== "") {

        const aux = {
          cb_id: conta.cb_id ? conta.cb_id : "",
          numero: conta.cb_numero,
          gerente: conta.cb_gerente,
          agencia: conta.cb_agencia,
          chave_pix: conta.cb_chave_pix,
          banco_id: conta.cb_banco_id
        }

        if (!conta.cb_id) {
          delete aux.cb_id
        }

        if (conta.cb_gerente === "") {
          delete aux.gerente
        }

        if (conta.cb_chave_pix === "") {
          delete aux.chave_pix
        }

        contasAux.push(aux)
      }
    });

    if (contasAux.length !== 0) {
      dados.contas = contasAux
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/funcionarios/${funcionario.id}`, dados, this.getToken())

      this.setState({
        modalErro: true,
        erro: {
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        }
      })
      this.backPage()
      return
    } catch ({ response }) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro no Cadastro!",
          descricao: (response.data && response.data.message) ? response.data.message : "Tente mais tarde."
        }
      })
      this.setState({ loadingSalvar: false })
    }
  }

  render() {
    const { funcionario } = this.state
    return (
      <div className="app-menu-closed" id="app">

        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container direction="row">
                <Grid item md={12}>
                  <h3>Dados do Usuário</h3>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => this.updateFieldPerfil(event, value)}
                    freeSolo
                    options={this.state.perfils.list}
                    value={this.state.perfil}
                    getOptionLabel={option => option.per_descricao}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Perfil" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={this.state.perfil.per_descricao} />
                    )}
                  />
                </Grid>
              </Grid>
              <FormUsuario updateField={e => this.updateFieldUsuario(e)} dados={funcionario.usuario} />
              <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
              <br />
              <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}