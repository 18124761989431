import getEndereco from './../../services/api/getEndereco'
import validaCnpj from './../../services/validaCnpj'
import React, { Component } from 'react'
import './Unidade.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, TextField, Button } from '@material-ui/core'

import FormPessoaFisica from '../../components/forms/FormPessoaFisica'
import FormPessoaJuridica from '../../components/forms/FormPessoaJuridica'
import FormEndereco from '../../components/forms/FormEndereco'
import FormEmail from '../../components/forms/FormEmail'
import FormContato from '../../components/forms/FormContato'

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios'
import moment from 'moment';
import { format } from 'cnpj';

const cpf = require('node-cpf-cnpj');
const cnpj = require('node-cpf-cnpj')

const initalState = {
  unidade: {
    id: "",
    pes_id: "",
    descricao: "",
    ativo: "",
    tipoPessoa: "FISICA",
    franqueado_id: "",
    unidadePrincipal: true,
    importarDadosFuncionario: true,
    class_tipo: "",
    pessoaFisica: {
      pesf_id: "",
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    pessoaJuridica: {
      pesj_id: "",
      razaoSocial: "",
      nomeFantasia: "",
      cnpj: "",
      inscricaoEstadual: "",
      dataFundacao: ""
    },
    enderecos: [{
      cep_id: "",
      rua: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: "1"
    }],
    emails: [{
      email: "",
      principal: true,
      index: "1"
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: "1"
    }]
  },
  unidades: {
    list: []
  },
  franqueados: {
    list: []
  },
  funcionario: {
    id: "",
    dataAdmissao: "",
    dataDemissao: "",
    contrato: {
      cargo: "",
      dataInicial: "",
      dataFinal: "",
      observação: ""
    },
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      rg_orgao_emissor: "",
      rg_uf: "",
      rg_data_emissao: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: "",
      tipoEndereco: "RESIDENCIAL",
      index: "1"
    }],
    emails: [{
      email: "",
      principal: "",
      index: "1"
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: "",
      index: "1"
    }],
    usuario: {
      login: "",
      senha: ""
    },
    ativo: ""
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  funcaosFiltrado: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  disableEndereco: true,
  enableDadosPessoa: true,
  enableDadosPessoaFisica: true,
  enableDadosPessoaJuridica: false,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  enableDadosFuncionario: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  disableEnderecoFuncionario: true,
  enableDadosPessoaFuncionario: true,
  enableDadosEnderecoFuncionario: false,
  enableDadosEmailFuncionario: false,
  enableDadosContatoFuncionario: false,
  enableDadosFuncionarioFuncionario: false,
  enableDadosContratoFuncionario: false,
  enableDadosUsuarioFuncionario: false,
  loading: true,
  loadingSalvar: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function formatarCEP(str) {
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  return str.replace(re, "$1.$2-$3");
}

function formatarTelefone(str) {
  if (str.length === 11) {
    return str.replace(/(\d{2})?(\d{4})?(\d{5})/, "($1) $2-$3")
  } else if (str.length === 10) {
    return str.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3")
  }
}

export default class CadastroFranqueado extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'unidade')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_alterar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    try {
      const { data: franqueados } = await axios.get(`${process.env.REACT_APP_API_URL}/franqueados`, this.getToken())

      const franqueadosFisica = franqueados.filter(u => u.pessoa.pes_tipo === "FISICA")
      const franqueadosJuridica = franqueados.filter(u => u.pessoa.pes_tipo === "JURIDICA")

      franqueadosFisica.forEach(pessoaFisica => {
        franqueados.push({
          _id: pessoaFisica.unin_id,
          franqueado: pessoaFisica.pessoa.fisica.pesf_nome,
        })
      });

      franqueadosJuridica.forEach(pessoaJuridica => {
        franqueados.push({
          _id: pessoaJuridica.unin_id,
          franqueado: pessoaJuridica.pessoa.juridica.pesj_nomefantasia,
        })
      });

      const { data: departamentos } = await axios.post(`${process.env.REACT_APP_API_URL}/departamentos/list`, {}, this.getToken())

      this.setState({
        franqueados: {
          list: franqueados
        },
        departamentos: {
          list: departamentos.filter(u => u.dep_ativo === true)
        },
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar a unidade, tente mais tarde"
      })

      this.backPage()
    }

    try {

      const { match: { params } } = this.props;

      const { data: unidade } = await axios.get(`${process.env.REACT_APP_API_URL}/unidades/${params.unidadeId}`, this.getToken())

      let fisica = {}
      let juridica = {}

      if (unidade.pessoa.pes_tipo === "FISICA") {
        fisica = {
          pesf_id: unidade.pessoa.fisica.pesf_id,
          nome: unidade.pessoa.fisica.pesf_nome,
          cpf: cpf.format(unidade.pessoa.fisica.pesf_cpf),
          rg: unidade.pessoa.fisica.pesf_rg,
          rg_orgao_emissor: unidade.pessoa.fisica.pesf_rg_orgao_emissor,
          rg_uf: unidade.pessoa.fisica.pesf_rg_uf,
          rg_data_emissao: moment(unidade.pessoa.fisica.pesf_rg_data_emissao).format('YYYY-MM-DD'),
          dataNascimento: moment(unidade.pessoa.fisica.pesf_datanascimento).format('YYYY-MM-DD')
        }

        this.setState({
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false,
        })
      } else {
        juridica = {
          pesj_id: unidade.pessoa.juridica.pesj_id,
          razaoSocial: unidade.pessoa.juridica.pesj_razaosocial,
          nomeFantasia: unidade.pessoa.juridica.pesj_nomefantasia,
          cnpj: format(unidade.pessoa.juridica.pesj_cnpj),
          inscricaoEstadual: unidade.pessoa.juridica.pesj_inscricaoestadual,
          dataFundacao:unidade.pessoa.juridica.pesj_datafundacao ? moment(unidade.pessoa.juridica.pesj_datafundacao).format('YYYY-MM-DD') : ''
        }

        this.setState({
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true,
        })
      }

      let enderecos = []
      let contatos = []
      let emails = []

      for (let i = 0; i < unidade.pessoa.enderecos.length; i++) {
        enderecos.push({
          end_id: unidade.pessoa.enderecos[i].end_id,
          cep_id: unidade.pessoa.enderecos[i].end_cep_id,
          rua: unidade.pessoa.enderecos[i].end_logradouro,
          numero: unidade.pessoa.enderecos[i].end_numero,
          complemento: unidade.pessoa.enderecos[i].complemento,
          bairro: unidade.pessoa.enderecos[i].end_bairro,
          cidade: unidade.pessoa.enderecos[i].end_cidade,
          estado: unidade.pessoa.enderecos[i].end_estado,
          cep: formatarCEP(unidade.pessoa.enderecos[i].end_cep),
          principal: unidade.pessoa.enderecos[i].end_principal,
          tipoEndereco: unidade.pessoa.enderecos[i].end_tipo,
          index: i + 1
        })
      }

      for (let i = 0; i < unidade.pessoa.telefones.length; i++) {
        contatos.push({
          tel_id: unidade.pessoa.telefones[i].tel_id,
          numero: unidade.pessoa.telefones[i].tel_numero,
          tipoContato: unidade.pessoa.telefones[i].tel_tipo,
          principal: unidade.pessoa.telefones[i].tel_principal,
          index: i + 1
        })
      }

      for (let i = 0; i < unidade.pessoa.emails.length; i++) {
        emails.push({
          ema_id: unidade.pessoa.emails[i].ema_id,
          email: unidade.pessoa.emails[i].ema_email,
          principal: unidade.pessoa.emails[i].ema_principal,
          index: i + 1
        })
      }

      const unidadeAux = {
        id: unidade.unin_id,
        descricao: unidade.unin_descricao,
        ativo: unidade.unin_status,
        tipoPessoa: unidade.pessoa.pes_tipo,
        franqueado_id: unidade.unin_superior_id,
        pessoa_id: unidade.unin_pessoa_id,
        pessoaFisica: fisica,
        pessoaJuridica: juridica,
        enderecos,
        emails,
        contatos
      }

      this.setState({
        unidade: unidadeAux,
        loading: false
      })


    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar a unidade, tente mais tarde"
      })

      this.backPage()
    }

  }

  updateFieldPessoaFisica(event) {
    const unidade = { ...this.state.unidade }
    unidade.pessoaFisica[event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldPessoaJuridica(event) {
    const unidade = { ...this.state.unidade }
    unidade.pessoaJuridica[event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldEndereco(event, index) {
    const unidade = { ...this.state.unidade }
    unidade.enderecos[index - 1][event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldEmail(event, index) {
    const unidade = { ...this.state.unidade }
    unidade.emails[index - 1][event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldContato(event, index) {
    const unidade = { ...this.state.unidade }
    unidade.contatos[index - 1][event.target.name] = event.target.value
    this.setState({ unidade })
  }

  formataTelefone(event, index) {
    let telefoneSemFormatacao = event.target.value.replace('(', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace(') ', '')
    telefoneSemFormatacao = telefoneSemFormatacao.replace('-', '')

    const unidade = { ...this.state.unidade }
    unidade.contatos[index - 1].numero = formatarTelefone(telefoneSemFormatacao)
    this.setState({ unidade })
  }

  updateFieldUsuario(event) {
    const funcionario = { ...this.state.funcionario }
    funcionario.usuario[event.target.name] = event.target.value
    this.setState({ funcionario })
  }

  updateField(event) {

    if (event.target.name === "tipoPessoa") {
      if (event.target.value === "FISICA") {
        this.setState({
          enableDadosPessoaFisica: true,
          enableDadosPessoaJuridica: false
        })
      } else {
        this.setState({
          enableDadosPessoaFisica: false,
          enableDadosPessoaJuridica: true
        })
      }
    }

    const unidade = { ...this.state.unidade }
    unidade[event.target.name] = event.target.value
    this.setState({ unidade })
  }

  updateFieldUnidadePrincipal() {
    const unidade = { ...this.state.unidade }
    unidade.unidadePrincipal = !unidade.unidadePrincipal
    this.setState({ unidade })
  }

  updateFieldEnderecoPrincipal(event, index) {
    const unidade = { ...this.state.unidade }
    const indexAux = unidade.enderecos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      unidade.enderecos[indexAux].principal = false
    }

    unidade.enderecos[index - 1].principal = !unidade.enderecos[index - 1].principal
    this.setState({ unidade })
  }

  updateFieldContatoPrincipal(event, index) {
    const unidade = { ...this.state.unidade }
    const indexAux = unidade.contatos.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      unidade.contatos[indexAux].principal = false
    }

    unidade.contatos[index - 1].principal = !unidade.contatos[index - 1].principal
    this.setState({ unidade })
  }

  updateFieldEmailPrincipal(event, index) {
    const unidade = { ...this.state.unidade }
    const indexAux = unidade.emails.findIndex(x => x.principal === true)

    if (indexAux !== -1) {
      unidade.emails[indexAux].principal = false
    }

    unidade.emails[index - 1].principal = !unidade.emails[index - 1].principal

    this.setState({ unidade })
  }

  updateFieldImportarDadosFuncionario(event) {
    const unidade = { ...this.state.unidade }
    unidade.importarDadosFuncionario = !unidade.importarDadosFuncionario
    this.setState({ unidade })
  }

  async validarCpfCnpj(event) {

    let cpf_cnpj = event.target.value
    const unidade = this.state.unidade

    if (unidade.tipoPessoa === "FISICA") {
      cpf_cnpj = cpf.format(cpf_cnpj)

      if (!cpf.isValid(cpf_cnpj)) {

        unidade.pessoaFisica.cpf = ""
        this.setState({ unidade })

        const erro = {
          titulo: "CPF Invalido!",
          descricao: "Por favor corrija o campo CPF"
        }
        this.setState({
          modalErro: true,
          erro
        })
      } else {
        unidade.pessoaFisica.cpf = cpf_cnpj
        this.setState({ unidade })
      }
    } else {
      const validadeCnpj = await validaCnpj(cpf_cnpj)
      cpf_cnpj = format(cpf_cnpj)
      if (validadeCnpj) {
        unidade.pessoaJuridica.cnpj = cpf_cnpj
        this.setState({ unidade })

      } else {
        unidade.pessoaJuridica.cnpj = ""
        this.setState({ unidade })
        const erro = {
          titulo: "CNPJ Invalido!",
          descricao: "Por favor corrija o campo CNPJ"
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }

    // if (cpf_cnpj.length <= 11) {

    // } else {

    // }
  }

  async pegaEndereco(event, index) {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const unidade = { ...this.state.unidade }
      const enderecos = unidade.enderecos
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: enderecos[index - 1].numero,
        complemento: enderecos[index - 1].complemento,
        cep: formatarCEP(enderecos[index - 1].cep),
        principal: enderecos[index - 1].principal,
        tipoEndereco: enderecos[index - 1].tipoEndereco,
        index: enderecos[index - 1].index
      }

      unidade.enderecos[index - 1] = endereco
      this.setState({ unidade })

      try {
        const resp = await getEndereco(event.target.value)

        endereco = {
          cep_id: resp.id,
          complemento: enderecos[index - 1].complemento,
          rua: resp.logradouro,
          numero: enderecos[index - 1].numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(enderecos[index - 1].cep),
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        unidade.enderecos[index - 1] = endereco
        this.setState({ unidade })

        this.setState({
          disableEndereco: true
        })
      } catch (error) {
        console.log(error)

        endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: enderecos[index - 1].numero,
          cep: "",
          complemento: enderecos[index - 1].complemento,
          principal: enderecos[index - 1].principal,
          tipoEndereco: enderecos[index - 1].tipoEndereco,
          index: enderecos[index - 1].index
        }

        unidade.enderecos[index - 1] = endereco
        this.setState({ unidade })

        this.setState({
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        })

      }
    }
  }

  addNovoEndereco() {
    const unidade = { ...this.state.unidade }
    const enderecos = unidade.enderecos

    const index = enderecos.length + 1

    enderecos.push({
      rua: "",
      numero: "",
      bairro: "",
      complemento: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: false,
      tipoEndereco: "RESIDENCIAL",
      index
    })

    unidade.enderecos = enderecos
    this.setState({ unidade })
  }

  addNovoContato() {
    const unidade = { ...this.state.unidade }
    const contatos = unidade.contatos

    const index = contatos.length + 1

    contatos.push({
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: false,
      index
    })

    unidade.contatos = contatos
    this.setState({ unidade })
  }

  addNovoEmail() {
    const unidade = { ...this.state.unidade }
    const emails = unidade.emails

    const index = emails.length + 1

    emails.push({
      email: "",
      principal: false,
      index
    })

    unidade.emails = emails
    this.setState({ unidade })
  }

  proximo(atual, prox) {
    const { unidade, funcionario } = this.state
    if (atual === "enableDadosPessoa" && prox === "enableDadosEndereco") {
      let valido = true
      if (unidade.descricao === "") {
        valido = false
      }

      if (unidade.tipoPessoa === "FISICA") {
        if (unidade.pessoaFisica.nome === "") {
          valido = false
        }
        if (unidade.pessoaFisica.cpf === "") {
          valido = false
        }
        if (unidade.pessoaFisica.rg === "") {
          valido = false
        }
        if (unidade.pessoaFisica.rg_orgao_emissor === "") {
          valido = false
        }
        if (unidade.pessoaFisica.rg_uf === "") {
          valido = false
        }
        if (unidade.pessoaFisica.rg_data_emissao === "") {
          valido = false
        }
        if (unidade.pessoaFisica.dataNascimento === "") {
          valido = false
        }

      } else {
        if (unidade.pessoaJuridica.razaoSocial === "") {
          valido = false
        }
        if (unidade.pessoaJuridica.nomeFantasia === "") {
          valido = false
        }
        if (unidade.pessoaJuridica.cnpj === "") {
          valido = false
        }
        if (unidade.pessoaJuridica.inscricaoEstadual === "") {
          valido = false
        }
        if (unidade.pessoaJuridica.dataFundacao === "") {
          valido = false
        }
      }

      if (valido) {
        this.setState({
          enableDadosPessoa: false,
          enableDadosEndereco: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosEndereco" && prox === "enableDadosContato") {
      let valido = true
      unidade.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEndereco: false,
          enableDadosContato: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosContato" && prox === "enableDadosEmail") {
      let valido = true
      unidade.contatos.forEach(contato => {
        if (contato.numero === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosContato: false,
          enableDadosEmail: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosEmail" && prox === "enableDadosFuncionario") {
      let valido = true
      unidade.emails.forEach(email => {
        if (email.email === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEmail: false,
          enableDadosFuncionario: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }

    if (atual === "enableDadosPessoaFuncionario" && prox === "enableDadosEnderecoFuncionario") {
      let valido = true

      if (funcionario.pessoaFisica.nome === "") {
        valido = false
      }
      if (funcionario.pessoaFisica.cpf === "") {
        valido = false
      }
      if (funcionario.pessoaFisica.rg === "") {
        valido = false
      }
      if (funcionario.pessoaFisica.dataNascimento === "") {
        valido = false
      }

      if (valido) {
        this.setState({
          enableDadosPessoaFuncionario: false,
          enableDadosEnderecoFuncionario: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }

    } else if (atual === "enableDadosEnderecoFuncionario" && prox === "enableDadosContatoFuncionario") {
      let valido = true
      funcionario.enderecos.forEach(endereco => {
        if (endereco.cep === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEnderecoFuncionario: false,
          enableDadosContatoFuncionario: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosContatoFuncionario" && prox === "enableDadosEmailFuncionario") {
      let valido = true
      funcionario.contatos.forEach(contato => {
        if (contato.numero === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosContato: false,
          enableDadosEmailFuncionario: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosEmailFuncionario" && prox === "enableDadosFuncionarioFuncionario") {
      let valido = true
      funcionario.emails.forEach(email => {
        if (email.email === "") {
          valido = false
        }
      });
      if (valido) {
        this.setState({
          enableDadosEmailFuncionario: false,
          enableDadosFuncionarioFuncionario: true
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    } else if (atual === "enableDadosFuncionarioFuncionario" && prox === "enableDadosContratoFuncionario") {
      let valido = true
      if (funcionario.dataAdmissao === "") {
        valido = false
      }
      if (valido) {
        this.setState({
          enableDadosFuncionarioFuncionario: false,
          enableDadosContratoFuncionario: true,
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }

    } else if (atual === "enableDadosContratoFuncionario" && prox === "enableDadosUsuarioFuncionario") {
      let valido = true

      if (funcionario.funcao === "") {
        valido = false
      }
      if (funcionario.dataInicial === "") {
        valido = false
      }

      if (valido) {
        this.setState({
          enableDadosContratoFuncionario: false,
          enableDadosUsuarioFuncionario: true,
        })
      } else {
        const erro = {
          titulo: "Campo Vazio!",
          descricao: "Existem campo obrigatorios vazios."
        }
        this.setState({
          modalErro: true,
          erro
        })
      }
    }
  }

  voltar(atual, anterior) {
    if (anterior === "enableDadosPessoa" && atual === "enableDadosEndereco") {
      this.setState({
        enableDadosPessoa: true,
        enableDadosEndereco: false
      })
    } else if (anterior === "enableDadosEndereco" && atual === "enableDadosContato") {
      this.setState({
        enableDadosEndereco: true,
        enableDadosContato: false
      })
    } else if (anterior === "enableDadosContato" && atual === "enableDadosEmail") {
      this.setState({
        enableDadosContato: true,
        enableDadosEmail: false
      })
    } else if (anterior === "enableDadosEmail" && atual === "enableDadosFuncionario") {
      this.setState({
        enableDadosEmail: true,
        enableDadosFuncionario: false
      })
    }

    if (anterior === "enableDadosPessoaFuncionario" && atual === "enableDadosEnderecoFuncionario") {
      this.setState({
        enableDadosPessoaFuncionario: true,
        enableDadosEnderecoFuncionario: false
      })
    } else if (anterior === "enableDadosEnderecoFuncionario" && atual === "enableDadosContatoFuncionario") {
      this.setState({
        enableDadosEnderecoFuncionario: true,
        enableDadosContatoFuncionario: false
      })
    } else if (anterior === "enableDadosContatoFuncionario" && atual === "enableDadosEmailFuncionario") {
      this.setState({
        enableDadosContatoFuncionario: true,
        enableDadosEmailFuncionario: false
      })
    } else if (anterior === "enableDadosEmailFuncionario" && atual === "enableDadosFuncionarioFuncionario") {
      this.setState({
        enableDadosEmailFuncionario: true,
        enableDadosFuncionarioFuncionario: false,
      })
    } else if (anterior === "enableDadosFuncionarioFuncionario" && atual === "enableDadosContratoFuncionario") {
      this.setState({
        enableDadosFuncionarioFuncionario: true,
        enableDadosContratoFuncionario: false,
      })
    } else if (anterior === "enableDadosContratoFuncionario" && atual === "enableDadosUsuario") {
      this.setState({
        enableDadosFuncionarioFuncionario: true,
        enableDadosContratoFuncionario: false,
      })
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this

    this.setState({
      unidade: initalState.unidade,
      loadingSalvar: false,
    })

    setTimeout(() => {
      self.props.history.push("/unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  removeEnderecoUnidade(index) {
    const unidade = { ...this.state.unidade }
    const enderecos = unidade.enderecos
    const aux = enderecos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    unidade.enderecos = aux
    this.setState({ unidade })
  }

  removeContatoUnidade(index) {
    const unidade = { ...this.state.unidade }
    const contatos = unidade.contatos
    const aux = contatos

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    unidade.contatos = aux
    this.setState({ unidade })
  }

  removeEmailUnidade(index) {
    const unidade = { ...this.state.unidade }
    const emails = unidade.emails
    const aux = emails

    if (aux.length > 1) {
      aux.splice(index - 1, 1)
    } else {
      return false
    }

    for (let i = 0; i < aux.length; i++) {
      aux[i].index = i + 1
    }

    unidade.emails = aux
    this.setState({ unidade })
  }

  async salvar() {
    this.setState({
      loadingSalvar: true
    })
    const { unidade } = this.state
    let valido = true

    unidade.emails.forEach(email => {
      if (email.email === "") {
        valido = false
      }
    });

    if (valido) {

      let fisica = {}
      let juridica = {}

      if (unidade.tipoPessoa === "FISICA") {
        fisica = {
          pessoa_id: unidade.pessoa_id,
          pesf_id: unidade.pessoaFisica.pesf_id,
          nome: unidade.pessoaFisica.nome,
          cpf: cpf.strip(unidade.pessoaFisica.cpf),
          rg: unidade.pessoaFisica.rg,
          rg_orgao_emissor: unidade.pessoaFisica.rg_orgao_emissor,
          rg_uf: unidade.pessoaFisica.rg_uf,
          rg_data_emissao: unidade.pessoaFisica.rg_data_emissao,
          datanascimento: unidade.pessoaFisica.dataNascimento,
          class_tipo: unidade.class_tipo,
        }
      } else {
        juridica = {
          pessoa_id: unidade.pessoa_id,
          pesj_id: unidade.pessoaJuridica.pesj_id,
          razaosocial: unidade.pessoaJuridica.razaoSocial,
          nomefantasia: unidade.pessoaJuridica.nomeFantasia,
          inscricaoestadual: unidade.pessoaJuridica.inscricaoEstadual,
          datafundacao: unidade.pessoaJuridica.dataFundacao,
          cnpj: cnpj.strip(unidade.pessoaJuridica.cnpj),
          class_tipo: unidade.class_tipo,
        }
      }

      let emails = []
      let telefones = []
      let enderecos = []

      unidade.emails.forEach(email => {
        if (email.ema_id) {
          emails.push({
            ema_id: email.ema_id,
            email: email.email,
            principal: email.principal
          })
        } else {
          emails.push({
            email: email.email,
            principal: email.principal
          })
        }
      })

      unidade.contatos.forEach(telefone => {
        if (telefone.tel_id) {
          telefones.push({
            tel_id: telefone.tel_id,
            numero: telefone.numero,
            principal: telefone.principal,
            tipo: telefone.tipoContato
          })
        } else {
          telefones.push({
            numero: telefone.numero,
            principal: telefone.principal,
            tipo: telefone.tipoContato
          })
        }
      })

      unidade.enderecos.forEach(endereco => {
        if (endereco.end_id) {
          enderecos.push({
            end_id: endereco.end_id,
            cep_id: endereco.cep_id,
            numero: endereco.numero,
            complemento: "N/A",
            rua: endereco.rua,
            bairro: endereco.bairro,
            cidade: endereco.cidade,
            estado: endereco.estado,
            cep: endereco.cep,
            principal: endereco.principal,
            tipo: endereco.tipoEndereco
          })
        } else {
          enderecos.push({
            cep_id: endereco.cep_id,
            numero: endereco.numero,
            complemento: "N/A",
            rua: endereco.rua,
            bairro: endereco.bairro,
            cidade: endereco.cidade,
            estado: endereco.estado,
            cep: endereco.cep,
            principal: endereco.principal,
            tipo: endereco.tipoEndereco
          })
        }
      })

      const dados = {
        franqueado_id: unidade.franqueado_id,
        unidade: {
          uni_id: unidade.id,
          descricao: unidade.descricao,
          pessoa_id: unidade.pessoa_id,
          tipo: unidade.tipoPessoa,
          juridica,
          fisica,
          emails,
          telefones,
          enderecos
        }
      }

      try {

        await axios.put(`${process.env.REACT_APP_API_URL}/unidades/${unidade.id}`, dados, this.getToken())

        this.setState({
          unidade: {
            id: "",
            descricao: "",
            ativo: true,
            tipoPessoa: "FISICA",
            franqueado_id: "",
            unidadePrincipal: true,
            importarDadosFuncionario: true,
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            pessoaJuridica: {
              razaoSocial: "",
              nomeFantasia: "",
              cnpj: "",
              inscricaoEstadual: "",
              dataFundacao: ""
            },
            enderecos: [{
              cep_id: "",
              rua: "",
              numero: "",
              complemento: "",
              bairro: "",
              cidade: "",
              estado: "",
              cep: "",
              principal: true,
              tipoEndereco: "RESIDENCIAL",
              index: "1"
            }],
            emails: [{
              email: "",
              principal: true,
              index: "1"
            }],
            contatos: [{
              numero: "",
              tipoContato: "RESIDENCIAL",
              principal: true,
              index: "1"
            }]
          },
          funcionario: {
            id: "",
            dataAdmissao: "",
            dataDemissao: "",
            contrato: {
              departamento: "",
              idDepartamento: "",
              cargo: "",
              idCargo: "",
              funcao: "",
              idFuncao: "",
              dataInicial: "",
              dataFinal: "",
              observacao: ""
            },
            pessoaFisica: {
              nome: "",
              cpf: "",
              rg: "",
              dataNascimento: ""
            },
            enderecos: [{
              rua: "",
              complemento: "",
              numero: "",
              bairro: "",
              cidade: "",
              estado: "",
              cep: "",
              principal: true,
              tipoEndereco: "RESIDENCIAL",
              index: 1
            }],
            emails: [{
              email: "",
              principal: true,
              index: 1
            }],
            contatos: [{
              numero: "",
              tipoContato: "RESIDENCIAL",
              principal: true,
              index: 1
            }],
            usuario: {
              login: "",
              senha: "",
              repetirSenha: "",
              usu_perfil_id: ""
            },
            ativo: "",
          }
        })

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Atualização realizada com sucesso.'
        })

        this.backPage()

      } catch ({ response }) {
        if (response.status === 400) {
          this.handleOpenDialog({
            titulo: 'Ops...',
            descricao: response.data.message
          })
          return
        }

        this.handleOpenDialog({})
      }

    } else {
      const erro = {
        titulo: "Campo Vazio!",
        descricao: "Existem campo obrigatorios vazios."
      }
      this.setState({
        modalErro: true,
        erro
      })
    }
  }

  render() {
    const { unidade } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11} xs={10} sm={10}>
                  <h2 className="titulo">Atualizar Unidade</h2>
                </Grid>
                <Grid item md={1} xs={2} sm={2}>
                  {this.state.loadingSalvar &&
                    <CircularProgress />
                  }
                </Grid>
              </Grid>
              {this.state.enableDadosPessoa &&
                <div className="formFranqueado">
                  <Grid container spacing={1} direction="row" className="mg_top_20">
                    <Grid item md={4} xs={12} sm={6}>
                      <TextField className="input" label="Descrição Unidade" variant="outlined" size="small" name="descricao" value={this.state.unidade.descricao} onChange={(e) => this.updateField(e)} />
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    className="mg_top_20"
                  >
                    <Grid item md={4} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Tipo Pessoa*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="tipoPessoa"
                        value={unidade.tipoPessoa}
                        onChangeCapture={(e) => this.updateField(e)}
                      >
                        <option value="FISICA">
                          Pessoa Física
											</option>
                        <option value="JURIDICA">
                          Pessoa Júridica
											</option>
                      </TextField>
                    </Grid>
                  </Grid>
                  {this.state.enableDadosPessoaFisica &&
                    <FormPessoaFisica validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaFisica(e)} dados={unidade.pessoaFisica} />
                  }

                  {this.state.enableDadosPessoaJuridica &&
                    <FormPessoaJuridica validarCpfCnpj={e => this.validarCpfCnpj(e)} updateField={e => this.updateFieldPessoaJuridica(e)} dados={unidade.pessoaJuridica} />
                  }
                  <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
                    <Grid item md={9}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosPessoa", "enableDadosEndereco")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEndereco &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Endereço</h3>
                    </Grid>
                  </Grid>
                  {unidade.enderecos.map(endereco => {
                    return (
                      <div key={endereco.index} className="cardEndereco">
                        <FormEndereco removerItem={(index) => this.removeEnderecoUnidade(index)} disableEndereco={this.state.disableEndereco} pegaEndereco={e => this.pegaEndereco(e, endereco.index)} updateField={e => this.updateFieldEndereco(e, endereco.index)} updateFieldPrincipal={e => this.updateFieldEnderecoPrincipal(e, endereco.index)} dados={endereco} />
                      </div>
                    )
                  })}
                  <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoEndereco()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Endereço </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEndereco", "enableDadosPessoa")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosEndereco", "enableDadosContato")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosContato &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de Contato</h3>
                    </Grid>
                  </Grid>
                  {unidade.contatos.map(contato => {
                    return (
                      <div key={contato.index} className="cardEndereco">
                        <FormContato formataTelefone={e => this.formataTelefone(e, contato.index)} removerItem={(index) => this.removeContatoUnidade(index)} updateField={e => this.updateFieldContato(e, contato.index)} dados={contato} updateFieldPrincipal={e => this.updateFieldContatoPrincipal(e, contato.index)} />
                      </div>
                    )
                  })}
                  <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoContato()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo Contato </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosContato", "enableDadosEndereco")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.proximo("enableDadosContato", "enableDadosEmail")} color="primary" variant="contained" size="small" endIcon={<ArrowForwardIcon />}>Proximo</Button>
                    </Grid>
                  </Grid>
                </div>
              }

              {this.state.enableDadosEmail &&
                <div className="formFranqueado">
                  <Grid container direction="row">
                    <Grid item md={12}>
                      <h3>Dados de E-mail</h3>
                    </Grid>
                  </Grid>
                  {unidade.emails.map(email => {
                    return (
                      <div key={email.index} className="cardEndereco">
                        <FormEmail removerItem={(index) => this.removeEmailUnidade(index)} updateField={e => this.updateFieldEmail(e, email.index)} dados={email} updateFieldPrincipal={e => this.updateFieldEmailPrincipal(e, email.index)} />
                      </div>
                    )
                  })}
                  <Grid className="mg_top_10" container direction="row" alignItems="flex-end" spacing={1}>
                    <Grid item md={4}>
                      <Button fullWidth onClick={() => this.addNovoEmail()} color="secondary" variant="contained" size="small" startIcon={<AddIcon />}>Adicionar Novo E-mail </Button>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                      <Button fullWidth onClick={() => this.voltar("enableDadosEmail", "enableDadosContato")} color="primary" variant="contained" size="small" startIcon={<ArrowBackIcon />}>Voltar</Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button fullWidth color="primary" variant="contained" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                    </Grid>
                  </Grid>
                </div>
              }
            </div>
          }
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}