import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import ModalParcelasUnidade from './../../components/modals/ParcelasUnidade'
import ModalCarteiraUnidade from './../../components/modals/CarteiraUnidade'
import Chart from "react-google-charts";
import real from '../../services/real'
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment'
import { saveAs } from 'file-saver';

const initalState = {
  filtro: {
    relatorio: "",
    data: '',
    unin_id: ''
  },
  filtroUnidade:{
    relatorio: "",
    data: '',
    unin_id: ''
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm:{
        perm_visualizar: false
      }
    }]
  },
  loading: false,
  loadingDownload: false,
  urlView: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
	funcionarios: {
		list: []
	},
	funcoes:{
		list: []
	},
  subCabecalhoTabela: [],
  subAcoesTabela: [],
  subRelatorios: {
    list: []
  },
  subUrlView: '',
  modalParcelasUnidade: false,
  filtroFuncionario: {
    fun_id: "",
  },
  filtroFuncao: {
    func_id: ""
  },
	dataChart: [],
	unidadeSelecionada: {
		unin_id: '',
		unin_numero: '',
		unin_descricao: '',
		pontos_matricula : 0,
		pontos_rematricula : 0,
		pontos_prorrogacao : 0,
		md : 0,
		total: 0
	},
	subDataChartGeral: [],
	subDataChartItem: [],
  minhaUnidade: {
    unin_id: '',
    unin_tipo: ''
  },
  qtdRowColaborador: 0,
  porCargo: [],
  qtdRowCargo: 0,
  tab: 0,
  mostrarRelatorio: false,
  listaPontuacao: [],
  cabecalhoTabelaPontosCurso: [
    { id: 'item', numeric: false, disablePadding: true, label: 'Item' },
    { id: 'ponto_matricula', numeric: true, disablePadding: true, label: 'Matricula' },
    { id: 'ponto_rematricula', numeric: true, disablePadding: false, label: 'Rematricula' },
    { id: 'pronto_prorrogacao', numeric: true, disablePadding: false, label: 'Prorrogação' }
  ],
  acoesTabelaPontosCurso: [],
  cabecalhoTabelaPontosProduto: [
    { id: 'item', numeric: false, disablePadding: true, label: 'Item' },
    { id: 'ponto_material', numeric: true, disablePadding: true, label: 'Matricula' }
  ],
  acoesTabelaPontosProduto: [],
  listaPontuacaoProduto: [],
  contas: {
    list: []
  },
  relatoriosFiltrado:{
    list: []
  },
  filtroParcelas: {
    titulo: '',
    situacao: '',
    data: ''
  },
  formasPagamento: {
    list: []
  },
  loadingFiltroParcelas: false,
  modalCarteiraUnidade: false,
  loadingFiltroCarteriaUnidade: false,
  recebidos_mes: 100,
  opcoesFiltro: [],
  tituloCarteira: '',
  total: 100,
  loadingDownloadParcelas: false,
  valores: {
    dentro_carteira:{
      total: 0,
      recebidos: 0,
      anteriores: 0,
      atual: 0
    },
    fora_carteira:{
      atrasadas_recebidas_neste_mes: 0,
      recebidas_carteiras_futuras: 0,
      alunos_inativo: 0,
      inicio_carteira: 0
    }
  }
}

const relatoriosAux = {
  list: []
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const maskData = value => {
  return value.replace(/\D/g, "").replace(/^(\d{2})(\d{4})+?$/, "$1/$2");
};

export default class FlyHigh extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    
    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'rel-ranking')

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    const dados = JSON.parse(localStorage.getItem('grafico_carteira_mensalidade'))

    if(dados){
      this.setState({
        filtro: dados.filtro,
        tab: dados.tab
      })

      this.setState({
        dataChart: dados.dataChart,
        relatorios: {
          list: dados.por_unidades
        },
        relatoriosFiltrado: {
          list: dados.por_unidades
        },
        mostrarRelatorio: true
      })
     
    }

    this.setState({
      relatorios_options: {
        list: permissoes.map( value => {
          return {
            tela_descricao: value.tela.tela_descricao,
            tela_slug: value.tela.tela_slug,
            perm: {
              perm_visualizar: value.perm_visualizar
            }
          }
        })
      },
      minhaUnidade: pessoa.fisica.funcionario.contrato.unidadetrabalho
    })
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = maskData(event.target.value)

    this.setState({ filtro })
  }

  async filtrar() {
    const filtro = this.state.filtro

    if (filtro.data === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data'
      })
      this.setState({
        loading: false
      })
      return
    }

    const aux = filtro.data.split('/')

    if( aux.length !== 2){
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data corretamente'
      })
      this.setState({
        loading: false
      })
      return
    }

    const mes = parseInt(aux[0])
    const ano = parseInt(aux[1])

    if( !mes || mes < 1 || mes > 12){
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data corretamente'
      })
      this.setState({
        loading: false
      })
      return
    }

    if( !ano || ano < 1990){
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data corretamente'
      })
      this.setState({
        loading: false
      })
      return
    }

    this.setState({
      loading: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio
			filtro.relatorio = 'carteira-mensalidades'

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())

      let dataChart = [
				["Unidade", "Pontos", { role: "style" }, { role: 'annotation' }]
			]

      // let porCargo = []

      // let porColaborador = [['Assessores', 'Matricula', 'Rematricula', 'Prorrogacao', 'MD']]

			data.list.por_unidades.forEach((unidade, key) => {
				var randomColor = Math.floor(Math.random()*16777215).toString(16);

				dataChart.push(
					[`${key + 1}º - ${unidade.unin_descricao}`, unidade.porc_baixado, `#${randomColor}`, `${unidade.porc_baixado}%` ]
				)
			});

      // data.list.por_colaboradores.forEach((assessor, key) => {
			// 	porColaborador.push(
			// 		[`${key + 1}º - ${assessor.colaborador} - ${assessor.total}`, assessor.pontos_matricula, assessor.pontos_rematricula, assessor.pontos_prorrogacao, assessor.md ]
			// 	)
			// });

      // data.list.por_cargo.forEach((cargo, key) => {

      //   let colaboradores = [['Assessores', 'Matricula', 'Rematricula', 'Prorrogacao', 'MD']]
        
      //   cargo.colaboradores.sort((a, b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0)).forEach((assessor, key) => {
      //     colaboradores.push(
      //       [`${key + 1}º - ${assessor.colaborador} - ${assessor.total}`, assessor.pontos_matricula, assessor.pontos_rematricula, assessor.pontos_prorrogacao, assessor.md ]
      //     )
      //   });

      //   porCargo.push({
      //     func_descricao: cargo.func_descricao,
      //     func_nivel: cargo.func_nivel,
      //     func_id: cargo.func_id,
      //     colaboradores
      //   })
			// });

      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list.por_unidades
        },
        relatoriosFiltrado: {
          list: data.list.por_unidades
        },
        urlView: data.urlView,
				dataChart,
        mostrarRelatorio: true,
        loading: false
      })

      localStorage.removeItem('grafico_carteira_mensalidade')

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }
  }

  async updateFieldFiltroUnidade(event){
    const filtroUnidade = this.state.filtroUnidade

    filtroUnidade[event.target.name] = maskData(event.target.value)

    this.setState({ filtroUnidade })
  }

  updateFieldFiltroFuncionario(event){
    const relatorios = this.state.relatorios.list
    const filtroFuncionario = this.state.filtroFuncionario

    let list = []

    if (event.target.value !== '') {
      list = relatorios.filter(param => param.fun_id === parseInt(event.target.value))
    } else {
      list = relatorios
    }

    filtroFuncionario[event.target.name] = event.target.value

    this.setState({
      relatoriosFiltrado: {
        list
      },
      filtroFuncao: {
        func_id: ''
      },
      filtroFuncionario
    })
  }

  updateFieldFiltroFuncao(event){
    const relatorios = this.state.relatorios.list
    const filtroFuncao = this.state.filtroFuncao

    let list = []

    if (event.target.value !== '') {
      list = relatorios.filter(param => param.func_id === parseInt(event.target.value))
    } else {
      list = relatorios
    }

    filtroFuncao[event.target.name] = event.target.value

    this.setState({
      relatoriosFiltrado: {
        list
      },
      filtroFuncao,
      filtroFuncionario: {
        fun_id: ''
      }
    })
  }

  async imprimir(value) {

    const { tab, dataChart, filtro, porCargo, porColaborador, listaPontuacao, relatoriosFiltrado } = this.state

    const dados = {
      dataChart,
      porCargo,
      porColaborador,
      filtro,
      listaPontuacao,
      tab,
      por_unidades: relatoriosFiltrado.list
    }

    localStorage.setItem('grafico_carteira_mensalidade', JSON.stringify(dados));

    if(tab === 0){
      this.props.history.push("/grafico_carteira_mensalidade/impressao/unidade");
      this.setState({ loading: false })
      return;
    }
  }

  async imprimirCarteiraUnidade(value){
    const {unidadeSelecionada, valores, filtro} = this.state

    this.setState({
      loadingDownloadParcelas: true
    })

    try {

      const aux = filtro.data.split('/')
      const data_filtro = `${aux[1]}-${aux[0]}`
    
      const data_inicial = new Date(`${data_filtro}-01`)
      const data_final = new Date(`${data_filtro}-${moment(`${data_filtro}-01`).daysInMonth()}`)

      let filtro_rel ={
        unidadesnegocio: unidadeSelecionada.unin_id,
        relatorio: 'carteira-mensalidades',
        titulo: 'Mensalidades da Carteira',
        data_inicial: data_inicial,
        data_final: data_final,
        valores
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro_rel, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownloadParcelas: false
      })
  

      saveAs(pdfBlob, 'Carteira Mensalidade.pdf')

    } catch (error) {
      this.setState({
        loadingDownloadParcelas: false
      })
      console.log(error)
    }
  }

  async imprimirParcelas(){
    const {tituloCarteira, filtroUnidade, unidadeSelecionada, contas} = this.state

    this.setState({
      loadingDownloadParcelas: true
    })

    try {

      const data_inicial = new Date(`${filtroUnidade.data}-01`)
      const data_final = new Date(`${filtroUnidade.data}-${moment(`${filtroUnidade.data}-01`).daysInMonth()}`)

      let filtro ={
        unidadesnegocio: unidadeSelecionada.unin_id,
        relatorio: 'carteira-mensalidades-parcelas',
        titulo: 'Mensalidades da Carteira - ' + tituloCarteira,
        data_inicial: data_inicial,
        data_final: data_final,
        list: contas.list
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownloadParcelas: false
      })
    
      saveAs(pdfBlob, tituloCarteira + '.pdf')

    } catch (error) {
      this.setState({
        loadingDownloadParcelas: false
      })
      console.log(error)
    }
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  registrarRows(value){
    
    relatoriosAux.list = value
    
    // this.setState({
    //   relatoriosAux: {
    //     list: value
    //   }
    // })
  }

  handleCloseSubRelatorio(){
    this.setState({
      modalSubRelatorio: false
    })
  }

	async openModalUnidade(row){

    const filtro = this.state.filtro
    filtro.relatorio = 'carteira-mensalidades-unidade'
    filtro.unin_id = row.unin_id

    this.setState({
      loadingDownload: true,
      filtroParcelas: {
        contpr_tipo: "",
        plancontas_id: "",
        contpr_descricao: "",
        data_inicial: "",
        data_final: "",
        parcont_situacao: "",
        data_por: "VENCIMENTO",
        formpag_id: "",
        stcontmat_situacao: "",
        formpag_descricao: "",
        plancontas_descricao: ""
      },
      filtroUnidade: {
        relatorio: 'carteira-mensalidades-unidade',
        data: filtro.data,
        unin_id: row.unin_id
      },
      unidadeSelecionada: row,
    })

    await this.filtrarUnidade()

	}

  async filtrarUnidade(){
    this.setState({
      loadingFiltroCarteriaUnidade: true
    })
    
    const filtro = this.state.filtroUnidade

    if (filtro.data === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data'
      })
      this.setState({
        loading: false
      })
      return
    }

    const aux = filtro.data.split('/')

    if( aux.length !== 2){
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data corretamente'
      })
      this.setState({
        loading: false
      })
      return
    }

    const mes = parseInt(aux[0])
    const ano = parseInt(aux[1])

    if( !mes || mes < 1 || mes > 12){
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data corretamente'
      })
      this.setState({
        loading: false
      })
      return
    }

    if( !ano || ano < 1990){
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data corretamente'
      })
      this.setState({
        loading: false
      })
      return
    }
    
    try {

			const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())
			
      const valores = data.list

      console.log(valores)

      this.setState({
        loadingDownload: false,
        loadingFiltroCarteriaUnidade: false,
        valores,
        modalCarteiraUnidade: true
      })
			
		} catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      this.setState({
        loadingDownload: false,
        loadingFiltroCarteriaUnidade: false
      })
      return
    }
  }

	handleCloseParcelasUnidade(){
		this.setState({
			modalParcelasUnidade: false
		})
	}

  handleCloseCarteiraUnidade(){
		this.setState({
			modalCarteiraUnidade: false,
			unidadeSelecionada: {
				unin_id: '',
				unin_numero: '',
				unin_descricao: '',
				pontos_matricula : 0,
				pontos_rematricula : 0,
				pontos_prorrogacao : 0,
				md : 0,
				total: 0
			}
		})
	}

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  gerarDescricao(descricao){
    return (
      <div className="content-fly" dangerouslySetInnerHTML={{__html: descricao}}></div>
  );
  }

  async filtrarParcela() {
    this.setState({loadingFiltroParcelas: true})
    try {

      const {filtroParcelas, filtroUnidade, unidadeSelecionada} = this.state

      const aux = filtroUnidade.data.split('/')

      if( aux.length !== 2){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }
  
      const mes = parseInt(aux[0])
      const ano = parseInt(aux[1])
  
      if( !mes || mes < 1 || mes > 12){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }
  
      if( !ano || ano < 1990){
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: 'Por favor informar a data corretamente'
        })
        this.setState({
          loading: false
        })
        return
      }

      const filtroAux = {
        relatorio: 'carteira-mensalidades-parcelas',
        data: filtroUnidade.data,
        unin_id: unidadeSelecionada.unin_id,
        situacao: filtroParcelas.situacao
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtroAux, this.getToken())
      
      const contas = []

      data.forEach(conta => {
        if(conta['conta.contpr_descricao']){
          if(conta.parcont_id === 1892347){
            console.log(conta)
          }
          contas.push({
            _id: conta.parcont_id,
            contpr_descricao: conta['conta.contpr_descricao'] + ' - Parc: ' + conta.parcont_num + '/' + conta['conta.contpr_numparcela'],
            parcont_num: conta.parcont_num,
            parcont_documento: conta.parcont_documento,
            contpr_tipo: conta['conta.contpr_tipo'] === 'PAGAR' ? 'PAGAR' : 'RECEBER',
            plancontas_descricao: conta['conta.planocontas.plancontas_codigo'] + ' - ' + conta['conta.planocontas.plancontas_descricao'],
            plancontas_id: conta['conta.planocontas.plancontas_id'],
            parcont_datavencimento: moment(conta.parcont_datavencimento_orginal).format('DD/MM/YYYY'),
            datavencimentoOrder: moment(conta.parcont_datavencimento_orginal).format('YYYYMMDD'),
            parcont_datapagamento:conta.parcont_datapagamento ? moment(conta.parcont_datapagamento).format('DD/MM/YYYY') : '',
            datapagamentoOrder: conta.parcont_datapagamento ? moment(conta.parcont_datapagamento).format('YYYYMMDD') : '',
            parcont_datavencimento_sem_format: conta.parcont_datavencimento_orginal,
            parcont_situacao: conta.parcont_situacao,
            parcont_valorparcela: real(conta.parcont_valorparcela),
            parcont_valor_previsto: real(conta.parcont_valor_previsto),
            valpag_valor_pagamento: conta.parcont_situacao === 'BAIXADO' ? real(conta.parcont_valorparcela + (conta.parcont_multajuros ? conta.parcont_multajuros : 0)) : '-',
            aluno_matricula: conta['conta.aluno.aluno_matricula'],
            aluno_nome: conta['conta.aluno.fisica.pesf_nome'],
            parcont_multajuros: conta.parcont_multajuros ? real(conta.parcont_multajuros) : real(0),
          })
        }
      })

      this.setState({
        loadingFiltroParcelas: false,
        contas:{
          list: contas
        }
      })
    } catch ({response}) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      this.setState({
        loadingFiltroParcelas: false
      })
      return
    }

  }

  updateFiltroParcela(event){
    const {filtroParcelas} = this.state

    filtroParcelas[event.target.name] = event.target.value

    this.setState({
      filtroParcelas
    })

  }

  async visualizarCarteira(value){

    const {filtroParcelas} = this.state

    let opcoesFiltro = [
      {option: 'total', value: 'Dentro da Carteira'},
      {option: 'baixado', value: 'Carteira do Mês'},
      {option: 'anterior', value: 'Meses Anteriores'},
      {option: 'atual', value: 'Mês Atual'},
      {option: 'atrasadas', value: 'Atrasadas recebidas neste mês'},
      {option: 'futuras', value: 'Recebidas de carteiras futuras'},
      {option: 'inativos', value: 'Recebidas de alunos inativos'},
      {option: 'periodo', value: 'Recebidas do mês atual'}
    ]
    
    filtroParcelas.situacao = value
    filtroParcelas.titulo = opcoesFiltro.filter(param => param.option === value)[0].value
    let tituloCarteira = filtroParcelas.titulo

    const ultima_tela_financeiro = {
      tela: 'carteira-mensalidade'
    }

    localStorage.setItem('ultima_tela_financeiro', JSON.stringify(ultima_tela_financeiro))

    this.setState({
      tituloCarteira,
      opcoesFiltro,
      modalParcelasUnidade: true,
      filtroParcelas
    })
  
    await this.filtrarParcela()
  }

  render() {
    const { filtro, filtroParcelas, loadingDownload, relatoriosFiltrado, tab, mostrarRelatorio, contas, 
      loadingFiltroParcelas, filtroUnidade, tituloCarteira, valores } = this.state

		return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Carteira de Mensalidade</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
						<Grid item md={3} xs={12} sm={5}>
              <TextField 
              className="input" 
              type="text" 
              placeholder='xx/xxxx' 
              label="Mês/Ano" 
              variant="outlined" 
              size="small" 
              name="data" 
              value={filtro.data} 
              inputProps={{ maxLength: 7 }}
              onChange={(e) => this.updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.data === '' ? true : false}>
                Filtrar
              </Button>
            </Grid>
            {this.state.dataChart.length > 1 &&
              <Grid item md={2} xs={12} sm={3}>
                {tab !== 3 &&
                  <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                    Imprimir
                  </Button>
                }
              </Grid>
            }
          </Grid>
          <br />
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {mostrarRelatorio &&
                <React.Fragment>
                  <AppBar position="static">
                    <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                      <Tab label="Por Unidade" {...a11yProps(0)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tab} index={0}>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12}>
                        <Chart 
                          chartType="BarChart" 
                          width="100%" 
                          height={`${(this.state.dataChart.length - 1) * 35}px`}
                          data={this.state.dataChart}
                          chartEvents={[
                            {
                              eventName: 'select',
                              callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart()
                                const selection = chart.getSelection()
                                if (selection.length === 1) {
                                  const [selectedItem] = selection
                                  const { row } = selectedItem

                                  if(this.state.minhaUnidade.unin_tipo === 'FRANQUEADOR'){
                                    this.openModalUnidade(relatoriosFiltrado.list[row])
                                  }else if (this.state.minhaUnidade.unin_tipo === 'FRANQUEADO' && this.state.minhaUnidade.unin_id === relatoriosFiltrado.list[row].unin_superior_id){
                                    this.openModalUnidade(relatoriosFiltrado.list[row])
                                  }else if(this.state.minhaUnidade.unin_tipo === 'UNIDADE' && this.state.minhaUnidade.unin_id === relatoriosFiltrado.list[row].unin_id){
                                    this.openModalUnidade(relatoriosFiltrado.list[row])
                                  }        
                                }
                              },
                            },
                          ]}
                          options={{
                            chartArea: {height: '95%'},
                            baseline: 0,
                            hAxis: {
                              title: 'Porcentagem',
                              minValue: 0
                            },
                            vAxis: {
                              title: 'Unidades'
                            },
                            legend: "none"
                          }} 
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </React.Fragment>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalParcelasUnidade 
            dados={contas.list}
            open={this.state.modalParcelasUnidade}
						unidadeSelecionada={this.state.unidadeSelecionada}
						handleClose={e => this.handleCloseParcelasUnidade(e)}
            filtro={filtroParcelas}
            filtroUnidade = {filtroUnidade}
            opcoesFiltro={this.state.opcoesFiltro}
            filtrar={e => this.filtrarParcela(e)}
            updateFiltro={e => this.updateFiltroParcela(e)}
            loadingFiltroParcelas={loadingFiltroParcelas}
            loadingDownloadParcelas={this.state.loadingDownloadParcelas}
            tituloCarteira={tituloCarteira}
            imprimirParcelas={e => this.imprimirParcelas(e)}
          />

          <ModalCarteiraUnidade 
            open={this.state.modalCarteiraUnidade}
						unidadeSelecionada={this.state.unidadeSelecionada}
						handleClose={e => this.handleCloseCarteiraUnidade(e)}
            loadingFiltroCarteriaUnidade={this.state.loadingFiltroCarteriaUnidade}
            updateFieldFiltroUnidade={e => this.updateFieldFiltroUnidade(e)}
            filtrarUnidade={e => this.filtrarUnidade(e)}
            openParcelas={e => this.openParcelas(e)}
            visualizarCarteira={e => this.visualizarCarteira(e)}
            filtro = {filtroUnidade}
            recebidos_mes = {this.state.recebidos_mes}
            total={this.state.total}
            imprimirCarteiraUnidade={e => this.imprimirCarteiraUnidade(e)}
            valores={valores}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}