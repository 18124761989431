import React, { Component } from 'react'
import './CobrancaUnidade.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import ModalTrocaQtdAlunos from './../../components/modals/TrocaQtdAlunosAula'

const initalState = {
  cobrancas: {
    list: []
  },
  cobrancasFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'configcobran_descricao', numeric: false, disablePadding: true, label: 'Configuração' },
    { id: 'configcobran_ativo', numeric: false, disablePadding: true, label: 'Ativo' }
  ],
  acoesTabela: [],
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Aula extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cobranca-unidade')[0]
    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoCobranca`, this.getToken())
      
      this.setState({
        cobrancasFiltrado: {
          list: data.map(value => {
            return {
              _id: value.configcobran_id,
              configcobran_descricao: value.configcobran_descricao,
              configcobran_ativo: value.configcobran_ativo
            }
          })
        },
        cobrancas: {
          list: data.map(value => {
            return {
              _id: value.configcobran_id,
              configcobran_descricao: value.configcobran_descricao,
              configcobran_ativo: value.configcobran_ativo
            }
          })
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      loading: false
    })    
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { cobrancasFiltrado, permissoes } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Cobranças...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Configuração de Cobrança das Unidades</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={3} xs={12}>
                    <Link to="/cobranca_unidade/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Configuração
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" style={{marginTop: 10}} >
                <Grid item md={12} xs={12}>
                  <Table
                    urlUpdate="/cobranca_unidade/cadastro/"
                    urlView="/cobranca_unidade/view/"
                    headCell={this.state.cabecalhoTabela}
                    rows={cobrancasFiltrado.list}
                    acoes={this.state.acoesTabela}
                  />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}