import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'

const checkPermissao = (perfil, tela, propriedade) => {

  for (const permissao of perfil.permissoes) {
    if (permissao.perm_tela_id === tela.tela_id) return permissao['perm_' + propriedade]
  }

  return false
}

const listaVisibilidade = () => {
  const pessoa = JSON.parse(localStorage.getItem('pessoa'))
  const tipoUnidade = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

  if (tipoUnidade === "FRANQUEADOR") {
    return (
      <React.Fragment>
        <option value="UNIDADE"> Unidade </option>
        <option value="FRANQUEADO"> Franqueado </option>
        <option value="FRANQUEADOR"> Franqueador </option>
      </React.Fragment>
    )
  } else if (tipoUnidade === "FRANQUEADO") {
    return (
      <React.Fragment>
        <option value="UNIDADE"> Unidade </option>
        <option value="FRANQUEADO"> Franqueado </option>
      </React.Fragment>
    )
  } else if (tipoUnidade === "UNIDADE") {
    return (
      <React.Fragment>
        <option value="UNIDADE"> Unidade </option>
      </React.Fragment>
    )
  }
}

const FormPerfil = ({ dados, telas, updateField, updateFieldAtivo, updateFieldSwitch, update, tipoUnidade, permissoesAtual, unin_id }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <TextField
          className="input"
          label="Descrição *"
          variant="outlined"
          size="small"
          name="per_descricao"
          value={dados.per_descricao} onChange={(e) => updateField(e)}
        />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Para qual usuario? *"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="per_visibilidade"
          value={dados.per_visibilidade}
          onChangeCapture={(e) => updateField(e)}
          disabled={update}
        >
          {listaVisibilidade()}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={<Switch
            name="Ativo"
            color="primary"
            checked={dados.per_status}
          />}
          label="Ativo"
          name="per_status"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12}>
        <h3>Permissões</h3>
      </Grid>
    </Grid>

    <Grid container direction="row">
      <Grid item md={12} className="permissoes">
        <div className="mg_top_20 linhas">
          <Grid container item md={12}>
            <Grid item md={6} xs={6} sm={6}><h5>Modulo</h5></Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Grid container item md={12}>
                <Grid item md={3} xs={3} sm={3}><h5>Visualizar</h5></Grid>
                <Grid item md={3} xs={3} sm={3}><h5>Inserir</h5></Grid>
                <Grid item md={3} xs={3} sm={3}><h5>Alterar</h5></Grid>
                <Grid item md={3} xs={3} sm={3}><h5>Deletar</h5></Grid>
              </Grid>
            </Grid>
          </Grid>

          {tipoUnidade === "FRANQUEADOR" &&
            <React.Fragment>
              {
                telas.sort((a, b) => (a.modulo.mod_grmod_id > b.modulo.mod_grmod_id) ? 1 : (b.modulo.mod_grmod_id > a.modulo.mod_grmod_id) ? -1 : 0).map((tela, key) => {
                  return <Grid container item md={12} key={key} className={key % 2 === 0 ? 'row-grey' : ''}>
                    <Grid item md={6} xs={6} sm={6} className="descricao"><p>{tela.modulo.mod_descricao} - {tela.tela_descricao}</p></Grid>
                    <Grid item md={6} xs={6} sm={6}>
                      <Grid container item md={12}>
                        <Grid item md={3} xs={3} sm={3}>
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="visualizar"
                              color="primary"
                              checked={checkPermissao(dados, tela, 'visualizar')}
                            />}
                            name="visualizar"
                            onChange={(e) => updateFieldSwitch(key, e)}
                            disabled={ dados.per_unin_id === '' ? false : (unin_id !== dados.per_unin_id ? true : false)}
                          />
                        </Grid>
                        <Grid item md={3} xs={3} sm={3}>
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="inserir"
                              color="primary"
                              checked={checkPermissao(dados, tela, 'inserir')}
                            />}
                            name="inserir"
                            onChange={(e) => updateFieldSwitch(key, e)}
                            disabled={ dados.per_unin_id === '' ? false : (unin_id !== dados.per_unin_id ? true : false)}
                          />
                        </Grid>
                        <Grid item md={3} xs={3} sm={3}>
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="alterar"
                              color="primary"
                              checked={checkPermissao(dados, tela, 'alterar')}
                            />}
                            name="alterar"
                            onChange={(e) => updateFieldSwitch(key, e)}
                            disabled={ dados.per_unin_id === '' ? false : (unin_id !== dados.per_unin_id ? true : false)}
                          />
                        </Grid>
                        <Grid item md={3} xs={3} sm={3}>
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="deletar"
                              color="primary"
                              checked={checkPermissao(dados, tela, 'deletar')}
                            />}
                            name="deletar"
                            onChange={(e) => updateFieldSwitch(key, e)}
                            disabled={ dados.per_unin_id === '' ? false : (unin_id !== dados.per_unin_id ? true : false)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                })
              }
            </React.Fragment>
          }
          {tipoUnidade !== "FRANQUEADOR" &&
            <React.Fragment>
              {permissoesAtual.sort((a, b) => (a.tela.modulo.mod_grmod_id > b.tela.modulo.mod_grmod_id) ? 1 : (b.tela.modulo.mod_grmod_id > a.tela.modulo.mod_grmod_id) ? -1 : 0).map((permi, key) => {
                return <Grid container item md={12} key={permi.perm_id} className={key % 2 === 0 ? 'row-grey' : ''}>
                  <Grid item md={6} xs={6} sm={6} className="descricao"><p>{permi.tela.modulo.mod_descricao} - {permi.tela.tela_descricao}</p></Grid>
                  <Grid item md={6} xs={6} sm={6}>
                    <Grid container item md={12}>
                      <Grid item md={3} xs={3} sm={3}>
                        {permi.perm_visualizar &&
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="visualizar"
                              color="primary"
                              checked={checkPermissao(dados, permi.tela, 'visualizar')}
                            />}
                            name="visualizar"
                            onChange={(e) => updateFieldSwitch(key, e)}
                          />
                        }
                      </Grid>
                      <Grid item md={3} xs={3} sm={3}>
                        {permi.perm_inserir &&
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="inserir"
                              color="primary"
                              checked={checkPermissao(dados, permi.tela, 'inserir')}
                            />}
                            name="inserir"
                            onChange={(e) => updateFieldSwitch(key, e)}
                          />
                        }
                      </Grid>
                      <Grid item md={3} xs={3} sm={3}>
                        {permi.perm_alterar &&
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="alterar"
                              color="primary"
                              checked={checkPermissao(dados, permi.tela, 'alterar')}
                            />}
                            name="alterar"
                            onChange={(e) => updateFieldSwitch(key, e)}
                          />
                        }
                      </Grid>
                      <Grid item md={3} xs={3} sm={3}>
                        {permi.perm_deletar &&
                          <FormControlLabel
                            control={<Switch
                              className="switch"
                              name="deletar"
                              color="primary"
                              checked={checkPermissao(dados, permi.tela, 'deletar')}
                            />}
                            name="deletar"
                            onChange={(e) => updateFieldSwitch(key, e)}
                          />
                        }

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              })}
            </React.Fragment>
          }
        </div>
      </Grid>

    </Grid>

  </React.Fragment>
)

export default FormPerfil
