import React, { Component } from 'react'
import './ConfigAditivo'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormConfigAditivo from '../../components/forms/FormConfigAditivo'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'

const initalState = {
  config_aditivo:{
    ccad_id: '',
		ccad_descricao: '',
		ccad_duracao: '',
		ccad_gera_fin: true,
		ccad_ativo: true,
		cursos_aditivo: []
	},
	curso_aditivo: {
    cursad_id: '',
		cursad_ativo: true,
		cursad_atual_curso_id: '',
		cursad_novo_curso_id: '',
		cursad_atual_cursad_descricao: '',
		cursad_novo_cursad_descricao: '',
	  index: ''
  },
	modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  cursos: {
    list: []
  },
  update: false,
  cabecalhoTabela: [
    { id: 'cursad_atual_cursad_descricao', numeric: false, disablePadding: true, label: 'Curso Atual' },
    { id: 'cursad_novo_cursad_descricao', numeric: false, disablePadding: false, label: 'Novo Curso' },
    { id: 'cursad_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: ['editar', 'remove'],
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class CadastroCurso extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'config-aditivo-curso')[0]

    this.setState({
      permissoes
    })

    if (params.configId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    this.setState({
			config_aditivo:{
        ccad_id: '',
				ccad_descricao: '',
				ccad_duracao: '',
				ccad_gera_fin: true,
				ccad_ativo: true,
				cursos_aditivo: []
			},
      curso_aditivo: {
        cursad_id: '',
        cursad_ativo: true,
        cursad_atual_curso_id: '',
        cursad_novo_curso_id: '',
        cursad_atual_cursad_descricao: '',
        cursad_novo_cursad_descricao: '',
        index: ''
      }
    })

    if (params.configId) {
      try {
        const { data: config_aditivo } = await axios.get(`${process.env.REACT_APP_API_URL}/configAditivo/${params.configId}`, this.getToken())

        this.setState({
          config_aditivo:{
            ccad_id: config_aditivo.ccad_id,
            ccad_descricao: config_aditivo.ccad_descricao,
            ccad_duracao: config_aditivo.ccad_duracao,
            ccad_gera_fin: config_aditivo.ccad_gera_fin,
            ccad_ativo: config_aditivo.ccad_ativo,
            cursos_aditivo: config_aditivo.cursos_aditivo.map((value, key) => {
              return {
                cursad_id: value.cursad_id,
                cursad_ativo: value.cursad_ativo,
                cursad_atual_curso_id: value.cursad_atual_curso_id,
                cursad_novo_curso_id: value.cursad_novo_curso_id,
                cursad_atual_cursad_descricao: value.curso_atual.curso_nome,
                cursad_novo_cursad_descricao: value.curso_novo.curso_nome,
                index: key
              }
            })
          }
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o configuração, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {
      const { data: cursos } = await axios.get(`${process.env.REACT_APP_API_URL}/curso`, this.getToken())
      
      this.setState({
        cursos: {
          list: cursos.sort((a, b) => (a.curso_nome > b.curso_nome) ? 1 : ((b.curso_nome > a.curso_nome) ? -1 : 0)).filter(param => param.curso_ativo === true)
        }
      })
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  updateField(event) {
    const {config_aditivo} = this.state
    config_aditivo[event.target.name] = event.target.value
    this.setState({ config_aditivo })
  }

  updateFieldConfig(event){
    const {curso_aditivo, cursos} = this.state

    if( event.target.name === 'cursad_atual_curso_id'){
      const curso = cursos.list.filter(param => param.curso_id === parseInt(event.target.value))[0]

      curso_aditivo.cursad_atual_cursad_descricao = curso.curso_nome
    }

    if( event.target.name === 'cursad_novo_curso_id'){
      const curso = cursos.list.filter(param => param.curso_id === parseInt(event.target.value))[0]

      curso_aditivo.cursad_novo_cursad_descricao = curso.curso_nome
    }

    curso_aditivo[event.target.name] = event.target.value

    this.setState({ curso_aditivo })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/config_aditivos");
    }, timeout)
    this.setState({
      curso: initalState.curso,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFieldFinanceiro(){
    const { config_aditivo } = this.state
    config_aditivo.ccad_gera_fin = !config_aditivo.ccad_gera_fin
    this.setState({config_aditivo})
  }

  updateFieldAtivo(){
    const { config_aditivo } = this.state
    config_aditivo.ccad_ativo = !config_aditivo.ccad_ativo
    this.setState({config_aditivo})
  }

  updateFieldAtivoConfig(){
    const { curso_aditivo } = this.state
    curso_aditivo.cursad_ativo = !curso_aditivo.cursad_ativo
    this.setState({curso_aditivo})
  }

  validateCurso(){
    const {curso_aditivo} = this.state

    if(curso_aditivo.cursad_atual_curso_id === '') return true
    if(curso_aditivo.cursad_novo_curso_id === '') return true

    return false
  }

  addItem() {

    if (this.validateCurso()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const {curso_aditivo, config_aditivo} = this.state

    const itens = config_aditivo.cursos_aditivo

    if (curso_aditivo.index) {
      const index = itens.findIndex(param => param.index === curso_aditivo.index)

      itens[index] = {
        cursad_id: curso_aditivo.cursad_id,
        cursad_ativo: curso_aditivo.cursad_ativo,
        cursad_atual_curso_id: curso_aditivo.cursad_atual_curso_id,
        cursad_novo_curso_id: curso_aditivo.cursad_novo_curso_id,
        cursad_atual_cursad_descricao: curso_aditivo.cursad_atual_cursad_descricao,
        cursad_novo_cursad_descricao: curso_aditivo.cursad_novo_cursad_descricao,
        index: curso_aditivo.index
      }
    } else {
      const index = itens.length + 1

      itens.push({
        cursad_id: curso_aditivo.cursad_id,
        cursad_ativo: curso_aditivo.cursad_ativo,
        cursad_atual_curso_id: curso_aditivo.cursad_atual_curso_id,
        cursad_novo_curso_id: curso_aditivo.cursad_novo_curso_id,
        cursad_atual_cursad_descricao: curso_aditivo.cursad_atual_cursad_descricao,
        cursad_novo_cursad_descricao: curso_aditivo.cursad_novo_cursad_descricao,
        index
      })
    }

    config_aditivo.cursos_aditivo = itens


    this.setState({
      update: false,
      config_aditivo,
      curso_aditivo: {
        cursad_id: '',
        cursad_ativo: true,
        cursad_atual_curso_id: '',
        cursad_novo_curso_id: '',
        cursad_atual_cursad_descricao: '',
        cursad_novo_cursad_descricao: '',
        index: ''
      },
    })
  }

  editarItem(index) {
    const aux = this.state.config_aditivo.cursos_aditivo.filter(param => param.index === index)

    this.setState({
      update: true,
      curso_aditivo: aux[0]
    })
  }

  removerItem(index) {
    const {config_aditivo} = this.state
    
    let itens = config_aditivo.cursos_aditivo

    itens.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        cursad_id: itens[i].cursad_id,
        cursad_ativo: itens[i].cursad_ativo,
        cursad_atual_curso_id: itens[i].cursad_atual_curso_id,
        cursad_novo_curso_id: itens[i].cursad_novo_curso_id,
        cursad_atual_cursad_descricao: itens[i].cursad_atual_cursad_descricao,
        cursad_novo_cursad_descricao: itens[i].cursad_novo_cursad_descricao,
        index: i + 1
      })
    }

    itens = aux

    config_aditivo.cursos_aditivo = itens

    this.setState({
      config_aditivo
    })
  }

  validateForm() {
    const { config_aditivo } = this.state

    if(config_aditivo.ccad_descricao === '') return true
    if(config_aditivo.ccad_duracao === '') return true

    return false
  }

  async salvar() {
    const {config_aditivo} = this.state

    if (this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    if(config_aditivo.cursos_aditivo.length === 0){
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Por favor selecionar um Curso."
      })
      return
    }

    const dados = {
      ccad_descricao: config_aditivo.ccad_descricao,
      ccad_duracao: parseInt(config_aditivo.ccad_duracao),
      ccad_gera_fin: config_aditivo.ccad_gera_fin,
      ccad_ativo: config_aditivo.ccad_ativo,
      cursos_aditivo: config_aditivo.cursos_aditivo.map(value => {
        if(value.cursad_id === ''){
          return {
            cursad_ativo: value.cursad_ativo,
            cursad_atual_curso_id: parseInt(value.cursad_atual_curso_id),
            cursad_novo_curso_id: parseInt(value.cursad_novo_curso_id)
          }
        }else{
          return {
            cursad_id: value.cursad_id,
            cursad_ativo: value.cursad_ativo,
            cursad_atual_curso_id: parseInt(value.cursad_atual_curso_id),
            cursad_novo_curso_id: parseInt(value.cursad_novo_curso_id)
          }
        }
      })
    }

    try {
      if (config_aditivo.ccad_id !== "") {
        await axios.put(`${process.env.REACT_APP_API_URL}/configAditivo/${config_aditivo.ccad_id}`, dados, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/configAditivo`, dados, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  render() {
    const { config_aditivo, curso_aditivo, cursos, update } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Configuração de Aditivo</h2>
                </Grid>
              </Grid>
              <FormConfigAditivo
                dados={config_aditivo}
                curso_aditivo={curso_aditivo}
                updateField={e => this.updateField(e)}
                updateFieldConfig={e => this.updateFieldConfig(e)}
                updateFieldFinanceiro={e => this.updateFieldFinanceiro(e)}
                updateFieldAtivo={e => this.updateFieldAtivo(e)}
                cursos={cursos.list}
                updateFieldAtivoConfig={e => this.updateFieldAtivoConfig(e)}
                addItem={e => this.addItem()}
                update={update}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Table
                  editar={e => this.editarItem(e)}
                  remove={e => this.removerItem(e)}
                  headCell={this.state.cabecalhoTabela}
                  rows={config_aditivo.cursos_aditivo}
                  acoes={this.state.acoesTabela}
                  noRemove={false} 
                />
              </Grid>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}