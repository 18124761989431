import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, Button, TextField } from '@material-ui/core'
import moment from 'moment';
import real from './../../services/real'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Table from './../Table'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    marginLeft: '5%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));


export default function SimpleModal(props) {
  const classes = useStyles();
  
  let cabecalhoTabela = [
    { id: 'aluno_matricula', numeric: false, disablePadding: true, label: 'Matricula' },
    { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
  ]

  let acoesTabela = ['removerAluno']
  
  let acoesTabelaAlunos = ['selecionarAluno']

  let { filtro, filtroAluno } = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper} id='view'>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h4 className='titulo'>Aula: {props.aula.aula_nome}</h4>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Filtrar por"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="filtrar_por"
                value={filtro.filtrar_por}
                onChangeCapture={(e) => props.updateFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="Subscrição - aluno_matricula"> Subscrição </option>
                <option value="Nome - pesf_nome"> Nome </option>
                <option value="CPF - pesf_cpf"> CPF </option>
              </TextField>
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <TextField className="input" label={filtroAluno.label} variant="outlined" size="small" name='value' value={filtro.value} onChange={(e) => props.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <Button style={{marginTop: 8}} fullWidth variant="contained" color="primary" onClick={() => props.buscarAluno()}>
                Buscar
              </Button>
            </Grid>
          </Grid><hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row">
            <Grid item md={12} xs={12} sm={12}>
              <h4>Selecionar Aluno:</h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={props.alunos.sort((a, b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0)).map(aluno => {
                  return {
                    _id: aluno._id,
                    aluno_matricula: aluno.aluno_matricula,
                    nome: aluno.pesf_nome
                  }
                })}
                acoes={acoesTabelaAlunos}
                selecionarAluno={(e) => props.selecionarAluno(e)}
              />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container spacing={1} direction="row">
            <Grid item md={12} xs={12} sm={12}>
              <h4>Alunos Selecionados:</h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row">
            <Grid item md={12} xs={12} sm={12}>
              <span><b>Nota: </b>Apenas os alunos que estiverem presentes em todas as aulas selecionadas apareceram nesta listagem. Os demais não apareceram.</span>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={props.alunosSelecionados.sort((a, b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0)).map(aluno => {
                  return {
                    ...aluno
                  }
                })}
                acoes={acoesTabela}
                removerAluno={(e) => props.removerAluno(e)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={6} xs={12} sm={4}></Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(false)} disabled={props.disabledSalvar}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={12} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => props.confirmar(true)} disabled={props.disabledSalvar}>Confirmar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
