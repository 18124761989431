import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid blue',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Alteração de limite</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p className='perguntaTrocaQtdAlunos'>Qual o novo valor para o limite de alunos desta aula em sua unidade?</p>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item md={4} xs={4} sm={4}>
              <p className='align-right qtdMinima'>{props.aula.aula_aluno_minimo}</p>
            </Grid>
            <Grid item md={4} xs={4} sm={4}>
              <TextField
                className="input inputCenter"
                variant="outlined"
                size="small"
                name="parcont_motivo_cancelamento"
                value={props.aula.limiteUnidade}
                onChange={(e) => props.updateQtd(e)}
                InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={4} xs={4} sm={4}>
              <p className='qtdMaxima'>{props.aula.aula_aluno_maximo}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" >
            <Grid item md={12} xs={12} sm={12}>
              <p className={props.errorMinMax ? 'erro padrao align-center margin0' : 'padrao align-center margin0'}>Padrão: {props.aula.aula_aluno_padrao} / Valor atual: {props.aula.limiteUnidade}</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className='mg_top_20'>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => props.voltar()}>Voltar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => props.salvar()} >Salvar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div >
  );
}
