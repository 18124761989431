
import React from 'react'
import { Grid, TextField } from '@material-ui/core'

const FormProfessor = ({ updateField, disponibilidades }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12}>
        <h3>Validade dos Horários</h3>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <table className="lista sempadding" id="professorDisp">
        <thead>
          <tr className="titulos acompanha">
            <th>
              <p>Dia</p>
            </th>
            <th>
              <p>Horário</p>
            </th>
            <th>
              <p>Válido a partir de</p>
            </th>
            <th>
              <p>Válido até</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {disponibilidades.filter(param => param.disp_dia_semana === 'SEGUNDA').sort((a, b) => (a.disp_hora_inicial > b.disp_hora_inicial) ? 1 : (b.disp_hora_inicial > a.disp_hora_inicial) ? -1 : 0).map(disp => {
            return (
              <tr>
                <td><p>{disp.disp_dia_semana}</p></td>
                <td><p>{disp.disp_hora_inicial}</p></td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_inicio" value={disp.disp_data_vig_inicio} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_fim" value={disp.disp_data_vig_fim} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
              </tr>
            )
          })}
          {disponibilidades.filter(param => param.disp_dia_semana === 'TERÇA').sort((a, b) => (a.disp_hora_inicial > b.disp_hora_inicial) ? 1 : (b.disp_hora_inicial > a.disp_hora_inicial) ? -1 : 0).map(disp => {
            return (
              <tr>
                <td><p>{disp.disp_dia_semana}</p></td>
                <td><p>{disp.disp_hora_inicial}</p></td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_inicio" value={disp.disp_data_vig_inicio} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_fim" value={disp.disp_data_vig_fim} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
              </tr>
            )
          })}
          {disponibilidades.filter(param => param.disp_dia_semana === 'QUARTA').sort((a, b) => (a.disp_hora_inicial > b.disp_hora_inicial) ? 1 : (b.disp_hora_inicial > a.disp_hora_inicial) ? -1 : 0).map(disp => {
            return (
              <tr>
                <td><p>{disp.disp_dia_semana}</p></td>
                <td><p>{disp.disp_hora_inicial}</p></td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_inicio" value={disp.disp_data_vig_inicio} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_fim" value={disp.disp_data_vig_fim} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
              </tr>
            )
          })}
          {disponibilidades.filter(param => param.disp_dia_semana === 'QUINTA').sort((a, b) => (a.disp_hora_inicial > b.disp_hora_inicial) ? 1 : (b.disp_hora_inicial > a.disp_hora_inicial) ? -1 : 0).map(disp => {
            return (
              <tr>
                <td><p>{disp.disp_dia_semana}</p></td>
                <td><p>{disp.disp_hora_inicial}</p></td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_inicio" value={disp.disp_data_vig_inicio} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_fim" value={disp.disp_data_vig_fim} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
              </tr>
            )
          })}
          {disponibilidades.filter(param => param.disp_dia_semana === 'SEXTA').sort((a, b) => (a.disp_hora_inicial > b.disp_hora_inicial) ? 1 : (b.disp_hora_inicial > a.disp_hora_inicial) ? -1 : 0).map(disp => {
            return (
              <tr>
                <td><p>{disp.disp_dia_semana}</p></td>
                <td><p>{disp.disp_hora_inicial}</p></td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_inicio" value={disp.disp_data_vig_inicio} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_fim" value={disp.disp_data_vig_fim} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
              </tr>
            )
          })}
          {disponibilidades.filter(param => param.disp_dia_semana === 'SÁBADO').sort((a, b) => (a.disp_hora_inicial > b.disp_hora_inicial) ? 1 : (b.disp_hora_inicial > a.disp_hora_inicial) ? -1 : 0).map(disp => {
            return (
              <tr>
                <td><p>{disp.disp_dia_semana}</p></td>
                <td><p>{disp.disp_hora_inicial}</p></td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_inicio" value={disp.disp_data_vig_inicio} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_fim" value={disp.disp_data_vig_fim} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
              </tr>
            )
          })}
          {disponibilidades.filter(param => param.disp_dia_semana === 'DOMINGO').sort((a, b) => (a.disp_hora_inicial > b.disp_hora_inicial) ? 1 : (b.disp_hora_inicial > a.disp_hora_inicial) ? -1 : 0).map(disp => {
            return (
              <tr key={disp.index}>
                <td><p>{disp.disp_dia_semana}</p></td>
                <td><p>{disp.disp_hora_inicial}</p></td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_inicio" value={disp.disp_data_vig_inicio} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
                <td>
                  <TextField type="date" className="input input-200w" variant="outlined" size="small" name="disp_data_vig_fim" value={disp.disp_data_vig_fim} onChange={(e) => updateField(e, disp.index)} InputLabelProps={{ shrink: true }} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Grid>
  </React.Fragment >
)

export default FormProfessor