import React, { Component } from 'react'
import './../Estoque.scss'
import './../../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../../components/templates/Main'
import Nav from '../../../components/templates/Nav'
import Footer from '../../../components/templates/Footer'
import Table from '../../../components/Table'

import { Grid, Button } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  localEstoque: {

  },
  localEstoques: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'franqueado', numeric: false, disablePadding: true, label: 'Franqueado' },
    { id: 'descricao', numeric: false, disablePadding: false, label: 'Descricão' }
  ],
  acoesTabela: ['update']
}

export default class LocalEstoque extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/localEstoque`, this.getToken())

      let localEstoques = []

      data.forEach(localEstoque => {
        localEstoques.push({
          _id: localEstoque.localestoq_id,
          descricao: localEstoque.localestoq_descricao,
          unidade: `${localEstoque.unidade.unin_numero} - ${localEstoque.unidade.unin_descricao}`,
          franqueado: localEstoque.unidade.superior.pessoa.pes_tipo === 'FISICA' ? localEstoque.unidade.superior.pessoa.fisica.pesf_nome : localEstoque.unidade.superior.pessoa.juridica.pesj_razaosocial,
        })
      })
      this.setState({
        localEstoques: {
          list: localEstoques
        },
        loading: false
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Local de Estoque</h1>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Link to="/local_estoque/cadastro">
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Local de Estoque
								    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
                className="mg_top_30"
              >
                <Grid item md={12} xs={12}>
                  <Table headCell={this.state.cabecalhoTabela} rows={this.state.localEstoques.list} acoes={this.state.acoesTabela} urlUpdate="/local_estoque/update/" />
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}