import React, { Component } from 'react'
import './PlanoContas.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button, TextField } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'

const initalState = {
  planoContas: {
    plancontas_id: "",
    plancontas_descricao: "",
    plancontas_codigo: "",
    plancontas_nivel_superior_id: "",
    plancontas_unin_id: "",
    plancontas_ativo: true,
    parametros_financeiro: {
      paramfin_id: "",
      paramfin_aluno: "I",
      paramfin_assessor: "I",
      paramfin_funcionario: "I",
      paramfin_professor: "I",
      paramfin_caixa: "I",
      paramfin_parcelamento: "",
      paramfin_duplo: "",
      paramfin_recorrente: "",
      paramfin_relatorio: "",
      paramfin_material_id: "",
      paramfin_agrupar: "",
      paramfin_recepcao: "",
    }
  },
  produto: {
    prod_descricao: "",
    prod_id: ""
  },
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class PlanoContasView extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'plano-contas')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_visualizar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.planoContasId) {
      try {
        const { data: planoContas } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas/${params.planoContasId}`, this.getToken())

        let parametros_financeiro = this.state.planoContas.parametros_financeiro

        if (planoContas.parametros) {
          this.setState({
            informarParametros: true,
          })

          parametros_financeiro = {
            paramfin_id: planoContas.parametros.paramfin_id,
            paramfin_aluno: planoContas.parametros.paramfin_aluno,
            paramfin_assessor: planoContas.parametros.paramfin_assessor,
            paramfin_funcionario: planoContas.parametros.paramfin_funcionario,
            paramfin_professor: planoContas.parametros.paramfin_professor,
            paramfin_caixa: planoContas.parametros.paramfin_caixa,
            paramfin_parcelamento: planoContas.parametros.paramfin_parcelamento,
            paramfin_duplo: planoContas.parametros.paramfin_duplo,
            paramfin_recorrente: planoContas.parametros.paramfin_recorrente,
            paramfin_relatorio: planoContas.parametros.paramfin_relatorio,
            paramfin_material_id: planoContas.parametros.paramfin_material_id,
            paramfin_agrupar: planoContas.parametros.paramfin_agrupar,
            paramfin_recepcao: planoContas.parametros.paramfin_recepcao,
          }
        }

        this.setState({
          planoContas: {
            plancontas_id: planoContas.plancontas_id,
            plancontas_descricao: planoContas.plancontas_descricao,
            plancontas_codigo: planoContas.plancontas_codigo,
            plancontas_nivel_superior_id: planoContas.plancontas_nivel_superior_id ? planoContas.plancontas_nivel_superior_id : "",
            plancontas_unin_id: planoContas.plancontas_unin_id,
            plancontas_ativo: planoContas.plancontas_ativo,
            parametros_financeiro: parametros_financeiro
          },
          update: true,
          loading: false
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o plano de contas, tente mais tarde"
        })

        this.backPage()
      }
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/plano_contas");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { planoContas } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div id="view">
              <Grid container spacing={1} direction="row" className="borderBottom" >
                <Grid item md={6} xs={12} sm={12}>
                  <h1 className="titulo">Plano de Contas</h1>
                </Grid>
                <Grid item md={3} xs={6} sm={6}>
                  <Link to={`/plano_contas`}>
                    <Button className="btnCadastrar" variant="contained" color="secondary">
                      Voltar
                    </Button>
                  </Link>
                </Grid>
                <Grid item md={3} xs={6} sm={6}>
                  <Link to={`/plano_contas/cadastro/${planoContas.plancontas_id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Editar Plano
                  </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Codigo" variant="outlined" size="small" value={planoContas.plancontas_codigo} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Nome" variant="outlined" size="small" value={planoContas.plancontas_descricao} disabled />
                </Grid>
                {planoContas.parametros_financeiro.material &&
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="Nome Material" variant="outlined" size="small" value={planoContas.parametros_financeiro.material.prod_descricao} disabled />
                  </Grid>
                }
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Ativo" variant="outlined" size="small" value={planoContas.plancontas_ativo ? 'Ativo' : 'Inativo'} disabled />
                </Grid>
              </Grid>
              <br />
              <hr />
              {planoContas.parametros_financeiro.paramfin_id &&
                <div>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField className="input" label="Aluno" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_aluno} disabled />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField className="input" label="Assessor" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_assessor} disabled />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField className="input" label="Caixa / Banco" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_caixa} disabled />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField className="input" label="Colaborador" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_funcionario} disabled />
                    </Grid>
                    <Grid item md={2} xs={12} sm={3}>
                      <TextField className="input" label="Professor" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_professor} disabled />
                    </Grid>
                  </Grid>
                  <br />
                  <hr />
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField className="input" label="Parcelamento" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_parcelamento} disabled />
                    </Grid>
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField className="input" label="Duplo Lançamento" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_duplo ? 'Sim' : 'Não'} disabled />
                    </Grid>
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField className="input" label="Recorrente" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_recorrente ? 'Sim' : 'Não'} disabled />
                    </Grid>
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField className="input" label="Relatório" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_relatorio ? 'Sim' : 'Não'} disabled />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={4}>
                      <TextField className="input" label="Recepção" variant="outlined" size="small" value={planoContas.parametros_financeiro.paramfin_recepcao ? 'Liberado' : 'Bloqueado'} disabled />
                    </Grid>
                  </Grid>
                </div>
              }
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main >
        <Nav />
        <Footer history={this.props.history} />
      </div >
    )
  }
}