import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '../../../components/Table'
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import axios from 'axios'
import ModalExibeBoleto from './../../../components/modals/ExibeBoleto'
import ModalAlterarFormaPagto from './../../../components/modals/AlterarFormaPagto'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ModalErro from '../../../components/modals/Erro'
import real from '../../../services/real'
import Alert from './../../../components/templates/Alert'
import ContaPagarReceberCadastro from '../../contaPagarReceber/components/Cadastro'
import PrintIcon from '@material-ui/icons/Print';
import { saveAs } from 'file-saver';

const initalState = {
  financeiros: {
    list: []
  },
  planosConta: {
    list: []
  },
  cabecalhoTabelaFinanceiro: [
    { id: 'parcont_datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
    { id: 'contpr_descricao', numeric: false, disablePadding: false, label: 'Descrição' },
    { id: 'parcont_documento', numeric: false, disablePadding: false, label: 'Doc.' },
    { id: 'formpag_descricao', numeric: false, disablePadding: false, label: 'Forma Pgto' },
    { id: 'parcont_situacao', numeric: false, disablePadding: false, label: 'Situação' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: false, label: 'Valor' },
    { id: 'parcont_multajuros', numeric: false, disablePadding: false, label: 'Juros/Multas ou Desconto' },
    { id: 'parcont_valorpagamento', numeric: false, disablePadding: false, label: 'Recebido' },
  ],
  acoesTabelaFinanceiro: ['update', 'imprimirBoleto', 'selecionarParcela'],
  loading: true,
  filtro: {
    contpr_tipo: "A RECEBER",
    plancontas_id: "",
    contpr_descricao: "",
    data_inicial: "",
    data_final: "",
    parcont_situacao: ""
  },
  openModalLancamento: false,
  contasFinanceira: {
    list: []
  },
  erro: {
    titulo: "",
    descricao: ""
  },
  openModalExibeBoleto: false,
  boletoSelecionado:{
    tipo_transaction: 'BOLETO',
    bank_slip:{
      url_slip_pdf: ''
    }
  },
  parcelasSelecionadas: [],
  openModalAlterarFormaPagto: false,
  formaSelecionada:{
    formpag_id: '',
    contfin_id: ''
  },
  formasPagamento: {
    list: []
  },
  disabledButtons: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

export default class Financeiro extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {

    this.setState({
      financeiros: {
        list: []
      }
    })

    const ultima_tela_financeiro = {
      tela: 'aluno',
      matricula: this.props.aluno.aluno_matricula
    }

    localStorage.setItem('ultima_tela_financeiro', JSON.stringify(ultima_tela_financeiro));

    const caixa = JSON.parse(localStorage.getItem('caixa'))
    this.setState({ logado: { caixa } })

    const filtro_financeiro_aluno = JSON.parse(localStorage.getItem('filtro_financeiro_aluno'))

    if (filtro_financeiro_aluno) {
      filtro_financeiro_aluno.contpr_tipo = "A RECEBER"
      this.setState({
        filtro: filtro_financeiro_aluno
      })
    }else {

      let data7Dias = new Date()
      data7Dias.setDate(data7Dias.getDate() + 15)
      data7Dias = moment(data7Dias).format('YYYY-MM-DD')

      let data7DiasAtras = new Date()
      data7DiasAtras.setDate(data7DiasAtras.getDate() - 15)
      data7DiasAtras = moment(data7DiasAtras).format('YYYY-MM-DD')

      this.setState({
        filtro: {
          contpr_tipo: "A RECEBER",
          plancontas_id: "",
          contpr_descricao: "",
          data_inicial: "",
          data_final: "",
          parcont_situacao: "A VENCER + VENCIDO"
        }
      })
    }

    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      // const planoContas = data.filter(param => {
      //   if (
      //     !caixa ||
      //     (caixa && param.parametros && param.parametros.paramfin_aluno != 'I')
      //   ) {
      //     return {
      //       plancontas_id: param.plancontas_id,
      //       plancontas_codigo: param.plancontas_codigo,
      //       plancontas_descricao: param.plancontas_descricao,
      //       plancontas_ativo: param.plancontas_ativo,
      //     }
      //   }
      // })

      const planoContas = data.sort((a, b) => (a.plancontas_descricao > b.plancontas_descricao) ? 1 : ((b.plancontas_descricao > a.plancontas_descricao) ? -1 : 0)).filter(param => {
        return {
          plancontas_id: param.plancontas_id,
          plancontas_codigo: param.plancontas_codigo,
          plancontas_descricao: param.plancontas_descricao,
          plancontas_ativo: param.plancontas_ativo,
        }
      })

      this.setState({
        planosConta: {
          list: planoContas
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {

      const { filtro } = this.state

      const { data: financeiros } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/financeiro/${this.props.aluno.aluno_matricula}`, filtro, this.getToken())

      const financeirosAux = []

      financeiros.forEach(financeiro => {
        financeirosAux.push({
          _id: financeiro.parcont_id,
          contpr_descricao: financeiro.contpr_descricao + ' - Parc: ' + financeiro.parcont_num + '/' + financeiro.contpr_numparcela,
          contpr_tipo: financeiro.contpr_tipo,
          parcont_datavencimento: moment(financeiro.parcont_datavencimento).format('DD/MM/YYYY'),
          datavencimentoOrder: moment(financeiro.parcont_datavencimento).format('YYYYMMDD'),
          parcont_documento: financeiro.parcont_documento,
          plancontas_descricao: financeiro.plancontas_descricao,
          formpag_descricao: financeiro.formpag_descricao,
          valpag_id: financeiro.valpag_id,
          parcont_situacao: financeiro.parcont_situacao,
          parcont_valorparcela: 'R$ ' + real( financeiro.contpr_tipo === 'RECEBER' ? financeiro.parcont_valorparcela : (financeiro.parcont_valorparcela * -1)),
          parcont_valorpagamento: 'R$ ' + (financeiro.parcont_situacao === 'BAIXADO' ? real( financeiro.contpr_tipo === 'RECEBER' ? financeiro.parcont_valorpagamento : (financeiro.parcont_valorpagamento * -1)) === '0,00' ? '-' : real( financeiro.contpr_tipo === 'RECEBER' ? financeiro.parcont_valorpagamento : (financeiro.parcont_valorpagamento * -1)) : '0,00'),
          parcont_multajuros: financeiro.parcont_multajuros ? real(financeiro.parcont_multajuros) : real(0),
          parcont_boleto_digitable_line: financeiro.parcont_boleto_digitable_line,
          parcont_boleto_transaction_id: financeiro.parcont_boleto_transaction_id,
          parcont_boleto_link: financeiro.parcont_boleto_link,
          parcont_tipo_transaction: financeiro.parcont_tipo_transaction,
          formpag_slug: financeiro.formpag_slug,
          habilita_pix_boleto: financeiro.parceiro ? true: false
        })
      });

      this.setState({
        financeiros: {
          list: financeirosAux
        }
      })

      this.setState({
        loading: false
      })
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: response.data.message
      })

      // this.backPage()
    }
  }

  async consultaContaFinanceira(unidade_id){
    try {
      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${unidade_id}`, this.getToken())

      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  backPage(timeout = 1000) {
    setTimeout(() => {
      window.location.reload()
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })

  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loadingTable: true,
      financeiros: {
        list: []
      }
    })

    localStorage.setItem('filtro_financeiro_aluno', JSON.stringify(filtro));

    try {

      const { data: financeiros } = await axios.post(`${process.env.REACT_APP_API_URL}/aluno/financeiro/${this.props.aluno.aluno_matricula}`, filtro, this.getToken())

      const financeirosAux = []

      financeiros.forEach(financeiro => {
        financeirosAux.push({
          _id: financeiro.parcont_id,
          contpr_descricao: financeiro.contpr_descricao + ' - Parc: ' + financeiro.parcont_num + '/' + financeiro.contpr_numparcela,
          contpr_tipo: financeiro.contpr_tipo,
          parcont_datavencimento: moment(financeiro.parcont_datavencimento).format('DD/MM/YYYY'),
          datavencimentoOrder: moment(financeiro.parcont_datavencimento).format('YYYYMMDD'),
          plancontas_descricao: financeiro.plancontas_descricao,
          formpag_descricao: financeiro.formpag_descricao,
          parcont_situacao: financeiro.parcont_situacao,
          valpag_id: financeiro.valpag_id,
          parcont_valorparcela: 'R$ ' + real( financeiro.contpr_tipo === 'RECEBER' ? financeiro.parcont_valorparcela : (financeiro.parcont_valorparcela * -1)),
          parcont_valorpagamento:'R$ ' + financeiro.parcont_situacao === 'BAIXADO' ? real( financeiro.contpr_tipo === 'RECEBER' ? financeiro.parcont_valorpagamento : (financeiro.parcont_valorpagamento * -1)) === '0,00' ? '-' : real( financeiro.contpr_tipo === 'RECEBER' ? financeiro.parcont_valorpagamento : (financeiro.parcont_valorpagamento * -1)) : 'R$ 0,00',
          parcont_multajuros: financeiro.parcont_multajuros ? real(financeiro.parcont_multajuros) : real(0),
          parcont_documento: financeiro.parcont_documento,
          parcont_boleto_digitable_line: financeiro.parcont_boleto_digitable_line,
          parcont_boleto_transaction_id: financeiro.parcont_boleto_transaction_id,
          parcont_boleto_link: financeiro.parcont_boleto_link,
          parcont_tipo_transaction: financeiro.parcont_tipo_transaction,
          formpag_slug: financeiro.formpag_slug,
          habilita_pix_boleto: financeiro.parceiro ? true: false
        })
      });

      this.setState({
        financeiros: {
          list: financeirosAux
        },
        loadingTable: false
      })


    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async toogleModalContas() {

    await this.consultaContaFinanceira(this.props.aluno.aluno_unin_id)

    this.setState({
      openModalLancamento: !this.state.openModalLancamento
    })
  }

  async imprimirBoleto(row){
    

    if(row.parcont_boleto_transaction_id){

      if(row.parcont_tipo_transaction === 'PIX'){
        if(row.parcont_situacao === 'VENCIDO'){
          
          this.setState({
            alerta: {
              open: true,
              severity: 'info',
              message: 'Atualizando PIX...'
            }
          })

          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pix/atualizaPix`, {parcela_id: row._id}, this.getToken())

          this.setState({
            boletoSelecionado: {
              tipo_transaction: 'PIX',
              bank_slip:{
                url_slip_pdf: data.pix_code.qrcode_image_url,
                boleto_digitable_line: data.pix_code.emv
              }
            }
          })
    
          this.setState({
            openModalExibeBoleto: true
          })

          this.setState({
            alerta: {
              open: false,
              severity: 'info',
              message: ''
            }
          })
          
          this.componentWillMount()
        }else{
          this.setState({
            boletoSelecionado:{
              tipo_transaction: row.parcont_tipo_transaction,
              bank_slip:{
                url_slip_pdf: row.parcont_boleto_link,
                boleto_digitable_line: row.parcont_boleto_digitable_line
              }
            },
            openModalExibeBoleto: true
          })
        }
      }else{
        this.setState({
          boletoSelecionado:{
            tipo_transaction: row.parcont_tipo_transaction,
            bank_slip:{
              url_slip_pdf: row.parcont_boleto_link,
              boleto_digitable_line: row.parcont_boleto_digitable_line
            }
          },
          openModalExibeBoleto: true
        })
      }


    }else{
      if(row.formpag_slug === 'boleto'){
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: row._id}, this.getToken())
     
        this.setState({
          boletoSelecionado: {
            tipo_transaction: 'BOLETO',
            bank_slip:{
              url_slip_pdf: data.bank_slip.url_slip_pdf,
              boleto_digitable_line: data.bank_slip.digitable_line
            }
          }
        })
  
        this.setState({
          openModalExibeBoleto: true
        })

        this.setState({
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        })
        
        this.componentWillMount()
      }else if (row.formpag_slug === 'pix'){
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: row._id}, this.getToken())

        this.setState({
          boletoSelecionado: {
            tipo_transaction: 'PIX',
            bank_slip:{
              url_slip_pdf: data.pix_code.qrcode_image_url,
              boleto_digitable_line: data.pix_code.emv
            }
          }
        })
  
        this.setState({
          openModalExibeBoleto: true
        })

        this.setState({
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        })
        
        this.componentWillMount()
      }
    }
  }

  handleModalExibeBoleto(){
    this.setState({openModalExibeBoleto: false})
  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.handleOpenDialog({
      titulo: 'Texto copiado',
      descricao: 'Texto copiado'
    })
  
    return
    
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  selecionarParcela(row){
    const {parcelasSelecionadas} = this.state

    const index = parcelasSelecionadas.findIndex(param => param === row._id)

    if(index !== -1){
      parcelasSelecionadas.splice(index, 1);
    }else{
      parcelasSelecionadas.push(row._id)
    }

    this.setState({
      parcelasSelecionadas
    })
  }

  async alterarFormaPagamento(){

    try {

      const aluno = this.props.aluno
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())
      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${aluno.aluno_unin_id}`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        },
        contasFinanceira: {
          list: contasFinanceira
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      openModalAlterarFormaPagto: true
    })
  }

  handleModalAlterarFormaPagto(){
    this.setState({
      openModalAlterarFormaPagto: false,
      formaSelecionada:{
        formpag_id: '',
        contfin_id: ''
      },
      disabledButtons: false
    })
  }

  updateFieldFormaPagto(event){
    const {formaSelecionada} = this.state
    formaSelecionada[event.target.name] = event.target.value
    this.setState({formaSelecionada})
  }

  async confirmarAlterarFormaPagto(value){
    if(value){
      const {formaSelecionada, parcelasSelecionadas} = this.state

      this.setState({disabledButtons: true})

      if(formaSelecionada.formpag_id === ''){
        this.handleOpenDialog({
          titulo: "Erro no Cadastro!",
          descricao: "Informar a forma de pagamento!"
        })
        this.setState({disabledButtons: false})
        return
      }

      
      try {
        const dados = {
          formpag_id: parseInt(formaSelecionada.formpag_id),
          contfin_id: parseInt(formaSelecionada.contfin_id),
          parcelasSelecionadas
        }
  
        if(dados.contfin_id === '' || !dados.contfin_id){
          delete dados.contfin_id
        }
        
        this.setState({
          alerta: {
            open: true,
            severity: 'info',
            message: 'Ajustando Financerio...'
          }
        })
        
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/parcelaConta/alterarFormaPagto`, dados, this.getToken())
        
        for (const parcela_id of data.parcelas_criadas) {
          if(data.forma_pagto === 'boleto'){
            await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: parcela_id}, this.getToken())
          }else if (data.forma_pagto === 'pix'){
            await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: parcela_id}, this.getToken())
          }
        }
        
        this.setState({disabledButtons: false})

        window.location.reload()

      } catch ({ response }) {
        this.setState({disabledButtons: false})
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }
    }else{
      this.handleModalAlterarFormaPagto()
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async imprimirContas(){
    const {financeiros} = this.state
  
    this.setState({
      loadingImprimir: true
    })

    try {
      
      let filtro = {
        relatorio: 'contas-pagar-receber-aluno',
        data_inicial: this.state.filtro.data_inicial,
        data_final: this.state.filtro.data_final,
        filtroTela: this.state.filtro,
        unin_id: this.props.aluno.aluno_unin_id,
        list: financeiros.list,
        aluno: this.props.aluno
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingImprimir: false
      })

      saveAs(pdfBlob, 'Contas-Pagar-Receber-Aluno.pdf')


    } catch (error) {
      this.setState({
        loadingImprimir: false
      })
      console.log(error)
    }
  }

  render() {
    const { financeiros, filtro, logado, planosConta } = this.state

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Financeiro do Aluno...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Tipo"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="contpr_tipo"
                  value={filtro.contpr_tipo}
                  onChangeCapture={(e) => this.updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  {!logado.caixa &&
                    <option value=""></option>
                  }
                  {!logado.caixa &&
                    <option value="A PAGAR">A PAGAR</option>
                  }
                  <option value="A RECEBER">A RECEBER</option>
                </TextField>
              </Grid>
              <Grid item md={4} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Plano de Contas"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="plancontas_id"
                  value={filtro.plancontas_id}
                  onChangeCapture={(e) => this.updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""></option>
                  {planosConta.list.map(plano => {
                    return (
                      <option key={plano.plancontas_id} value={plano.plancontas_id}>{plano.plancontas_descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12} sm={6}>
                <TextField className="input" label="Descrição" variant="outlined" size="small" name="contpr_descricao" value={filtro.contpr_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Situação"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="parcont_situacao"
                  value={filtro.parcont_situacao}
                  onChangeCapture={(e) => this.updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""></option>
                  <option value="A VENCER + VENCIDO">A VENCER + VENCIDO</option>
                  <option value="A VENCER">A VENCER</option>
                  <option value="BAIXADO">BAIXADO</option>
                  <option value="VENCIDO">VENCIDO</option>
                  <option value="ESTORNADO">ESTORNADO</option>
                  <option value="CANCELADO">CANCELADO</option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => this.filtrar()}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => this.toogleModalContas()}
                  startIcon={<AddIcon />}
                >
                  Novo Lançamento
                </Button>
              </Grid>
            </Grid>            
            <Grid container direction="row" spacing={1}>
              {this.state.parcelasSelecionadas.length > 0 && 
                <Grid item md={7} xs={12} sm={8}></Grid>
              }
              {this.state.parcelasSelecionadas.length === 0 && 
                <Grid item md={10} xs={12} sm={8}></Grid>
              }
              {this.state.parcelasSelecionadas.length > 0 &&
                <Grid item md={3} xs={12} sm={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => this.alterarFormaPagamento()}
                  >
                    Alterar Formas de Pagamento
                  </Button>
                </Grid>
              }
              <Grid item md={2} xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => this.imprimirContas()}
                  startIcon={<PrintIcon/>}
                >
                  Imprimir
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <Table 
                  urlUpdate="/conta_pagar_receber/cadastro/" 
                  headCell={this.state.cabecalhoTabelaFinanceiro} 
                  rows={financeiros.list} 
                  imprimirBoleto={e => this.imprimirBoleto(e)}
                  acoes={this.state.acoesTabelaFinanceiro} 
                  parcelasSelecionadas={this.state.parcelasSelecionadas}
                  selecionarParcela={e => this.selecionarParcela(e)}
                />
              </Grid>
            </Grid>
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              open={this.state.openModalLancamento}
              onClose={e => this.toogleModalContas(e)}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogContent>
                <ContaPagarReceberCadastro contasFinanceira={this.state.contasFinanceira.list} {...this.props} />
              </DialogContent>
              <DialogActions>
                <Button onClick={e => this.toogleModalContas(e)} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>

            <ModalExibeBoleto 
              open={this.state.openModalExibeBoleto}
              dados={this.state.login}
              handleClose={e => this.handleModalExibeBoleto()} 
              boletoSelecionado={this.state.boletoSelecionado}
              copyLink={e => this.copyLink(e)}
            />

            <ModalAlterarFormaPagto 
              open={this.state.openModalAlterarFormaPagto}
              handleClose={e => this.handleModalAlterarFormaPagto()}
              dados={this.state.formaSelecionada}
              formasPagamento={this.state.formasPagamento.list}
              contasFinanceira={this.state.contasFinanceira.list}
              updateField={e => this.updateFieldFormaPagto(e)}
              confirmar={e => this.confirmarAlterarFormaPagto(e)}
              disabledButtons={this.state.disabledButtons}
            />

            <ModalErro 
              open={this.state.modalErro} 
              titulo={this.state.erro.titulo} 
              descricao={this.state.erro.descricao} 
              handleClose={e => this.handleCloseErro(e)} 
            />        

            <Alert 
              open={this.state.alerta.open}
              handleClose={e => this.handleCloseAlerta()} 
              severity={this.state.alerta.severity}
              message={this.state.alerta.message} 
            />
          </div>
        }

      </React.Fragment>
    )
  }
}