import React, { Component } from 'react'
import './Templates.scss'

import { Grid } from '@material-ui/core'

import { KeyboardArrowRight } from '@material-ui/icons';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import StoreIcon from '@material-ui/icons/Store';
import BookIcon from '@material-ui/icons/Book';
import TodayIcon from '@material-ui/icons/Today';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PieChartIcon from '@material-ui/icons/PieChart';
import NavItens from './NavItens'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MoneyIcon from '@material-ui/icons/Money';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BuildIcon from '@material-ui/icons/Build';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const initalState = {
  itensMenu: [
    {
      title: "Home",
      subMenu: [],
      icone: [<HomeIcon className="iconMenu" />],
      href: "/home",
      slug: ['home'],
      hiddenSubMenu: true,
      index: 1
    },
    {
      title: "Franquias",
      subMenu: [
        {
          title: "Franqueados",
          subMenu: [],
          slug: ['franqueado'],
          icone: [<AccountTreeIcon className="iconMenu" />],
          href: "/franqueado",
          index: 2.1
        },
        {
          title: "Unidades",
          subMenu: [],
          slug: ['unidade'],
          icone: [<SchoolIcon className="iconMenu" />],
          href: "/unidade",
          index: 2.2
        },
        {
          title: "Contratos das Unidades",
          subMenu: [],
          slug: ['contrato-unidade'],
          icone: [<DescriptionIcon className="iconMenu" />],
          href: "/contratos_unidades",
          index: 2.3
        }
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 2
    },
    {
      title: "Recursos Humanos",
      subMenu: [
        {
          title: "Departamentos",
          subMenu: [],
          slug: ['departamento'],
          icone: [<WorkIcon className="iconMenu" />],
          href: "/departamentos",
          index: 3.1
        },
        {
          title: "Cargos",
          subMenu: [],
          slug: ['cargo'],
          icone: [<WorkIcon className="iconMenu" />],
          href: "/cargo",
          index: 3.2
        },
        {
          title: "Função",
          subMenu: [],
          slug: ['funcao'],
          icone: [<WorkIcon className="iconMenu" />],
          href: "/funcao",
          index: 3.3
        },
        {
          title: "Colaboradores",
          subMenu: [],
          slug: ['funcionario'],
          icone: [<AccessibilityIcon className="iconMenu" />],
          href: "/funcionario",
          index: 3.5
        },
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 3
    },
    {
      title: "Produtos",
      subMenu: [
        {
          title: "Grupo Produtos",
          subMenu: [],
          slug: ['grupo-produtos'],
          icone: [<ShoppingBasketIcon className="iconMenu" />],
          href: "/grupo_produtos",
          index: 4.1
        },
        {
          title: "Sub-Grupo Produtos",
          subMenu: [],
          slug: ['sub-grupo-produtos'],
          icone: [<ShoppingBasketIcon className="iconMenu" />],
          href: "/sub_grupo_produtos",
          index: 4.2
        },
        {
          title: "Produtos",
          subMenu: [],
          slug: ['produto'],
          icone: [<ShoppingBasketIcon className="iconMenu" />],
          href: "/produtos",
          index: 4.3
        },
        {
          title: "Tabela de Preços",
          subMenu: [],
          slug: ['tabela-precos'],
          icone: [<StoreIcon className="iconMenu" />],
          href: "/tabela_preco",
          index: 4.4
        },
        {
          title: "Codigo Alfanum.",
          subMenu: [],
          slug: ['codigo-alfa'],
          icone: [<VpnKeyIcon className="iconMenu" />],
          href: "/codigo_alfa",
          index: 4.5
        },
        {
          title: "Venda de Produtos Central",
          subMenu: [],
          slug: ['venda-prod-central-unidade'],
          icone: [<ShoppingCartIcon className="iconMenu" />],
          href: "/venda_prod_central_unidade",
          index: 4.6
        },
        {
          title: "Compra de Produtos Unidade",
          subMenu: [],
          slug: ['compra-prod-unidade-central'],
          icone: [<ShoppingCartIcon className="iconMenu" />],
          href: "/compra_prod_unidade_central",
          index: 4.7
        }
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 4
    },
    {
      title: "Aulas",
      subMenu: [
        {
          title: "Book",
          subMenu: [],
          slug: ['books'],
          icone: [<BookIcon className="iconMenu" />],
          href: "/book",
          index: 5.1
        },
        {
          title: "Configurações Aditivos",
          subMenu: [],
          slug: ['config-aditivo-curso'],
          icone: [<BuildIcon className="iconMenu" />],
          href: "/config_aditivos",
          index: 5.2
        },
        {
          title: "Curso",
          subMenu: [],
          slug: ['curso'],
          icone: [<SchoolIcon className="iconMenu" />],
          href: "/curso",
          index: 5.3
        },
        {
          title: "Aulas",
          subMenu: [],
          slug: ['aulas'],
          icone: [<SchoolIcon className="iconMenu" />],
          href: "/aula",
          index: 5.4
        },
        {
          title: "Abrir Aulas Fixas",
          subMenu: [],
          slug: ['abrir_aula_fixas'],
          icone: [<DescriptionIcon className="iconMenu" />],
          href: "/aulas_fixas",
          index: 5.5
        },
        {
          title: "Schedule",
          subMenu: [],
          slug: ['schedule'],
          icone: [<ScheduleIcon className="iconMenu" />],
          href: "/schedule",
          index: 5.6
        },
        {
          title: "Baixar Aula",
          subMenu: [],
          slug: ['baixa-aula'],
          icone: [<SaveAltIcon className="iconMenu" />],
          href: "/baixa_aula",
          index: 5.7
        }
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 5
    },
    {
      title: "Alunos",
      subMenu: [
        {
          title: "Alunos",
          subMenu: [],
          slug: ['alunos', 'nova-matricula'],
          icone: [<SchoolIcon className="iconMenu" />],
          href: "/aluno",
          index: 6.1
        },
        {
          title: "Rematrícula",
          subMenu: [],
          slug: ['nova-matricula'],
          icone: [<SchoolIcon className="iconMenu" />],
          href: "/nova-matricula",
          index: 6.1
        },
        // {
        //   title: "Cadastro EAD",
        //   subMenu: [],
        //   slug: ['nova-matricula'],
        //   icone: [<SchoolIcon className="iconMenu" />],
        //   href: "/ead/cadastro",
        //   index: 6.1
        // }
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 6
    },
    {
      title: "Financeiro",
      subMenu: [
        {
          title: "Planos de Pagamento Curso",
          subMenu: [],
          slug: ['plano-pagamento-curso'],
          icone: [<MonetizationOnIcon className="iconMenu" />],
          href: "/plano_pagamento",
          index: 7.1
        },
        {
          title: "Planos de Contas",
          subMenu: [],
          slug: ['plano-contas'],
          icone: [<MonetizationOnIcon className="iconMenu" />],
          href: "/plano_contas",
          index: 7.2
        },
        {
          title: "Contas Financeiras",
          subMenu: [],
          slug: ['conta-financeira'],
          icone: [<AccountBalanceIcon className="iconMenu" />],
          href: "/conta_financeira",
          index: 7.3
        },
        {
          title: "Movimentações",
          subMenu: [],
          slug: ['movimentacao'],
          icone: [<SwapHorizIcon className="iconMenu" />],
          href: "/movimentacao_financeira",
          index: 7.4
        },
        {
          title: "Contas a Pagar/Receber",
          subMenu: [],
          slug: ['contas-pagar-receber'],
          icone: [<MoneyIcon className="iconMenu" />],
          href: "/conta_pagar_receber",
          index: 7.5
        },
        {
          title: "Caixa",
          subMenu: [],
          slug: ['caixa'],
          icone: [<AccountBalanceWalletIcon className="iconMenu" />],
          href: "/caixa",
          index: 7.6
        },
        {
          title: "Fechamento de Caixa",
          subMenu: [],
          slug: ['caixa_fechamento'],
          icone: [<DoneAllIcon className="iconMenu" />],
          href: "/caixa_fechamento",
          index: 7.7
        },
        {
          title: "Demonstrativo Diário",
          subMenu: [],
          slug: ['demonstrativo_diario'],
          icone: [<AccountBalanceWalletIcon className="iconMenu" />],
          href: "/demonstrativo_diario",
          index: 7.8
        }
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 7
    },
    {
      title: "Usuário",
      subMenu: [
        {
          title: "Perfil",
          subMenu: [],
          slug: ['perfil'],
          icone: [<AssignmentIndIcon className="iconMenu" />],
          href: "/perfil",
          index: 8.1
        },
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 8
    },
    {
      title: "Escola",
      subMenu: [
        {
          title: "Feriado",
          subMenu: [],
          slug: ['feriados'],
          icone: [<TodayIcon className="iconMenu" />],
          href: "/feriado",
          index: 9.1
        },
        {
          title: "Documento",
          subMenu: [],
          slug: ['template-documentos'],
          icone: [<DescriptionIcon className="iconMenu" />],
          href: "/documento",
          index: 9.2
        },
        {
          title: "Lembretes",
          subMenu: [],
          slug: ['lembretes'],
          icone: [<TodayIcon className="iconMenu" />],
          href: "/lembretes",
          index: 9.3
        },
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 9
    },
    {
      title: "Relatórios",
      subMenu: [
        {
          title: "Pedagógico",
          subMenu: [],
          slug: ['rel-pedagogico'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/rel_pedagogico",
          index: 10.1
        },
        {
          title: "Administrativo",
          subMenu: [],
          slug: ['rel-administrativo'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/rel_administrativo",
          index: 10.2
        },
        {
          title: "Comercial",
          subMenu: [],
          slug: ['rel-comercial'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/rel_comercial",
          index: 10.2
        },
        {
          title: "Financeiro",
          subMenu: [],
          slug: ['rel-financeiro'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/rel_financeiro",
          index: 10.3
        }
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 10
    },
    {
      title: "Ranking",
      subMenu: [
        {
          title: "Relatórios",
          subMenu: [],
          slug: ['rel-ranking'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/rel_ranking",
          index: 11.1
        },
        {
          title: "Fly High",
          subMenu: [],
          slug: ['fly-high'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/fly_high",
          index: 11.2
        },
        {
          title: "Carteira de Mensalidades",
          subMenu: [],
          slug: ['carteira-mensalidades'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/carteira_mensalidades",
          index: 11.3
        },
        {
          title: "Pontuação Fly High",
          subMenu: [],
          slug: ['pontuacao-fly-high'],
          icone: [<DescriptionIcon className="iconMenu" />],
          href: "/pontos_fly_high",
          index: 11.4
        },
        {
          title: "Ranking BFF",
          subMenu: [],
          slug: ['ranking-bff'],
          icone: [<PieChartIcon className="iconMenu" />],
          href: "/ranking_bff",
          index: 11.4
        }
      ],
      icone: [<KeyboardArrowRight className="iconMenu" />],
      href: "",
      hiddenSubMenu: true,
      index: 11
    },
    {
      title: "Admin",
      subMenu: [
        {
          title: "Serviços Bancários",
          subMenu: [],
          slug: ['servicos-bancario'],
          icone: [<AccountBalanceIcon className="iconMenu" />],
          href: "/servicos_bancario",
          index: 12.1
        },
        {
          title: "Parceiros Bancários",
          subMenu: [],
          slug: ['parceiros-bancario'],
          icone: [<AccountBalanceIcon className="iconMenu" />],
          href: "/parceiros_bancario",
          index: 12.2
        },
        {
          title: "Central de Notificações",
          subMenu: [],
          slug: ['central-alerta'],
          icone: [<NotificationsIcon className="iconMenu" />],
          href: "/central_notificacao",
          index: 11.3
        },
        {
          title: "API WhatsApp",
          subMenu: [],
          slug: ['api-whatsapp'],
          icone: [<WhatsAppIcon className="iconMenu" />],
          href: "/api_whatsapp",
          index: 11.4
        },
        {
          title: "Cupom de Desconto",
          subMenu: [],
          slug: ['cupom-desconto'],
          icone: [<MoneyOffIcon className="iconMenu" />],
          href: "/cupom_desconto",
          index: 11.5
        },
        {
          title: "Configuração de Vendas Central",
          subMenu: [],
          slug: ['config-venda-central'],
          icone: [<SettingsIcon className="iconMenu" />],
          href: "/config_venda_central",
          index: 11.6
        }
      ],
      icone: 'fa fa-cog',
      href: "",
      hiddenSubMenu: true,
      index: 12
    }
  ],
  menuFiltrado: [],
  term: ""
}

function searching(term) {
  return function (x) {
    return x.title.toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Nav extends Component {

  state = { ...initalState }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))
    const caixa = JSON.parse(localStorage.getItem('caixa'))

    this.setState({ logado: { caixa } })

    const itensMenu = this.state.itensMenu

    let menuFiltrado = []

    if (perfil.per_unin_id) {
      menuFiltrado.push(itensMenu[0])

      for (const item of itensMenu) {
        if (item.slug && item.slug[0] === 'home') continue

        if (item.subMenu.length === 0) {
          menuFiltrado.push(item)
          continue
        }

        let itemMenuAux = { ...item }
        itemMenuAux.subMenu = []


        for (let j = 0; j < item.subMenu.length; j++) {
          if (item.subMenu[j].subMenu.length === 0) {
            const indexSubMenu = perfil.permissoes.findIndex(param => {
              if (param.tela.modulo.mod_slug === 'caixa' && !caixa) return false
              if (item.subMenu[j].slug.includes(param.tela.modulo.mod_slug)) return true
              return false
            })

            if (indexSubMenu !== -1) itemMenuAux.subMenu.push(item.subMenu[j])
          } else {

            let itemSubMenuAux = { ...item.subMenu[j] }
            itemSubMenuAux.subMenu = []

            for (let k = 0; k < item.subMenu[j].subMenu.length; k++) {
              const indexSubSubMenu = perfil.permissoes.findIndex(param => {
                if (param.tela.modulo.mod_slug === 'caixa' && !caixa) return false
                if (item.subMenu[j].subMenu[k].slug.includes(param.tela.modulo.mod_slug)) return true
                return false
              })

              if (indexSubSubMenu !== -1) {
                itemSubMenuAux.subMenu.push(item.subMenu[j].subMenu[k])
              }
            }

            if (itemSubMenuAux.subMenu.length > 0) {
              itemMenuAux.subMenu.push(itemSubMenuAux)
            }
          }

        }

        if (itemMenuAux.subMenu.length > 0) {
          menuFiltrado.push(itemMenuAux)
        }
      }
    } else {
      menuFiltrado = itensMenu
    }

    this.setState({
      menuFiltrado
    })

  }

  getDropdown() {
    return this.state.dropdown
  }

  dropdown(value) {
    const menuFiltrado = this.state.menuFiltrado

    const indexMenuAberto = menuFiltrado.findIndex(item => item.hiddenSubMenu === false)
    const index = menuFiltrado.findIndex(x => x.index === value)

    if (indexMenuAberto !== -1) {
      if (indexMenuAberto !== index) {
        menuFiltrado[indexMenuAberto].hiddenSubMenu = true
      }
    }

    menuFiltrado[index].hiddenSubMenu = !this.state.menuFiltrado[index].hiddenSubMenu
    this.setState({ menuFiltrado })
  }

  dropdownSubMenu(indexMenu, indexSubMenu) {
    const menuFiltrado = this.state.menuFiltrado

    const index = menuFiltrado.findIndex(x => x.index === indexMenu)

    const indexMenuAberto = menuFiltrado[index].subMenu.findIndex(item => item.hiddenSubMenu === false)

    const indexSubMenuAux = menuFiltrado[index].subMenu.findIndex(x => x.index === indexSubMenu)

    if (indexMenuAberto !== -1) {
      if (indexMenuAberto !== indexSubMenuAux) {
        menuFiltrado[index].subMenu[indexMenuAberto].hiddenSubMenu = true
      }
    }

    menuFiltrado[index].subMenu[indexSubMenuAux].hiddenSubMenu = !menuFiltrado[index].subMenu[indexSubMenuAux].hiddenSubMenu
    this.setState({ menuFiltrado })
  }

  searchHandler(event) {
    this.setState({ term: event.target.value })
  }

  // addFavorito(itemSubMenu) {
  //   console.log(itemSubMenu)
  // }

  render() {
    const menuFiltrado = this.state.menuFiltrado
    const term = this.state.term
    const logado = this.state.logado

    return (
      <aside id="aside" className="menu-area">
        <nav id="nav" className="menu">
          {/* <div className="inputMenu">
            <Grid container direction="row" >
              <Grid item md={12}>
                <TextField className='inputM' onChange={e => this.searchHandler(e)} label="Pesquisar" value={term} variant="outlined" size="small" />
              </Grid>
            </Grid>
          </div> */}
          {menuFiltrado.filter(searching(term)).map(itemMenu => {
            return (
              <div key={itemMenu.index}>
                {itemMenu.subMenu.length > 0 &&
                  <div className={`${!itemMenu.hiddenSubMenu ? "backSubMenu" : ""}`}>
                    <div className="itemNav" onClick={() => this.dropdown(itemMenu.index)}>
                      <Grid container direction="row">
                        <Grid item md={2}>
                          <KeyboardArrowRight className="iconMenu" />
                        </Grid>
                        <Grid item md={8}>
                          <span>{itemMenu.title}</span>
                        </Grid>
                      </Grid>
                    </div>
                    {!itemMenu.hiddenSubMenu &&
                      <div>
                        {itemMenu.subMenu.map(itemSubMenu => {
                          return (
                            <React.Fragment key={itemSubMenu.index}>
                              {itemSubMenu.subMenu.length > 0 &&
                                <React.Fragment>
                                  <div className={`${!itemSubMenu.hiddenSubMenu ? "backSubSubMenu" : ""}`}>
                                    <div className="itemNav" onClick={() => this.dropdownSubMenu(itemMenu.index, itemSubMenu.index)}>
                                      <Grid container direction="row">
                                        <Grid item md={1}></Grid>
                                        <Grid item md={2}>
                                          <KeyboardArrowRight className="iconMenu" />
                                        </Grid>
                                        <Grid item md={9}>
                                          <span>{itemSubMenu.title}</span>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    {!itemSubMenu.hiddenSubMenu &&
                                      <div>
                                        {itemSubMenu.subMenu.map(itemSubSubMenu => {
                                          return (
                                            <React.Fragment key={itemSubSubMenu.index}>
                                              {itemSubSubMenu.subMenu.length > 0 &&
                                                <React.Fragment>
                                                  <div className="itemNav" onClick={() => this.dropdownSubMenu(itemMenu.index, itemSubMenu.index, itemSubSubMenu.index)}>
                                                    <Grid container direction="row">
                                                      <Grid item md={2}></Grid>
                                                      <Grid item md={2}>
                                                        <KeyboardArrowRight className="iconMenu" />
                                                      </Grid>
                                                      <Grid item md={9}>
                                                        <span>{itemSubSubMenu.title}</span>
                                                      </Grid>
                                                    </Grid>
                                                  </div>
                                                </React.Fragment>
                                              }
                                              {itemSubSubMenu.subMenu.length === 0 &&
                                                <div className="itemNavSub">
                                                  <Grid container direction="row">
                                                    <Grid item md={2}></Grid>
                                                    <Grid item md={2}>
                                                      {itemSubSubMenu.icone[0]}
                                                    </Grid>
                                                    <Grid item md={8}>
                                                      <NavItens href={itemSubSubMenu.href} title={itemSubSubMenu.title} />
                                                    </Grid>
                                                    {/* <Grid item md={2}>
                                                      <StarIcon className="iconStar" onClick={() => this.addFavorito(itemSubSubMenu)} />
                                                    </Grid> */}
                                                  </Grid>
                                                </div>
                                              }
                                            </React.Fragment>
                                          )
                                        })}
                                      </div>
                                    }
                                  </div>
                                </React.Fragment>
                              }
                              {
                                itemSubMenu.subMenu.length === 0 &&
                                (
                                  (itemSubMenu.slug[0] === 'caixa' && logado.caixa) ||
                                  itemSubMenu.slug[0] !== 'caixa'
                                ) &&
                                <div className="itemNav">
                                  <Grid container direction="row">
                                    <Grid item md={1}></Grid>
                                    <Grid item md={2}>
                                      {itemSubMenu.icone[0]}
                                    </Grid>
                                    <Grid item md={9}>
                                      <NavItens href={itemSubMenu.href} title={itemSubMenu.title} />
                                    </Grid>
                                    {/* <Grid item md={2}>
                                      <StarIcon className="iconStar" onClick={() => this.addFavorito(itemSubMenu)} />
                                    </Grid> */}
                                  </Grid>
                                </div>
                              }
                            </React.Fragment>
                          )
                        })}
                      </div>
                    }
                  </div>
                }
                {itemMenu.subMenu.length === 0 &&
                  <div className="itemNav">
                    <Grid container direction="row">
                      <Grid item md={2}>
                        {itemMenu.icone[0]}
                      </Grid>
                      <Grid item md={10}>
                        <NavItens href={itemMenu.href} title={itemMenu.title} />
                      </Grid>
                    </Grid>
                  </div>
                }
              </div>
            )
          })}
        </nav>
      </aside>
    )
  }
}