import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import MaskedInput from 'react-text-mask';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import ModalErro from '../../../components/modals/Erro'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+',/\d/,/\d/,' ','(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const initalState = {
  aluno: {
    _id: "",
    pessoaFisica: {
      nome: "",
      sobrenome: "",
      apelido:"",
      cpf: "",
      rg: "",
      dataNascimento: "",
      idade: 0,
      maiorIdade: false
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "RESIDENCIAL",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "RESIDENCIAL",
      principal: true,
      index: 1
    }],
    contrato: {
      contm_data_inicio_vigencia: "",
      contm_duracao: "",
      contm_observacao: "",
      contm_curso_id: "",
      contm_ativo: true,
      contm_unin_id: ""
    }
  },
  responsavel: {
    pessoaFisica: {
      nome: "",
      cpf: "",
      rg: "",
      dataNascimento: ""
    },
    enderecos: [{
      rua: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      principal: true,
      tipoEndereco: "",
      index: 1
    }],
    emails: [{
      email: "",
      principal: true,
      index: 1
    }],
    contatos: [{
      numero: "",
      tipoContato: "",
      principal: true,
      index: 1
    }],
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesAltPessoa: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  erro: {
    titulo: "",
    descricao: ""
  },
  modalErro: false
}

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'alunos')[0]
    if (!permissoes) permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'nova-matricula')[0]

    let permissoesAltPessoa = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'adm-pessoas')[0]

    if (permissoesAltPessoa) {
      this.setState({
        permissoesAltPessoa
      })
    }

    if (permissoes) {
      let acoesTabela = []

      if (permissoes.perm_visualizar) {
        acoesTabela.push('viewAluno')
      }

      if (permissoes.perm_alterar) {
        acoesTabela.push('update')
      }
      this.setState({
        permissoes,
        acoesTabela
      })
    }

    const aluno = this.props.aluno

    this.setState({
      aluno: aluno
    })
  }

  copyLink(link){
    navigator.clipboard.writeText(link);

    this.handleOpenDialog({
      titulo: 'Link copiado',
      descricao: 'Link copiado'
    })
  
    return
    
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { aluno, permissoes, permissoesAltPessoa } = this.state

    console.log(aluno)

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados do Aluno...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={3} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Nome" value={aluno.pessoaFisica.nome} disabled />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Sobrenome" value={aluno.pessoaFisica.sobrenome} disabled InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Nome Social" value={aluno.pessoaFisica.apelido} disabled InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="CPF" value={aluno.pessoaFisica.cpf} disabled InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="RG" value={aluno.pessoaFisica.rg} disabled InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField type="date" fullWidth size="small" variant="outlined" className="input" label="Data de Nascimento" value={aluno.pessoaFisica.dataNascimento} disabled />
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">Endereços do Aluno: </h4>
              </Grid>
            </Grid>
            {aluno.enderecos.map(endereco => {
              return (
                <div className="cardEndereco" key={endereco.end_id}>
                  {endereco.principal &&
                    <h4>Endereço Principal:</h4>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="CEP" value={endereco.cep} disabled />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Rua" value={endereco.rua} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={endereco.numero} disabled />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Complemento" value={endereco.complemento} disabled />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Bairro" value={endereco.bairro} disabled />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Cidade" value={endereco.cidade} disabled />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Estado" value={endereco.estado} disabled />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={endereco.tipoEndereco} disabled />
                    </Grid>
                  </Grid>
                </div>
              )
            })}
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">Telefones do Aluno: </h4>
              </Grid>
            </Grid>
            {aluno.contatos.map(telefone => {
              return (
                <div className="cardEndereco" key={telefone.tel_id}>
                  {telefone.principal &&
                    <h4>Telefone Principal:</h4>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={telefone.numero} disabled InputProps={{ inputComponent: TextMaskCustom }} />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Tipo" value={telefone.tipoContato} disabled />
                    </Grid>
                  </Grid>
                </div>
              )
            })}
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <h4 className="titulo">E-mails do Aluno: </h4>
              </Grid>
            </Grid>
            {aluno.emails.map(email => {
              return (
                <div className="cardEndereco" key={email.ema_id}>
                  {email.principal &&
                    <h4>E-mail Principal:</h4>
                  }
                  <Grid container spacing={1} direction="row" className="mg_top_10">
                    <Grid item md={6} sm={6} xs={12}>
                      <TextField fullWidth size="small" variant="outlined" className="input" label="Número" value={email.email} disabled />
                    </Grid>
                  </Grid>
                </div>
              )
            })}

            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={4} xs={12} sm={8}>
                <TextField fullWidth size="small" variant="outlined" className="input" label="Link Portal Aluno" value={`${process.env.REACT_APP_URL}/portal_aluno/${aluno.contrato.resp_fin ? aluno.contrato.resp_fin.pesf_pessoa_id : aluno.pessoaFisica.pesf_pessoa_id}`} InputLabelProps={{ shrink: true }} disabled />
              </Grid>
              <Grid item md={2} xs={12} sm={4}>
                <Tooltip title='Copiar Link' aria-label="add">
                  <IconButton style={{marginTop: '-3px', marginLeft: '-10px'}} color="primary" aria-label="view" onClick={e => this.copyLink(`${process.env.REACT_APP_URL}/portal_aluno/${aluno.contrato.resp_fin ? aluno.contrato.resp_fin.pesf_pessoa_id : aluno.pessoaFisica.pesf_pessoa_id}`)}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              {permissoes.perm_alterar &&
                <Grid item md={3} xs={6} sm={6}>
                  <Link to={`/aluno/cadastro/${aluno._id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Editar Aluno
                    </Button>
                  </Link>
                </Grid>
              }
              {permissoesAltPessoa.perm_inserir &&
                <Grid item md={3} xs={6} sm={6}>
                  <Link to={`/aluno/cadastro/pessoa/${aluno._id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Trocar Pessoa
                    </Button>
                  </Link>
                </Grid>
              }

              <Grid item md={3} xs={6} sm={6}>
                <Link to={`/aluno`}>
                  <Button className="btnCadastrar" variant="contained" color="secondary">
                    Voltar
                </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        }
        <ModalErro 
          open={this.state.modalErro} 
          titulo={this.state.erro.titulo} 
          descricao={this.state.erro.descricao} 
          handleClose={e => this.handleCloseErro(e)} 
        />
      </React.Fragment>
    )
  }
}