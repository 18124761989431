import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@material-ui/core'
import CurrencyInput from './../mask/CurrencyInput'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import real  from '../../services/real'
import Table from './../Table'
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment'

function getModalStyle() {
  return {
   
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '92%',
    marginLeft: '4%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {dados, produtos, disabledButton} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Adicionar Serviço no Contrato</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={4}>
              <TextField
                id="standard-select-currency"
                select
                label="Serviço*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="prod_id"
                value={dados.prod_id}
                onChangeCapture={(e) => props.updateFieldServico(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value={dados.prod_id !== '' ? dados.prod_id : ''}> {dados.prod_id !== '' ? dados.prod_descricao : 'Selecionar Serviço'}  </option>
                {produtos.filter(param => param._id !== parseInt(dados.prod_id)).map((produto, key) => {
                  return (
                    <option key={key} value={produto._id}> {produto.prod_descricao} </option>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField className="input" type='number' label="Dia de Vencimento*" variant="outlined" size="small" name="dia_vencimento" value={dados.dia_vencimento} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField
                id="standard-select-currency"
                select
                label="Parâmetro*"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="parametro"
                value={dados.parametro}
                onChangeCapture={(e) => props.updateFieldServico(e)}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {dados.parametro === "" &&
                  <option value="">
                    Selecionar Parâmetro
                  </option>
                }
                <option value="FIXO">
                  Fixo
                </option>
                <option value="ALUNOS">
                  Por Alunos
                </option>
                <option value="NUM_WHATS">
                  Por Números do Whats
                </option>
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={dados.valor} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Inativa Unidade</FormLabel>
                <FormControlLabel className="mg_left_10"
                  control={
                    <Switch
                      name="inativa_unidade"
                      color="primary"
                      checked={dados.inativa_unidade}
                    />
                  }
                  size="small"
                  label={dados.inativa_unidade ? 'Sim' : 'Não'}
                  name="inativa_unidade"
                  onChange={(e) => props.updateFieldInativaUnidadeServico(e)}
                />
              </FormControl>
            </Grid>
            <Grid item md={1} xs={12} sm={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Ativo</FormLabel>
                <FormControlLabel className="mg_left_10"
                  control={
                    <Switch
                      name="Ativo"
                      color="primary"
                      checked={dados.ativo}
                    />
                  }
                  size="small"
                  label={dados.ativo ? 'Sim' : 'Não'}
                  name="ativo"
                  onChange={(e) => props.updateFieldAtivoServico(e)}
                />
              </FormControl>
            </Grid>
            {(dados.parametro !== "FIXO" && dados.parametro !== "") &&
              <React.Fragment>
                <Grid item md={2} xs={12} sm={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Informar Valor Mínimo</FormLabel>
                    <FormControlLabel className="mg_left_10"
                      control={
                        <Switch
                          name="Ativo"
                          color="primary"
                          checked={dados.habilita_valor_min}
                        />
                      }
                      size="small"
                      label={dados.habilita_valor_min ? 'Sim' : 'Não'}
                      name="habilita_valor_min"
                      onChange={(e) => props.updateFieldMinServico(e)}
                    />
                  </FormControl>
                </Grid>
                {dados.habilita_valor_min &&
                  <Grid item md={2} xs={12} sm={2}>
                    <TextField className="input" label="Valor Mínimo*" variant="outlined" size="small" name="valor_min" value={dados.valor_min} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
                  </Grid>
                }
                <Grid item md={2} xs={12} sm={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Informar Valor Máximo</FormLabel>
                    <FormControlLabel className="mg_left_10"
                      control={
                        <Switch
                          name="Ativo"
                          color="primary"
                          checked={dados.habilita_valor_max}
                        />
                      }
                      size="small"
                      label={dados.habilita_valor_max ? 'Sim' : 'Não'}
                      name="habilita_valor_max"
                      onChange={(e) => props.updateFieldMaxServico(e)}
                    />
                  </FormControl>
                </Grid>
                {dados.habilita_valor_max &&
                  <Grid item md={2} xs={12} sm={2}>
                    <TextField className="input" label="Valor Máximo*" variant="outlined" size="small" name="valor_max" value={dados.valor_max} onChange={(e) => props.updateFieldServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }}/>
                  </Grid>
                }
              </React.Fragment>
            }
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={disabledButton}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={disabledButton}>Adicionar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
