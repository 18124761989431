import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './main/App';
// require('module-alias/register')

import moment from 'moment';
import 'moment/locale/pt-br'  // without this line it didn't work
moment.locale('pt-br')

ReactDOM.render(<App />, document.getElementById('root'));