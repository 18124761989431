import React, { Component } from 'react'
import './CodigoAlfaNum.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormCodigoAlfa from '../../components/forms/FormCodigoAlfa'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  codigo: {
    qtd: '',
    codmatdid_prod_id: '',
    prod_descricao: '',
    unin_descricao: '',
    unin_numero: '',
    hiscod_unin_id: ''
  },
  codigos: {
    list: []
  },
  materiais: {
    list: []
  },
  unidades: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true
}

export default class CadastroCargo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/listBooks`, this.getToken())

      const produtos = []

      resp.data.forEach(produto => {
        produtos.push({
          _id: produto.prod_id,
          url: produto.midia.mid_caminho,
          prod_descricao: produto.prod_descricao,
          unidadeMedida: produto.prod_unidademedida,
          subGrupoProduto: produto.subgrupoproduto.subgrupprod_descricao,
          grupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
          visibilidade: produto.prod_visibilidade,
          ativo: produto.prod_ativo
        })
      })

      this.setState({
        materiais: {
          list: produtos
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())

      const unidades = []

      resp.data.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : (b.unin_numero > a.unin_numero) ? -1 : 0).forEach(unidade => {
        const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.end_principal)
        if (unidade.pessoa.pes_tipo === "FISICA") {
          let franqueado = ""
          if (unidade.superior.pessoa.pes_tipo === "FISICA") {
            franqueado = unidade.superior.pessoa.fisica.pesf_nome
          } else {
            franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
          }
          unidades.push({
            _id: unidade.unin_id,
            unin_numero: unidade.unin_numero,
            unin_descricao: unidade.unin_descricao,
            franqueado,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: unidade.pessoa.pes_tipo,
            ativo: unidade.unin_status
          })
        } else {
          let franqueado = ""
          if (unidade.superior.pessoa.pes_tipo === "FISICA") {
            franqueado = unidade.superior.pessoa.fisica.pesf_nome
          } else {
            franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
          }
          unidades.push({
            _id: unidade.unin_id,
            unin_numero: unidade.unin_numero,
            unin_descricao: unidade.unin_descricao,
            franqueado,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: unidade.pessoa.pes_tipo,
            ativo: unidade.unin_status
          })
        }
      });

      this.setState({
        unidades: {
          list: unidades
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({ loading: false })

  }

  updateField(event) {
    const codigo = { ...this.state.codigo }
    const materiais = this.state.materiais.list
    const unidades = this.state.unidades.list

    if (event.target.name === 'codmatdid_prod_id') {
      const material = materiais.filter(param => param._id === parseInt(event.target.value))[0]

      codigo.prod_descricao = material.prod_descricao
    }

    if (event.target.name === 'hiscod_unin_id') {
      const unidade = unidades.filter(param => param._id === parseInt(event.target.value))[0]

      codigo.unin_descricao = unidade.unin_descricao
      codigo.unin_numero = unidade.unin_numero
    }

    codigo[event.target.name] = event.target.value
    this.setState({ codigo })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/codigo_alfa");
    }, timeout)
    this.setState({
      codigo: initalState.codigo
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { codigo } = this.state
    if (!codigo.qtd) return false
    if (!codigo.codmatdid_prod_id) return false
    if (!codigo.hiscod_unin_id) return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const codigo = this.state.codigo

    const dados = {
      qtd: parseInt(codigo.qtd),
      codmatdid_prod_id: parseInt(codigo.codmatdid_prod_id),
      hiscod_unin_id: parseInt(codigo.hiscod_unin_id),
    }

    try {

      await axios.post(`${process.env.REACT_APP_API_URL}/codigoMaterial`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })

      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { codigo } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Codigos Alfanúmericos</h2>
                </Grid>
              </Grid>
              <FormCodigoAlfa
                materiais={this.state.materiais.list}
                unidades={this.state.unidades.list}
                updateField={e => this.updateField(e)}
                dados={codigo} />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}