import React, { Component } from 'react'
import './PlanoPagamento.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'

const initalState = {
  planoPagamento: {

  },
  planoPagamentos: {
    list: []
  },
  planoPagamentosFiltrado: {
    list: []
  },
  filtro: {
    planpag_descricao: "",
    unin_descricao: "",
  },
  cabecalhoTabela: [
    { id: 'planpag_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'planpag_data', numeric: false, disablePadding: false, label: 'Data', orderBy: 'dataOrder' },
    { id: 'planpag_ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class PlanoPagamento extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'plano-pagamento-curso')[0]

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/planoPagamentoCurso/list`, dados, this.getToken())

      const planoPagamentos = data.map(param => {
        let unin_descricao = ''

        for (let i = 0; i < param.unidades.length; i++) {
          if ((i + 1) === param.unidades.length) {
            unin_descricao = unin_descricao + `${param.unidades[i].unin_numero} - ${param.unidades[i].unin_descricao}`
          } else {
            unin_descricao = unin_descricao + `${param.unidades[i].unin_numero} - ${param.unidades[i].unin_descricao} |`
          }
        }

        return {
          _id: param.planpag_id,
          planpag_descricao: param.planpag_descricao,
          planpag_ativo: param.planpag_ativo,
          unin_descricao,
          planpag_data: moment(param.planpag_data).format('DD/MM/YYYY'),
          dataOrder: moment(param.planpag_data).format('YYYYMMDD')
        }
      })

      this.setState({
        planoPagamentos: {
          list: planoPagamentos
        },
        planoPagamentosFiltrado: {
          list: planoPagamentos
        },
        loading: false
      })

    } catch ({ response }) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const planoPagamentos = this.state.planoPagamentos.list
    const filtro = this.state.filtro

    const list = planoPagamentos.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      planoPagamentosFiltrado: {
        list
      },
      filtro
    })

  }

  render() {
    const { filtro, permissoes, planoPagamentosFiltrado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Planos de Pagamento...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Planos de Pagamento</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/plano_pagamento/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Plano de Pagamento
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="planpag_descricao" value={filtro.planpag_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Unidade" variant="outlined" size="small" name="unin_descricao" value={filtro.unin_descricao} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/plano_pagamento/cadastro/" urlView="/plano_pagamento/view/" headCell={this.state.cabecalhoTabela} rows={planoPagamentosFiltrado.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}