import React, { Component } from 'react'
import './FechamentoCaixa.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'

import { Grid, Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import real from '../../services/real'
import moment from 'moment'
import ModalErro from './../../components/modals/Erro'
import ModalEditarFormaPagto from './../../components/modals/EditarFormaPagto'

const initalState = {
  loading: true,
  caixa: {},
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  modalOpen: false,
  modal: {
    mensagem: '',
  },
  movimentacao: {
    movcontfin_valor: '',
    movcontfin_contfin_id: 0,
    movcontfin_data_conciliacao: '',
    movcontfin_descricao: '',
    caixa: ''
  },
  toogleTransferencia: false,
  cofre: {
    list: []
  },
  transferencias: {
    list: []
  },
  recebidos: {
    list: []
  },
  lancamentos: {
    list: []
  },
  headCellTransferencia: [
    { id: 'posicao', numeric: false, disablePadding: true, label: '' },
    { id: 'data_hora', numeric: false, disablePadding: true, label: 'Data e Hora' },
    { id: 'movcontfin_descricao', numeric: false, disablePadding: true, label: 'Descrição da Movimentação' },
    { id: 'movcontfin_tipo', numeric: false, disablePadding: true, label: 'Tipo da Movimentação' },
    { id: 'movcontfin_valor_caixa', numeric: false, disablePadding: true, label: 'Valor' }
  ],
  headCellRecebidos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'parcont_datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'parcont_datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: true, label: 'Valor Parcela' },
    { id: 'parcont_multajuros', numeric: false, disablePadding: true, label: 'Juros' },
    { id: 'parcont_valorpagamento', numeric: false, disablePadding: true, label: 'Valor Pago' },
    { id: 'formpag_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
  ],
  headCellLancamentos: [
    { id: 'contpr_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'num_parcela', numeric: false, disablePadding: true, label: 'Nº Parcela' },
    { id: 'parcont_datavencimento', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'parcont_datapagamento', numeric: false, disablePadding: true, label: 'Data Pagamento' },
    { id: 'parcont_valorparcela', numeric: false, disablePadding: true, label: 'Valor Parcela' },
    { id: 'parcont_multajuros', numeric: false, disablePadding: true, label: 'Juros' },
    { id: 'parcont_valorpagamento', numeric: false, disablePadding: true, label: 'Valor Pago' },
    { id: 'formpag_descricao', numeric: false, disablePadding: true, label: 'Forma Pagamento' },
  ],
  headCellValores: [
    { id: 'formpag_descricao', numeric: false, disablePadding: false, label: 'Forma de Pagamento' },
    { id: 'valor_recebido', numeric: false, disablePadding: false, label: 'Saldo' },
    { id: 'conta_destino', numeric: false, disablePadding: false, label: 'Conta Destino' },
  ],
  contaCofre: {},
  valores_recebidos: {
    list: []
  },
  disabledButtonTransferir: false,
  contasFinanceira: {
    list: []
  },
  modalEditarFormaPagto: false,
  dadosEditarFormaPagto: {
    parcont_valorpagamento: '',
    formpag_descricao: '',
    formpag_id: ''
  },
  formas_sem_filtro: {
    list: []
  },
  recebidosAlterados: []
}

export default class Caixa extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentWillMount() {
    this.setState({
      loading: true
    })

    const { match: { params } } = this.props;

    if (params.caixaId) {
      try {
        const dados = {
          filtro: {
            contfin_tipo_conta: "COFRE",
            contfin_descricao: "",
            contfin_ativo: "Sim"
          }
        }

        const { data: caixa } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${params.caixaId}`, this.getToken())
        const { data: cofres } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())
        const { data: transferencias } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${caixa.cai_id}/movimentacoes`, this.getToken())
        const { data: recebidos } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${caixa.cai_id}/recebidos`, this.getToken())
        const { data: lancamentos } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/${caixa.cai_id}/lancamentos`, this.getToken())
        let { data: valores_recebidos } = await axios.get(`${process.env.REACT_APP_API_URL}/caixa/valores-recebidos/${caixa.cai_id}`, this.getToken())
        const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${caixa.cai_unin_id}`, this.getToken())
        const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

        this.setState({
          formas_sem_filtro: {
            list: formasPagamento.filter(param => param.formpag_ativo === true && param.formpag_hab_caixa === true)
          }
        })

        this.setState({
          contasFinanceira: {
            list: contasFinanceira
          }
        })

        for (let i = 0; i < transferencias.length; i++) {
          const index = valores_recebidos.findIndex(param => param.formpag_descricao === "Dinheiro")

          if (index !== -1) {
            if (transferencias[i].movcontfin_tipo === "DEPOSITO") {
              valores_recebidos[index].valor_recebido = valores_recebidos[index].valor_recebido + transferencias[i].movcontfin_valor
            }

            if (transferencias[i].movcontfin_tipo === "SAQUE") {
              valores_recebidos[index].valor_recebido = valores_recebidos[index].valor_recebido - transferencias[i].movcontfin_valor
            }
          }else{
            let valor_recebido = 0

            if (transferencias[i].movcontfin_tipo === "DEPOSITO") {
              valor_recebido = valor_recebido + transferencias[i].movcontfin_valor
            }

            if (transferencias[i].movcontfin_tipo === "SAQUE") {
              valor_recebido = valor_recebido - transferencias[i].movcontfin_valor
            }

            valores_recebidos.push({
              valor_recebido,
              formpag_id: 1,
              formpag_descricao: "Dinheiro"
            })
          }
        }

        this.setState({
          caixa,
          cofre: {
            list: cofres
          },
          transferencias: {
            list: transferencias
          },
          valores_recebidos: {
            list: valores_recebidos.map((valor, key) => {
              return {
                formpag_id: valor.formpag_id,
                formpag_descricao: valor.formpag_descricao,
                valor_recebido: valor.valor_recebido,
                contfin_id: valor.formpag_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.contfin_cofre === true)[0].contfin_id : '',
                contfin_descricao: valor.formpag_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.contfin_cofre === true)[0].contfin_descricao : '',
                contfin_tipo_conta: valor.formpag_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.contfin_cofre === true)[0].contfin_tipo_conta : '',
                contfin_conciliavel: valor.formpag_descricao === 'Dinheiro' ? contasFinanceira.filter(param => param.contfin_cofre === true)[0].contfin_conciliavel : '',
                index: key
              }
            })
          },
          recebidos: {
            list: recebidos.map((parcela, key) => {
              return {
                contpr_descricao: parcela.contpr_descricao,
                contpr_numparcela: parcela.contpr_numparcela,
                parcont_num: parcela.parcont_num,
                parcont_datavencimento: parcela.parcont_datavencimento,
                parcont_datapagamento: parcela.parcont_datapagamento ? parcela.parcont_datapagamento : '',
                parcont_valorparcela: parcela.parcont_valorparcela,
                parcont_multajuros: parcela.parcont_multajuros ? parcela.parcont_multajuros : '',
                parcont_valorpagamento: parcela.valpag_valor_pagamento,
                valpag_id: parcela.valpag_id,
                formpag_descricao: parcela.formpag_descricao,
                formpag_id: parcela.formpag_id,
                index: key
              }
            })
          },
          lancamentos: {
            list: lancamentos.map(parcela => {
              return {
                contpr_descricao: parcela.contpr_descricao,
                contpr_numparcela: parcela.contpr_numparcela,
                parcont_num: parcela.parcont_num,
                parcont_datavencimento: parcela.parcont_datavencimento,
                parcont_datapagamento: parcela.parcont_datapagamento ? parcela.parcont_datapagamento : '',
                parcont_valorparcela: parcela.parcont_valorparcela,
                parcont_multajuros: parcela.parcont_multajuros ? parcela.parcont_multajuros : '',
                parcont_valorpagamento: parcela.valpag_valor_pagamento,
                formpag_descricao: parcela.formpag_descricao
              }
            })
          },
          contaCofre: cofres.filter(param => param.contfin_cofre === true)[0]
        })

      } catch (error) {
        console.log(error)
        // this.handleOpenDialog({
        //   titulo: "Opps!",
        //   descricao: error.response.data.message
        // })
        // this.backPage()
      }
    }

    this.setState({ loading: false })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  handleCloseEditarFormaPagto() {
    this.setState({
      modalEditarFormaPagto: !this.state.modalEditarFormaPagto
    })
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/caixa_fechamento");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  listaParcelas(lista) {
    const retorno = lista.map(object => {
      return {
        ...object,
        parcont_datavencimento: moment(object.parcont_datavencimento).format('DD/MM/YYYY'),
        parcont_datapagamento: object.parcont_datapagamento ? moment(object.parcont_datapagamento).format('DD/MM/YYYY HH:mm') + 'h' : '',
        num_parcela: `${object.parcont_num}/${object.contpr_numparcela}`,
        parcont_valorparcela: real(object.parcont_valorparcela || 0),
        parcont_multajuros: real(object.parcont_multajuros || 0),
        parcont_valorpagamento: real(object.parcont_valorpagamento || 0)
      }
    })

    return retorno
  }

  updateFieldConta(event, key) {
    const { valores_recebidos, contasFinanceira } = this.state

    let itens = valores_recebidos.list

    itens[key][event.target.name] = parseInt(event.target.value)
    itens[key].contfin_descricao = contasFinanceira.list.filter(param => param.contfin_id === parseInt(event.target.value))[0].contfin_descricao
    itens[key].contfin_tipo_conta = contasFinanceira.list.filter(param => param.contfin_id === parseInt(event.target.value))[0].contfin_tipo_conta
    valores_recebidos.list = itens

    this.setState({
      valores_recebidos
    })
  }

  validarAprovar() {
    const { valores_recebidos } = this.state

    for (let i = 0; i < valores_recebidos.list.length; i++) {
      if (!valores_recebidos.list[i].contfin_id) return false
    }

    return true
  }

  async aprovarFechamento() {
    const { valores_recebidos, caixa, recebidos } = this.state

    if (!this.validarAprovar()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const dados = {
      cai_id: caixa.cai_id,
      contfin_id: caixa['conta.contfin_id'],
      valores_recebidos: valores_recebidos.list,
      recebidos: recebidos.list
    }

    for (let i = 0; i < dados.recebidos.length; i++) {
      if(dados.recebidos[i].parcont_valorpagamento === 0 || !dados.recebidos[i].parcont_valorpagamento){
        dados.recebidos.splice(i, 1)
      }
    }

    if (dados.recebidos.length === 0) {
      delete dados.recebidos
    }

    if (dados.valores_recebidos.length === 0) {
      delete dados.valores_recebidos
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/caixa/aprovarFechamento`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabens.',
        descricao: 'Caixa fechado com sucesso'
      })

      this.backPage()

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Opps.',
        descricao: response.data.message || 'Algo de errado aconteceu, tente mais tarde'
      })
    }
  }

  editarFormaPagto(event) {
    let dados = event

    dados.parcont_valorpagamento = parseFloat(event.parcont_valorpagamento.replace('.', '').replace(',', '.'))

    this.setState({
      modalEditarFormaPagto: true,
      dadosEditarFormaPagto: event
    })
  }

  updateFieldParcela(event) {
    let { formas_sem_filtro, dadosEditarFormaPagto } = this.state

    dadosEditarFormaPagto[event.target.name] = parseInt(event.target.value)
    dadosEditarFormaPagto.formpag_descricao = formas_sem_filtro.list.filter(param => param.formpag_id === parseInt(event.target.value))[0].formpag_descricao

    this.setState({
      dadosEditarFormaPagto
    })
  }

  async confirmarParcela() {
    let { dadosEditarFormaPagto } = this.state

    try {
      const dados = {
        formpag_id: dadosEditarFormaPagto.formpag_id
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/editarFormaPagamento/${dadosEditarFormaPagto.valpag_id}`, dados, this.getToken())

      this.setState({
        modalEditarFormaPagto: false
      })

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Parcela baixada com sucesso.'
      })

      this.componentWillMount()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  recusarParcela() {
    this.setState({
      modalEditarFormaPagto: true,
      dadosEditarFormaPagto: {
        parcont_valorpagamento: '',
        formpag_descricao: '',
        formpag_id: ''
      }
    })
  }

  render() {
    const { caixa, valores_recebidos } = this.state

    const transferencias = []
    let i = this.state.transferencias.list.length
    for (const transf of this.state.transferencias.list) {
      transferencias.push({
        ...transf,
        posicao: i--,
        movcontfin_valor_caixa: real(transf.movcontfin_valor),
        data_hora: moment(transf.movcontfin_data_hora_conta).format('DD/MM/YYYY HH:mm') + 'h',
        conta: caixa['conta.contfin_id'] === transf.conta_financeira.contfin_id ? '' : transf.conta_financeira.contfin_descricao,
        movcontfin_descricao: transf.movcontfin_descricao,
        movcontfin_tipo: transf.movcontfin_tipo,
      })
    }

    const recebidos = this.listaParcelas(this.state.recebidos.list)
    const lancamentos = this.listaParcelas(this.state.lancamentos.list)

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div className="view" id="caixa">
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={8}>
                  <h2 className="titulo">
                    Caixa Nº {caixa.cai_numero}
                    {caixa['status_atual.scd_status'] !== 'ABERTO' && <span>({caixa['status_atual.scd_status']})</span>}
                  </h2>
                </Grid>
              </Grid>
              <hr />
              <Grid container direction="row" spacing={1} style={{ 'marginTop': '20px' }}>
                <Grid item md={4} xs={12} sm={4}>
                  <TextField className="input" label="Operador" variant="outlined" size="small" value={caixa['user.pessoa.fisica.pesf_nome']} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={4}>
                  <TextField className="input" label="Data/Hora Abertura" variant="outlined" size="small" value={moment(caixa.cai_dataabertura).format('DD/MM/YYYY HH:mm')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={4}>
                  <TextField className="input" label="Data/Hora Fechamento" variant="outlined" size="small" value={moment(caixa.cai_datafechamento).format('DD/MM/YYYY HH:mm')} InputLabelProps={{ shrink: true }} disabled />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '20px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Transferencias:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (transferencias.length) {
                      return (
                        <Table headCell={this.state.headCellTransferencia} rows={transferencias} acoes={[]} ordem='desc' heightRows={8} />
                      )
                    } else {
                      return (<p>Não há Transferências no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '20px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Recebidos de Baixa:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (recebidos.length) {
                      return (
                        <Table headCell={this.state.headCellRecebidos} rows={recebidos} acoes={['updateFormaPagto']} ordem='desc' heightRows={15} editarFormaPagto={e => this.editarFormaPagto(e)} />
                      )
                    } else {
                      return (<p>Não há Transferências no momento</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ 'marginTop': '20px' }}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Lançamentos:</h5>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  {(() => {
                    if (lancamentos.length) {
                      return (
                        <Table headCell={this.state.headCellLancamentos} rows={lancamentos} acoes={[]} ordem='desc' heightRows={15} />
                      )
                    } else {
                      return (<p>Não há Lançamentos no caixa</p>)
                    }
                  })()}
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ marginTop: '20px' }}>
                <Grid item lg={4} md={4} xs={12} sm={4}>
                  <h4 className="saldo">Saldo Atual:</h4>
                  <p>Iniciado com R$ {real(caixa.cai_fundotroco || 0)}</p>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1}>
                <Grid item md={8} xs={12} sm={12}>
                  <Table
                    headCell={this.state.headCellValores}
                    rows={valores_recebidos.list}
                    acoes={[]}
                    updateFieldConta={(event, key) => this.updateFieldConta(event, key)}
                    contasFinanceira={this.state.contasFinanceira.list}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} style={{ marginTop: '20px' }}>
                <Grid item md={3} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    className="btn_salvar"
                    size="small"
                    onClick={e => this.voltar()}>
                    Voltar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    className="btn_salvar"
                    size="small"
                    onClick={e => this.aprovarFechamento()}>
                    Aprovar Fechamento
                  </Button>
                </Grid>
              </Grid>

            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalEditarFormaPagto
            open={this.state.modalEditarFormaPagto}
            handleClose={e => this.handleCloseEditarFormaPagto(e)}
            dados={this.state.dadosEditarFormaPagto}
            formas_sem_filtro={this.state.formas_sem_filtro.list}
            updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
            confirmar={e => this.confirmarParcela(e)}
            recusar={e => this.recusarParcela(e)} />
        </Main>
        <Nav />
        <Footer />
      </div >
    )
  }
}