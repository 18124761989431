import React from 'react'
import { Grid, Button, Radio } from '@material-ui/core'
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask"

const FormCargo = ({ aluno, handleChangeRadio, updateField, proximo, updateFieldResponsavel }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1}>
      <Grid item md={4} xs={12} sm={3}></Grid>
      <Grid item md={4} xs={12} sm={6} className='card_cadastro'>
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={12} xs={12} sm={12}>
            <img src="https://system-british-no-delete.s3.amazonaws.com/logo_2.png" alt="logo" />
          </Grid>                
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={12} xs={12} sm={12}>
            <h5>Informe o seu CPF</h5>
            <p>Para realizar a sua matricula</p>
          </Grid>                
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item md={4} xs={12} sm={6}>
            <Radio
              checked={aluno.tipoCadastro === 'aluno'}
              color='primary'
              onChange={(e) => handleChangeRadio(e)}
              value="aluno"
              name="radio-button-demo"
            />
            Aluno
          </Grid> 
          <Grid item md={8} xs={12} sm={6}>
            <Radio
              checked={aluno.tipoCadastro === 'responsavel'}
              onChange={(e) => handleChangeRadio(e)}
              value="responsavel"
              name="radio-button-demo"
            />
            Responsável Financeiro
          </Grid>
        </Grid>
        {aluno.tipoCadastro === 'responsavel' &&
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={1} xs={false} sm={1}></Grid>
            <Grid item md={10} xs={12} sm={10} className='card_aviso'>
              <p>Use essa opção apenas se você estiver realizando o processo de inscrição para seus filhos ou dependentes. Nas estapas a frente você informará os dados do aluno.</p>
              <p>Caso esteja realizando o processo de inscrição para você mesmo, utilize a opção <b>Aluno</b>.</p>
            </Grid>
          </Grid>
        }
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={1} xs={false} sm={1}></Grid>
          <Grid item md={10} xs={12} sm={10}>
            <CpfCnpj
              label="CPF"
              className="inputCpfCnpj"
              name="cpf"
              value={aluno.tipoCadastro === 'aluno' ? aluno.cpf : aluno.responsavel.cpf}
              onChange={aluno.tipoCadastro === 'aluno' ? (e) => updateField(e) : (e) => updateFieldResponsavel(e)}
              disabled={aluno.tipoCadastro === ''}
              placeholder="CPF*"
            />
          </Grid>
          <Grid item md={1} xs={false} sm={1}></Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10" style={{marginBottom:20}}>
          <Grid item md={1} xs={false} sm={1}></Grid>
          <Grid item md={4} xs={12} sm={4}>
            <Button 
              fullWidth 
              color="primary" 
              variant="contained" 
              className="btn_salvar" 
              size="small" 
              onClick={e => proximo()}
            >
              Proximo
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={12} sm={3}></Grid>
    </Grid>
  </React.Fragment>
)

export default FormCargo