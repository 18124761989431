import React, { Component } from 'react'
import './Curso.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormCurso from '../../components/forms/FormCurso'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'

const initalState = {
  curso: {
    _id: "",
    curso_nome: "",
    curso_ativo: true,
    curso_ead: false,
    curso_gera_certificado: true,
    books: []
  },
  cursos: {
    list: []
  },
  book: {
    bok_id: "",
    bok_nome: "",
    bc_obrigatorio: true,
    bc_ativo: true,
    bc_ordem: '',
    produto: {},
  },
  books: {
    list: []
  },
  booksRemovidos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'url', numeric: false, disablePadding: true, label: 'Icone Book' },
    { id: 'bok_nome', numeric: false, disablePadding: true, label: 'Book' },
    { id: 'bc_ordem', numeric: false, disablePadding: false, label: 'Ordem' },
    { id: 'bc_obrigatorio', numeric: false, disablePadding: false, label: 'Obrigatório' },
    { id: 'bc_ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: ['editar', 'remove'],
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  duracoesSelecionadas: [],
  duracoes: ['3 Meses', '6 Meses', '12 Meses', '15 Meses', '18 Meses', '24 Meses', '27 Meses', '30 Meses', '36 Meses', '42 Meses', '48 Meses']
}

export default class CadastroCurso extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'curso')[0]

    this.setState({
      permissoes
    })

    if (params.cursoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    this.setState({
      curso: {
        _id: "",
        curso_nome: "",
        curso_ativo: true,
        curso_ead: false,
        curso_gera_certificado: true,
        books: []
      },
      cursos: {
        list: []
      },
      book: {
        bok_id: "",
        bok_nome: "",
        bc_obrigatorio: true,
        bc_ativo: true,
        bc_ordem: '',
        produto: {},
      },
      books: {
        list: []
      },
      duracoesSelecionadas: []
    })

    if (params.cursoId) {
      try {
        const { data: curso } = await axios.get(`${process.env.REACT_APP_API_URL}/curso/${params.cursoId}`, this.getToken())

        const books = []
        let booksList = this.state.books.list

        for (let i = 0; i < curso.books.length; i++) {
          books.push({
            bok_id: curso.books[i].bok_id,
            bok_nome: curso.books[i].bok_nome,
            bc_obrigatorio: curso.books[i].BookCurso.bc_obrigatorio,
            bc_ativo: curso.books[i].BookCurso.bc_ativo,
            bc_ordem: curso.books[i].BookCurso.bc_ordem,
            produto: curso.books[i].produto,
            index: i + 1
          })


          const index2 = booksList.findIndex(param => param.bok_id === curso.books[i].bok_id)

          if (index2 !== -1) {
            var bookRemovido = books[index2]

            var booksRemovidos = this.state.booksRemovidos.list

            booksRemovidos.push(bookRemovido)

            booksList.splice(index2, 1)

            this.setState({
              books: {
                list: booksList
              },
              booksRemovidos: {
                list: booksRemovidos
              }
            })
          }

        }

        this.setState({
          curso: {
            _id: curso.curso_id,
            curso_nome: curso.curso_nome,
            curso_gera_certificado: curso.curso_gera_certificado,
            curso_ativo: curso.curso_ativo,
            curso_ead: curso.curso_ead,
            books,
          },
          duracoesSelecionadas: curso.duracoes.map(value => `${value.durcur_tempo} Meses`)
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o curso, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/book`, this.getToken())
      let booksAtivos = []
      let books = []

      booksAtivos = resp.data.filter(u => u.bok_ativo === true)

      booksAtivos.forEach(book => {
        books.push({
          bok_id: book.bok_id,
          bok_nome: book.bok_nome,
          bok_descricao: book.bok_descricao,
          bok_produto_id: book.bok_produto_id,
          bok_ativo: book.bok_ativo,
          produto: book.produto
        })
      })

      this.setState({
        books: {
          list: books
        },
        loading: false
      })

    } catch (error) {
      console.log(error);
    }


  }

  updateField(event) {
    const curso = { ...this.state.curso }
    curso[event.target.name] = event.target.value
    this.setState({ curso })
  }

  updateFieldAtivo() {
    const curso = { ...this.state.curso }
    curso.curso_ativo = !curso.curso_ativo
    this.setState({ curso })
  }

  updateFieldEAD() {
    const curso = { ...this.state.curso }
    curso.curso_ead = !curso.curso_ead
    this.setState({ curso })
  }

  updateFieldCertificado() {
    const curso = { ...this.state.curso }
    curso.curso_gera_certificado = !curso.curso_gera_certificado
    this.setState({ curso })
  }

  updateFieldObrigatorio() {
    const book = { ...this.state.book }
    book.bc_obrigatorio = !book.bc_obrigatorio
    this.setState({ book })
  }

  updateFieldBookAtivo() {
    const book = { ...this.state.book }
    book.bc_ativo = !book.bc_ativo
    this.setState({ book })
  }

  updateFieldBook(event, value) {
    if (value) {
      this.setState({
        book: {
          bok_id: value.bok_id,
          bok_nome: value.bok_nome,
          bc_obrigatorio: this.state.book.bc_obrigatorio,
          bc_ativo: this.state.book.bc_ativo,
          bc_ordem: this.state.book.bc_ordem,
          produto: value.produto
        }
      })
    }
  }

  updateFieldValorPontoBook(event){
    const {book} = this.state
    book[event.target.name] = event.target.value
    this.setState({book})
  }

  validateBook() {
    const { book } = this.state

    if (!book.bok_id) return false
    if (!book.bok_nome) return false
    if (!book.bc_ordem) return false

    return true
  }

  addItem() {

    if (!this.validateBook()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const curso = this.state.curso
    const book = this.state.book
    let books = this.state.books.list

    const itens = curso.books

    const ordemIndex = itens.findIndex(param => (parseInt(param.bc_ordem) === parseInt(book.bc_ordem)) && (param.index !== book.index))

    if(ordemIndex !== -1){
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Essa ordem já foi inserida."
      })
      return
    }

    if (book.index) {
      const index = curso.books.findIndex(param => param.index === book.index)

      itens[index] = {
        bok_id: book.bok_id,
        bok_nome: book.bok_nome,
        bc_obrigatorio: book.bc_obrigatorio,
        bc_ativo: book.bc_ativo,
        bc_ordem: book.bc_ordem,
        produto: book.produto,
        index: book.index
      }
    } else {
      const index = itens.length + 1

      itens.push({
        bok_id: book.bok_id,
        bok_nome: book.bok_nome,
        bc_obrigatorio: book.bc_obrigatorio,
        bc_ativo: book.bc_ativo,
        bc_ordem: book.bc_ordem,
        produto: book.produto,
        index
      })
    }

    curso.books = itens

    const index2 = books.findIndex(param => param.bok_id === book.bok_id)

    if (index2 !== -1) {
      var bookRemovido = books[index2]

      var booksRemovidos = this.state.booksRemovidos.list

      booksRemovidos.push(bookRemovido)

      books.splice(index2, 1)

      this.setState({
        books: {
          list: books
        },
        booksRemovidos: {
          list: booksRemovidos
        }
      })
    }

    this.setState({
      update: false,
      curso,
      book: initalState.book,
    })
  }

  removerItem(index) {
    const curso = this.state.curso
    const booksRemovidos = this.state.booksRemovidos.list
    const books = this.state.books.list

    let itens = curso.books

    const indexProdutoRemovido = booksRemovidos.findIndex(param => param.prod_id === itens[index - 1].precprod_produto_id)

    const produtoAdd = booksRemovidos[indexProdutoRemovido]

    books.push(produtoAdd)

    booksRemovidos.splice(indexProdutoRemovido - 1, 1)

    itens.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        bok_id: itens[i].bok_id,
        bok_nome: itens[i].bok_nome,
        bc_obrigatorio: itens[i].bc_obrigatorio,
        bc_ativo: itens[i].bc_ativo,
        bc_ordem: itens[i].bc_ordem,
        produto: itens[i].produto,
        index: i + 1
      })
    }

    itens = aux

    curso.itens = itens

    this.setState({
      curso,
      booksRemovidos: {
        list: booksRemovidos
      },
      books: {
        list: books
      }
    })
  }

  editarItem(index) {
    const aux = this.state.curso.books.filter(param => param.index === index)

    this.setState({
      update: true,
      book: aux[0]
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/curso");
    }, timeout)
    this.setState({
      curso: initalState.curso,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFieldDuracao(event, value) {
    this.setState({
      duracoesSelecionadas: value
    })
  }

  validateForm() {
    const { curso } = this.state

    if (!curso.curso_nome) return false

    return true
  }

  async salvar() {
    const {curso, duracoesSelecionadas} = this.state

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    if(duracoesSelecionadas.length === 0){
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Por favor selecionar a duração do curso (*)."
      })
      return
    }

    for (const book of curso.books) {
      if(!book.bc_ordem){
        this.handleOpenDialog({
          titulo: "Erro no Cadastro!",
          descricao: "Por favor informar a ordem de todos os books (*)."
        })
        return
      }
    }

    const dados = {
      curso_nome: curso.curso_nome,
      curso_gera_certificado: curso.curso_gera_certificado,
      curso_ativo: curso.curso_ativo,
      curso_ead: curso.curso_ead,
      duracoes: duracoesSelecionadas.map(value => parseInt(value.replace(' Meses', ''))),
      books_curso: curso.books.map(param => {
        return {
          bok_id: param.bok_id,
          bc_obrigatorio: param.bc_obrigatorio,
          bc_ativo: param.bc_ativo,
          bc_ordem: parseInt(param.bc_ordem)
        }
      })
    }

    try {
      if (curso._id !== "") {
        await axios.put(`${process.env.REACT_APP_API_URL}/curso/${curso._id}`, dados, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/curso`, dados, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { curso } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Curso</h2>
                </Grid>
              </Grid>
              <FormCurso
                book={this.state.book}
                books={this.state.books.list}
                updateField={e => this.updateField(e)}
                updateFieldAtivo={e => this.updateFieldAtivo(e)}
                updateFieldEAD={e => this.updateFieldEAD(e)}
                updateFieldCertificado={e => this.updateFieldCertificado(e)}
                updateFieldDuracao={(event, value) => this.updateFieldDuracao(event, value)}
                updateFieldBook={(e, value) => this.updateFieldBook(e, value)}
                dados={curso}
                addItem={e => this.addItem()}
                updateFieldObrigatorio={e => this.updateFieldObrigatorio()}
                updateFieldBookAtivo={e => this.updateFieldBookAtivo()}
                update={this.state.update}
                duracoesSelecionadas={this.state.duracoesSelecionadas}
                duracoes={this.state.duracoes}
                updateFieldValorPontoBook={e=> this.updateFieldValorPontoBook(e)}
              />

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Table
                  updateAtivo={e => this.updateAtivo(e)}
                  editar={e => this.editarItem(e)}
                  remove={e => this.removerItem(e)}
                  headCell={this.state.cabecalhoTabela}
                  rows={this.state.curso.books.map(book => {
                    return {
                      _id: book.bok_id,
                      url: book.produto.midia.mid_caminho,
                      bok_nome: book.bok_nome,
                      bc_obrigatorio: book.bc_obrigatorio,
                      bc_ativo: book.bc_ativo,
                      bc_ordem: book.bc_ordem,
                      index: book.index,
                    }
                  })}
                  acoes={this.state.acoesTabela}
                  noRemove={false} />
              </Grid>

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}