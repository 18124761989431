import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core'

const pessoa = JSON.parse(localStorage.getItem('pessoa'))
const unin_tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo : 'UNIDADE'

const FormAgendaaula = ({ dados, updateField, unidades, unidade, aula, aulas, aluno, alunos,
  professor, professores, updateFieldUnidade, updateFieldAula, updateFieldFuncionario,
  updateFieldAluno, disabledUnidade }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      {unin_tipo === "FRANQUEADOR" &&
        <Grid item md={4} xs={12} sm={6}>
          <Autocomplete
            onChange={(event, value) => updateFieldUnidade(event, value)}
            freeSolo
            options={unidades}
            value={unidade}
            getOptionLabel={option => `${option.unin_numero} - ${option.unin_descricao}`}
            renderInput={(params) => (
              <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Unidade*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${unidade.unin_numero} - ${unidade.unin_descricao}`} />
            )}
            disabled={disabledUnidade}
          />
        </Grid>
      }
      {unin_tipo === "FRANQUEADO" &&
        <Grid item md={4} xs={12} sm={6}>
          <Autocomplete
            onChange={(event, value) => updateFieldUnidade(event, value)}
            freeSolo
            options={unidades}
            value={unidade}
            getOptionLabel={option => `${option.unin_numero} - ${option.unin_descricao}`}
            renderInput={(params) => (
              <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Unidade*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${unidade.unin_numero} - ${unidade.unin_descricao}`} />
            )}
            disabled={disabledUnidade}
          />
        </Grid>
      }
      <Grid item md={4} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldAluno(event, value)}
          freeSolo
          options={alunos}
          value={aluno}
          getOptionLabel={option => `${option.aluno_matricula} - ${option.pesf_nome}`}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Aluno*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${aluno.aluno_matricula} - ${aluno.pesf_nome}`} />
          )}
          disabled={disabledUnidade}
        />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldAula(event, value)}
          freeSolo
          options={aulas}
          value={aula}
          getOptionLabel={option => option.aula_nome}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Aula*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={aula.aula_nome} />
          )}
        />
      </Grid>
    </Grid>
    {/* <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={6} xs={12} sm={6}>
        <Autocomplete
          onChange={(event, value) => updateFieldFuncionario(event, value)}
          freeSolo
          options={professores}
          value={professor}
          getOptionLabel={option => option.pesf_nome}
          renderInput={(params) => (
            <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Professor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={professor.pesf_nome} />
          )}
        />
      </Grid>
      <Grid item md={4} xs={12} sm={6}>
        <TextField
          type="date"
          label="Data da Aula*"
          variant="outlined"
          size="small"
          name="ageaula_data"
          InputProps={{ inputProps: { min: dataHojeFormatado } }}
          value={dados.ageaula_data}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          type="time"
          label="Hora da Aula*"
          variant="outlined"
          size="small"
          name="ageaula_hora"
          InputProps={{ inputProps: { min: dataHojeFormatado } }}
          value={dados.ageaula_hora}
          onChange={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid> */}
  </React.Fragment>
)

export default FormAgendaaula