import React, { Component } from 'react'
import './../Estoque.scss'
import './../../../main/ultil.scss'

import Main from '../../../components/templates/Main'
import Nav from '../../../components/templates/Nav'
import Footer from '../../../components/templates/Footer'
import axios from 'axios'
import FormLocalEstoque from '../../../components/forms/FormLocalEstoque'

import { Grid, Button } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../../components/modals/Erro'

import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  localEstoque: {
    _id: "",
    descricao: "",
    unidade: "",
    idUnidade: ""
  },
  localEstoques: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  unidades: {
    list: []
  },
  loading: true,
  loadingSalvar: false
}

export default class CadastroLocalEstoque extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    axios.post(`${process.env.REACT_APP_API_URL}/unidades/list`, {}, this.getToken())
      .then(resp => {

        const unidades = []

        resp.data.forEach(unidade => {
          const enderecoPrincipal = unidade.pessoa.enderecos.filter(u => u.end_principal)
          if (unidade.pessoa.pes_tipo === "FISICA") {
            let franqueado = ""
            if (unidade.superior.pessoa.pes_tipo === "FISICA") {
              franqueado = unidade.superior.pessoa.fisica.pesf_nome
            } else {
              franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
            }
            unidades.push({
              _id: unidade.unin_id,
              numero: unidade.unin_numero,
              descricao: unidade.unin_descricao,
              franqueado,
              cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
              tipo: unidade.pessoa.pes_tipo,
              ativo: unidade.unin_status
            })
          } else {
            let franqueado = ""
            if (unidade.superior.pessoa.pes_tipo === "FISICA") {
              franqueado = unidade.superior.pessoa.fisica.pesf_nome
            } else {
              franqueado = unidade.superior.pessoa.juridica.pesj_razaosocial
            }
            unidades.push({
              _id: unidade.unin_id,
              numero: unidade.unin_numero,
              descricao: unidade.unin_descricao,
              franqueado,
              cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
              tipo: unidade.pessoa.pes_tipo,
              ativo: unidade.unin_status
            })
          }
        });

        this.setState({
          unidades: {
            list: unidades
          },
          loading: false
        })

      }).catch(erro => {
        console.log(erro)
        this.setState({
          modalErro: true,
          erro
        })
      })
  }

  updateField(event) {
    const localEstoque = { ...this.state.localEstoque }
    localEstoque[event.target.name] = event.target.value
    this.setState({ localEstoque })
  }

  validateForm() {
    const { localEstoque } = this.state

    if (!localEstoque.idUnidade) return false
    if (!localEstoque.descricao) return false

    return true
  }

  async salvar() {
    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { localEstoque } = this.state

    const data = {
      localestoq_unin_id: localEstoque.idUnidade,
      localestoq_descricao: localEstoque.descricao,
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/localEstoque`, data, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()
    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { localEstoque } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={11}>
              <h2 className="titulo">Cadastro de Local de Estoque</h2>
            </Grid>
            <Grid item md={1}>
              {this.state.loadingSalvar &&
                <div>
                  <CircularProgress />
                </div>
              }
            </Grid>
          </Grid>
          <FormLocalEstoque updateField={e => this.updateField(e)} dados={localEstoque} dadosUnidades={this.state.unidades.list} />
          <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
            <Grid item md={9}></Grid>
            <Grid item md={3}>
              <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
            </Grid>
          </Grid>
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}