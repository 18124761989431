import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import ModalErro from './../../components/modals/Erro'
import ModalRelatorios from './../../components/modals/Relatorios'
import ModalRelatorioAlunos from './../../components/modals/Relatorios'
import moment from 'moment'

const initalState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  filtroSub: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  relatoriosAux:{
    list: []
  },
  relatorios_options: {
    list: [{
      perm:{
        perm_visualizar: false
      }
    }]
  },
  loading: false,
  loadingDownload: false,
  urlView: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  openModalRelatorio: false,
  relatoriosAula:{
    list: []
  },
  bookSelecionado: {
    book: '',
    qtd: ''
  },
  cabecalhoTabelaAula: [
    { id: 'aula', numeric: false, disablePadding: false, label: 'Aula.' },
    { id: 'qtd', numeric: false, disablePadding: false, label: 'Qtd. Alunos' },
  ],
  acoesTabelaAula: ['viewContratosAlunos'],
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  openModalRelatorioAlunos: false,
  linhaSelecionada: '',
}

export default class Pedagogico extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const { filtro } = this.state

    filtro.data_inicial = moment(new Date()).format('YYYY-MM-DD')

    this.setState({
      filtro
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    
    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'rel-pedagogico')

    this.setState({
      relatorios_options: {
        list: permissoes.map( value => {
          return {
            tela_descricao: value.tela.tela_descricao,
            tela_slug: value.tela.tela_slug,
            perm: {
              perm_visualizar: value.perm_visualizar
            }
          }
        })
      }
    })
  }

  updateFieldFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({ filtro })
  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loading: true
    })
    
    localStorage.removeItem('ordenacao');

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())

      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list
        },
        relatoriosAux: {
          list: data.list
        },
        urlView: data.urlView
      })

      this.setState({
        loading: false
      })

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }
  }

  async imprimir() {
    const {filtro, cabecalhoTabela} = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const ordenacao = JSON.parse(localStorage.getItem('ordenacao'))
      let indexCabecalho = -1

      if(ordenacao){
        indexCabecalho = cabecalhoTabela.findIndex(param => param.id === ordenacao.property)
      }

      if(indexCabecalho !== -1){
        if(ordenacao.isAsc){
          filtro.list = this.state.relatorios.list.sort((a, b) => (a[ordenacao.property] < b[ordenacao.property]) ? 1 : (b[ordenacao.property] < a[ordenacao.property]) ? -1 : 0)
        }else{
          filtro.list = this.state.relatorios.list.sort((a, b) => (a[ordenacao.property] > b[ordenacao.property]) ? 1 : (b[ordenacao.property] > a[ordenacao.property]) ? -1 : 0)
        }
      }else{
        filtro.list = this.state.relatorios.list
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      if(filtro.relatorio === 'aluno-sem-aula-marcada')
        saveAs(pdfBlob, `Alunos-Sem-Aula.pdf`);

      if(filtro.relatorio === 'aluno-por-aula')
        saveAs(pdfBlob, `Alunos-Por-Book.pdf`);

      if(filtro.relatorio === 'ultima-aula-complementar')
        saveAs(pdfBlob, `Ultima-Aula-Complementar.pdf`);

    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  async imprimirRelatorioAlunos(){
    const {cabecalhoTabela} = this.state

    let filtro = {
      unidadesnegocio: [],
      list: [],
      relatorio: 'aluno-por-aula-aulas'
    }

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const ordenacao = JSON.parse(localStorage.getItem('ordenacao'))
      let indexCabecalho = -1

      if(ordenacao){
        indexCabecalho = cabecalhoTabela.findIndex(param => param.id === ordenacao.property)
      }

      if(indexCabecalho !== -1){
        if(ordenacao.isAsc){
          filtro.list = this.state.relatoriosAula.list.sort((a, b) => (a[ordenacao.property] < b[ordenacao.property]) ? 1 : (b[ordenacao.property] < a[ordenacao.property]) ? -1 : 0)
        }else{
          filtro.list = this.state.relatoriosAula.list.sort((a, b) => (a[ordenacao.property] > b[ordenacao.property]) ? 1 : (b[ordenacao.property] > a[ordenacao.property]) ? -1 : 0)
        }
      }else{
        filtro.list = this.state.relatoriosAula.list
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtro, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, `Alunos-Por-Aula.pdf`);
    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  registrarRows(value){
    
    // const {relatoriosAux} = this.state

    // relatoriosAux.list = value
    
    // this.setState({
    //   relatoriosAux: {
    //     list: value
    //   }
    // })
  }

  openModalAulas(row){
    this.setState({
      relatoriosAula:{
        list: row.aulas
      },
      bookSelecionado: {
        book: row.book,
        qtd: row.qtd
      },
      openModalRelatorio: true
    })
  }

  handleCloseModalRelatorio(){
    this.setState({
      openModalRelatorio: false
    })
  }

  async viewRow(ids, acao, status) {
    try {

      console.log(status)

      let filtroAux = {
        relatorio: acao,
        ids: ids,
        status
      }
            
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtroAux, this.getToken())

      const filtroSub = this.state.filtroSub

      filtroSub.relatorio = acao
      filtroSub.list = data.list

      this.setState({
        cabecalhoTabelaModal: data.cabecalhoTabela,
        acoesTabelaModal: data.acoesTabela,
        relatoriosModal: {
          list: data.list
        },
        filtroSub,
        urlViewModal: data.urlView,
        openModalRelatorioAlunos: true,
        linhaSelecionada: status
      })

    } catch ({response}) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseModalRelatorioAlunos(){
    this.setState({
      openModalRelatorioAlunos: false
    })
  }

  async imprimirSub(){
    const { filtro, filtroSub, linhaSelecionada } = this.state

    this.setState({
      loadingDownload: true
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtroSub.relatorio = 'contratos-alunos'
      filtroSub.unidadesnegocio = dados.unidadesnegocio
      filtroSub.titulo = filtro.relatorio.replace('-', ' ').replace('-', ' ').toUpperCase() + ' - ' + linhaSelecionada
      filtroSub.data_inicial = filtro.data_inicial
      filtroSub.data_final = filtro.data_inicial

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtroSub, this.getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      this.setState({
        loadingDownload: false
      })

      saveAs(pdfBlob, 'Alunos.pdf')
    } catch (error) {
      this.setState({
        loadingDownload: false
      })
      console.log(error)
    }
  }

  render() {
    const { filtro, cabecalhoTabela, acoesTabela, relatorios, urlView, loadingDownload, relatorios_options } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Relatório Pedagógico</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="borderBottom mg_top_20">
            <Grid item md={4} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Relatório"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="relatorio"
                value={filtro.relatorio}
                onChangeCapture={(e) => this.updateFieldFiltro(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha o Relatório--</option>
                {relatorios_options.list.sort((a, b) => (a.tela_descricao > b.tela_descricao) ? 1 : ((b.tela_descricao > a.tela_descricao) ? -1 : 0)).map(value => {
                  if(value.perm){
                   if(value.perm.perm_visualizar){
                      return (
                        <option value={value.tela_slug}>{value.tela_descricao}</option>
                      )
                    }
                  }
                  return false
                })}
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.relatorio === '' ? true : false}>
                Filtrar
							</Button>
            </Grid>
            {relatorios.list.length > 0 &&
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                  Imprimir
                </Button>
              </Grid>
            }
          </Grid>
          {filtro.relatorio === 'ultima-aula-complementar' &&
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <span>Esse relatório pode demorar alguns minutos, por favor aguardar.</span>
              </Grid>
            </Grid>
          }
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {cabecalhoTabela.length > 0 &&
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table 
                      urlView={urlView} 
                      headCell={cabecalhoTabela} 
                      rows={relatorios.list} 
                      acoes={acoesTabela} 
                      registrarRows={e => this.registrarRows(e)}
                      openModalAulas={e => this.openModalAulas(e)}
                    />
                  </Grid>
                </Grid>
              }
            </React.Fragment>
          }
          <ModalErro 
            open={this.state.modalErro} 
            handleClose={e => this.handleCloseErro(e)} 
            titulo={this.state.erro.titulo} 
            descricao={this.state.erro.descricao} 
          />

          <ModalRelatorios 
            open={this.state.openModalRelatorio}
            handleClose={e => this.handleCloseModalRelatorio(e)}
            bookSelecionado={this.state.bookSelecionado}
            list={this.state.relatoriosAula.list}
            cabecalhoTabela= {this.state.cabecalhoTabelaAula}
            acoesTabela= {this.state.acoesTabelaAula}
            viewRow={(e, acao, status) => this.viewRow(e, acao, status)} 
            imprimir={e => this.imprimirRelatorioAlunos()}
          />

          <ModalRelatorioAlunos
            open={this.state.openModalRelatorioAlunos}
            handleClose={e => this.handleCloseModalRelatorioAlunos(e)}
            list={this.state.relatoriosModal.list}
            cabecalhoTabela={this.state.cabecalhoTabelaModal}
            acoesTabela={this.state.acoesTabelaModal}
            imprimir={e => this.imprimirSub(e)}
            loadingDownload={loadingDownload}
            urlView={this.state.urlViewModal}
            linhaSelecionada={this.state.linhaSelecionada}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}