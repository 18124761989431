import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core'
import { Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const { dados, disabledSalvar, professores, professor } = props
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const hora = dados.hora.replace(':00', 'h')

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              {!dados.aberto &&
                <h5 className="tituloConfirmacao">Confirmar abertura de aula</h5>
              }
              {dados.aberto &&
                <h5 className="tituloConfirmacao">Adicionar aluno na aula</h5>
              }
            </Grid>
          </Grid>

          <hr />
          <br />
          <p>Confirme as informações abaixo para continuar.</p>
          <p>Data/Hora da Aula: <span className="capitalize">{dados.data.diaSemana}</span>, {dados.data.dia}/{dados.data.mes}/{dados.data.ano} - {hora}</p>
          <p>Aula: {dados.aula.aula_nome}</p>
          {dados.aberto &&
            <div>
              <p>Professor: {professor.pesf_nome}</p>
            </div>
          }
          {!dados.aberto &&
            <Autocomplete
              onChange={(event, value) => props.updateFieldFuncionario(event, value)}
              freeSolo
              options={professores}
              value={professor}
              getOptionLabel={option => option.pesf_nome}
              renderInput={(params) => (
                <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Professor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={professor.pesf_nome} />
              )}
            />
          }

          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => props.salvar(e)} disabled={disabledSalvar}>Salvar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => props.voltar(e)} disabled={disabledSalvar}>Voltar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
