import React from 'react'
import { Grid, TextField, FormControlLabel, Switch, Button } from '@material-ui/core'
import InputMask from "react-input-mask";
import MaskedInput from 'react-text-mask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+',/\d/,/\d/,' ','(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const FormCargo = ({ proximo, aluno, updateField, updateFieldEndereco, updateFieldResponsavel, updateFieldNomeSocial, 
  updateFieldNomeSocialResponsavel, voltar, buscaEndereco, formatarCPFResponsavel }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1}>
      <Grid item md={5} xs={false} sm={4}></Grid>
      <Grid item md={2} xs={12} sm={4}>
        <img src="https://system-british-no-delete.s3.amazonaws.com/logo.png" alt="logo" />
      </Grid>
      <Grid item md={5} xs={false} sm={4}></Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={12} xs={12} sm={12} style={{textAlign: 'center'}}>
        <h5>INFORME SEUS DADOS PARA CONTINUAR A SUA INSCRIÇÃO</h5>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={1} xs={false} sm={1}></Grid>
      <Grid item md={10} xs={12} sm={10}>
        <div className='card_dados'>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h5>Dados Básicos - Aluno</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={3} xs={12} sm={4}>
              <FormControlLabel className="mg_left_10"
                control={
                  <Switch
                    name="possui_nome_social"
                    color="primary"
                    checked={aluno.possui_nome_social}
                  />
                }
                size="small"
                name="possui_nome_social"
                onChange={(e) => updateFieldNomeSocial(e)}
              />
              <span>Possui nome social?</span>
            </Grid>
            {aluno.possui_nome_social && 
              <Grid item md={6} xs={12} sm={8}>
                <TextField className="input" label="Nome Social" variant="outlined" size="small" name="nome_social" value={aluno.nome_social} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            }
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={4} xs={12} sm={6}>
              <InputMask
                mask="999.999.999-99"
                value={aluno.cpf}
                disabled={aluno.tipoCadastro === 'aluno' ? true : false}
                maskChar=""
                onChange={(e) => updateField(e)}
              >
                {() =>
                  <TextField id='cpf_aluno' className="input" label="CPF*" variant="outlined" size="small" name="cpf" InputLabelProps={{ shrink: true }} disabled={aluno.tipoCadastro === 'aluno' ? true : false}/>
                }
              </InputMask>              
            </Grid>
            <Grid item md={4} xs={12} sm={8}>
              <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={aluno.nome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={4} xs={12} sm={8}>
              <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={aluno.sobrenome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={3} xs={12} sm={8}>
              <TextField type='date' className="input" label="Data de Nascimento*" variant="outlined" size="small" name="data_nascimento" value={aluno.data_nascimento} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={3} xs={12} sm={8}>
              <TextField
                fullWidth
                className="input"
                label="Celular*"
                variant="outlined"
                size="small"
                name="telefone"
                value={aluno.telefone}
                onChange={(e) => updateField(e)} 
                disabled={false}
                InputProps={{ inputComponent: TextMaskCustom }}
              />
              {/* <InputMask
                mask={['+',/\d/,/\d/,' ','(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
                value={aluno.telefone}
                disabled={false}
                maskChar=""
                onChange={(e) => updateField(e)} 
              >
                {() =>
                  <TextField className="input" label="Celular*" variant="outlined" size="small" name="telefone" InputLabelProps={{ shrink: true }} />
                }
              </InputMask> */}
            </Grid>
            <Grid item md={6} xs={12} sm={8}>
              <TextField className="input" label="E-mail*" variant="outlined" size="small" name="email" value={aluno.email} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item md={1} xs={false} sm={1}></Grid>
    </Grid>
    {aluno.tipoCadastro === 'responsavel' &&
      <Grid container direction="row" spacing={1} className="mg_top_20">
        <Grid item md={1} xs={false} sm={1}></Grid>
        <Grid item md={10} xs={12} sm={10}>
          <div className='card_dados'>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <h5>Dados Básicos - Responsavel</h5>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item md={3} xs={12} sm={4}>
                <FormControlLabel className="mg_left_10"
                  control={
                    <Switch
                      name="possui_nome_social"
                      color="primary"
                      checked={aluno.responsavel.possui_nome_social}
                    />
                  }
                  size="small"
                  name="possui_nome_social"
                  onChange={(e) => updateFieldNomeSocialResponsavel(e)}
                />
                <span>Possui nome social?</span>
              </Grid>
              {aluno.responsavel.possui_nome_social && 
                <Grid item md={6} xs={12} sm={8}>
                  <TextField className="input" label="Nome Social" variant="outlined" size="small" name="nome_social" value={aluno.responsavel.nome_social} onChange={(e) => updateFieldResponsavel(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
              }
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_20">
              <Grid item md={4} xs={12} sm={6}>
                <InputMask
                  mask="999.999.999-99"
                  value={aluno.responsavel.cpf}
                  disabled={aluno.tipoCadastro === 'responsavel' ? true : false}
                  maskChar=""
                  onChange={(e) => formatarCPFResponsavel(e)}
                >
                  {() =>
                    <TextField id='cpf_responsavel' className="input" label="CPF*" variant="outlined" size="small" name="cpf" InputLabelProps={{ shrink: true }} disabled={aluno.tipoCadastro === 'responsavel' ? true : false}/>
                  }
                </InputMask>
              </Grid>
              <Grid item md={4} xs={12} sm={8}>
                <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={aluno.responsavel.nome} onChange={(e) => updateFieldResponsavel(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={4} xs={12} sm={8}>
                <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={aluno.responsavel.sobrenome} onChange={(e) => updateFieldResponsavel(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_20">
              <Grid item md={3} xs={12} sm={8}>
                <TextField type='date' className="input" label="Data de Nascimento*" variant="outlined" size="small" name="data_nascimento" value={aluno.responsavel.data_nascimento} onChange={(e) => updateFieldResponsavel(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={3} xs={12} sm={8}>
                <InputMask
                  mask="(99) 99999-9999"
                  value={aluno.responsavel.telefone}
                  disabled={false}
                  maskChar=""
                  onChange={(e) => updateFieldResponsavel(e)} 
                >
                  {() =>
                    <TextField className="input" label="Celular*" variant="outlined" size="small" name="telefone" InputLabelProps={{ shrink: true }} />
                  }
                </InputMask>
              </Grid>
              <Grid item md={6} xs={12} sm={8}>
                <TextField className="input" label="E-mail*" variant="outlined" size="small" name="email" value={aluno.responsavel.email} onChange={(e) => updateFieldResponsavel(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item md={1} xs={false} sm={1}></Grid>
      </Grid>
    }
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={1} xs={false} sm={1}></Grid>
      <Grid item md={10} xs={12} sm={10}>
        <div className='card_dados'>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h5>Endereço</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={2} xs={12} sm={8}>
              <InputMask
                mask="99999-999"
                value={aluno.endereco.cep}
                disabled={false}
                maskChar=""
                onChange={(e) => updateFieldEndereco(e)}
                onBlur={(e) => buscaEndereco(e)}
              >
                {() =>
                  <TextField className="input" label="CEP*" variant="outlined" size="small" name="cep" InputLabelProps={{ shrink: true }} />
                }
              </InputMask>
            </Grid>
            <Grid item md={6} xs={12} sm={8}>
              <TextField className="input" label="Endereço*" variant="outlined" size="small" name="logradouro" value={aluno.endereco.logradouro} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={8}>
              <TextField className="input" label="Número*" variant="outlined" size="small" name="numero" value={aluno.endereco.numero} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={8}>
              <TextField className="input" label="Complemento" variant="outlined" size="small" name="complemento" value={aluno.endereco.complemento} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={4} xs={12} sm={8}>
              <TextField className="input" label="Bairro*" variant="outlined" size="small" name="bairro" value={aluno.endereco.bairro} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={4} xs={12} sm={8}>
              <TextField className="input" label="Cidade*" variant="outlined" size="small" name="cidade" value={aluno.endereco.cidade} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={4} xs={12} sm={8}>
              <TextField className="input" label="Estado*" variant="outlined" size="small" name="estado" value={aluno.endereco.estado} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item md={1} xs={false} sm={1}></Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10" style={{marginBottom:20}}>
      <Grid item md={1} xs={false} sm={1}></Grid>
      <Grid item md={3} xs={12} sm={4}>
        <Button 
          fullWidth 
          color="secondary" 
          variant="contained" 
          className="btn_salvar" 
          size="small" 
          onClick={e => voltar()}
        >
          Voltar
        </Button>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <Button 
          fullWidth 
          color="primary" 
          variant="contained" 
          className="btn_salvar" 
          size="small" 
          onClick={e => proximo()}
        >
          Proximo
        </Button>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormCargo