import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button, FormControlLabel, Switch, FormLabel, FormControl } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Table from './../Table'
import CurrencyInput from './../mask/CurrencyInput'

function getModalStyle() {
  return {
  
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '96%',
    marginLeft: '2%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    height: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

const cabecalhoTabelaFinanceiro = [
  { id: 'parcont_datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
  { id: 'plancontas_descricao', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'formpag_descricao', numeric: false, disablePadding: false, label: 'Forma Pgto' },
  { id: 'parcont_situacao', numeric: false, disablePadding: false, label: 'Situação' },
  { id: 'parcont_valorparcela', numeric: false, disablePadding: false, label: 'Valor' },
  { id: 'parcont_multajuros', numeric: false, disablePadding: false, label: 'Juros/Multas' },
]

const acoesTabelaFinanceiro = []

const cabecalhoTabelaQuitacao = [
  { id: 'parcont_datavencimento', numeric: false, disablePadding: false, label: 'Vencimento', orderBy: 'datavencimentoOrder' },
  { id: 'plancontas_descricao', numeric: false, disablePadding: false, label: 'Descrição' },
  { id: 'parcont_valorparcela', numeric: false, disablePadding: false, label: 'Valor' },
]

const acoesTabelaQuitacao = []

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Confirmação Cancelamento</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h5>Financeiro Pendente:</h5>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table headCell={cabecalhoTabelaFinanceiro} rows={props.financeiros_pendentes} acoes={acoesTabelaFinanceiro} />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={1}>
						<Grid item md={4} xs={12} sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Lançar Financeiro de Conciliação</FormLabel>
								<FormControlLabel className="mg_left_10"
									control={
										<Switch
											name="Ativo"
											color="primary"
											checked={dados.lanc_fin}
										/>
									}
									size="small"
									label={dados.lanc_fin ? 'Sim' : 'Não'}
									name="lanc_fin"
									onChange={(e) => props.updateFieldLancFinanceiroCancelamento(e)}
								/>
							</FormControl>
						</Grid>
					</Grid>

          {dados.lanc_fin && 
            <React.Fragment>
              <br />
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Valores para Quitação:</h5>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={12} xs={12}>
                  <Table headCell={cabecalhoTabelaQuitacao} rows={props.valores_quitacao} acoes={acoesTabelaQuitacao} />
                </Grid>
              </Grid>

              <hr />
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={6}>
                  <h5>Lançamento da Quitação:</h5>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <h5 style={{textAlign: 'right'}}>Total Sugerido para Quitação: {props.total_sugerido}</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Tipo da Conta" variant="outlined" value={props.conta.contpr_tipo} InputLabelProps={{ shrink: true }} disabled/>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Plano de Contas" variant="outlined" value={`${props.conta.plancontas_codigo} - ${props.conta.plancontas_descricao}`} InputLabelProps={{ shrink: true }} disabled/>
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Descrição*" variant="outlined" name="contpr_descricao" value={props.conta.contpr_descricao} InputLabelProps={{ shrink: true }} disabled/>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" label="Valor*" variant="outlined" name="contpr_valortotal" value={props.conta.contpr_valortotal} onChange={(e) => props.updateFieldConta(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <div className='scroll'>
                    <table className="lista sempadding borderTable">
                      <thead>
                        <tr className="titulos acompanha">
                          <th>Parcelas</th>
                          <th>Valor*</th>
                          <th>Vencimento*</th>
                          <th>Forma de Pagamento*</th>
                          <th>Conta Financeira</th>
                          <th>Documento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                          return (
                            <tr key={parcela.index}>
                              <td>
                                <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                                <span className="separacaoParcelas">/</span>
                                <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={props.conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) => props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                              </td>
                              <td>
                                <TextField className="inputValor" variant="outlined" size="small" name="parcont_valorparcela" value={parcela.parcont_valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                              </td>
                              <td>
                                <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="parcont_datavencimento" value={parcela.parcont_datavencimento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                              </td>
                              <td>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="inputFormPagamento"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="parcont_formapagto_id"
                                  value={parcela.parcont_formapagto_id}
                                  onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  <option value="">--Escolha--</option>
                                  {props.formasPagamento.map(forma => {
                                    return (
                                      <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                                    )
                                  })}
                                </TextField>
                              </td>
                              <td>
                                <TextField
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="inputConta"
                                  fullWidth
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="contfin_id"
                                  value={parcela.contfin_id}
                                  onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                  InputLabelProps={{ shrink: true }}
                                  disabled={parcela.parcont_formapagto_id === ''}
                                >
                                  <option value="">--Escolha--</option>
                                  {props.contasFinanceira.filter(param => param.contfin_tipo_conta === (parcela.parcont_formapagto_id === 1 ? 'COFRE': 'BANCO')).map(conta => {
                                    return (
                                      <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
                                    )
                                  })}
                                </TextField>
                              </td>
                              <td>
                                <TextField className="inputDocumento" variant="outlined" size="small" name="parcont_documento" value={parcela.parcont_documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </Grid>
              <br />
              <hr />
              <br />
            </React.Fragment>
          }

          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p>Deseja realmente cancelar esse contrato?</p>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
            <TextField
                id="outlined-multiline-static"
                label="Motivo"
                className="input"
                multiline
                rows={5}
                size="small"
                variant="outlined"
                name="motivoCancelamento"
                value={dados.motivoCancelamento}
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={ props.loadingSalvar ? true : (dados.motivoInativar === "" ? true : false)}>Sim</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.loadingSalvar}>Não</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
