import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Alterar Forma de Pagamento</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                className="input"
                fullWidth
                label="Forma de Pagamento*"
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="formpag_id"
                value={dados.formpag_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              >
                <option value="">--Escolha--</option>
                {props.formasPagamento.map(forma => {
                  return (
                    <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                id="standard-select-currency"
                select
                variant="outlined"
                className="input"
                fullWidth
                label="Conta Financeira"
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="contfin_id"
                value={dados.contfin_id}
                onChangeCapture={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
                disabled={dados.formpag_id === ''}
              >
                <option value="">--Escolha--</option>
                {props.contasFinanceira.filter(param => param.contfin_tipo_conta === (dados.formpag_id === '1' ? 'COFRE': 'BANCO')).map(conta => {
                  return (
                    <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
                  )
                })}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={props.disabledButtons}>Confirmar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.disabledButtons}>Voltar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
