import React, { Component } from 'react'
import './Aula.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button, TextField } from '@material-ui/core'

import { Link } from 'react-router-dom'

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'

const initalState = {
  aula: {
    _id: "",
    aula_nome: "",
    aula_book_id: "",
    aula_duracao: "",
    aula_tipo: "",
    aula_agend_online: false,
    aula_ativo: true,
    aula_soma_min_aval: 0,
    avaliacoes: []
  },
  avaliacao: {
    avalaula_descricao: "",
    avalaula_nota: 0
  },
  aulas: {
    list: []
  },
  book: {
    bok_id: "",
    bok_nome: "",
    bc_obrigatorio: true,
    bc_ativo: true,
    produto: {},
  },
  books: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'avalaula_descricao', numeric: false, disablePadding: true, label: 'Descrição Avaliação' },
    { id: 'avalaula_nota', numeric: false, disablePadding: false, label: 'Nota Avaliação' },
  ],
  update: false,
  acoesTabela: [],
}

export default class ViewAula extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'aulas')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_visualizar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.aulaId) {
      try {
        const { data: aula } = await axios.get(`${process.env.REACT_APP_API_URL}/aula/${params.aulaId}`, this.getToken())

        const avaliacoes = []

        for (let i = 0; i < aula.avaliacoes.length; i++) {
          avaliacoes.push({
            avalaula_id: aula.avaliacoes[i].avalaula_id,
            avalaula_descricao: aula.avaliacoes[i].avalaula_descricao,
            avalaula_nota: aula.avaliacoes[i].avalaula_nota,
            index: i + 1
          })
        }

        const duracao_div = aula.aula_duracao.toString().split('.')
        let aula_duracao = ""

        if (duracao_div.length === 1) {
          aula_duracao = `0${duracao_div[0]}:00`
        } else if (duracao_div.length === 2) {
          aula_duracao = `0${duracao_div[0]}:30`
        }

        this.setState({
          aula: {
            _id: aula.aula_id,
            aula_nome: aula.aula_nome,
            aula_book_id: aula.aula_book_id,
            aula_duracao,
            aula_tipo: aula.aula_tipo,
            aula_agend_online: aula.aula_agend_online,
            aula_ativo: aula.aula_ativo,
            aula_soma_min_aval: aula.aula_soma_min_aval,
            aula_tipo_horario: aula.aula_tipo_horario,
            avaliacoes
          },
          book: {
            bok_id: aula.book.bok_id,
            bok_nome: aula.book.bok_nome,
            bc_obrigatorio: aula.book.bc_obrigatorio,
            bc_ativo: aula.book.bc_ativo
          }
        })


      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar a aula, tente mais tarde"
        })

        this.backPage()
      }
    }

    this.setState({ loading: false })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/aula");
    }, timeout)
    this.setState({
      aula: initalState.aula,
      book: initalState.book
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { aula, book } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div id="view">
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={9} xs={12}>
                  <h2 className="titulo">Aula: {aula.aula_nome}</h2>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Link to={`/aula/cadastro/${aula._id}`}>
                    <Button className="btnCadastrar" variant="contained" color="primary">
                      Editar Aula
                  </Button>
                  </Link>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={6} xs={12} sm={6}>
                  <TextField className="input" label="Nome" variant="outlined" size="small" name="aula_nome" value={aula.aula_nome} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Módulo" variant="outlined" size="small" name="bok_nome" value={book.bok_nome} disabled />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Duração da Aula" variant="outlined" size="small" name="aula_duracao" value={aula.aula_duracao} disabled />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Tipo da Aula" variant="outlined" size="small" name="aula_tipo" value={aula.aula_tipo} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Tipo do Horário" variant="outlined" size="small" name="aula_tipo_horario" value={aula.aula_tipo_horario} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Soma Mínima" variant="outlined" size="small" name="aula_soma_min_aval" value={aula.aula_soma_min_aval} disabled />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Agendamento Online?" variant="outlined" size="small" name="aula_agend_online" value={aula.aula_agend_online ? 'Sim' : 'Não'} disabled />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField className="input" label="Ativo?" variant="outlined" size="small" name="aula_ativo" value={aula.aula_ativo ? 'Ativo' : 'Inativo'} disabled />
                </Grid>
              </Grid>

              <hr />
              <h4 className="mg_top_10">Avaliações:</h4>
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Table
                  headCell={this.state.cabecalhoTabela}
                  rows={this.state.aula.avaliacoes.map(avaliacao => {
                    return {
                      _id: avaliacao.avalaula_id,
                      avalaula_descricao: avaliacao.avalaula_descricao,
                      avalaula_nota: avaliacao.avalaula_nota,
                      index: avaliacao.index,
                    }
                  })}
                  acoes={this.state.acoesTabela} />
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}