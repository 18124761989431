import React, { Component } from 'react'
import './BaixaAula.scss'
import './../../main/ultil.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import ModalErro from './../../components/modals/Erro'
import { Grid, Button, TextField, FormControlLabel, Switch } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import ModalMudarProfessor from './../../components/modals/MudarProfessor'

const initalState = {
  schedules: {
    list: []
  },
  schedulesFiltrado: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'ageaula_data_hora', numeric: false, disablePadding: true, label: 'Data' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Aula' },
    { id: 'professor', numeric: false, disablePadding: false, label: 'Professor' },
    { id: 'qtd_alunos', numeric: false, disablePadding: false, label: 'Qtd. Alunos' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  erro: {
    titulo: "",
    descricao: ""
  },
  filtro: {
    data: "",
    hora_inicio: "",
    hora_fim: "",
    professor: "",
    aula: "",
    ageaula_status: 'ABERTO'
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesAlterarProfessor: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  professores: [],
  professor: {
    prof_id: "",
    pesf_nome: ""
  },
  aulas: [],
  aula: {
    aula_id: '',
    aula_nome: ''
  },
  loadingFiltro: false,
  disabledButton: false,
  modalMudarProfessor: false,
  agendaSelecionada: {
    _id: '',
    aula_nome: '',
    professor_schedule: '',
    professor_id: '',
    ageaula_data_hora: '',
    qtd_alunos: '',
    alunos: '',
    ageaula_unin_id: ''
  },
  rowProfessores: [],
  professoresUnidade: {
    list: []
  },
  professorSelecionado: {
    prof_id: ''
  },
  verificaAulas: true,
  afastamentos: {
    list: []
  },
  agendasUni: {
    list: []
  },
  loadingTrocaProfessor: false
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default class Schedules extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'baixa-aula')[0]
    let permissoesAlterarProfessor = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'altera-professor-baixa-aula')[0]

    this.setState({
      permissoes
    })

    if (permissoesAlterarProfessor) {
      this.setState({ permissoesAlterarProfessor })
    }


    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/byUnidade/${dados.unidadesnegocio[0]}`, this.getToken())

      this.setState({
        professores: data
      })

    } catch (error) {
      console.log(error);
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aula`, this.getToken())

      const aulas = []

      data.filter(param => param.aula_ativo === true).sort((a, b) => (a.aula_nome > b.aula_nome) ? 1 : ((b.aula_nome > a.aula_nome) ? -1 : 0)).map(aula => {
        aulas.push({
          aula_id: aula.aula_id,
          aula_nome: aula.aula_nome
        })
        return false
      })

      this.setState({
        aulas: aulas
      })

    } catch (error) {
      console.log(error);
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }

    const { match: { params } } = this.props;

    if (params.data) {
      let filtro = {
        data: params.data,
        hora_inicio: "",
        hora_fim: "",
        professor: "",
        aula: "",
        ageaula_status: 'ABERTO'
      }

      this.setState({ filtro })

      this.filtrar()
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  // updateFieldProfessor(event, value, schedule) {
  //   if (!value) return false

  //   let schedules = this.state.schedulesFiltrado.list

  //   const indexSchedules = schedules.findIndex(param => param._id === schedule._id)

  //   schedules[indexSchedules].id_professor = value.prof_id
  //   schedules[indexSchedules].professor.prof_id = value.prof_id
  //   schedules[indexSchedules].professor.prof_nome = value.prof_nome

  //   this.setState({
  //     schedulesFiltrado: {
  //       list: schedules
  //     }
  //   })
  // }

  updatePresenca(aluno, schedule) {
    let schedules = this.state.schedules.list

    const indexSchedules = schedules.findIndex(param => param._id === schedule._id)
    const indexAluno = schedules[indexSchedules].alunos.findIndex(param => param.aluno_id === aluno.aluno_id)

    schedules[indexSchedules].alunos[indexAluno].presente = !schedules[indexSchedules].alunos[indexAluno].presente

    for (let i = 0; i < schedules[indexSchedules].alunos[indexAluno].avaliacoes.length; i++) {
      schedules[indexSchedules].alunos[indexAluno].avaliacoes[i].nota_tirada = 0
    }

    this.setState({
      schedulesFiltrado: {
        list: schedules
      }
    })
  }

  updateNotaAvaliacao(event, avaliacao, aluno, schedule) {
    let schedules = this.state.schedulesFiltrado.list

    const indexSchedules = schedules.findIndex(param => param._id === schedule._id)
    const indexAluno = schedules[indexSchedules].alunos.findIndex(param => param.aluno_id === aluno.aluno_id)
    const indexAvaliacao = schedules[indexSchedules].alunos[indexAluno].avaliacoes.findIndex(param => param.avalaula_id === avaliacao.avalaula_id)

    schedules[indexSchedules].alunos[indexAluno].avaliacoes[indexAvaliacao].nota_tirada = parseInt(event.target.value)
    schedules[indexSchedules].alunos[indexAluno].nota_total = somarValores(schedules[indexSchedules].alunos[indexAluno].avaliacoes.map(avaliacao => { return avaliacao.nota_tirada }))

    this.setState({
      schedulesFiltrado: {
        list: schedules
      }
    })
  }

  updateFiltro(event) {
    const filtro = this.state.filtro
    filtro[event.target.name] = event.target.value

    this.setState({
      filtro
    })
  }

  removerSchedule(schedule) {
    let schedules = this.state.schedules.list
    const indexSchedules = schedules.findIndex(param => param._id === schedule._id)

    schedules.splice(indexSchedules, 1)

    this.setState({
      schedules: {
        list: schedules
      }
    })
  }

  async baixar(schedule) {
    this.setState({
      disabledButton: true
    })
    const schedules = this.state.schedules.list

    const indexSchedules = schedules.findIndex(param => param._id === schedule._id)

    const scheduleAux = schedules[indexSchedules]

    let dados = {
      prof_id: scheduleAux.professor.prof_id,
      aula_id: scheduleAux.aula.aula_id,
      alunos: scheduleAux.alunos.map(aluno => {
        return {
          aluno_id: aluno.aluno_id,
          aluaula_id: aluno.AlunoAgenda.aluaula_id,
          aluaula_status: aluno.presente ? 'PRESENTE' : 'AUSENTE',
          avaliacoes: aluno.avaliacoes.map(avaliacao => {
            return {
              avalaula_id: avaliacao.avalaula_id,
              nota_tirada: avaliacao.nota_tirada
            }
          })
        }
      })
    }

    if (dados.alunos.length === 0) {
      delete dados.alunos
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/baixarAula/${scheduleAux._id}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Aula baixada com sucesso.'
      })

      this.setState({
        disabledButton: false
      })

      this.removerSchedule(scheduleAux)

    } catch ({ response }) {
      this.setState({
        disabledButton: false
      })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  validateForm() {
    const { filtro } = this.state

    if (!filtro.data) return false
    return true
  }

  async filtrar() {

    this.setState({
      loadingFiltro: true
    })

    if (!this.validateForm()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Filtro!",
        descricao: "Preencha o campo da data"
      })
      return
    }

    try {

      let filtro = this.state.filtro

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/schedule/list`, filtro, this.getToken())

      const schedules = []

      data.filter(param => param.ageaula_status === "ABERTO").forEach(agenda => {
        schedules.push({
          _id: agenda.ageaula_id,
          aula_nome: agenda.aula.aula_nome,
          id_professor: agenda.professor.funcionario.fisica.pesf_nome,
          ageaula_data: moment(agenda.ageaula_data_hora).format('DD/MM/YYYY'),
          ageaula_hora: moment(agenda.ageaula_data_hora).format('HH:mm'),
          ageaula_data_hora_sem_form: agenda.ageaula_data_hora,
          ageaula_unin_id: agenda.ageaula_unin_id,
          qtd_alunos: agenda.alunos.length,
          alunos: agenda.alunos.map(aluno => {
            return {
              aluno_id: aluno.aluno_id,
              aluno_matricula: aluno.aluno_matricula,
              fisica: aluno.fisica,
              presente: false,
              AlunoAgenda: aluno.AlunoAgenda,
              avaliacoes: agenda.aula.avaliacoes.sort((a, b) => (a.avalaula_id > b.avalaula_id) ? 1 : ((b.avalaula_id > a.avalaula_id) ? -1 : 0)).map(avaliacao => {
                return {
                  avalaula_id: avaliacao.avalaula_id,
                  avalaula_nota: avaliacao.avalaula_nota,
                  avalaula_descricao: avaliacao.avalaula_descricao,
                  nota_tirada: 0,
                }
              }),
              nota_total: 0
            }
          }),
          aula: agenda.aula,
          professor: {
            prof_id: agenda.professor.prof_id,
            pesf_nome: agenda.professor.funcionario.fisica.pesf_nome,
          },
        })
      })

      this.setState({
        schedules: {
          list: schedules
        },
        loadingFiltro: false,
      })

    } catch ({ response }) {
      this.setState({
        loadingFiltro: false
      })

      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  updateFieldProfessorFiltro(event, value) {
    const filtro = this.state.filtro

    if (!value) {
      filtro.prof_id = ''

      this.setState({
        professor: {
          prof_id: '',
          pesf_nome: ''
        },
        filtro
      })
      return false
    }


    filtro.prof_id = value.prof_id

    this.setState({
      professor: value,
      filtro
    })
  }

  updateFieldAulaFiltro(event, value) {
    const filtro = this.state.filtro

    if (!value) {
      filtro.aula_id = ''
      this.setState({
        aula: {
          aula_id: '',
          aula_nome: ''
        },
        filtro
      })
      return false
    }


    filtro.aula_id = value.aula_id

    this.setState({
      aula: value,
      filtro
    })
  }

  async trocarProfessor(row) {
    const { filtro } = this.state

    this.setState({ loadingTrocaProfessor: true })

    try {

      let amanha = new Date(filtro.data)
      amanha = amanha.setDate(amanha.getDate() + 1);
      amanha = moment(amanha).format('YYYY-MM-DD')

      const dados = {
        data_inicial: filtro.data + ' 00:01',
        data_final: amanha + ' 23:59'
      }

      const periodo = {
        data_inicial: moment(filtro.data).format('YYYY-MM-DD'),
        data_final: moment(amanha).format('YYYY-MM-DD'),
      }

      const { data: professores } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/byUnidade/${row.ageaula_unin_id}`, this.getToken())
      const { data: afastamentos } = await axios.post(`${process.env.REACT_APP_API_URL}/afastamentoFuncionario/periodo/${row.ageaula_unin_id}`, dados, this.getToken())
      const { data: agendas } = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/byUnidade/periodo/${row.ageaula_unin_id}`, periodo, this.getToken())

      let agendaSelecionada = {
        _id: row._id,
        aula_nome: row.aula.aula_nome,
        aula_id: row.aula.aula_id,
        aula_duracao: row.aula.aula_duracao,
        professor_schedule: row.professor.pesf_nome,
        professor_id: row.professor.prof_id,
        ageaula_data_hora: row.ageaula_data_hora,
        ageaula_data_hora_sem_form: row.ageaula_data_hora_sem_form,
        ageaula_unin_id: row.ageaula_unin_id,
      }

      this.setState({
        professoresUnidade: {
          list: professores
        },
        afastamentos: {
          list: afastamentos
        },
        agendasUni: {
          list: agendas
        },
        agendaSelecionada
      })

      this.getProfDisp(row.aula.aula_id, row.ageaula_data_hora_sem_form, row.ageaula_unin_id, agendaSelecionada)

    } catch ({ response }) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  async getProfDisp(aulaSelc, dataAula, unidade, agenda) {
    const { afastamentos, agendasUni } = this.state

    try {
      const { data: professores } = await axios.get(`${process.env.REACT_APP_API_URL}/professores/byUnidadeByAula/${unidade}/${aulaSelc}`, this.getToken())

      let index = null
      const rows = []
      const diaSemana = moment(dataAula).format("dddd").replace("-feira", "").toUpperCase()
      const agendasUnidade = agendasUni.list
      const aula = agenda

      for (let i = 0; i < professores.length; i++) {
        const hora = moment(dataAula).format('HH:mm')
        let horaForm = hora.replace(":", "")
        horaForm = parseInt(horaForm)
        let horaMais = hora.split(":")
        horaMais = parseInt(horaMais[0]) + 1
        horaMais = horaMais < 10 ? `0${horaMais}` : horaMais
        horaMais = horaMais + ':00'
        let horaMenos = hora.split(":")
        horaMenos = parseInt(horaMenos[0]) - 1
        horaMenos = horaMenos < 10 ? `0${horaMenos}` : horaMenos
        horaMenos = horaMenos + ':00'

        let indexAgenda = agendasUnidade.findIndex(agendaUni => {
          const dataHoje = new Date()
          if (dataHoje.getTimezoneOffset() === 240) {
            let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
            dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
            if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
              return true
            } else {
              return false
            }
          } else {
            if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${hora}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id) {
              return true
            } else {
              return false
            }
          }
        })

        if (aula.aula_duracao === 2) {
          if (indexAgenda === -1) {
            const indexAgendaAnterior = agendasUnidade.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)

            if (indexAgendaAnterior !== -1) {
              if (agendasUnidade[indexAgendaAnterior].ageaula_duracao_aula === 2) {
                indexAgenda = agendasUnidade.findIndex(agendaUni => moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.professor.prof_id === professores[i].prof_id && agenda.ageaula_id !== agendaUni.ageaula_id)
              }
            }
          }
        }



        if (indexAgenda === -1) {
          if (aula.aula_duracao === 2) {
            indexAgenda = agendasUnidade.findIndex(agendaUni => {
              const dataHoje = new Date()

              if (dataHoje.getTimezoneOffset() === 240) {
                let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
                dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
                if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD HH:mm")} ${horaMais}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                  return true
                } else {
                  return false
                }
              } else {
                if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD HH:mm")} ${horaMais}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                  return true
                } else {
                  return false
                }
              }
            })

            if (indexAgenda === -1) {
              indexAgenda = agendasUnidade.findIndex(agendaUni => {
                const dataHoje = new Date()
                if (dataHoje.getTimezoneOffset() === 240) {
                  let dataAgenda = moment(agendaUni.ageaula_data_hora, moment.defaultFormat).toDate()
                  dataAgenda.setMinutes(dataAgenda.getMinutes() + 60)
                  if (moment(dataAgenda).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  if (moment(agendaUni.ageaula_data_hora).format('YYYY-MM-DD HH:mm') === `${moment(dataAula).format("YYYY-MM-DD")} ${horaMenos}` && agendaUni.ageaula_professor_id === professores[i].prof_id) {
                    return true
                  } else {
                    return false
                  }
                }
              })
            }
          }
        }

        if (indexAgenda === -1) {
          index = professores[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === horaForm)

          if (aula.aula_duracao === 2) {
            index = professores[i].disponibilidades.findIndex(param => param.disp_dia_semana === diaSemana && param.disp_hora_inicial_form === (horaForm + 100))
          }

          if (index !== -1) {

            const indexAusencia = afastamentos.list.findIndex(param => {
              if (new Date(`${moment(dataAula).format("YYYY-MM-DD")} ${hora}`).valueOf() <= new Date(param.afafun_data_final).valueOf() && new Date(`${moment(dataAula).format("YYYY-MM-DD")} ${hora}`).valueOf() >= new Date(param.afafun_data_inicial).valueOf() && param.afafun_fun_id === professores[i].funcionario.fun_id) {
                return true
              } else {
                return false
              }
            })

            if (indexAusencia === -1) {
              if (agenda.ageaula_professor_id !== professores[i].prof_id) {
                if (!professores[i].disponibilidades[index].disp_data_vig_fim && !professores[i].disponibilidades[index].disp_data_vig_inicio) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (Number(new Date(professores[i].disponibilidades[index].disp_data_vig_fim)) > Number(new Date(dataAula)) && Number(new Date(professores[i].disponibilidades[index].disp_data_vig_inicio)) < Number(new Date(dataAula))) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (Number(new Date(professores[i].disponibilidades[index].disp_data_vig_fim)) > Number(new Date(dataAula)) && !professores[i].disponibilidades[index].disp_data_vig_inicio) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                } else if (!professores[i].disponibilidades[index].disp_data_vig_fim && Number(new Date(professores[i].disponibilidades[index].disp_data_vig_inicio)) < Number(new Date(dataAula))) {
                  rows.push(<option key={professores[i].prof_id} value={professores[i].prof_id}> {professores[i].funcionario.fisica.pesf_nome} </option>)
                }
              }
            }

          }
        }
      }

      this.setState({
        rowProfessores: rows,
        modalMudarProfessor: true,
        loadingTrocaProfessor: false
      })

    } catch (error) {
      console.log(error)
    }
  }

  handleCloseModalMudarProfessor() {
    this.setState({
      modalMudarProfessor: false,
      agendaSelecionada: {
        _id: '',
        aula_nome: '',
        professor_schedule: '',
        professor_id: '',
        ageaula_data_hora: '',
        qtd_alunos: '',
        ageaula_unin_id: '',
        alunos: ''
      }
    })
  }

  updateFieldProfessor(event) {
    const { agendaSelecionada, professoresUnidade } = this.state

    const indexProf = professoresUnidade.list.findIndex(param => param.prof_id === parseInt(event.target.value))

    agendaSelecionada.professor_id = parseInt(event.target.value)
    agendaSelecionada.professor_schedule = professoresUnidade.list[indexProf].pesf_nome

    this.setState({
      agendaSelecionada
    })

  }

  async salvarTrocaProfessores() {
    const { agendaSelecionada, schedules } = this.state

    const dados = [{
      ageaula_id: agendaSelecionada._id,
      ageaula_professor_id: agendaSelecionada.professor_id
    }]

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/trocarProfessor`, dados, this.getToken())

      this.setState({
        modalMudarProfessor: false
      })

      const index = schedules.list.findIndex(param => param._id === agendaSelecionada._id)

      schedules.list[index].id_professor = agendaSelecionada.professor_id
      schedules.list[index].professor.prof_id = agendaSelecionada.professor_id
      schedules.list[index].professor.pesf_nome = agendaSelecionada.professor_schedule

      this.setState({
        schedules
      })

      // this.filtrar()
    } catch ({ response }) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  verficaNotaAula(notaInformada, avaliacao, aluno, schedule) {
    if (parseInt(notaInformada.target.value) > avaliacao.avalaula_nota) {

      let schedules = this.state.schedulesFiltrado.list

      const indexSchedules = schedules.findIndex(param => param._id === schedule._id)
      const indexAluno = schedules[indexSchedules].alunos.findIndex(param => param.aluno_id === aluno.aluno_id)
      const indexAvaliacao =
        schedules[indexSchedules].alunos[indexAluno].avaliacoes.findIndex(param => param.avalaula_id === avaliacao.avalaula_id)

      schedules[indexSchedules].alunos[indexAluno].avaliacoes[indexAvaliacao].nota_tirada = 0
      schedules[indexSchedules].alunos[indexAluno].nota_total =
        somarValores(schedules[indexSchedules].alunos[indexAluno].avaliacoes.map(avaliacao => { return avaliacao.nota_tirada }))

      this.setState({
        schedulesFiltrado: {
          list: schedules
        }
      })

      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'A nota informada não pode ser maior que a nota máxima da avaliação.'
      })
      return
    }
  }

  render() {
    const { filtro, schedules, permissoes, professores, professor, aula, aulas, disabledButton, permissoesAlterarProfessor } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Aulas...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Baixar aulas</h1>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="date" className="input" label="Data*" variant="outlined" name="data" value={filtro.data} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="time" className="input" label="Hora Inicio" variant="outlined" name="hora_inicio" value={filtro.hora_inicio} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField type="time" className="input" label="Hora Fim" variant="outlined" name="hora_fim" value={filtro.hora_fim} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => this.updateFieldProfessorFiltro(event, value)}
                    freeSolo
                    options={professores}
                    value={professor}
                    getOptionLabel={option => option.pesf_nome}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Professor*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={professor.pesf_nome} />
                    )}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <Autocomplete
                    onChange={(event, value) => this.updateFieldAulaFiltro(event, value)}
                    freeSolo
                    options={aulas}
                    value={aula}
                    getOptionLabel={option => option.aula_nome}
                    renderInput={(params) => (
                      <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Aula*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={aula.aula_nome} />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={10} xs={12} sm={4}></Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()}>
                    Filtrar
                  </Button>
                </Grid>
              </Grid>

              {this.state.loadingFiltro &&
                <React.Fragment>
                  <div className="progressCircular">
                    <CircularProgress />
                  </div>
                  <div className="progressText">
                    <p>Carregando Aulas...</p>
                  </div>
                </React.Fragment>
              }
              {!this.state.loadingFiltro &&
                <React.Fragment>
                  {schedules.list.length === 0 &&
                    <h3>Não existem aulas abertas para essa data</h3>
                  }
                  {schedules.list.map(schedule => {
                    if (schedule.alunos.length > 0) {
                      return (
                        <Grid container spacing={1} direction="row" key={schedule._id}>
                          <Grid item md={12} xs={12}>
                            <div className="cardEndereco">
                              <Grid container spacing={1} direction="row">
                                <Grid item md={9} xs={9} sm={7}>
                                  <span className='spanBaixaAula'>{schedule.ageaula_data}-{schedule.ageaula_hora}: {schedule.aula_nome} com {schedule.professor.pesf_nome}</span>
                                </Grid>
                                <Grid item md={1} xs={9} sm={1}>
                                  {this.state.loadingTrocaProfessor &&
                                    <CircularProgress />
                                  }
                                </Grid>
                                {permissoesAlterarProfessor.perm_alterar &&
                                  <Grid item md={2} xs={3} sm={4}>
                                    <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<SwapHorizIcon />} onClick={e => this.trocarProfessor(schedule)} disabled={this.state.loadingTrocaProfessor}>Trocar Professor</Button>
                                  </Grid>
                                }
                              </Grid>
                              < br />
                              <hr />
                              < br />
                              <Grid container spacing={1} direction="row mg_top_20">
                                <Grid item md={12} xs={12} sm={12}>
                                  <table className="lista sempadding borderTable">
                                    <thead>
                                      <tr className="titulos acompanha">
                                        <th>Aluno</th>
                                        <th>Presença</th>
                                        {schedule.aula.avaliacoes.map(avaliacao => {
                                          return (
                                            <th key={avaliacao.avalaula_id}>{avaliacao.avalaula_descricao}</th>
                                          )
                                        })}
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {schedule.alunos.map(aluno => {
                                        return (
                                          <tr key={aluno.aluno_id}>
                                            <td className="textalignleft"><span className="spanTabelaSchedule">{aluno.aluno_matricula}-{aluno.fisica.pesf_nome}{aluno.fisica.pesf_sobrenome ? ` ${aluno.fisica.pesf_sobrenome}` : ''}</span></td>
                                            <td className="switch">
                                              <div className="switchSchedule">
                                                <FormControlLabel
                                                  control={
                                                    <Switch
                                                      name="presente"
                                                      color="primary"
                                                      checked={aluno.presente}
                                                    />
                                                  }
                                                  label={aluno.presente ? "Presente" : "Ausente"}
                                                  name="presente"
                                                  onChange={(e) => this.updatePresenca(aluno, schedule)}
                                                />
                                              </div>
                                            </td>
                                            {aluno.avaliacoes.map(avaliacao => {
                                              return (
                                                <td key={avaliacao.avalaula_id}>
                                                  <TextField
                                                    type="number"
                                                    className="inputParcela"
                                                    variant="outlined"
                                                    size="small"
                                                    name="nota_tirada"
                                                    value={avaliacao.nota_tirada}
                                                    onChange={(e) => this.updateNotaAvaliacao(e, avaliacao, aluno, schedule)}
                                                    onBlur={(e) => this.verficaNotaAula(e, avaliacao, aluno, schedule)}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={!aluno.presente}
                                                  />
                                                  <span className="spanTabelaSchedule">{avaliacao.nota_tirada}/{avaliacao.avalaula_nota}</span>
                                                </td>
                                              )
                                            })}
                                            <td><span className="spanTabelaSchedule">{aluno.nota_total}</span></td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} direction="row">
                                <Grid item md={10} xs={6} sm={8}></Grid>
                                <Grid item md={2} xs={6} sm={4}>
                                  {permissoes.perm_inserir &&
                                    <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveAltIcon />} onClick={e => this.baixar(schedule)} disabled={disabledButton}>Baixar</Button>
                                  }
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      )
                    }
                    return false
                  })}
                </React.Fragment>
              }
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalMudarProfessor
            open={this.state.modalMudarProfessor}
            handleClose={e => this.handleCloseModalMudarProfessor(e)}
            updateFieldProfessor={e => this.updateFieldProfessor(e)}
            agenda={this.state.agendaSelecionada}
            rowProfessores={this.state.rowProfessores}
            salvarTrocaProfessores={e => this.salvarTrocaProfessores()} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}