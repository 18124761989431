import React, { Component } from 'react'
import './../Relatorios.scss'
import './../../../main/ultil.scss'
import { Grid } from '@material-ui/core'
import moment from 'moment'

const initalState = {
  relatorios: {
    list: []
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
		func_id: "",
		fun_id: ""
  }
}

export default class FlyHigh extends Component {
  state = { ...initalState }

  async componentWillMount() {
    const dados = JSON.parse(localStorage.getItem('evolucao_alunos_periodo'))
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    this.setState({
      relatorios: {
        list: dados.relatorio
      },
      cabecalhoTabela: dados.cabecalhoTabela,
      filtro: dados.filtro,
      pessoa
    })
    
    setTimeout(() => {
      window.print()
      this.props.history.push("/rel_comercial");
      this.setState({ loading: false })
      return;
    }, 500);
 
  }    

  render() {
    const { filtro, pessoa, relatorios, cabecalhoTabela } = this.state
		
		return (
      <div id="app">
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={12} xs={12} sm={12}>
            <p style={{fontSize: '42px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'center'}}><b>Evolução de Alunos por Período</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Relatório: {moment(new Date()).format('DD/MM/YYYY HH:mm')}</b></p>
          </Grid>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman', textAlign: 'right'}}><b>Gerado por: {pessoa ? pessoa.fisica.pesf_nome.split(' ')[0] : ''}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row" className="mg_top_10" style={{backgroundColor: '#FFF'}}>
          <Grid item md={6} xs={6} sm={6}>
            <p style={{fontSize: '28px', textTransform: 'uppercase', fontFamily: 'times new roman'}}><b>Data Inicial: {filtro.data}</b></p>
          </Grid>
        </Grid>
        <hr style={{width: "100%"}}/>
        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12}>
            <div className='scroll relatorio'>
              <table className="lista sempadding borderTable">
                <thead>
                  <tr className="titulos acompanha">
                    {cabecalhoTabela.map(value => {
                      return (
                        <th key={value.id}>{value.label}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {relatorios.list.map((row, key) => {return (
                    <tr key={key}>
                      <td>{row.descricao}</td>
                      <td>{row.data_0.qtd}</td>
                      <td>{row.data_1.qtd}</td>
                      <td>{row.data_2.qtd}</td>
                      <td>{row.data_3.qtd}</td>
                      <td>
                        {row.porcentagem === 'msg' &&
                          <span>Novos Alunos + Rematrículas + Prorrogações - <br /> Encerramento - Inativações - Cancelamentos</span>
                        }
                        {(row.porcentagem !== '' &&  row.porcentagem !== 'msg') &&
                          <div className='porcentagem'>
                            <div style={{width: `${row.porcentagem}%`}}>
                              {row.porcentagem}%
                            </div>
                          </div>
                        }
                      </td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}