import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormCargo = ({ update, tabela_valor, dados, updateField, updateFieldAtivo,
  updateFieldTabelaValor, updateFieldComissao, updateFieldAtivoTabelaValor, addItem,
  unidades, updateFieldUnidade, unidadesSelecionadas, cursosSelecionados, cursos, updateFieldCurso  }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={7} xs={12} sm={4}>
        <TextField className="input" label="Descrição*" variant="outlined" size="small" name="planpag_descricao" value={dados.planpag_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField type="date" className="input" label="Data*" variant="outlined" size="small" name="planpag_data" value={dados.planpag_data} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="planpag_ativo"
                color="primary"
                checked={dados.planpag_ativo}
              />
            }
            size="small"
            label={dados.planpag_ativo ? 'Ativo' : 'Inativo'}
            name="planpag_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={6} xs={12} sm={12}>
        <Autocomplete
          multiple
          limitTags={10}
          id="multiple-limit-tags"
          options={unidades.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : ((b.unin_numero > a.unin_numero) ? -1 : 0)).filter(unidade => {
            if (unidadesSelecionadas.findIndex(i => i.unin_id === unidade.unin_id) === -1){
              return unidade;
            }
            return false
          })}
          size="small"
          value={unidadesSelecionadas}
          onChange={(event, value) => updateFieldUnidade(event, value)}
          getOptionLabel={(option) => `${option.unin_numero} - ${option.unin_descricao}`}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Unidades" InputLabelProps={{ shrink: true }} />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12} sm={12}>
        <Autocomplete
          multiple
          limitTags={10}
          id="multiple-limit-tags"
          options={cursos.sort((a, b) => (a.curso_nome > b.curso_nome) ? 1 : ((b.curso_nome > a.curso_nome) ? -1 : 0)).filter(curso => {
            if (cursosSelecionados.findIndex(i => i.curso_id === curso.curso_id) === -1){
              return curso;
            }
            return false
          })}
          size="small"
          value={cursosSelecionados}
          onChange={(event, value) => updateFieldCurso(event, value)}
          getOptionLabel={(option) => option.curso_nome}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Cursos" InputLabelProps={{ shrink: true }} />
          )}
        />
      </Grid>
    </Grid>
    <br />
    <hr />
    <br />
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <h4>Plano de Pagamento:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={4} xs={12} sm={6}>
        <TextField className="input" label="Nome*" variant="outlined" size="small" name="tabval_nome" value={tabela_valor.tabval_nome} onChange={(e) => updateFieldTabelaValor(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField type="number" className="input" label="N. de parcelas*" variant="outlined" size="small" name="tabval_num_parc" value={tabela_valor.tabval_num_parc} onChange={(e) => updateFieldTabelaValor(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Matrícula*" variant="outlined" size="small" name="tabval_val_matri" value={tabela_valor.tabval_val_matri} onChange={(e) => updateFieldTabelaValor(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Mensalidade*" variant="outlined" size="small" name="tabval_val_mensal" value={tabela_valor.tabval_val_mensal} onChange={(e) => updateFieldTabelaValor(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Mensalidade Cheia*" variant="outlined" size="small" name="tabval_val_cheio_mensal" value={tabela_valor.tabval_val_cheio_mensal} onChange={(e) => updateFieldTabelaValor(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={3} xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="tabval_ativo"
                color="primary"
                checked={tabela_valor.tabval_ativo}
              />
            }
            size="small"
            label={tabela_valor.tabval_ativo ? 'Ativo' : 'Inativo'}
            name="tabval_ativo"
            onChange={(e) => updateFieldAtivoTabelaValor(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={12} xs={12} sm={12}>
        <h4>Honorários:</h4>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      {tabela_valor.comissoes.map(comissao => {
        return (
          <Grid key={comissao.index} item md={2} xs={12} sm={6}>
            <TextField className="input" label={comissao.func_descricao} variant="outlined" size="small" name="comissfunc_valor_comissao" value={comissao.comissfunc_valor_comissao} onChange={(e) => updateFieldComissao(e, comissao.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
          </Grid>
        )
      })}
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label='Total Honorários' variant="outlined" size="small" name="tabval_val_comissoes" value={tabela_valor.tabval_val_comissoes} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid item md={9} xs={false} sm={6}></Grid>
      <Grid item md={3} xs={false} sm={6}>
        <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" onClick={e => addItem(e)}>{update ? 'Alterar' : 'Adicionar'}</Button>
      </Grid>
    </Grid>
    <hr />
  </React.Fragment>
)

export default FormCargo