import React, { Component } from 'react'
import './../Unidade.scss'
import './../../../main/ultil.scss'
import { Grid, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'
import Table from '../../../components/Table'
import axios from 'axios'
import ModalErro from './../../../components/modals/Erro'

const initalState = {
  unidade: {},
  loading: true,
  locaisEstoques: [],
  cabecalhoTabela: [
    { id: 'unidade', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'descricao', numeric: false, disablePadding: false, label: 'Descricão' }
  ],
  acoesTabela: ['update'],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
}

export default class Unidade extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    const unidade = this.props.unidade

    try {

      const { data: localEstoques } = await axios.get(`${process.env.REACT_APP_API_URL}/localEstoque/byUnidade/${unidade.unin_id}`, this.getToken())

      this.setState({
        locaisEstoques: localEstoques.map(local => {
          return {
            _id: local.localestoq_id,
            descricao: local.localestoq_descricao,
            unidade: local.unidade.unin_numero + ' - ' + local.unidade.unin_descricao
          }
        })
      })

    } catch ({ response }) {
      // console.log(error)
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      unin_tipo,
      unidade: unidade,
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { locaisEstoques } = this.state
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Dados do Local de Estoque...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom mg_top_10"
            >
              <Grid item md={8} xs={12}>
                <h1 className="titulo">Local de Estoque</h1>
              </Grid>
              <Grid item md={4} xs={12}>
                <Link to="/local_estoque/cadastro">
                  <Button className="btnCadastrar" variant="contained" color="primary">
                    Cadastrar Local de Estoque
							    </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              className="mg_top_10"
            >
              <Grid item md={12} xs={12}>
                <Table headCell={this.state.cabecalhoTabela} rows={locaisEstoques} acoes={this.state.acoesTabela} urlUpdate="/local_estoque/update/" />
              </Grid>
            </Grid>

          </div>
        }
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
      </React.Fragment>
    )
  }
}