import React from 'react'

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'

const FormSubGrupoProdutos = ({ grupoProdutos, dados, updateField, updateFieldAtivo }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={5} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="subgrupprod_descricao" value={dados.subgrupprod_descricao} onChange={(e) => updateField(e)} />
      </Grid>
      <Grid item md={5} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Grupo Produtos"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="subgrupprod_idgrupoproduto"
          value={dados.subgrupprod_idgrupoproduto}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          <option value={dados.subgrupprod_idgrupoproduto === "" ? "Selecionar Grupo Produto" : dados.subgrupprod_idgrupoproduto}> {dados.subgrupprod_idgrupoproduto === "" ? "Selecionar Grupo Produto" : dados.grupprod_descricao} </option>
          {grupoProdutos.map(grupoProduto => {
            if (grupoProduto.grupprod_id !== dados.subgrupprod_idgrupoproduto) {
              return (
                <option key={grupoProduto.grupprod_id} value={grupoProduto.grupprod_id}> {grupoProduto.grupprod_descricao} </option>
              )
            } else {
              return false
            }
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="ativo"
              color="primary"
              checked={dados.subgrupprod_ativo}
            />
          }
          label={dados.subgrupprod_ativo ? "Ativo" : "Inativo"}
          name="subgrupprod_ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormSubGrupoProdutos