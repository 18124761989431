import React, { Component } from 'react'
import './ContaFinanceira.scss'
import './../../main/ultil.scss'
import CurrencyInput from './../../components/mask/CurrencyInput'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, TextField, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import ModalConfirmarSenha from './../../components/modals/ConfirmarSenha'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import real from '../../services/real'

const initalState = {
  contaFinanceiraAux: {
    contfin_id: '',
    contfin_descricao: '',
    contfin_ordem: '',
    contfin_conciliavel: '',
    contfin_saldo_inicial: '',
    contfin_saldo_atual: '',
    contfin_saldo_atual_corrigido: '',
    contfin_tipo_conta: '',
    contfin_pes_id: '',
    contfin_ativo: '',
    contfin_cofre: '',
    contfin_saldo_nao_conciliado: '',
    contfin_unin_id: '',
    observacao: '',
    data_ajuste: ''
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  loadingSalvar: false,
  update: false,
  modalSenha: false,
  login: {
    login: '',
    senha: ''
  },
  showPassword: false
}


export default class CadastroPlanoContas extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {

    this.setState({
      contaFinanceiraAux: {
        contfin_id: '',
        contfin_descricao: '',
        contfin_ordem: '',
        contfin_conciliavel: '',
        contfin_saldo_inicial: '',
        contfin_saldo_atual: '',
        contfin_saldo_atual_corrigido: '',
        contfin_tipo_conta: '',
        contfin_pes_id: '',
        contfin_ativo: '',
        contfin_cofre: '',
        contfin_saldo_nao_conciliado: '',
        contfin_unin_id: '',
        observacao: ''
      },
      login: {
        login: '',
        senha: ''
      },
    })

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    let permissoesCorrigeSaldo = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'ajuste_saldo_conta')[0]

    if (!permissoesCorrigeSaldo.perm_inserir && !permissoesCorrigeSaldo.perm_alterar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.contaFinanceiraId) {
      try {
        const { data: contaFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/${params.contaFinanceiraId}`, this.getToken())

        let contaFinanceiraAux = {
          contfin_id: contaFinanceira.contfin_id,
          contfin_descricao: contaFinanceira.contfin_descricao,
          contfin_ordem: contaFinanceira.contfin_ordem,
          contfin_conciliavel: contaFinanceira.contfin_conciliavel,
          contfin_saldo_inicial: real(contaFinanceira.contfin_saldo_inicial),
          contfin_saldo_atual: real(contaFinanceira.contfin_saldo_atual),
          contfin_saldo_atual_corrigido: real(contaFinanceira.contfin_saldo_atual),
          contfin_tipo_conta: contaFinanceira.contfin_tipo_conta,
          contfin_pes_id: contaFinanceira.contfin_pes_id,
          contfin_ativo: contaFinanceira.contfin_ativo,
          contfin_cofre: contaFinanceira.contfin_cofre ? 'Sim' : 'Não',
          contfin_saldo_nao_conciliado: contaFinanceira.contfin_saldo_nao_conciliado,
          contfin_unin_id: contaFinanceira.contfin_unin_id,
          observacao: ''
        }

        this.setState({
          contaFinanceira: contaFinanceiraAux
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar a conta financeira, tente mais tarde"
        })

        this.backPage()
      }
    }

    this.setState({ loading: false })
  }

  updateField(event) {
    const contaFinanceira = { ...this.state.contaFinanceira }

    contaFinanceira[event.target.name] = event.target.value
    this.setState({ contaFinanceira })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleCloseConfirmarSenha() {
    this.setState({
      modalSenha: false
    })
  }

  backPage(timeout = 1000) {
    const self = this
    this.setState({
      contaFinanceira: {
        contfin_id: '',
        contfin_descricao: '',
        contfin_ordem: '',
        contfin_conciliavel: '',
        contfin_saldo_inicial: '',
        contfin_saldo_atual: '',
        contfin_saldo_atual_corrigido: '',
        contfin_tipo_conta: '',
        contfin_pes_id: '',
        contfin_ativo: '',
        contfin_cofre: '',
        contfin_saldo_nao_conciliado: '',
        contfin_unin_id: '',
        observacao: ''
      },
      loadingSalvar: false
    })
    setTimeout(() => {
      self.props.history.push("/conta_financeira");
    }, timeout)

  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { contaFinanceira } = this.state

    if (!contaFinanceira.contfin_saldo_atual_corrigido) return false
    if (!contaFinanceira.observacao) return false

    return true
  }

  updateData(event) {
    const { login } = this.state

    login[event.target.name] = event.target.value
    this.setState({ login })
  }

  openConfirmaSenha() {
    this.setState({
      login: {
        login: '',
        senha: ''
      }
    })
    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      this.setState({
        loadingSalvar: false
      })
      return
    }

    this.setState({
      modalSenha: true
    })
  }

  verificaData(event){
    const contaFinanceira = { ...this.state.contaFinanceira }
    
    const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
    const data_vencimento = new Date(event.target.value)

    if (data_vencimento.getTime() > data_hoje.getTime()) {
      contaFinanceira.data_ajuste = moment(new Date()).format('YYYY-MM-DD')
    }

    this.setState({
      contaFinanceira
    })
  }

  async salvar(value) {
    if (value) {
      this.setState({
        loadingSalvar: true
      })

      let valido = true

      try {
        const login = {
          login: JSON.parse(localStorage.getItem('usu_login')),
          senha: this.state.login.senha
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/usuarios/login`, login)

        valido = true

      } catch (error) {
        valido = false
        this.setState({
          loadingSalvar: false
        })
        this.handleOpenDialog({
          titulo: "Senha Invalida!",
          descricao: "Por favor informar a senha corretamente!"
        })
      }

      if (valido) {
        try {

          const { contaFinanceira } = this.state

          if(contaFinanceira.data_ajuste === ""){
            this.handleOpenDialog({
              titulo: 'Erro no cadastro',
              descricao: 'Por favor informar a data do ajuste.'
            })
            return
          }

          if(contaFinanceira.observacao === ""){
            this.handleOpenDialog({
              titulo: 'Erro no cadastro',
              descricao: 'Por favor informar a justificativa.'
            })
            return
          }

          const dados = {
            contfin_saldo_atual_corrigido: parseFloat(contaFinanceira.contfin_saldo_atual_corrigido.replace("R$ ", "").replace(".", "").replace(",", ".")),
            observacao: contaFinanceira.observacao,
            data_ajuste: contaFinanceira.data_ajuste
          }

          await axios.put(`${process.env.REACT_APP_API_URL}/contaFinanceira/corrigeSaldo/${contaFinanceira.contfin_id}`, dados, this.getToken())

          this.setState({
            contaFinanceira: {
              contfin_id: '',
              contfin_descricao: '',
              contfin_ordem: '',
              contfin_conciliavel: '',
              contfin_saldo_inicial: '',
              contfin_saldo_atual: '',
              contfin_saldo_atual_corrigido: '',
              contfin_tipo_conta: '',
              contfin_pes_id: '',
              contfin_ativo: '',
              contfin_cofre: '',
              contfin_saldo_nao_conciliado: '',
              contfin_unin_id: '',
              observacao: ''
            }
          })

          this.handleOpenDialog({
            titulo: 'Parabéns',
            descricao: 'Atualização realizada com sucesso.'
          })
          this.backPage()

        } catch ({ response }) {
          this.setState({
            loadingSalvar: false
          })
          if (response.status === 400 || response.status === 500) {
            this.handleOpenDialog({
              titulo: 'Ops...',
              descricao: response.data.message
            })
            return
          }

          this.handleOpenDialog({})
        }
      }
    } else {
      this.setState({
        loadingSalvar: true
      })
      this.setState({
        modalSenha: false
      })
    }

  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }


  render() {
    const { contaFinanceira, loadingSalvar } = this.state

    const dataHoje = new Date()
    const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Conta Financeira...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h2 className="titulo">Corrigir Saldo Conta Financeira</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={1} xs={12} sm={2}>
                  <TextField className="input" label="Ordem" variant="outlined" size="small" name="contfin_ordem" value={contaFinanceira.contfin_ordem} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <TextField className="input" label="Descrição" variant="outlined" size="small" name="contfin_descricao" value={contaFinanceira.contfin_descricao} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
                <Grid item md={1} xs={12} sm={4}>
                  <TextField className="input" label="Conciliavel" variant="outlined" size="small" name="contfin_conciliavel" value={contaFinanceira.contfin_conciliavel ? 'Sim' : 'Não'} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
                <Grid item md={1} xs={12} sm={4}>
                  <TextField className="input" label="Tipo Conta" variant="outlined" size="small" name="contfin_tipo_conta" value={contaFinanceira.contfin_tipo_conta} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Saldo Atual" variant="outlined" size="small" name="contfin_saldo_atual" value={contaFinanceira.contfin_saldo_atual} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Saldo Corrigido*" variant="outlined" size="small" name="contfin_saldo_atual_corrigido" value={contaFinanceira.contfin_saldo_atual_corrigido} InputLabelProps={{ shrink: true }} onChange={(e) => this.updateField(e)} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Data AJuste*" type='date' variant="outlined" size="small" name="data_ajuste" value={contaFinanceira.data_ajuste} InputLabelProps={{ shrink: true }} onBlur={(e) => this.verificaData(e)} onChange={(e) => this.updateField(e)} InputProps={{ inputProps: { max: dataHojeFormatado } }} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Justificativa*"
                    className="input"
                    multiline
                    rows={5}
                    size="small"
                    variant="outlined"
                    name="observacao"
                    value={contaFinanceira.observacao}
                    onChange={(e) => this.updateField(e)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <br />
              <hr />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9} xs={12} sm={8}></Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.openConfirmaSenha(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalConfirmarSenha
            open={this.state.modalSenha}
            handleClose={e => this.handleCloseConfirmarSenha(e)}
            dados={this.state.login}
            updateData={e => this.updateData(e)}
            confirmar={e => this.salvar(e)}
            disabled={loadingSalvar}
            showPassword={this.state.showPassword}
            handleClickShowPassword={e => this.handleClickShowPassword(e)}
            mensagem='Deseja confirmar a alteração do saldo?' 
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}