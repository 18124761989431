import React from 'react'

import { Grid, TextField, FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const FormLogin = ({ dados, updateField, showPassword, handleClickShowPassword }) => (
  <React.Fragment>
    <Grid container direction="row" >
      <Grid item md={12} xs={12} sm={12}>
        <TextField className="input" label="Email" variant="outlined" name="login" value={dados.usuario} onChange={(e) => updateField(e)} />
      </Grid>
    </Grid>
    <Grid container direction="row" className="mg_top_20">
      <Grid item md={12} xs={12} sm={12}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={dados.senha}
            onChange={(e) => updateField(e)}
            className="input"
            name="senha"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => handleClickShowPassword(e)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormLogin