import React, { Component } from 'react'
import './Aluno.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'
import FormContratoAluno from '../../components/forms/FormContratoAluno'

import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios'
import moment from 'moment';
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  aluno: {
    contrato: {}
  },
  cursos: {
    list: []
  },
  curso: {
    curso_id: "",
    curso_nome: "",
  },
  formasPagamento: {
    list: []
  },
  tabelaValores: {
    list: []
  },
  tabelaValoresFiltrado: {
    list: []
  },
  unidade: {
    unin_id: "",
    unin_descricao: "",
  },
  unidades: {
    list: []
  },
  enableDadosPessoa: true,
  enableDadosEndereco: false,
  enableDadosEmail: false,
  enableDadosContato: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false
}

export default class UpdateContrato extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    try {
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    const { match: { params } } = this.props;
    if (params.contratoId) {
      try {
        const { data: contrato } = await axios.get(`${process.env.REACT_APP_API_URL}/contratoMatricula/${params.contratoId}`, this.getToken())

        contrato.contm_data_hora_cadastro = moment(contrato.contm_data_hora_cadastro).format('DD/MM/YYYY HH:mm')
        contrato.contm_data_cancelamento = contrato.contm_data_cancelamento ? moment(contrato.contm_data_cancelamento).format('Y-MM-DD') : false
        contrato.contm_observacao = contrato.contm_observacao ? contrato.contm_observacao : ''

        this.setState({
          aluno: {
            contrato
          },
          curso: {
            curso_id: contrato.curso.curso_id,
            curso_nome: contrato.curso.curso_nome,
          },
          unidade: {
            unin_id: contrato.unidade.unin_id,
            unin_descricao: contrato.unidade.unin_descricao,
          }
        })

      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o aluno, tente mais tarde"
        })

        this.backPage()
      }
    }

    try {
      this.setState({
        unidades: {
          list: (await axios.get(`${process.env.REACT_APP_API_URL}/unidades`, this.getToken())).data
        },
      })

      this.setState(
        {
          cursos: {
            list: (await axios.get(`${process.env.REACT_APP_API_URL}/curso`, this.getToken())).data
          }
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  async updateFieldCurso(event, value) {
    const { data: tabelaValores } = await axios.post(`${process.env.REACT_APP_API_URL}/planoPagamentoCurso/curso/${value.curso_id}`, {}, this.getToken())

    const { aluno } = this.state
    aluno.contrato.contm_curso_id = value.curso_id

    this.setState({
      aluno,
      curso: {
        curso_id: value.curso_id,
        curso_nome: value.curso_nome
      },
      tabelaValoresFiltrado: {
        list: tabelaValores
      }
    })
  }

  render() {
    const { aluno } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={11}>
              <h2 className="titulo">Atualização de Contrato</h2>
            </Grid>
            {this.state.loadingSalvar &&
              <Grid item md={1}>
                <div>
                  <CircularProgress />
                </div>
              </Grid>
            }
          </Grid>
          <div className="formFranqueado">
            <Grid container direction="row">
              <Grid item md={12}>
                <h3>Dados do Contrato</h3>
              </Grid>
            </Grid>
            <FormContratoAluno
              cursos={this.state.cursos.list}
              curso={this.state.curso}
              unidades={this.state.unidades.list}
              unidade={this.state.unidade}
              updateFieldCurso={(event, value) => this.updateFieldCurso(event, value)}
              updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
              updateField={e => this.updateFieldContrato(e)}
              dados={aluno.contrato}
              tabelaValores={this.state.tabelaValoresFiltrado.list} />

            <Grid spacing={1} className="mg_top_10" container direction="row" alignItems="flex-end">
              <Grid item md={9}></Grid>
              <Grid item md={3}>
                <Button fullWidth color="primary" variant="contained" size="small" endIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
              </Grid>
            </Grid>
          </div>


          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}