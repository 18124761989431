import React, { Component } from 'react'
import './Aula.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import ModalTrocaQtdAlunos from './../../components/modals/TrocaQtdAlunosAula'

const initalState = {
  aula: {
    aula_aluno_maximo: '',
    aula_aluno_minimo: '',
    aula_aluno_padrao: '',
    aula_limite_aluno: '',
    limite: '',
    aula_nome: ''
  },
  aulas: {
    list: []
  },
  aulasFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'aula_ordem', numeric: false, disablePadding: true, label: 'Ordem' },
    { id: 'aula_nome', numeric: false, disablePadding: true, label: 'Nome Aula' },
    { id: 'bok_nome', numeric: false, disablePadding: true, label: 'Nome Book' },
    { id: 'aula_tipo', numeric: false, disablePadding: false, label: 'Tipo Aula' },
    { id: 'aula_tipo_horario', numeric: false, disablePadding: false, label: 'Tipo Horario' },
    { id: 'aula_duracao', numeric: false, disablePadding: false, label: 'Duração Aula' },
    { id: 'aula_aluno_padrao', numeric: false, disablePadding: false, label: 'Alunos' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    aula_nome: '',
    bok_nome: '',
    aula_tipo: '',
    aula_tipo_horario: ''
  },
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  modalTrocaQtdAlunos: false,
  errorMinMax: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesHorario: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  habilitaQtdAlunos: false
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Aula extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const unid = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    if (unid.unidadesnegocio.length === 1) {
      try {
        const { data: unidade } = await axios.get(`${process.env.REACT_APP_API_URL}/unidades/${unid.unidadesnegocio[0]}`, this.getToken())

        if (unidade.unin_tipo === 'UNIDADE') {
          this.setState({
            habilitaQtdAlunos: true
          })
        } else {
          this.setState({
            habilitaQtdAlunos: false
          })
        }
      } catch (error) {
        console.log(error)
        this.setState({
          habilitaQtdAlunos: false
        })
      }
    } else {
      this.setState({
        habilitaQtdAlunos: false
      })
    }

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'aulas')[0]
    let permissoesHorario = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'horario_aula_fixas')[0]

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    if (permissoesHorario) {
      if (permissoesHorario.perm_inserir) {
        acoesTabela.push('horario')
      }
    }

    this.setState({
      acoesTabela,
      permissoes,
      permissoesHorario
    })

    this.setState({
      filtro: {
        aula_nome: '',
        bok_nome: '',
        aula_tipo: '',
        aula_tipo_horario: ''
      }
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/aula`, this.getToken())
      const { data: limiteAulas } = await axios.get(`${process.env.REACT_APP_API_URL}/aula/limiteAulas/ByUnidade/${unid.unidadesnegocio[0]}`, this.getToken())

      const aulas = []

      data.forEach(aula => {
        aulas.push({
          _id: aula.aula_id,
          aula_nome: aula.aula_nome,
          aula_tipo: aula.aula_tipo,
          aula_tipo_horario: aula.aula_tipo_horario,
          aula_duracao: aula.aula_duracao + 'h',
          bok_nome: aula.book[0] ? aula.book[0].bok_nome : "",
          aula_aluno_maximo: aula.aula_aluno_maximo,
          aula_aluno_minimo: aula.aula_aluno_minimo,
          aula_aluno_padrao: aula.aula_aluno_padrao,
          aula_limite_aluno: aula.aula_limite_aluno,
          aula_ordem: aula.aula_ordem,
          limite: aula.aula_aluno_padrao,
          limiteUnidade: limiteAulas.filter(param => param.aula.aula_id === aula.aula_id)[0] ? limiteAulas.filter(param => param.aula.aula_id === aula.aula_id)[0].limauluni_limite : null,
          ativo: aula.aula_ativo,
        })
      })

      this.setState({
        aulas: {
          list: aulas
        },
        aulasFiltrado: {
          list: aulas
        },
        loading: false
      })

    } catch (error) {
      console.log(error);
      this.setState({
        modalErro: true,
        msgErro: error.response.data.error,
      })
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    // this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const aulas = this.state.aulas.list
    const filtro = this.state.filtro

    const list = aulas.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      aulasFiltrado: {
        list
      },
      filtro
    })
  }

  editarQtdAlunos(aula) {
    this.setState({
      aula: {...aula},
      modalTrocaQtdAlunos: true
    })
  }

  handleCloseTrocaQtdAlunos() {
    this.setState({
      modalTrocaQtdAlunos: !this.state.modalTrocaQtdAlunos,
      errorMinMax: false
    })
  }

  updateQtd(event) {
    const { aula } = this.state
    aula.limiteUnidade = event.target.value
    this.setState({ aula })
  }

  async salvarQtdAlunos() {
    const { aula } = this.state

    if (aula.aula_aluno_padrao !== aula.limiteUnidade) {
      if (aula.limiteUnidade < aula.aula_aluno_minimo || aula.limiteUnidade > aula.aula_aluno_maximo) {
        this.setState({
          errorMinMax: true
        })
      } else {
        try {

          const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

          const unid = {
            unidadesnegocio: acesso_atual.map(acesso => {
              return acesso.unin_id
            })
          }

          const dados = {
            limauluni_limite: aula.limiteUnidade,
            limauluni_aula_id: aula._id,
            limauluni_unin_id: unid.unidadesnegocio[0]
          }

          await axios.post(`${process.env.REACT_APP_API_URL}/aula/createQtdAlunos`, dados, this.getToken())

          this.setState({
            errorMinMax: false
          })

          window.location.reload()

        } catch ({ response }) {
          if (response.status === 400 || response.status === 500) {
            this.handleOpenDialog({
              titulo: 'Ops...',
              descricao: response.data.message
            })
            return
          }

          this.handleOpenDialog({})
        }
      }
    } else {
      this.setState({
        modalTrocaQtdAlunos: false
      })
    }
  }

  render() {
    const { filtro, aulasFiltrado, permissoes } = this.state

    console.log(this.state.aula)

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Aulas...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Aulas</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/aula/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Aula
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Aula" variant="outlined" size="small" name="aula_nome" value={filtro.aula_nome} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Book" variant="outlined" size="small" name="bok_nome" value={filtro.bok_nome} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Tipo Aula" variant="outlined" size="small" name="aula_tipo" value={filtro.aula_tipo} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Tipo Horário" variant="outlined" size="small" name="aula_tipo_horario" value={filtro.aula_tipo_horario} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table
                    urlHorario='/aula/horario/'
                    urlUpdate="/aula/cadastro/"
                    urlView="/aula/view/"
                    headCell={this.state.cabecalhoTabela}
                    rows={aulasFiltrado.list}
                    acoes={this.state.acoesTabela}
                    editarQtdAlunos={e => this.editarQtdAlunos(e)}
                    unin_tipo={this.state.unin_tipo}
                    habilitaQtdAlunos={this.state.habilitaQtdAlunos} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalTrocaQtdAlunos
            open={this.state.modalTrocaQtdAlunos}
            aula={this.state.aula}
            handleClose={e => this.handleCloseTrocaQtdAlunos(e)}
            updateQtd={e => this.updateQtd(e)}
            salvar={e => this.salvarQtdAlunos(e)}
            voltar={e => this.handleCloseTrocaQtdAlunos(e)}
            errorMinMax={this.state.errorMinMax} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}