import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import './Unidade.scss'
import Unidade from './View/Unidade'
import LocalEstoque from './View/LocalEstoque'
import Configuracao from './View/Configuracao'
import Contratos from './View/Contratos'
import Documentos from './View/Documentos'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();

  const tabs = {
    unidade: 0,
    estoque: 1,
    configuracao: 2,
    contratos: 3,
    documento: 4,
  }

  console.log(props.params)

  const [value, setValue] = React.useState(tabs[props.params.tab] ? tabs[props.params.tab] : 0);

  const {unidade, unin_tipo, permissoes_contrato, permissoes_documento} = props

  const handleChange = (event, newValue) => {
    const tabsUrl = {
      0: 'unidade',
      1: 'estoque',
      2: 'configuracao',
      3: 'contratos',
      4: 'documento'
    }

    setValue(newValue)
    props.history.push(`/unidade/view/${unidade.unin_id}/${tabsUrl[newValue]}`);
    return;
  };

  return (
    <div className={classes.root} id="view">
      <AppBar position="static">
        <Tabs id="viewAluno" value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Dados da Unidade" {...a11yProps(0)} />
          <Tab label="Dados do Local de Estoque" {...a11yProps(1)} />
          <Tab label="Configuração Unidade" {...a11yProps(2)} />
          {(unin_tipo === 'FRANQUEADOR' && permissoes_contrato.perm_visualizar) &&
            <Tab label="Contratos" {...a11yProps(3)} />
          }
           {(unin_tipo === 'FRANQUEADOR'  && permissoes_documento.perm_visualizar) &&
            <Tab label="Documentos" {...a11yProps(3)} />
          }
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Unidade unidade={unidade} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LocalEstoque unidade={unidade} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Configuracao unidade={unidade} history={props.history} />
      </TabPanel>
      {(unin_tipo === 'FRANQUEADOR' && permissoes_contrato.perm_visualizar) &&
        <TabPanel value={value} index={3}>
          <Contratos unidade={unidade} history={props.history} permissoes_contrato={permissoes_contrato} />
        </TabPanel>
      }
      {(unin_tipo === 'FRANQUEADOR' && permissoes_documento.perm_visualizar) &&
        <TabPanel value={value} index={4}>
          <Documentos unidade={unidade} history={props.history} permissoes_documento={permissoes_documento} />
        </TabPanel>
      }
    </div>
  );
}
