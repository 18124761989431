import React, { Component } from 'react'
import './ConfigAditivo.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import axios from 'axios'
import { Grid, Button } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import moment from 'moment'

const initalState = {
  config_aditivos: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'ccad_descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'ccad_duracao', numeric: false, disablePadding: false, label: 'Dias à Aditivar' },
    { id: 'ccad_criado_em', numeric: false, disablePadding: false, label: 'Data Criação' },
		{ id: 'ccad_gera_fin', numeric: false, disablePadding: false, label: 'Gera Fin.' },
		{ id: 'ccad_ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    nome: ""
  },
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class Curso extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'config-aditivo-curso')[0]

    let acoesTabela = []

    if (permissoes.perm_visualizar) {
      acoesTabela.push('view')
    }

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })


    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/configAditivo`, this.getToken())
      
			this.setState({
				config_aditivos: {
					list: data.map(value => {
						return {
							_id: value.ccad_id,
							ccad_descricao: value.ccad_descricao,
              ccad_duracao: value.ccad_duracao,
              ccad_criado_em: moment(value.ccad_criado_em).format('DD/MM/YYYY HH:mm'),
              ccad_gera_fin: value.ccad_gera_fin,
              ccad_ativo: value.ccad_ativo
						}
					})
				},
				loading: false
			})

    } catch (error) {
      console.log(error);
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { permissoes, config_aditivos } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Configurações...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Configurações de Aditivos</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={3} xs={12}>
                    <Link to="/config_aditivos/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Configuração
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className='mg_top_20'>
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/config_aditivos/cadastro/" urlView="/config_aditivos/view/" headCell={this.state.cabecalhoTabela} rows={config_aditivos.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}