import React, { Component } from 'react'
import './Funcao.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormFuncao from '../../components/forms/FormFuncao'
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios'
import ModalErro from './../../components/modals/Erro'

const initalState = {
  funcao: {
    func_id: "",
    func_descricao: "",
    func_nivel: "",
    car_descricao: "",
    func_idcargo: "",
    dep_descricao: "",
    dep_id: "",
    func_ativo: true
  },
  funcaos: {
    list: []
  },
  departamentos: {
    list: []
  },
  cargos: {
    list: []
  },
  cargosFiltrado: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class CadastroCargo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'funcao')[0]

    this.setState({
      permissoes
    })

    if (params.funcaoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.funcaoId) {
      try {
        const { data: funcao } = await axios.get(`${process.env.REACT_APP_API_URL}/funcoes/${params.funcaoId}`, this.getToken())

        this.setState({
          funcao: {
            func_id: funcao.func_id,
            func_descricao: funcao.func_descricao,
            func_nivel: funcao.func_nivel,
            car_descricao: funcao.cargo.car_descricao,
            func_idcargo: funcao.func_cargo_id,
            dep_descricao: funcao.cargo.departamento.dep_descricao,
            dep_id: funcao.cargo.car_departamento_id,
            func_ativo: funcao.func_ativo
          }
        })


      } catch (error) {
        console.log(error);
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar a função, tente mais tarde"
        })

        this.backPage()
      }

    }
    try {
      const { data: departamentos } = await axios.post(`${process.env.REACT_APP_API_URL}/departamentos/list`, {}, this.getToken())

      const { data: cargos } = await axios.post(`${process.env.REACT_APP_API_URL}/cargos/list`, {}, this.getToken())

      this.setState({
        departamentos: {
          list: departamentos.filter(u => u.dep_ativo === true)
        },
        cargos: {
          list: cargos.filter(u => u.car_ativo === true)
        },
        loading: false
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Departamentos e Cargos, tente mais tarde"
      })

      this.backPage()
    }
  }

  async updateField(event) {
    const funcao = { ...this.state.funcao }

    if (event.target.name === "dep_id") {

      const departamento = this.state.departamentos.list.filter(u => u.dep_id === event.target.value)
      const cargos = this.state.cargos.list.filter(u => u.car_departamento_id === event.target.value)

      this.setState({
        cargosFiltrado: {
          list: cargos
        }
      })
      funcao.dep_descricao = departamento[0].dep_descricao

      funcao.car_descricao = ""
      funcao.func_idcargo = ""
    }

    if (event.target.name === "func_idcargo") {
      const cargo = this.state.cargosFiltrado.list.filter(u => u.car_id === event.target.value)

      funcao.car_descricao = cargo[0].car_descricao
    }

    funcao[event.target.name] = event.target.value
    this.setState({ funcao })

  }

  updateFieldAtivo(event) {
    const funcao = { ...this.state.funcao }
    funcao.func_ativo = !funcao.func_ativo
    this.setState({ funcao })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/funcao");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { funcao } = this.state

    if (!funcao.func_idcargo) return false
    if (!funcao.func_descricao) return false
    if (!funcao.func_nivel) return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const funcao = this.state.funcao

    const dados = {
      func_descricao: funcao.func_descricao,
      func_nivel: funcao.func_nivel,
      func_cargo_id: funcao.func_idcargo,
      func_ativo: funcao.func_ativo,
    }

    try {

      const method = funcao.func_id !== "" ? 'put' : 'post'
      const url = funcao.func_id !== "" ? `/funcoes/${funcao.func_id}` : `/funcoes`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { funcao } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid
            container
            spacing={1}
            direction="row"
            className="borderBottom"
          >
            <Grid item md={12}>
              <h2 className="titulo">Cadastro de Funções</h2>
            </Grid>
          </Grid>
          <FormFuncao cargos={this.state.cargosFiltrado.list} departamentos={this.state.departamentos.list} updateField={e => this.updateField(e)} updateFieldAtivo={e => this.updateFieldAtivo(e)} dados={funcao} />
          <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
            <Grid item md={9}></Grid>
            <Grid item md={3}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
            </Grid>
          </Grid>
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}