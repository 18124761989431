import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormContaFinanceira = ({ dados, updateField, unidade, unidades, updateFieldUnidade, contasBancarias,
  addConta, criarConta,unin_tipo, updateFieldBanco, updateFieldContaBancaria, banco, bancos, contaBancaria, updateFieldContaCorrente, updateFieldAtivo, update, updateFieldSelectConta }) => (
  <React.Fragment>
    {dados.contfin_tipo_conta === 'BANCO' && 
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={6} xs={12} sm={6}>
            <Autocomplete
              onChange={(event, value) => updateFieldBanco(event, value)}
              freeSolo
              options={bancos}
              value={banco}
              getOptionLabel={option => `${option.banco_numero} - ${option.banco_nome}`}
              renderTags={options => 
                {
                  return (
                    options.map(option =>
                      <React.Fragment>
                        <img src={option.logo} style={{width: '40px', height: '40px', marginRight: '5px'}} alt='logo'/> {/*Mock image, attribute in option*/}
                        {`${option.banco_numero} - ${option.banco_nome}`}
                      </React.Fragment>))
            
                }}
                renderOption={option => {
                  return (
                    <React.Fragment>
                      <img src={option.logo} style={{width: '40px', height: '40px', marginRight: '5px'}} alt='logo'/> {/*Mock image, attribute in option*/}
                      {`${option.banco_numero} - ${option.banco_nome}`}
                    </React.Fragment>
                  );
                }}
              renderInput={(params) => (
                <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Banco*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${banco.banco_numero} - ${banco.banco_nome}`} />
              )}
            />
            
            {/* <TextField className="input" label="Banco" variant="outlined" size="small" name="cb_agencia" value={`${contaBancaria.banco.banco_numero} - ${contaBancaria.banco.banco_nome}`} disabled={update} /> */}
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField className="input" label="Núm. Agência" variant="outlined" size="small" name="cb_agencia" value={contaBancaria.cb_agencia} onChange={(e) => updateFieldContaBancaria(e)} />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField className="input" label="Núm. Conta" variant="outlined" size="small" name="cb_numero" value={contaBancaria.cb_numero} onChange={(e) => updateFieldContaBancaria(e)} />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={6} xs={12} sm={6}>
            <TextField className="input" label="Gerente" variant="outlined" name="cb_gerente" value={contaBancaria.cb_gerente} onChange={(e) => updateFieldContaBancaria(e)} />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField className="input" label="Chave Pix" variant="outlined" name="cb_chave_pix" value={contaBancaria.cb_chave_pix} onChange={(e) => updateFieldContaBancaria(e)} />
          </Grid>
        </Grid>
      </React.Fragment>
    }

    {dados.contfin_tipo_conta !== 'BANCO' &&
      <React.Fragment> 
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h5>Apenas para contas do tipo BANCO</h5>
          </Grid>
        </Grid>
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormContaFinanceira