import React, { Component } from 'react'
import './ServicosBancarios.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormServicosBancarios from '../../components/forms/FormServicosBancarios'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  servico_bancario: {
    id: "",
    descricao: "",
    ativo: true
  },
  loadingSalvar: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class CadastroCargo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'servicos-bancario')[0]

    this.setState({
      permissoes
    })

    if (params.servico_bancarioId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.servico_bancarioId) {
      try {

        const { data: servico_bancario } = await axios.get(`${process.env.REACT_APP_API_URL}/servicosBancarios/${params.servico_bancarioId}`, this.getToken())

        this.setState({
          servico_bancario: {
            id: servico_bancario.sb_id,
            descricao: servico_bancario.sb_descricao,
            ativo: servico_bancario.sb_ativo
          },
          loading: false
        })

      } catch ({ response }) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })

        this.backPage()
        return
      }
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/servicos_bancario");
    }, timeout)
    this.setState({
      servico_bancario: initalState.servico_bancario
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateField(event) {
    const servico_bancario = { ...this.state.servico_bancario }
    servico_bancario[event.target.name] = event.target.value

    this.setState({ servico_bancario })
  }

  updateFieldAtivo() {
    const servico_bancario = { ...this.state.servico_bancario }
    servico_bancario.ativo = !servico_bancario.ativo
    this.setState({ servico_bancario })
  }

  validateForm() {
    const { servico_bancario } = this.state

    if (!servico_bancario.descricao) return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      })
      return
    }

    const servico_bancario = this.state.servico_bancario

    const dados = {
      descricao: servico_bancario.descricao,
      ativo: servico_bancario.ativo
    }

    try {

      const method = servico_bancario.id !== "" ? 'put' : 'post'
      const url = servico_bancario.id !== "" ? `/servicosBancarios/${servico_bancario.id}` : `/servicosBancarios`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro realizado com sucesso.'
        }
      })

      this.backPage()

    }catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  render() {
    const { servico_bancario } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h1 className="titulo">Cadastro de Serviços Bancarios</h1>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormServicosBancarios 
                updateField={e => this.updateField(e)} 
                dados={servico_bancario} 
                updateFieldAtivo={e => this.updateFieldAtivo()}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={10} xs={12} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button 
                    fullWidth 
                    color="primary" 
                    variant="contained"  
                    size="small" 
                    startIcon={<SaveIcon />} 
                    onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}