import React, { Component } from 'react'
import './Cargo.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import ModalErro from './../../components/modals/Erro'

import { Grid, Button } from '@material-ui/core'

import FormCargo from '../../components/forms/FormCargo'
import SaveIcon from '@material-ui/icons/Save';

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  cargo: {
    car_id: "",
    car_descricao: "",
    car_departamento_id: "",
    dep_descricao: "",
    car_comissionado: true,
    car_ativo: true
  },
  cargos: {
    list: []
  },
  departamentos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class CadastroCargo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cargo')[0]

    this.setState({
      permissoes
    })

    if (params.cargoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.cargoId) {
      try {
        const { data: cargo } = await axios.get(`${process.env.REACT_APP_API_URL}/cargos/${params.cargoId}`, this.getToken())

        this.setState({
          cargo: {
            car_id: cargo.car_id,
            car_descricao: cargo.car_descricao,
            car_departamento_id: cargo.car_departamento_id,
            dep_descricao: cargo.departamento.dep_descricao,
            car_comissionado: cargo.car_comissionado,
            car_ativo: cargo.car_ativo
          }
        })

      } catch (error) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o cargo, tente mais tarde"
        })

        this.backPage()
      }
    }

    axios.post(`${process.env.REACT_APP_API_URL}/departamentos/list`, {}, this.getToken())
      .then(resp => {

        let departamentosAtivos = []
        let departamentos = []

        departamentosAtivos = resp.data.filter(u => u.dep_ativo === true)

        departamentosAtivos.forEach(departamento => {
          departamentos.push({
            _id: departamento.dep_id,
            dep_descricao: departamento.dep_descricao,
            dep_ativo: departamento.dep_ativo
          })
        })

        this.setState({
          departamentos: {
            list: departamentos
          },
          loading: false
        })
      }).catch(error => {
        console.log(error)
        this.setState({
          modalErro: true,
          msgErro: error.response.data.error,
        })
      })

  }

  updateField(event) {
    const cargo = { ...this.state.cargo }

    if (event.target.name === "car_departamento_id") {
      const departamento = this.state.departamentos.list.filter(u => u._id === parseInt(event.target.value))

      cargo.dep_descricao = departamento[0].dep_descricao
    }

    cargo[event.target.name] = event.target.value
    this.setState({ cargo })
  }

  updateFieldAtivo(event) {
    const cargo = { ...this.state.cargo }
    cargo.car_ativo = !cargo.car_ativo
    this.setState({ cargo })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/cargo");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { cargo } = this.state
    if (!cargo.car_descricao) return false
    if (!cargo.car_departamento_id) return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const cargo = this.state.cargo

    const dados = {
      car_departamento_id: cargo.car_departamento_id,
      car_descricao: cargo.car_descricao,
      car_comissionado: cargo.car_comissionado,
      car_ativo: cargo.car_ativo
    }

    try {

      const method = cargo.car_id !== "" ? 'put' : 'post'
      const url = cargo.car_id !== "" ? `/cargos/${cargo.car_id}` : `/cargos`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  render() {
    const { cargo } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Cargos</h2>
                </Grid>
              </Grid>
              <FormCargo departamentos={this.state.departamentos.list} updateField={e => this.updateField(e)} updateFieldAtivo={e => this.updateFieldAtivo(e)} dados={cargo} />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}