import React from 'react'

import { Grid, TextField } from '@material-ui/core'

const FormComissao = ({ funcoes, updateField, comissoes, funcionarios }) => (
  <React.Fragment>
    <div style={{'marginTop': '30px'}}>
			<Grid container direction="row" spacing={1} style={{'marginBottom': '30px'}}>
				<Grid item md={3} xs={4} sm={4}>
					<h6><b>Função</b></h6>
				</Grid>
				<Grid item md={9} xs={8} sm={8}>
					<h6><b>Colaborador</b></h6>
				</Grid>
			</Grid>

			{funcoes.sort((a, b) => (a.func_nivel > b.func_nivel) ? 1 : ((b.func_nivel > a.func_nivel) ? -1 : 0)).map((funcao, key) => {
				const index = comissoes.findIndex(param => param.funcao_id === funcao.func_id)
				
				return (
					<Grid container direction="row" spacing={1} style={{'marginTop': '15px'}} key={key}>
						<Grid item md={3} xs={4} sm={4}>
							{funcao.func_descricao}
						</Grid>
						<Grid item md={9} xs={8} sm={8}>
							<TextField
								id="standard-select-currency"
								select
								variant="outlined"
								className="input"
								fullWidth
								label="Colaborador*"
								size="small"
								SelectProps={{
									native: true,
								}}
								name={`contcomis_fun_id`}
								value={comissoes[index] ? comissoes[index].contcomis_fun_id : ''}
								onChangeCapture={(e) => updateField(e, funcao.func_id)}
								InputLabelProps={{ shrink: true }}
							>
								<option value="">--Escolha--</option>
								{funcionarios.filter(param => param.car_id === comissoes[index].cargo_id && param.func_nivel >= funcao.func_nivel).map((contrato, keyC) => {
									return (
										<option key={keyC} value={contrato.funcionario.fun_id}>{contrato.funcionario.fisica.pesf_nome}</option>
									)
								})}
							</TextField>
						</Grid>
					</Grid>
				)
			})}
		</div>
  </React.Fragment>
)

export default FormComissao