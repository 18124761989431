import React, { Component } from 'react'
import './Produto.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import TabViews from './TabViews'
import ModalErro from './../../components/modals/Erro'

const initalState = {
  produto: {},
  locaisEstoques: [],
  estoqueProdutos: [],
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class View extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'produto')[0]

    this.setState({
      permissoes
    })

    if (!permissoes.perm_visualizar) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Você não tem permissão para acessa essa tela!"
      })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.produtoId) {
      try {
        const { data: produto } = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/${params.produtoId}`, this.getToken())

        const itensCombo = []

        if (produto.itemCombo) {
          for (let i = 0; i < produto.itemCombo.length; i++) {
            itensCombo.push({
              _id: produto.itemCombo[i].itemcombo_id,
              produtoId: produto.itemCombo[i].itemcombo_idprodutoitem,
              produtoItem: produto.itemCombo[i].produtoitem,
              quantidade: produto.itemCombo[i].itemcombo_quantidade,
              ativo: produto.itemCombo[i].itemcombo_ativo,
              index: i + 1
            })
          }
        }

        this.setState({
          produto: {
            _id: produto.prod_id,
            descricao: produto.prod_descricao,
            ativo: produto.prod_ativo,
            grupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
            idGrupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_id,
            subGrupoProduto: produto.subgrupoproduto.subgrupprod_descricao,
            idSubGrupoProduto: produto.subgrupoproduto.subgrupprod_id,
            unidadeMedida: produto.prod_unidademedida,
            visibilidade: produto.prod_visibilidade,
            idMidia: produto.prod_idmidia,
            itensCombo
          },
          uploadedFiles: [{
            id: produto.midia.mid_id,
            name: produto.midia.mid_nome,
            preview: produto.midia.mid_caminho,
            uploaded: true,
            url: produto.midia.mid_caminho
          }]
        })
      } catch (error) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o produto, tente mais tarde"
        })

        this.backPage()
      }
    }

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    axios.post(`${process.env.REACT_APP_API_URL}/estoqueProduto/byProduto/${params.produtoId}`, dados, this.getToken())
      .then(resp => {

        this.setState({
          estoqueProdutos: resp.data,
          loading: false
        })


      }).catch(erro => {
        console.log(erro)
        this.setState({
          modalErro: true,
          erro
        })
      })

  }

  render() {
    const { produto, uploadedFiles, estoqueProdutos } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <TabViews produto={produto} uploadedFiles={uploadedFiles} estoqueProdutos={estoqueProdutos} />
            </div>
          }

          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}