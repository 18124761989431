import React, { Component } from 'react'
import './ParceirosBancarios.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormParceirosBancarios from '../../components/forms/FormParceirosBancarios'
import FormServicosParceiro from '../../components/forms/FormServicosParceiro'
import FormBancosIsentos from './../../components/forms/FormBancosIsentos'

import SaveIcon from '@material-ui/icons/Save';

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import real from './../../services/real'

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import moment from 'moment'

const initalState = {
  parceiro_bancario: {
    id: "",
    descricao: "",
    parceiro_id: "",
    pessoa_id: "",
    ativo: true,
    parceiro:{
      pessoa_id: '',
      nome: '',
      parceiro_id: ''
    },
    servicos: [],
    bancos_isentos: []
  },
  servico: {
    _id: '',
    valor: '',
    data_inicio: '',
    data_final: '',
    servico_bancario_id: '',
    cobrar_isentos: 'Sim',
    index: ''
  },
  servicos:{
    list: []
  },
  pessoas:{
    list: []
  },
  loadingSalvar: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  bancos: {
    list: []
  },
  banco: {
    id: "",
    banco_id: "",
    nome: "",
    numero: "",
    padraocosif: "",
    logo: "",
    index: ''
  },
  tab: 0
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default class CadastroParceiro extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'servicos-bancario')[0]

    this.setState({
      permissoes
    })

    if (params.servico_bancarioId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    try {
      
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/servicosBancarios`, this.getToken())

      let servicos = data.map(servico => {
        return {
          _id: servico.sb_id,
          descricao: servico.sb_descricao,
          ativo: servico.sb_ativo
        }
      })
      
      this.setState({
        servicos: {
          list: servicos
        }
      })
    }catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })

      this.backPage()
      return
    }

    try {
      const { data: bancos } = await axios.get(`${process.env.REACT_APP_API_URL}/bancos`, this.getToken())

      this.setState({
        bancos: {
          list: bancos.filter(param => param.banco_ativo).map(banco => {
            return {
              id: banco.banco_id,
              nome: banco.banco_nome,
              numero: banco.banco_numero,
              banco_padraocosif: banco.banco_padraocosif,
            }
          })
        }
      })

    }catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })

      this.backPage()
      return
    }

    if (params.parceiro_bancarioId) {
      try {

        const { data: parceiro_bancario } = await axios.get(`${process.env.REACT_APP_API_URL}/parceiroBancario/${params.parceiro_bancarioId}`, this.getToken())

        this.setState({
          parceiro_bancario: {
            id: parceiro_bancario.pb_id,
            descricao: parceiro_bancario.pb_descricao,
            parceiro_id: parceiro_bancario.pb_parceiro_id,
            ativo: parceiro_bancario.pb_ativo,
            servicos: parceiro_bancario.servicos_parceiro.map((servico, key) => {
              return {
                _id: servico.sp_id,
                valor: real(servico.sp_valor),
                data_inicio: servico.sp_data_inicio,
                data_final: servico.sp_data_final,
                servico_bancario_id: servico.sp_servico_bancario_id,
                servico_descricao: servico.servico_bancario.sb_descricao,
                cobrar_isentos: servico.sp_cobrar_isentos ? 'Sim' : 'Não',
                index: key
              }
            }),
            bancos_isentos: parceiro_bancario.bancos_isentos.map((item, key) => {
              return {
                id: item.bi_id,
                banco_id: item.banco.banco_id,
                nome: item.banco.banco_nome,
                numero: item.banco.banco_numero,
                padraocosif: item.banco.banco_padraocosif,
                logo: item.banco.banco_logo,
                index: key
              }
            })
          },
          loading: false
        })

      }catch (error) {
        console.log(error)
        // this.handleOpenDialog({
        //   titulo: 'Ops...',
        //   descricao: response.data.message
        // })
  
        // this.backPage()
        // return
      }
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/parceiros_bancario");
    }, timeout)
    this.setState({
      servico_bancario: initalState.servico_bancario
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  updateField(event) {
    const parceiro_bancario = { ...this.state.parceiro_bancario }
    parceiro_bancario[event.target.name] = event.target.value
    this.setState({ parceiro_bancario })
  }

  updateFieldAtivo() {
    const parceiro_bancario = { ...this.state.parceiro_bancario }
    parceiro_bancario.ativo = !parceiro_bancario.ativo
    this.setState({ parceiro_bancario })
  }

  updateFieldParceiro(event){
    const parceiro_bancario = { ...this.state.parceiro_bancario }
    parceiro_bancario.parceiro[event.target.name] = event.target.value

    if(event.target.name === 'pessoa_id'){
      const pessoaSelecionada = this.state.pessoas.list.filter(param => param.pessoa_id === parseInt(event.target.value))[0]
    
      parceiro_bancario.parceiro.nome = pessoaSelecionada.nome
    }

    this.setState({parceiro_bancario})
  }

  updateFieldServico(event){
    const servico = { ...this.state.servico }
    servico[event.target.name] = event.target.value
    this.setState({ servico })
  }

  validateFormServico(){
    const { servico } = this.state

    if (!servico.servico_bancario_id) return false
    if (!servico.valor) return false
    if (!servico.data_inicio) return false

    return true
  }

  addServico(){
    if (!this.validateFormServico()) {
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios do serviço (*).'
        }
      })
      return
    }

    const {parceiro_bancario, servico, servicos} = this.state

    const itens = parceiro_bancario.servicos

    const index = itens.findIndex(param => {
     if(param.servico_bancario_id === servico.servico_bancario_id && servico.index !== param.index){
      if(moment(servico.data_inicio).isAfter(param.data_inicio) && moment(servico.data_inicio).isBefore(param.data_final)){
        return true
      }else if (moment(servico.data_final).isAfter(param.data_inicio) && moment(servico.data_final).isBefore(param.data_final)){
        return true
      }else if (moment(servico.data_inicio).isBefore(param.data_inicio) && moment(servico.data_final).isAfter(param.data_final)){
        return true
      }else{
        return false
      }
     }else{
       return false
     }

    })

    if(index !== -1){
      this.setState({
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Esse registro sobrepõe a data de outro serviço!'
        }
      })
      return
    }

    if(servico.index === ''){
      itens.push({
        ...servico,
        servico_descricao: servicos.list.filter(param => param._id === parseInt(servico.servico_bancario_id))[0].descricao,
        index: itens.length
      })
    }else{
      itens[servico.index] = {
        ...servico,
        servico_descricao: servicos.list.filter(param => param._id === parseInt(servico.servico_bancario_id))[0].descricao,
        index: servico.index
      }
    }

    parceiro_bancario.servicos = itens

    this.setState({
      parceiro_bancario,
      servico: {
        _id: '',
        valor: '',
        data_inicio: '',
        data_final: '',
        servico_bancario_id: '',
        cobrar_isentos: 'Sim',
        index: ''
      },
      alteraServico: false
    })

  }

  editarItemServico(index){
    const {parceiro_bancario} = this.state

    this.setState({
      servico: parceiro_bancario.servicos[index],
      alteraServico: true
    })
  }
  
  removerItemServico(index){
    const parceiro_bancario = this.state.parceiro_bancario

    let itens = parceiro_bancario.servicos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        _id: itens[i]._id,
        valor: itens[i].valor,
        data_inicio: itens[i].data_inicio,
        data_final: itens[i].data_final,
        servico_bancario_id: itens[i].servico_bancario_id,
        servico_descricao: itens[i].servico_descricao,
        index: i
      })
    }

    itens = aux

    parceiro_bancario.servicos = itens

    this.setState({ parceiro_bancario })
  }

  updateFieldBanco(event, value) {
    if (!value) return false
    
    this.setState({
      banco: {
        id: "",
        banco_id: value.id,
        nome: value.nome,
        numero: value.numero,
        padraocosif: value.padraocosif,
        logo: value.logo,
        index: ''
      }
    })
  }

  addBanco(){
    const {banco, parceiro_bancario} = this.state

    const itens = parceiro_bancario.bancos_isentos

    itens.push({
      ...banco,
      index: itens.length
    })

    parceiro_bancario.bancos_isentos = itens

    this.setState({
      parceiro_bancario,
      banco: {
        id: "",
        banco_id: "",
        nome: "",
        numero: "",
        padraocosif: "",
        logo: "",
        index: ""
      }
    })
  }

  removerItemBanco(index){
    const parceiro_bancario = this.state.parceiro_bancario

    let itens = parceiro_bancario.bancos_isentos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        id: itens[i].id,
        banco_id: itens[i].banco_id,
        nome: itens[i].nome,
        numero: itens[i].numero,
        padraocosif: itens[i].padraocosif,
        ativo: itens[i].ativo,
        logo: itens[i].logo,
        index: i
      })
    }

    itens = aux

    parceiro_bancario.bancos_isentos = itens

    this.setState({ parceiro_bancario })
  }

  updateFieldAtivoBanco(){
    const banco = { ...this.state.banco }
    banco.ativo = !banco.ativo
    this.setState({ banco })
  }

  validateForm() {
    const { parceiro_bancario } = this.state

    if (!parceiro_bancario.descricao) return false

    return true
  }

  async salvar() {

    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      this.setState({ loadingSalvar: false })
      return
    }

    const parceiro_bancario = this.state.parceiro_bancario

    const dados = {
      descricao: parceiro_bancario.descricao,
      ativo: parceiro_bancario.ativo,
      parceiro_id: parceiro_bancario.parceiro_id,
      servicos: parceiro_bancario.servicos.map(servico => {
        return {
          id: servico._id,
          valor: parseFloat(servico.valor.replace(".", "").replace(",", ".")),
          data_inicio: servico.data_inicio,
          data_final: servico.data_final,
          servico_bancario_id: servico.servico_bancario_id,
          cobrar_isentos: servico.cobrar_isentos === 'Sim' ? true : false
        }
      }),
      bancos_isentos: parceiro_bancario.bancos_isentos.map(banco => {
        return {
          id: banco.id,
          banco_id: banco.banco_id
        }
      })
    }
    
    if(dados.servicos.length > 0){
      for (let i = 0; i < dados.servicos.length; i++) {
        if(dados.servicos[i].id === '' || !dados.servicos[i].id){
          delete dados.servicos[i].id
        }
        
        if(dados.servicos[i].data_final === '' || !dados.servicos[i].data_final){
          delete dados.servicos[i].data_final
        }
      }
    }else{
      delete dados.servicos
    }

    if(dados.bancos_isentos.length === 0){
      delete dados.bancos_isentos
    }else{
      for (let i = 0; i < dados.bancos_isentos.length; i++) {
        if(dados.bancos_isentos[i].id === '' || !dados.bancos_isentos[i].id){
          delete dados.bancos_isentos[i].id
        }
        
      }
    }

    try {

      const method = parceiro_bancario.id !== "" ? 'put' : 'post'
      const url = parceiro_bancario.id !== "" ? `/parceiroBancario/${parceiro_bancario.id}` : `/parceiroBancario`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())
      
      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })

      this.backPage()

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

  }

  render() {
    const { parceiro_bancario, tab } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h1 className="titulo">Cadastro de Parceiros Bancários</h1>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <AppBar position="static" className="mg_top_20">
                <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                  <Tab label="Dados do Parceiro" {...a11yProps(0)} />
                  <Tab label="Serviços do Parceiro" {...a11yProps(1)} />
                  <Tab label="Bancos Isentos" {...a11yProps(1)} />
                </Tabs>
              </AppBar>

              <TabPanel value={tab} index={0}>
                <FormParceirosBancarios 
                  updateField={e => this.updateField(e)} 
                  dados={parceiro_bancario} 
                  updateFieldAtivo={e => this.updateFieldAtivo()}
                />
              </TabPanel>

              <TabPanel value={tab} index={1}>
                <FormServicosParceiro 
                  dados={parceiro_bancario} 
                  servicos={this.state.servicos.list}
                  servico={this.state.servico}
                  updateFieldServico={e => this.updateFieldServico(e)}
                  addServico={e => this.addServico(e)}
                  editarItemServico={e => this.editarItemServico(e)}
                  alteraServico={this.state.alteraServico}
                  removerItemServico={e => this.removerItemServico(e)}
                />
              </TabPanel>

              <TabPanel value={tab} index={2}>
                <FormBancosIsentos
                  dados={parceiro_bancario} 
                  bancos={this.state.bancos.list}
                  banco={this.state.banco}
                  updateFieldBanco={(e, value) => this.updateFieldBanco(e, value)}
                  addBanco={e => this.addBanco(e)}
                  removerItemBanco={e => this.removerItemBanco(e)}
                  updateFieldAtivoBanco={e => this.updateFieldAtivoBanco(e)}
                />
              </TabPanel>

              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={10} xs={12} sm={6}></Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button 
                    fullWidth 
                    color="primary" 
                    variant="contained"  
                    size="small" 
                    startIcon={<SaveIcon />} 
                    onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}