import React, { Component } from 'react'
import './Franqueado.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  franqueado: {

  },
  franqueados: {
    list: []
  },
  franqueadosFiltrado: {
    list: []
  },
  loading: true,
  cabecalho: [
    { id: 'franqueado', numeric: false, disablePadding: true, label: 'Franqueado', edit: true, link: "/franqueado/update/", tooltip: "Editar Franqueado" },
    { id: 'responsavel', numeric: false, disablePadding: false, label: 'Responsavel', edit: true, link: "/franqueado/updateResponsavel/", tooltip: "Editar Responsavel" },
    { id: 'cidade', numeric: false, disablePadding: false, label: 'Cidade', edit: false, link: "" },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo Pessoa', edit: false, link: "" },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo', edit: false, link: "" }
  ],
  acoesTabela: ['view'],
  filtro: {
    responsavel: "",
    franqueado: "",
    cidade: "",
    tipo: '',
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

export default class Franqueados extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    this.setState({
      permissoes: perfil.permissoes
    })

    try {

      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/franqueados`, this.getToken())

      const franqueados = []

      resp.data.forEach(franqueado => {
        const enderecoPrincipal = franqueado.pessoa.enderecos.filter(u => u.end_principal)
        if (franqueado.pessoa.pes_tipo === "FISICA") {
          franqueados.push({
            _id: franqueado.unin_id,
            franqueado: franqueado.pessoa.fisica.pesf_nome,
            responsavel: franqueado.responsavel.pesf_nome,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: franqueado.pessoa.pes_tipo,
            ativo: franqueado.unin_status
          })
        } else {
          franqueados.push({
            _id: franqueado.unin_id,
            franqueado: franqueado.pessoa.juridica.pesj_nomefantasia,
            responsavel: franqueado.responsavel.pesf_nome,
            cidade: `${enderecoPrincipal[0].cep.bairro.cidade.nome} - ${enderecoPrincipal[0].cep.bairro.cidade.uf.uf}`,
            tipo: franqueado.pessoa.pes_tipo,
            ativo: franqueado.unin_status
          })
        }
      });

      this.setState({
        franqueados: {
          list: franqueados,
        },
        franqueadosFiltrado: {
          list: franqueados
        },
        loading: false
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateFiltro(event) {
    this.setState({ term: event.target.value })
    const franqueados = this.state.franqueados.list
    const filtro = this.state.filtro

    const list = franqueados.filter(searching(event.target.value, event.target.name))

    filtro[event.target.name] = event.target.value

    this.setState({
      franqueadosFiltrado: {
        list
      },
      filtro
    })
  }

  render() {
    const { filtro, franqueadosFiltrado } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Franqueados...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Franqueados</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                {/* <Button color="primary" variant="contained" className="btn_search" startIcon={<SearchIcon />} disabled></Button> */}
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Franqueado" variant="outlined" size="small" name="franqueado" value={filtro.franqueado} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Responsavel" variant="outlined" size="small" name="responsavel" value={filtro.responsavel} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label="Cidade" variant="outlined" size="small" name="cidade" value={filtro.cidade} onChange={(e) => this.updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Tipo Pessoa"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tipo"
                    value={filtro.tipo}
                    onChangeCapture={(e) => this.updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">Pesquisar</option>
                    <option value="FISICA">
                      Pessoa Física
											</option>
                    <option value="JURIDICA">
                      Pessoa Júridica
											</option>
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <Table urlView="/franqueado/view/" headCell={this.state.cabecalho} rows={franqueadosFiltrado.list} acoes={this.state.acoesTabela} />
                  {/* <SimpleTabs urlView="/franqueado/view/" headCellPessoaFisica={this.state.cabecalhoTabelaPessoaFisica} headCellPessoaJuridica={this.state.cabecalhoTabelaPessoaJuridica} rowsFisica={this.state.franqueadosFisica.list} rowsJuridica={this.state.franqueadosJuridica.list} /> */}
                </Grid>
              </Grid>
            </div>
          }
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}