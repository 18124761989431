import React, { Component } from 'react'
import './Movimentacao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button } from '@material-ui/core'
import FormMovimentacao from '../../components/forms/FormMovimentacao'
import SaveIcon from '@material-ui/icons/Save'
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import moment from 'moment'
import real from '../../services/real'

const initalState = {
  movimentacoes: [
    {
      movcontfin_data_hora_conta: "",
      movcontfin_descricao: "",
      movcontfin_tipo: "SAQUE",
      movcontfin_conciliado: false,
      movcontfin_valor: "",
      movcontfin_contfin_id: "",
      contfin_conciliavel: "",
      contfin_saldo_atual: "",
      contfin_descricao: ""
    },
    {
      movcontfin_data_hora_conta: "",
      movcontfin_descricao: "",
      movcontfin_tipo: "DEPOSITO",
      movcontfin_conciliado: false,
      movcontfin_valor: "",
      movcontfin_contfin_id: "",
      contfin_conciliavel: "",
      contfin_saldo_atual: "",
      contfin_descricao: ""
    }
  ],
  movcontfin_tipo: "",
  contasFinanceira: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false
}

function converteFloatMoeda(valor) {
  var inteiro = null, decimal = null, c = null, j = null;
  var aux = [];
  valor = "" + valor;
  c = valor.indexOf(".", 0);
  //encontrou o ponto na string
  if (c > 0) {
    //separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
  } else {
    inteiro = valor;
  }

  //pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  //percorre a string acrescentando os pontos
  inteiro = "";
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += aux[c] + '.';
  }
  //retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = "00";
  } else {
    decimal = "" + decimal;
    if (decimal.length === 1) {
      decimal = decimal + "0";
    }
  }


  valor = inteiro + "," + decimal;


  return valor;

}

export default class CadastroMovimentacao extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {

    this.setState({
      movimentacoes: [
        {
          movcontfin_data_hora_conta: "",
          movcontfin_descricao: "",
          movcontfin_tipo: "SAQUE",
          movcontfin_conciliado: false,
          movcontfin_valor: "",
          movcontfin_contfin_id: "",
          contfin_conciliavel: "",
          contfin_saldo_atual: "",
          contfin_descricao: ""
        },
        {
          movcontfin_data_hora_conta: "",
          movcontfin_descricao: "",
          movcontfin_tipo: "DEPOSITO",
          movcontfin_conciliado: false,
          movcontfin_valor: "",
          movcontfin_contfin_id: "",
          contfin_conciliavel: "",
          contfin_saldo_atual: "",
          contfin_descricao: ""
        }
      ],
      movcontfin_tipo: "",
      contasFinanceira: {
        list: []
      }
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        filtro: {
          contfin_tipo_conta: "",
          contfin_descricao: "",
          contfin_ativo: "Sim"
        },
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceiras = data.sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(param => {
        return {
          contfin_id: param.contfin_id,
          contfin_descricao: param.contfin_descricao,
          contfin_ordem: param.contfin_ordem,
          contfin_saldo_atual: 'R$ ' + real(param.contfin_saldo_atual),
          contfin_tipo_conta: param.contfin_tipo_conta,
          contfin_ativo: param.contfin_ativo,
          contfin_conciliavel: param.contfin_conciliavel,
          unin_descricao: param.unin_descricao,
        }
      })

      this.setState({
        contasFinanceira: {
          list: contaFinanceiras
        }
      })

    } catch (error) {
      console.log(error)
    }

    this.setState({
      loading: false
    })
  }

  updateFieldSaque(event) {
    const movimentacoes = { ...this.state.movimentacoes }

    if (event.target.name === 'movcontfin_contfin_id') {
      const conta = this.state.contasFinanceira.list.filter(conta => conta.contfin_id === parseInt(event.target.value))

      movimentacoes[0].contfin_conciliavel = conta[0].contfin_conciliavel
      movimentacoes[0].contfin_saldo_atual = converteFloatMoeda(conta[0].contfin_saldo_atual)
      movimentacoes[0].contfin_descricao = conta[0].contfin_descricao

      if (this.state.movcontfin_tipo === 'TRANSFERENCIA') {
        movimentacoes[0].movcontfin_descricao = `Transferência para a conta: ${movimentacoes[1].contfin_descricao}`
        movimentacoes[1].movcontfin_descricao = `Transferência recebida da conta: ${movimentacoes[0].contfin_descricao} `
      }
    }

    if (this.state.movcontfin_tipo === 'TRANSFERENCIA') {
      if (event.target.name === 'movcontfin_valor') {
        movimentacoes[1].movcontfin_valor = event.target.value
      }
    }

    movimentacoes[0][event.target.name] = event.target.value
    this.setState({ movimentacoes })
  }

  verificaValorSaldo(event) {
    const movimentacoes = { ...this.state.movimentacoes }

    if (movimentacoes[0].contfin_saldo_atual < parseFloat(event.target.value.replace("R$ ", "").replace(".", "").replace(",", "."))) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Valor de saque maior que o saldo atual da conta!."
      })

      movimentacoes[0].movcontfin_valor = ''
      movimentacoes[1].movcontfin_valor = ''

      this.setState({ movimentacoes })
    }
  }

  updateFieldDeposito(event) {
    const movimentacoes = { ...this.state.movimentacoes }

    if (event.target.name === 'movcontfin_contfin_id') {

      const conta = this.state.contasFinanceira.list.filter(conta => conta.contfin_id === parseInt(event.target.value))

      movimentacoes[1].contfin_conciliavel = conta[0].contfin_conciliavel
      movimentacoes[1].contfin_saldo_atual = converteFloatMoeda(conta[0].contfin_saldo_atual)
      movimentacoes[1].contfin_descricao = conta[0].contfin_descricao

      if (this.state.movcontfin_tipo === 'TRANSFERENCIA') {
        movimentacoes[0].movcontfin_descricao = `Transferência para a conta: ${movimentacoes[1].contfin_descricao}`
        movimentacoes[1].movcontfin_descricao = `Transferência recebida da conta: ${movimentacoes[0].contfin_descricao} `
      }

    }

    movimentacoes[1][event.target.name] = event.target.value
    this.setState({ movimentacoes })
  }

  updateFieldConciliadoSaque() {
    const movimentacoes = { ...this.state.movimentacoes }

    movimentacoes[0].movcontfin_conciliado = !movimentacoes[0].movcontfin_conciliado
    this.setState({ movimentacoes })
  }

  updateFieldConciliadoDeposito() {
    const movimentacoes = { ...this.state.movimentacoes }

    movimentacoes[1].movcontfin_conciliado = !movimentacoes[1].movcontfin_conciliado

    this.setState({ movimentacoes })
  }

  updateFieldTipo(event) {
    const movimentacoes = { ...this.state.movimentacoes }

    if (event.target.value === 'TRANSFERENCIA') {
      movimentacoes[0].movcontfin_descricao = `Transferência da conta:  Para a conta:`
      movimentacoes[1].movcontfin_descricao = `Transferência da conta:  Para a conta:`
    }

    this.setState({
      movimentacoes,
      movcontfin_tipo: event.target.value
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/movimentacao_financeira");
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { movimentacoes, movcontfin_tipo } = this.state

    if (!movcontfin_tipo) return false

    if (movcontfin_tipo === 'TRANSFERENCIA') {
      if (!movimentacoes[0].movcontfin_descricao) return false
      if (!movimentacoes[0].movcontfin_valor) return false
      if (!movimentacoes[0].movcontfin_contfin_id) return false

      if (!movimentacoes[1].movcontfin_descricao) return false
      if (!movimentacoes[1].movcontfin_valor) return false
      if (!movimentacoes[1].movcontfin_contfin_id) return false


    } else if (movcontfin_tipo === 'SAQUE') {
      if (!movimentacoes[0].movcontfin_descricao) return false
      if (!movimentacoes[0].movcontfin_valor) return false
      if (!movimentacoes[0].movcontfin_contfin_id) return false

    } else if (movcontfin_tipo === 'DEPOSITO') {
      if (!movimentacoes[1].movcontfin_descricao) return false
      if (!movimentacoes[1].movcontfin_valor) return false
      if (!movimentacoes[1].movcontfin_contfin_id) return false

    }

    return true
  }

  async salvar() {
    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    try {
      const { movimentacoes, movcontfin_tipo } = this.state

      const usuario = JSON.parse(localStorage.getItem('usuario'))

      if (movcontfin_tipo === 'TRANSFERENCIA') {
        movimentacoes[1].movcontfin_data_hora_conta = moment(movimentacoes[0].movcontfin_data_hora_conta).format('YYYY-MM-DD') + ' 00:01:00'
        movimentacoes[1].movcontfin_valor = parseFloat(movimentacoes[0].movcontfin_valor.replace("R$ ", "").replace(".", "").replace(",", "."))
        movimentacoes[1].movcontfin_usu_criador_id = usuario.usu_id
        movimentacoes[1].movcontfin_contfin_id = parseInt(movimentacoes[1].movcontfin_contfin_id) 

        if(!movimentacoes[1].contfin_conciliavel){
          movimentacoes[1].movcontfin_conciliado = true
          movimentacoes[1].movcontfin_usu_baixa_id = usuario.usu_id
        }

        delete movimentacoes[1].contfin_conciliavel
        delete movimentacoes[1].contfin_saldo_atual
        delete movimentacoes[1].contfin_descricao

        const data = { ...movimentacoes[1] }
        
        data.transferencia = true
        
        await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira`, data, this.getToken())

        movimentacoes[0].movcontfin_data_hora_conta = moment(movimentacoes[0].movcontfin_data_hora_conta).format('YYYY-MM-DD') + ' 00:01:00'
        movimentacoes[0].movcontfin_valor = parseFloat(movimentacoes[0].movcontfin_valor.replace("R$ ", "").replace(".", "").replace(",", "."))
        movimentacoes[0].movcontfin_usu_criador_id = usuario.usu_id
        movimentacoes[0].movcontfin_contfin_id = parseInt(movimentacoes[0].movcontfin_contfin_id) 

        if(!movimentacoes[0].contfin_conciliavel){
          movimentacoes[0].movcontfin_conciliado = true
          movimentacoes[0].movcontfin_usu_baixa_id = usuario.usu_id
        }

        delete movimentacoes[0].contfin_conciliavel
        delete movimentacoes[0].contfin_saldo_atual
        delete movimentacoes[0].contfin_descricao

        await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira`, movimentacoes[0], this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })
        this.backPage()
      } else if (movcontfin_tipo === 'SAQUE') {

        movimentacoes[0].movcontfin_data_hora_conta = moment(movimentacoes[0].movcontfin_data_hora_conta).format('YYYY-MM-DD') + ' 00:01:00'
        movimentacoes[0].movcontfin_valor = parseFloat(movimentacoes[0].movcontfin_valor.replace("R$ ", "").replace(".", "").replace(",", "."))
        movimentacoes[0].movcontfin_usu_criador_id = usuario.usu_id
        movimentacoes[0].movcontfin_contfin_id = parseInt(movimentacoes[0].movcontfin_contfin_id) 
        
        if(!movimentacoes[0].contfin_conciliavel){
          movimentacoes[0].movcontfin_conciliado = true
          movimentacoes[0].movcontfin_usu_baixa_id = usuario.usu_id
        }

        delete movimentacoes[0].contfin_conciliavel
        delete movimentacoes[0].contfin_saldo_atual
        delete movimentacoes[0].contfin_descricao

        await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira`, movimentacoes[0], this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })
        this.backPage()
      } else if (movcontfin_tipo === 'DEPOSITO') {

        movimentacoes[1].movcontfin_data_hora_conta = moment(movimentacoes[1].movcontfin_data_hora_conta).format('YYYY-MM-DD') + ' 00:01:00'
        movimentacoes[1].movcontfin_valor = parseFloat(movimentacoes[1].movcontfin_valor.replace("R$ ", "").replace(".", "").replace(",", "."))
        movimentacoes[1].movcontfin_usu_criador_id = usuario.usu_id
        movimentacoes[1].movcontfin_contfin_id = parseInt(movimentacoes[1].movcontfin_contfin_id) 

        if(!movimentacoes[1].contfin_conciliavel){
          movimentacoes[1].movcontfin_conciliado = true
          movimentacoes[1].movcontfin_usu_baixa_id = usuario.usu_id
        }
       
        delete movimentacoes[1].contfin_conciliavel
        delete movimentacoes[1].contfin_saldo_atual
        delete movimentacoes[1].contfin_descricao

        await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira`, movimentacoes[1], this.getToken())

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })
        this.backPage()
      }

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  render() {
    const { movimentacoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Movimentação Financeira</h2>
                </Grid>
              </Grid>
              <FormMovimentacao
                updateFieldDeposito={e => this.updateFieldDeposito(e)}
                verificaValorSaldo={e => this.verificaValorSaldo(e)}
                updateFieldSaque={e => this.updateFieldSaque(e)}
                updateFieldTipo={e => this.updateFieldTipo(e)}
                updateFieldConciliadoSaque={e => this.updateFieldConciliadoSaque(e)}
                updateFieldConciliadoDeposito={e => this.updateFieldConciliadoDeposito(e)}
                dados={movimentacoes}
                movcontfin_tipo={this.state.movcontfin_tipo}
                contasFinanceira={this.state.contasFinanceira.list}
              />

              <Grid container direction="row" alignItems="flex-end" className="mg_top_20">
                <Grid item md={9} xs={12} sm={8}></Grid>
                <Grid item md={3} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}