import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button, InputLabel, OutlinedInput, FormControl } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

function getModalStyle() {
  return {
  
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '96%',
    marginLeft: '2%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dados = props.dados

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Configuração de Envio de Email</h5>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={1}>
            <Grid item md={3} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Email Remetente*" 
                variant="outlined" 
                name="email"
                value={dados.email} 
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={3}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={props.showPassword ? 'text' : 'password'}
                  value={dados.senha}
                  onChange={(e) => props.updateField(e)}
                  className="input"
                  size="small"
                  name="senha"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => props.handleClickShowPassword(e)}
                        edge="end"
                      >
                        {props.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Servidor*" 
                variant="outlined"
                name="servidor"
                value={dados.servidor} 
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Porta*" 
                variant="outlined"
                name="porta"
                value={dados.porta} 
                onChange={(e) => props.updateField(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={props.loadingSalvar}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={ props.loadingSalvar ? true : (dados.motivoInativar === "" ? true : false)}>Salvar</Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
