import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Grid, TextField, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

function getModalStyle() {
  return {
  
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    marginLeft: '10%',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "auto",
    listStyle: "none",
    maxHeight: "90%",
    '&::-webkit-scrollbar': {
      width: '0.7em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,149,237, .5)',
      outline: '1px solid slategrey'
    }
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
					<Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Aulas Agendadas</h5>
            </Grid>
          </Grid>
					<br />
					<Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <p>Esse professor possui aulas marcadas, por favor trocar os professores!</p>
            </Grid>
          </Grid>
					<Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <table className="lista sempadding borderTable">
                <thead>
                  <tr className="titulos acompanha">
                    <th style={{backgroundColor: '#2c4e82', color: '#FFF', padding: '10px 10px', border: '1px solid #FFF'}}>Data da Aula</th>
                    <th style={{backgroundColor: '#2c4e82', color: '#FFF', padding: '10px 10px', border: '1px solid #FFF'}}>Aula</th>
                    <th style={{backgroundColor: '#2c4e82', color: '#FFF', padding: '10px 10px', border: '1px solid #FFF'}}>Professor</th>
                  </tr>
                </thead>
                <tbody>
                  {props.aulasAgendadas.map(agenda => {
                    return (
                      <tr key={agenda._id}>
                          <td style={{fontSize: '18px', padding: '20px 10px'}}>{agenda.ageaula_data_hora}</td>
                          <td style={{fontSize: '18px', padding: '20px 10px'}}>{agenda.aula_nome}</td>
                          <td>
                          <TextField
                            style={{ marginTop: '8px', marginBottom: '8px' }}
                            id="standard-select-currency"
                            select
                            variant="outlined"
                            className="input"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="ageaula_professor_id"
                            value={agenda.ageaula_professor_id}
                            onClick={e => props.getProfDisp(agenda.ageaula_aula_id, agenda.ageaula_data_hora_sem_form, agenda.ageaula_unin_id, agenda)}
                            onChangeCapture={(e) => props.updateFieldProfessor(e, agenda)}
                            onBlur={e => props.limpaProfessor(e)}
                            InputLabelProps={{ shrink: true }}
                          >
                            <option value={agenda.ageaula_professor_id === "" ? "" : agenda.ageaula_professor_id}> {agenda.ageaula_professor_id === "" ? "Sem Professor" : agenda.prof_nome} </option>
                            {props.rowProfessores}
                          </TextField>
                          </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Grid>
          </Grid>
					<br />
					<Grid container direction="row" spacing={1}>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.salvarTrocaProfessores()} >Remarcar</Button>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.handleClose()} >Voltar</Button>
            </Grid>
          </Grid>
          <br />
        </div>
      </Modal>
    </div>
  );
}
