import React, { Component } from 'react'
import './../Produto.scss'
import './../../../main/ultil.scss'

import Main from '../../../components/templates/Main'
import Nav from '../../../components/templates/Nav'
import Footer from '../../../components/templates/Footer'

import FormSubGrupoProdutos from '../../../components/forms/FormSubGrupoProdutos'

import { Grid, Button } from '@material-ui/core'

import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios'
import ModalErro from './../../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';

const initalState = {
  subGrupoProduto: {
    subgrupprod_id: "",
    subgrupprod_descricao: "",
    subgrupprod_idgrupoproduto: "",
    grupprod_descricao: "",
    subgrupprod_ativo: true
  },
  subGrupoProdutos: {
    list: []
  },
  grupoProdutos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  loadingSalvar: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class CadastroCargo extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'sub-grupo-produtos')[0]

    this.setState({
      permissoes
    })

    if (params.subGrupoProdutoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.subGrupoProdutoId) {

      try {

        const { data: subGrupoProduto } = await axios.get(`${process.env.REACT_APP_API_URL}/subGrupoProdutos/${params.subGrupoProdutoId}`, this.getToken())

        this.setState({
          subGrupoProduto: {
            subgrupprod_id: subGrupoProduto.subgrupprod_id,
            subgrupprod_descricao: subGrupoProduto.subgrupprod_descricao,
            subgrupprod_idgrupoproduto: subGrupoProduto.subgrupprod_idgrupoproduto,
            grupprod_descricao: subGrupoProduto.grupoproduto.grupprod_descricao,
            subgrupprod_ativo: subGrupoProduto.subgrupprod_ativo
          },
          loading: false
        })

      } catch (error) {
        console.log(error);
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o sub grupo de produto, tente mais tarde"
        })

        this.backPage()
      }

    }

    try {

      const { data: grupoProdutos } = await axios.get(`${process.env.REACT_APP_API_URL}/grupoProdutos`, this.getToken())

      this.setState({
        grupoProdutos: {
          list: grupoProdutos.filter(u => u.grupprod_ativo === true)
        },
        loading: false
      })

    } catch (error) {
      console.log(error);
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar os Grupos de Produtos, tente mais tarde"
      })

      this.backPage()
    }

  }

  updateField(event) {
    const subGrupoProduto = { ...this.state.subGrupoProduto }

    if (event.target.name === "subgrupprod_idgrupoproduto") {

      const grupoProduto = this.state.grupoProdutos.list.filter(u => u.grupprod_id === parseInt(event.target.value))

      subGrupoProduto.grupprod_descricao = grupoProduto[0].grupprod_descricao
    }

    subGrupoProduto[event.target.name] = event.target.value
    this.setState({ subGrupoProduto })
  }

  updateFieldAtivo(event) {
    const subGrupoProduto = { ...this.state.subGrupoProduto }
    subGrupoProduto.subgrupprod_ativo = !subGrupoProduto.subgrupprod_ativo
    this.setState({ subGrupoProduto })
  }

  validateForm() {
    const { subGrupoProduto } = this.state

    if (!subGrupoProduto.subgrupprod_descricao) return false
    if (!subGrupoProduto.subgrupprod_idgrupoproduto) return false

    return true
  }

  async salvar() {
    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { subGrupoProduto } = this.state

    const dados = {
      subgrupprod_descricao: subGrupoProduto.subgrupprod_descricao,
      subgrupprod_idgrupoproduto: subGrupoProduto.subgrupprod_idgrupoproduto,
      subgrupprod_ativo: subGrupoProduto.subgrupprod_ativo
    }

    try {

      const method = subGrupoProduto.subgrupprod_id !== "" ? 'put' : 'post'
      const url = subGrupoProduto.subgrupprod_id !== "" ? `/subGrupoProdutos/${subGrupoProduto.subgrupprod_id}` : `/subGrupoProdutos`

      await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/sub_grupo_produtos");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  render() {
    const { subGrupoProduto } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={11}>
                  <h2 className="titulo">Cadastro de Sub-Grupo de Produtos</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <FormSubGrupoProdutos grupoProdutos={this.state.grupoProdutos.list} updateField={e => this.updateField(e)} updateFieldAtivo={e => this.updateFieldAtivo(e)} dados={subGrupoProduto} />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}