import React, { Component } from 'react'
import './Caixa.scss'
import './../../main/ultil.scss'
import axios from 'axios'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button, TextField } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ModalErro from './../../components/modals/Erro'
import real from '../../services/real'

import CurrencyInput from './../../components/mask/CurrencyInput'

const initalState = {
  caixaOriginal: {},
  caixa: {
    cai_id: '',
    cai_fundotroco: ''
  },
  telas: [],
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loadingSalvar: false,
  update: false
}

export default class CadastroPerfil extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const ultimo = JSON.parse(localStorage.getItem('ultimoCaixa'))

    const caixaOriginal = { ...this.state.caixa }

    if (ultimo && ultimo.cai_fechado) {
      caixaOriginal.cai_fundotroco = ultimo['conta.contfin_saldo_atual']
    }

    this.setState({
      ultimoCaixa: ultimo,
      caixa: {
        cai_id: caixaOriginal.cai_id,
        cai_fundotroco: real(caixaOriginal.cai_fundotroco)
      }
    })
  }


  async updateField(event) {
    const caixa = { ...this.state.caixa }
    caixa[event.target.name] = event.target.value
    this.setState({ caixa })
  }

  async salvar() {
    this.setState({ loadingSalvar: true })
    let { caixa } = this.state

    const dados = {
      cai_fundotroco: parseFloat(caixa.cai_fundotroco.replace("R$ ", "").replace(".", "").replace(",", "."))
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/caixa`, dados, this.getToken())

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })

      this.setState({
        caixaOriginal: {},
        caixa: {
          cai_id: '',
          cai_fundotroco: 0
        }
      })

      this.backPage()
    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return this.setState({ loadingSalvar: false })
      }

      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({})
    }
  }

  backPage(timeout = 2000) {
    const self = this
    this.setState({
      caixa: initalState.caixa
    })
    setTimeout(() => {
      const { match: { params } } = self.props
      let redirect = null
      if (params.redirect) redirect = Buffer.from(params.redirect, 'base64').toString('ascii')

      self.props.history.push(redirect ? redirect : '/caixa');
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  render() {
    const { caixa, ultimoCaixa } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <div id="caixa" className="cadastro">
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={10}>
                <h2 className="titulo">Abertura de Caixa</h2>
              </Grid>
              <Grid item md={2}>
                {this.state.loadingSalvar}
                {this.state.loadingSalvar &&
                  <div>
                    <CircularProgress />
                  </div>
                }
              </Grid>
            </Grid>
            {((!ultimoCaixa || !ultimoCaixa.cai_id) || (ultimoCaixa && ultimoCaixa.cai_fechado)) &&
              <div>
                <Grid container direction="row" style={{ 'marginTop': '20px' }}>
                  <Grid item md={12}>
                    O caixa será iniciado com:
                  </Grid>
                  <Grid item md={12} className="mg_top_20">
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        className="input"
                        label="Fundo de Troco *"
                        variant="outlined"
                        size="small"
                        name="cai_fundotroco"
                        value={caixa.cai_fundotroco} onChange={(e) => this.updateField(e)}
                        InputProps={{ inputComponent: CurrencyInput }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={3}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      className="btn_salvar"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={e => this.salvar(e)}
                      disabled={this.state.loadingSalvar}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </div>
            }

            {ultimoCaixa && ultimoCaixa.cai_id && !ultimoCaixa.cai_fechado &&
              <div style={{ 'marginTop': '30px' }}>
                <p>Para criar um novo caixa, é necessário fechar o caixa em aberto de nº {ultimoCaixa.cai_numero}</p>

                <Grid container direction="row" className="mg_top_10">
                  <Grid item md={3}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      className="btn_salvar"
                      size="small"
                      startIcon={<ArrowBackIcon />}
                      onClick={e => this.backPage(0)}
                      disabled={this.state.loadingSalvar}
                    >
                      Voltar
                    </Button>
                  </Grid>
                </Grid>
              </div>
            }


            <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          </div>
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}