import React, { Component } from 'react'
import './ContaFinanceira.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@material-ui/core'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import FormDadosBancario from '../../components/forms/FormDadosBancario'
import FormContaFinanceira from '../../components/forms/FormContaFinanceira'
import FormDadosParceiro from '../../components/forms/FormDadosParceiro'
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment'

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Footer from '../../components/templates/Footer'
import ModalErro from './../../components/modals/Erro'

const initalState = {
  contaFinanceira: {
    contfin_descricao: "",
    contfin_ordem: "",
    contfin_conciliavel: true,
    contfin_saldo_inicial: "",
    contfin_saldo_atual: "",
    contfin_tipo_conta: "",
    contfin_pes_id: "",
    contfin_ativo: true,
    contfin_cofre: "Não",
    contfin_unin_id: '',
    contfin_saldo_nao_conciliado: 0,
    contfin_limite: 0,
    contfin_data_abertura: '',
    conta_corrente: {
      id: "",
      cb_id: ""
    },
    informar_parceiro: false,
    parceiro:{
      id: '',
      api_key: '',
      token: '',
      pessoa_id: '',
      nome: '',
      parceiro_id: '',
      parceiro_descricao: '',
      bol_multa: '0.0%',
      bol_juros: false,
      bol_texto: "",
      bol_qtd_dias_pag: ""
    }
  },
  contaBancaria: {
    cb_agencia: "",
    cb_numero: "",
    cb_gerente: "",
    cb_chave_pix: "",
    banco_id: "",
    index: 1,
    banco: {
      banco_id: "",
      banco_nome: "",
      banco_numero: "",
      banco_padraocosif: ""
    }
  },
  contasFinanceira: {
    list: []
  },
  contasBancarias: {
    list: []
  },
  contasBancariasFiltrada: {
    list: []
  },
  unidades: {
    list: []
  },
  listUnidades: {
    list: []
  },
  unidade: {
    unin_descricao: "",
    unin_id: "",
    unin_tipo: "",
    unin_pessoa_id: ""
  },
  bancos: {
    list: []
  },
  criarConta: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  pessoas: {
    list: []
  },
  tab: 0,
  parceirosBancarios: {
    list:[]
  },
  modalConfirmacaoOpen: false,
  modalConfirmacao:{
    message: ''
  },
  contasPH: {
    list: []
  },
  contas_relacao: {
    list: []
  }
}

function converteFloatMoeda(valor) {
  var inteiro = null, decimal = null, c = null, j = null;
  var aux = [];
  valor = "" + valor;
  c = valor.indexOf(".", 0);
  //encontrou o ponto na string
  if (c > 0) {
    //separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
  } else {
    inteiro = valor;
  }

  //pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  //percorre a string acrescentando os pontos
  inteiro = "";
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += aux[c] + '.';
  }
  //retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = "00";
  } else {
    decimal = "" + decimal;
    if (decimal.length === 1) {
      decimal = decimal + "0";
    }
  }

  valor = inteiro + "," + decimal;

  return valor;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default class CadastroPlanoContas extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async componentDidMount() {
    this.setState({
      contaFinanceira: {
        contfin_descricao: "",
        contfin_ordem: "",
        contfin_conciliavel: true,
        contfin_saldo_inicial: "",
        contfin_saldo_atual: "",
        contfin_tipo_conta: "",
        contfin_pes_id: "",
        contfin_ativo: true,
        contfin_cofre: "Não",
        contfin_unin_id: '',
        contfin_saldo_nao_conciliado: 0,
        contfin_limite: 0,
        contfin_data_abertura: '',
        conta_corrente: {
          id: "",
          cb_id: "",
        },
        informar_parceiro: false,
        parceiro:{
          id: '',
          api_key: '',
          token: '',
          pessoa_id: '',
          nome: '',
          parceiro_id: '',
          parceiro_descricao: '',
          bol_multa: '0.0%',
          bol_juros: false,
          bol_texto: "",
          bol_qtd_dias_pag: ""
        }
      },
      contaBancaria: {
        cb_agencia: "",
        cb_numero: "",
        cb_gerente: "",
        cb_chave_pix: "",
        banco_id: "",
        index: 1,
        banco: {
          banco_id: "",
          banco_nome: "",
          banco_numero: "",
          banco_padraocosif: ""
        }
      },
      update: false
    })

    try {

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
      
      const unidades_acesso = JSON.parse(localStorage.getItem('unidades_acesso'))
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        filtro: {
          contfin_tipo_conta: "",
          contfin_descricao: "",
          contfin_ativo: "Sim"
        },
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unidade_id
        })
      }

      const { data: contasBancarias } = await axios.get(`${process.env.REACT_APP_API_URL}/contasBancaria`, this.getToken())
      const { data: contasFinanceira } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      const contaFinanceira = { ...this.state.contaFinanceira }

      this.setState({
        unin_tipo
      })

      try {

        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }
  
        const { data: unidades } = await axios.post(`${process.env.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, this.getToken())
    
        this.setState({
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              
              if (unidade.unin_tipo === 'FRANQUEADOR') {
                descricao = `Franqueador - ${unidade.pessoa.pes_tipo === "JURIDICA" ? unidade.pessoa.juridica.pesj_razaosocial : unidade.pessoa.fisica.pesf_nome}`
              }if (unidade.unin_tipo === 'FRANQUEADO') {
                descricao = `Franqueado - ${unidade.pessoa.pes_tipo === "JURIDICA" ? unidade.pessoa.juridica.pesj_razaosocial : unidade.pessoa.fisica.pesf_nome}`
              } else if (unidade.unin_tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.unin_numero} - ${unidade.unin_descricao}`
              }
  
              return {
                unin_id: unidade.unin_id,
                unin_descricao: descricao,
                unin_tipo: unidade.unin_tipo,
                unin_pessoa_id: unidade.unin_pessoa_id
              }
            })
          }
        })

        if(unidades.length === 1){
          contaFinanceira.unidade_id = unidades[0].id
          contaFinanceira.pessoa_id = unidades[0].pessoa_id
        }
      } catch (error) {
        console.log(error)
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro",
            descricao: error.response ? error.response.data.message : 'Erro Interno'
          }
        })
  
        this.backPage()
      }

      
      if (unin_tipo === 'UNIDADE') {

        const contasBancariasFiltrada = contasBancarias.filter(param => param.pessoa.pessoa_id === unidades_acesso.unidade.pessoa_id)

        contaFinanceira.pessoa_id = unidades_acesso.unidade.pessoa_id
        contaFinanceira.unidade_id = unidades_acesso.unidade.unidade_id

        this.setState({
          contasBancariasFiltrada: {
            list: contasBancariasFiltrada
          }
        })
      }

      this.setState({
        contaFinanceira,
        contasBancarias: {
          list: contasBancarias,
        },
        contasFinanceira: {
          list: contasFinanceira,
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro",
          descricao: error.response ? error.response.data.message : 'Erro Interno'
        }
      })

      this.backPage()
    }

    try {
      
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/parceiroBancario`, this.getToken())

      let parceirosBancarios = data.map(parceiro => {
        return {
          _id: parceiro.pb_id,
          descricao: parceiro.pb_descricao,
          ativo: parceiro.pb_ativo
        }
      })

      this.setState({
        parceirosBancarios: {
          list: parceirosBancarios
        }
      })
    } catch (error) {
      console.log(error)
      this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro",
            descricao: error.response ? error.response.data.message : 'Erro Interno'
          }
        })
    }

    try {
      const { data: bancos } = await axios.get(`${process.env.REACT_APP_API_URL}/bancos`, this.getToken())

      this.setState({
        bancos: {
          list: bancos
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro",
            descricao: error.response ? error.response.data.message : 'Erro Interno'
          }
        })

      this.backPage()
    }

    const { match: { params } } = this.props;
    if (params.contaFinanceiraId) {
      try {

        const tab = localStorage.getItem('tab_cad_conta_fin')

        if(tab){
          this.setState({
            tab: parseInt(tab)
          })
        }else{
          this.setState({
            tab: 0
          })
        }

        const { data: contaFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/${params.contaFinanceiraId}`, this.getToken())

        let parceiro = null
        let informar_parceiro = null

        if(contaFinanceira.conta_corrente){
          if(contaFinanceira.conta_corrente.parceiro){
            parceiro = {
              id: contaFinanceira.conta_corrente.parceiro.pbu_id,
              api_key: contaFinanceira.conta_corrente.parceiro.pbu_api_key,
              token: contaFinanceira.conta_corrente.parceiro.pbu_token,
              parceiro_id: contaFinanceira.conta_corrente.parceiro.pbu_parceiro_bancario_id,
              parceiro_descricao: contaFinanceira.conta_corrente.parceiro.parceiro_bancario.pb_descricao,
              bol_multa: contaFinanceira.conta_corrente.parceiro.pbu_bol_multa * 100 + ' %',
              bol_juros: contaFinanceira.conta_corrente.parceiro.pbu_bol_juros,
              bol_texto: contaFinanceira.conta_corrente.parceiro.pbu_bol_texto,
              bol_qtd_dias_pag: contaFinanceira.conta_corrente.parceiro.pbu_bol_qtd_dias_pag
            }
  
            this.setState({
              contas_relacao:{
                list: contaFinanceira.conta_corrente.parceiro.contas_relacao
              }
            })
  
            await this.buscaContasPH(contaFinanceira.conta_corrente.parceiro.pbu_id)
  
            informar_parceiro = true
          }else{
            parceiro = {
              id: '',
              api_key: '',
              token: '',
              parceiro_id: '',
              parceiro_descricao: '',
              bol_multa: '0.0%',
              bol_juros: false,
              bol_texto: "",
              bol_qtd_dias_pag: ""
            }
  
            informar_parceiro = false
          }
        }else{
          parceiro = {
            id: '',
            api_key: '',
            token: '',
            parceiro_id: '',
            parceiro_descricao: '',
            bol_multa: '0.0%',
            bol_juros: false,
            bol_texto: "",
            bol_qtd_dias_pag: ""
          }

          informar_parceiro = false
        }
        

        let contaFinanceiraAux = {
          contfin_descricao: contaFinanceira.contfin_descricao,
          contfin_ordem: contaFinanceira.contfin_ordem,
          contfin_conciliavel: contaFinanceira.contfin_conciliavel,
          contfin_saldo_inicial: converteFloatMoeda(contaFinanceira.contfin_saldo_inicial),
          contfin_saldo_atual: converteFloatMoeda(contaFinanceira.contfin_saldo_atual),
          contfin_tipo_conta: contaFinanceira.contfin_tipo_conta,
          contfin_pes_id: contaFinanceira.contfin_pes_id,
          contfin_ativo: contaFinanceira.contfin_ativo,
          contfin_cofre: contaFinanceira.contfin_cofre ? 'Sim' : 'Não',
          contfin_unin_id: contaFinanceira.contfin_unin_id,
          informar_parceiro,
          parceiro
        }

        if (contaFinanceira.conta_corrente) {
          const conta_corrente = {
            id: contaFinanceira.conta_corrente.contfincontcor_id,
            cb_id: contaFinanceira.conta_corrente.contfincontcor_cb_id,
          }

          const contaBancaria = {
            id: contaFinanceira.conta_corrente.contfincontcor_cb_id,
            cb_agencia: contaFinanceira.conta_corrente.conta.cb_agencia,
            cb_numero: contaFinanceira.conta_corrente.conta.cb_numero,
            cb_gerente: contaFinanceira.conta_corrente.conta.cb_gerente,
            cb_chave_pix: contaFinanceira.conta_corrente.conta.cb_chave_pix,
            banco_id: contaFinanceira.conta_corrente.conta.cb_banco_id,
            banco: {
              id: contaFinanceira.conta_corrente.conta.banco.banco_id,
              banco_nome: contaFinanceira.conta_corrente.conta.banco.banco_nome,
              banco_numero: contaFinanceira.conta_corrente.conta.banco.banco_numero,
              banco_padraocosif: contaFinanceira.conta_corrente.conta.banco.banco_padraocosif
            }
          }

          contaFinanceiraAux.conta_corrente = conta_corrente

          this.setState({
            contaBancaria
          })

        }else{
          contaFinanceiraAux.conta_corrente = {
            id: "",
            cb_id: "",
          }
        }

        let unin_descricao = ''
        if(this.state.tipo === 'UNIDADE'){
          unin_descricao = contaFinanceira.pessoa.unidade.unin_descricao
        }else{
          unin_descricao = this.state.unidades.list.filter(param => param.unin_id === contaFinanceira.contfin_unin_id)[0].unin_descricao
        }

        let criarConta = false
        if(contaFinanceira.tipo_conta !== 'BANCO'){
          criarConta = true
        }

        await this.consultaContasBancarias(contaFinanceira.unidade_id)

        this.setState({
          contaFinanceira: contaFinanceiraAux,
          unidade: {
            unin_descricao,
            unin_id: contaFinanceira.pessoa.unidade.unin_id,
            unin_tipo: contaFinanceira.pessoa.unidade.unin_tipo,
            unin_pessoa_id: contaFinanceira.pessoa.unidade.unin_pessoa_id
          },
          update: true,
          criarConta
        })

      } catch (error) {
        console.log(error)
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro",
            descricao: error.response ? error.response.data.message : 'Erro Interno'
          }
        })
  
        this.backPage()
      }
    }

    this.setState({
      loading: false
    })
  }

  async buscaContasPH(parceiro_unidade_id){
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/boleto/listContasPH/${parceiro_unidade_id}`, this.getToken())
    
      if(data.http_code === 201){
        this.setState({
          contasPH:{
            list: data.bank_account_list
          }
        })
      }else{
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro",
            descricao: data.response_message
          }
        })
        
      }
    
    } catch (error) {
      console.log(error)
      this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro",
            descricao: error.response ? error.response.data.message : 'Erro Interno',
          }
        })
    }
  }

  async consultaContasBancarias(unidade_id){
    try {

      const {contas_relacao} = this.state
     
      const dados = {
        filtro: {
          contfin_tipo_conta: "",
          contfin_descricao: "",
          contfin_ativo: "Sim"
        },
        unidadesnegocio: [unidade_id]
      }
      
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/contaFinanceira/list`, dados, this.getToken())

      this.setState({
        contasFinanceira: {
          list: data.filter(param => param.tipo_conta === 'BANCO' && !param.parceiro_id).map((conta, key) => {
            let relacao = contas_relacao.list.filter(param => param.contfin_id === conta.id)[0]

            return {
              id: relacao ? relacao.id : '',
              contfin_id: conta.id,
              logo: conta.logo,
              descricao: conta.descricao,
              bank_account_id: relacao ? relacao.bank_account_id : '',
              index: key
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
          modalErro: true,
          erro: {
            titulo: "Erro",
            descricao: error.response ? error.response.data.message : 'Erro Interno',
          }
        })
    }
  }

  updateField(event) {
    const contaFinanceira = { ...this.state.contaFinanceira }

    if (event.target.name === 'saldo_inicial') {
      if (!this.state.update) {
        contaFinanceira.saldo_atual = event.target.value
      }
    }

    if(event.target.name === 'unidade_id'){
      const unidades = this.state.unidades.list
      const contasBancarias = this.state.contasBancarias.list

      const unidade = unidades.filter(param => param.unidade_id === parseInt(event.target.value))[0]
      
      contaFinanceira.pessoa_id = unidade.pessoa_id

      const contasBancariasFiltrada = contasBancarias.filter(param => param.pessoa.pessoa_id === unidade.pessoa_id)

      this.setState({
        contasBancariasFiltrada: {
          list: contasBancariasFiltrada
        }
      })
    }

    contaFinanceira[event.target.name] = event.target.value
    this.setState({ contaFinanceira })
  }

  updateFieldInformarParceiro(){
    const contaFinanceira = { ...this.state.contaFinanceira }
    contaFinanceira.informar_parceiro = !contaFinanceira.informar_parceiro
    this.setState({contaFinanceira})
  }

  updateFieldParceiro(event){
    const contaFinanceira = { ...this.state.contaFinanceira }
    contaFinanceira.parceiro[event.target.name] = event.target.value

    if(event.target.name === 'parceiro_id'){
      const parceiro_selecionado = this.state.parceirosBancarios.list.filter(param => param._id === parseInt(event.target.value))[0]
    
      contaFinanceira.parceiro.parceiro_descricao = parceiro_selecionado.descricao
    }

    this.setState({contaFinanceira})
  }

  updateFieldCobrarJuros(){
    const contaFinanceira = { ...this.state.contaFinanceira }
    contaFinanceira.parceiro.bol_juros = !contaFinanceira.parceiro.bol_juros
    this.setState({contaFinanceira})
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      self.props.history.push("/conta_financeira");
    }, timeout)
    this.setState({
      contaFinanceira: initalState.contaFinanceira
    })
  }

  updateFieldUnidade(event, value) {
    if (!value) return false

    const contaFinanceira = { ...this.state.contaFinanceira }
    contaFinanceira.contfin_pes_id = value.unin_pessoa_id
    contaFinanceira.contfin_unin_id = value.unin_id

    this.setState({
      contaFinanceira,
      unidade: value
    })
  }

  updateFieldBanco(event, value) {
    if (!value) return false

    const contaBancaria = { ...this.state.contaBancaria }
    contaBancaria.banco = value
    contaBancaria.banco_id = value.banco_id

    this.setState({ contaBancaria })
  }

  addConta() {
    this.setState({
      criarConta: true
    })
  }

  updateFieldContaBancaria(event, index) {
    const contaBancaria = { ...this.state.contaBancaria }
    contaBancaria[event.target.name] = event.target.value
    this.setState({ contaBancaria })
  }

  updateFieldContaCorrente(event) {
    const contaFinanceira = { ...this.state.contaFinanceira }
    contaFinanceira.conta_corrente[event.target.name] = event.target.value
    this.setState({ contaFinanceira })
  }

  updateFieldAtivo() {
    const contaFinanceira = { ...this.state.contaFinanceira }
    contaFinanceira.contfin_ativo = !contaFinanceira.contfin_ativo
    this.setState({ contaFinanceira })
  }

  updateFieldSelectConta(value) {
    const contaFinanceira = { ...this.state.contaFinanceira }
    contaFinanceira.conta_corrente.cb_id = value.cb_id
    this.setState({ contaFinanceira })
  }

  handleChange = (event, newValue) => {
    localStorage.setItem('tab_cad_conta_fin', newValue);

    this.setState({
      tab: newValue
    })
  };

  relacionarContas(){
    const { match: { params } } = this.props;
    if (params.contaFinanceiraId) {

    }else{

      if (!this.validateForm()) {
        this.setState({
          modalErro: true,
          erro: {
            titulo: "Campo Vazio",
            descricao: 'Preencha os campos obrigatorios (*).',
          }
        })
        return
      }

      this.setState({
        modalConfirmacao: {
          mensagem: 'Para relacionar as contas é necessário salvar os dados do parceiro, deseja salvar?',
        },
        modalConfirmacaoOpen: true,
      })
    }
  }

  updateFieldContaPH(event, index){
    const {contasFinanceira} = this.state

    contasFinanceira.list[index].bank_account_id = event.target.value

    this.setState({
      contasFinanceira
    })
  }

  validateForm() {
    const { contaFinanceira, contaBancaria } = this.state

    if (!contaFinanceira.contfin_descricao) return false
    if (!contaFinanceira.contfin_saldo_inicial) return false
    if (!contaFinanceira.contfin_tipo_conta) return false

    if (contaFinanceira.contfin_tipo_conta === 'BANCO') {
     
      if (!contaBancaria.cb_agencia) return false
      if (!contaBancaria.cb_numero) return false
      if (!contaBancaria.banco_id) return false
    }

    if(contaFinanceira.informar_parceiro){
      if (!contaFinanceira.parceiro.api_key) return false
      if (!contaFinanceira.parceiro.parceiro_id) return false
      if (!contaFinanceira.parceiro.token) return false

      if (contaFinanceira.parceiro.bol_multa === '') return false
      if (contaFinanceira.parceiro.bol_qtd_dias_pag === '') return false
    }

    return true
  }

  async salvar(voltar) {
    if (!this.validateForm()) {
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Campo Vazio",
          descricao: 'Preencha os campos obrigatorios (*).',
        }
      })
      return
    }

    try {

      let { contaFinanceira, contaBancaria, contasFinanceira } = this.state

      if(contaFinanceira.informar_parceiro){
        if((contaFinanceira.parceiro.bol_qtd_dias_pag >= 1 && contaFinanceira.parceiro.bol_qtd_dias_pag < 5) || contaFinanceira.parceiro.bol_qtd_dias_pag > 30 || contaFinanceira.parceiro.bol_qtd_dias_pag < 0 ){
          this.setState({
            modalErro: true,
            erro: {
              titulo: "Aviso",
              descricao: 'A Qtd. de dias para pagamento apos o vencimento precisa ser igual a 0 ou entre 5 e 30 dias!',
            }
          })
          return
        }
  
        if(parseFloat(contaFinanceira.parceiro.bol_multa.replace(" %", "").replace(".", "").replace(",", ".")) !== 2 && parseFloat(contaFinanceira.parceiro.bol_multa.replace(" %", "").replace(".", "").replace(",", ".")) !== 1 && parseFloat(contaFinanceira.parceiro.bol_multa.replace(" %", "").replace(".", "").replace(",", ".")) !== 0){
          this.setState({
            modalErro: true,
            erro: {
              titulo: "Aviso",
              descricao: 'O valor da multa do boleto precisa ser igual a 1% ou 2%!'
            }
          })
          return
        }
      }

      let dadosContaFinanceira = null

      if (contaFinanceira.tipo_conta === 'COFRE' || contaFinanceira.tipo_conta === 'CAIXATURNO') {
        dadosContaFinanceira = {
          ordem: parseInt(contaFinanceira.contfin_ordem),
          descricao: contaFinanceira.contfin_descricao,
          conciliavel: contaFinanceira.contfin_tipo_conta === 'BANCO' ? true : false,
          saldo_inicial: parseFloat(contaFinanceira.contfin_saldo_inicial.replace("R$ ", "").replace(".", "").replace(",", ".")),
          saldo_atual: 0,
          tipo_conta: contaFinanceira.contfin_tipo_conta,
          pessoa_id: contaFinanceira.contfin_pes_id,
          ativo: contaFinanceira.contfin_ativo,
          cofre: contaFinanceira.contfin_tipo_conta === 'BANCO' ? false : true,
          unidade_id: contaFinanceira.contfin_unin_id
        }
      } else {
        const dadosContaBancaria = {
          id: contaBancaria.id,
          numero: contaBancaria.cb_numero,
          gerente: contaBancaria.cb_gerente,
          agencia: contaBancaria.cb_agencia,
          banco_id: contaBancaria.banco_id,
          chave_pix: contaBancaria.cb_chave_pix,
          pessoa_id: contaFinanceira.contfin_pes_id,
        }

        let contas_assossiadas = []

        if(contasFinanceira.list.length > 0){
          contas_assossiadas = contasFinanceira.list.filter(param => param.bank_account_id !== '').map(conta => {
            return {
              id: conta.id,
              contfin_id: conta.contfin_id,
              bank_account_id: parseInt(conta.bank_account_id)
            }
          })
        }

        for (let i = 0; i < contas_assossiadas.length; i++) {
          if(contas_assossiadas[i].id === '' || !contas_assossiadas[i].id){
            delete contas_assossiadas[i].id
          }
        }

        if(dadosContaBancaria.id === ''){
          delete dadosContaBancaria.id
        }

        if(dadosContaBancaria.gerente === '' || !dadosContaBancaria.gerente){
          delete dadosContaBancaria.gerente
        }

        if(dadosContaBancaria.chave_pix === '' || !dadosContaBancaria.chave_pix){
          delete dadosContaBancaria.chave_pix
        }

        dadosContaFinanceira = {
          ordem: parseInt(contaFinanceira.contfin_ordem),
          descricao: contaFinanceira.contfin_descricao,
          conciliavel: contaFinanceira.contfin_tipo_conta === 'BANCO' ? true : false,
          saldo_inicial: parseFloat(contaFinanceira.contfin_saldo_inicial.replace("R$ ", "").replace(".", "").replace(",", ".")),
          saldo_atual: 0,
          tipo_conta: contaFinanceira.contfin_tipo_conta,
          pessoa_id: contaFinanceira.contfin_pes_id,
          ativo: contaFinanceira.contfin_ativo,
          cofre: contaFinanceira.contfin_tipo_conta === 'BANCO' ? false : true,
          unidade_id: contaFinanceira.contfin_unin_id,
          conta_corrente: {
            id: contaFinanceira.conta_corrente.id,
            conta_bancaria: dadosContaBancaria,
          },
          parceiro: {
            id: contaFinanceira.parceiro.id,
            api_key: contaFinanceira.parceiro.api_key,
            parceiro_id: contaFinanceira.parceiro.parceiro_id,
            token: contaFinanceira.parceiro.token,
            bol_multa: parseFloat(contaFinanceira.parceiro.bol_multa.replace(" %", "").replace(".", "").replace(",", ".")) / 100,
            bol_juros: contaFinanceira.parceiro.bol_juros,
            bol_texto: contaFinanceira.parceiro.bol_texto,
            bol_qtd_dias_pag: contaFinanceira.parceiro.bol_qtd_dias_pag
          },
          contas_assossiadas
        }

        if(dadosContaFinanceira.contas_assossiadas.length === 0){
          delete dadosContaFinanceira.contas_assossiadas
        }

      }

      if(dadosContaFinanceira.conta_corrente){
        if (dadosContaFinanceira.conta_corrente.id === '' || !dadosContaFinanceira.conta_corrente.id) {
          delete dadosContaFinanceira.conta_corrente.id
        }else{
          dadosContaFinanceira.conta_corrente.id = parseInt(dadosContaFinanceira.conta_corrente.id)
        }  

        if(dadosContaFinanceira.parceiro.bol_texto === '' || !dadosContaFinanceira.parceiro.bol_texto){
          delete dadosContaFinanceira.parceiro.bol_texto
        }
      }

      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

      if(unin_tipo === 'UNIDADE'){
        dadosContaFinanceira.unidade_id = pessoa.fisica.funcionario.contrato.unidadetrabalho.id
      }

      if(dadosContaFinanceira.parceiro){
        if(dadosContaFinanceira.parceiro.id === '' || !dadosContaFinanceira.parceiro.id){
          delete dadosContaFinanceira.parceiro.id
        }
      }

      if(!contaFinanceira.informar_parceiro){
        delete dadosContaFinanceira.parceiro
      }

      if(dadosContaFinanceira.tipo_conta === 'COFRE'){
        delete dadosContaFinanceira.conta_corrente
      }

      const { match: { params } } = this.props;

      const method = params.contaFinanceiraId ? 'put' : 'post'
      const url = params.contaFinanceiraId ? `/contaFinanceira/${params.contaFinanceiraId}` : `/contaFinanceira`

      const {data : respConta} = await axios[method](`${process.env.REACT_APP_API_URL}${url}`, dadosContaFinanceira, this.getToken())

      if(method === 'post'){

        const movimentacao = {
          movcontfin_data_hora_conta:moment(new Date()).format('YYYY-MM-DD') + ' 00:01',
          movcontfin_descricao: 'Saldo Inicial',
          movcontfin_tipo:'DEPOSITO',
          movcontfin_conciliado: true,
          movcontfin_valor: parseFloat(contaFinanceira.contfin_saldo_inicial.replace("R$ ", "").replace(".", "").replace(",", ".")),
          movcontfin_contfin_id: respConta.contfin_id,
          movcontfin_data_conciliacao: new Date(),
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/movimentoContaFinanceira`, movimentacao, this.getToken())
      }

      this.setState({
        contaFinanceira: initalState.contaFinanceira
      })

      this.setState({
        modalErro: true,
        erro: {
          titulo: "",
          descricao: 'Cadastro realizado com sucesso.'
        }
      })

      if(voltar){
        localStorage.removeItem('tab_cad_conta_fin');
        this.backPage()
      }else{
        this.props.history.push(`/conta_financeira/cadastro/${respConta.id}`);
        // window.location.reload()
        this.handleCloseConfirmacao()
        this.componentDidMount()

        return;
      }

    } catch (error) {
      console.log(error)
      this.setState({
        modalErro: true,
        erro: {
          titulo: "Erro",
          descricao: error.response ? error.response.data.message : 'Erro Interno'
        } 
      })
    }

  }

  async continuarSalvamento(value){
    if(value){
      await this.salvar(false)
    }else{
      this.handleCloseConfirmacao()
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  handleCloseConfirmacao(){
    this.setState({
      modalConfirmacao: {
        mensagem: '',
      },
      modalConfirmacaoOpen: false,
    })
  }

  render() {
    const { contaFinanceira, tab } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h1 className="titulo">Cadastro de Contas Bancárias</h1>
                </Grid>
              </Grid>
              <AppBar position="static" className="mg_top_10">
                <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                  <Tab label="Dados da Conta" {...a11yProps(0)} />
                  <Tab label="Dados Bancário" {...a11yProps(1)} />
                  <Tab label="Emissão de Boleto" {...a11yProps(2)} />
                </Tabs>
              </AppBar>

              <TabPanel value={tab} index={0}>
                <FormContaFinanceira
                  dados={contaFinanceira}
                  updateField={e => this.updateField(e)}
                  updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
                  update={this.state.update}
                  unidades={this.state.unidades.list}
                  unidade={this.state.unidade}
                  contasBancarias={this.state.contasBancariasFiltrada.list}
                  addConta={e => this.addConta()}
                  criarConta={this.state.criarConta}
                  updateFieldAtivo={e => this.updateFieldAtivo(e)}
                  updateFieldSelectConta={e => this.updateFieldSelectConta(e)}
                  unin_tipo={this.state.unin_tipo}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <FormDadosBancario
                  dados={contaFinanceira}
                  updateFieldBanco={(event, value) => this.updateFieldBanco(event, value)}
                  updateFieldContaBancaria={e => this.updateFieldContaBancaria(e)}
                  banco={this.state.contaBancaria.banco}
                  bancos={this.state.bancos.list}
                  contaBancaria={this.state.contaBancaria}
                />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <FormDadosParceiro
                  dados={contaFinanceira}
                  parceirosBancarios={this.state.parceirosBancarios.list}
                  updateFieldContaCorrente={e => this.updateFieldContaCorrente(e)}
                  updateFieldInformarParceiro={e => this.updateFieldInformarParceiro(e)}
                  updateFieldParceiro={e => this.updateFieldParceiro(e)}
                  contasPH={this.state.contasPH.list}
                  contasFinanceira={this.state.contasFinanceira.list}
                  updateFieldContaPH={(e, index) => this.updateFieldContaPH(e, index)}
                  updateFieldCobrarJuros={e => this.updateFieldCobrarJuros()}
                />
              </TabPanel>
              <Grid container spacing={1} direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={8} xs={12} sm={8}></Grid>
                <Grid item md={2} xs={12} sm={4}>
                  {tab === 2 && 
                    <React.Fragment>
                      {contaFinanceira.informar_parceiro &&
                        <React.Fragment>
                           {!contaFinanceira.parceiro.id && 
                              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" onClick={e => this.relacionarContas(e)}>Relacionar Contas</Button>
                           }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(true)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <br />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
        <ModalConfirmacao
          open={this.state.modalConfirmacaoOpen} 
          dados={this.state.modalConfirmacao} 
          handleClose={e => this.handleCloseConfirmacao(e)}  
          confirmar={e => this.continuarSalvamento(e)} />
      </div>
    )
  }
}