import React from 'react'

import { Grid, TextField, FormControlLabel, Switch, FormLabel, FormControl, Button, Checkbox } from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormCargo = ({ dados, updateField, updateFieldAtivo, updateFieldAgendamento, books,
  book, avaliacao, update, addItem, updateFieldAvaliacao, updateFieldBook, unidades, updateFieldUnidade, }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={4} xs={12} sm={4}>
        <TextField className="input" label="Nome*" variant="outlined" size="small" name="aula_nome" value={dados.aula_nome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo da Aula*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="aula_tipo"
          value={dados.aula_tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Tipo da Aula </option>
          <option value="EVENTO"> EVENTO </option>
          <option value="COMPLEMENTAR"> COMPLEMENTAR </option>
          <option value="UNIDADE"> UNIDADE </option>
          <option value="PROVA"> PROVA </option>
          <option value="REVIEW"> REVIEW </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo do Horário*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="aula_tipo_horario"
          value={dados.aula_tipo_horario}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Tipo do Horário </option>
          <option value="FIXO"> FIXO </option>
          <option value="LIVRE"> LIVRE </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Duração da Aula*"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="aula_duracao"
          value={dados.aula_duracao}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=""> Selecionar Duração da Aula </option>
          <option value={1}> 01h </option>
          <option value={2}> 02h </option>
          <option value={3}> 03h </option>
          <option value={4}> 04h </option>
          <option value={5}> 05h </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Ordem*" variant="outlined" size="small" name="aula_ordem" value={dados.aula_ordem} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      {(dados.aula_tipo !== '' && dados.aula_tipo !== 'EVENTO') &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <Autocomplete
              onChange={(event, value) => updateFieldBook(event, value)}
              freeSolo
              options={books}
              value={book}
              getOptionLabel={option => option.bok_nome}
              renderInput={(params) => (
                <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Módulo*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={book.bok_nome} />
              )}
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" type="number" label="Soma Mínima*" variant="outlined" size="small" name="aula_soma_min_aval" value={dados.aula_soma_min_aval} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
        </React.Fragment>
      }
      {/* {dados.aula_tipo === 'EVENTO' &&
        <Grid item md={6} xs={12} sm={6}>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={unidades}
            disableCloseOnSelect
            getOptionLabel={(option) => option.descricao}
            limitTags={3}
            value={dados.unidades}
            onChange={((event, value) => updateFieldUnidade(event, value))}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.descricao}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} className="input" variant="outlined" label="Unidades*" size="small" InputLabelProps={{ shrink: true }} />
            )}
          />
        </Grid>
      } */}
      <Grid item md={2} xs={12} sm={4}>
        <TextField type='number' className="input" label="Nivel Inicial*" variant="outlined" size="small" name="aula_nivel_inicial" value={dados.aula_nivel_inicial} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField type='number' className="input" label="Nivel Final*" variant="outlined" size="small" name="aula_nivel_final" value={dados.aula_nivel_final} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={2} xs={12} sm={4}>
        <TextField type='number' className="input" label="Limite de Alunos (padrão)*" variant="outlined" size="small" name="aula_aluno_padrao" value={dados.aula_aluno_padrao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField type='number' className="input" label="Limite Mínimo (unidade)*" variant="outlined" size="small" name="aula_aluno_minimo" value={dados.aula_aluno_minimo} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField type='number' className="input" label="Limite Máximo (unidade)*" variant="outlined" size="small" name="aula_aluno_maximo" value={dados.aula_aluno_maximo} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Agendamento Online?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="aula_agend_online"
                color="primary"
                checked={dados.aula_agend_online}
              />
            }
            size="small"
            label={dados.aula_agend_online ? 'Sim' : 'Não'}
            name="aula_agend_online"
            onChange={(e) => updateFieldAgendamento(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Ativo?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="aula_ativo"
                color="primary"
                checked={dados.aula_ativo}
              />
            }
            size="small"
            label={dados.aula_ativo ? 'Ativo' : 'Inativo'}
            name="aula_ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={7} xs={12} sm={6}>
        <div className="apresentacao">
          <Grid container direction="row" spacing={1}>
            <Grid item md={4} xs={false} sm={false}>
              <div className="cardApresentacao" style={{ backgroundColor: dados.aula_cor }}>
                <p style={{ color: dados.aula_cor_texto }}>{dados.aula_nome}</p>
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <TextField type="color" className="input" label="Cor da Aula*" variant="outlined" size="small" name="aula_cor" value={dados.aula_cor} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <TextField type="color" className="input" label="Cor da Fonte*" variant="outlined" size="small" name="aula_cor_texto" value={dados.aula_cor_texto} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
    <br />
    <hr />
    {dados.aula_tipo !== '' &&
      <React.Fragment>
        {dados.aula_tipo !== 'EVENTO' &&
          <Grid container direction="row" spacing={1} className="mg_top_20">
            <Grid item md={8} xs={12} sm={6}>
              <TextField className="input" label="Critério da Avaliação*" variant="outlined" size="small" name="avalaula_descricao" value={avaliacao.avalaula_descricao} onChange={(e) => updateFieldAvaliacao(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField className="input" type="number" label="Nota da Avaliação*" variant="outlined" size="small" name="avalaula_nota" value={avaliacao.avalaula_nota} onChange={(e) => updateFieldAvaliacao(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={update ? <CreateIcon /> : <AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
            </Grid>
          </Grid>
        }
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormCargo