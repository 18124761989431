import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormContaFinanceira = ({ dados, updateField, unidade, unidades, updateFieldUnidade, contasBancarias,
  addConta, criarConta,unin_tipo, updateFieldBanco, updateFieldContaBancaria, banco, bancos, contaBancaria, updateFieldContaCorrente, updateFieldAtivo, update, updateFieldSelectConta }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Ordem" variant="outlined" size="small" name="contfin_ordem" value={dados.contfin_ordem} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={4} xs={12} sm={8}>
        <TextField className="input" label="Descrição*" variant="outlined" size="small" name="contfin_descricao" value={dados.contfin_descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      {unin_tipo === 'FRANQUEADO' &&
        <Grid item md={6} xs={12} sm={4}>
          <Autocomplete
            onChange={(event, value) => updateFieldUnidade(event, value)}
            freeSolo
            options={unidades}
            value={unidade}
            getOptionLabel={option => `${option.unin_descricao}`}
            disabled={update}
            renderInput={(params) => (
              <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Unidade" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${unidade.unin_descricao}`} />
            )}
          />
        </Grid>
      }
       {unin_tipo === 'FRANQUEADOR' &&
        <Grid item md={6} xs={12} sm={4}>
          <Autocomplete
            onChange={(event, value) => updateFieldUnidade(event, value)}
            freeSolo
            options={unidades}
            value={unidade}
            getOptionLabel={option => `${option.unin_descricao}`}
            disabled={update}
            renderInput={(params) => (
              <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} label="Unidade" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${unidade.unin_descricao}`} />
            )}
          />
        </Grid>
      }
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Saldo Incial*" variant="outlined" size="small" name="contfin_saldo_inicial" value={dados.contfin_saldo_inicial} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={update} />
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Saldo Atual" variant="outlined" size="small" name="contfin_saldo_atual" value={dados.contfin_saldo_atual} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled />
      </Grid> 
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo Conta*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="contfin_tipo_conta"
          value={dados.contfin_tipo_conta}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={dados.contfin_pes_id === ''  ? true : false}
        >
          <option value=''> Selecionar Tipo da Conta </option>
          <option value='COFRE'> COFRE </option>
          <option value='BANCO'> BANCO </option>
          <option value='CAIXATURNO'> CAIXA TURNO </option>
        </TextField>
      </Grid>
      {/* <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Conta Cofre?*"
          variant="outlined"
          className="input"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="contfin_cofre"
          value={dados.contfin_cofre}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value='Sim'> Sim </option>
          <option value='Não'> Não </option>
        </TextField>
      </Grid> */}
      <Grid item md={2} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          label="Conciliavel*"
          variant="outlined"
          className="input"
          size="small"
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="contfin_conciliavel"
          value={dados.contfin_conciliavel}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value=''> Selecionar </option>
          <option value={true}> Sim </option>
          <option value={false}> Não </option>
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={4}>
        <FormControlLabel className="mg_left_10"
          control={
            <Switch
              name="Ativo"
              color="primary"
              checked={dados.contfin_ativo}
            />
          }
          label="Ativo"
          name="contfin_ativo"
          onChange={(e) => updateFieldAtivo(e)}
        />
      </Grid>
    </Grid>
    {/* <br />
    <hr />
    {dados.contfin_tipo_conta === 'BANCO' &&
      <React.Fragment>
        {dados.contfin_pes_id !== "" &&
          <React.Fragment>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={3} xs={12} sm={6}>
                <TextField className="input" label="Multa Boleto*" variant="outlined" size="small" name="contfincontcor_bol_multa" value={dados.conta_corrente.contfincontcor_bol_multa} onChange={(e) => updateFieldContaCorrente(e)} InputLabelProps={{ shrink: true }} placeholder="0,00%" InputProps={{ inputComponent: Porcentagem }} />
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField className="input" label="Juros Boleto*" variant="outlined" size="small" name="contfincontcor_bol_juros" value={dados.conta_corrente.contfincontcor_bol_juros} onChange={(e) => updateFieldContaCorrente(e)} InputLabelProps={{ shrink: true }} placeholder="0,00%" InputProps={{ inputComponent: Porcentagem }} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Texto Boleto*"
                  className="input"
                  multiline
                  rows={3}
                  size="small"
                  variant="outlined"
                  name="contfincontcor_bol_texto"
                  value={dados.conta_corrente.contfincontcor_bol_texto}
                  onChange={(e) => updateFieldContaCorrente(e)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Instruções Boleto*"
                  className="input"
                  multiline
                  rows={3}
                  size="small"
                  variant="outlined"
                  name="contfincontcor_bol_instrucoes"
                  value={dados.conta_corrente.contfincontcor_bol_instrucoes}
                  onChange={(e) => updateFieldContaCorrente(e)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Linha 1 Boleto*"
                  className="input"
                  multiline
                  rows={3}
                  size="small"
                  variant="outlined"
                  name="contfincontcor_bol_linha1"
                  value={dados.conta_corrente.contfincontcor_bol_linha1}
                  onChange={(e) => updateFieldContaCorrente(e)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    } */}
  </React.Fragment>
)

export default FormContaFinanceira