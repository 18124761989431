import React, { Component } from 'react'
import '../ContaPagarReceber.scss'
import '../../../main/ultil.scss'

import { Grid, Button } from '@material-ui/core'

import FormContaPagarReceber from '../../../components/forms/FormContaPagarReceber'
import SaveIcon from '@material-ui/icons/Save';

import ModalErro from '../../../components/modals/Erro'
import ModalSimple from '../../../components/modals/Confirmacao'
import ModalSalvarBaixa from '../../../components/modals/SalvarBaixa'

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import moeda from '../../../services/moeda'
import real from '../../../services/real';

import { saveAs } from 'file-saver';

const initalState = {
  conta: {
    contpr_descricao: "",
    contpr_tipo: "",
    contpr_valortotal: "",
    contpr_numparcela: 1,
    contpr_planocontas_id: "",
    qtd_parcelas: 1,
    parcelas: [
      {
        parcont_baixado: "",
        parcont_datavencimento: "",
        parcont_datavencimento_orig: "",
        parcont_valorparcela: "",
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: "",
        parcela_editada: false,
        contfin_id: '',
        index: 1
      }
    ]
  },
  planoContas: {
    plancontas_id: "",
    plancontas_codigo: "",
    plancontas_descricao: "",
    plancontas_ativo: "",
    parametros: {
      paramfin_parcelamento: 'Mensal'
    }
  },
  planosConta: {
    list: []
  },
  qtdParcelasEditado: 0,
  contasFinanceira: {
    list: []
  },
  formasPagamento: {
    list: []
  },
  modalErro: false,
  modalSalvarBaixa: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  update: false,
  modalCaixaOpen: false,
  modalCaixa: {
    mensagem: '',
    situacao: ''
  },
  permissoesContasPR: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesCaixa: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  perfilLogado: '',
  disabledButton: false,
  salvarBaixa:{
    valpag_id: '',
    valpag_formapagto_id: '',
    valpag_valor_pagamento: real(0),
    formpag_descricao: '',
    formpag_hab_caixa: false,
    contfin_id: ''
  },
  disabledButtonsSalvar: false,
  disabledSalvarBaixar: false
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

export default class CadastroPlanoContas extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {

    // if(this.props.contasFinanceira){
    //   this.setState({
    //     contasFinanceira:{
    //       list: this.props.contasFinanceira.list
    //     }
    //   })
    // }

    this.setState({
      salvarBaixa:{
        valpag_id: '',
        valpag_formapagto_id: '',
        valpag_valor_pagamento: real(0),
        formpag_descricao: '',
        formpag_hab_caixa: false,
        contfin_id: ''
      }
    })

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.unin_id
      })
    }

    if (dados.unidadesnegocio.length > 1) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor selecionar apenas uma unidade!'
      })
      return
    }

    const caixa = JSON.parse(localStorage.getItem('caixa'))
    this.setState({ logado: { caixa } })

    const dataHoje = new Date()
    const conta = { ...this.state.conta }

    conta.parcelas[0].parcont_datavencimento = moment(dataHoje).format('YYYY-MM-DD')
    conta.parcelas[0].parcont_datavencimento_orig = moment(dataHoje).format('YYYY-MM-DD')

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoesContasPR = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'contas-pagar-receber')[0]
    let permissoesCaixa = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'caixa')[0]

    if (permissoesCaixa) {
      this.setState({
        permissoesCaixa,
        perfilLogado: 'caixa'
      })
    }

    if (permissoesContasPR) {
      this.setState({
        permissoesContasPR,
        perfilLogado: 'contas-pagar-receber'
      })
    }


    console.log(this.props)
    console.log(dados.unidadesnegocio[0])

    let unidade_id = ''

    if(this.props.aluno){
      unidade_id = this.props.aluno.aluno_unin_id
    }else{
      unidade_id = dados.unidadesnegocio[0]
    }

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      let planoContas = []
      if (this.props.aluno) {
        planoContas = data
          .filter(param => param.plancontas_ativo === true)
          .filter(param => {
            if(param.parametros){
              if (param.parametros.paramfin_aluno !== 'I') {
                return {
                  plancontas_id: param.plancontas_id,
                  plancontas_codigo: param.plancontas_codigo,
                  plancontas_descricao: param.plancontas_descricao,
                  plancontas_ativo: param.plancontas_ativo,
                  plancontas_slug: param.plancontas_slug,
                  parametros: param.parametros
                }
              }
            }
            return false
          })
      } else {
        planoContas = data
          .filter(param => param.plancontas_ativo === true)
          .filter(param => {
            if (
              !caixa ||
              (caixa && param.parametros && param.parametros.paramfin_aluno === 'I')
            ) {
              return {
                plancontas_id: param.plancontas_id,
                plancontas_codigo: param.plancontas_codigo,
                plancontas_descricao: param.plancontas_descricao,
                plancontas_ativo: param.plancontas_ativo,
                plancontas_slug: param.plancontas_slug,
                parametros: param.parametros
              }
            }
            return false
          })
      }

      this.setState({
        planosContaOriginal: {
          list: data
        },
        planosConta: {
          list: planoContas
        }
      })

    } catch ({response}) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    

    try {
      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${unidade_id}`, this.getToken())

      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    try {
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      loading: false
    })

    const ultimoCaixa = JSON.parse(localStorage.getItem('ultimoCaixa')) || {}

    this.setState({
      ultimoCaixa,
      conta
    })
  }

  updateField(event) {
    const conta = { ...this.state.conta }

    if (event.target.name === 'contpr_valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replace(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].parcont_valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = this.ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    console.log(conta.parcelas)

    conta[event.target.name] = event.target.value

    this.setState({ conta })
  }

  updateFieldParcela(event, index) {
    const conta = { ...this.state.conta }

    let value = event.target.value
    let name = event.target.name

    if(name === 'parcont_datavencimento'){
      conta.parcelas[index - 1][name] = value
    }else{
      conta.parcelas[index - 1][name] = value
    }

    if(name === 'contfin_id'){
      const conta_selecionada = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(value))[0]

      if(conta_selecionada.conta_corrente){
        if(conta_selecionada.conta_corrente.parceiro){
          this.setState({
            disabledSalvarBaixar: true
          })
        }else{
          this.setState({
            disabledSalvarBaixar: false
          })
        }
      }else{
        this.setState({
          disabledSalvarBaixar: false
        })
      }
    }
    
    this.setState({ conta })
  }

  verificaDataParcela(event, index){
    const {conta, planoContas} = this.state

    const data_hoje = new Date(moment(new Date()).format('YYYY-MM-DD'))
    const data_vencimento = new Date(event.target.value)

    if (data_vencimento.getTime() < data_hoje.getTime()) {
      conta.parcelas[index - 1].parcont_datavencimento = conta.parcelas[index - 1].parcont_datavencimento_orig
    }else{
      conta.parcelas[index - 1].parcont_datavencimento_orig = event.target.value
    }
    
    for (let i = index; i < conta.parcelas.length; i++) {
      let count = (i - index ) + 1

      if(planoContas.parametros.paramfin_parcelamento === 'Mensal'){
        let novaData = moment(event.target.value).add(count, 'months').format('YYYY-MM-DD')

        conta.parcelas[i].parcont_datavencimento = novaData
        conta.parcelas[i].parcont_datavencimento_orig = novaData
      }else{
        let qtdDias = 30 *  count
        let novaData = moment(event.target.value).add(qtdDias, 'days').format('YYYY-MM-DD')
        let diaSemana = moment(novaData).format('dddd')
        
        if(diaSemana === 'sábado'){
          novaData = moment(novaData).add(2, 'days').format('YYYY-MM-DD')
        }else if(diaSemana === 'domingo'){
          novaData = moment(novaData).add(1, 'days').format('YYYY-MM-DD')
        }

        conta.parcelas[i].parcont_datavencimento = novaData
        conta.parcelas[i].parcont_datavencimento_orig = novaData
      }
    }

    this.setState({ conta })
  }

  updateFieldPlano(event, value) {
    const conta = { ...this.state.conta }

    conta.contpr_planocontas_id = value.plancontas_id
    conta.contpr_tipo = value.parametros.paramfin_caixa === 'D' ? 'PAGAR' : 'RECEBER'

    this.setState({
      planoContas: value,
      conta
    })
  }

  recalcularParcela(event, index) {
    const conta = { ...this.state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.parcont_valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))

      let qtdParcelasEditado = this.state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        this.setState({
          qtdParcelasEditado
        })
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)


      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].parcont_valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = this.ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      this.setState({ conta })
    }
  }

  mudarParcelas(event) {
    const conta = { ...this.state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date();
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i));

      array.push({
        parcont_baixado: "",
        parcont_datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        parcont_valorparcela: real(valorDividido),
        parcont_formapagto_id: "",
        parcont_documento: "",
        parcont_num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = this.ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.contpr_numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array
    this.setState({ conta })
  }

  ajustarArray(qtdParcela, array, valorDividido, sobra) {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].parcont_valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })

    // this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this
    setTimeout(() => {
      const ultima_tela_financeiro = JSON.parse(localStorage.getItem('ultima_tela_financeiro'))

      if (ultima_tela_financeiro.tela === 'contasPR') {
        self.props.history.push("/conta_pagar_receber");
      } else if (ultima_tela_financeiro.tela === 'aluno') {
        if (self.props.aluno) {
          window.location.reload()
        } else {
          self.props.history.push(`/aluno/view/${ultima_tela_financeiro.matricula}/financeiro`)
        }
      } else {
        window.location.reload()
      }
    }, timeout)
    this.setState({
      conta: initalState.conta
    })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  verificaCaixaInicial(scope) {
    if (!scope.state.ultimoCaixa || !scope.state.logado) return ''
    const { ultimoCaixa, perfilLogado } = scope.state

    if (
      perfilLogado === 'caixa' &&
      (!Object.keys(ultimoCaixa).length || (ultimoCaixa && ultimoCaixa.cai_fechado))
    ) {
      return (
        <Grid item md={12} xs={12} sm={12} style={{ 'margin': '30px 0' }}>
          <p> É necessário abrir um caixa </p>
        </Grid>
      )
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.cai_fechado
    ) {

      const dataCaixa = moment(ultimoCaixa.cai_dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        return (
          <Grid item md={12} xs={12} sm={12} style={{ 'margin': '30px 0' }}>
            <p> O caixa não é de hoje, é necessario fechar o anterior e abrir um novo </p>
          </Grid>
        )
      }
    }

    return ''
  }

  verificaCaixa() {
    const { ultimoCaixa, perfilLogado } = this.state

    if (
      perfilLogado === 'caixa' &&
      (
        !Object.keys(ultimoCaixa).length ||
        (ultimoCaixa && ultimoCaixa.cai_fechado)
      )
    ) {
      this.setState({
        modalCaixa: {
          mensagem: 'É necessário abrir um caixa, deseja abrir um caixa?',
          situacao: 'ABRIR'
        },
        modalCaixaOpen: true,
      })
      return false
    }

    if (
      perfilLogado === 'caixa' &&
      ultimoCaixa &&
      !ultimoCaixa.cai_fechado
    ) {
      const dataCaixa = moment(ultimoCaixa.cai_dataabertura).utcOffset('-03:00').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      const hoje = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (hoje.diff(dataCaixa, 'days') > 0) {
        // verificar Data abertura
        this.setState({
          modalCaixa: {
            mensagem: 'Opps, O caixa não é de hoje, é necessario fechar o anterior e abrir um novo, deseja ser redirecionado para o fechamento?',
            situacao: 'FECHAR'
          },
          modalCaixaOpen: true,
        })
        return false
      }
    }

    return true
  }

  confirmarCaixa(resposta) {
    const { modalCaixa, ultimoCaixa } = this.state

    if (resposta) {
      const encode = Buffer.from(this.props.location.pathname).toString('base64')

      if (modalCaixa.situacao === 'ABRIR') {
        this.props.history.push({ pathname: `/caixa/cadastro/${encode}` })
      }

      if (modalCaixa.situacao === 'FECHAR') {
        this.props.history.push({ pathname: `/caixa/view/${ultimoCaixa.cai_id}` })
      }

    } else {
      this.handleClose()
    }
  }

  validateForm() {
    const { conta } = this.state
    
    if (!conta.contpr_descricao) return false
    if (!conta.contpr_tipo) return false
    if (!conta.contpr_valortotal) return false
    if (!conta.contpr_numparcela) return false
    if (!conta.contpr_planocontas_id) return false
    
    for (let i = 0; i < conta.parcelas.length; i++) {
      if (!conta.parcelas[i].parcont_datavencimento) return false
      if (!conta.parcelas[i].parcont_valorparcela) return false
      if (!conta.parcelas[i].parcont_formapagto_id) return false
    }

    return true
  }

  validateFormCaixa() {
    let { conta, planosContaOriginal: { list: planos } } = this.state

    if (conta.contpr_tipo !== 'RECEBER') {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Para incluir ao caixa, somente contas A RECEBER"
      })

      return false
    }

    let plano = null
    let ficar = true
    let i = 0
    do {
      if (planos[i].plancontas_id === conta.contpr_planocontas_id) {
        plano = planos[i]
        ficar = false
      }
      i++
    } while (ficar && i <= planos.length)

    if (
      !['matricula', 'mensalidade'].includes(plano.plancontas_slug) &&
      (plano.parametros && plano.parametros.paramfin_aluno === 'I')
    ) {
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Para incluir ao caixa, o plano de conta deve ser associado ao aluno"
      })
      return false
    }

    return true
  }

  async salvar(baixar) {
    this.setState({
      disabledButton: true
    })

    if (!this.verificaCaixa()) {
      return false
    }

    if (!this.validateForm()) {
      this.setState({
        disabledButton: false
      })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { perfilLogado } = this.state
    let conta = { ...this.state.conta }

    if (perfilLogado === 'caixa' && !this.validateFormCaixa()) {
      return false
    }

    try {
      let dados = { ...conta }

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dadosUnidades = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      for (let i = 0; i < dados.parcelas.length; i++) {
        const forma_selecionada = this.state.formasPagamento.list.filter(param => param.formpag_id === parseInt(dados.parcelas[i].parcont_formapagto_id))[0]
        const conta_selecionada = this.state.contasFinanceira.list.filter(param => param.contfin_id === parseInt(dados.parcelas[i].contfin_id))[0]

        if(conta_selecionada){
          if(conta_selecionada.conta_corrente){
            if(conta_selecionada.conta_corrente.parceiro){
              if(
                moment(dados.parcelas[i].parcont_datavencimento).isBefore(new Date()) 
                && forma_selecionada.formpag_slug === 'boleto'){
                  this.setState({ disabledButton: false })
                  this.handleOpenDialog({
                    titulo: "Data Invalida!",
                    descricao: `A data de vencimento da parcela ${dados.parcelas[i].index} não pode ser inferior ao dia de amanhã!`
                  })
    
                  return
              }
    
              let valor_parcela = parseFloat(dados.parcelas[i].parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", "."))
            
              if(valor_parcela < 3 && (forma_selecionada.formpag_slug === 'boleto' || forma_selecionada.formpag_slug === 'pix')){
                this.setState({ disabledButton: false })
                this.handleOpenDialog({
                  titulo: "Valor Inferior!",
                  descricao: "O Valor do boleto/pix não pode ser inferior a 3 Reias!"
                })
    
                return
              }
            }
          }
        }
        
      }

      let contpr_resp_pessoa_id = ''

      if(this.props.aluno){
        if(this.props.aluno.contrato.resp_fin){
          contpr_resp_pessoa_id = this.props.aluno.contrato.resp_fin.pessoa.pes_id
        }else{
          contpr_resp_pessoa_id = this.props.aluno.pessoaFisica.pesf_pessoa_id
        }
      }

      let dadosAux = {
        contpr_descricao: dados.contpr_descricao,
        contpr_tipo: dados.contpr_tipo,
        contpr_valortotal: parseFloat(dados.contpr_valortotal.replace("R$ ", "").replace(".", "").replace(",", ".")),
        contpr_numparcela: dados.contpr_numparcela,
        contpr_planocontas_id: dados.contpr_planocontas_id,
        contpr_unin_id: dadosUnidades.unidadesnegocio[0],
        contpr_resp_pessoa_id,
        parcelas: dados.parcelas.map(parcela => {
          return {
            parcont_datavencimento: parcela.parcont_datavencimento,
            parcont_valorparcela: parseFloat(parcela.parcont_valorparcela.replace("R$ ", "").replace(".", "").replace(",", ".")),
            parcont_num: parcela.index,
            parcont_documento: parcela.parcont_documento,
            formas_pgto: [
              {
                valpag_formapagto_id: parseInt(parcela.parcont_formapagto_id),
                contfin_id: parseInt(parcela.contfin_id)
              }
            ]
          }
        })
      }

      if(dadosAux.contpr_resp_pessoa_id === '' || !dadosAux.contpr_resp_pessoa_id ){
        delete dadosAux.contpr_resp_pessoa_id 
      }

      for (let i = 0; i < dadosAux.parcelas.length; i++) {
        if (dadosAux.parcelas[i].parcont_documento === '') {
          delete dadosAux.parcelas[i].parcont_documento
        }

        if (dadosAux.parcelas[i].formas_pgto[0].contfin_id === '' || !dadosAux.parcelas[i].formas_pgto[0].contfin_id) {
          delete dadosAux.parcelas[i].formas_pgto[0].contfin_id
        }
      }

      if (perfilLogado === 'caixa') {
        dadosAux.caixa = this.state.ultimoCaixa.cai_id
      }

      if (this.props.aluno) {
        dadosAux.aluno = this.props.aluno._id
      }

      const {data: contaResp} = await axios.post(`${process.env.REACT_APP_API_URL}/contasPagarReceber`, dadosAux, this.getToken())

      // for (const parcela of contaResp.parcelas) {
      //   if(parcela.forma_pgto === 'boleto'){
      //     await axios.post(`${process.env.REACT_APP_API_URL}/boleto`, {parcela_id: parcela.parcont_id}, this.getToken())
      //   }else if (parcela.forma_pgto === 'pix'){
      //     await axios.post(`${process.env.REACT_APP_API_URL}/pix`, {parcela_id: parcela.parcont_id}, this.getToken())
      //   }
      // }

      if(baixar){
        const {salvarBaixa} = this.state

        const dadosConta = {
          conta: {
            contpr_id: contaResp.contpr_id,
            contpr_descricao: contaResp.contpr_descricao,
            plancontas_id: dadosAux.contpr_planocontas_id
          },
          parcela: {
            parcont_baixado: true,
            parcont_datavencimento: dadosAux.parcelas[0].parcont_datavencimento,
            parcont_valorparcela: dadosAux.parcelas[0].parcont_valorparcela,
            parcont_multajuros: 0,
            parcont_num: dadosAux.parcelas[0].parcont_num,
            parcont_situacao: 'BAIXADO',
            formas_pgto:[{
              valpag_formapagto_id: parseInt(salvarBaixa.valpag_formapagto_id),
              contfin_id: parseInt(salvarBaixa.contfin_id),
              valpag_valor_pagamento: dadosAux.parcelas[0].parcont_valorparcela
            }]
          }
        }

        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/parcelaConta/${contaResp.parcelas[0].parcont_id}`, dadosConta, this.getToken())

        if (data.caminhoRecibo !== '') {

          const dadosImpressao = {
            path: data.caminhoRecibo
          }

          const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImpressao, { responseType: 'blob' })

          const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

          saveAs(pdfBlob, `Recibo-${contaResp.parcelas[0].parcont_id}.pdf`);
        }

        this.setState({
          salvarBaixa: initalState.salvarBaixa
        })

        this.setState({disabledButtonsSalvar: false})

        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Parcela baixada com sucesso.'
        })
        this.backPage()

      }else{
        this.setState({
          conta: initalState.conta
        })
  
        this.setState({
          disabledButton: false
        })
  
        this.handleOpenDialog({
          titulo: 'Parabéns',
          descricao: 'Cadastro realizado com sucesso.'
        })
        this.backPage()
      }


    } catch ({response}) {
      this.setState({disabledButtonsSalvar: false})
      this.setState({
        disabledButton: false
      })

      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }
  
  async salvarBaixar(){
    if (!this.validateForm()) {
      this.setState({
        disabledButton: false
      })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    this.setState({
      modalSalvarBaixa: true
    })
  }

  handleCloseSalvarBaixa(){
    this.setState({
      modalSalvarBaixa: false
    })
  }
  
  updateFieldBaixaParcela(event) {
    let { salvarBaixa, formasPagamento, contasFinanceira } = this.state

    if (event.target.name === 'valpag_formapagto_id') {
      const itens = formasPagamento.list
      const index = itens.findIndex(param => param.formpag_id === parseInt(event.target.value))

      salvarBaixa[event.target.name] = parseInt(event.target.value)
      salvarBaixa.formpag_descricao = itens[index].formpag_descricao
      salvarBaixa.formpag_hab_caixa = itens[index].formpag_hab_caixa
    } else if (event.target.name === 'contfin_id') {
      const itens = contasFinanceira.list
      const index = itens.findIndex(param => param.contfin_id === parseInt(event.target.value))

      salvarBaixa[event.target.name] = parseInt(event.target.value)
      salvarBaixa.contfin_descricao = itens[index].contfin_descricao
      salvarBaixa.contfin_tipo_conta = itens[index].contfin_tipo_conta
           
    } else {
      salvarBaixa[event.target.name] = event.target.value
    }

    this.setState({ salvarBaixa })
  }

  confirmarBaixaSalvar(){
    const {salvarBaixa} = this.state
    
    if(salvarBaixa.contfin_id === ''){
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Por favor informar a conta financeira (*)."
      })
      return
    }

    if(salvarBaixa.valpag_formapagto_id === ''){
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Por favor informar a forma de pagamento (*)."
      })
      return
    }

    this.setState({disabledButtonsSalvar: true})

    this.salvar(true)
  }

  render() {
    const { conta } = this.state

    return (
      <div
        style={{
          width: '100%'
        }}
      >
        {this.state.loading &&
          <div>
            <CircularProgress />
          </div>
        }
        {!this.state.loading &&
          <div>
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={12}>
                <h2 className="titulo">Cadastro de Contas Pagar/Receber</h2>
              </Grid>
            </Grid>
            <FormContaPagarReceber
              logado={this.state.perfilLogado}
              verificaCaixaInicial={() => this.verificaCaixaInicial(this)}
              dados={conta}
              updateField={e => this.updateField(e)}
              updateFieldPlano={(event, value) => this.updateFieldPlano(event, value)}
              updateFieldParcela={(e, index) => this.updateFieldParcela(e, index)}
              verificaDataParcela={(e, index) => this.verificaDataParcela(e, index)}
              mudarParcelas={e => this.mudarParcelas(e)}
              recalcularParcela={(e, value) => this.recalcularParcela(e, value)}
              planoContas={this.state.planoContas}
              planosConta={this.state.planosConta.list}
              formasPagamento={this.state.formasPagamento.list}
              contasFinanceira={this.state.contasFinanceira.list}
              ultimoCaixa={this.state.ultimoCaixa}
              habilitaContasFinan = {this.props.aluno ? true: false}
            />
            <hr />
            <Grid container direction="row" alignItems="flex-end" className="mg_top_10" spacing={1}>
              <Grid item md={6} xs={false} sm={4}></Grid>
              <Grid item md={3} xs={12} sm={4}>
                {conta.qtd_parcelas === 1 &&
                  <React.Fragment>
                    {!this.state.disabledSalvarBaixar &&
                      <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvarBaixar(e)} disabled={this.state.disabledButton}>Salvar e Baixar</Button>
                    }
                  </React.Fragment>
                }
              </Grid>
              <Grid item md={3} xs={12} sm={4}>
                <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(false)} disabled={this.state.disabledButton}>Salvar</Button>
              </Grid>
            </Grid>
          </div>
        }
        <br />
        <ModalErro 
          open={this.state.modalErro} 
          titulo={this.state.erro.titulo} 
          descricao={this.state.erro.descricao} 
          handleClose={e => this.handleCloseErro(e)} />

        <ModalSimple 
          open={this.state.modalCaixaOpen} 
          dados={this.state.modalCaixa} 
          handleClose={e => this.handleClose(e)} 
          updateData={e => this.updateField(e)} 
          confirmar={e => this.confirmarCaixa(e)} />

        <ModalSalvarBaixa 
          open={this.state.modalSalvarBaixa} 
          dados={this.state.salvarBaixa} 
          handleClose={e => this.handleCloseSalvarBaixa(e)} 
          updateField={e => this.updateFieldBaixaParcela(e)}
          contasFinanceira={this.state.contasFinanceira.list}
          formasPagamento={this.state.formasPagamento.list}
          conta={conta}
          confirmar={e => this.confirmarBaixaSalvar(e)}
          disabledButtonsSalvar={this.state.disabledButtonsSalvar}
        />
      </div>
    )
  }
}