import React, { Component } from 'react'
import './ParceirosBancarios.scss'
import './../../main/ultil.scss'

import { Link } from 'react-router-dom'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'

import { Grid, Button } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'

const initalState = {
  parceirosBancarios: {
    list: []
  },
  parceirosBancariosFiltrado: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  loading: true,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  }
}

export default class Produto extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'parceiros-bancario')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))
    const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo

    this.setState({
      unin_tipo
    })
    
    try {
      
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/parceiroBancario`, this.getToken())

      let parceirosBancarios = data.map(parceiro => {
        return {
          _id: parceiro.pb_id,
          descricao: parceiro.pb_descricao,
          ativo: parceiro.pb_ativo
        }
      })
      
      this.setState({
        parceirosBancarios: {
          list: parceirosBancarios
        },
        parceirosBancariosFiltrado: {
          list: parceirosBancarios
        }
      })
    }catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
    this.backPage()
  }

  backPage(timeout = 1000) {
    const self = this

    setTimeout(() => {
      self.props.history.push("/home");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro ao Visualizar!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

 
  render() {
    const { parceirosBancariosFiltrado, permissoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Parceiros...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={8} xs={12}>
                  <h1 className="titulo">Parceiros Bancários</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={4} xs={12}>
                    <Link to="/parceiros_bancario/cadastro">
                      <Button className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Parceiro
								      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/parceiros_bancario/cadastro/" headCell={this.state.cabecalhoTabela} rows={parceirosBancariosFiltrado.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}