import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from '../pages/login/Login'
import RecuperarSenha from '../pages/login/RecuperarSenha'
import TrocarSenha from '../pages/login/TrocarSenha'
import Home from '../pages/home/Home'
import Usuario from '../pages/usuario/Usuario'

import Franqueado from '../pages/franqueado/Franqueado'
import FranqueadoCadastro from '../pages/franqueado/Cadastro'
import FranqueadoUpdateResponsavel from '../pages/franqueado/CadastroResp'
import FranqueadoView from '../pages/franqueado/View'

import Unidade from '../pages/unidade/Unidade'
import UnidadeCadastro from '../pages/unidade/Cadastro'
import UnidadeView from '../pages/unidade/View'
import UnidadeUpdate from '../pages/unidade/Update'

import Cargo from '../pages/cargo/Cargo'
import CargoCadastro from '../pages/cargo/Cadastro'

import Funcionario from '../pages/funcionario/Funcionario'
import FuncionarioCadastro from '../pages/funcionario/Cadastro'
import FuncionarioView from '../pages/funcionario/View'
import FuncionarioContrato from '../pages/funcionario/Contrato'
import FuncionarioUpdateUsuario from '../pages/funcionario/UpdateUsuario'

import GrupoProdutos from '../pages/produto/grupoProduto/GrupoProduto'
import GrupoProdutosCadastro from '../pages/produto/grupoProduto/Cadastro'

import SubGrupoProdutos from '../pages/produto/subGrupoProduto/SubGrupoProduto'
import SubGrupoProdutosCadastro from '../pages/produto/subGrupoProduto/Cadastro'

import Produtos from '../pages/produto/Produto'
import ProdutosCadastro from '../pages/produto/Cadastro'
import ProdutosView from '../pages/produto/View'

import Departamentos from '../pages/departamento/Departamento'
import DepartamentosCadastro from '../pages/departamento/Cadastro'

import Funcao from '../pages/funcao/Funcao'
import FuncaoCadastro from '../pages/funcao/Cadastro'

import Perfil from '../pages/perfil/Perfil'
import PerfilCadastro from '../pages/perfil/Cadastro'

import LocalEstoque from './../pages/estoque/localEstoque/LocalEstoque'
import LocalEstoqueCadastro from './../pages/estoque/localEstoque/Cadastro'
import LocalEstoqueUpdate from './../pages/estoque/localEstoque/Update'

import Estoque from './../pages/estoque/Estoque'
import EstoqueCadastro from './../pages/estoque/Cadastro'

import TabelaPreco from './../pages/tabelaPrecos/TabelaPreco'
import TabelaPrecoCadastro from './../pages/tabelaPrecos/Cadastro'

import Book from './../pages/book/Book'
import BookCadastro from './../pages/book/Cadastro'

import Curso from './../pages/curso/Curso'
import CursoCadastro from './../pages/curso/Cadastro'
import CursoView from './../pages/curso/View'

import Aula from './../pages/aula/Aula'
import AulaCadastro from './../pages/aula/Cadastro'
import AulaView from './../pages/aula/View'
import AulaHorario from './../pages/aula/Horario'
import AbrirAulaFixa from './../pages/aula/AbrirAulaFixa'

import Aluno from './../pages/aluno/Aluno'
import AlunoCadastro from './../pages/aluno/Cadastro'
import AlunoNovaPessoa from './../pages/aluno/NovaPessoa'
import AlunoView from './../pages/aluno/View'
import UpdateAluno from './../pages/aluno/UpdateAluno'
import UpdateContrato from './../pages/aluno/UpdateContrato'
import UpdateResponsavel from './../pages/aluno/UpdateResponsavel'

import Feriado from './../pages/feriado/Feriado'
import FeriadoCadastro from './../pages/feriado/Cadastro'
import FeriadoView from './../pages/feriado/View'

import PlanoPagamento from './../pages/planoPagamento/PlanoPagamento'
import PlanoPagamentoCadastro from './../pages/planoPagamento/Cadastro'
import PlanoPagamentoView from './../pages/planoPagamento/View'

import PlanoContas from './../pages/planoContas/PlanoContas'
import PlanoContasCadastro from './../pages/planoContas/Cadastro'
import PlanoContasView from './../pages/planoContas/View'

import ContaFinanceira from './../pages/contaFinanceira/ContaFinanceira'
import ContaFinanceiraCadastro from './../pages/contaFinanceira/Cadastro'
import ContaFinanceiraView from './../pages/contaFinanceira/View'
import ContaFinanceiraCorrigirSaldo from './../pages/contaFinanceira/CorrigirSaldo'

import Movimentacao from './../pages/movimentacao/Movimentacao'
import MovimentacaoCadastro from './../pages/movimentacao/Cadastro'

import ContaPagarReceber from './../pages/contaPagarReceber/ContaPagarReceber'
import ContaPagarReceberCadastro from './../pages/contaPagarReceber/Cadastro'
import ContaPagarReceberUpdate from './../pages/contaPagarReceber/Update'

// import Conciliacao from './../pages/conciliacao/Conciliacao'

import Documento from './../pages/documento/Documento'
import DocumentoCadastro from './../pages/documento/Cadastro'

import Schedule from './../pages/schedule/Schedule'
import BaixaAula from './../pages/baixaAula/BaixaAula'

import RelatorioAdm from './../pages/relatorios/Administrativo'
import RelatorioPegagogico from './../pages/relatorios/Pedagogico'
import RelatorioComercial from './../pages/relatorios/Comercial'
import RelatorioRanking from './../pages/relatorios/Ranking'
import RelatorioFlyHigh from './../pages/relatorios/FlyHigh'
import RelatorioCarteiraMensalidade from './../pages/relatorios/CarteiraMensalidade'
import RelatorioFinanceiro from './../pages/relatorios/Financeiro'

import Caixa from '../pages/caixa/Caixa'
import CaixaCadastro from '../pages/caixa/Cadastro'
import CaixaView from '../pages/caixa/View'
import SolicitacaoAberturaCaixa from '../pages/caixa/SolicitacaoAberturaCaixa'

import FechamentoCaixa from '../pages/fechamentoCaixa/FechamentoCaixa'
import FechamentoCaixaView from '../pages/fechamentoCaixa/View'

import CodigoAlfaNum from '../pages/codigoAlfaNum/CodigoAlfaNum'
import CodigoAlfaNumCadastro from '../pages/codigoAlfaNum/Cadastro'
import CodigoAlfaNumView from '../pages/codigoAlfaNum/View'

import DemonstrativoDiario from '../pages/demonstrativoDiario/DemonstrativoDiario'
import DemonstrativoDiarioView from '../pages/demonstrativoDiario/DemonstrativoDiarioView'

import NovaMatriculaCadastro from '../pages/aluno-nova-matricula/Cadastro'

import AprovarTransferencia from '../pages/transferencia/AprovarTransferencia'
import AprovarRematricula from '../pages/transferencia/AprovarRematricula'

import ConfigAditivo from '../pages/configAditivo/ConfigAditivo'
import CadastroConfigAditivo from '../pages/configAditivo/Cadastro'
import ViewConfigAditivo from '../pages/configAditivo/View'

import FlyHigh from '../pages/flyHigh/FlyHigh'
import FlyHighCadastro from '../pages/flyHigh/Cadastro'
import FlyHighImpressaoUnidade from '../pages/relatorios/impressao/fly_high_unidade'
import FlyHighImpressaoCargo from '../pages/relatorios/impressao/fly_high_cargo'
import FlyHighImpressaoColaborador from '../pages/relatorios/impressao/fly_high_colaborador'
import RankingBFF from '../pages/rankingBFF/RankingBFF'


import CarteiraMensalidadeImpressao from '../pages/relatorios/impressao/carteira_mensalidade'
import CarteiraMensalidadeUnidadeImpressao from '../pages/relatorios/impressao/carteira_mensalidade_unidade'

import GraficoCarteiraMensalidadeImpressao from '../pages/relatorios/impressao/evolucao_alunos_periodo'

import ServicosBancarios from './../pages/servicosBancarios/ServicosBancarios'
import ServicosBancariosCadastro from './../pages/servicosBancarios/Cadastro'

import ParceirosBancarios from './../pages/parceirosBancarios/ParceirosBancarios'
import ParceirosBancariosCadastro from './../pages/parceirosBancarios/Cadastro'

import CentralNotificacao from './../pages/centralNotificacao/CentralNotificacao'
import CentralNotificacaoCadastro from './../pages/centralNotificacao/Cadastro'

import WhatsApp from './../pages/whatsApp/WhatsApp'
import WhatsAppCadastro from './../pages/whatsApp/Cadastro'

import CobrancaUnidade from './../pages/cobrancaUnidade/CobrancaUnidade'
import CobrancaUnidadeCadastro from './../pages/cobrancaUnidade/Cadastro'
import CobrancaUnidadeView from './../pages/cobrancaUnidade/View'

import CupomDesconto from './../pages/cupomDesconto/CupomDesconto'
import CupomDescontoCadastro from './../pages/cupomDesconto/Cadastro'
import CupomDescontoView from './../pages/cupomDesconto/View'

import ContratosUnidades from './../pages/contratosUnidades/ContratosUnidades'

import AreaCliente from './../pages/areaCliente/AreaCliente'
import AssinarContrato from './../pages/aluno/AssinarContrato'
import AssinarAusencia from './../pages/aluno/AssinarAusencia'
import AssinarProrrogacao from './../pages/aluno/AssinarProrrogacao'
import AssinarAditivo from './../pages/aluno/AssinarAditivo'

import ConfigVendaCentral from './../pages/configVendaCentral/ConfigVendaCentral'

import EADCadastro from './../pages/EAD/Cadastro'

import VendaProduto from './../pages/vendaProduto/VendaProduto'
import VendaProdutoCadastro from './../pages/vendaProduto/Cadastro'

import CompraProduto from './../pages/compraProduto/CompraProduto'
import CompraProdutoCadastro from './../pages/compraProduto/Cadastro'

import Lembrete from './../pages/lembrete/Lembrete'
import LembreteCadastro from './../pages/lembrete/Cadastro'

import PrivateRoute from './auth';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path={`/`} component={Login} />
      <Route exact path={`/login`} component={Login} />
      <Route exact path={`/recuperar_senha`} component={RecuperarSenha} />
      <Route exact path={`/trocar_senha/:usuarioId`} component={TrocarSenha} />
      <Route exact path={`/portal_aluno/:pessoaId`} component={AreaCliente} />
      <Route exact path={`/ead/:cursoId/cadastro`} component={EADCadastro} />
      <Route exact path={`/assinatura_contrato/:contrato_id`} component={AssinarContrato} />
      <Route exact path={`/assinatura_ausencia/:ausencia_id`} component={AssinarAusencia} />
      <Route exact path={`/assinatura_prorrogacao/:prorrogacao_id`} component={AssinarProrrogacao} />
      <Route exact path={`/assinatura_aditivo/:aditivo_id`} component={AssinarAditivo} />

      <PrivateRoute exact path={`/home`} component={Home} />
      <PrivateRoute exact path={`/usuario`} component={Usuario} />

      <PrivateRoute exact path={`/franqueado`} component={Franqueado} />
      <PrivateRoute exact path={`/franqueado/view/:franqueadoId`} component={FranqueadoView} />
      <PrivateRoute exact path={`/franqueado/update/:franqueadoId`} component={FranqueadoCadastro} />
      <PrivateRoute exact path={`/franqueado/updateResponsavel/:franqueadoId`} component={FranqueadoUpdateResponsavel} />

      <PrivateRoute exact path={`/unidade`} component={Unidade} />
      <PrivateRoute exact path={`/unidade/cadastro`} component={UnidadeCadastro} />
      <PrivateRoute exact path={`/unidade/view/:unidadeId`} component={UnidadeView} />
      <PrivateRoute exact path={`/unidade/view/:unidadeId/:tab`} component={UnidadeView} />
      <PrivateRoute exact path={`/unidade/update/:unidadeId`} component={UnidadeUpdate} />

      <PrivateRoute exact path={`/cargo`} component={Cargo} />
      <PrivateRoute exact path={`/cargo/cadastro`} component={CargoCadastro} />
      <PrivateRoute exact path={`/cargo/cadastro/:cargoId`} component={CargoCadastro} />

      <PrivateRoute exact path={`/funcionario`} component={Funcionario} />
      <PrivateRoute exact path={`/funcionario/cadastro`} component={FuncionarioCadastro} />
      <PrivateRoute exact path={`/funcionario/cadastro/:funcionarioId`} component={FuncionarioCadastro} />
      <PrivateRoute exact path={`/funcionario/contrato/:status/:funcionarioId`} component={FuncionarioContrato} />
      <PrivateRoute exact path={`/funcionario/view/:funcionarioId`} component={FuncionarioView} />
      <PrivateRoute exact path={`/funcionario/view/:funcionarioId/:tab`} component={FuncionarioView} />
      <PrivateRoute exact path={`/funcionario/update/usuario/:funcionarioId`} component={FuncionarioUpdateUsuario} />

      <PrivateRoute exact path={`/grupo_produtos`} component={GrupoProdutos} />
      <PrivateRoute exact path={`/grupo_produtos/cadastro`} component={GrupoProdutosCadastro} />
      <PrivateRoute exact path={`/grupo_produtos/cadastro/:grupoProdutoId`} component={GrupoProdutosCadastro} />

      <PrivateRoute exact path={`/sub_grupo_produtos`} component={SubGrupoProdutos} />
      <PrivateRoute exact path={`/sub_grupo_produtos/cadastro`} component={SubGrupoProdutosCadastro} />
      <PrivateRoute exact path={`/sub_grupo_produtos/cadastro/:subGrupoProdutoId`} component={SubGrupoProdutosCadastro} />

      <PrivateRoute exact path={`/produtos`} component={Produtos} />
      <PrivateRoute exact path={`/produtos/cadastro`} component={ProdutosCadastro} />
      <PrivateRoute exact path={`/produtos/cadastro/:produtoId`} component={ProdutosCadastro} />
      <PrivateRoute exact path={`/produtos/view/:produtoId`} component={ProdutosView} />

      <PrivateRoute exact path={`/departamentos`} component={Departamentos} />
      <PrivateRoute exact path={`/departamentos/cadastro`} component={DepartamentosCadastro} />
      <PrivateRoute exact path={`/departamentos/cadastro/:departamentoId`} component={DepartamentosCadastro} />

      <PrivateRoute exact path={`/funcao`} component={Funcao} />
      <PrivateRoute exact path={`/funcao/cadastro`} component={FuncaoCadastro} />
      <PrivateRoute exact path={`/funcao/cadastro/:funcaoId`} component={FuncaoCadastro} />

      <PrivateRoute exact path={`/perfil`} component={Perfil} />
      <PrivateRoute exact path={`/perfil/cadastro/`} component={PerfilCadastro} />
      <PrivateRoute exact path={`/perfil/cadastro/:perfilId`} component={PerfilCadastro} />

      <PrivateRoute exact path={`/local_estoque`} component={LocalEstoque} />
      <PrivateRoute exact path={`/local_estoque/cadastro`} component={LocalEstoqueCadastro} />
      <PrivateRoute exact path={`/local_estoque/update/:localEstoqueId`} component={LocalEstoqueUpdate} />

      <PrivateRoute exact path={`/estoque`} component={Estoque} />
      <PrivateRoute exact path={`/estoque/cadastro`} component={EstoqueCadastro} />
      <PrivateRoute exact path={`/estoque/update/:estoqueProdutoId`} component={EstoqueCadastro} />
      <PrivateRoute exact path={`/estoque/cadastro/:produtoId`} component={EstoqueCadastro} />

      <PrivateRoute exact path={`/tabela_preco`} component={TabelaPreco} />
      <PrivateRoute exact path={`/tabela_preco/cadastro`} component={TabelaPrecoCadastro} />
      <PrivateRoute exact path={`/tabela_preco/cadastro/:tabelaPrecoProdutoId`} component={TabelaPrecoCadastro} />

      <PrivateRoute exact path={`/book`} component={Book} />
      <PrivateRoute exact path={`/book/cadastro`} component={BookCadastro} />
      <PrivateRoute exact path={`/book/cadastro/:bookId`} component={BookCadastro} />

      <PrivateRoute exact path={`/curso`} component={Curso} />
      <PrivateRoute exact path={`/curso/cadastro`} component={CursoCadastro} />
      <PrivateRoute exact path={`/curso/cadastro/:cursoId`} component={CursoCadastro} />
      <PrivateRoute exact path={`/curso/view/:cursoId`} component={CursoView} />

      <PrivateRoute exact path={`/aula`} component={Aula} />
      <PrivateRoute exact path={`/aula/cadastro`} component={AulaCadastro} />
      <PrivateRoute exact path={`/aula/cadastro/:aulaId`} component={AulaCadastro} />
      <PrivateRoute exact path={`/aula/view/:aulaId`} component={AulaView} />
      <PrivateRoute exact path={`/aula/horario/:aulaId`} component={AulaHorario} />

      <PrivateRoute exact path={`/aluno`} component={Aluno} />
      <PrivateRoute exact path={`/aluno/cadastro`} component={AlunoCadastro} />
      <PrivateRoute exact path={`/aluno/cadastro/:alunoId`} component={AlunoCadastro} />
      <PrivateRoute exact path={`/aluno/cadastro/pessoa/:alunoId`} component={AlunoNovaPessoa} />
      <PrivateRoute exact path={`/aluno/cadastro/:pefIdEncode/nova-matricula`} component={AlunoCadastro} />
      <PrivateRoute exact path={`/aluno/view/:alunoId`} component={AlunoView} />
      <PrivateRoute exact path={`/aluno/view/:alunoId/:tab`} component={AlunoView} />
      <PrivateRoute exact path={`/aluno/update/:alunoId`} component={UpdateAluno} />
      <PrivateRoute exact path={`/aluno/update/contrato/:contratoId`} component={UpdateContrato} />
      <PrivateRoute exact path={`/aluno/update/responsavel/:contratoId`} component={UpdateResponsavel} />
      <PrivateRoute exact path={`/aulas_fixas`} component={AbrirAulaFixa} />

      <PrivateRoute exact path={`/feriado`} component={Feriado} />
      <PrivateRoute exact path={`/feriado/cadastro`} component={FeriadoCadastro} />
      <PrivateRoute exact path={`/feriado/cadastro/:feriadoId`} component={FeriadoCadastro} />
      <PrivateRoute exact path={`/feriado/view/:feriadoId`} component={FeriadoView} />

      <PrivateRoute exact path={`/plano_pagamento`} component={PlanoPagamento} />
      <PrivateRoute exact path={`/plano_pagamento/cadastro`} component={PlanoPagamentoCadastro} />
      <PrivateRoute exact path={`/plano_pagamento/cadastro/:planoPagamentoId`} component={PlanoPagamentoCadastro} />
      <PrivateRoute exact path={`/plano_pagamento/view/:planoPagamentoId`} component={PlanoPagamentoView} />

      <PrivateRoute exact path={`/plano_contas`} component={PlanoContas} />
      <PrivateRoute exact path={`/plano_contas/cadastro`} component={PlanoContasCadastro} />
      <PrivateRoute exact path={`/plano_contas/cadastro/:planoContasId`} component={PlanoContasCadastro} />
      <PrivateRoute exact path={`/plano_contas/view/:planoContasId`} component={PlanoContasView} />

      <PrivateRoute exact path={`/conta_financeira`} component={ContaFinanceira} />
      <PrivateRoute exact path={`/conta_financeira/cadastro`} component={ContaFinanceiraCadastro} />
      <PrivateRoute exact path={`/conta_financeira/cadastro/:contaFinanceiraId`} component={ContaFinanceiraCadastro} />
      <PrivateRoute exact path={`/conta_financeira/view/:contaFinanceiraId`} component={ContaFinanceiraView} />
      <PrivateRoute exact path={`/conta_financeira/corrige_saldo/:contaFinanceiraId`} component={ContaFinanceiraCorrigirSaldo} />

      <PrivateRoute exact path={`/movimentacao_financeira`} component={Movimentacao} />
      <PrivateRoute exact path={`/movimentacao_financeira/cadastro`} component={MovimentacaoCadastro} />

      <PrivateRoute exact path={`/conta_pagar_receber`} component={ContaPagarReceber} />
      <PrivateRoute exact path={`/conta_pagar_receber/cadastro`} component={ContaPagarReceberCadastro} />
      <PrivateRoute exact path={`/conta_pagar_receber/cadastro/:parcelaId`} component={ContaPagarReceberUpdate} />

      {/* <PrivateRoute exact path={`/conciliacao_movimentacao`} component={Conciliacao} /> */}

      <PrivateRoute exact path={`/documento`} component={Documento} />
      <PrivateRoute exact path={`/documento/cadastro`} component={DocumentoCadastro} />
      <PrivateRoute exact path={`/documento/cadastro/:documentoId`} component={DocumentoCadastro} />

      <PrivateRoute exact path={`/schedule`} component={Schedule} />
      <PrivateRoute exact path={`/baixa_aula`} component={BaixaAula} />
      <PrivateRoute exact path={`/baixa_aula/:data`} component={BaixaAula} />

      <PrivateRoute exact path={`/rel_administrativo`} component={RelatorioAdm} />
      <PrivateRoute exact path={`/rel_pedagogico`} component={RelatorioPegagogico} />
      <PrivateRoute exact path={`/rel_comercial`} component={RelatorioComercial} />
      <PrivateRoute exact path={`/rel_ranking`} component={RelatorioRanking} />
      <PrivateRoute exact path={`/rel_financeiro`} component={RelatorioFinanceiro} />
      <PrivateRoute exact path={`/fly_high`} component={RelatorioFlyHigh} />
      <PrivateRoute exact path={`/carteira_mensalidades`} component={RelatorioCarteiraMensalidade} />

      <PrivateRoute exact path={`/grafico_carteira_mensalidade/impressao/unidade`} component={CarteiraMensalidadeImpressao} />
      <PrivateRoute exact path={`/grafico_carteira_unidade/impressao/unidade`} component={CarteiraMensalidadeUnidadeImpressao} />      

      <PrivateRoute exact path={`/fly_high/impressao/unidade`} component={FlyHighImpressaoUnidade} />
      <PrivateRoute exact path={`/fly_high/impressao/cargo`} component={FlyHighImpressaoCargo} />
      <PrivateRoute exact path={`/fly_high/impressao/colaborador`} component={FlyHighImpressaoColaborador} />
      
      <PrivateRoute exact path={`/rel_comercial/impressao/evolucao_alunos_periodo`} component={GraficoCarteiraMensalidadeImpressao} />

      <PrivateRoute exact path={`/caixa`} component={Caixa} />
      <PrivateRoute exact path={`/caixa/cadastro/`} component={CaixaCadastro} />
      <PrivateRoute exact path={`/caixa/cadastro/:redirect`} component={CaixaCadastro} />
      <PrivateRoute exact path={`/caixa/view/:caixaId`} component={CaixaView} />
      <PrivateRoute exact path={`/solicitacao_abertura_caixa/:solicitacaoId`} component={SolicitacaoAberturaCaixa} />

      <PrivateRoute exact path={`/caixa_fechamento`} component={FechamentoCaixa} />
      <PrivateRoute exact path={`/caixa_fechamento/view/:caixaId`} component={FechamentoCaixaView} />

      <PrivateRoute exact path={`/codigo_alfa`} component={CodigoAlfaNum} />
      <PrivateRoute exact path={`/codigo_alfa/cadastro`} component={CodigoAlfaNumCadastro} />
      <PrivateRoute exact path={`/codigo_alfa/view/:codigoId`} component={CodigoAlfaNumView} />

      <PrivateRoute exact path={`/nova-matricula`} component={NovaMatriculaCadastro} />
      <PrivateRoute exact path={`/aluno/aprovarTransferencia/:transfId`} component={AprovarTransferencia} />
      <PrivateRoute exact path={`/aluno/aprovarRematricula/:transfId`} component={AprovarRematricula} />

      <PrivateRoute exact path={`/demonstrativo_diario`} component={DemonstrativoDiario} />
      <PrivateRoute exact path={`/demonstrativo_diario/view/:data/:pesf_id/:unin_id`} component={DemonstrativoDiarioView} />
    
      <PrivateRoute exact path={`/config_aditivos`} component={ConfigAditivo} />
      <PrivateRoute exact path={`/config_aditivos/cadastro`} component={CadastroConfigAditivo} />
      <PrivateRoute exact path={`/config_aditivos/cadastro/:configId`} component={CadastroConfigAditivo} />
      <PrivateRoute exact path={`/config_aditivos/view/:configId`} component={ViewConfigAditivo} />

      <PrivateRoute exact path={`/pontos_fly_high`} component={FlyHigh} />
      <PrivateRoute exact path={`/pontos_fly_high/cadastro`} component={FlyHighCadastro} />
      <PrivateRoute exact path={`/pontos_fly_high/cadastro/:flyHighId`} component={FlyHighCadastro} />

      <PrivateRoute exact path={`/ranking_bff`} component={RankingBFF} />

      <PrivateRoute exact path={`/servicos_bancario`} component={ServicosBancarios} />
      <PrivateRoute exact path={`/servicos_bancario/cadastro`} component={ServicosBancariosCadastro} />
      <PrivateRoute exact path={`/servicos_bancario/cadastro/:servico_bancarioId`} component={ServicosBancariosCadastro} />
    
      <PrivateRoute exact path={`/parceiros_bancario`} component={ParceirosBancarios} />
      <PrivateRoute exact path={`/parceiros_bancario/cadastro`} component={ParceirosBancariosCadastro} />
      <PrivateRoute exact path={`/parceiros_bancario/cadastro/:parceiro_bancarioId`} component={ParceirosBancariosCadastro} />

      <PrivateRoute exact path={`/central_notificacao`} component={CentralNotificacao} />
      <PrivateRoute exact path={`/central_notificacao/cadastro`} component={CentralNotificacaoCadastro} />
      <PrivateRoute exact path={`/central_notificacao/cadastro/:centralAlertaId`} component={CentralNotificacaoCadastro} />

      <PrivateRoute exact path={`/api_whatsapp`} component={WhatsApp} />
      <PrivateRoute exact path={`/api_whatsapp/cadastro`} component={WhatsAppCadastro} />
      <PrivateRoute exact path={`/api_whatsapp/cadastro/:numeroWhatsId`} component={WhatsAppCadastro} />

      <PrivateRoute exact path={`/cupom_desconto`} component={CupomDesconto} />
      <PrivateRoute exact path={`/cupom_desconto/cadastro`} component={CupomDescontoCadastro} />
      <PrivateRoute exact path={`/cupom_desconto/cadastro/:cupomId`} component={CupomDescontoCadastro} />
      <PrivateRoute exact path={`/cupom_desconto/view/:cupomId`} component={CupomDescontoView} />

      <PrivateRoute exact path={`/cobranca_unidade`} component={CobrancaUnidade} />
      <PrivateRoute exact path={`/cobranca_unidade/cadastro`} component={CobrancaUnidadeCadastro} />
      <PrivateRoute exact path={`/cobranca_unidade/cadastro/:configId`} component={CobrancaUnidadeCadastro} />
      <PrivateRoute exact path={`/cobranca_unidade/view/:configId`} component={CobrancaUnidadeView} />

      <PrivateRoute exact path={`/contratos_unidades`} component={ContratosUnidades} />

      <PrivateRoute exact path={`/config_venda_central`} component={ConfigVendaCentral} />

      <PrivateRoute exact path={`/venda_prod_central_unidade`} component={VendaProduto} />
      <PrivateRoute exact path={`/venda_prod_central_unidade/cadastro`} component={VendaProdutoCadastro} />
      <PrivateRoute exact path={`/venda_prod_central_unidade/cadastro/:vendaId`} component={VendaProdutoCadastro} />

      <PrivateRoute exact path={`/compra_prod_unidade_central`} component={CompraProduto} />
      <PrivateRoute exact path={`/compra_prod_unidade_central/cadastro`} component={CompraProdutoCadastro} />
      <PrivateRoute exact path={`/compra_prod_unidade_central/cadastro/:vendaId`} component={CompraProdutoCadastro} />

      <PrivateRoute exact path={`/lembretes`} component={Lembrete} />
      <PrivateRoute exact path={`/lembretes/cadastro`} component={LembreteCadastro} />
      <PrivateRoute exact path={`/lembretes/cadastro/:lembreteId`} component={LembreteCadastro} />
    </Switch>
  </Router>
);

export default Routes;