import React, { Component } from 'react'
import './CentralNotificacao.scss'
import './../../main/ultil.scss'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button } from '@material-ui/core'
import axios from 'axios'
import Footer from '../../components/templates/Footer'
import ModalErro from './../../components/modals/Erro'
import ModalConfigurarEmail from './../../components/modals/ConfigurarEmail'
import moment from 'moment';

const initalState = {
  notificacoes: {
    list: []
  },
  notificacoesFiltrado: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'nome_alerta', numeric: false, disablePadding: true, label: 'Nome do Alerta' },
    { id: 'titulo_alerta', numeric: false, disablePadding: true, label: 'Titulo do Alerta' },
    { id: 'modulo', numeric: false, disablePadding: true, label: 'Modulo' },
    { id: 'canal', numeric: false, disablePadding: true, label: 'Canal' },
    { id: 'dias_envia', numeric: false, disablePadding: true, label: 'Dias para Envio' },
    { id: 'hora_envio', numeric: false, disablePadding: true, label: 'Horario do Envio' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  filtro: {
    descricao: "",
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  permissoesConfig: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  openModalConfigurarEmail: false,
  configuracao_email:{
    email: '',
    senha: '',
    servidor: '',
    porta: ''
  },
  showPassword: false
}
export default class CentralNotificacao extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'central-alerta')[0]
    let permissoesConfig = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'config-email')[0]

    let acoesTabela = []

    if (permissoes.perm_alterar) {
      acoesTabela.push('update')
    }

    if(permissoesConfig){
      this.setState({
        permissoesConfig
      })
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      const { data: alertas } = await axios.post(`${process.env.REACT_APP_API_URL}/centralAlerta/list`, dados, this.getToken())

      console.log(alertas)

      this.setState({
        notificacoes:{
          list: alertas.map(alerta => {
            return{
              _id: alerta.centalert_id,
              nome_alerta: alerta.centalert_nome_alerta,
              titulo_alerta: alerta.centalert_titulo_alerta,
              modulo: alerta.centalert_modulo,
              canal: alerta.centalert_canal,
              dias_envia: alerta.centalert_dias_antes_evento,
              hora_envio: moment(`${moment(new Date()).format('YYYY-MM-DD')} ${alerta.centalert_hora_envio}`).format('HH:mm'),
              ativo: alerta.centalert_ativo
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: error.response.data.error
      })
    }

    this.setState({
      loading: false
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  async openModalConfigurarEmail(){
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/centralAlerta/showConfiguracao`, this.getToken())
      this.setState({
        openModalConfigurarEmail: true,
        configuracao_email: {
          email: data.confgnot_remetente,
          senha: '',
          servidor: data.confgnot_servidor,
          porta: data.confgnot_porta
        }
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Erro!",
        descricao: error.response.data.error
      })
    }
  }

  closeModalConfigurarEmail(){
    this.setState({
      openModalConfigurarEmail: false
    })
  }

  updateFieldConfiguracao(event){
    let {configuracao_email} = this.state
    console.log(event.target.name)
    configuracao_email[event.target.name] = event.target.value
    this.setState({configuracao_email})
  }

  validaForm(){
    const {configuracao_email} = this.state

    if(configuracao_email.email === '') return false
    if(configuracao_email.servidor === '') return false
    if(configuracao_email.senha === '') return false
    if(configuracao_email.porta === '') return false

    return true
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  async confirmarConfiguracao(value){
    if(value){
      try {
        if(!this.validaForm()){
          this.handleOpenDialog({
            titulo: "Erro no Cadastro!",
            descricao: 'Preencha os campos obrigatorios (*).'
          })
          return
        }
    

        let {configuracao_email} = this.state

        await axios.put(`${process.env.REACT_APP_API_URL}/centralAlerta/configuracao`,configuracao_email, this.getToken())
        
        this.handleOpenDialog({
          titulo: "Sucesso!",
          descricao: 'Cadastro atualizado com sucesso.'
        })

        this.setState({
          openModalConfigurarEmail: false,
          showPassword: false
        })
  
      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Erro!",
          descricao: error.response.data.error
        })
      }
    }else{
      this.closeModalConfigurarEmail()
    }
  }

  handleClickShowPassword(){
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    const { permissoes, notificacoes } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Notificações...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={7} xs={12} sm={6}>
                  <h1 className="titulo">Central de Notificações</h1>
                </Grid>
                {permissoes.perm_inserir &&
                  <Grid item md={2} xs={12} sm={6}>
                    <Button onClick={() => this.openModalConfigurarEmail()} size="small" className="btnCadastrar" variant="contained" color="secondary">
                      Configurar Email
                    </Button>
                  </Grid>
                }
                {permissoes.perm_inserir &&
                  <Grid item md={3} xs={12} sm={6}>
                    <Link to="/central_notificacao/cadastro">
                      <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Notificação
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={12} xs={12}>
                  <Table urlUpdate="/central_notificacao/cadastro/" headCell={this.state.cabecalhoTabela} rows={notificacoes.list} acoes={this.state.acoesTabela} />
                </Grid>
              </Grid>
            </div>
          }
          <ModalConfigurarEmail
            open={this.state.openModalConfigurarEmail}
            handleClose={() => this.closeModalConfigurarEmail()}
            dados={this.state.configuracao_email}
            updateField={(e) => this.updateFieldConfiguracao(e)}
            confirmar={(e) => this.confirmarConfiguracao(e)}
            showPassword={this.state.showPassword}
            handleClickShowPassword={() => this.handleClickShowPassword()}
          />
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}