import React, { Component } from 'react'
import './CobrancaUnidade.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import { Grid, Button } from '@material-ui/core'
import FormConfiguracaoCobranca from '../../components/forms/FormConfiguracaoCobranca'
import SaveIcon from '@material-ui/icons/Save';
import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import Table from '../../components/Table'
import real from './../../services/real'

const initalState = {
  configuracao: {
    configcobran_id: "",
    configcobran_descricao: "",
    configcobran_contfin_id: "",
    configcobran_formpag_id: "",
    configcobran_receita_plancontas_id: "",
    configcobran_dias_pagamento: "",
    configcobran_juros: "",
    configcobran_ativo: true,
    servicos: []
  },
  servico: {
    prod_id: "",
    prod_descricao: "",
    configprod_id: "",
    configprod_dia_vencimento: "",
    configprod_parametro: "",
    configprod_valor: "",
    configprod_ativo: true,
    configprod_habilita_valor_min: false,
    configprod_habilita_valor_max: false,
    configprod_valor_min: real(0),
    configprod_valor_max: real(0),
    index: ""
  },
  books: {
    list: []
  },
  unidades: {
    list: []
  },
  produtos: {
    list: []
  },
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'avalaula_descricao', numeric: false, disablePadding: true, label: 'Descrição Avaliação' },
    { id: 'avalaula_nota', numeric: false, disablePadding: false, label: 'Nota Avaliação' },
  ],
  acoesTabela: ['editar', 'remove'],
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  formasPagamento: {
    list: []
  },
  contasFinanceira: {
    list: []
  },
  planosConta: {
    list: []
  }
}
export default class CadastroAula extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {
    this.setState({
      configuracao: {
        configcobran_id: "",
        configcobran_descricao: "",
        configcobran_contfin_id: "",
        configcobran_formpag_id: "",
        configcobran_receita_plancontas_id: "",
        configcobran_despesa_plancontas_id: "",
        configcobran_dias_pagamento: "",
        configcobran_juros: "",
        configcobran_ativo: true,
        servicos: []
      },
      servico: {
        prod_id: "",
        prod_descricao: "",
        configprod_id: "",
        configprod_dia_vencimento: "",
        configprod_parametro: "",
        configprod_valor: "",
        configprod_ativo: true,
        configprod_habilita_valor_min: false,
        configprod_habilita_valor_max: false,
        configprod_valor_min: real(0),
        configprod_valor_max: real(0),
        index: ""
      }
    })

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'cobranca-unidade')[0]

    this.setState({
      permissoes
    })

    await this.consultaProdutos()
    await this.consultaFormasPagamento()
    await this.consultaContasFinanceira()
    await this.consultaPlanoContas()

    if (params.configId) {
      try {
        const { data: config } = await axios.get(`${process.env.REACT_APP_API_URL}/configuracaoCobranca/${params.configId}`, this.getToken())

        this.setState({
          configuracao: {
            configcobran_id: config.configcobran_id,
            configcobran_descricao: config.configcobran_descricao,
            configcobran_ativo: config.configcobran_ativo,
            configcobran_contfin_id: config.configcobran_contfin_id,
            configcobran_formpag_id: config.configcobran_formpag_id,
            configcobran_receita_plancontas_id: config.configcobran_receita_plancontas_id,
            configcobran_despesa_plancontas_id: config.configcobran_despesa_plancontas_id,
            configcobran_dias_pagamento: config.configcobran_dias_pagamento,
            configcobran_juros: config.configcobran_juros,
            servicos: config.servicos.map((servico, key) => {
              return {
                prod_id: servico.produto.prod_id,
                prod_descricao: servico.produto.prod_descricao,
                configprod_id: servico.configprod_id,
                configprod_dia_vencimento: servico.configprod_dia_vencimento,
                configprod_parametro: servico.configprod_parametro,
                configprod_valor: real(servico.configprod_valor),
                configprod_ativo: servico.configprod_ativo,
                configprod_habilita_valor_min: servico.configprod_habilita_valor_min,
                configprod_habilita_valor_max: servico.configprod_habilita_valor_max,
                configprod_valor_min: real(servico.configprod_valor_min),
                configprod_valor_max: real(servico.configprod_valor_max),
                index: key
              }
            })
          },
        })

      } catch ({ response }) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        this.backPage()
        return
      }
    }

    this.setState({ loading: false })
  }

  async consultaProdutos(){
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/produtos`, this.getToken())

      const produtos = []

      resp.data.forEach(produto => {
        if(produto.prod_ativo && produto.prod_tipo === 'SERVICO'){
          produtos.push({
            _id: produto.prod_id,
            prod_descricao: produto.prod_descricao,
            prod_ativo: produto.prod_ativo,
            index: ""
          })
        }
      })

      this.setState({
        produtos: {
          list: produtos
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async consultaFormasPagamento(){
    try {
      const { data: formasPagamento } = await axios.get(`${process.env.REACT_APP_API_URL}/formapagto`, this.getToken())

      this.setState({
        formasPagamento: {
          list: formasPagamento.filter(param => param.formpag_ativo === true)
        }
      })
    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async consultaContasFinanceira(){
    try {
      const { data: contasFinanceira } = await axios.get(`${process.env.REACT_APP_API_URL}/contaFinanceira/listByUnidade/1`, this.getToken())

      this.setState({
        contasFinanceira: {
          list: contasFinanceira
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  async consultaPlanoContas(){
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      this.setState({
        planosConta: {
          list: data.filter(param => param.plancontas_ativo === true)
          .sort((a, b) => (parseInt(a.plancontas_codigo) > parseInt(b.plancontas_codigo)) ? 1 : ((parseInt(b.plancontas_codigo) > parseInt(a.plancontas_codigo)) ? -1 : 0))
          .map(value => {
            return {
              plancontas_id: value.plancontas_id,
              plancontas_codigo: value.plancontas_codigo,
              plancontas_descricao: value.plancontas_descricao,
              plancontas_ativo: value.plancontas_ativo,
              plancontas_slug: value.plancontas_slug,
              parametros: value.parametros
            }
          })
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  backPage(timeout = 1000) {
    this.setState({
      configuracao: {
        configcobran_id: "",
        configcobran_descricao: "",
        configcobran_contfin_id: "",
        configcobran_formpag_id: "",
        configcobran_receita_plancontas_id: "",
        configcobran_despesa_plancontas_id: "",
        configcobran_dias_pagamento: "",
        configcobran_juros: "",
        configcobran_ativo: true,
        servicos: []
      },
      servico: {
        prod_id: "",
        prod_descricao: "",
        configprod_id: "",
        configprod_dia_vencimento: "",
        configprod_parametro: "",
        configprod_valor: "",
        configprod_ativo: true,
        index: ""
      }
    })
    const self = this
    setTimeout(() => {
      self.props.history.push("/cobranca_unidade");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  updateField(event) {
    const configuracao = { ...this.state.configuracao }
    configuracao[event.target.name] = event.target.value
    this.setState({ configuracao })
  }

  updateFieldServico(event){
    const {servico, produtos} = this.state

    let value = event.target.value
    let name = event.target.name

    if(name === "prod_id"){
      servico.prod_descricao = produtos.list.filter(param => param._id === parseInt(value))[0].prod_descricao
    }

    servico[name] = value
    this.setState({ servico })
  }

  updateFieldAtivo(){
    const configuracao = { ...this.state.configuracao }
    configuracao.configcobran_ativo = !configuracao.configcobran_ativo
    this.setState({ configuracao })
  }

  updateFieldAtivoServico(){
    const servico = { ...this.state.servico }
    servico.configprod_ativo = !servico.configprod_ativo
    this.setState({ servico })
  }

  updateFieldMinServico(){
    const servico = { ...this.state.servico }
    servico.configprod_habilita_valor_min = !servico.configprod_habilita_valor_min
    this.setState({ servico })
  }

  updateFieldMaxServico(){
    const servico = { ...this.state.servico }
    servico.configprod_habilita_valor_max = !servico.configprod_habilita_valor_max
    this.setState({ servico })
  }

  validateServico(){
    const {servico} = this.state

    if(servico.prod_id === '') return false
    if(servico.configprod_dia_vencimento === '') return false
    if(servico.configprod_parametro === '') return false
    if(servico.configprod_valor === '') return false

    if(servico.configprod_habilita_valor_min){
      if(servico.configprod_valor_min === '') return false
    }

    if(servico.configprod_habilita_valor_max){
      if(servico.configprod_valor_max === '') return false
    }

    return true
  }

  addItem(){
    const {servico, configuracao} = this.state

    if (!this.validateServico()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    if(parseFloat(servico.configprod_valor_min.replace(".", "").replace(",", ".")) > parseFloat(servico.configprod_valor_max.replace(".", "").replace(",", "."))){
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "O valor mínimo não pode ser superior ao valor máximo!"
      })
      return
    }

    if(servico.index === ""){
      configuracao.servicos.push({
        ...servico,
        index: configuracao.servicos.length
      })
    }else{
      configuracao.servicos[servico.index] = servico
    }


    this.setState({
      configuracao,
      servico: {
        prod_id: "",
        prod_descricao: "",
        configprod_id: "",
        configprod_dia_vencimento: "",
        configprod_parametro: "",
        configprod_valor: "",
        configprod_ativo: true,
        configprod_habilita_valor_min: false,
        configprod_habilita_valor_max: false,
        configprod_valor_min: real(0),
        configprod_valor_max: real(0),
        index: ""
      },
      update: false
    })
  }

  editarItem(index) {
    const {configuracao} = this.state

    this.setState({
      update: true,
      servico: configuracao.servicos[index]
    })
  }

  removerItem(index) {
    const {configuracao} = this.state
    
    let itens = configuracao.servicos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        prod_id: itens[i].prod_id,
        prod_descricao: itens[i].prod_descricao,
        configprod_id: itens[i].configprod_id,
        configprod_dia_vencimento: itens[i].configprod_dia_vencimento,
        configprod_parametro: itens[i].configprod_parametro,
        configprod_valor: itens[i].configprod_valor,
        configprod_ativo: itens[i].configprod_ativo,
        configprod_habilita_valor_min: itens[i].configprod_habilita_valor_min,
        configprod_habilita_valor_max: itens[i].configprod_habilita_valor_max,
        configprod_valor_min: real(itens[i].configprod_valor_min),
        configprod_valor_max: real(itens[i].configprod_valor_max),
        index: i
      })
    }

    itens = aux

    configuracao.servicos = itens

    this.setState({
      configuracao
    })
  }

  validateForm(){
    let {configuracao} = this.state

    if(configuracao.configcobran_descricao === "") return 
    if(configuracao.configcobran_contfin_id === "") return false
    if(configuracao.configcobran_formpag_id === "") return false
    if(configuracao.configcobran_receita_plancontas_id === "") return false
    if(configuracao.configcobran_despesa_plancontas_id === "") return false
    if(configuracao.configcobran_dias_pagamento === "") return false
    if(configuracao.configcobran_juros === "") return false
    
    return true
  }

  async salvar(){
    
    try {
      let {configuracao} = this.state
  
      if (!this.validateForm()) {
        this.handleOpenDialog({
          titulo: "Erro ao Adicionar!",
          descricao: "Preencha os campos obrigatorios (*)."
        })
        return
      }
  
      if(configuracao.servicos.length === 0){
        this.handleOpenDialog({
          titulo: "Erro ao Adicionar!",
          descricao: "Por favor informar ao menos um serviço."
        })
        return
      }

      let dados = {
        configcobran_descricao: configuracao.configcobran_descricao,
        configcobran_ativo: configuracao.configcobran_ativo,
        configcobran_contfin_id: configuracao.configcobran_contfin_id,
        configcobran_formpag_id: configuracao.configcobran_formpag_id,
        configcobran_receita_plancontas_id: configuracao.configcobran_receita_plancontas_id,
        configcobran_despesa_plancontas_id: configuracao.configcobran_despesa_plancontas_id,
        configcobran_dias_pagamento: configuracao.configcobran_dias_pagamento,
        configcobran_juros: configuracao.configcobran_juros,
        servicos: configuracao.servicos.map(servico => {
          return {
            configprod_id: servico.configprod_id,
            prod_id: parseInt(servico.prod_id),
            configprod_dia_vencimento: servico.configprod_dia_vencimento,
            configprod_parametro: servico.configprod_parametro,
            configprod_ativo: servico.configprod_ativo,
            configprod_valor: parseFloat(servico.configprod_valor.replace(".", "").replace(",", ".")),
            configprod_habilita_valor_min: servico.configprod_habilita_valor_min,
            configprod_habilita_valor_max: servico.configprod_habilita_valor_max,
            configprod_valor_min: parseFloat(servico.configprod_valor_min.replace(".", "").replace(",", ".")),
            configprod_valor_max: parseFloat(servico.configprod_valor_max.replace(".", "").replace(",", ".")),
          }
        })
      }
      
      if(configuracao.configcobran_id){
        await axios.put(`${process.env.REACT_APP_API_URL}/configuracaoCobranca/${configuracao.configcobran_id}`, dados, this.getToken())
      }else{
        await axios.post(`${process.env.REACT_APP_API_URL}/configuracaoCobranca`, dados, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  render() {
    const { configuracao, produtos, servico, contasFinanceira, formasPagamento, planosConta } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid
                container
                spacing={1}
                direction="row"
                className="borderBottom"
              >
                <Grid item md={12}>
                  <h2 className="titulo">Cadastro de Configuração de Cobrança</h2>
                </Grid>
              </Grid>
              <FormConfiguracaoCobranca
                dados={configuracao}
                updateField={e => this.updateField(e)}
                updateFieldAtivo={() =>this.updateFieldAtivo()}
                produtos={produtos.list}
                servico={servico}
                updateFieldServico={e => this.updateFieldServico(e)}
                addItem={() => this.addItem()}
                editarItem={(e) => this.editarItem(e)}
                update={this.state.update}
                removerItem={(e) => this.removerItem(e)}
                contasFinanceira={contasFinanceira.list}
                formasPagamento={formasPagamento.list}
                planosConta={planosConta.list}
                updateFieldAtivoServico={() => this.updateFieldAtivoServico()}
                updateFieldMinServico={() => this.updateFieldMinServico()}
                updateFieldMaxServico={() => this.updateFieldMaxServico()}
              />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}